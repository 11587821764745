import { useState, useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { DETAIL_PANEL_PATH } from '~/routes';
import { useIsInTabletMode } from 'airshare-pilot-web-shared';

const DETAIL_PANEL_WIDTH = 450;
const PAN_DISTANCE = DETAIL_PANEL_WIDTH / 2;

export function useAdaptivePan(
  mapInstance: google.maps.Map,
  pathname: string,
  defaultCenter: google.maps.LatLng | null
) {
  const [isPanned, updateIsPanned] = useState(false);
  const pathMatch = matchPath(pathname, {
    path: DETAIL_PANEL_PATH,
    exact: true,
    strict: true,
  });
  const isInTabletMode = useIsInTabletMode();

  useEffect(() => {
    const shouldPan =
      mapInstance &&
      !isPanned &&
      pathMatch &&
      pathMatch.isExact &&
      !isInTabletMode;
    const shouldPanBack =
      mapInstance && isPanned && !pathMatch && !isInTabletMode;

    if (shouldPan) {
      mapInstance.panBy(PAN_DISTANCE * -1, 0);
      updateIsPanned(true);
    } else if (shouldPanBack) {
      if (defaultCenter) {
        mapInstance.panTo(defaultCenter);
      } else {
        mapInstance.panBy(PAN_DISTANCE, 0);
      }
      updateIsPanned(false);
    }
  }, [mapInstance, pathMatch, isInTabletMode, isPanned, defaultCenter]);
}
