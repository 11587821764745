import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import {
  OverlappingEdgeDetails,
  OverlappingType,
} from '@airshare/external-api-types';

import { useFlightAdvisories } from '~/state/flight-advisories/hooks';

export interface LocalOverlapDetail {
  mySegmentId: string;
  otherFlightId: number;
  otherSegmentId: string | undefined;
  areaOverlap: OverlappingEdgeDetails;
  timeOverlap: OverlappingEdgeDetails;
  altitudeOverlap: OverlappingEdgeDetails;
  overallOverlap: OverlappingType;
  otherStartDateTime: DateTime;
  otherEndDateTime: DateTime;
  otherMinAltitudeFeet: number;
  otherMaxAltitudeFeet: number;
  isConflicting?: boolean;
}
export interface LocalOverlapSummary {
  fullFlight: LocalOverlapDetail[];
  segments: {
    [key: string]: LocalOverlapDetail[];
  };
}

export function useOverlapSummary() {
  const flightAdvisories = useFlightAdvisories();
  const [overlapSummary, setOverlapSummary] = useState<LocalOverlapSummary>();

  useEffect(() => {
    if (flightAdvisories?.length > 0) {
      const flightOverlaps: LocalOverlapDetail[] = flightAdvisories.map(
        (advisory) => {
          return {
            mySegmentId: undefined,
            otherFlightId: advisory.properties.flightId,
            otherSegmentId: advisory.properties.segmentId,
            areaOverlap: advisory?.properties?.overlapDetails?.areaOverlap,
            timeOverlap: advisory?.properties?.overlapDetails?.timeOverlap,
            altitudeOverlap:
              advisory?.properties?.overlapDetails?.altitudeOverlap,
            overallOverlap:
              advisory?.properties?.overlapDetails?.overallOverlap,
            otherStartDateTime: DateTime.fromISO(
              advisory?.properties?.startDateTime
            ),
            otherEndDateTime: DateTime.fromISO(
              advisory?.properties?.endDateTime
            ),
            otherMinAltitudeFeet: advisory?.properties?.minAltitudeFeet,
            otherMaxAltitudeFeet: advisory?.properties?.maxAltitudeFeet,
            isConflicting: advisory?.properties?.isConflicting,
          };
        }
      );

      const allSegmentOverlaps = flightAdvisories
        .map((advisory) => {
          return advisory?.properties?.overlapDetails?.mySegments?.map((s) => {
            return {
              mySegmentId: s.mySegmentId,
              otherFlightId: advisory.properties.flightId,
              otherSegmentId: advisory.properties.segmentId,
              areaOverlap: s.areaOverlap,
              timeOverlap: s.timeOverlap,
              altitudeOverlap: s.altitudeOverlap,
              overallOverlap: s.overallOverlap,
              otherStartDateTime: DateTime.fromISO(
                advisory?.properties?.startDateTime
              ),
              otherEndDateTime: DateTime.fromISO(
                advisory?.properties?.endDateTime
              ),
              otherMinAltitudeFeet: advisory?.properties?.minAltitudeFeet,
              otherMaxAltitudeFeet: advisory?.properties?.maxAltitudeFeet,
              isConflicting: advisory?.properties?.isConflicting,
            };
          });
        })
        .flat();

      const segmentOverlaps: LocalOverlapSummary = allSegmentOverlaps.reduce(
        (acc: LocalOverlapSummary, overlap) => {
          if (!overlap?.mySegmentId) {
            return acc;
          }

          if (!acc.segments[overlap.mySegmentId]) {
            acc.segments[overlap.mySegmentId] = [];
          }
          acc.segments[overlap.mySegmentId].push(overlap);
          return acc;
        },
        { fullFlight: flightOverlaps, segments: {} }
      );

      setOverlapSummary(segmentOverlaps);
    } else {
      setOverlapSummary(undefined);
    }
  }, [flightAdvisories]);

  return { overlapSummary };
}
