export const LogInStatus = Object.freeze({
  IDLE: 'IDLE',
  LOG_IN_ATTEMPT_IN_PROGRESS: 'LOG_IN_ATTEMPT_IN_PROGRESS',
  POLICY_ACCEPTANCE: 'POLICY_ACCEPTANCE',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const RegistrationStatus = Object.freeze({
  IDLE: 'IDLE',
  REGISTRATION_ATTEMPT_IN_PROGRESS: 'REGISTRATION_ATTEMPT_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const ActivationStatus = Object.freeze({
  IDLE: 'IDLE',
  ACTIVATION_ATTEMPT_IN_PROGRESS: 'ACTIVATION_ATTEMPT_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const ResendActivationEmailStatus = Object.freeze({
  IDLE: 'IDLE',
  RESEND_ACTIVATION_ATTEMPT_IN_PROGRESS:
    'RESEND_ACTIVATION_ATTEMPT_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});
