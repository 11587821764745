import React from 'react';
import { useTransition, animated } from 'react-spring';
import { makeStyles, createStyles } from '@material-ui/styles';

import { LoadingButton } from 'airshare-pilot-web-shared';

interface Props {
  componentLabel: string;
  isRequesting: boolean;
  isRequestingComplete: boolean;
  isRequestSuccessful?: boolean;
  handleAction: React.MouseEventHandler<HTMLButtonElement>;
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  showRequestingComplete: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignContent: 'flex-end',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      marginTop: 'auto',
      bottom: '0',
    },
    actionButton: {},
    loadingButton: {},
    cancel: {
      width: '100%',
      paddingTop: '1rem',

      textDecoration: 'underline',
    },
    requestCompleteMessage: {
      right: '2rem',
    },
  })
);

export const AreYouSureCancel = (props: Props) => {
  const {
    componentLabel,
    isRequesting,
    isRequestingComplete,
    isRequestSuccessful = false,
    handleAction,
    handleCancel,
    showRequestingComplete = false,
  } = props;

  const classes = useStyles({});

  const cancelButtonTransitions = useTransition(isRequesting, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const showAreYouSure =
    !showRequestingComplete || (isRequesting && !isRequestingComplete);

  return (
    <div
      data-testid={`pilot-web:${componentLabel}:are-you-sure-container`}
      className={classes.container}
    >
      {showAreYouSure && (
        <LoadingButton
          data-testid={`pilot-web:${componentLabel}:confirm-button`}
          isLoading={isRequestingComplete}
          className={`form-control ${classes.loadingButton}`}
          type="button"
          onClick={handleAction}
        >
          Are you sure?
        </LoadingButton>
      )}

      {showAreYouSure &&
        cancelButtonTransitions.map(
          (a) =>
            a.item && (
              <animated.button
                key={a.key}
                style={a.props}
                className={classes.cancel}
                type="button"
                onClick={handleCancel}
              >
                cancel
              </animated.button>
            )
        )}

      {showRequestingComplete && isRequestingComplete && (
        <div>
          <div className={classes.requestCompleteMessage}>
            {isRequestSuccessful && (
              <div>
                The request has been forwarded to Airshare to be processed. You
                will be notified when the request is completed.
                <br />
                For more information see the Privacy section.
              </div>
            )}
            {!isRequestSuccessful && (
              <div>
                The request could not be completed at this time. Please try
                again later.
              </div>
            )}
          </div>
          <div className={classes.actionButton}>
            <LoadingButton
              data-testid={`pilot-web:${componentLabel}:confirm-button`}
              className={`form-control ${classes.loadingButton}`}
              type="button"
              onClick={handleCancel}
            >
              OK
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};
