import React, { ReactElement } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

interface GoogleApiLoaderProps {
  children?: any;
  loading?: any;
}

function GoogleApiLoader({ children, loading }: GoogleApiLoaderProps) {
  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return loading || <div className="map" />;
    if (status === Status.FAILURE)
      return (
        <div className="map">
          There was a failure loading the maps. Please reload to try again
        </div>
      );
    return <></>;
  };

  return (
    <Wrapper
      // @ts-ignore
      apiKey={window.env.GOOGLE_MAPS_API_KEY}
      render={render}
      libraries={['places', 'drawing', 'geometry', 'marker']}
      version="3.56"
    >
      {children}
    </Wrapper>
  );
}
export default GoogleApiLoader;
