import { useEffect } from 'react';

export function useNoaLayer(google: any, map: google.maps.Map | null) {
  const setStyle = (feature: google.maps.Data.Feature) =>
    ({
      title: feature.getProperty('name') as string,
      zIndex: feature.getProperty('zIndex') as number,
      strokeColor: feature.getProperty('strokeColor') as string,
      strokeOpacity: feature.getProperty('strokeOpacity') as number,
      strokeWidth: feature.getProperty('strokeWidth') as string,
      strokeWeight: feature.getProperty('strokeWeight') as number,
      fillOpacity: feature.getProperty('fillOpacity') as number,
      clickable: false,
      editable: false,
      draggable: false,
    }) as google.maps.Data.StyleOptions;

  useEffect(() => {
    if (map) {
      const url = `${window.env.ARGUS_CONTENT_API_URL}/approval-zones/primary-noa.geojson`;
      const dataLayer: google.maps.Data = new google.maps.Data({});

      dataLayer.loadGeoJson(url, {}, (_: google.maps.Data.Feature[]) => {
        dataLayer.setStyle(setStyle);

        dataLayer.setMap(map);
      });
    }
  }, [google, map]);
}
