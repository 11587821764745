import { useEffect, useState } from 'react';
import { OrgPilotsFetchStatus } from '~/state/org-pilots/constants';
import { useIsSucceeded } from './is-succeeded.hook';
import { useIsFailed } from './is-failed.hook';

export function usePreviousFetchResult() {
  const isSucceeded = useIsSucceeded();
  const isFailed = useIsFailed();
  const [previousResult, updatePreviousResult] = useState(null);

  useEffect(() => {
    const previous = isSucceeded
      ? OrgPilotsFetchStatus.SUCCESS
      : isFailed
        ? OrgPilotsFetchStatus.FAILED
        : null;

    if (previous) {
      updatePreviousResult(previous);
    }
  }, [isSucceeded, isFailed]);

  const previousResultFailed = previousResult === OrgPilotsFetchStatus.FAILED;
  const previousResultSucceeded =
    previousResult === OrgPilotsFetchStatus.SUCCESS;

  return { previousResultFailed, previousResultSucceeded };
}
