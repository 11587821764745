import { useEffect, useState, useRef } from 'react';
import { MapPointCode } from 'argus-data-model/db/schemas/map-points';
import { POINT_MIN_ZOOM } from 'argus-data-model/db/schemas/map-points/map-code-properties';

import hangGlidingIcon from './images/hang-gliding.png';
import parachutingIcon from './images/parachuting.png';

// THIS SHOULD BE REDIRECTED TO EXTERNAL API WHEN AVAILABLE, see: UTM-1540
const url = `${window.env.ARGUS_FIMS_WEB_API_URL}/map/points`;

export function useMapPoints(
  google: any,
  map: google.maps.Map | null,
  zoom?: number | null
) {
  const dataPointsLayer = useRef<google.maps.Data | null>(null);

  useEffect(() => {
    if (map) {
      dataPointsLayer.current = new google.maps.Data({});
    }
  }, [google, map]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(zoom >= POINT_MIN_ZOOM);
  }, [zoom, setVisible]);

  useEffect(() => {
    const layer = dataPointsLayer.current;
    if (layer) {
      if (visible) {
        layer.loadGeoJson(url, {}, (_: google.maps.Data.Feature[]) => {
          layer.setStyle((feature: google.maps.Data.Feature) => {
            return {
              icon: {
                url:
                  feature.getProperty('code') === MapPointCode.HangGliding
                    ? hangGlidingIcon
                    : parachutingIcon,
              },
              title: feature.getProperty('name') as string,
              visible: true,
            };
          });
        });
        layer.setMap(map);
      } else {
        layer.forEach((f) => layer.remove(f));
        layer.setMap(null);
      }
    }
  }, [google, map, visible]);
}
