import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';
import { flightPlanFormReducer } from './flight-plan-form/reducers';
import {
  FlightPlanValidationStatus,
  FlightPlanCreationStatus,
  FlightPlanEditStatus,
  FlightPlanUpdateStatus,
} from './constants';
import { preValidateFlightAreaReducer } from './pre-validate-flight-area/reducers';
import {
  VALIDATION_REQUESTED,
  VALIDATION_SUCCEEDED,
  VALIDATION_FAILED,
  VALIDATION_STATUS_RESET,
  CREATION_REQUESTED,
  CREATION_SUCCEEDED,
  CREATION_FAILED,
  CREATION_STATUS_RESET,
  FAVOURITE_SELECTED,
  FAVOURITE_RESET,
  FAVOURITE_FLIGHT_AREA_RENDERED,
  NEW_FLIGHTAREA_DRAWN,
  FLIGHT_PATH_AREA_SELECTED,
  FLIGHT_PATH_AREA_RESET,
  FLIGHT_AREA_FILE_UPLOADED,
  FLIGHT_AREA_FILE_RESET,
  FLIGHT_PLAN_EDIT_REQUESTED,
  FLIGHT_PLAN_EDIT_RETRIEVED_SUCCESS,
  FLIGHT_PLAN_EDIT_RESET,
  UPDATE_SUCCEEDED,
  UPDATE_REQUESTED,
  UPDATE_STATUS_RESET,
} from './actions';
import { FlightPlanState } from './saga';

export const REDUCER_KEY = 'flightPlan';

export const creationStatusReducer = createReducer(
  {
    [CREATION_REQUESTED]: () =>
      FlightPlanCreationStatus.FLIGHT_PLAN_CREATION_IN_PROGRESS,
    [CREATION_SUCCEEDED]: () => FlightPlanCreationStatus.SUCCESS,
    [CREATION_FAILED]: () => FlightPlanCreationStatus.FAILED,
    [CREATION_STATUS_RESET]: () => FlightPlanCreationStatus.IDLE,
  },
  FlightPlanCreationStatus.IDLE
);

export const validationStatusReducer = createReducer(
  {
    [VALIDATION_REQUESTED]: () =>
      FlightPlanValidationStatus.FLIGHT_PLAN_VALIDATION_IN_PROGRESS,
    [VALIDATION_SUCCEEDED]: () => FlightPlanValidationStatus.SUCCESS,
    [VALIDATION_FAILED]: () => FlightPlanValidationStatus.FAILED,
    [VALIDATION_STATUS_RESET]: () => FlightPlanValidationStatus.IDLE,
  },
  FlightPlanValidationStatus.IDLE
);

export const selectedFavouriteReducer = createReducer(
  {
    [FAVOURITE_SELECTED]: (_: any, { payload }: { payload: any }) => payload,
    // @ts-ignore
    [FAVOURITE_RESET]: () => null,
  },
  null
);

export const selectedFlightPathAreaReducer = createReducer(
  {
    [FLIGHT_PATH_AREA_SELECTED]: (_: any, { payload }: { payload: any }) =>
      payload,
    // @ts-ignore
    [FLIGHT_PATH_AREA_RESET]: () => null,
  },
  null
);

export const uploadedFlightAreaReducer = createReducer(
  {
    [FLIGHT_AREA_FILE_UPLOADED]: (_: any, { payload }: { payload: any }) =>
      payload,
    // @ts-ignore
    [FLIGHT_AREA_FILE_RESET]: () => null,
  },
  null
);

export const isEditingFavouriteFlightAreaReducer = createReducer<boolean>(
  {
    [FAVOURITE_FLIGHT_AREA_RENDERED]: () => true,
    [NEW_FLIGHTAREA_DRAWN]: () => false,
  },
  false
);

export const validationSuccessReducer = createReducer(
  {
    [VALIDATION_SUCCEEDED]: (_: any, { payload }: { payload: any }) => {
      return payload;
    },
    // @ts-ignore
    [VALIDATION_STATUS_RESET]: () => null,
  },
  null
);

export const editFlightPlanReducer = createReducer(
  {
    [FLIGHT_PLAN_EDIT_REQUESTED]: () => FlightPlanEditStatus.REQUESTED,
    [FLIGHT_PLAN_EDIT_RETRIEVED_SUCCESS]: (
      _: any,
      { payload }: { payload: any }
    ) => payload,
    // @ts-ignore
    [FLIGHT_PLAN_EDIT_RESET]: () => null,
  },
  null
);

export const updateStatusReducer = createReducer(
  {
    [UPDATE_REQUESTED]: () =>
      FlightPlanUpdateStatus.FLIGHT_PLAN_UPDATE_IN_PROGRESS,
    [UPDATE_SUCCEEDED]: () => FlightPlanUpdateStatus.SUCCESS,
    [UPDATE_STATUS_RESET]: () => FlightPlanUpdateStatus.IDLE,
  },
  FlightPlanUpdateStatus.IDLE
);

export const flightPlanReducer = combineReducers<FlightPlanState>({
  selectedFavourite: selectedFavouriteReducer,
  isEditingFavouriteFlightArea: isEditingFavouriteFlightAreaReducer,
  creationStatus: creationStatusReducer,
  validationStatus: validationStatusReducer,
  preValidateFlightArea: preValidateFlightAreaReducer,
  validationSuccess: validationSuccessReducer,
  editFlightPlan: editFlightPlanReducer,
  selectedFlightPathArea: selectedFlightPathAreaReducer,
  uploadedFlightArea: uploadedFlightAreaReducer,
  updateStatus: updateStatusReducer,
  ...flightPlanFormReducer,
});
