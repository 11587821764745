import get from 'lodash/get';
import {
  useNavigateTo,
  useLocation,
} from '~/components/shared/hooks/router.hook';
import { DEFAULT_ROUTE } from '~/routes';

export function useCloseDetailPanel() {
  const location = useLocation();
  const navigateTo = useNavigateTo();

  return () => {
    const firstRoute = get(location.pathname.split('/'), '[1]', null);

    if (firstRoute) {
      navigateTo(`/${firstRoute}`);
    } else {
      navigateTo(DEFAULT_ROUTE);
    }
  };
}
