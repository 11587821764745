import React from 'react';
import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/styles';

import { formatStatus } from 'airshare-pilot-web-shared/src/lib/format-status';

import {
  type ComparativeFlightAdvisoryProperties,
  type FeatureStyle,
  FlightDataSource,
  OverlappingEdge,
} from '@airshare/external-api-types';
import { ExpandButton } from '../../expand-button/expand-button';
import { AdvisoryDetailItem } from './advisory-detail-item';
import {
  formatAlt,
  formatDatetime,
  formatOverlappingTypeText,
  getHighlightedStyle,
} from '../flight-advisories.helpers';

const useStyles = makeStyles({
  accordionHeader: {
    marginTop: '0.25rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accordionTitleNearby: {},
  accordionTitleOverlap: {
    color: 'var(--color-highlight-orange)',
  },
  accordionTitleConflict: {
    color: 'var(--color-highlight-red)',
  },
  accordionTitleBold: {
    fontWeight: 'bold',
  },
  expandButtonBlack: {
    color: '#333',
  },
  advisoryDetails: {
    paddingLeft: '0.5rem',
    paddingRight: '0.75rem',
    paddingBottom: '0.25rem',
  },
  advisoryDetailItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const FlightAdvisoryAccordion = ({
  advisory,
  highlightFlight,
  isExpanded,
}: {
  advisory?: ComparativeFlightAdvisoryProperties;
  highlightFlight: (
    flightId: number,
    segmentId: string | undefined,
    style: FeatureStyle
  ) => void;
  isExpanded: boolean;
}): JSX.Element | null => {
  const classes = useStyles();

  if (!advisory) {
    return null;
  }

  const {
    flightId,
    segmentId,
    status,
    activity,
    dataSource,
    startDateTime,
    endDateTime,
    minAltitudeFeet,
    maxAltitudeFeet,
    minAltitudeRef,
    maxAltitudeRef,
    acType,
    acModel,
    numAircraft,
    speed,
    comment,
    isOverlapping,
    isConflicting,
    pilotName,
    pilotPhoneNumber,
    pilotEmail,
    orgName,
    overlapDetails,
  } = advisory;

  const flightLabel = `#${flightId}` + (segmentId ? `-${segmentId}` : '');

  return (
    <>
      <div
        className={classes.accordionHeader}
        data-testid={`pilot-web:shared:flight-advisory-accordian:${flightId}-heading`}
        onClick={() => {
          highlightFlight(
            flightId,
            segmentId,
            isExpanded ? undefined : getHighlightedStyle()
          );
        }}
      >
        {!isConflicting && !isOverlapping && (
          <Typography
            className={`${classes.accordionTitleNearby}${
              isExpanded ? ` ${classes.accordionTitleBold}` : ''
            }`}
            variant="subtitle1"
          >
            {`Nearby flight ${
              acType && acType !== 'Drone' ? `- ${acType}` : '- UA'
            }`}
          </Typography>
        )}
        {!isConflicting && isOverlapping && (
          <Typography
            className={`${classes.accordionTitleOverlap}${
              isExpanded ? ` ${classes.accordionTitleBold}` : ''
            }`}
            variant="subtitle1"
          >
            {`Overlapping flight ${
              acType && acType !== 'Drone' ? `- ${acType}` : '- UA'
            }`}
          </Typography>
        )}
        {isConflicting && (
          <Typography
            className={`${classes.accordionTitleConflict}${
              isExpanded ? ` ${classes.accordionTitleBold}` : ''
            }`}
            variant="subtitle1"
          >
            {`Conflicting flight ${
              acType && acType !== 'Drone' ? `- ${acType}` : '- UA'
            }`}
          </Typography>
        )}
        <ExpandButton
          className={isExpanded ? classes.expandButtonBlack : ''}
          isExpanded={isExpanded}
        />
      </div>

      <Collapse
        in={isExpanded}
        data-testid={`pilot-web:shared:flight-advisory-accordian:${flightId}-details`}
      >
        <ul className={classes.advisoryDetails}>
          {orgName && (
            <AdvisoryDetailItem
              label="Organisation:"
              testId="org-name"
              value={orgName}
            />
          )}
          {pilotName && (
            <AdvisoryDetailItem
              label="Pilot:"
              testId="pilot-name"
              value={pilotName}
            />
          )}
          {dataSource === FlightDataSource.Airshare &&
          !pilotPhoneNumber &&
          !pilotEmail ? (
            <Typography variant="body2">No contact info</Typography>
          ) : null}
          {pilotPhoneNumber && (
            <AdvisoryDetailItem
              label="Phone Number:"
              testId="pilot-number"
              value={pilotPhoneNumber}
            />
          )}
          {pilotEmail && (
            <AdvisoryDetailItem
              label="Email:"
              testId="pilot-email"
              value={pilotEmail}
            />
          )}
          <AdvisoryDetailItem
            label="Flight ID:"
            testId="flight-id"
            value={flightLabel}
          />
          {formatOverlappingTypeText(overlapDetails?.areaOverlap) && (
            <AdvisoryDetailItem
              label="Area Overlap:"
              testId="area-overlap"
              value={formatOverlappingTypeText(overlapDetails.areaOverlap)}
              overlappingDetails={overlapDetails?.areaOverlap}
              edge={OverlappingEdge.BOTH}
            />
          )}
          {status === 'Activated' && (
            <AdvisoryDetailItem
              label="Current Status:"
              testId="status"
              value={formatStatus(status)}
            />
          )}
          <AdvisoryDetailItem
            label="Starts At:"
            testId="start-date-time"
            value={formatDatetime(startDateTime)}
            overlappingDetails={overlapDetails?.timeOverlap}
            edge={OverlappingEdge.START}
          />
          <AdvisoryDetailItem
            label="Ends At:"
            testId="end-date-time"
            value={formatDatetime(endDateTime)}
            overlappingDetails={overlapDetails?.timeOverlap}
            edge={OverlappingEdge.END}
          />
          {acModel && (
            <AdvisoryDetailItem
              label="Model:"
              testId="ac-model"
              value={acModel}
            />
          )}
          {activity && (
            <AdvisoryDetailItem
              label="Flight Purpose:"
              testId="flight-purpose"
              value={activity}
            />
          )}
          {numAircraft !== undefined && (
            <AdvisoryDetailItem
              label="Number of Aircraft:"
              testId="num-aircraft"
              value={`${numAircraft}`}
            />
          )}
          <AdvisoryDetailItem
            label="Lower Alt:"
            testId="min-altitude"
            value={formatAlt(minAltitudeFeet, minAltitudeRef)}
            overlappingDetails={overlapDetails?.altitudeOverlap}
            edge={OverlappingEdge.START}
          />
          <AdvisoryDetailItem
            label="Upper Alt:"
            testId="max-altitude"
            value={formatAlt(maxAltitudeFeet, maxAltitudeRef)}
            overlappingDetails={overlapDetails?.altitudeOverlap}
            edge={OverlappingEdge.END}
          />
          {speed !== undefined && (
            <AdvisoryDetailItem
              label="Speed:"
              testId="speed"
              value={`${speed}`}
            />
          )}
          {comment && (
            <AdvisoryDetailItem
              label="Comment:"
              testId="comment"
              value={comment}
            />
          )}
        </ul>
      </Collapse>
    </>
  );
};
