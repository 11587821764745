import React, { useState } from 'react';
import {
  Button,
  Snackbar,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MyLocation } from '@material-ui/icons';
import type { StyledFlightAdvisory } from '@airshare/pilot-types';

import { useNavigateTo } from '../../shared/hooks/router.hook';
import { getFlightBriefingPath } from '../../../routes';
import { useHighlightedFlightAdvisoriesUpdated } from '../../../state/flight-advisories/hooks';
import { getHighlightedStyle } from '../flight-advisories/flight-advisories.helpers';
import { acknowledgeMessage } from '../../../pilot-api-client';

interface Props {
  messageId: string;
  activatedFlightId: number;
  overlappingFlightIds: number[];
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    alert: {
      '& .MuiAlert-message': {
        padding: 0,
      },
    },
  })
);

export const Notification = ({
  messageId,
  activatedFlightId,
  overlappingFlightIds,
}: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const navigateTo = useNavigateTo();
  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();

  const handleClose = (
    _?: React.SyntheticEvent<any, Event>,
    _reason?: string
  ) => {
    acknowledgeMessage(messageId, activatedFlightId);

    setOpen(false);
  };

  const navigateToFlight = () => {
    navigateTo(getFlightBriefingPath(activatedFlightId.toString()));
    const styledAdvs: StyledFlightAdvisory[] = overlappingFlightIds.map(
      (flightId) => ({
        flightId,
        segmentId: undefined,
        style: getHighlightedStyle(),
      })
    );
    setHighlightedNearbyFlights(styledAdvs);

    handleClose();
  };

  return (
    <Snackbar
      id={`activated-notification-${activatedFlightId}-snackbar`}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      data-testid={`activated-notification-${activatedFlightId}-snackbar`}
      key={`activated-notification-${activatedFlightId}`}
    >
      <Alert
        id={`activated-notification-${activatedFlightId}-alert`}
        data-testid={`activated-notification-${activatedFlightId}-alert`}
        onClose={handleClose}
        icon={false}
        severity="warning"
        elevation={6}
        variant="filled"
        className={classes.alert}
      >
        <Button
          color="inherit"
          data-testid={`activated-notification-${activatedFlightId}-alert-button`}
          style={{ textTransform: 'none' }}
          onClick={navigateToFlight}
          endIcon={<MyLocation />}
        >
          Another flight just activated nearby
        </Button>
      </Alert>
    </Snackbar>
  );
};
