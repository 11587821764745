import { call, put, takeEvery } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { AxiosResponse } from 'axios';

import { PreValidateFlightAreaResponse } from '@airshare/pilot-types';

import { pilotAPI } from '../../../pilot-api-client/api';
import { FLIGHT_PLAN_PATH } from '~/routes';
import {
  PRE_VALIDATE_FLIGHT_AREA_REQUESTED,
  preValidateFlightAreaSucceeded,
  preValidateFlightAreaFailed,
  preValidateFlightAreaReset,
  PreValidateFlightAreaSucessPayload,
  PreValidateFlightAreaPayloadFailed,
} from './actions';
import { PreValidateFlightAreaStatus } from './constants';
import { ProcessedPayload } from './hooks';

export interface PreValidateFlightAreaState {
  flightAreaValidation:
    | PreValidateFlightAreaSucessPayload
    | PreValidateFlightAreaPayloadFailed
    | null;
  validationStatus: PreValidateFlightAreaStatus;
}

function* preValidateFlightArea({ payload }: { payload: ProcessedPayload }) {
  try {
    const response: AxiosResponse<PreValidateFlightAreaResponse> = yield call(
      pilotAPI.post,
      '/pre-validate-flight-area',
      {
        flightArea: payload,
      }
    );
    yield put(preValidateFlightAreaSucceeded(response));
  } catch (error) {
    const { response } = error;

    if (!response?.data || response.status === 500) {
      yield put(
        preValidateFlightAreaFailed({
          message: 'There was an error validating the selected flight area',
        })
      );
      return;
    }

    const { data: errorData } = response;
    const message = errorData.noCertificationNumber || errorData.diameterLimit;

    if (message) {
      yield put(preValidateFlightAreaFailed({ message }));
    } else if (errorData.controlZoneIntersection) {
      // ! FIXME: This is a hack in lieu of the endpoint returning
      // ! 200 for controlZoneIntersections alone.
      yield put(preValidateFlightAreaSucceeded(errorData));
    }
  }
}

function* clearFlightAreaValidation({
  payload: { location },
}: {
  payload: { location: any };
}) {
  try {
    const pathMatch = matchPath(location.pathname, {
      path: FLIGHT_PLAN_PATH,
      exact: true,
    });

    const isViewingFlightPlanForm = pathMatch?.isExact;

    if (!isViewingFlightPlanForm) {
      yield put(preValidateFlightAreaReset());
    }
  } catch (e) {
    //
  }
}

export function* preValidateFlightAreaSaga() {
  yield takeEvery(
    PRE_VALIDATE_FLIGHT_AREA_REQUESTED as any,
    preValidateFlightArea
  );
  yield takeEvery(LOCATION_CHANGE as any, clearFlightAreaValidation);
}
