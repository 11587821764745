import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useIsLoading } from './hooks/is-loading.hook';
import { useIsFailed } from './hooks/is-failed.hook';
import { useIsSucceeded } from './hooks/is-succeeded.hook';
import { usePreviousFetchResult } from './hooks/previous-status.hook';
import { getOrgPilotDetailPath } from '../../../../routes';
import {
  useOrgPilots,
  useFetchOrgPilots,
} from '../../../../state/org-pilots/hooks';
import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';

import './org-pilots.scss';

export function OrgPilots() {
  const orgPilots: { id: string; name: string }[] = useOrgPilots();
  const fetchOrgPilots = useFetchOrgPilots();
  const isLoading = useIsLoading();
  const isFailed = useIsFailed();
  const isSucceeded = useIsSucceeded();

  const { previousResultFailed, previousResultSucceeded } =
    usePreviousFetchResult();

  function handleRetry() {
    fetchOrgPilots();
  }

  return (
    <LoadingPanel isLoading={isLoading}>
      <div
        className="org-pilots-panel"
        data-testid="pilot-web:org-pilots-panel"
      >
        <Typography
          className="heading"
          variant="h6"
          component="h1"
          align="center"
        >
          Pilots
        </Typography>

        {(isFailed || previousResultFailed) && (
          <>
            <Typography
              className="failure-message"
              variant="h6"
              component="h2"
              align="center"
            >
              We failed to retrieve your pilots
            </Typography>
            <Typography
              className="failure-action"
              variant="body1"
              component="p"
              align="center"
            >
              Please click{' '}
              <button
                type="button"
                className="retry-link"
                onClick={handleRetry}
              >
                here
              </button>{' '}
              to retry
            </Typography>
          </>
        )}

        {(isSucceeded || previousResultSucceeded) &&
          orgPilots &&
          orgPilots.length === 0 && (
            <Typography className="no-data" variant="subtitle1">
              You have no pilots
            </Typography>
          )}

        {orgPilots &&
          orgPilots.length > 0 &&
          orgPilots.map(({ id, name }) => (
            <Link
              to={getOrgPilotDetailPath(id)}
              className="pilot card"
              key={id}
            >
              <i className="icon" />
              <Typography className="description">{name}</Typography>
            </Link>
          ))}
      </div>
    </LoadingPanel>
  );
}
