import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import Downshift from 'downshift';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import './search.scss';

const useTextFieldStyles = makeStyles({
  root: {
    backgroundColor: 'var(--color-true-white)',
    borderRadius: 'var(--radius-l)',
    boxShadow: 'var(--shadow-primary)',
    padding: '0.1rem 0.1rem 0.1rem 1rem',
  },
});

const usePaperStyles = makeStyles({
  root: {
    borderRadius: 'var(--radius-l)',
    boxShadow: 'var(--shadow-primary)',
  },
});

const useMenuItemStyles = makeStyles({
  root: {
    backgroundColor: 'transparent !important',
  },
});

interface SearchProps {
  google: any;
  placesService: any;
  onChange(placeSelection: PlaceSelection, callback?: () => void): void;
  className: string;
  resetSearchInput: boolean;
  onResetInput: () => void;
  onOpened: (opened: boolean) => void;
  mapCenter: google.maps.LatLng;
}

export interface PlaceSelection {
  geometry: {
    location: google.maps.LatLng;
  };
}

// Component
export default function Search({
  google,
  placesService,
  onChange,
  className,
  resetSearchInput,
  onResetInput,
  onOpened,
  mapCenter,
}: SearchProps) {
  const [suggestions, updateSuggestions] = useState([]);
  const paperClasses = usePaperStyles({});
  const menuItemClasses = useMenuItemStyles({});
  const textFieldClasses = useTextFieldStyles({});
  const resetRef = useRef<(emit: boolean) => void>();
  const isOpenRef = useRef<boolean>(false);

  useEffect(() => {
    if (resetRef.current) {
      resetRef.current(false);
    }
  }, [resetSearchInput]);

  function handleIsOpenProperty(isOpen: boolean) {
    if (isOpen !== isOpenRef.current) {
      isOpenRef.current = isOpen;
      onOpened(isOpen);
    }
  }

  // Renderers
  function renderInput(inputProps: any) {
    const { InputProps, ref, ...other } = inputProps;

    handleIsOpenProperty(InputProps?.isOpen);

    return (
      <TextField
        className="input"
        classes={textFieldClasses}
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  function renderSuggestion(suggestionProps: any) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } =
      suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem?.name.includes(suggestion.name);

    return (
      <div className="suggestion-container" key={index}>
        <div className="suggestion-aside">
          <div className="suggestion-icon" />
        </div>
        <MenuItem
          {...itemProps}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
          classes={menuItemClasses}
          className="suggestion"
        >
          <Typography variant="subtitle1" display="block">
            {suggestion.name}
          </Typography>
          <Typography variant="caption" component="p" display="block">
            {suggestion.formatted_address}
          </Typography>
        </MenuItem>
      </div>
    );
  }

  // Handlers
  async function handleSelect(selection: any) {
    if (onChange) {
      onChange(selection);
    }
  }

  async function handleChange(query: any) {
    let address = query;
    if (typeof address === 'object') {
      address = address.nativeEvent.data;
    }

    if (!address || address.length < 2) return;

    const request = {
      query: address,
      fields: ['name', 'geometry'],
      location: mapCenter,
    };

    placesService.textSearch(request, (results: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        updateSuggestions(results.slice(0, 5));
      }
    });
  }

  return (
    <div className={cn('search', { [className]: className })}>
      <Downshift
        id="downshift-google-places"
        onChange={handleSelect}
        onInputValueChange={debounce(handleChange, 500)}
        itemToString={(item) => (item ? item.name : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          inputValue,
          reset,
          clearSelection,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: 'Start typing your address...',
          });

          const resetAll = (emit: boolean) => {
            clearSelection();
            reset();
            updateSuggestions([]);
            if (emit) {
              onResetInput();
            }
          };
          resetRef.current = resetAll;

          return (
            <div>
              {renderInput({
                fullWidth: true,
                InputProps: {
                  onBlur,
                  onFocus,
                  onChange: handleChange,
                  disableUnderline: true,
                  isOpen,
                  endAdornment: (
                    <InputAdornment position="start">
                      {inputValue ? (
                        <i
                          className="reset-icon"
                          onClick={() => resetAll(true)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <i className="search-icon" />
                      )}
                    </InputAdornment>
                  ),
                },
                inputProps,
              })}

              <div {...getMenuProps()}>
                {isOpen && suggestions && suggestions.length > 0 ? (
                  <Paper classes={paperClasses} className="suggestions">
                    {suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}
