import get from 'lodash/get';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { accountNotActivateError } from 'argus-api-shared/lib/constants';
import { AirshareUserRole } from 'argus-common/enums';

import { LOG_OUT } from 'airshare-web-utils/redux-helpers';
import ReactGA from 'react-ga';
import { pilotAPI, contentAPI } from '../../pilot-api-client/api';
import {
  ATTEMPT_LOG_IN,
  ATTEMPT_REGISTRATION,
  ATTEMPT_ACTIVATION,
  ATTEMPT_RESEND_ACTIVATION_EMAIL,
  COMPLETE_POLICY_ACCEPTANCE,
} from './actions';
import {
  setLoginStatus,
  setRegistrationStatus,
  setActivationStatus,
  setProfile,
  setAccountActive,
  setResendActivationStatus,
  setCompleteLoginData,
  setPoliciesToAccept,
} from './action-creators';
import { actionCreators as registrationFormActionCreators } from './auth-forms/registration/actions';
import { actionCreators as loginFormActionCreators } from './auth-forms/login/actions';
import { actionCreators as activationFormActionCreators } from './auth-forms/activation/actions';
import {
  LogInStatus,
  RegistrationStatus,
  ActivationStatus,
  ResendActivationEmailStatus,
} from './constants';

const origin = 'pilot-web';

export function* sessionSaga() {
  // Listen for actions
  yield takeLatest(ATTEMPT_LOG_IN as any, onAttemptLogIn);
  yield takeLatest(
    COMPLETE_POLICY_ACCEPTANCE as any,
    onCompletePolicyAcceptance
  );
  yield takeLatest(ATTEMPT_REGISTRATION as any, onAttempRegistration);
  yield takeLatest(ATTEMPT_ACTIVATION as any, onAttemptActivation);
  yield takeLatest(
    ATTEMPT_RESEND_ACTIVATION_EMAIL as any,
    resendActivationEmail
  );

  yield takeEvery(LOG_OUT, onLogOut);

  // If there is a profile in local storage, get
  // it and put it into the store
  //@ts-ignore
  const profileJson = yield call([localStorage, 'getItem'], 'profile');

  if (profileJson) {
    const profile = JSON.parse(profileJson);
    yield call(onSetProfile, profile);
    yield put(setProfile(profile));
  }
}

function* onCompletePolicyAcceptance({
  payload: {
    completeLoginData: { profile, refreshToken },
    acceptedPolicies,
  },
}: {
  payload: {
    completeLoginData: { profile: any; refreshToken: string };
    acceptedPolicies: any[];
  };
}) {
  yield call(pilotAPI.put, 'end-user-policies-accepted', acceptedPolicies, {
    headers: {
      Authorization: `Bearer ${profile.token}`,
    },
  });

  // Complete the rest of the login
  yield call(onSetProfile, profile, refreshToken);
  yield put(setProfile(profile));
  yield put(setLoginStatus(LogInStatus.SUCCESS));
  yield put(setAccountActive(true));
  yield put(setCompleteLoginData(null));
}

function* onAttemptLogIn({
  payload: { email, password, recaptchaToken },
}: {
  payload: { email?: string; password?: string; recaptchaToken?: string };
}) {
  // ? Map form fields to the api fields
  const loginForm = {
    userName: email,
    password,
    origin: 'pilot-web',
    recaptchaToken,
  };
  try {
    yield put(setLoginStatus(LogInStatus.LOG_IN_ATTEMPT_IN_PROGRESS));
    const { data } = yield call(pilotAPI.post, '/v2/log-in', loginForm);
    const { profile, refreshToken } = data;

    if (
      profile.roles.some((r: string) =>
        [
          AirshareUserRole.PILOT,
          AirshareUserRole.ORG_ADMIN,
          AirshareUserRole.SUPER_ADMIN,
        ].includes(r as AirshareUserRole)
      )
    ) {
      // Need to pass the token through as haven't set on the main API yet
      const { data: policiesToAccept } = yield call(
        pilotAPI.get,
        'end-user-policies-required',
        {
          headers: {
            Authorization: `Bearer ${profile.token}`,
          },
        }
      );

      if (policiesToAccept && policiesToAccept.length > 0) {
        // There are policies that the user needs to accept
        policiesToAccept.forEach((policy: any) => {
          policy.accepted = false;
        });

        yield put(setCompleteLoginData({ profile, refreshToken }));
        yield put(setLoginStatus(LogInStatus.POLICY_ACCEPTANCE));
        yield put(setPoliciesToAccept(policiesToAccept));
      } else {
        // User has no policies to accept. Therefore straight through
        yield call(onSetProfile, profile, refreshToken);
        yield put(setProfile(profile));
        yield put(setLoginStatus(LogInStatus.SUCCESS));
        yield put(setAccountActive(true));
      }
    } else {
      yield put(setLoginStatus(LogInStatus.FAILED));
      yield put(
        loginFormActionCreators.formErrored({
          message:
            "Your account type doesn't have access, please register or contact the system administrators",
        })
      );
    }
  } catch (error) {
    yield put(setLoginStatus(LogInStatus.FAILED));

    const errors = get(error, 'response.data', {});

    // ? Map api fields back to the form fields
    const formErrors = {
      email: errors.userName,
      password: errors.password,
      message: errors.message,
    };

    if (errors.code === accountNotActivateError.code) {
      yield put(setAccountActive(false));
    }

    // if error code == invalid username password
    yield put(loginFormActionCreators.formErrored(formErrors));
  }
}

function* onAttempRegistration({ payload }: { payload: any }) {
  try {
    yield put(
      setRegistrationStatus(RegistrationStatus.REGISTRATION_ATTEMPT_IN_PROGRESS)
    );

    yield call(pilotAPI.post, '/v2/user/register', payload);

    ReactGA.event({
      category: 'Registration',
      action: 'RegistrationRequested',
    });

    yield put(setRegistrationStatus(RegistrationStatus.SUCCESS));
  } catch (error) {
    yield put(setRegistrationStatus(RegistrationStatus.FAILED));

    const errors = get(error, 'response.data', {});

    yield put(registrationFormActionCreators.formErrored(errors));
  }
}

function* onAttemptActivation({ payload }: { payload: any }) {
  try {
    yield put(
      setActivationStatus(ActivationStatus.ACTIVATION_ATTEMPT_IN_PROGRESS)
    );

    yield call(pilotAPI.post, '/v1/user/activate', payload);

    yield put(setActivationStatus(ActivationStatus.SUCCESS));

    ReactGA.event({
      category: 'Registration',
      action: 'ActivationSuccess',
    });

    yield put(setAccountActive(true));
  } catch (error) {
    yield put(setActivationStatus(ActivationStatus.FAILED));

    yield put(
      activationFormActionCreators.formErrored({
        message: 'Activation failed, please try again.',
      })
    );
  }
}

function* onSetProfile(profile: any, refreshToken?: string) {
  yield call([localStorage, 'setItem'], 'profile', JSON.stringify(profile));
  if (refreshToken) {
    yield call([localStorage, 'setItem'], 'refreshToken', refreshToken);
  }
  yield call(contentAPI.setAuthHeader, profile.token);
  yield call(pilotAPI.setAuthHeader, profile.token);
}

function* onLogOut() {
  const refreshToken: string = yield call(
    [localStorage, 'getItem'],
    'refreshToken'
  );

  yield call(pilotAPI.post, '/v2/log-out', { origin, refreshToken });

  yield call([localStorage, 'clear']);
  yield call(pilotAPI.clearAuthHeader);
  yield call(contentAPI.clearAuthHeader);
}

function* resendActivationEmail({
  payload: { email },
}: {
  payload: { email?: string };
}) {
  try {
    yield put(
      setResendActivationStatus(
        ResendActivationEmailStatus.RESEND_ACTIVATION_ATTEMPT_IN_PROGRESS
      )
    );

    ReactGA.event({
      category: 'Registration',
      action: 'ActivationEmailResend',
    });

    yield call(pilotAPI.post, '/user/register/resendcode', { email });

    yield put(setResendActivationStatus(ResendActivationEmailStatus.SUCCESS));
  } catch (error) {
    yield put(setResendActivationStatus(ResendActivationEmailStatus.FAILED));
  }
}
