import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import {
  ExtendedFlightResponseBodyV2,
  PilotOnlyStatus,
} from '@airshare/pilot-types';

import { ExpandButton } from '../../../shared/expand-button/expand-button';
import { NearbyFlightsAccordion } from '../../../shared/flight-advisories/nearby-flights-accordion';
import { SegmentsAccordion } from '../../../shared/segments/segments-accordion';

import { ImportantInfo } from './important-info';
import AuthoriserList from './authorisers-list';
import { getPilotFilteredStatuses } from './important-info-section.lib';

export default function ImportantInfoSection({
  flight,
  atcHasDigitalAuthEnabled,
}: {
  flight?: ExtendedFlightResponseBodyV2;
  atcHasDigitalAuthEnabled: boolean;
}) {
  const [showAuthorisersState, updateShowAuthorisersState] = useState(true);
  const [showDisclaimers, updateShowDisclaimers] = useState(true);
  const filteredStatuses: PilotOnlyStatus[] = getPilotFilteredStatuses(flight);

  return (
    <>
      {filteredStatuses?.length ? (
        <div className="important-info-section">
          <div
            className="collapsible-section-heading"
            data-testid="pilot-web:flight-briefing-panel:authorisers-section"
          >
            <Typography className="sub-heading" variant="subtitle1">
              Authorisers
            </Typography>
            <ExpandButton
              isExpanded={showAuthorisersState}
              onClick={() =>
                updateShowAuthorisersState(
                  (prevShowDisclaimers) => !prevShowDisclaimers
                )
              }
            />
          </div>
          <Collapse in={showAuthorisersState} className="important-info">
            <AuthoriserList statuses={filteredStatuses} />
          </Collapse>
        </div>
      ) : null}

      <SegmentsAccordion
        segmentFeatures={flight?.properties?.segmentCollection?.features}
        timezone={flight?.properties?.timezone}
        isReadOnly={true}
        waypoints={flight?.properties?.waypoints}
      />

      <NearbyFlightsAccordion />

      <div className="important-info-section">
        <div
          className="collapsible-section-heading"
          data-testid="pilot-web:flight-briefing-panel:importal-info-section"
        >
          <Typography className="sub-heading" variant="subtitle1">
            Important Information
          </Typography>
          <ExpandButton
            isExpanded={showDisclaimers}
            onClick={() =>
              updateShowDisclaimers(
                (prevShowDisclaimers) => !prevShowDisclaimers
              )
            }
          />
        </div>
        <Collapse in={showDisclaimers} className="important-info">
          {ImportantInfo({ flight, atcHasDigitalAuthEnabled })}
        </Collapse>
      </div>
    </>
  );
}
