import { useSelector } from 'react-redux';
import { useFocussedFlightRequestFetchStatus } from '~/state/flight-requests/hooks';
import { FlightRequestsFetchFocussedStatus } from '~/state/flight-requests/constants';
import { getErrorMessage } from '~/state/flight-requests/selectors';

export function useHasError() {
  const flightRequestsFetchFocussedStatus =
    useFocussedFlightRequestFetchStatus();
  const errorMessage = useSelector(getErrorMessage);

  return {
    hasError:
      flightRequestsFetchFocussedStatus ===
      FlightRequestsFetchFocussedStatus.FAILED,
    errorMessage,
  };
}
