import type { FeatureCollection, Feature, Polygon, LineString } from 'geojson';
import { DateTime } from 'luxon';
import buffer from '@turf/buffer';
import length from '@turf/length';
import distance from '@turf/distance';
import { lineString } from '@turf/helpers';

import {
  AltitudeType,
  SegmentClearanceStatusCode,
  SegmentStatusCode,
  type SegmentFeatureCollectionJSONView,
  type StyledFlightSegmentPropertiesJSONView,
  type FeatureStyle,
  type FlightSegmentPropertiesJSONView,
  StyledSegmentFeatureCollectionJSONView,
  FlightSegmentPropertiesRequest,
  getSegmentId,
} from '@airshare/external-api-types';

import type { FlightPlanForm } from '../../../state/flight-plan/flight-plan-form/types';

const highlightColor = () =>
  getComputedStyle(document.documentElement)?.getPropertyValue(
    '--color-highlight-orange'
  ) || '#f57c00';

export const DEFAULT_SEGMENT_STYLE: FeatureStyle = {
  fillColor: '#2f2f2f',
  fillOpacity: 0.3,
  strokeColor: '#2f2f2f',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  clickable: false,
  editable: false,
  zIndex: 2,
};

export interface LocalSegmentDetailItem {
  id: string;
  startDateTime: string;
  endDateTime: string;
  minAltitudeFeet: number;
  maxAltitudeFeet: number;
  distanceMeters?: number;
}

export interface SegmentValidationDetailItem {
  id: string;
  messages: string[];
  // Failed validation checks
  minAltitudeFeet: boolean;
  maxAltitudeFeet: boolean;
  startDateTime: boolean;
  endDateTime: boolean;
}
export interface SegmentValidationResults {
  anyValidationFailed: boolean;
  segments: SegmentValidationDetailItem[];
}

export const getSegmentAltitudeRangeLabel = (
  minAltitudeFeet: number,
  maxAltitudeFeet: number
) => {
  return `${minAltitudeFeet?.toLocaleString()}-${maxAltitudeFeet?.toLocaleString()} ft`;
};

export const getSegmentStatusLabel = (status: SegmentStatusCode) => {
  return status;
};

export const getSegmentClearanceStatusLabel = (
  clearanceStatus: SegmentClearanceStatusCode
) => {
  if (clearanceStatus === SegmentClearanceStatusCode.NOTREQUIRED) {
    return 'Not Required';
  }
  return clearanceStatus;
};

export const getSegmentDetailTimeLabel = (
  dateTime: string,
  timezone: string
) =>
  DateTime.fromISO(dateTime).isValid
    ? DateTime.fromISO(dateTime).setZone(timezone).toFormat('HH:mm ZZZZ')
    : '';

export const getSegmentTitle = (
  segment: Feature<Polygon, StyledFlightSegmentPropertiesJSONView>,
  timezone: string
): string => {
  const time = DateTime.fromISO(segment.properties.startDateTime).isValid
    ? DateTime.fromISO(segment.properties.startDateTime)
        .setZone(timezone)
        .toFormat(' - HH:mm ZZZZ')
    : '';

  const statusText = getSegmentStatusText(segment);

  return `${segment.properties.id}${time}${statusText}`;
};

const getSegmentStatusText = (
  segment: Feature<Polygon, StyledFlightSegmentPropertiesJSONView>
) => {
  if (
    segment.properties.segmentClearanceStatus ===
    SegmentClearanceStatusCode.REQUESTED
  ) {
    return ' - Requested';
  }
  if (segment.properties.segmentStatus === SegmentStatusCode.ACTIVATED) {
    return ` - ${segment.properties.segmentStatus}`;
  }
  return '';
};

export const getSegmentTitleColor = (
  segment: Feature<Polygon, StyledFlightSegmentPropertiesJSONView>
): string | null => {
  if (
    (segment?.properties?.segmentClearanceStatus ===
      SegmentClearanceStatusCode.REQUESTED ||
      segment?.properties?.segmentStatus === SegmentStatusCode.ACTIVATED) &&
    segment?.properties?.fillColor
  ) {
    return segment.properties.fillColor;
  }
  return null;
};

export const getLocalSegmentDetailItems = (
  segmentFeatures: Feature<Polygon, StyledFlightSegmentPropertiesJSONView>[],
  waypoints?: LineString | undefined
): LocalSegmentDetailItem[] => {
  const validWaypoints =
    waypoints?.coordinates?.length > 1 &&
    segmentFeatures?.length > 0 &&
    segmentFeatures?.length === waypoints?.coordinates?.length - 1;

  return segmentFeatures?.map((segment, ix) => {
    return {
      id: segment.properties.id,
      startDateTime: segment.properties.startDateTime,
      endDateTime: segment.properties.endDateTime,
      minAltitudeFeet: segment.properties.minAltitudeFeet,
      maxAltitudeFeet: segment.properties.maxAltitudeFeet,
      distanceMeters: validWaypoints
        ? Math.round(
            distance(waypoints.coordinates[ix], waypoints.coordinates[ix + 1], {
              units: 'meters',
            })
          )
        : null,
    };
  });
};

export const styleFeatureCollection = (
  featureCollection: SegmentFeatureCollectionJSONView,
  highlightedSegments: string[]
): FeatureCollection<Polygon> => {
  const updatedStyle = (segmentId: string) => {
    if (highlightedSegments?.includes(segmentId)) {
      return {
        fillOpacity: 0.8,
        fillColor: highlightColor(),
      };
    }
    return {};
  };

  if (!featureCollection?.features?.length) {
    return null;
  }

  const styledFeatures = featureCollection.features.map((feature) => {
    return {
      ...feature,
      properties: {
        ...feature.properties,
        ...updatedStyle(feature.properties.id),
      },
    };
  });

  return {
    ...featureCollection,
    features: styledFeatures,
  };
};

export const cleanExistingFeatureCollection = (
  featureCollection: SegmentFeatureCollectionJSONView
): StyledSegmentFeatureCollectionJSONView => {
  if (!featureCollection) {
    return null;
  }

  const styledFeatures = featureCollection.features.map((feature) => {
    return {
      ...feature,
      properties: {
        ...feature.properties,
        ...DEFAULT_SEGMENT_STYLE,
        segmentClearanceStatus: undefined,
        segmentStatus: undefined,
        startDateTime: undefined,
        endDateTime: undefined,
      },
    };
  });

  return {
    ...featureCollection,
    features: styledFeatures,
  };
};

export const setFormSegmentCollection = (
  setFlightPlanFormField?: (field: keyof FlightPlanForm, value: any) => void,
  waypoints?: LineString,
  dateAndTime?: DateTime,
  durationMinutes?: number,
  altitudeFeet?: number
): void => {
  if (!waypoints) {
    setFlightPlanFormField('waypoints', null);
    setFlightPlanFormField('segmentCollection', null);
    return;
  }

  if (!dateAndTime || !durationMinutes || !altitudeFeet) {
    setFlightPlanFormField('segmentCollection', null);
    return;
  }

  const bufferMeters = parseInt(process.env.WAYPOINT_BUFFER_METRES) || 100;
  const bufferMinutes = 5;
  const waypointsCount = waypoints.coordinates.length;
  const segmentsCount = waypointsCount - 1;
  const finalLoop = waypointsCount - 2;
  const endDateTime = dateAndTime.plus({ minutes: durationMinutes });

  const totalLengthMeters = length(lineString(waypoints.coordinates), {
    units: 'meters',
  });
  let cummulativePercentOffset = 0;
  let previousEndTime = dateAndTime;

  const features: Feature<Polygon, FlightSegmentPropertiesRequest>[] = [];

  for (let i = 0; i < waypointsCount; i++) {
    if (i > finalLoop) {
      break;
    }

    const polygon = buffer(
      lineString([waypoints.coordinates[i], waypoints.coordinates[i + 1]]),
      bufferMeters,
      {
        units: 'meters',
      }
    )?.geometry;

    const lengthMeters = length(
      lineString([waypoints.coordinates[i], waypoints.coordinates[i + 1]]),
      { units: 'meters' }
    );

    const percent = lengthMeters / totalLengthMeters;

    // First segment: starts at dateAndTime, ends at portional end time + 2X {bufferMinutes}
    // Middle segment(s): start {bufferMinutes} before portional start time, end {bufferMinutes} after portional end time
    // Last segment: starts at portional start time - 2X {bufferMinutes}, ends at endDateTime

    let start =
      i === 0
        ? dateAndTime
        : dateAndTime
            .plus({ minutes: durationMinutes * cummulativePercentOffset })
            .minus({ minutes: bufferMinutes * (i === finalLoop ? 2 : 1) });
    let end =
      i === finalLoop
        ? endDateTime
        : dateAndTime
            .plus({
              minutes: durationMinutes * (cummulativePercentOffset + percent),
            })
            .plus({ minutes: bufferMinutes * (i === 0 ? 2 : 1) });

    // Ensure this segment never ends out of order
    if (end < previousEndTime) {
      end = previousEndTime;
    }

    // Ensure all segments are within the overall flight duration
    if (start < dateAndTime) {
      start = dateAndTime;
    }
    if (end > endDateTime) {
      end = endDateTime;
    }

    cummulativePercentOffset += percent;

    const feature: Feature<Polygon, FlightSegmentPropertiesRequest> = {
      type: 'Feature',
      properties: {
        ...DEFAULT_SEGMENT_STYLE,
        id: getSegmentId(i, segmentsCount),
        minAltitudeFeet: 0,
        maxAltitudeFeet: altitudeFeet,
        startDateTime: start.setZone('UTC').toISO(),
        endDateTime: end.setZone('UTC').toISO(),
        altitudeType: AltitudeType.AGL,
      },
      geometry: polygon,
    };

    previousEndTime = end;

    features.push(feature);
  }

  // Adjust start times to ensure segments are in order
  let previousStartTime = endDateTime;
  for (let i = segmentsCount - 1; i >= 0; i--) {
    const segment = features[i];
    const start = DateTime.fromISO(segment.properties.startDateTime as string);

    if (start > previousStartTime) {
      segment.properties.startDateTime = previousStartTime
        .setZone('UTC')
        .toISO();
    } else {
      previousStartTime = start;
    }
  }

  const featureCollection: any = {
    type: 'FeatureCollection',
    features,
  };

  setFlightPlanFormField('segmentCollection', featureCollection);
};

export const updateFormSegmentCollection = (
  setFlightPlanFormField?: (field: keyof FlightPlanForm, value: any) => void,
  existingSegmentFeatures?: Feature<Polygon, FlightSegmentPropertiesJSONView>[],
  updatedSegmentItems?: LocalSegmentDetailItem[]
): void => {
  if (!updatedSegmentItems) {
    return;
  }

  const features = existingSegmentFeatures?.map((feature) => {
    const updatedSegment = updatedSegmentItems.find(
      (item) => item.id === feature.properties.id
    );

    if (updatedSegment) {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          minAltitudeFeet: updatedSegment.minAltitudeFeet,
          maxAltitudeFeet: updatedSegment.maxAltitudeFeet,
          startDateTime: updatedSegment.startDateTime,
          endDateTime: updatedSegment.endDateTime,
        },
      };
    }

    return feature;
  });

  const featureCollection: any = {
    type: 'FeatureCollection',
    features,
  };

  setFlightPlanFormField('segmentCollection', featureCollection);
};

export const checkAllSegmentsAreValid = (
  segments: {
    id: string;
    minAltitudeFeet: number;
    maxAltitudeFeet: number;
    startDateTime: string;
    endDateTime: string;
  }[]
): SegmentValidationResults => {
  // Start with all segments not failing validation
  let anyValidationFailed = false;
  const resp = segments?.map((segment) => ({
    id: segment.id,
    minAltitudeFeet: false,
    maxAltitudeFeet: false,
    startDateTime: false,
    endDateTime: false,
    messages: [],
  }));

  if (segments?.length > 1) {
    for (let i = 0; i < segments.length; i++) {
      const start = DateTime.fromISO(segments[i].startDateTime);
      const end = DateTime.fromISO(segments[i].endDateTime);
      if (!start.isValid || !end.isValid) {
        resp[i].startDateTime = true;
        resp[i].endDateTime = true;
        resp[i].messages.push('Invalid date time format.');
        anyValidationFailed = true;
      }
      if (start >= end) {
        resp[i].startDateTime = true;
        resp[i].endDateTime = true;
        resp[i].messages.push('This segment ends before it starts.');
        anyValidationFailed = true;
      }

      if (segments[i].minAltitudeFeet >= segments[i].maxAltitudeFeet) {
        resp[i].minAltitudeFeet = true;
        resp[i].maxAltitudeFeet = true;
        resp[i].messages.push(
          'Lower altitude is higher than the upper altitude.'
        );
        anyValidationFailed = true;
      }
      if (segments[i].minAltitudeFeet < 0) {
        resp[i].minAltitudeFeet = true;
        resp[i].messages.push('Lower altitude is negative.');
        anyValidationFailed = true;
      }
      if (
        (i === 0 || i === segments.length - 1) &&
        segments[i].minAltitudeFeet !== 0
      ) {
        resp[i].minAltitudeFeet = true;
        resp[i].messages.push(
          'Lower altitude for first and last segment must be 0.'
        );
        anyValidationFailed = true;
      }

      if (i + 1 < segments.length) {
        const nextStart = DateTime.fromISO(segments[i + 1].startDateTime);
        const nextEnd = DateTime.fromISO(segments[i + 1].endDateTime);
        if (start > nextStart) {
          resp[i].startDateTime = true;
          resp[i + 1].startDateTime = true;
          resp[i].messages.push(
            'This segment starts after the next segment starts.'
          );
          anyValidationFailed = true;
        }
        if (nextStart >= end) {
          resp[i + 1].startDateTime = true;
          resp[i].endDateTime = true;
          resp[i].messages.push('Next segment starts after this segment ends.');
          anyValidationFailed = true;
        }
        if (nextEnd < end) {
          resp[i + 1].endDateTime = true;
          resp[i].endDateTime = true;
          resp[i].messages.push('Next segment ends before this segment ends.');
          anyValidationFailed = true;
        }

        if (segments[i].maxAltitudeFeet <= segments[i + 1].minAltitudeFeet) {
          resp[i].maxAltitudeFeet = true;
          resp[i + 1].minAltitudeFeet = true;
          resp[i].messages.push(
            'Upper altitude is required to overlap the lower altitude of the next segment.'
          );
          anyValidationFailed = true;
        }
        if (segments[i].minAltitudeFeet >= segments[i + 1].maxAltitudeFeet) {
          resp[i].minAltitudeFeet = true;
          resp[i + 1].maxAltitudeFeet = true;
          resp[i].messages.push(
            'Lower altitude is required to overlap the upper altitude of the next segment.'
          );
          anyValidationFailed = true;
        }
      }
    }
  }

  return { anyValidationFailed, segments: resp };
};

export const checkAllSegmentsTerminated = (
  featureCollection: SegmentFeatureCollectionJSONView,
  segmentsToActivate: string[] = [],
  segmentsToTerminate: string[] = []
): boolean => {
  if (segmentsToActivate?.length > 0) {
    return false;
  }

  if (featureCollection?.features?.length > 0) {
    let notTerminated = false;

    featureCollection.features.forEach((feature) => {
      if (
        feature.properties.segmentStatus !== SegmentStatusCode.TERMINATED &&
        !segmentsToTerminate?.includes(feature.properties.id)
      ) {
        notTerminated = true;
      }
    });

    return !notTerminated;
  }

  return false;
};
