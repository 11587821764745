/// <reference types="../../../types" />

import ReactDomServer from 'react-dom/server';
import { DateTime } from 'luxon';

import {
  FeatureStyle,
  FlightAdvisoryResponse,
  FlightStatusColorCode,
  OverlappingType,
  getColorForStatus,
  OverlappingEdgeDetails,
} from '@airshare/external-api-types';
import FlightAdvisoryMarkerInfo from './flight-advisory-marker-info';
import {
  MapHelpers,
  aircraftPath,
  aircraftViewBox,
  closeActiveInfoWindows,
  flightAdvisoryToPoints,
  getVisibleMapBounds,
  helicopterPath,
  helicopterViewBox,
  knownDronePath,
  knownDroneViewBox,
  normalizeLatLngLiteral,
  polygonFindCenter,
  unknownDronePath,
  unknownDroneViewBox,
} from 'airshare-pilot-web-shared';

export interface FlightAdvisoryMarker
  extends google.maps.marker.AdvancedMarkerElement {
  infowindow?: google.maps.InfoWindow;
  isOriginalLocation?: boolean;
  isVisible?: boolean;
  originalPosition?: google.maps.LatLngLiteral;
}

export interface FlightAdvisoryMarkersWithFlight {
  flightId: number;
  flight: FlightAdvisoryResponse;
  zoomLevel: number;
  trueMarker: FlightAdvisoryMarker;
  offscreenMarker?: FlightAdvisoryMarker;
}

const warningOrange = () =>
  getComputedStyle(document.documentElement).getPropertyValue(
    '--color-highlight-orange'
  ) || '#f57c00';

export const getHighlightedStyle = () => ({
  fillColor: warningOrange(),
  fillOpacity: 0.7,
  strokeColor: warningOrange(),
  strokeOpacity: 1,
  strokeWeight: 3,
  zIndex: 999999,
});

export const formatAlt = (altInFeet: number, ref?: string): string =>
  // eslint-disable-next-line sonarjs/no-nested-template-literals
  `${altInFeet}ft${ref ? ` ${ref}` : ''}`;

export const formatDatetime = (dateTimeIso: string): string =>
  DateTime.fromISO(dateTimeIso).toFormat('yyyy-MM-dd HH:mm');

export const formatOverlappingTypeText = (
  overlappingDirectionalDetails:
    | OverlappingEdgeDetails
    | { overlap: OverlappingType }
): string => {
  switch (overlappingDirectionalDetails?.overlap) {
    case OverlappingType.OVERLAPPING:
      return 'Overlapping';
    case OverlappingType.NEARBY:
      return 'Nearby';
    case OverlappingType.DISTANT:
      return '';
    default:
      return overlappingDirectionalDetails?.overlap;
  }
};

const getIconColorForMarkerByStatusColorCode = (
  statusColorCode: FlightStatusColorCode
): string => {
  if (statusColorCode === FlightStatusColorCode.Declared) {
    return '#bfbfbf';
  }
  return '#000000';
};

const domParser = new DOMParser();

export function processFlightAdvisoriesMarkers(
  flights: FlightAdvisoryResponse[],
  flightMarkers: FlightAdvisoryMarkersWithFlight[],
  map: google.maps.Map,
  detailPanelIsVisible: boolean,
  setFlightMarkers: (newMarkers: FlightAdvisoryMarkersWithFlight[]) => void
) {
  if (map) {
    const clearMarkers = () => {
      flightMarkers.forEach((m) => {
        m.trueMarker.map = null;
        if (m.offscreenMarker) {
          m.offscreenMarker.map = null;
        }
      });
    };

    if (flights?.length > 0) {
      const effectiveMapBounds = getVisibleMapBounds(map, detailPanelIsVisible);

      const markers: FlightAdvisoryMarkersWithFlight[] = flights
        .map((f) => {
          const { trueMarker, offscreenMarker } = getFlightAdvisoryMarkers(
            map,
            effectiveMapBounds,
            detailPanelIsVisible,
            f
          );
          return {
            flightId: f.properties.flightId,
            trueMarker,
            offscreenMarker,
            flight: f,
            zoomLevel: map.getZoom(),
          };
        })
        .filter((m) => m.trueMarker);

      const removedMarkers = removedFlightAdvisoryMarkers(
        flightMarkers,
        markers
      );

      const updatedMarkers = newFlightAdvisoryMarkers(flightMarkers, markers);

      if (removedMarkers?.length !== 0 || updatedMarkers?.length !== 0) {
        clearMarkers();
        setFlightMarkers(markers);
      }
    } else if (flightMarkers?.length > 0) {
      clearMarkers();
      setFlightMarkers([]);
    }
  }
}

export function getFlightAdvisoryMarkers(
  map: google.maps.Map,
  viewport: google.maps.LatLngBounds,
  detailPanelIsVisible: boolean,
  flight: FlightAdvisoryResponse
): {
  trueMarker?: FlightAdvisoryMarker;
  offscreenMarker?: FlightAdvisoryMarker;
} {
  if (map && viewport) {
    const points = flightAdvisoryToPoints(flight);
    const polygonCentre = polygonFindCenter(points[0]);

    const isVisible = isWithinViewport(
      viewport,
      polygonCentre.lat(),
      polygonCentre.lng()
    );

    const flightColor = getColorForStatus(flight.properties.status);
    const iconColor = getIconColorForMarkerByStatusColorCode(flightColor);

    let viewBox: string = '0 0 100 100';
    let path: string = '';
    let transformWhenFuzzy = '';

    switch (flight.properties.acType.toLowerCase()) {
      case 'drone':
      case 'ua':
        viewBox = knownDroneViewBox;
        path = knownDronePath;
        transformWhenFuzzy = 'scale(0.09 0.09) translate(900 950)';
        break;
      case 'helicopter':
        viewBox = helicopterViewBox;
        path = helicopterPath;
        transformWhenFuzzy = 'scale(0.4 0.4) translate(-155 -130)';
        break;
      case 'twin':
      case 'plane':
        viewBox = aircraftViewBox;
        path = aircraftPath;
        transformWhenFuzzy = 'scale(0.09 0.09) translate(850 900)';
        break;
      default:
        viewBox = unknownDroneViewBox;
        path = unknownDronePath;
        break;
    }

    const iconSvg = `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="913.333" height="840" viewBox="${viewBox}">
  <g fill="${iconColor}">
    <path
      d="${path}" />
  </g>
</svg>
`;

    const glyphImg = domParser.parseFromString(
      iconSvg,
      'image/svg+xml'
    ).documentElement;

    const customPin = new google.maps.marker.PinElement({
      background: flightColor,
      borderColor: 'black',
      scale: 1.0,
      glyph: glyphImg,
    });

    const flightLabel =
      `${flight.properties.flightId}` +
      (flight.properties.segmentId ? `-${flight.properties.segmentId}` : '');

    const trueMarker: FlightAdvisoryMarker =
      new google.maps.marker.AdvancedMarkerElement({
        position: { lat: polygonCentre.lat(), lng: polygonCentre.lng() },
        title: `flight:${flightLabel}`,
        content: customPin.element,
        zIndex: 5,
      });
    trueMarker.isVisible = true;
    trueMarker.isOriginalLocation = true;
    trueMarker.originalPosition = normalizeLatLngLiteral(trueMarker.position);

    if (isVisible) {
      return { trueMarker };
    }

    let newLat = getAdjustedVisibleValue(
      polygonCentre.lat(),
      viewport?.getNorthEast().lat(),
      viewport?.getSouthWest().lat(),
      map.getDiv().offsetHeight ?? 0,
      0
    );
    const adjustedWidth =
      map.getDiv().offsetWidth - (detailPanelIsVisible ? 470 : 0);
    let newLng = getAdjustedVisibleValue(
      polygonCentre.lng(),
      viewport?.getNorthEast().lng(),
      viewport?.getSouthWest().lng(),
      adjustedWidth,
      0
    );

    const busyMapAreaScale = isInBusyMapArea(map, newLat, newLng);

    if (busyMapAreaScale > 0) {
      newLat = getAdjustedVisibleValue(
        polygonCentre.lat(),
        viewport?.getNorthEast().lat(),
        viewport?.getSouthWest().lat(),
        map.getDiv().offsetHeight ?? 0,
        busyMapAreaScale
      );
      newLng = getAdjustedVisibleValue(
        polygonCentre.lng(),
        viewport?.getNorthEast().lng(),
        viewport?.getSouthWest().lng(),
        adjustedWidth,
        busyMapAreaScale
      );
    }

    const offscreenMarker: FlightAdvisoryMarker =
      new google.maps.marker.AdvancedMarkerElement({
        position: { lat: newLat, lng: newLng },
        title: `nearby flight:${flightLabel}`,
        content: getFuzzySvg(iconSvg, iconColor, transformWhenFuzzy),
      });
    offscreenMarker.isVisible = true;
    offscreenMarker.isOriginalLocation = false;
    offscreenMarker.originalPosition = normalizeLatLngLiteral(
      trueMarker.position
    );

    return { trueMarker, offscreenMarker };
  }
  return {};
}

const compareMarkers = (
  originalBundle: FlightAdvisoryMarkersWithFlight,
  newBundle: FlightAdvisoryMarkersWithFlight
): boolean => {
  const o = originalBundle.offscreenMarker ?? originalBundle.trueMarker;
  const n = newBundle.offscreenMarker ?? newBundle.trueMarker;
  const origLatLng = normalizeLatLngLiteral(o.position);
  const newLatLng = normalizeLatLngLiteral(n.position);
  return (
    o.title === n.title &&
    origLatLng.lat === newLatLng.lat &&
    origLatLng.lng === newLatLng.lng &&
    originalBundle.zoomLevel === newBundle.zoomLevel
  );
};

const removedFlightAdvisoryMarkers = (
  originalMarkers: FlightAdvisoryMarkersWithFlight[],
  newMarkers: FlightAdvisoryMarkersWithFlight[]
) =>
  originalMarkers.filter((o) => !newMarkers.find((n) => compareMarkers(o, n)));

const newFlightAdvisoryMarkers = (
  originalMarkers: FlightAdvisoryMarkersWithFlight[],
  newMarkers: FlightAdvisoryMarkersWithFlight[]
) =>
  newMarkers.filter((n) => !originalMarkers.find((o) => compareMarkers(o, n)));

function isWithinViewport(
  viewport: google.maps.LatLngBounds,
  lat: number,
  lng: number
) {
  return viewport ? viewport.contains({ lat, lng }) : false;
}

function isInBusyMapArea(
  map: google.maps.Map,
  lat: number,
  lng: number
): number {
  // sizes included margins
  const searchBoxHeightPx = 64; // 4rem
  const searchBoxWidthPx = 532; // 500 + 2rem
  const controlButtonHeightPx = 272; // 17rem
  const controlButtonWidthPx = 160; // 10rem

  const mapWidthPx = map.getDiv()?.offsetWidth ?? 0;
  const mapHeightPx = map.getDiv()?.offsetHeight ?? 0;

  // use true bounds of map for pixel calculations
  const viewport = map.getBounds();

  const topRight: google.maps.LatLngLiteral = {
    lat: viewport.getNorthEast().lat(),
    lng: viewport.getNorthEast().lng(),
  };
  const topLeft: google.maps.LatLngLiteral = {
    lat: viewport.getNorthEast().lat(),
    lng: viewport.getSouthWest().lng(),
  };
  const bottomRight: google.maps.LatLngLiteral = {
    lat: viewport.getSouthWest().lat(),
    lng: viewport.getNorthEast().lng(),
  };

  const relativeSearchBoxWidthPct = searchBoxWidthPx / mapWidthPx;
  const relativeSearchBoxHeightPct = searchBoxHeightPx / mapHeightPx;

  const relativeControlButtonWidthPct = controlButtonWidthPx / mapWidthPx;
  const relativeControlButtonHeightPct = controlButtonHeightPx / mapHeightPx;

  const searchBoxBottomLeft: google.maps.LatLngLiteral = {
    lat:
      topRight.lat -
      relativeSearchBoxHeightPct * (topRight.lat - bottomRight.lat),
    lng:
      topRight.lng - relativeSearchBoxWidthPct * (topRight.lng - topLeft.lng),
  };

  const isInOrBeyondSearchBox =
    lat >= searchBoxBottomLeft.lat && lng >= searchBoxBottomLeft.lng;

  if (isInOrBeyondSearchBox) {
    return 1;
  }

  const controlButtonsTopLeft: google.maps.LatLngLiteral = {
    lat:
      bottomRight.lat +
      relativeControlButtonHeightPct * (topRight.lat - bottomRight.lat),
    lng:
      bottomRight.lng -
      relativeControlButtonWidthPct * (topRight.lng - topLeft.lng),
  };

  const isInOrBeyondContrllButtonsBox =
    lat <= controlButtonsTopLeft.lat && lng >= controlButtonsTopLeft.lng;

  if (isInOrBeyondContrllButtonsBox) {
    return 2;
  }

  return 0;
}

function getAdjustedVisibleValue(
  valueLatLng: number,
  northOrEastLatLng: number,
  southOrWestLatLng: number,
  totalMapLengthPx: number,
  offsetScale: number
) {
  const offsetPixels = offsetScale === 0 ? 40 : offsetScale === 1 ? 90 : 100;
  const offsetPct = offsetPixels / totalMapLengthPx;
  const totalMapLengthLatLng = northOrEastLatLng - southOrWestLatLng;
  const min = southOrWestLatLng + totalMapLengthLatLng * offsetPct;
  const max = northOrEastLatLng - totalMapLengthLatLng * offsetPct;
  return Math.min(max, Math.max(min, valueLatLng));
}

function getFuzzySvg(
  iconSvg: string,
  existingIconColor: string,
  transform: string
): HTMLElement {
  const clusterColor = '#222222';
  const iconColor = '#ffffff';

  const clusterSVG = `
<svg fill="${clusterColor}" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
  <g transform="${transform}" width="20px" height="20px">
  ${iconSvg.replaceAll(existingIconColor, iconColor)}
  </g>
</svg>`;

  return domParser.parseFromString(clusterSVG, 'image/svg+xml').documentElement;
}

export function addMarkerListeners(
  map: google.maps.Map,
  mapHelpers: MapHelpers | null,
  detailPanelIsVisible: boolean,
  trueMarker: FlightAdvisoryMarker,
  flight: FlightAdvisoryResponse,
  highlightFlight: (
    flightId: number,
    segmentId: string | undefined,
    style: FeatureStyle
  ) => void,
  allFlightsBounds: google.maps.LatLngBounds,
  offscreenMarker?: FlightAdvisoryMarker
): () => void {
  if (map) {
    trueMarker.map = map;

    trueMarker.infowindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true,
    });

    const infoWindowListener = google.maps.event.addListener(
      trueMarker.infowindow,
      'domready',
      () => {
        const markerInfoEl = document.getElementById(
          `flight-advisory-${flight.properties.flightId}-marker-info`
        );
        if (markerInfoEl) {
          markerInfoEl.addEventListener(
            'click',
            () => {
              trueMarker.infowindow?.close();
              closeActiveInfoWindows();
            },
            { passive: true }
          );
        }
      }
    );

    const clickListener = trueMarker.addListener('click', async () => {
      closeActiveInfoWindows();

      const content = ReactDomServer.renderToStaticMarkup(
        FlightAdvisoryMarkerInfo(flight)
      );
      trueMarker.infowindow.setContent(content);
      trueMarker.infowindow.open({
        map,
        anchor: trueMarker,
        shouldFocus: false,
      });
      trueMarker.infowindow.addListener('closeclick', () => {
        trueMarker.infowindow?.close();
        closeActiveInfoWindows();
      });
      window.activeMarker = trueMarker;

      highlightFlight(
        flight.properties.flightId,
        flight.properties.segmentId,
        getHighlightedStyle()
      );
    });

    const offscreenClickListener = offscreenMarker?.addListener(
      'click',
      async () => {
        closeActiveInfoWindows();

        if (allFlightsBounds) {
          mapHelpers.fitToBoundsWithPadding(
            allFlightsBounds,
            detailPanelIsVisible
          );
        } else {
          map.setZoom(map.getZoom() - 1);
        }
      }
    );

    return () => {
      [infoWindowListener, clickListener, offscreenClickListener].map((l) =>
        l?.remove()
      );
      if (offscreenMarker) {
        offscreenMarker.map = null;
      }
      if (trueMarker) {
        trueMarker.map = null;
      }
    };
  }
}
