import {
  getAtcDisplayName,
  useAppConfig,
} from '../../../../../state/app-config';

export function useAtcName() {
  const appConfig = useAppConfig();
  const cfgDisplayName = getAtcDisplayName(appConfig);
  const atcDisplayName =
    cfgDisplayName && cfgDisplayName !== 'undefined'
      ? cfgDisplayName
      : (window as any).env.AIR_TRAFFIC_CONTROL_DISPLAY_NAME ?? 'Airways ATC';
  return atcDisplayName;
}
