import { createSelector } from 'reselect';
import { RootState } from '../store';

const getFlightPurposesState = (state: RootState) =>
  state.flightRequestDefaults.flightPurposes;

export const getFlightPurposes = createSelector(
  [getFlightPurposesState],
  (state) => state
);

const getPilotsState = (state: RootState) => state.flightRequestDefaults.pilots;
export const getPilot = createSelector([getPilotsState], (state) =>
  state ? state[0] : null
);

export const getPilots = createSelector([getPilotsState], (state) => state);

const getUavsState = (state: RootState) => state.flightRequestDefaults.uavs;
export const getUavs = createSelector([getUavsState], (state) => state);

const getPathModesState = (state: RootState) =>
  state.flightRequestDefaults.pathModes;
export const getPathModes = createSelector(
  [getPathModesState],
  (state) => state
);
