import React, { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { type Polygon } from '@turf/helpers';
import centroid from '@turf/centroid';
import debounce from 'lodash/debounce';
import PublishIcon from '@material-ui/icons/Publish';
import {
  createStyles,
  makeStyles,
  IconButton,
  Theme,
  DialogContent,
  Slider,
} from '@material-ui/core';
import { TextTooltip } from 'airshare-pilot-web-shared/src/components/text-tooltip/text-tooltip';
import { getPolygonFromGeojsonOrKmlFile } from 'airshare-web-utils/file-parser';

import { defaultBufferMetres } from '../../../lib/polygon-helpers';

import { useConfirmDialog } from 'airshare-pilot-web-shared';

import {
  AdvisorySource,
  FlightPathModeEnum,
  type LatLng,
} from '@airshare/pilot-types';

import {
  useSelectedFlightPathArea,
  useUploadedFlightArea,
  useUploadFlightArea,
  useResetUploadedFlightArea,
} from '../../../state/flight-plan/hooks';
import { useFormState } from '../../../state/flight-plan/flight-plan-form/hooks';
import { GlobalState } from '../../../state/GlobalState';

import { FLIGHT_PLAN_PATH } from '../../../routes';

import './line-drawing.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadButton: {
      borderRadius: '0.5rem',
      alignItems: 'center',
      marginBottom: '37px',
      position: 'absolute',
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.5)',
      },
    },
    bufferSlider: {
      borderRadius: '0.5rem',
      marginBottom: '110px',
      position: 'absolute',
      bottom: 0,
      width: '200px',
      left: 'calc(50% - 72px)',
      '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.5)',
      },
    },
    changeAreaButton: {
      borderRadius: '0.5rem',
      alignItems: 'center',
      left: 'calc(50% - 140px)',
      marginBottom: '37px',
      position: 'absolute',
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.5)',
      },
    },
  })
);

interface Props {
  allowSegmentedFlights: boolean;
}

const formatSliderLabel = (value: number) => {
  if (value === 1000) {
    return '1km';
  }
  if (value > 1000) {
    return `${(value / 1000).toFixed(1)}km`;
  }
  return `${value}m`;
};

export const AdditionalDrawingButtons = ({
  allowSegmentedFlights,
}: Readonly<Props>) => {
  const classes = useStyles();
  const location = useLocation();
  const flightPathArea = useSelectedFlightPathArea();
  const uploadedFlightArea = useUploadedFlightArea();
  const setUploadedFlightArea = useUploadFlightArea();
  const resetUploadedFlightArea = useResetUploadedFlightArea();
  const form = useFormState();
  const pathMode = useMemo(() => form?.pathMode, [form?.pathMode]);

  const {
    setAdvisoryGeometry,
    segmentedBufferRadius,
    setSegmentedBufferRadius,
  } = useContext(GlobalState);

  const {
    dialog: uploadErrorDialog,
    setShowConfirm: setShowFileUploadError,
    setContentToConfirm: setErrorDialogContent,
  } = useConfirmDialog(
    'File processing failed',
    () => {
      clearUploadedFlightArea();
    },
    () => {},
    { text: 'OK' },
    null
  );

  const onUploadFileSucess = useCallback(
    (polygon: Polygon) => {
      setUploadedFlightArea({ geometry: polygon });
    },
    [setUploadedFlightArea]
  );
  const onUploadFileFail = useCallback(
    (errMsg?: string) => {
      setErrorDialogContent(
        <DialogContent data-testid="pilot-web:file-upload-error-content">
          {errMsg || 'File processing failed'}
        </DialogContent>
      );
      setShowFileUploadError(true);
    },
    [setErrorDialogContent, setShowFileUploadError]
  );
  const clearUploadedFlightArea = useCallback(() => {
    setAdvisoryGeometry(AdvisorySource.None, null);
    resetUploadedFlightArea();
  }, [setAdvisoryGeometry, resetUploadedFlightArea]);

  const {
    dialog: confirmResetUploadDialog,
    setShowConfirm: setShowConfirmResetUpload,
    setContentToConfirm: setConfirmationDialogContent,
  } = useConfirmDialog(
    'Are you sure?',
    () => {
      clearUploadedFlightArea();
    },
    () => {},
    { text: 'Yes' }
  );

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    onSuccess: (polygon: Polygon | null, center: LatLng) => void,
    onFail: (message?: string) => void
  ) => {
    if (!event.target.files.length) return;
    try {
      const polygon = await getPolygonFromGeojsonOrKmlFile(
        event.target.files[0]
      );
      const [lng, lat] = centroid(polygon).geometry.coordinates;
      onSuccess(polygon, { lat, lng });
    } catch (err) {
      onFail(err.message);
    }
  };

  const handleResetUploadedAreaClick = useCallback(() => {
    setConfirmationDialogContent(
      <DialogContent>
        Removing the uploaded flight area will remove it completely from the
        map. You will need to draw a new flight area or upload a file again to
        submit this flight request.
      </DialogContent>
    );
    setShowConfirmResetUpload(true);
  }, [setConfirmationDialogContent, setShowConfirmResetUpload]);

  const showUploadFileButton =
    location?.pathname.endsWith(FLIGHT_PLAN_PATH) &&
    !flightPathArea &&
    !uploadedFlightArea;
  const showChangeAreaButton: boolean =
    !!uploadedFlightArea && location?.pathname.endsWith(FLIGHT_PLAN_PATH);

  return (
    <>
      {showUploadFileButton && (
        <>
          <input
            type="file"
            data-testid="pilot-web:upload-button-file"
            id="upload-button-file"
            accept=".geojson,.json,.kml"
            hidden
            onChange={(e) =>
              handleFileUpload(e, onUploadFileSucess, onUploadFileFail)
            }
          />
          <label
            data-testid="pilot-web:upload-button-file:label"
            htmlFor="upload-button-file"
          >
            <TextTooltip title="Upload geojson or kml">
              <IconButton
                className={classes.uploadButton}
                style={{
                  height: '48px',
                  width: '47px',
                  bottom: '37px',
                  padding: '0',
                  margin: '0',
                  left: allowSegmentedFlights
                    ? 'calc(50% + 80px)'
                    : 'calc(50% + 55px)',
                }}
                component="span"
              >
                <PublishIcon />
              </IconButton>
            </TextTooltip>
          </label>
        </>
      )}
      {allowSegmentedFlights && pathMode === FlightPathModeEnum.SEGMENTED && (
        <Slider
          aria-labelledby="continuous-slider"
          name="durationMinutes"
          defaultValue={defaultBufferMetres()}
          value={segmentedBufferRadius}
          className={classes.bufferSlider}
          min={50}
          max={1000}
          step={5}
          marks={[
            {
              value: 50,
              label: '50m',
            },
            {
              value: 1000,
              label: '1km',
            },
          ]}
          onChange={debounce((_, value: number) => {
            setSegmentedBufferRadius(value);
          }, 200)}
          valueLabelDisplay="on"
          valueLabelFormat={formatSliderLabel}
          data-testid="pilot-web:flight-request:segmented-buffer-slider"
        />
      )}
      {showChangeAreaButton && (
        <IconButton
          className={classes.changeAreaButton}
          component="span"
          onClick={handleResetUploadedAreaClick}
          data-testid="pilot-web:remove-uploaded-flight-area-button"
        >
          Remove uploaded flight area
        </IconButton>
      )}
      {uploadErrorDialog}
      {confirmResetUploadDialog}
    </>
  );
};

export default React.memo(AdditionalDrawingButtons);
