import { createReducer } from 'airshare-web-utils/redux-helpers';
import type { FlightAdvisoryResponse } from '@airshare/external-api-types';

import type { StyledFlightAdvisory } from '@airshare/pilot-types';

import type { RootState } from '../store';

import { HIGHLIGHTS_UPDATED, UPDATED } from './actions';

export const flightAdvisoriesReducer = createReducer(
  {
    [UPDATED]: (
      _state: RootState,
      { payload }: { payload: FlightAdvisoryResponse[] }
    ) => payload,
  },
  []
);

export const highlightedFlightAdvisoriesReducer = createReducer(
  {
    [HIGHLIGHTS_UPDATED]: (
      _state: RootState,
      { payload }: { payload: StyledFlightAdvisory[] }
    ) => payload,
  },
  []
);
