import React, { useState, useEffect, useCallback } from 'react';
import { DateTime } from 'luxon';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { BackButton } from '../../../shared/back-button/back-button';
import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { feetToMeters } from 'airshare-shared-utils/measurements';
import { useMobileDetection, useInterval } from 'airshare-pilot-web-shared';

import {
  useFetchFlightRequestsFocussed,
  useFocussedFlightRequest,
  useFocussedFlightRequestFetchStatus,
} from '../../../../state/flight-requests/hooks';
import { FlightRequestsFetchFocussedStatus } from '../../../../state/flight-requests/constants';
import {
  getActivationEligibility,
  requestDigitalActivation,
} from '../../../../pilot-api-client';
import { Footer } from './footer';

import './clearance.scss';
import { FLIGHT_REQUESTS_PATH } from '~/routes';

export interface Props extends RouteComponentProps<{ flightId: string }> {}

const FLIGHT_CLEARANCE_REQUEST_REFRESH_INTERVAL = 3000;

function Clearance({ match }: Props) {
  const { flightId: flightIdParam } = match.params;
  const isMobile = useMobileDetection();
  const navigateTo = useNavigateTo();
  const [flightRequest, setFlightRequest] =
    useState<ExtendedFlightResponseBodyV2>();
  const fetchFocussedFlight = useFetchFlightRequestsFocussed();
  const focussedFlight = useFocussedFlightRequest();
  const fetchStatus = useFocussedFlightRequestFetchStatus();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [requestMade, setRequestMade] = useState(false);
  const [viewEligibility, setViewEligibility] = useState<boolean>(undefined);

  const updateFlightRequest = useCallback(
    async (updateFlightId?: string) => {
      if (updateFlightId) {
        fetchFocussedFlight({ id: updateFlightId, resetState: false });
      }
    },
    [fetchFocussedFlight]
  );

  useEffect(() => {
    if (focussedFlight?.properties) {
      setFlightRequest(focussedFlight);
    }
  }, [focussedFlight]);

  useEffect(() => {
    const checkDigitalAuthEligibility = async () => {
      try {
        const { eligible } = await getActivationEligibility(flightIdParam);
        setViewEligibility(eligible);
      } catch (err) {
        setViewEligibility(undefined);
      }
    };
    checkDigitalAuthEligibility();
  }, [flightIdParam]);

  useInterval(
    () => updateFlightRequest(flightIdParam),
    FLIGHT_CLEARANCE_REQUEST_REFRESH_INTERVAL
  );
  const backButton = (
    <div style={{ height: '3rem' }}>
      <BackButton
        onClick={() => navigateTo(`${FLIGHT_REQUESTS_PATH}/${flightIdParam}`)}
      />
    </div>
  );

  const heading = (
    <div
      style={{ padding: isMobile ? '30px 16px 15px' : '30px 10px' }}
      className="heading"
    >
      {isMobile && <div className="airshare-brand" />}
      <Typography
        variant="h6"
        component="h1"
        align="center"
        data-testid="digital-clearance-request-page"
      >
        Request Digital Activation
      </Typography>
    </div>
  );

  if (
    ((!flightRequest?.properties || viewEligibility === undefined) &&
      fetchStatus !== FlightRequestsFetchFocussedStatus.FAILED) ||
    ((requestMade || isSubmitting) &&
      !flightRequest?.properties.digitalClearance.status)
  ) {
    return (
      <>
        {backButton}
        {heading}
        <Box style={{ display: 'flex', marginLeft: '40%', marginTop: 100 }}>
          <CircularProgress
            size={60}
            style={{
              color: 'gray',
            }}
          />
        </Box>
      </>
    );
  }
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { status } = await requestDigitalActivation(flightIdParam);
      if (status === 200) {
        setErrors([]);
        setRequestMade(true);
      }
    } catch (err) {
      setErrors(err?.response?.data?.errors || err?.data?.errors || []);
    }
    setIsSubmitting(false);
  };

  if (flightRequest?.properties) {
    const renderListItem = (item: { key: string; value: string }) => (
      <ListItem style={{ display: 'flex' }} key={item.key}>
        <ListItemText
          style={{ margin: 0 }}
          primaryTypographyProps={{ style: { fontWeight: 600, width: 80 } }}
          primary={`${item.key}:`}
          secondary={item.value}
        />
      </ListItem>
    );

    const {
      pilotName,
      maxAltitudeFeet,
      endDateTime,
      startDateTime,
      streetAddress,
      timezone,
    } = flightRequest.properties;
    const startTime = DateTime.fromISO(startDateTime).setZone(timezone);
    const endTime = DateTime.fromISO(endDateTime).setZone(timezone);
    return (
      <>
        {backButton}
        {heading}
        <List
          dense
          style={{ minWidth: 220, marginBottom: isMobile ? 20 : 50 }}
          id={`flight-${flightIdParam}-marker-info`}
        >
          {[
            { key: 'Flight ID', value: flightIdParam },
            { key: 'Pilot', value: pilotName },
            { key: 'Date', value: startTime.toFormat('dd/MM/yyyy') },
            {
              key: 'Time',
              value: `${startTime.toFormat('HH:mm')} - ${endTime.toFormat(
                'HH:mm'
              )}`,
            },
            {
              key: 'Altitude',
              value: `${maxAltitudeFeet} ft (${feetToMeters(
                maxAltitudeFeet
              )} m)`,
            },
            { key: 'Address', value: streetAddress },
          ].map(renderListItem)}
        </List>
        <Footer
          flightRequest={flightRequest}
          requestMade={requestMade}
          handleSubmit={handleSubmit}
          errors={errors}
          isSubmitting={isSubmitting}
          viewEligibility={viewEligibility}
        />
      </>
    );
  }

  return (
    <>
      {backButton}
      {heading}
      <Typography style={{ padding: '30px 10px' }}>
        There was an error accessing digital clearance for this flight. If this
        problem persists, please call the air traffic control tower for
        authorisation before your flight.
      </Typography>
    </>
  );
}

export default withRouter(Clearance);
