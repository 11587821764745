import { take, fork, put, cancel } from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { push } from 'connected-react-router';

import { LOG_OUT } from 'airshare-web-utils/redux-helpers';

import { SET_PROFILE } from './session/actions';

import { flightRequestDefaultsSaga } from './flight-request-defaults/saga';
import { uavDefaultsSaga } from './uav-defaults/saga';
import { mapStylesSaga } from './map-styles/saga';
import { sessionSaga } from './session/saga';
import { profileSaga } from './profile/saga';
import { flightPlanSaga } from './flight-plan/saga';
import { flightRequestsSaga } from './flight-requests/saga';
import { uavsSaga } from './uavs/saga';
import { orgPilotsSaga } from './org-pilots/saga';
import { saga as appConfigSaga } from './app-config';

export default function* rootSaga() {
  // Start the session saga
  yield fork(appConfigSaga);
  yield fork(mapStylesSaga);
  yield fork(sessionSaga);

  // Saga infinite loop that starts and stops
  // other sagas for logged in users.
  while (true) {
    // Wait for the SET_PROFILE action to be dispatched
    const { payload } = yield take(SET_PROFILE);

    if (payload) {
      // Start module tasks
      const tasks: Task[] = [];

      tasks.push(yield fork(profileSaga));
      tasks.push(yield fork(flightRequestDefaultsSaga));
      tasks.push(yield fork(uavDefaultsSaga));
      tasks.push(yield fork(flightPlanSaga));
      tasks.push(yield fork(flightRequestsSaga));
      tasks.push(yield fork(uavsSaga));
      tasks.push(yield fork(orgPilotsSaga));

      // Wait for LOG_OUT action to be dispatched
      yield take(LOG_OUT);

      // User has logged out so cancel module tasks
      yield cancel(tasks);
    }

    // Navigate to the root
    yield put(push('/'));
  }
}
