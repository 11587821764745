/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { LoginForm } from './login-form';
import './login.scss';

interface Props extends RouteComponentProps {
  referrer: string;
  redirectUrl: string;
}

function LoginFormComponent({ redirectUrl, referrer }: Props) {
  return (
    <div className="signin-form">
      <LoginForm redirectUrl={redirectUrl} referrer={referrer} />
      <Link
        to={{
          pathname: `${referrer}/signin/reset-password`,
          state: { referrer, redirectUrl },
        }}
        className="sub-link"
        data-testid="pilot-web:signin-form:forgot-password-link"
      >
        Forgot password?
      </Link>
    </div>
  );
}

export const Login = withRouter(LoginFormComponent);
