import React, { useState, useEffect, useCallback } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { EditOrganisationRequestBodyData } from '@airshare/pilot-types';

import * as pilotApiClient from '../../../../pilot-api-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: '5px',
    },
    textField: {
      marginLeft: 0,
      width: 400,
    },
    checkboxLabel: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    checkboxRow: {
      display: 'flex',
      verticalAlign: 'middle',
      flexDirection: 'row',
    },
    autocompleteBox: {
      width: '500px',
    },
    generateKeyButton: {
      marginTop: theme.spacing(2),
    },
    saveButton: {
      minWidth: 146,
    },
    emailList: {},
    emailItem: {
      padding: '6px',
      border: '1px solid lightgray',
      marginTop: '2px',
      marginRight: '2px',
      display: 'inline-block',
      cursor: 'pointer',
    },
    locatePilotsButton: {
      float: 'right',
      marginTop: '6px',
      marginBottom: '6px',
      paddingTop: '2px',
      paddingBottom: '2px',
    },
    removeButton: {
      float: 'right',
      borderRadius: '9px',
      backgroundColor: '#333',
      height: '18px',
      width: '18px',
      maxWidth: '18px!important',
      minWidth: '18px!important',
      color: '#fff',
      textAlign: 'center',
      top: '-14px',
      left: '9px',
      position: 'relative',
      lineHeight: '1.5rem',
    },
  })
);

interface OrgModalProps {
  closeModal(): any;
  organization: EditOrgWithId;
}

export interface EditOrgWithId extends EditOrganisationRequestBodyData {
  _id: string;
}

function EditOrganisationModal({
  closeModal,
  organization,
}: Readonly<OrgModalProps>) {
  const classes = useStyles();
  const { _id, allowPilotsShareContactInfo, priorityOperation, name } =
    organization;

  const isNew = _id === 'new';

  const [orgAdminEmailValue, setOrgAdminEmailValue] = useState('');
  const [orgAdminEmailInputValue, setOrgAdminEmailInputValue] = useState('');
  const [orgAdminOptions, setOrgAdminOptions] = useState<string[]>([]);
  const [selectedOrgAdmins, setSelectedOrgAdmins] = useState<string[]>([]);

  const [pilotEmailValue, setPilotEmailValue] = useState('');
  const [pilotEmailInputValue, setPilotEmailInputValue] = useState('');
  const [pilotOptions, setPilotOptions] = useState<string[]>([]);
  const [selectedPilots, updateSelectedPilots] = useState<string[]>([]);

  const [updatedName, setUpdatedName] = useState(name);
  const [updatedAllowPilotsShareContactInfo, setAllowPilotsShareContactInfo] =
    useState(allowPilotsShareContactInfo);
  const [isPriorityOperator, setIsPriorityOperator] = useState<boolean>(
    priorityOperation?.priorityLevel !== undefined
  );
  const [priorityLevel, setPriorityLevel] = useState<number>(
    priorityOperation?.priorityLevel || 0
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingByDomain, setIsLoadingByDomain] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (_id !== 'new') {
      updateSelectedPilots(organization.pilots);
      setSelectedOrgAdmins(organization.orgAdmin);
      setAllowPilotsShareContactInfo(organization.allowPilotsShareContactInfo);
    }
  }, [_id, organization]);

  const validateInputs = useCallback(() => {
    if (updatedName) {
      setValidationErrors([]);
      return true;
    } else {
      setValidationErrors(['Name is required']);
      return false;
    }
  }, [updatedName]);

  useEffect(() => {
    validateInputs();
  }, [validateInputs]);

  const handleSubmit = async (_: any): Promise<void> => {
    setIsSubmitting(true);

    const isValid = validateInputs();

    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    const updatedOrg = {
      name: updatedName?.trim(),
      orgAdmin: selectedOrgAdmins,
      pilots: selectedPilots,
      allowPilotsShareContactInfo: updatedAllowPilotsShareContactInfo,
      priorityOperation: isPriorityOperator ? { priorityLevel } : undefined,
    };
    if (isNew) {
      await pilotApiClient.createOrganisation(updatedOrg);
    } else {
      await pilotApiClient.updateOrganisation(_id, updatedOrg);
    }
    setIsSubmitting(false);
    closeModal();
  };

  const onAutocompleteOrgAdminEmails = async (
    _event: any,
    newInputValue: string
  ) => {
    setOrgAdminEmailInputValue(newInputValue);

    if (newInputValue?.length > 2) {
      const response = await pilotApiClient.getEmailAutocomplete(newInputValue);
      const emails = response.map((e: any) => e.email);
      setOrgAdminOptions(emails);
    }
  };

  const onAutocompletePilotEmails = async (
    _event: any,
    newInputValue: string
  ) => {
    setPilotEmailInputValue(newInputValue);

    if (newInputValue?.length > 2) {
      const response = await pilotApiClient.getEmailAutocomplete(newInputValue);
      const emails = response.map((e: any) => e.email);
      setPilotOptions(emails);
    }
  };

  const removeOrgAdminEmail = (email: any) => {
    const remainingEmails = selectedOrgAdmins.filter((e) => e !== email);
    setSelectedOrgAdmins(remainingEmails);
  };

  const removePilotEmail = (email: any) => {
    const remainingEmails = selectedPilots.filter((e) => e !== email);
    updateSelectedPilots(remainingEmails);
  };

  const displayOrgAdmin = () =>
    selectedOrgAdmins.map((email) => (
      <span className={classes.emailItem} key={`orgadmin - ${email}`}>
        <Button
          className={classes.removeButton}
          type="button"
          onClick={() => removeOrgAdminEmail(email)}
          data-testid="pilot-web:settings-panel:manage-org:remove-email-button"
        >
          X
        </Button>
        {email}
      </span>
    ));

  const displayPilots = () =>
    selectedPilots.map((email) => (
      <span className={classes.emailItem} key={`pilot - ${email}`}>
        <Button
          className={classes.removeButton}
          type="button"
          onClick={() => removePilotEmail(email)}
          data-testid="pilot-web:settings-panel:manage-org:remove-pilot-button"
        >
          X
        </Button>
        {email}
      </span>
    ));

  useEffect(() => {
    if (isLoadingByDomain) {
      setIsLoadingByDomain(false);
    }
  }, [selectedPilots, isLoadingByDomain, setIsLoadingByDomain]);

  const locatePilotsByEmail = async () => {
    const domain = selectedOrgAdmins[0]?.split('@')[1];
    if (domain) {
      setIsLoadingByDomain(true);
      const response = await pilotApiClient.getEmailAutocomplete(domain, 1000);
      const emails = response.map((e) => e.email);
      await updateSelectedPilots([...new Set([...selectedPilots, ...emails])]);
    }
  };

  return (
    <Dialog onClose={closeModal} open maxWidth="md">
      <DialogTitle data-testid="pilot-web:settings-panel:manage-org:add-edit-organisation-header">{`${
        isNew ? 'Add' : 'Update'
      } Organisation`}</DialogTitle>
      <br />
      <DialogContent>
        <FormControl className={classes.formControl}>
          <TextField
            type="text"
            label="Organisation Name"
            className={classes.textField}
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value?.trimStart() || '')}
            id="pilot-web:settings-panel:manage-org:name-text-field"
            data-testid="pilot-web:settings-panel:manage-org:name-text-field"
          />
        </FormControl>
        <FormControl className={classes.checkboxRow}>
          <Typography
            variant="subtitle1"
            display="inline"
            className={classes.checkboxLabel}
          >
            Allow pilots to share their contact information?
          </Typography>
          <Checkbox
            name="allowPilotsShareContactInfo"
            size="medium"
            color="primary"
            checked={updatedAllowPilotsShareContactInfo}
            onChange={(_, value) => {
              setAllowPilotsShareContactInfo(value);
            }}
            data-testid="pilot-web:settings-panel:manage-org:allow-pilots-share-contact-info-checkbox"
          />
        </FormControl>
        <FormControl className={classes.checkboxRow}>
          <Typography
            variant="subtitle1"
            display="inline"
            className={classes.checkboxLabel}
          >
            Priority Operator?
          </Typography>
          <Checkbox
            name="isPriorityOperator"
            size="medium"
            color="primary"
            checked={isPriorityOperator}
            onChange={(_, value) => {
              setIsPriorityOperator(value);
            }}
            data-testid="pilot-web:settings-panel:manage-org:priority-operator-checkbox"
          />
        </FormControl>
        <FormControl
          className={classes.checkboxRow}
          disabled={!isPriorityOperator}
        >
          <Typography
            variant="subtitle1"
            display="inline"
            className={classes.checkboxLabel}
          >
            Priority Level&nbsp;&nbsp;
          </Typography>
          <TextField
            name="priorityLevel"
            size="medium"
            color="primary"
            type="number"
            style={{ paddingTop: '10px' }}
            value={priorityLevel}
            disabled={!isPriorityOperator}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPriorityLevel(parseInt(e.target.value, 10));
            }}
            onBlur={() => {
              if (isNaN(priorityLevel)) {
                setPriorityLevel(0);
              }
            }}
            data-testid="pilot-web:settings-panel:manage-org:priority-operator-level"
          />
        </FormControl>
        <div>
          <Typography variant="subtitle1">Org Admin:</Typography>
          <FormControl className={classes.formControl}>
            <Autocomplete
              className={classes.autocompleteBox}
              value={orgAdminEmailValue}
              onChange={(_, newValue) => {
                if (!!newValue && !selectedOrgAdmins?.includes(newValue)) {
                  setSelectedOrgAdmins([...selectedOrgAdmins, newValue]);
                }
                setOrgAdminEmailInputValue('');
                setOrgAdminEmailValue('');
              }}
              inputValue={orgAdminEmailInputValue}
              onInputChange={(event, newInputValue) => {
                onAutocompleteOrgAdminEmails(event, newInputValue);
              }}
              id="pilot-web:settings-panel:manage-org:org-admin-autocomplete-input"
              data-testid="pilot-web:settings-panel:manage-org:org-admin-autocomplete-input"
              options={orgAdminOptions}
              freeSolo={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Org Admin"
                  variant="outlined"
                  data-testid="pilot-web:settings-panel:manage-org:org-admin-text-field"
                />
              )}
            />
          </FormControl>
          <div
            className={classes.emailList}
            data-testid="pilot-web:settings-panel:manage-org:org-admin-list"
          >
            {displayOrgAdmin()}
          </div>
        </div>
        {selectedOrgAdmins?.length > 0 && (
          <div>
            <Button
              className={classes.locatePilotsButton}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => locatePilotsByEmail()}
              data-testid="pilot-web:settings-panel:manage-org:locate-pilot-by-email-button"
            >
              Locate pilots with the same email domain
            </Button>
          </div>
        )}
        <br />
        <Typography variant="subtitle1">Pilots:</Typography>
        <FormControl className={classes.formControl}>
          <Autocomplete
            className={classes.autocompleteBox}
            value={pilotEmailValue}
            onChange={(_, newValue) => {
              if (!!newValue && !selectedPilots?.includes(newValue)) {
                updateSelectedPilots([...selectedPilots, newValue]);
              }
              setPilotEmailInputValue('');
              setPilotEmailValue('');
            }}
            inputValue={pilotEmailInputValue}
            onInputChange={(event, newInputValue) => {
              onAutocompletePilotEmails(event, newInputValue);
            }}
            id="pilot-web:settings-panel:manage-org:pilots-autocomplete-input"
            data-testid="pilot-web:settings-panel:manage-org:pilots-autocomplete-input"
            options={pilotOptions}
            freeSolo={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pilots"
                variant="outlined"
                data-testid="pilot-web:settings-panel:manage-org:pilots-text-field"
              />
            )}
          />
        </FormControl>
        <div
          className={classes.emailList}
          data-testid="pilot-web:settings-panel:manage-org:pilot-list"
        >
          {displayPilots()}
        </div>
        <br />
      </DialogContent>
      <DialogActions>
        {validationErrors?.length > 0 && (
          <Typography color="error" variant="subtitle1">
            {validationErrors.join(', ')}
          </Typography>
        )}
        <Button
          onClick={closeModal}
          variant="outlined"
          color="primary"
          disabled={isSubmitting}
          data-testid="pilot-web:settings-panel:manage-org:add-edit-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={isSubmitting ? () => {} : handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={classes.saveButton}
          data-testid="pilot-web:settings-panel:manage-org:add-edit-save-button"
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : isNew ? (
            'Save Organisation'
          ) : (
            'Save Changes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditOrganisationModal;
