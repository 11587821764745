import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { authFormsReducer } from './auth-forms/reducers';
import {
  SET_PROFILE,
  SET_ERROR,
  SET_LOGIN_STATUS,
  SET_REGISTRATION_STATUS,
  SET_ACTIVATION_STATUS,
  SET_IS_ACCOUNT_ACTIVE,
  SET_RESEND_ACTIVATION_EMAIL_STATUS,
  SET_COMPLETE_LOGIN_DATA,
  SET_POLICIES_TO_ACCEPT,
} from './actions';
import { LogInStatus, RegistrationStatus, ActivationStatus } from './constants';

export const REDUCER_KEY = 'session';

export const error = createReducer({
  [SET_ERROR]: (_: any, { payload }: { payload: any }) => payload,
});

export const profile = createReducer({
  [SET_PROFILE]: (_: any, { payload }: { payload: any }) => payload,
});

export const loginStatus = createReducer(
  {
    [SET_LOGIN_STATUS]: (_: any, { payload }: { payload: any }) => payload,
  },
  LogInStatus.IDLE
);

export const registrationStatus = createReducer(
  {
    [SET_REGISTRATION_STATUS]: (_: any, { payload }: { payload: any }) =>
      payload,
  },
  RegistrationStatus.IDLE
);

export const activationStatus = createReducer(
  {
    [SET_ACTIVATION_STATUS]: (_: any, { payload }: { payload: any }) => payload,
  },
  ActivationStatus.IDLE
);

export const isAccountActive = createReducer(
  {
    [SET_IS_ACCOUNT_ACTIVE]: (_: any, { payload }: any) => payload,
  },
  true
);

export const completeLoginData = createReducer({
  [SET_COMPLETE_LOGIN_DATA]: (_: any, { payload }: { payload: any }) => payload,
});

export const policiesToAccept = createReducer({
  [SET_POLICIES_TO_ACCEPT]: (_: any, { payload }: { payload: any }) => payload,
});

export const resendActivationEmailStatus = createReducer(
  {
    [SET_RESEND_ACTIVATION_EMAIL_STATUS]: (
      _: any,
      { payload }: { payload: any }
    ) => payload,
  },
  ActivationStatus.IDLE
);

export const sessionReducer = combineReducers({
  authForms: authFormsReducer,
  profile,
  error,
  loginStatus,
  completeLoginData,
  registrationStatus,
  activationStatus,
  isAccountActive,
  resendActivationEmailStatus,
  policiesToAccept,
});
