import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  getFlightPlanValidationStatus,
  getFlightPlanCreationStatus,
  getFlightPlanSelectedFavourite,
  getIsEditingFavouriteFlightArea,
  getWarnings,
  getFlightPlanSelectedFlightPathArea,
  getFlightPlanUploadedFlightArea,
  getFlightPlanEditing,
  getFlightPlanUpdatingStatus,
} from './selectors';
import {
  flightPlanValidationRequested,
  flightPlanCreationRequested,
  flightPlanValidationStatusReset,
  flightPlanCreationStatusReset,
  flightPlanFavouriteSelected,
  flightPlanFavouriteReset,
  flightPlanFavouriteFlightAreaRendered,
  flightPlanNewFlightAreaDrawn,
  flightPlanFlightPathAreaSelected,
  flightPlanFlightPathAreaReset,
  flightPlanUploadedFlightAreaSelected,
  flightPlanUploadedFlightAreaReset,
  flightPlanEditRequested,
  flightPlanUpdateRequested,
} from './actions';
import { FlightPlanForm } from './flight-plan-form/types';
import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { type Polygon } from '@turf/helpers';
import { StyledAreaResponse } from 'argus-common/interfaces';
import { ExternalFlightResponseBody } from '@airshare/external-api-types';

type FlightPlanFormWithId = FlightPlanForm & { flightId: string };

export function useValidateFlightPlan() {
  const dispatch = useDispatch();

  return (payload: FlightPlanFormWithId) =>
    dispatch(flightPlanValidationRequested(payload));
}

export function useCreateFlightPlan() {
  const dispatch = useDispatch();

  return (payload: FlightPlanForm) =>
    dispatch(flightPlanCreationRequested(payload));
}

export function useSelectFavourite() {
  const dispatch = useDispatch();

  return (payload: ExtendedFlightResponseBodyV2) =>
    dispatch(flightPlanFavouriteSelected(payload));
}

export function useResetFavourite() {
  const dispatch = useDispatch();

  return () => dispatch(flightPlanFavouriteReset());
}

export function useSelectedFavourite(): ExtendedFlightResponseBodyV2 {
  const selectedFavourite = useSelector(getFlightPlanSelectedFavourite);

  return selectedFavourite;
}

export function useIsEditingFavouriteFlightArea() {
  const isEditingFavouriteFlightArea = useSelector(
    getIsEditingFavouriteFlightArea
  );

  return isEditingFavouriteFlightArea;
}

export function useFavouriteFlightAreaRendered() {
  const dispatch = useDispatch();

  return () => dispatch(flightPlanFavouriteFlightAreaRendered());
}

export function useNewFlightAreaDrawn() {
  const dispatch = useDispatch();

  const newFlightAreaDrawn = useCallback(() => {
    dispatch(flightPlanNewFlightAreaDrawn());
  }, [dispatch]);

  return newFlightAreaDrawn;
}

export function useFlightPlanValidationStatus() {
  const dispatch = useDispatch();
  const resetFlightPlanValidationStatus = () =>
    dispatch(flightPlanValidationStatusReset());
  const flightPlanValidationStatus = useSelector(getFlightPlanValidationStatus);

  return { flightPlanValidationStatus, resetFlightPlanValidationStatus };
}

export function useFlightPlanCreationStatus() {
  const dispatch = useDispatch();
  const resetFlightPlanCreationStatus = () =>
    dispatch(flightPlanCreationStatusReset());
  const flightPlanCreationStatus = useSelector(getFlightPlanCreationStatus);

  return { flightPlanCreationStatus, resetFlightPlanCreationStatus };
}

export function useFlightPlanValidationResponse() {
  const flightPlanSuccess: ExternalFlightResponseBody =
    useSelector(getWarnings);

  const dispatch = useDispatch();
  const resetFlightPlanValidation = useCallback(() => {
    dispatch(flightPlanValidationStatusReset());
  }, [dispatch]);

  return { flightPlanSuccess, resetFlightPlanValidation };
}

export function useSelectFlightPathArea() {
  const dispatch = useDispatch();
  return (payload: StyledAreaResponse | null) =>
    dispatch(flightPlanFlightPathAreaSelected(payload));
}

export function useResetFlightPathArea() {
  const dispatch = useDispatch();
  return () => dispatch(flightPlanFlightPathAreaReset());
}

export function useSelectedFlightPathArea() {
  const area = useSelector(getFlightPlanSelectedFlightPathArea);
  return area;
}

export function useUploadFlightArea() {
  const dispatch = useDispatch();
  return (payload: { geometry: Polygon | null }) =>
    dispatch(flightPlanUploadedFlightAreaSelected(payload));
}

export function useResetUploadedFlightArea() {
  const dispatch = useDispatch();
  return () => dispatch(flightPlanUploadedFlightAreaReset());
}

export function useUploadedFlightArea() {
  const area = useSelector(getFlightPlanUploadedFlightArea);
  return area;
}

export function useFlightPlanEdit() {
  const dispatch = useDispatch();
  const requestFlightPlanToEdit = (flightId: string) =>
    dispatch(flightPlanEditRequested(flightId));

  return [requestFlightPlanToEdit];
}

export function useFlightPlanEditSuccess() {
  const flightPlanToEditSuccess = useSelector(getFlightPlanEditing);
  return [flightPlanToEditSuccess];
}

export function useUpdateFlightPlan() {
  const dispatch = useDispatch();

  return (payload: Object) => dispatch(flightPlanUpdateRequested(payload));
}

export function useUpdateFlightPlanStatus() {
  return useSelector(getFlightPlanUpdatingStatus);
}
