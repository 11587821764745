import { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';

import {
  useIsInTabletMode,
  useMobileDetection,
} from 'airshare-pilot-web-shared';

import { AUTH_PATH } from '../../auth-modal/auth-modal';
import { DETAIL_PANEL_PATH } from '../../../routes';
import { useLocation } from './router.hook';

export function useDetailPanelIsVisible(): boolean {
  const [detailPanelIsVisible, setDetailPanelIsVisible] = useState(false);
  const location = useLocation();

  const isInTabletMode = useIsInTabletMode();
  const isMobile = useMobileDetection();

  useEffect(() => {
    const pathMatch = matchPath(location.pathname, {
      path: DETAIL_PANEL_PATH,
      exact: true,
      strict: true,
    });
    const isVisible = pathMatch ? pathMatch.isExact : false;

    const authMatch = matchPath(location.pathname, {
      path: AUTH_PATH,
      exact: false,
    });
    const isAuth = authMatch ? authMatch.isExact : false;

    setDetailPanelIsVisible(
      !isInTabletMode && !isMobile && !isAuth && isVisible
    );
  }, [isInTabletMode, isMobile, location]);

  return detailPanelIsVisible;
}
