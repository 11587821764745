import React from 'react';
import { ImageFallback } from '~/components/shared/image-fallback/image-fallback';
import { useProfile } from '~/state/profile/hooks';

import fallbackImage from './images/profile.png';

import './avatar.scss';

export function Avatar() {
  const profile = useProfile();

  return (
    <ImageFallback
      className="avatar"
      src={profile?.photoUrl || ''}
      fallbackSrc={fallbackImage}
      alt="avatar"
    />
  );
}
