import { useDispatch, useSelector } from 'react-redux';
import { getRegistrationStatus } from '~/state/session/selectors';
import {
  attemptRegistration,
  setRegistrationStatus,
} from '~/state/session/action-creators';
import { RegistrationStatus } from '~/state/session/constants';

export function useRegister() {
  const dispatch = useDispatch();

  const registrationStatus = useSelector(getRegistrationStatus);

  function dispatchAttemptRegistration(payload: any) {
    return dispatch(attemptRegistration(payload));
  }

  function dispatchResetRegistrationStatus() {
    return dispatch(setRegistrationStatus(RegistrationStatus.IDLE));
  }

  return [
    registrationStatus,
    dispatchAttemptRegistration,
    dispatchResetRegistrationStatus,
  ];
}
