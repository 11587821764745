import { useEffect, useState } from 'react';
import { useOrgPilotsFetchStatus } from '~/state/org-pilots/hooks';
import { OrgPilotsFetchStatus } from '~/state/org-pilots/constants';

export function useIsLoading() {
  const [hasFetched, updateHasFetched] = useState(false);

  const orgPilotsFetchStatus = useOrgPilotsFetchStatus();
  useEffect(() => {
    if (orgPilotsFetchStatus === OrgPilotsFetchStatus.SUCCESS) {
      updateHasFetched(true);
    }
    if (orgPilotsFetchStatus === OrgPilotsFetchStatus.FAILED) {
      updateHasFetched(false);
    }
  }, [orgPilotsFetchStatus]);

  const isLoading =
    orgPilotsFetchStatus === OrgPilotsFetchStatus.ORG_PILOTS_FETCH_IN_PROGRESS;

  return isLoading && !hasFetched;
}
