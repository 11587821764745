import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { NOT_FOUND } from 'http-status-codes';
import { Feature } from '@turf/helpers';
import { contentAPI } from '../../lib/api';
import {
  NotamsActionType,
  notamsFetchFailed,
  notamsFetchSucceeded,
  NotamsFetchRequestedAction,
} from './actions';

interface NotamsResponse {
  data: Feature[];
}

function* fetchNotams({ payload }: NotamsFetchRequestedAction) {
  try {
    const { data }: NotamsResponse = yield call(contentAPI.get, '/notams', {
      params: { ...payload },
    });

    yield put(notamsFetchSucceeded(data));
  } catch (error) {
    const status = get(error, 'response.status', null);

    if (status === NOT_FOUND) {
      yield put(notamsFetchSucceeded([]));
    } else {
      yield put(notamsFetchFailed(error.message));
    }
  }
}

export function* notamsSaga() {
  yield takeLatest(NotamsActionType.FETCH_REQUESTED, fetchNotams);
}
