import { MapStyle } from '../../state/map-styles/hooks';

const DETAIL_PANEL_WIDTH = 450;
const PAN_DISTANCE = DETAIL_PANEL_WIDTH / 2;

export class MapHelpers {
  google: any;

  map: google.maps.Map;

  constructor(google: any, map: google.maps.Map) {
    this.google = google;
    this.map = map;
  }

  project = (latLng: google.maps.LatLng) => {
    const TILE_SIZE = 256;

    let siny = Math.sin((latLng.lat() * Math.PI) / 180);

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999);

    return new this.google.maps.Point(
      TILE_SIZE * (0.5 + latLng.lng() / 360),
      TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
    );
  };

  getPixel = (latLng: google.maps.LatLng, zoom: number) => {
    // eslint-disable-next-line no-bitwise
    const scale = 1 << zoom;
    const worldCoordinate = this.project(latLng);
    return new this.google.maps.Point(
      Math.floor(worldCoordinate.x * scale),
      Math.floor(worldCoordinate.y * scale)
    );
  };

  getMapDimenInPixels = () => {
    const zoom = this.map.getZoom();
    const bounds = this.map.getBounds();
    if (bounds) {
      const se = bounds?.getSouthWest();
      const ne = bounds?.getNorthEast();
      const southWestPixel = this.getPixel(se, zoom);
      const northEastPixel = this.getPixel(ne, zoom);
      return {
        width: Math.abs(southWestPixel.x - northEastPixel.x),
        height: Math.abs(southWestPixel.y - northEastPixel.y),
      };
    }
    return {};
  };

  willAnimatePanTo = (
    destLatLng: google.maps.LatLng,
    optionalZoomLevel?: number
  ) => {
    const dimen = this.getMapDimenInPixels();

    const mapCenter = this.map.getCenter();
    const nextOptionalZoomLevel = optionalZoomLevel || this.map.getZoom();

    const destPixel = this.getPixel(destLatLng, nextOptionalZoomLevel);
    const mapPixel = this.getPixel(mapCenter, nextOptionalZoomLevel);
    const diffX = Math.abs(destPixel.x - mapPixel.x);
    const diffY = Math.abs(destPixel.y - mapPixel.y);

    return diffX < dimen?.width && diffY < dimen?.height;
  };

  getOptimalZoomOut = (latLng: google.maps.LatLng, currentZoom: number) => {
    if (this.willAnimatePanTo(latLng, currentZoom - 1)) {
      return currentZoom - 1;
    }
    if (this.willAnimatePanTo(latLng, currentZoom - 2)) {
      return currentZoom - 2;
    }
    return currentZoom - 3;
  };

  smoothlyAnimatePanToWorkarround = (
    destLatLng: google.maps.LatLng,
    optionalAnimationEndCallback?: () => void
  ) => {
    const initialZoom = this.map.getZoom();
    let listener: any;

    const googleInstance = this.google;
    const mapInstance = this.map;
    const { willAnimatePanTo } = this;
    const { getOptimalZoomOut } = this;

    function zoomIn() {
      if (mapInstance.getZoom() < initialZoom) {
        mapInstance.setZoom(Math.min(mapInstance.getZoom() + 3, initialZoom));
      } else {
        googleInstance.maps.event.removeListener(listener);

        // here you should (re?)enable only the ui controls that make sense to your app
        mapInstance.setOptions({
          draggable: true,
          zoomControl: false,
          scrollwheel: true,
          disableDoubleClickZoom: true,
        });

        if (optionalAnimationEndCallback) {
          optionalAnimationEndCallback();
        }
      }
    }

    function zoomOut() {
      if (willAnimatePanTo(destLatLng)) {
        googleInstance.maps.event.removeListener(listener);
        listener = googleInstance.maps.event.addListener(
          mapInstance,
          'idle',
          zoomIn
        );
        mapInstance.panTo(destLatLng);
      } else {
        mapInstance.setZoom(
          getOptimalZoomOut(destLatLng, mapInstance.getZoom())
        );
      }
    }

    // here you should disable all the ui controls that your app uses
    this.map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    });
    this.map.setZoom(this.getOptimalZoomOut(destLatLng, initialZoom));
    listener = this.google.maps.event.addListener(this.map, 'idle', zoomOut);
  };

  smoothlyAnimatePanTo = (
    destLatLng: google.maps.LatLng,
    optionalAnimationEndCallback?: () => void
  ) => {
    if (this.willAnimatePanTo(destLatLng)) {
      this.map.panTo(destLatLng);
      const listener = this.google.maps.event.addListenerOnce(
        this.map,
        'idle',
        () => {
          if (optionalAnimationEndCallback) {
            optionalAnimationEndCallback();
          }
          this.google.maps.event.removeListener(listener);
        }
      );
    } else {
      this.smoothlyAnimatePanToWorkarround(
        destLatLng,
        optionalAnimationEndCallback
      );
    }
  };

  animateMapZoomTo(targetZoom: number, callback?: any, z?: any) {
    const targetZoomInt = Math.round(targetZoom);
    const currentZoomInt = Math.round(z || this.map.getZoom());
    if (currentZoomInt !== targetZoomInt) {
      this.google.maps.event.addListenerOnce(this.map, 'zoom_changed', () => {
        const nextZoom =
          currentZoomInt + (targetZoomInt > currentZoomInt ? 1 : -1);
        this.animateMapZoomTo(targetZoomInt, callback, nextZoom);
      });
      setTimeout(() => {
        this.map.setZoom(currentZoomInt);
      }, 200);
    }

    if (currentZoomInt === targetZoomInt) {
      const listener = this.google.maps.event.addListenerOnce(
        this.map,
        'idle',
        () => {
          if (callback) {
            callback();
          }
          this.google.maps.event.removeListener(listener);
        }
      );
    }
  }

  fitToBoundsWithPadding = (
    bounds: google.maps.LatLngBounds,
    detailPanelIsVisible: boolean
  ) => {
    this.map.fitBounds(bounds);
    this.map.setZoom(this.map.getZoom() - 1);
    if (detailPanelIsVisible) {
      this.map.panBy(-1 * PAN_DISTANCE, 0);
    }
  };
}

export interface MapBounds {
  ne: string;
  sw: string;
  zoom?: number;
}

export function createMapBounds(map: google.maps.Map | null): MapBounds | null {
  if (!map) {
    return null;
  }

  const bounds = map.getBounds();

  if (bounds) {
    const neBound = bounds.getNorthEast();
    const swBound = bounds.getSouthWest();

    const ne = `${neBound.lng()},${neBound.lat()}`;
    const sw = `${swBound.lng()},${swBound.lat()}`;

    return {
      ne,
      sw,
      zoom: map?.getZoom(),
    };
  }

  return null;
}

export function createMapOptions(styleOptions: MapStyle): {
  panControl: boolean;
  mapTypeControl: boolean;
  scrollwheel: boolean;
  zoomControl: boolean;
  minZoom: number;
  fullscreenControl: boolean;
  streetViewControl: boolean;
  mapId: string | undefined;
  mapTypeId: string;
  [key: string]: any;
} {
  return {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: true,
    zoomControl: false,
    minZoom: 0,
    fullscreenControl: false,
    streetViewControl: false,
    mapId: undefined,
    mapTypeId: 'roadmap',
    ...styleOptions,
  };
}
