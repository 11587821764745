import { pilotAPI } from './api';

const ERR_MESSAGE = 'Failed to reset password, Please try again later.';

export async function resetPassword(email: string): Promise<void> {
  return pilotAPI
    .post<{ message: string; id: string }>('/v2/reset-password', {
      email,
      origin: 'pilot-web',
    })
    .then((resp) => {
      if (resp.data?.id || resp.data?.message === 'Success')
        return Promise.resolve();
      return Promise.reject(new Error(resp.data.message || ERR_MESSAGE));
    })
    .catch(() => Promise.reject(new Error(ERR_MESSAGE)));
}

export async function changePassword(
  email: string,
  newPassword: string,
  resetKey: string
): Promise<void> {
  return pilotAPI
    .post<{ message: string; id: string }>('/v2/change-password', {
      email,
      newPassword,
      resetKey,
      origin: 'pilot-web',
    })
    .then((resp) => {
      if (resp.data.message === 'Success') return Promise.resolve();
      return Promise.reject(new Error(resp.data.message || ERR_MESSAGE));
    })
    .catch((error) =>
      Promise.reject(new Error(error.response?.data?.message || ERR_MESSAGE))
    );
}
