import React from 'react';
import cn from 'classnames';

import {
  ManagedAreaCode,
  MapAreaType,
  UnfinishedManagedAreaCode,
} from '@airshare/pilot-types';

import { ImageFallback } from '../../../components/shared/image-fallback/image-fallback';
import { AppConfigData, useAppConfig } from '../../../state/app-config';
import moaFallbackImage from './images/nzdf-logo.png';
import adFallbackImage from './images/aerodrome.png';
import lfzFallbackImage from './images/low-flying-zone.png';
import defaultFallbackImage from './images/caution-icon.png';

import './advisory-logo.scss';

const getFallbackImageFromType = (
  type: MapAreaType,
  ctrLogo: string
): string => {
  switch (type) {
    case UnfinishedManagedAreaCode.CTR:
      return ctrLogo;
    case UnfinishedManagedAreaCode.MOA:
      return moaFallbackImage;
    case ManagedAreaCode.Aerodrome:
      return adFallbackImage;
    case UnfinishedManagedAreaCode.LFZ:
      return lfzFallbackImage;
    default:
      return defaultFallbackImage;
  }
};

const getFallbackImageFromCode = (code: string, ctrLogo: string): string => {
  // code is mapCode || code on the advisory

  switch (code) {
    case 'ctr':
      return ctrLogo;
    case 'moa':
      return moaFallbackImage;
    case 'ad':
      return adFallbackImage;
    case 'lfz':
      return lfzFallbackImage;
    default:
      return defaultFallbackImage;
  }
};

const getFallbackImage = ({
  type,
  code,
  appConfig,
}: {
  type?: MapAreaType;
  code?: string;
  appConfig: AppConfigData;
}): string => {
  const ctrLogo = appConfig?.logoRectangle;

  if (type) {
    // type is returned only if going through the external api and not throught the db;
    return getFallbackImageFromType(type, ctrLogo);
  }

  // Still using direct db calls for the getting of a singular advisory, remove when /map-areas/:id endpoint is used to get map area
  return getFallbackImageFromCode(code, ctrLogo);
};

export function AdvisoryLogo({
  logoUrl = null,
  code,
  type,
  className,
  alt = 'advisory-logo',
}: {
  logoUrl?: string;
  type?: MapAreaType;
  code: string;
  className: string;
  alt?: string;
}) {
  const url = logoUrl ? `${window.env.ARGUS_CONTENT_API_URL}${logoUrl}` : null;

  const appConfig = useAppConfig();

  const fallbackImage =
    getFallbackImage({ type, code, appConfig }) || defaultFallbackImage;

  return (
    <ImageFallback
      className={cn('advisory-logo', { [className]: className })}
      src={url}
      fallbackSrc={fallbackImage}
      alt={alt}
      id="pilot-web:advisory-logo"
    />
  );
}
