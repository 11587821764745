import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Feature, FeatureCollection, Polygon, MultiPolygon } from 'geojson';
import { polygon, featureCollection } from '@turf/helpers';

import {
  Paper,
  Typography,
  ButtonBase,
  FormHelperText,
  FormControlLabel,
  FormControl,
  Input,
  InputLabel,
  Checkbox,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import PhoneNumberInput from 'material-ui-phone-number';
import MuiAlert from '@material-ui/lab/Alert';

import {
  AirshareUser,
  AirshareUserRole,
  MobileRegex,
} from '@airshare/pilot-types';

import {
  CertificationType,
  OperatorType,
  UserStatus,
} from '@airshare/external-api-types';

import { MANAGE_PILOT_USERS_PATH } from '../../../routes';
import * as pilotApiClient from '../../../pilot-api-client';
import { getUpdateReqBodyFromEdits } from '../../../lib/pilot-helpers';
import { useProfile } from '../../../state/profile/hooks';
import { useNavigateTo } from '../../shared/hooks/router.hook';

import './edit-pilot-user.scss';
import { parsePhoneNumber } from 'libphonenumber-js';

const useEditPilotStyles = makeStyles(() =>
  createStyles({
    updatePilotUserForm: {
      width: 'calc(100% - 16px)',
      marginLeft: '16px',
      padding: '2rem 1rem 1rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflowX: 'hidden',
      overflowY: 'scroll',
      height: '100vh',
    },
    title: {
      width: '100%',
      paddingLeft: '1rem',
    },
    formControl: {
      width: '100%',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
    errorDiv: {
      width: '100%',
      marginTop: '1rem',
      padding: '0rem 1rem',
      display: 'flex',
      justifyContent: 'center',
    },
    buttonDiv: {
      width: '100%',
      marginTop: '0.5rem',
      padding: '0rem 1rem',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  })
);

interface Props extends RouteComponentProps<{ id: string }> {}

function EditPilotUserComponent({ match }: Props) {
  const { id: pilotUserId } = match.params;

  const profile = useProfile();
  const navigateTo = useNavigateTo();
  const classes = useEditPilotStyles({});

  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [saveResultMessage, setSaveResultMessage] = useState('');

  const [loadedPilotUser, setLoadedPilotUser] = useState<AirshareUser | null>(
    null
  );
  const [editedPilotUser, setEditedPilotUser] = useState<AirshareUser | null>(
    null
  );

  const loadPilotUser = useCallback(async () => {
    if (pilotUserId) {
      pilotApiClient
        .getPilotUser(pilotUserId)
        .then((resp) => {
          setLoadedPilotUser(resp);
          setEditedPilotUser(resp);
        })
        .catch((e) => {
          console.error(
            `Error loading pilot user ${pilotUserId}, error: ${e?.message}`
          );
          setLoadedPilotUser(null);
          setEditedPilotUser(null);
        });
    } else {
      setLoadedPilotUser(null);
      setEditedPilotUser(null);
    }
    setIsLoading(false);
  }, [pilotUserId]);

  useEffect(() => {
    if (profile?.roles.includes(AirshareUserRole.SUPER_ADMIN)) {
      setIsAdmin(true);
      loadPilotUser();
    } else {
      setLoadedPilotUser(null);
      setEditedPilotUser(null);
    }
  }, [profile, loadPilotUser]);

  const returnToManagePilots = () => {
    setIsLoading(true);
    navigateTo(MANAGE_PILOT_USERS_PATH);
  };

  const resetEditedPilot = () => {
    loadPilotUser();
    setErrors([]);
  };

  const submitUpdate = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const updateReqBody = getUpdateReqBodyFromEdits(
      loadedPilotUser,
      editedPilotUser
    );
    const updatedPilot = await pilotApiClient.updatePilotUser(updateReqBody);
    if (updatedPilot.errors) {
      setErrors(updatedPilot.errors);
      setSaveResultMessage('There was an error saving your changes');
    } else if (updatedPilot.user) {
      setErrors([]);
      setLoadedPilotUser(updatedPilot.user);
      setEditedPilotUser(updatedPilot.user);
      setSaveResultMessage('Changes saved successfully');
    }
    setIsLoading(false);
  };

  const updateFieldValue = (fieldName: string, value: any) => {
    setEditedPilotUser((user) => {
      const update = {} as any;
      update[fieldName] = value;
      return {
        ...user,
        ...update,
      };
    });
  };

  const updatePilotFieldValue = (fieldName: string, value: any) => {
    setEditedPilotUser((user) => {
      const update = {} as any;
      update[fieldName] = value;
      return {
        ...user,
        pilot: {
          ...user?.pilot,
          ...update,
        },
      } as AirshareUser;
    });
  };

  const getErrorForField = (fieldName: string) => {
    const errs = errors?.filter((error) => error.includes(fieldName));
    if (errs?.length) {
      return errs.join(', ');
    }
    return '';
  };

  const isErrorInField = (fieldName: string) => {
    return getErrorForField(fieldName) !== '';
  };

  const handleSnackbarClose = (
    _event?: React.SyntheticEvent,
    _reason?: string
  ) => {
    setSaveResultMessage('');
  };

  const haveChanges =
    editedPilotUser &&
    JSON.stringify(loadedPilotUser) !== JSON.stringify(editedPilotUser);

  const isPrivilegedViewer = editedPilotUser?.roles.includes(
    AirshareUserRole.PRIVILEGED_VIEWER
  );

  const formatAreaOfVisibility = () =>
    !isErrorInField('Area of visibility') &&
    editedPilotUser?.viewer?.areaOfVisibility
      ? JSON.stringify(editedPilotUser.viewer.areaOfVisibility, null, 2)
      : '';

  const checkSupportedGeojsonFormat = (inputJson: FeatureCollection) => {
    if (
      inputJson?.type !== 'FeatureCollection' ||
      inputJson?.features.some(
        (f) => !['Polygon', 'MultiPolygon'].includes(f.geometry.type)
      )
    )
      throw new Error(
        'Please use FeatureCollection format containing Polygon or MultiPolygon'
      );
  };

  const handleAreaOfVisibilityChanges = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value?.replace(/[\\\n\s]/g, '');
    setErrors((currErrors) =>
      currErrors.filter((err) => !err.includes('Area of visibility'))
    );
    let parsedVal: FeatureCollection<Polygon> | string = '';
    if (val?.length) {
      try {
        const rawParsedVal = JSON.parse(val);
        checkSupportedGeojsonFormat(rawParsedVal);
        parsedVal = rawParsedVal.features.reduce(
          (
            normalisedPolygons: FeatureCollection<Polygon>,
            currentFeature: Feature<Polygon | MultiPolygon>
          ) =>
            featureCollection([
              ...normalisedPolygons.features,
              ...(currentFeature.geometry.type === 'Polygon'
                ? [currentFeature]
                : currentFeature.geometry.coordinates.map((c) => polygon(c))),
            ]),
          featureCollection([])
        );
      } catch (err) {
        console.error(`Error while updating Area of Visibility: ${err}`);
        setErrors((currErrs) => [
          ...currErrs,
          'Area of visibility requires geojson FeatureCollection format',
        ]);
        parsedVal = val;
      }
    }
    updateFieldValue(
      'viewer',
      val?.length && parsedVal !== '' ? { areaOfVisibility: parsedVal } : null
    );
  };

  const handleMobileNumberChange = (value: string) => {
    try {
      if (!MobileRegex.test(value)) {
        updateFieldValue('mobileNo', value);
      } else {
        const formattedNumber = parsePhoneNumber(value);
        const validNumber = `+${formattedNumber.countryCallingCode} ${formattedNumber.nationalNumber}`;
        updateFieldValue('mobileNo', validNumber);
      }
    } catch (error) {
      updateFieldValue('mobileNo', '+');
      console.error('Invalid mobile number');
    }
  };

  return (
    <>
      <Snackbar
        open={Boolean(saveResultMessage)}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        data-testid="pilot-web:update-pilot-user-form:snackbar"
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={saveResultMessage.includes('error') ? 'error' : 'success'}
          style={{ marginLeft: 'calc(var(--aside-width) + var(--radius-l)' }}
          data-testid="pilot-web:update-pilot-user-form:result-alert"
        >
          {saveResultMessage}
        </MuiAlert>
      </Snackbar>

      {!isAdmin || !editedPilotUser ? null : (
        <form
          onSubmit={submitUpdate}
          className={classes.updatePilotUserForm + ' scrollable-page'}
          data-testid="pilot-web:update-pilot-user-form"
        >
          <div>
            <Typography className={classes.title} variant="h3">
              Edit Pilot Details
            </Typography>
            <Paper></Paper>
          </div>

          <FormControl
            className={classes.formControl}
            error={isErrorInField('fullName')}
          >
            <InputLabel className="input-label" htmlFor="fullName">
              Name
            </InputLabel>
            <Input
              name="fullName"
              value={editedPilotUser.fullName}
              inputProps={{
                'data-testid': 'pilot-web:update-pilot-user-form:name-input',
              }}
              onChange={(e) => {
                updateFieldValue('fullName', e.target.value);
              }}
            />
            <FormHelperText className="input-hint">
              {getErrorForField('fullName')}
            </FormHelperText>
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={isErrorInField('email')}
          >
            <InputLabel className="input-label" htmlFor="email">
              Email
            </InputLabel>
            <Input
              name="email"
              value={editedPilotUser.email}
              inputProps={{
                'data-testid': 'pilot-web:update-pilot-user-form:email-input',
              }}
              onChange={(e) => {
                updateFieldValue('email', e.target.value);
              }}
            />
            <FormHelperText className="input-hint">
              {getErrorForField('email')}
            </FormHelperText>
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={isErrorInField('mobileNo')}
          >
            <PhoneNumberInput
              className="phone-input"
              label="Phone Number"
              name="mobileNo"
              defaultCountry="nz"
              disableAreaCodes={true}
              onChange={(value: any) => {
                handleMobileNumberChange(value);
              }}
              value={editedPilotUser.mobileNo}
              inputProps={{
                'data-testid': 'pilot-web:update-pilot-user-form:phone-input',
              }}
            />
            <FormHelperText className="input-hint">
              {getErrorForField('mobileNo')}
            </FormHelperText>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel className="input-label">User Status</InputLabel>
            <Select
              type="text"
              name="userStatus"
              inputProps={{
                name: 'userStatus',
                className: 'user-status',
              }}
              value={editedPilotUser.status}
              onChange={(_, component) => {
                updateFieldValue('status', (component as any)?.props.value);
              }}
              data-testid="pilot-web:update-pilot-user-form:user-status-input"
            >
              {Object.values(UserStatus)?.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={isErrorInField('businessName')}
          >
            <InputLabel className="input-label" htmlFor="businessName">
              Business Name
            </InputLabel>
            <Input
              name="businessName"
              value={editedPilotUser.pilot?.businessName}
              data-testid="pilot-web:update-pilot-user-form:business-name-input"
              onChange={(e) => {
                updatePilotFieldValue('businessName', e.target.value);
              }}
            />
            <FormHelperText
              className="input-hint"
              data-testid="pilot-web:update-pilot-user-form:business-name-error"
            >
              {getErrorForField('businessName')}
            </FormHelperText>
          </FormControl>

          <FormControlLabel
            label={
              <Typography variant="subtitle1">
                Notify of conflicting flights?
              </Typography>
            }
            className={classes.formControl}
            control={
              <Checkbox
                name="notifyConflictingFlight"
                size="small"
                color="primary"
                checked={editedPilotUser.pilot?.notifyConflicts}
                onChange={(_, value) => {
                  updatePilotFieldValue('notifyConflicts', value);
                }}
                data-testid="pilot-web:update-pilot-user-form:notify-conflicts-checkbox"
              />
            }
          />

          <FormControlLabel
            label={
              <Typography variant="subtitle1">Share contact info?</Typography>
            }
            className={classes.formControl}
            control={
              <Checkbox
                name="shareContactInfo"
                size="small"
                color="primary"
                checked={editedPilotUser.pilot?.shareContactInfo}
                onChange={(_, value) => {
                  updatePilotFieldValue('shareContactInfo', value);
                }}
                data-testid="pilot-web:update-pilot-user-form:share-contact-info-checkbox"
              />
            }
          />

          <FormControlLabel
            label={
              <Typography variant="subtitle1">
                Is commercial operator?
              </Typography>
            }
            className={classes.formControl}
            control={
              <Checkbox
                name="operatorType"
                size="small"
                color="primary"
                checked={
                  editedPilotUser.pilot?.operatorType ===
                  OperatorType.COMMERCIAL
                }
                onChange={(_, value) => {
                  const operatorType = value
                    ? OperatorType.COMMERCIAL
                    : OperatorType.RECREATIONAL;
                  updatePilotFieldValue('operatorType', operatorType);
                }}
                data-testid="pilot-web:update-pilot-user-form:is-commercial-checkbox"
              />
            }
          />

          <FormControlLabel
            label={<Typography variant="subtitle1">Is Part 102?</Typography>}
            className={classes.formControl}
            control={
              <Checkbox
                name="certificationType"
                size="small"
                color="primary"
                checked={
                  editedPilotUser.pilot?.certificationType ===
                  CertificationType.PART_102
                }
                onChange={(_, value) => {
                  const certType = value
                    ? CertificationType.PART_102
                    : CertificationType.PART_101;
                  updatePilotFieldValue('certificationType', certType);
                }}
                data-testid="pilot-web:update-pilot-user-form:is-part-102-checkbox"
              />
            }
          />

          {editedPilotUser.pilot?.certificationType ===
            CertificationType.PART_102 && (
            <FormControl
              className={classes.formControl}
              error={isErrorInField('certificationNumber')}
            >
              <InputLabel className="input-label" htmlFor="certificationNumber">
                Part 102 Cert Number
              </InputLabel>
              <Input
                name="certificationNumber"
                value={editedPilotUser.pilot?.certificationNumber}
                data-testid="pilot-web:update-pilot-user-form:cert-number-input"
                onChange={(e) => {
                  updatePilotFieldValue('certificationNumber', e.target.value);
                }}
              />
              <FormHelperText
                className="input-hint"
                data-testid="pilot-web:update-pilot-user-form:cert-number-error"
              >
                {getErrorForField('certificationNumber')}
              </FormHelperText>
            </FormControl>
          )}

          <FormControlLabel
            label={
              <Typography variant="subtitle1">Is Privileged Viewer?</Typography>
            }
            className={classes.formControl}
            control={
              <Checkbox
                name="privilegedViewer"
                size="small"
                color="primary"
                checked={isPrivilegedViewer}
                onChange={(_, value) => {
                  const updatedRoles = value
                    ? [
                        ...editedPilotUser.roles,
                        AirshareUserRole.PRIVILEGED_VIEWER,
                      ]
                    : editedPilotUser.roles.filter(
                        (r) => r !== AirshareUserRole.PRIVILEGED_VIEWER
                      );
                  updateFieldValue('roles', updatedRoles);
                  if (value === false) {
                    updateFieldValue('viewer', null);
                    setErrors([]);
                  }
                }}
                data-testid="pilot-web:update-pilot-user-form:is-privileged-viewer-checkbox"
              />
            }
          />
          {isPrivilegedViewer && (
            <TextField
              label="Area of Visibility (GEOJSON)"
              error={isErrorInField('Area of visibility')}
              helperText={
                isErrorInField('Area of visibility')
                  ? 'Copy + paste correct geojson feature format'
                  : ''
              }
              name="areaOfVisibility"
              multiline
              fullWidth
              variant="outlined"
              value={formatAreaOfVisibility()}
              data-testid="pilot-web:update-pilot-user-form:area-of-visibility"
              onChange={handleAreaOfVisibilityChanges}
            />
          )}

          <div className={classes.errorDiv}>
            <FormHelperText
              error
              data-testid="pilot-web:update-pilot-user-form:error-text"
            >
              {errors?.join(', ')}
            </FormHelperText>
          </div>

          <div className={classes.buttonDiv}>
            <ButtonBase
              className="edit-pilot-user-button"
              type="button"
              disabled={isLoading}
              onClick={() => returnToManagePilots()}
              data-testid="pilot-web:update-pilot-user-form:back-button"
            >
              Back
            </ButtonBase>
            <ButtonBase
              className="edit-pilot-user-button reset-edited-pilot"
              type="button"
              disabled={!haveChanges || isLoading}
              onClick={() => resetEditedPilot()}
              data-testid="pilot-web:update-pilot-user-form:reset-button"
            >
              Reset
            </ButtonBase>
            <ButtonBase
              className="edit-pilot-user-button update-pilot-user"
              type="submit"
              disabled={!haveChanges || isLoading}
              data-testid="pilot-web:update-pilot-user-form:save-button"
            >
              Save Changes
            </ButtonBase>
          </div>
        </form>
      )}
    </>
  );
}

export const EditPilotUser = withRouter(EditPilotUserComponent);
