import React from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink, withRouter } from 'react-router-dom';

import { versionDescription } from 'argus-common/version';
import {
  useIsOrgAdmin,
  useIsPrivilegedViewer,
  useIsSuperAdmin,
} from '../../state/session/hooks';
import { Avatar } from '../shared/avatar/avatar';
import { CustomerLogo } from '../shared/customer-logo/customer-logo';
import { useIsAuthenticated } from '../shared/hooks/is-authenticated.hook';
import './navigation-bar.scss';

import {
  FLIGHT_REQUESTS_PATH,
  UAVS_PATH,
  NOTAMS_ROUTE,
  SETTINGS_PATH,
  PROFILE_PATH,
  ORG_PILOTS_PATH,
  HOME_PATH,
  INFORMATION_PATH,
  AIRSPACE_ACTIVITY_PATH,
} from '~/routes';

export function NavigationBarComponent({ location }: RouteComponentProps) {
  const isAuthenticated = useIsAuthenticated();
  const isOrgAdmin = useIsOrgAdmin();
  const isPrivilegedViewer = useIsPrivilegedViewer();
  const isSuperAdmin = useIsSuperAdmin();

  // If this a proper build then use the canonical version number (last part of the versionDescription)
  // Otherwise this is just dev
  const version =
    versionDescription.indexOf('_') > 0
      ? versionDescription.substring(versionDescription.lastIndexOf('_') + 1)
      : ' Dev';

  return (
    <div className="navigation-bar" data-testid="pilot-web:navigation-bar">
      <NavLink
        to={{ pathname: HOME_PATH, state: { referrer: location.pathname } }}
      >
        <CustomerLogo />
      </NavLink>
      <NavLink
        to={{ pathname: PROFILE_PATH, state: { referrer: location.pathname } }}
        activeClassName="is-selected"
      >
        <div
          className="profile"
          data-testid="pilot-web:nav-bar:profile-nav-button"
        >
          <Avatar />
        </div>
      </NavLink>

      <nav className="nav">
        {!isAuthenticated && (
          <NavLink
            to={INFORMATION_PATH}
            className="nav-item"
            activeClassName="is-selected"
            data-testid="pilot-web:nav-bar:information-nav-button"
          >
            <div className="icon map" />
            <label className="label">Information</label>
          </NavLink>
        )}
        {isAuthenticated && (
          <>
            <NavLink
              to={FLIGHT_REQUESTS_PATH}
              className="nav-item"
              activeClassName="is-selected"
              data-testid="pilot-web:nav-bar:flights-nav-button"
            >
              <div className="icon flights" />
              <label className="label">Flights</label>
            </NavLink>
            <NavLink
              to={UAVS_PATH}
              className="nav-item"
              activeClassName="is-selected"
              data-testid="pilot-web:nav-bar:uav-link"
            >
              <div className="icon uav" />
              <label className="label">UAVs</label>
            </NavLink>
          </>
        )}
        <NavLink
          className="nav-item"
          to={NOTAMS_ROUTE}
          activeClassName="is-selected"
          data-testid="pilot-web:nav-bar:notam-nav-button"
        >
          <div className="icon notam" />
          <label className="label">NOTAM</label>
        </NavLink>
        {isAuthenticated && isOrgAdmin && (
          <>
            <NavLink
              to={ORG_PILOTS_PATH}
              className="nav-item"
              activeClassName="is-selected"
              data-testid="pilot-web:nav-bar:pilots-link"
            >
              <div className="icon pilots" />
              <label className="label">Pilots</label>
            </NavLink>
          </>
        )}
        {isAuthenticated && (isPrivilegedViewer || isSuperAdmin) ? (
          <>
            <NavLink
              to={AIRSPACE_ACTIVITY_PATH}
              className="nav-item activity"
              activeClassName="is-selected"
              data-testid="pilot-web:nav-bar:airspace-activity-link"
            >
              <div className="icon activity" />
              <label className="label">
                Airspace
                <br />
                Activity
              </label>
            </NavLink>
          </>
        ) : null}
        {isAuthenticated && (
          <>
            <NavLink
              to={SETTINGS_PATH}
              className="nav-item"
              activeClassName="is-selected"
              data-testid="pilot-web:nav-bar:settings-nav-button"
            >
              <div className="icon settings" />
              <label className="label">Settings</label>
            </NavLink>
          </>
        )}
      </nav>
      <div className="version">V{version}</div>
    </div>
  );
}

export const NavigationBar = withRouter(NavigationBarComponent);
