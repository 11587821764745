import React, { useState } from 'react';
import { type match, matchPath } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { type PaperProps } from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import {
  AltitudeType,
  OverlappingEdge,
  OverlappingEdgeDetails,
  OverlappingType,
} from '@airshare/external-api-types';
import { StyledFlightAdvisory } from '@airshare/pilot-types';

import { useFocussedFlightRequest } from '../../../state/flight-requests/hooks';
import {
  useOverlapSummary,
  type LocalOverlapDetail,
} from '../../map-screen/hooks/use-overlap-summary';
import { useLocation } from '../hooks/router.hook';
import { FLIGHT_PLAN_EDIT_PATH } from '../../../routes';
import {
  formatOverlappingTypeText,
  getHighlightedStyle,
} from './flight-advisories.helpers';
import { useFormState } from '~/state/flight-plan/flight-plan-form/hooks';
import { useHighlightedFlightAdvisoriesUpdated } from '../../../state/flight-advisories/hooks';

import './flight-advisory-overlap-modal.scss';

const FORMAT_OVERLAPPING_COLOR = 'var(--color-highlight-orange)';
const FORMAT_CONFLICTING_COLOR = 'var(--color-highlight-red)';

interface OtherFlightSegmentId {
  flightId: number;
  segmentId: string | undefined;
}

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

export const FlightAdvisoryOverlapModal = ({
  showModal,
  closeModal,
}: Props) => {
  const location = useLocation();
  const focussedFlight = useFocussedFlightRequest();
  const form = useFormState();
  const { overlapSummary } = useOverlapSummary();
  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();

  const [onlyShowOverlaps, setOnlyShowOverlaps] = useState(false);

  const handleCancel = () => {
    setHighlightedNearbyFlights([]);
    closeModal();
  };
  const handleClose = () => {
    setHighlightedNearbyFlights([]);
    closeModal();
  };

  const handleMouseover = (a: OtherFlightSegmentId) => {
    const flightToHighlight: StyledFlightAdvisory = {
      ...a,
      style: getHighlightedStyle(),
      mapOnly: true,
    };
    setHighlightedNearbyFlights([flightToHighlight]);
  };
  const handleMouseleave = () => {
    setHighlightedNearbyFlights([]);
  };

  const editPathMatch: match<{ id: string }> = matchPath(location.pathname, {
    path: FLIGHT_PLAN_EDIT_PATH,
    exact: true,
    strict: true,
  });
  const edittingFlightId = editPathMatch?.isExact
    ? editPathMatch.params.id
    : '';

  const thisFlightLabel =
    focussedFlight?.properties?.flightId?.toString() ||
    edittingFlightId ||
    'Draft Flight';

  const formStartDateTime: DateTime | null =
    form?.dateAndTime instanceof DateTime
      ? form?.dateAndTime
      : form?.dateAndTime instanceof Date
        ? DateTime.fromJSDate(form?.dateAndTime)
        : null;

  const thisFlightProps: {
    minAltitudeFeet: number;
    maxAltitudeFeet: number;
    startDateTime: string;
    endDateTime: string;
  } = focussedFlight?.properties || {
    startDateTime: formStartDateTime?.toISO(),
    endDateTime: formStartDateTime
      ?.plus({ minutes: form.durationMinutes })
      .toISO(),
    minAltitudeFeet: 0,
    maxAltitudeFeet: form?.altitudeFeet,
  };

  const thisFlightSegmentFeatures =
    focussedFlight?.properties?.segmentCollection?.features ||
    form?.segmentCollection?.features;

  const timezone =
    focussedFlight?.properties?.timezone ||
    (form?.dateAndTime instanceof DateTime
      ? form?.dateAndTime?.zoneName
      : undefined) ||
    window.env.DEFAULT_TZ;

  const hideFullFlightSection =
    thisFlightSegmentFeatures?.length > 0 &&
    overlapSummary?.fullFlight?.some(
      (s) =>
        (onlyShowOverlaps &&
          s.overallOverlap === OverlappingType.OVERLAPPING) ||
        (!onlyShowOverlaps && s.overallOverlap !== OverlappingType.DISTANT)
    );

  return showModal ? (
    <Dialog
      open={showModal}
      className="flight-advisory-overlap-modal"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      maxWidth="md"
    >
      <DialogTitle
        disableTypography={true}
        className="flight-advisory-overlap-modal-title-wrapper"
      >
        <Typography
          variant="h6"
          component="h1"
          align="left"
          style={{ cursor: 'move' }}
          data-testid="pilot-web:flight-advisory-overlap-modal:dialog-title"
          id="flight-advisory-overlap-modal-draggable-title"
          className="flight-advisory-overlap-modal-title"
        >
          Nearby and Overlapping Flights
        </Typography>
        <Typography
          variant="subtitle1"
          align="right"
          className="flight-advisory-overlap-modal-title-switch-label"
        >
          {formatOverlappingTypeText({ overlap: OverlappingType.OVERLAPPING })}{' '}
          only
        </Typography>
        <Switch
          name="onlyShowOverlaps"
          size="small"
          color="primary"
          checked={onlyShowOverlaps}
          onChange={() => {
            setOnlyShowOverlaps((prev) => !prev);
          }}
        />
      </DialogTitle>
      <DialogContent className="flight-advisory-overlap-modal-content">
        <div className="flight-advisory-overlap-modal-header-grid">
          <GridTextItem text="This Flight" isHeader={true} />
          <GridTextItem text="Other Flight" isHeader={true} />
          <GridTextItem text="Altitude" isHeader={true} />
          <GridTextItem text="Time" isHeader={true} />
          <GridTextItem text="Location" isHeader={true} />
          <GridTextItem text="Overall" isHeader={true} />
        </div>
        <div className="flight-advisory-overlap-modal-details-grid">
          {!hideFullFlightSection && (
            <MyFlightAreaHeaderRow
              title={thisFlightLabel}
              minAltitudeFeet={thisFlightProps?.minAltitudeFeet || 0}
              maxAltitudeFeet={thisFlightProps?.maxAltitudeFeet || 0}
              altitudeType={AltitudeType.AGL}
              startDateTime={DateTime.fromISO(
                thisFlightProps?.startDateTime || ''
              )}
              endDateTime={DateTime.fromISO(thisFlightProps?.endDateTime || '')}
              timezone={timezone}
            />
          )}
          {!hideFullFlightSection &&
            overlapSummary?.fullFlight?.map((overlapDetail) => {
              return (
                <FlightOverlapDetailRow
                  overlapDetail={overlapDetail}
                  key={`${overlapDetail.mySegmentId}-${overlapDetail.otherFlightId}-${overlapDetail.otherSegmentId}`}
                  timezone={timezone}
                  onlyShowOverlaps={onlyShowOverlaps}
                  handleMouseover={handleMouseover}
                  handleMouseleave={handleMouseleave}
                />
              );
            })}
          {thisFlightSegmentFeatures?.length > 0 &&
            thisFlightSegmentFeatures.map((feature) => {
              const mySegmentLabel = `Segment ${feature.properties.id}`;
              const segmentOverlapDetails =
                overlapSummary?.segments[feature.properties.id];
              const hasNearbyOrOverlappingFlights = segmentOverlapDetails?.some(
                (s) =>
                  (onlyShowOverlaps &&
                    s.overallOverlap === OverlappingType.OVERLAPPING) ||
                  (!onlyShowOverlaps &&
                    s.overallOverlap !== OverlappingType.DISTANT)
              );

              return hasNearbyOrOverlappingFlights ? (
                <>
                  <MyFlightAreaHeaderRow
                    key={mySegmentLabel}
                    title={mySegmentLabel}
                    minAltitudeFeet={feature.properties?.minAltitudeFeet || 0}
                    maxAltitudeFeet={feature.properties?.maxAltitudeFeet || 0}
                    altitudeType={
                      feature.properties?.altitudeType || AltitudeType.AGL
                    }
                    startDateTime={DateTime.fromISO(
                      feature.properties?.startDateTime || ''
                    )}
                    endDateTime={DateTime.fromISO(
                      feature.properties?.endDateTime || ''
                    )}
                    locationDescription={
                      feature.properties?.segmentStreetAddress
                    }
                    timezone={timezone}
                  />
                  {segmentOverlapDetails?.map((overlapDetail) => {
                    return (
                      <FlightOverlapDetailRow
                        overlapDetail={overlapDetail}
                        key={`${overlapDetail.mySegmentId}-${overlapDetail.otherFlightId}-${overlapDetail.otherSegmentId}`}
                        timezone={timezone}
                        onlyShowOverlaps={onlyShowOverlaps}
                        handleMouseover={handleMouseover}
                        handleMouseleave={handleMouseleave}
                      />
                    );
                  })}
                </>
              ) : null;
            })}
        </div>
      </DialogContent>
      <DialogActions className="flight-advisory-overlap-modal-dialog-actions">
        <div className="flight-advisory-overlap-modal-info-message-wrapper">
          <Typography
            variant="subtitle1"
            className="flight-advisory-overlap-modal-info-message"
          >
            The <strong style={OVERLAP_TEXT_VALUE_STYLE}>orange</strong> color
            shows when the specific value of another flight overlaps with this
            flight
            {hideFullFlightSection ? ' segment' : ''}. The overall status of the
            other flight is{' '}
            <strong style={OVERLAP_TEXT_VALUE_STYLE}>
              {formatOverlappingTypeText({
                overlap: OverlappingType.OVERLAPPING,
              })}
            </strong>{' '}
            only when the altitude, time and location are all overlapping.
          </Typography>
        </div>
        <ButtonBase
          onClick={handleCancel}
          data-testid="pilot-web:flight-advisory-overlap-modal:dialog-close-button"
          className="close-modal-button"
        >
          Close
        </ButtonBase>
      </DialogActions>
    </Dialog>
  ) : null;
};

const MyFlightAreaHeaderRow = ({
  title,
  minAltitudeFeet,
  maxAltitudeFeet,
  altitudeType,
  startDateTime,
  endDateTime,
  locationDescription,
  timezone,
}: {
  title: string;
  minAltitudeFeet: number;
  maxAltitudeFeet: number;
  altitudeType: AltitudeType;
  startDateTime: DateTime;
  endDateTime: DateTime;
  locationDescription?: string;
  timezone: string;
}): JSX.Element => {
  const formattedTime = `${startDateTime.setZone(timezone).toFormat('HH:mm')}-${endDateTime.setZone(timezone).toFormat('HH:mm')}`;
  return (
    <>
      <GridTextItem text={title} isMyFlight={true} />
      <EmptyGridItem isMyFlight={true} />
      <GridTextItem
        text={`${minAltitudeFeet}-${maxAltitudeFeet} ft ${altitudeType}`}
        isMyFlight={true}
      />
      <GridTextItem text={formattedTime} isMyFlight={true} />
      {locationDescription ? (
        <GridTextItem
          text={locationDescription}
          isMyFlight={true}
          className="address"
          tooltip={locationDescription}
        />
      ) : (
        <EmptyGridItem isMyFlight={true} />
      )}
      <EmptyGridItem isMyFlight={true} />
    </>
  );
};

const FlightOverlapDetailRow = ({
  overlapDetail,
  timezone,
  onlyShowOverlaps,
  handleMouseover,
  handleMouseleave,
}: {
  overlapDetail: LocalOverlapDetail;
  timezone: string;
  onlyShowOverlaps: boolean;
  handleMouseover: (a: OtherFlightSegmentId) => void;
  handleMouseleave: () => void;
}): JSX.Element => {
  const {
    otherFlightId,
    otherSegmentId,
    otherMinAltitudeFeet,
    otherMaxAltitudeFeet,
    otherStartDateTime,
    otherEndDateTime,
    altitudeOverlap,
    timeOverlap,
    areaOverlap,
    overallOverlap,
    isConflicting,
  } = overlapDetail;

  if (overallOverlap === OverlappingType.DISTANT) {
    return null;
  }

  if (onlyShowOverlaps && overallOverlap !== OverlappingType.OVERLAPPING) {
    return null;
  }

  const label =
    `${otherFlightId}` + (otherSegmentId ? `-${otherSegmentId}` : '');

  const onMouseOver = () =>
    handleMouseover({
      flightId: otherFlightId,
      segmentId: otherSegmentId,
    });

  return (
    <>
      <EmptyGridItem />
      <GridTextItem
        text={label}
        onMouseOver={onMouseOver}
        onMouseLeave={handleMouseleave}
      />

      <GridTextRangeItem
        lowerValueText={otherMinAltitudeFeet?.toString()}
        endValueText={otherMaxAltitudeFeet?.toString()}
        trailingText=" ft AGL"
        overlappingEdgeDetails={altitudeOverlap}
        onMouseOver={onMouseOver}
        onMouseLeave={handleMouseleave}
      />
      <GridTextRangeItem
        lowerValueText={otherStartDateTime.setZone(timezone).toFormat('HH:mm')}
        endValueText={otherEndDateTime.setZone(timezone).toFormat('HH:mm')}
        overlappingEdgeDetails={timeOverlap}
        onMouseOver={onMouseOver}
        onMouseLeave={handleMouseleave}
      />
      <GridTextItem
        text={formatOverlappingTypeText(areaOverlap)}
        color={
          areaOverlap?.overlap === OverlappingType.OVERLAPPING
            ? FORMAT_OVERLAPPING_COLOR
            : ''
        }
        onMouseOver={onMouseOver}
        onMouseLeave={handleMouseleave}
      />
      {isConflicting ? (
        <GridTextItem
          text="Conflicting"
          color={FORMAT_CONFLICTING_COLOR}
          onMouseOver={onMouseOver}
          onMouseLeave={handleMouseleave}
        />
      ) : (
        <GridTextItem
          text={formatOverlappingTypeText({ overlap: overallOverlap })}
          color={
            overallOverlap === OverlappingType.OVERLAPPING
              ? FORMAT_OVERLAPPING_COLOR
              : ''
          }
          onMouseOver={onMouseOver}
          onMouseLeave={handleMouseleave}
        />
      )}
    </>
  );
};

const GridTextItem = ({
  text,
  className = '',
  color = null,
  isHeader = false,
  isMyFlight = false,
  tooltip = null,
  onMouseOver,
  onMouseLeave,
}: {
  text: string;
  className?: string;
  color?: string | null;
  isHeader?: boolean;
  isMyFlight?: boolean;
  tooltip?: string | null;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}): JSX.Element => {
  const style = color ? { color, fontWeight: 600 } : {};
  const body = (
    <Typography
      variant={isHeader ? 'subtitle1' : 'body2'}
      className={classNames(
        isHeader
          ? 'flight-advisory-overlap-modal-grid-header-item-label'
          : 'flight-advisory-overlap-modal-grid-item-label',
        className
      )}
      style={style}
    >
      {text}
    </Typography>
  );
  return (
    <div
      className={classNames(
        'flight-advisory-overlap-modal-grid-item',
        isMyFlight ? 'myflight' : ''
      )}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseLeave={onMouseLeave}
      role="tooltip"
    >
      {tooltip ? (
        <Tooltip title={<div style={{ fontSize: '1rem' }}>{tooltip}</div>}>
          {body}
        </Tooltip>
      ) : (
        body
      )}
    </div>
  );
};

const OVERLAP_TEXT_VALUE_STYLE = {
  color: FORMAT_OVERLAPPING_COLOR,
  display: 'contents',
  fontWeight: 600,
};
const DEFAULT_TEXT_VALUE_STYLE = {
  display: 'contents',
};

const GridTextRangeItem = ({
  lowerValueText,
  endValueText,
  trailingText,
  overlappingEdgeDetails,
  onMouseOver,
  onMouseLeave,
}: {
  lowerValueText: string;
  endValueText: string;
  trailingText?: string;
  overlappingEdgeDetails: OverlappingEdgeDetails;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}): JSX.Element => {
  const lowerValueStyle =
    [OverlappingEdge.BOTH, OverlappingEdge.START].includes(
      overlappingEdgeDetails.edge
    ) && overlappingEdgeDetails.overlap === OverlappingType.OVERLAPPING
      ? OVERLAP_TEXT_VALUE_STYLE
      : DEFAULT_TEXT_VALUE_STYLE;
  const endValueStyle =
    [OverlappingEdge.BOTH, OverlappingEdge.END].includes(
      overlappingEdgeDetails.edge
    ) && overlappingEdgeDetails.overlap === OverlappingType.OVERLAPPING
      ? OVERLAP_TEXT_VALUE_STYLE
      : DEFAULT_TEXT_VALUE_STYLE;
  return (
    <div
      className="flight-advisory-overlap-modal-grid-item"
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseLeave={onMouseLeave}
      role="tooltip"
    >
      <Typography variant={'body2'}>
        <div style={lowerValueStyle}>{lowerValueText}</div>-
        <div style={endValueStyle}>{endValueText}</div>
        {trailingText}
      </Typography>
    </div>
  );
};

const EmptyGridItem = ({
  isMyFlight = false,
}: {
  isMyFlight?: boolean;
}): JSX.Element => {
  return (
    <div
      className={classNames(
        'flight-advisory-overlap-modal-grid-item',
        isMyFlight ? 'myflight' : ''
      )}
    />
  );
};

function PaperComponent(props: Readonly<PaperProps>) {
  return (
    <Draggable
      handle="#flight-advisory-overlap-modal-draggable-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default FlightAdvisoryOverlapModal;
