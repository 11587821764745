/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginStatus, getAccountIsActive } from '~/state/session/selectors';
import { attemptLogin, setLoginStatus } from '~/state/session/action-creators';
import { LogInStatus } from '~/state/session/constants';
import { useSetFieldValue } from '~/state/session/auth-forms/activation/hooks';

export function useLogin() {
  const dispatch = useDispatch();

  const loginStatus = useSelector(getLoginStatus);

  function dispatchAttemptLogin(payload: any) {
    return dispatch(attemptLogin(payload));
  }

  function dispatchResetLoginStatus() {
    return dispatch(setLoginStatus(LogInStatus.IDLE));
  }

  return [loginStatus, dispatchAttemptLogin, dispatchResetLoginStatus];
}

interface Props {
  handleLoginAttempt: (isActive: boolean) => void;
  email: string;
}

export function useHandleInactiveAccount({ handleLoginAttempt, email }: Props) {
  const isActive = useSelector(getAccountIsActive);
  const setActivationFormField = useSetFieldValue();
  useEffect(() => {
    handleLoginAttempt(!isActive);
    setActivationFormField('email', email);
  }, [isActive]);
}
