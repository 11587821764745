import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import {
  FETCH_REQUESTED,
  FETCH_SUCCEEDED,
  FETCH_FAILED,
  FETCH_STATUS_RESET,
  CREATION_REQUESTED,
  CREATION_SUCCEEDED,
  CREATION_FAILED,
  CREATION_STATUS_RESET,
  UPDATE_REQUESTED,
  UPDATE_SUCCEEDED,
  UPDATE_FAILED,
  UPDATE_STATUS_RESET,
  DELETION_REQUESTED,
  DELETION_SUCCEEDED,
  DELETION_FAILED,
  DELETION_STATUS_RESET,
} from './actions';
import {
  OrgPilotsFetchStatus,
  OrgPilotsCreationStatus,
  OrgPilotsUpdateStatus,
  OrgPilotsDeletionStatus,
} from './constants';
import { orgPilotsFormReducer } from './org-pilots-form/reducers';

export const REDUCER_KEY = 'orgPilots';

const INITIAL_FETCH_STATUS_STATE = OrgPilotsFetchStatus.IDLE;
const orgPilotsFetchStatusReducer = createReducer(
  {
    [FETCH_REQUESTED]: () => OrgPilotsFetchStatus.ORG_PILOTS_FETCH_IN_PROGRESS,
    [FETCH_SUCCEEDED]: () => OrgPilotsFetchStatus.SUCCESS,
    [FETCH_FAILED]: () => OrgPilotsFetchStatus.FAILED,
    [FETCH_STATUS_RESET]: () => INITIAL_FETCH_STATUS_STATE,
  },
  INITIAL_FETCH_STATUS_STATE
);

const INITIAL_CREATION_STATUS_STATE = OrgPilotsCreationStatus.IDLE;
const orgPilotsCreationStatusReducer = createReducer(
  {
    [CREATION_REQUESTED]: () =>
      OrgPilotsCreationStatus.ORG_PILOTS_CREATION_IN_PROGRESS,
    [CREATION_SUCCEEDED]: () => OrgPilotsCreationStatus.SUCCESS,
    [CREATION_FAILED]: () => OrgPilotsCreationStatus.FAILED,
    [CREATION_STATUS_RESET]: () => INITIAL_CREATION_STATUS_STATE,
  },
  INITIAL_CREATION_STATUS_STATE
);

const INITIAL_UPDATE_STATUS_STATE = OrgPilotsUpdateStatus.IDLE;
const orgPilotsUpdateStatusReducer = createReducer(
  {
    [UPDATE_REQUESTED]: () =>
      OrgPilotsUpdateStatus.ORG_PILOTS_UPDATE_IN_PROGRESS,
    [UPDATE_SUCCEEDED]: () => OrgPilotsUpdateStatus.SUCCESS,
    [UPDATE_FAILED]: () => OrgPilotsUpdateStatus.FAILED,
    [UPDATE_STATUS_RESET]: () => INITIAL_UPDATE_STATUS_STATE,
  },
  INITIAL_UPDATE_STATUS_STATE
);

const INITIAL_DELETION_STATUS_STATE = OrgPilotsDeletionStatus.IDLE;
const orgPilotsDeletionStatusReducer = createReducer(
  {
    [DELETION_REQUESTED]: () =>
      OrgPilotsDeletionStatus.ORG_PILOTS_DELETION_IN_PROGRESS,
    [DELETION_SUCCEEDED]: () => OrgPilotsDeletionStatus.SUCCESS,
    [DELETION_FAILED]: () => OrgPilotsDeletionStatus.FAILED,
    [DELETION_STATUS_RESET]: () => INITIAL_DELETION_STATUS_STATE,
  },
  INITIAL_DELETION_STATUS_STATE
);

const DEFAULT_ORG_PILOTS_STATE = [];
export const orgPilotsDataReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (_, { payload }) => payload,
  },
  DEFAULT_ORG_PILOTS_STATE
);

export const orgPilotsReducer = combineReducers({
  data: orgPilotsDataReducer,
  fetchStatus: orgPilotsFetchStatusReducer,
  creationStatus: orgPilotsCreationStatusReducer,
  updateStatus: orgPilotsUpdateStatusReducer,
  deletionStatus: orgPilotsDeletionStatusReducer,
  ...orgPilotsFormReducer,
});
