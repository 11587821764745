import React, { useState } from 'react';
import { TextField, FormHelperText, InputAdornment } from '@material-ui/core';
import {
  COMPLETION_DELAY,
  LoadingButton,
  useTimeoutEffect,
} from 'airshare-pilot-web-shared';
import { resetPassword } from '../../../../pilot-api-client';
import { emailValidationHint } from './reset-password-helpers';

import './reset-password.scss';

const enum ResetPasswordStatus {
  IDLE = 'IDLE',
  RESET_PASSWORD_ATTEMPT_IN_PROGRESS = 'RESET_PASSWORD_ATTEMPT_IN_PROGRESS',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export default function VerificationCodeRequest({
  updateResetPasswordStep,
}: {
  updateResetPasswordStep: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [email, updateEmail] = useState<string>('');
  const [emailHint, updateEmailHint] = useState<string>('');
  const [error, updateError] = useState<string>('');

  const [requestVerificationCodeStatus, updateRequestVerificationCodeStatus] =
    useState<ResetPasswordStatus>(ResetPasswordStatus.IDLE);

  useTimeoutEffect(
    () => updateResetPasswordStep(email),
    COMPLETION_DELAY,
    requestVerificationCodeStatus === ResetPasswordStatus.SUCCESS
  );

  function isValidEmail(value: string): boolean {
    return emailHint === '' && value !== '';
  }

  function handleUpdateEmail(value: string) {
    updateEmailHint(emailValidationHint(value));
    updateEmail(value);
  }

  function handleRequestVerificationCode() {
    if (
      requestVerificationCodeStatus !==
      ResetPasswordStatus.RESET_PASSWORD_ATTEMPT_IN_PROGRESS
    ) {
      updateRequestVerificationCodeStatus(
        ResetPasswordStatus.RESET_PASSWORD_ATTEMPT_IN_PROGRESS
      );
      resetPassword(email)
        .then(() =>
          updateRequestVerificationCodeStatus(ResetPasswordStatus.SUCCESS)
        )
        .catch((err: Error) => {
          updateError(err.message);
          updateRequestVerificationCodeStatus(ResetPasswordStatus.FAILURE);
        });
    }
  }

  return (
    <>
      <TextField
        type="email"
        name="email"
        className="email-input"
        inputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="input-icon email" />
            </InputAdornment>
          ),
          'data-testid': 'pilot-web:reset-password-form:email-input',
        }}
        placeholder="Enter your email"
        value={email}
        onChange={(e) => handleUpdateEmail(e.target.value)}
        error={Boolean(error)}
      />
      <FormHelperText
        className="input-hint"
        error={Boolean(emailHint)}
        data-testid="pilot-web:reset-password-form:email-input-hint"
      >
        {emailHint}
      </FormHelperText>
      <FormHelperText
        className="validation-errors"
        error
        data-testid="pilot-web:reset-password-form:validation-error"
      >
        {error}
      </FormHelperText>

      <LoadingButton
        data-testid="pilot-web:reset-password-form:get-code-button"
        disabled={!isValidEmail(email)}
        isLoading={
          requestVerificationCodeStatus ===
          ResetPasswordStatus.RESET_PASSWORD_ATTEMPT_IN_PROGRESS
        }
        className="form-control"
        type="button"
        onClick={handleRequestVerificationCode}
      >
        Get Verification Code
      </LoadingButton>
    </>
  );
}
