import React from 'react';
import cn from 'classnames';
import { useTransition, animated } from 'react-spring';

import './loading.scss';

interface LoadingProps {
  className?: string;
}

export function Loading({ className = 'loading' }: LoadingProps) {
  const transitions = useTransition(true, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className={cn('loader', { [className]: className })}
              data-testid="loading"
            >
              Loading...
            </animated.div>
          )
      )}
    </>
  );
}
