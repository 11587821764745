export const OrgPilotsFetchStatus = Object.freeze({
  IDLE: 'IDLE',
  ORG_PILOTS_FETCH_IN_PROGRESS: 'ORG_PILOTS_FETCH_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const OrgPilotsCreationStatus = Object.freeze({
  IDLE: 'IDLE',
  ORG_PILOTS_CREATION_IN_PROGRESS: 'ORG_PILOTS_CREATION_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const OrgPilotsDeletionStatus = Object.freeze({
  IDLE: 'IDLE',
  ORG_PILOTS_DELETION_IN_PROGRESS: 'ORG_PILOTS_DELETIO_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const OrgPilotsUpdateStatus = Object.freeze({
  IDLE: 'IDLE',
  ORG_PILOTS_UPDATE_IN_PROGRESS: 'ORG_PILOTS_UPDATE_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});
