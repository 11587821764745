import React from 'react';
import cn from 'classnames';
import { Status } from '@airshare/external-api-types';

import { resolveSingleStatus } from '../../lib/multi-status-check';
import { getStatusText } from '../../lib/format-status';

import './status-pill.scss';
interface Props {
  id: string;
  status: string | Status[];
  className?: string;
  variant?: string;
  testId?: string;
}

const StatusPill: React.FC<Props> = ({
  id,
  status,
  className,
  variant = 'contained',
  testId,
}) => (
  <div
    id={id}
    data-testid={testId ? testId : 'pilot-web:flight-briefing:flight-status'}
    className={cn('status-pill', {
      [className as string]: className,
      [variant]: variant,
    })}
    data-status={
      typeof status === 'string'
        ? status.toLowerCase()
        : resolveSingleStatus(status)?.toLowerCase()
    }
  >
    {getStatusText(status)}
  </div>
);

export default StatusPill;
