import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { AirshareUserRole } from 'argus-common/enums';

import { useSignout } from '../../../../state/session/hooks';
import { useAppConfig } from '../../../../state/app-config';
import { useProfile } from '../../../../state/profile/hooks';
import { useNavigateTo } from '../../../shared/hooks/router.hook';

import { pilotAPI } from '../../../../pilot-api-client/api';
import {
  MANAGE_ORGS_PATH,
  MANAGE_PILOT_USERS_PATH,
  EDIT_APP_DEFAULTS_PATH,
} from '../../../../routes';

import { AreYouSureCancel } from '../../../shared/confirmation-with-cancel/AreYouSureCancel';

import './settings.scss';

export function Settings() {
  const signout = useSignout();
  const appConfig = useAppConfig();
  const [isRequestingDelete, updateIsRequestingDelete] = useState(false);
  const [isRequestingDeleteComplete, updateIsRequestingDeleteComplete] =
    useState(false);
  const [isRequestSuccessful, updateIsRequestiSuccessful] = useState(false);
  const profile = useProfile();

  const navigateTo = useNavigateTo();

  async function deleteAccount() {
    const deleteResponse = await pilotAPI.post('/user');

    if (deleteResponse.data.success === 'OK') {
      updateIsRequestingDeleteComplete(true);
      updateIsRequestiSuccessful(true);
    } else {
      updateIsRequestingDeleteComplete(true);
      updateIsRequestiSuccessful(false);
    }
  }

  function handleRequestDeleteAccount() {
    updateIsRequestingDelete(true);
  }

  function handleCancelDeleteAccount() {
    updateIsRequestingDelete(false);
    updateIsRequestingDeleteComplete(false);
  }

  const onManageOrgsClicked = () => {
    navigateTo(MANAGE_ORGS_PATH);
  };

  const onEditAppDefaultsClicked = () => {
    navigateTo(EDIT_APP_DEFAULTS_PATH);
  };

  const onManagePilotUsersClicked = () => {
    navigateTo(MANAGE_PILOT_USERS_PATH);
  };

  return (
    <div className="settings-panel" data-testid="pilot-web:settings-panel">
      <Typography
        className="heading"
        variant="h6"
        component="h1"
        align="center"
        data-testid="pilot-web:settings-panel:header"
      >
        Settings
      </Typography>

      <div className="links-container">
        {profile?.roles.includes(AirshareUserRole.SUPER_ADMIN) ? (
          <>
            <button
              type="button"
              onClick={onManageOrgsClicked}
              className="link manage"
              data-testid="pilot-web:settings-panel:manage-org-link"
            >
              Manage Organisations
            </button>
            <button
              type="button"
              onClick={onManagePilotUsersClicked}
              className="link manage"
              data-testid="pilot-web:settings-panel:manage-pilot-users-link"
            >
              Manage Pilot Users
            </button>
            <button
              type="button"
              onClick={onEditAppDefaultsClicked}
              className="link manage"
              data-testid="pilot-web:settings-panel:edit-app-defaults-link"
            >
              Edit App Defaults
            </button>
          </>
        ) : null}

        {appConfig?.settingsPage?.map(({ link, label }) => (
          <a
            href={link}
            key={label}
            className="link"
            rel="noopener noreferrer"
            target="_blank"
            data-testid="pilot-web:settings-panel:external-url-link"
          >
            {label}
          </a>
        ))}
        <button
          type="button"
          onClick={() => handleRequestDeleteAccount()}
          className="link delete"
          data-testid="pilot-web:settings-panel:delete-account-link"
        >
          Delete Account
        </button>
        <button
          type="button"
          onClick={() => signout()}
          className="link signout"
          data-testid="pilot-web:sign-out-button"
        >
          Sign Out
        </button>
      </div>
      <AreYouSureCancel
        componentLabel="settings-delete-account"
        isRequesting={isRequestingDelete}
        isRequestingComplete={isRequestingDeleteComplete}
        isRequestSuccessful={isRequestSuccessful}
        handleAction={deleteAccount}
        handleCancel={handleCancelDeleteAccount}
        showRequestingComplete={true}
      />
    </div>
  );
}
