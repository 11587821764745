import { DateTime } from 'luxon';
import { resolveSingleStatus } from 'airshare-pilot-web-shared/src/lib/multi-status-check';
import { FRStatusCode } from 'argus-common/flight-requests.lib';
import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

export const isCurrentlyInActivePeriod = (
  flightStartTime?: string,
  durationMinutes?: number
): boolean => {
  if (!flightStartTime || !durationMinutes) return false;
  const startDateTime = DateTime.fromISO(flightStartTime);
  const finishDateTime = DateTime.fromISO(flightStartTime)
    .plus({ minutes: durationMinutes })
    .endOf('day');
  if (!startDateTime) {
    console.error(`Unable to parse invalid start time: ${flightStartTime}`);
    return false;
  }
  const now = DateTime.now();
  return startDateTime.minus({ minutes: 30 }) <= now && now <= finishDateTime;
};

export const isApprovedStatus = (
  flightStatus?: { code: FRStatusCode }[]
): boolean => {
  return (
    !!flightStatus?.length &&
    [
      FRStatusCode.Declared,
      FRStatusCode.ProvAuth,
      FRStatusCode.Authorized,
      FRStatusCode.RNZAFAuthReq,
      FRStatusCode.Activated,
    ].includes(resolveSingleStatus(flightStatus))
  );
};

export const isPastFlight = (
  pastFlights?: ExtendedFlightResponseBodyV2[],
  flight?: ExtendedFlightResponseBodyV2
): boolean =>
  !!(
    flight?.properties?.flightId &&
    pastFlights &&
    pastFlights.find(
      (pastFlight) =>
        flight.properties.flightId === pastFlight.properties.flightId
    )
  );

export const canEndFlight = (
  flightStartTime?: string,
  durationMinutes?: number,
  flightStatus?: { code: FRStatusCode }[]
): boolean =>
  isApprovedStatus(flightStatus) &&
  isCurrentlyInActivePeriod(flightStartTime, durationMinutes);
