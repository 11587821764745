import React from 'react';
import Typography from '@material-ui/core/Typography';
import { AirshareUserRole } from 'argus-common/enums';
import { useDeleteFavourite } from '../../../../state/flight-requests/hooks';
import { useSelectFavourite } from '../../../../state/flight-plan/hooks';
import { FLIGHT_PLAN_PATH } from '../../../../routes';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import type {
  ExtendedFlightResponseBodyV2,
  Profile,
} from '@airshare/pilot-types';

import { useClearForm } from '../../../../state/flight-plan/flight-plan-form/hooks';

export default function FavouriteCard({
  flightRequest,
}: {
  flightRequest: ExtendedFlightResponseBodyV2;
}) {
  const setClearForm = useClearForm();
  const {
    id,
    additionalInfo: { flightPurpose, nickname },
  } = flightRequest.properties;

  const navigateTo = useNavigateTo();
  const profile: Profile = JSON.parse(localStorage.getItem('profile') || '{}');

  const deleteFavourite = useDeleteFavourite();
  function handleDeleteFavourite(event: any) {
    event.stopPropagation();

    deleteFavourite(id);
  }

  const selectFavourite = useSelectFavourite();
  function handleChooseFavourite() {
    setClearForm();

    selectFavourite(flightRequest);

    navigateTo(FLIGHT_PLAN_PATH);
  }

  const isFavouriteOwner = flightRequest.properties.owner === profile.email;
  const isOrgAdminOfFlight =
    profile.roles?.includes(AirshareUserRole.ORG_ADMIN) &&
    flightRequest.properties.additionalInfo.organisationId?.toString() ===
      profile.orgAdmin;

  const canDelete = isFavouriteOwner || isOrgAdminOfFlight;

  return (
    <div
      tabIndex={-1}
      role="button"
      onClick={handleChooseFavourite}
      className="favourite-card"
      data-testid="pilot-web:flights-panel:favourite-card"
    >
      <Typography
        className="nickname"
        variant="subtitle1"
        data-testid="pilot-web:flights-panel:favourite-card:nickname"
      >
        {nickname}
      </Typography>
      <i className="star-icon" />
      <Typography
        className="flight-type"
        variant="subtitle1"
        data-testid="pilot-web:flights-panel:favourite-card:flight-type"
      >
        {flightPurpose}
      </Typography>
      {canDelete && (
        <button
          onClick={handleDeleteFavourite}
          type="button"
          className="delete-button"
          data-testid="pilot-web:flights-panel:favourite-card:delete-button"
        >
          Delete
        </button>
      )}
    </div>
  );
}
