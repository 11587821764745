import React from 'react';
import { DateTime } from 'luxon';
import { DateTimePicker as MUIDateTimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { isBrowserSupported } from './browser-detect';

interface Props {
  setFieldValue: (name: string, value: any) => void;
  onFocus: (event: any) => void;
  dateTime?: DateTime | Date | null;
  isDateAndTimeTouched: boolean;
  isErrored: boolean;
  timezone: string;
}

const useStyles = makeStyles((_theme) => {
  return {
    dateTime: {
      marginTop: 1,
    },
  };
});

const formatDateTime = (dateTime?: DateTime | Date | null) =>
  dateTime instanceof Date
    ? DateTime.fromJSDate(dateTime).toISO({ includeOffset: false })
    : dateTime?.toISO({ includeOffset: false }) || '';

const DateTimePicker = ({
  setFieldValue,
  onFocus,
  dateTime,
  isDateAndTimeTouched,
  isErrored,
  timezone,
}: Props) => {
  const classes = useStyles();
  const showNativePicker = isBrowserSupported();

  if (showNativePicker) {
    return (
      <TextField
        data-testid="datetime-updated"
        id="datetime-local"
        type="datetime-local"
        value={formatDateTime(dateTime)}
        className={classes.dateTime}
        onChange={(event) => {
          setFieldValue(
            'dateAndTime',
            DateTime.fromISO(event.target.value, { zone: timezone })
          );
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <MUIDateTimePicker
      data-testid="datetime-legacy"
      variant="inline"
      margin="normal"
      format="yyyy/MM/dd HH:mm"
      className={classes.dateTime}
      name="dateAndTime"
      disablePast
      autoOk
      PopoverProps={{
        PaperProps: {
          style: {
            width: '340px',
          },
        },
      }}
      hideTabs={true}
      onFocus={onFocus}
      onChange={(value) => setFieldValue('dateAndTime', value)}
      value={dateTime}
      error={isDateAndTimeTouched && isErrored}
      emptyLabel="Enter date and time of flight"
    />
  );
};

export default DateTimePicker;
