import React from 'react';
import { CloseButton } from '../../../shared/close-button/close-button';
import { BackButton } from '../../../shared/back-button/back-button';
import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';
import { useIsInTabletMode } from 'airshare-pilot-web-shared';
import { useNavigateTo } from '../../../shared/hooks/router.hook';

import { ORG_PILOTS_PATH } from '../../../../routes';

export default function LoadingFormWrapper({
  isLoading,
  isReadOnly,
  children,
}: {
  isLoading: boolean;
  isReadOnly: boolean;
  children: any;
}) {
  const isInTabletMode = useIsInTabletMode();
  const navigateTo = useNavigateTo();

  function handleClose() {
    navigateTo(ORG_PILOTS_PATH);
  }

  return (
    <LoadingPanel isLoading={isLoading}>
      {isInTabletMode ? (
        <BackButton onClick={handleClose} />
      ) : (
        <CloseButton onClick={handleClose} />
      )}
      <div
        className={`org-pilots-detail${isReadOnly ? ' read-only' : ''}`}
        data-testid="pilot-web:org-pilot-detail:panel"
      >
        {children}
      </div>
    </LoadingPanel>
  );
}
