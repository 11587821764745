import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';

import { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import {
  FlightRequestsFetchStatus,
  FlightRequestsFetchFocussedStatus,
  FlightRequestsCancelStatus,
  FlightActivationStatus,
  FilterValue,
  FlightRequestsEndStatus,
} from './constants';
import {
  FETCH_REQUESTED,
  FETCH_SUCCEEDED,
  FETCH_FAILED,
  FETCH_STATUS_RESET,
  RESET_STATE,
  FETCH_FOCUSSED_REQUESTED,
  FETCH_FOCUSSED_SUCCEEDED,
  FETCH_FOCUSSED_FAILED,
  FOCUSSED_RESET,
  FETCH_FOCUSSED_STATUS_RESET,
  CANCEL_REQUESTED,
  CANCEL_SUCCEEDED,
  CANCEL_FAILED,
  CANCEL_STATUS_RESET,
  ACTIVATE_FLIGHT_REQUESTED,
  ACTIVATE_FLIGHT_SUCCEEDED,
  ACTIVATE_FLIGHT_FAILED,
  ACTIVATE_FLIGHT_STATUS_RESET,
  STARTED_LISTENING,
  STOPPED_LISTENING,
  STARTED_LISTENING_TO_FOCUSSED,
  STOPPED_LISTENING_TO_FOCUSSED,
  END_REQUESTED,
  END_FAILED,
  END_SUCCEEDED,
  END_STATUS_RESET,
  DELETE_FAVOURITE_SUCCEEDED,
  FetchSucceedActionPayload,
} from './actions';
import { PagesMetaState, PagesState, State } from './saga';
import { FRStatusCode } from '@airshare/external-api-types';

const filterActivated = (fs: ExtendedFlightResponseBodyV2[]) => {
  return fs.filter((x) => x.properties.status === FRStatusCode.Activated);
};

const INITIAL_PAGES_STATE: PagesState = {
  favourite: null,
  current: null,
  past: null,
  activated: null,
};
const flightRequestsPagesReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (
      _: PagesState,
      { payload }: { payload: FetchSucceedActionPayload }
    ) => ({
      [FilterValue.FAVOURITE]: payload.favourite.data,
      [FilterValue.CURRENT]: payload.current.data,
      [FilterValue.PAST]: payload.past.data,
      [FilterValue.ACTIVATED]: filterActivated(
        payload.current.data.concat(payload.past.data)
      ),
    }),
    [DELETE_FAVOURITE_SUCCEEDED]: (
      state: PagesState,
      { payload }: { payload: any }
    ) => ({
      ...state,
      [FilterValue.FAVOURITE]: payload.data.data,
    }),
    [RESET_STATE]: () => INITIAL_PAGES_STATE,
  },
  INITIAL_PAGES_STATE
);

const INITIAL_META_STATE: PagesMetaState = null;
const flightRequestsMetaReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (_: State, { payload }: { payload: any }) => {
      // eslint-disable-next-line no-unused-vars
      const { data: currentData, ...currentMeta } = payload.current;
      // eslint-disable-next-line no-unused-vars
      const { data: pastData, ...pastMeta } = payload.past;
      // eslint-disable-next-line no-unused-vars
      const { data: favouriteData, ...favouriteMeta } = payload.favourite;

      return {
        [FilterValue.FAVOURITE]: favouriteMeta,
        [FilterValue.CURRENT]: currentMeta,
        [FilterValue.PAST]: pastMeta,
      };
    },
    [DELETE_FAVOURITE_SUCCEEDED]: (
      state: State,
      { payload }: { payload: any }
    ) => {
      // eslint-disable-next-line no-unused-vars
      const { data, ...favouriteMeta } = payload.data;

      return {
        ...state,
        [FilterValue.FAVOURITE]: favouriteMeta,
      };
    },
    [RESET_STATE]: () => INITIAL_META_STATE,
  },
  INITIAL_META_STATE
);

const INITIAL_STATUS_STATE = FlightRequestsFetchStatus.IDLE;
const flightRequestsFetchStatusReducer = createReducer(
  {
    [FETCH_REQUESTED]: () =>
      FlightRequestsFetchStatus.FLIGHT_REQUESTS_FETCH_IN_PROGRESS,
    [FETCH_SUCCEEDED]: () => FlightRequestsFetchStatus.SUCCESS,
    [FETCH_FAILED]: () => FlightRequestsFetchStatus.FAILED,
    [FETCH_STATUS_RESET]: () => INITIAL_STATUS_STATE,
  },
  INITIAL_STATUS_STATE
);

const INITIAL_FOCUSSED_STATE: ExtendedFlightResponseBodyV2 | null = null;
const flightRequestsFocussedReducer = createReducer(
  {
    [FETCH_FOCUSSED_SUCCEEDED]: (
      _: State,
      { payload }: { payload: ExtendedFlightResponseBodyV2 | null }
    ) => payload,
    [FOCUSSED_RESET]: () => INITIAL_FOCUSSED_STATE,
  },
  INITIAL_FOCUSSED_STATE
);

const INITIAL_FOCUSSED_STATUS_STATE = FlightRequestsFetchFocussedStatus.IDLE;
const flightRequestsFetchFocussedStatusReducer = createReducer(
  {
    [FETCH_FOCUSSED_REQUESTED]: () =>
      FlightRequestsFetchFocussedStatus.FLIGHT_REQUESTS_FETCH_FOCUSSED_IN_PROGRESS,
    [FETCH_FOCUSSED_SUCCEEDED]: () => FlightRequestsFetchFocussedStatus.SUCCESS,
    [FETCH_FOCUSSED_FAILED]: () => FlightRequestsFetchFocussedStatus.FAILED,
    [FETCH_FOCUSSED_STATUS_RESET]: () => INITIAL_FOCUSSED_STATUS_STATE,
  },
  INITIAL_FOCUSSED_STATUS_STATE
);

const INITIAL_CANCEL_STATE = FlightRequestsCancelStatus.IDLE;
const flightRequestsCancelStatusReducer = createReducer(
  {
    [CANCEL_REQUESTED]: () =>
      FlightRequestsCancelStatus.FLIGHT_REQUESTS_CANCEL_IN_PROGRESS,
    [CANCEL_SUCCEEDED]: () => FlightRequestsCancelStatus.SUCCESS,
    [CANCEL_FAILED]: () => FlightRequestsCancelStatus.FAILED,
    [CANCEL_STATUS_RESET]: () => INITIAL_CANCEL_STATE,
  },
  INITIAL_CANCEL_STATE
);

const INITIAL_FLIGHT_ACTIVATION_STATE = FlightActivationStatus.IDLE;
const flightRequestsActivationReducer = createReducer(
  {
    [ACTIVATE_FLIGHT_REQUESTED]: () =>
      FlightActivationStatus.FLIGHT_ACTIVATION_IN_PROGRESS,
    [ACTIVATE_FLIGHT_SUCCEEDED]: () => FlightActivationStatus.SUCCESS,
    [ACTIVATE_FLIGHT_FAILED]: (_: any, { payload }: { payload: any }) => {
      return {
        payload,
        status: FlightActivationStatus.FAILED,
      };
    },
    [ACTIVATE_FLIGHT_STATUS_RESET]: () => INITIAL_FLIGHT_ACTIVATION_STATE,
  },
  INITIAL_FLIGHT_ACTIVATION_STATE
);

const INITIAL_END_STATE = FlightRequestsEndStatus.IDLE;
const flightRequestsEndStatusReducer = createReducer(
  {
    [END_REQUESTED]: () =>
      FlightRequestsEndStatus.FLIGHT_REQUESTS_END_IN_PROGRESS,
    [END_SUCCEEDED]: () => FlightRequestsEndStatus.SUCCESS,
    [END_FAILED]: () => FlightRequestsEndStatus.FAILED,
    [END_STATUS_RESET]: () => INITIAL_END_STATE,
  },
  INITIAL_END_STATE
);

const flightRequestsErrorMessageReducer = createReducer(
  {
    [END_REQUESTED]: () => null as any,
    [END_SUCCEEDED]: () => null as any,
    [END_FAILED]: (_: any, { payload }: { payload: any }) => payload,
    [END_STATUS_RESET]: () => null as any,
    [CANCEL_REQUESTED]: () => null as any,
    [CANCEL_SUCCEEDED]: () => null as any,
    [CANCEL_FAILED]: (_: any, { payload }: { payload: any }) => payload,
    [CANCEL_STATUS_RESET]: () => null as any,
  },
  null
);

const flightRequestsIsListeningReducer = createReducer(
  {
    [STARTED_LISTENING]: () => true,
    [STOPPED_LISTENING]: () => false,
  },
  false
);

const flightRequestsIsListeningFocussedReducer = createReducer(
  {
    [STARTED_LISTENING_TO_FOCUSSED]: () => true,
    [STOPPED_LISTENING_TO_FOCUSSED]: () => false,
  },
  false
);

export const flightRequestsReducer = combineReducers({
  pages: flightRequestsPagesReducer,
  meta: flightRequestsMetaReducer,
  fetchPageStatus: flightRequestsFetchStatusReducer,
  isListening: flightRequestsIsListeningReducer,
  isListeningFocussed: flightRequestsIsListeningFocussedReducer,
  focussed: flightRequestsFocussedReducer,
  fetchFocussedStatus: flightRequestsFetchFocussedStatusReducer,
  cancelStatus: flightRequestsCancelStatusReducer,
  flightActivation: flightRequestsActivationReducer,
  endStatus: flightRequestsEndStatusReducer,
  errorMessage: flightRequestsErrorMessageReducer,
});
