import React, { useState } from 'react';

interface Props {
  src: string;
  fallbackSrc: string;
  alt: string;
  className: string;
  id?: string;
}

export function ImageFallback({
  src,
  fallbackSrc,
  alt,
  className,
  id,
}: Readonly<Props>) {
  const [fallback, updateFallback] = useState(false);

  const useFallback = fallback || !src;

  function handleError() {
    if (!fallback) {
      updateFallback(true);
    }
  }

  return (
    <img
      className={className}
      alt={alt}
      src={useFallback ? fallbackSrc : src}
      onError={handleError}
      data-testid={id}
    />
  );
}
