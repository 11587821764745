import React, { useEffect, useCallback, useRef } from 'react';
import { DateTime } from 'luxon';
import { ButtonBase, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  AcknowledgeFlightRequestResponse,
  FRStatusCode,
  PilotFacingStatusCode,
} from '@airshare/external-api-types';

import { RequestToPilotStatus, RequestToPilotType } from 'argus-common/enums';

import { useFetchFlightRequestsPage } from '../../../../state/flight-requests/hooks';

import { acknowledgePilotAlertType } from '../../../../pilot-api-client';

import './pilot-alerts.scss';

interface RequestToPilot {
  type: string | RequestToPilotType;
  status: string | RequestToPilotStatus;
  message: string;
  createdAt: string | Date;
}

interface Props {
  flightId: string;
  title: string;
  alerts: RequestToPilot[];
  overallStatus: PilotFacingStatusCode;
  timezone?: string;
}

const COMPLETED_STATUSES: PilotFacingStatusCode[] = [
  FRStatusCode.Cancelled,
  FRStatusCode.Terminated,
];

export default function PilotAlerts({
  flightId,
  alerts,
  title,
  overallStatus,
  timezone,
}: Readonly<Props>) {
  const [showAcknowledgeButton, setShowAcknowledgeButton] =
    React.useState(false);
  const [alertType, setAlertType] = React.useState('');
  const [ackErrors, setAckErrors] = React.useState<string[]>([]);
  const acknowledgedAlertCountRef = useRef(0);

  const fetchFlightRequests = useFetchFlightRequestsPage();

  useEffect(() => {
    if (alerts?.length > 0) {
      setAlertType(alerts[0]?.type);
    } else {
      acknowledgedAlertCountRef.current = 0;
    }

    if (
      alerts?.length > 0 &&
      alerts?.some((alert) => alert.status !== 'Acknowledged') &&
      acknowledgedAlertCountRef.current !== alerts.length &&
      !COMPLETED_STATUSES.includes(overallStatus)
    ) {
      setShowAcknowledgeButton(true);
    } else {
      setShowAcknowledgeButton(false);
    }
  }, [alerts, overallStatus]);

  const handleAcknowledgeAlerts = useCallback(() => {
    if (alertType) {
      acknowledgedAlertCountRef.current = alerts.length;
      acknowledgePilotAlertType(flightId, alertType).then(
        (resp: AcknowledgeFlightRequestResponse) => {
          if (resp.errors) {
            setAckErrors(resp.errors);
          } else {
            setShowAcknowledgeButton(false);
          }
          fetchFlightRequests();
        }
      );
    }
  }, [alertType, flightId, fetchFlightRequests, alerts]);

  return (
    <>
      {alerts.length > 0 && (
        <div className="pilot-alerts-panel">
          <Alert severity="warning">
            <Typography variant="h6">{title}</Typography>
            {alerts.map((alert, key) => (
              <div key={key} className="pilot-alert-item-wrapper">
                <Typography variant="subtitle2" className="pilot-alert-message">
                  {alert.message}
                </Typography>
                <Typography variant="caption" className="pilot-alert-caption">
                  {DateTime.fromJSDate(new Date(alert.createdAt))
                    .setZone(timezone)
                    .toFormat('HH:mm:ss ZZZZ')}
                </Typography>
              </div>
            ))}
            {showAcknowledgeButton && (
              <ButtonBase
                className="acknowledge-pilot-alerts-button button primary"
                type="button"
                onClick={handleAcknowledgeAlerts}
                data-testid="pilot-web:flight-briefing-panel:acknowledge-pilot-alerts-button"
              >
                Acknowledge Alerts
              </ButtonBase>
            )}
            {ackErrors?.length > 0 &&
              ackErrors.map((error, key) => (
                <div key={key}>
                  <Typography variant="subtitle2">{error}</Typography>
                </div>
              ))}
          </Alert>
        </div>
      )}
    </>
  );
}
