import { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import type { NotamsResponseWithLocation } from '@airshare/pilot-types';

import { NOTAM_DETAIL_PATH, NOTAMS_ROUTE } from '../../../routes';

import bbox from '@turf/bbox';

export function useFocussedNotamMapOrientation(
  google: any,
  mapInstance: google.maps.Map,
  pathname: string,
  setMapCenter: (x: any) => void,
  notams: NotamsResponseWithLocation[],
  setShowNotamLayer: (x: boolean) => void
) {
  const leftAside = document.getElementsByClassName('left-aside')[0];
  const leftAsideWith = leftAside ? getComputedStyle(leftAside).width : '450px';
  const DETAIL_PANEL_WIDTH = Number.parseInt(leftAsideWith);
  const PAN_DISTANCE = DETAIL_PANEL_WIDTH / 2;

  const notamDetailPathMatch = matchPath<{ id?: string }>(pathname, {
    path: NOTAM_DETAIL_PATH,
    exact: true,
    strict: true,
  });

  const isViewingNotamPanel = pathname === NOTAMS_ROUTE;

  const [lastFocussed, updateLastFocussed] = useState(null);

  const id = notamDetailPathMatch ? notamDetailPathMatch.params.id : null;
  const isViewingNotamDetail = notamDetailPathMatch
    ? notamDetailPathMatch.isExact
    : null;

  const focussedNotam = notams?.find((notam) => notam.id === id) || null;

  useEffect(() => {
    if (isViewingNotamPanel) {
      setShowNotamLayer(true);
      return;
    }
    if (!isViewingNotamPanel && !isViewingNotamDetail) {
      setShowNotamLayer(false);
      return;
    }
    if (
      mapInstance &&
      isViewingNotamDetail &&
      focussedNotam &&
      (!lastFocussed || lastFocussed.id !== id)
    ) {
      const [swLng, swLat, neLng, neLat] = bbox({
        type: 'Feature',
        geometry: {
          type: focussedNotam.type,
          coordinates: focussedNotam.coordinates,
        },
      });

      const bounds = new google.maps.LatLngBounds(
        { lat: swLat - 0.01, lng: swLng + 0.01 },
        { lat: neLat + 0.01, lng: neLng - 0.01 }
      );

      mapInstance.fitBounds(bounds);
      mapInstance.panBy(PAN_DISTANCE * -1, 0);

      setMapCenter(focussedNotam.location);
      setShowNotamLayer(true);
    }
    updateLastFocussed(focussedNotam);
  }, [
    mapInstance,
    focussedNotam,
    isViewingNotamDetail,
    id,
    lastFocussed,
    google.maps.LatLngBounds,
    setMapCenter,
    setShowNotamLayer,
    PAN_DISTANCE,
    isViewingNotamPanel,
  ]);
}
