import { useState } from 'react';
import zipObject from 'lodash/zipObject';
import { MapStyleCode } from './actions';
import { MapOptionsState } from './reducers';
import defaultStyles from './defaultStyles';

export interface MapStyle {
  mapId: string;
  mapTypeId: string;
  styleCode: MapStyleCode;
}

const DEFAULT_MAP_OPTIONS_STATE: MapOptionsState = defaultStyles;

export const getDefaultMapStyles = (styleCode: MapStyleCode) => {
  return {
    ...defaultStyles[styleCode],
    styleCode,
  };
};

const getDefaultMapIndex = (styleCode: MapStyleCode) => {
  return [
    MapStyleCode.Standard,
    MapStyleCode.Gray,
    MapStyleCode.Dark,
    MapStyleCode.Terrain,
    MapStyleCode.Satellite,
    MapStyleCode.Hybrid,
  ].findIndex((x) => x === styleCode);
};

export function useMapStyle(
  styleCode: MapStyleCode = MapStyleCode.Standard,
  setMapStyleCode: (code: MapStyleCode) => void
): [MapStyle, (mapStyleCode: MapStyleCode) => MapStyle] {
  const mapOptions: MapOptionsState = DEFAULT_MAP_OPTIONS_STATE;
  const [mapStyle, updateMapStyle] = useState<MapStyle>(
    getDefaultMapStyles(styleCode)
  );

  const setMapStyle = (mapStyleCode: MapStyleCode) => {
    const mapStyleValues = Object.values(mapOptions).map((options) => ({
      ...options,
    }));
    const mapStyleEnum = zipObject(
      mapStyleValues.map((_, index) => index),
      mapStyleValues
    );

    const nextMapStyleIndex = getDefaultMapIndex(mapStyleCode);
    let newMapStyle = {
      ...mapStyleEnum[nextMapStyleIndex],
      styleCode: mapStyleCode,
    };

    updateMapStyle(newMapStyle);
    setMapStyleCode(newMapStyle.styleCode);

    return newMapStyle;
  };

  return [mapStyle, setMapStyle];
}
