import { Duration } from 'luxon';

export const humanizeTime = (durationMinutes: number): string => {
  const duration = Duration.fromObject({
    minutes: durationMinutes,
  });
  if (durationMinutes < 60) {
    return duration.shiftTo('minutes').toHuman({ listStyle: 'narrow' });
  }
  if (durationMinutes % 60 === 0) {
    return duration.shiftTo('hours').toHuman({ listStyle: 'narrow' });
  }
  return duration.shiftTo('hours', 'minutes').toHuman({ listStyle: 'narrow' });
};
