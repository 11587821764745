import { matchPath } from 'react-router-dom';
import {
  DETAIL_PANEL_PATH,
  CLEARANCE_PATH,
  FLIGHT_BRIEFING_PATH,
} from '../../../routes';

export function useIsFocussedOnAFeature(pathname?: string) {
  const focussedFeaturePathMatch = matchPath(pathname, {
    path: [DETAIL_PANEL_PATH, CLEARANCE_PATH, FLIGHT_BRIEFING_PATH],
    exact: true,
    strict: true,
  });
  return !!focussedFeaturePathMatch?.isExact;
}
