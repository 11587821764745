import { useFormState } from '~/state/flight-plan/flight-plan-form/hooks';
import {
  useFlightPlanEditSuccess,
  useSelectedFavourite,
} from '~/state/flight-plan/hooks';

export function useShouldShowShieldedModal() {
  const form: { isShielded?: boolean } = useFormState();
  const selectedFavourite = useSelectedFavourite();

  const [editFlightPlan] = useFlightPlanEditSuccess();

  return form?.isShielded && !selectedFavourite && !editFlightPlan?.isShielded;
}
