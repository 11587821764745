import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableRow,
  Paper,
  Typography,
  ButtonBase,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import { AirshareUserRole } from 'argus-common/enums';

import { AppDefault } from '../../../state/app-config';
import { useProfile } from '../../../state/profile/hooks';
import * as pilotApiClient from '../../../pilot-api-client';

import './edit-app-defaults.scss';

const useAppDefaultStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '97%',
      marginLeft: '3%',
      paddingTop: '2rem',
    },
    title: {
      width: '100%',
      paddingLeft: '2rem',
    },
    table: {
      tableLayout: 'fixed',
      width: '100%',
    },
    tableColName: {
      width: '25%',
    },
    tableColValue: {
      width: '25%',
    },
    tableColNewValue: {
      width: '50%',
    },
    editInput: {
      width: '100%',
    },
    saveButtonDiv: {
      width: '35%',
      marginTop: '1rem',
      marginRight: '1rem',
      marginLeft: 'auto',
    },
  })
);

export function EditAppDefaults() {
  const classes = useAppDefaultStyles({});
  const [appDefaults, setAppDefaults] = useState<AppDefault[]>([]);
  const profile = useProfile();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAppDefaults = useCallback(async () => {
    const defaults = await pilotApiClient.getDefaultConfigs();

    defaults.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setAppDefaults(defaults);
  }, [setAppDefaults]);

  useMemo(() => {
    if (profile?.roles.includes(AirshareUserRole.SUPER_ADMIN)) {
      setIsAdmin(true);
    }
  }, [profile]);

  useEffect(() => {
    fetchAppDefaults();
  }, [fetchAppDefaults]);

  const getDisplayName = (name: string) => {
    const words = name.split(/(?=[A-Z])/);
    words[0] = words[0].charAt(0).toUpperCase() + words[0].substring(1);
    return words.join(' ');
  };

  const parseNewValue = (type: AppDefault['type'], newVal: string) => {
    if (!newVal) {
      return undefined;
    }

    switch (type) {
      case 'string':
        return newVal;
      case 'number': {
        const parsed = Number.parseFloat(newVal);
        return Number.isNaN(parsed) ? undefined : parsed;
      }
      case 'bool':
        return newVal === 'true';
      default:
        return undefined;
    }
  };

  const editDefaultValue = (name: string, newVal: string) => {
    const defaults = appDefaults.map((def) => {
      return def.name === name
        ? {
            name,
            type: def.type,
            value: def.value,
            editedValue: newVal === '' ? undefined : newVal,
          }
        : def;
    });
    setAppDefaults(defaults);
  };

  const uploadEditedValues = async () => {
    setIsLoading(true);
    const updated = appDefaults
      .map((def) => {
        return def.editedValue !== undefined
          ? {
              name: def.name,
              type: def.type,
              value: parseNewValue(def.type, def.editedValue),
            }
          : undefined;
      })
      .filter((exists) => exists);

    if (updated?.length) {
      const defaults = await pilotApiClient.editDefaultConfigs(updated);
      if (defaults.length) {
        defaults.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        setAppDefaults(defaults);
      }
    }
    setIsLoading(false);
  };

  const haveChanges = !!appDefaults.find((def) => {
    if (def.editedValue?.length) {
      const parsed = parseNewValue(def.type, def.editedValue);
      return parsed !== undefined && parsed !== def.value;
    }
    return false;
  });

  return (
    <>
      {!isAdmin ? null : (
        <div className={classes.wrapper}>
          <div>
            <Typography className={classes.title} variant="h3">
              Edit App Defaults
            </Typography>
            <Paper>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColName}>Name</TableCell>
                    <TableCell className={classes.tableColValue}>
                      Current Value
                    </TableCell>
                    <TableCell className={classes.tableColNewValue}>
                      New Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appDefaults?.map((def) => (
                    <TableRow key={def.name}>
                      <TableCell>{getDisplayName(def.name)}</TableCell>
                      <TableCell>{def.value.toString()}</TableCell>
                      <TableCell>
                        <TextField
                          className={classes.editInput}
                          name="edit-default"
                          value={def.editedValue ?? ''}
                          variant="outlined"
                          size="small"
                          onChange={(event) =>
                            editDefaultValue(def.name, event.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>
          <div className={classes.saveButtonDiv}>
            <ButtonBase
              className="save-new-defaults-button"
              type="button"
              onClick={() => uploadEditedValues()}
              disabled={!haveChanges || isLoading}
            >
              Save Changes
            </ButtonBase>
          </div>
        </div>
      )}
    </>
  );
}
