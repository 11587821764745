import {
  ManagedAreaCode,
  MapAreaType,
  UnfinishedManagedAreaCode,
} from '@airshare/external-api-types';

export { ManagedAreaCode, MapAreaType, UnfinishedManagedAreaCode };

export interface LogoData {
  category: string;
  code: string;
}

// Ext api /map-areas uses type and code
// Adv db calls use code and mapCode
// Beware the code is not the same in both cases - if type is present use this preferentially

export interface AdvisoryTowerScheduleDay {
  start: string;
  end: string;
}

export interface AdvisoryTowerSchedule {
  allDay: boolean;
  monday?: AdvisoryTowerScheduleDay[];
  tuesday?: AdvisoryTowerScheduleDay[];
  wednesday?: AdvisoryTowerScheduleDay[];
  thursday?: AdvisoryTowerScheduleDay[];
  friday?: AdvisoryTowerScheduleDay[];
  saturday?: AdvisoryTowerScheduleDay[];
  sunday?: AdvisoryTowerScheduleDay[];
  additionalInfo?: string;
  status?: TowerStatus;
}

export interface TowerStatus {
  nextOnWatch: string;
  nextOffWatch: string;
  onWatch: boolean;
}

export interface CommonAdvisory {
  id: string;
  type?: MapAreaType;
  code: string;
  icaoCode?: string;
  name: string;
  siteName?: string;
  description?: string;
  address?: string | null;
  email?: string;
  phone?: string | null;
  website?: string;
  activity?: string;
  lowerlimit?: string;
  upperlimit?: string;
  startDateTime?: string;
  endDateTime?: string;
  workinghours?: string;
  policies?: string[];
  mapCode?: string;
  isMapLayer: boolean;
  logoUrl: string;
  groupId?: string;
  logo?: LogoData;
  deviationThresholdHorizontalMetres?: number;
  deviationThresholdVerticalFeet?: number;
  schedule?: AdvisoryTowerSchedule;
}

export enum AdvisorySource {
  DraftFlightArea = 'DraftFlightArea',
  FlightBriefing = 'FlightBriefing',
  Pin = 'Pin',
  None = 'None',
}
