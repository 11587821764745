import { createContext } from 'react';
import type { NearbyActivatedFlightData } from 'argus-events/interfaces';
import type { PilotAlert } from '@airshare/pilot-types';

export interface NotificationStateType {
  alerts: Record<string, PilotAlert[]>;
  setAlert: ({ messageId, payload }: NearbyActivatedFlightData) => void;
  clearAlerts: () => void;
}

export const defaultNotificationState: NotificationStateType = {
  alerts: {},
  setAlert: () => {},
  clearAlerts: () => {},
};

export const NotificationState = createContext<NotificationStateType>(
  defaultNotificationState
);
