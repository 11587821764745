import { createAction } from 'airshare-web-utils/redux-helpers';

export const FETCH_REQUESTED = 'org-pilots/FETCH_REQUESTED';
export const FETCH_SUCCEEDED = 'org-pilots/FETCH_SUCCEEDED';
export const FETCH_FAILED = 'org-pilots/FETCH_FAILED';
export const FETCH_STATUS_RESET = 'org-pilots/FETCH_STATUS_RESET';

export const orgPilotsFetchRequested = createAction(FETCH_REQUESTED);
export const orgPilotsFetchSucceeded = createAction(FETCH_SUCCEEDED);
export const orgPilotsFetchFailed = createAction(FETCH_FAILED);
export const orgPilotsFetchStatusReset = createAction(FETCH_STATUS_RESET);

export const CREATION_REQUESTED = 'org-pilots/CREATION_REQUESTED';
export const CREATION_SUCCEEDED = 'org-pilots/CREATION_SUCCEEDED';
export const CREATION_FAILED = 'org-pilots/CREATION_FAILED';
export const CREATION_STATUS_RESET = 'org-pilots/CREATION_STATUS_RESET';

export const orgPilotsCreationRequested = createAction(CREATION_REQUESTED);
export const orgPilotsCreationSucceeded = createAction(CREATION_SUCCEEDED);
export const orgPilotsCreationFailed = createAction(CREATION_FAILED);
export const orgPilotsCreationStatusReset = createAction(CREATION_STATUS_RESET);

export const UPDATE_REQUESTED = 'org-pilots/UPDATE_REQUESTED';
export const UPDATE_SUCCEEDED = 'org-pilots/UPDATE_SUCCEEDED';
export const UPDATE_FAILED = 'org-pilots/UPDATE_FAILED';
export const UPDATE_STATUS_RESET = 'org-pilots/UPDATE_STATUS_RESET';

export const orgPilotsUpdateRequested = createAction(UPDATE_REQUESTED);
export const orgPilotsUpdateSucceeded = createAction(UPDATE_SUCCEEDED);
export const orgPilotsUpdateFailed = createAction(UPDATE_FAILED);
export const orgPilotsUpdateStatusReset = createAction(UPDATE_STATUS_RESET);

export const DELETION_REQUESTED = 'org-pilots/DELETION_REQUESTED';
export const DELETION_SUCCEEDED = 'org-pilots/DELETION_SUCCEEDED';
export const DELETION_FAILED = 'org-pilots/DELETION_FAILED';
export const DELETION_STATUS_RESET = 'org-pilots/DELETION_STATUS_RESET';

export const orgPilotsDeletionRequested = createAction(DELETION_REQUESTED);
export const orgPilotsDeletionSucceeded = createAction(DELETION_SUCCEEDED);
export const orgPilotsDeletionFailed = createAction(DELETION_FAILED);
export const orgPilotsDeletionStatusReset = createAction(DELETION_STATUS_RESET);
