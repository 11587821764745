import { createAction } from 'airshare-web-utils/redux-helpers';

export const CREATION_REQUESTED = 'flight-plan/CREATION_REQUESTED';
export const CREATION_SUCCEEDED = 'flight-plan/CREATION_SUCCEEDED';
export const CREATION_FAILED = 'flight-plan/CREATION_FAILED';
export const CREATION_STATUS_RESET = 'flight-plan/CREATION_STATUS_RESET';

export const VALIDATION_REQUESTED = 'flight-plan/VALIDATION_REQUESTED';
export const VALIDATION_SUCCEEDED = 'flight-plan/VALIDATION_SUCCEEDED';
export const VALIDATION_FAILED = 'flight-plan/VALIDATION_FAILED';
export const VALIDATION_STATUS_RESET = 'flight-plan/VALIDATION_STATUS_RESET';

export const FAVOURITE_SELECTED = 'flight-plan/FAVOURITE_SELECTED';
export const FAVOURITE_RESET = 'flight-plan/FAVOURITE_RESET';
export const FAVOURITE_FLIGHT_AREA_RENDERED =
  'flight-plan/FAVOURITE_FLIGHTAREA_RENDERED';
export const NEW_FLIGHTAREA_DRAWN = 'flight-plan/NEW_FLIGHTAREA_DRAWN';

export const FLIGHT_PATH_AREA_SELECTED =
  'flight-plan/FLIGHT_PATH_AREA_SELECTED';
export const FLIGHT_PATH_AREA_RESET = 'flight-plan/FLIGHT_PATH_AREA_RESET';

export const FLIGHT_AREA_FILE_UPLOADED =
  'flight-plan/FLIGHT_AREA_FILE_UPLOADED';
export const FLIGHT_AREA_FILE_RESET = 'flight-plan/FLIGHT_AREA_FILE_RESET';

export const FLIGHT_PLAN_EDIT_REQUESTED = 'flight-plan/EDIT_SELECTED';

export const FLIGHT_PLAN_EDIT_RETRIEVED_SUCCESS =
  'flight-plan/EDIT_RETRIEVED_SUCCESS';

export const FLIGHT_PLAN_EDIT_RESET = 'flight-plan/EDIT_RESET';
export const UPDATE_REQUESTED = 'flight-plan/UPDATE_REQUESTED';
export const UPDATE_SUCCEEDED = 'flight-plan/UPDATE_SUCCEEDED';
export const UPDATE_STATUS_RESET = 'flight-plan/UPDATE_STATUS_RESET';

export const flightPlanCreationRequested = createAction(CREATION_REQUESTED);
export const flightPlanCreationSucceeded = createAction(CREATION_SUCCEEDED);
export const flightPlanCreationFailed = createAction(CREATION_FAILED);
export const flightPlanCreationStatusReset = createAction<void, string>(
  CREATION_STATUS_RESET
);

export const flightPlanValidationRequested = createAction(VALIDATION_REQUESTED);
export const flightPlanValidationSucceeded = createAction(VALIDATION_SUCCEEDED);
export const flightPlanValidationFailed = createAction(VALIDATION_FAILED);
export const flightPlanValidationStatusReset = createAction(
  VALIDATION_STATUS_RESET
);

export const flightPlanFavouriteSelected = createAction(FAVOURITE_SELECTED);
export const flightPlanFavouriteReset = createAction(FAVOURITE_RESET);
export const flightPlanFavouriteFlightAreaRendered = createAction(
  FAVOURITE_FLIGHT_AREA_RENDERED
);
export const flightPlanNewFlightAreaDrawn = createAction(NEW_FLIGHTAREA_DRAWN);

export const flightPlanFlightPathAreaSelected = createAction(
  FLIGHT_PATH_AREA_SELECTED
);
export const flightPlanFlightPathAreaReset = createAction(
  FLIGHT_PATH_AREA_RESET
);

export const flightPlanUploadedFlightAreaSelected = createAction(
  FLIGHT_AREA_FILE_UPLOADED
);
export const flightPlanUploadedFlightAreaReset = createAction(
  FLIGHT_AREA_FILE_RESET
);

//Edit flight plan/request action(s)
export const flightPlanEditRequested = createAction(FLIGHT_PLAN_EDIT_REQUESTED);
export const flightPlanEditSucceeded = createAction(
  FLIGHT_PLAN_EDIT_RETRIEVED_SUCCESS
);

export const flightPlanEditReset = createAction(FLIGHT_PLAN_EDIT_RESET);

export const flightPlanUpdateRequested = createAction(UPDATE_REQUESTED);
export const flightPlanUpdateSucceeded = createAction(UPDATE_SUCCEEDED);

export const flightPlanUpdateReset = createAction(UPDATE_STATUS_RESET);
