import { createSelector } from 'reselect';
import { UavDefaults } from './saga';

const getLocalState = (state: any) => state.uavDefaults;

export const getUavDefaults = createSelector([getLocalState], (state) => state);

export const getUavDefaultWeights = createSelector(
  [getUavDefaults],
  (uavDefault: UavDefaults) => uavDefault.weights
);

export const getUavDefaultCClasses = createSelector(
  [getUavDefaults],
  (uavDefault: UavDefaults) => uavDefault.uavCClasses
);
