import { initSentry } from 'airshare-web-utils/sentry';

initSentry({ app: 'argus-pilot-web' });

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { Settings } from 'luxon';
import ReactGA from 'react-ga';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import detectTouchDevice from 'airshare-web-utils/detect-touch-device';

import App from './components/app/app.container';
import store, { startSagas } from './state/store';
import { logOut } from './state/session/action-creators';

import { pilotAPI } from './pilot-api-client/api';

import 'airshare-pilot-web-shared/src/styles/index.scss';

if (window.env.GA_APP_ID) {
  ReactGA.initialize(window.env.GA_APP_ID);
}

if (window.env.LOGROCKET_APP_ID) {
  LogRocket.init(window.env.LOGROCKET_APP_ID);
  LogRocket.identify('pilot-web');
  setupLogRocketReact(LogRocket);
}

document.body.className += detectTouchDevice() ? ' touch-only' : '';

pilotAPI.handleErrorStatus(
  401,
  () => {
    store.dispatch(logOut());
  },
  'pilot-web',
  `${window.env.ARGUS_PILOT_APP_API_URL}/v2/token-refresh`,
  '/pre-validate-flight-area'
);

startSagas();

Settings.defaultLocale = `${window.env.DEFAULT_LOCALE}`;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
);
