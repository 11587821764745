import React, { useEffect } from 'react';
import { Route, RouteProps, matchPath } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useLocation } from '../hooks/router.hook';

export const TrackedRoute = (props: RouteProps) => {
  const location: any = useLocation();
  const { path } = props;

  useEffect(() => {
    if (window.env.GA_APP_ID) {
      const pathMatch = matchPath(location.pathname, {
        path,
        exact: true,
        strict: true,
      });

      if (pathMatch?.isExact) {
        const page = location.pathname;
        ReactGA.set({ page });
        ReactGA.pageview(page);
      }
    }
  }, [location.pathname, path]);

  return <Route {...props} />;
};
