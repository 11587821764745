import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MapStyleCode } from 'airshare-pilot-web-shared';

import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';
import { getAirspaceActivity } from '../../../../pilot-api-client';
import { GlobalState } from '../../../../state/GlobalState';

import './airspace-activity.scss';
import { DateTime } from 'luxon';

import droneIconSvg from './images/droneIconSvg';
import droneIconSvgAnimated from './images/droneIconSvgAnimated';
import helicopterSgv from './images/helicopterSgv';
import planeSvg from './images/planeSvg';
import questionMarkSvg from './images/questionMarkSvg';

import {
  useIsPrivilegedViewer,
  useIsSuperAdmin,
} from '../../../../state/session/hooks';

const REFRESH_AIRSPACE_ACTIVITY_INTERVAL = 60000;

type AirspaceActivitySuccessResponse = { properties?: any; geometry?: any }[];
type AirspaceActivityResponse = {
  data?: AirspaceActivitySuccessResponse;
  error?: string;
};

function AirspaceActivityComponent({
  history,
}: RouteComponentProps<{ flightId: string }>) {
  const [airspaceActivity, setAirspaceActivity] =
    useState<AirspaceActivityResponse>({ data: null });

  const { addMapLayer, mapStyleCode } = useContext(GlobalState);

  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setloaded] = useState(false);
  const [selectedFlightId] = useState(null);
  const [airspaceActivityGeometries, setAirspaceActivityGeometries] = useState(
    []
  );
  const isPrivilegedViewer = useIsPrivilegedViewer();
  const isSuperAdmin = useIsSuperAdmin();

  useEffect(() => {
    if (!isSuperAdmin && !isPrivilegedViewer) {
      history.push('/');
    }
  }, [isSuperAdmin, isPrivilegedViewer, history]);

  const addMapLayerCallback = useCallback(
    (airspaceActivityGeometriesResponse, selectedId = '') => {
      let scaledSize24x24: any = null;
      let scaledSize32x32: any = null;
      let anchorSize8: any = null;
      let anchorSize12: any = null;

      const geometries = airspaceActivityGeometriesResponse.map(
        (geometry: any) => ({
          ...geometry,
          selected: geometry.id === selectedId,
        })
      );
      setAirspaceActivityGeometries(geometries);
      addMapLayer('airspace-activity', {
        visible: true,
        isVisibleOnUrl: (url: string) => url.indexOf('/airspace-activity') > -1,
        geometries: geometries,
        layerForegroundColor: '#ff0000',
        getLayer: (
          id: any,
          selectedLayerId: any,
          geometry: any,
          mapStyle: MapStyleCode
        ) => {
          return {
            ...(selectedLayerId && selectedId === id
              ? {
                  strokeColor: '#000000',
                  strokeOpacity: 0.0,
                  strokeWeight: 1,
                  fillColor: '#EFEEBB',
                  fillOpacity: 0.4,
                  zIndex: 10,
                  mapCode: 'geometry-show',
                }
              : {
                  ...geometry.layerStyle,
                  ...getFillStyle(mapStyle),
                }),

            clickable: false,
            editable: false,
            draggable: false,
          };
        },
        getLayerForegroundColor: (_feature: any) => {
          return '#ff0000';
        },
        layerBorderColor: '#ff0000',
        getLayerBorderColor: (_feature: any) => {
          return '#ff0000';
        },
        useMarkerCluster: true,
        useMarkerInfoWindow: true,
        getMarkerInfoWindowOptions: (geometry: any) => {
          return {
            content: `<div class=airspace-activity-flight-info style='font-weight:bold;font-size:1.1em'>
              <ul>${Object.keys(geometry.layerInfo)
                .map(
                  (key: string) =>
                    `<li style='display:flex;margin:7px 0'><span class="label" style='color:#FFF;min-width:80px;display:inline-block'>${key}</span> <span class="value" style='color:#FFF;font-weight:bolder;width:200px;display:inline-block;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;font-weight:normal'>: ${geometry.layerInfo[key]}</span></li>`
                )
                .join('\n')}  </ul>
          </div>`,
            shouldFocus: false,
          };
        },
        marker: 'airplane',
        getMarker: (geometry: any, mapInstance: any, google: any) => {
          const currentZoom = mapInstance.getZoom();

          if (!scaledSize24x24) {
            scaledSize24x24 = new google.maps.Size(24, 24);
          }
          if (!scaledSize32x32) {
            scaledSize32x32 = new google.maps.Size(32, 32);
          }
          if (!anchorSize8) {
            anchorSize8 = new google.maps.Point(8, 8);
          }
          if (!anchorSize12) {
            anchorSize12 = new google.maps.Point(12, 12);
          }

          const scaledSize =
            currentZoom < 7 ? scaledSize24x24 : scaledSize32x32;
          const anchor = currentZoom < 7 ? anchorSize8 : anchorSize12;
          const icon = getIcon(geometry.acType, geometry.layerInfo.Status);
          return {
            url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              icon.replaceAll(
                'fill="#f57c00"',
                `fill="${geometry.layerStyle.iconColor}"`
              )
            )}`,
            anchor: anchor,
            scaledSize: scaledSize,
          };
        },
      });
    },
    [addMapLayer]
  );

  function getIcon(acType: string, status: string) {
    switch (acType.toLowerCase()) {
      case 'twin':
      case 'plane':
      case 'airplane':
        return planeSvg;
      case 'helicopter':
        return helicopterSgv;
      case 'ua':
      case 'drone':
        return status === 'Activated' ? droneIconSvgAnimated : droneIconSvg;
    }
    return questionMarkSvg;
  }

  const refreshAirspaceActivity = useCallback(() => {
    async function refresh() {
      try {
        const airspaceActivityResponse: any = await getAirspaceActivity();
        const airspaceActivityGeometriesResponse = airspaceActivityResponse.map(
          (item: any) => mapToGeometry(item, selectedFlightId, mapStyleCode)
        );
        setIsLoading(false);
        setAirspaceActivityGeometries(airspaceActivityGeometriesResponse);

        addMapLayerCallback(airspaceActivityGeometriesResponse);
      } catch (err) {
        setAirspaceActivity({ error: err });
      }
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addMapLayerCallback,
    airspaceActivityGeometries,
    selectedFlightId,
    mapStyleCode,
  ]);

  useEffect(() => {
    if (!loaded) {
      refreshAirspaceActivity();
      setloaded(true);
    }
    const refreshInterval = setInterval(() => {
      refreshAirspaceActivity();
    }, REFRESH_AIRSPACE_ACTIVITY_INTERVAL);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [loaded, refreshAirspaceActivity]);

  return (
    <LoadingPanel isLoading={isLoading}>
      {airspaceActivity?.error && (
        <>
          <span>We failed to retrieve airspace activity information</span>
        </>
      )}
    </LoadingPanel>
  );
}

export const AirspaceActivity = withRouter(AirspaceActivityComponent);

function mapToGeometry(
  item: any,
  selectedFlightId: any,
  mapStyleCode: MapStyleCode
) {
  return {
    id: item.properties.flightId,
    center: item.flightLocation.deadCenter,
    geometry: item.geometry,
    selected: item.properties.flightId === selectedFlightId,
    layerStyle: getLayerStyle(item, mapStyleCode),
    layerInfo: getLayerInfo(item),
    startDateTime: item.properties.startDateTime,
    endDateTime: item.properties.endDateTime,
    acType: item.properties.acType || 'UA',
  };
}

const weightValues: { [key: string]: string } = {
  '15 to 25': 'Between 15kg to 25kg',
  '>25': 'Above 25kg',
  '-': undefined,
};
const droneAcTypes = ['ua', 'uav', 'drone'];

function getLayerInfo(item: any) {
  const DEFAULT_LOCALE = window.env.DEFAULT_LOCALE ?? 'en-nz';
  let weight = Object.keys(weightValues).includes(item.properties.uav.weight)
    ? weightValues[item.properties.uav.weight]
    : null;
  let aircraft = droneAcTypes.includes(item.properties.acType.toLowerCase())
    ? 'Drone'
    : item.properties.acType;
  let rule = item.properties.rule || null;
  rule = aircraft !== 'Drone' ? 'General Aviation' : rule;

  return {
    'Flight ID': item.properties.flightId,
    Altitude: `${item.properties.maxAltitudeFeet}ft`,
    Aircraft: aircraft,
    ...(rule ? { Rule: rule } : {}),
    Start: DateTime.fromISO(item.properties.startDateTime)
      .setLocale(DEFAULT_LOCALE)
      .toLocaleString(DateTime.DATETIME_SHORT),
    End: DateTime.fromISO(item.properties.endDateTime)
      .setLocale(DEFAULT_LOCALE)
      .toLocaleString(DateTime.DATETIME_SHORT),
    Status:
      item.properties.dataSource === 'FlightAdvisor'
        ? 'Planned'
        : item.properties.status,
    ...(weight ? { 'UAV Weight': weight } : {}),
  };
}

function getFillStyle(mapStyleCode: MapStyleCode) {
  const fill = {
    [MapStyleCode.Standard]: { fillColor: '#000000', fillOpacity: '0.3' },
    [MapStyleCode.Gray]: { fillColor: '#FFFFFF', fillOpacity: '0.5' },
    [MapStyleCode.Dark]: { fillColor: '#FFFFFF', fillOpacity: '0.5' },
    [MapStyleCode.Terrain]: { fillColor: '#FFFFFF', fillOpacity: '0.5' },
    [MapStyleCode.Satellite]: { fillColor: '#FFFFFF', fillOpacity: '0.5' },
    [MapStyleCode.Hybrid]: { fillColor: '#FFFFFF', fillOpacity: '0.3' },
  };

  return fill[mapStyleCode];
}

function getLayerStyle(item: any, mapStyleCode: MapStyleCode) {
  return {
    iconColor: item.properties.strokeColor,
    strokeColor: '#000000',
    strokeOpacity: '0.8',
    strokeWeight: '0.5',
    ...getFillStyle(mapStyleCode),
    zIndex: 100000,
  };
}
