export enum RoomKey {
  Surveillance = 'surveillance',
  FlightpathAggregate = 'flightpath-aggregate',
  Flightpath = 'flightpath',
  FlightAlerts = 'flight-alerts',
  FlightReport = 'flight-report',
}

export const pilotRoomName = (pilotId: string) => `pilotId:${pilotId}`;

export const getPilotIdFromRoom = (roomName: string) => roomName.split(':')[1];
