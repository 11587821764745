import * as React from 'react';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { useInterval } from 'airshare-pilot-web-shared';
import { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { useProfile } from '../../state/profile/hooks';
import { useActivatedFlightRequests } from '../../state/flight-requests/hooks';

const MINIMUM_UPDATE_INTERVAL = 1000;

export default function SurveillanceLatencyDisplay({
  avgLatencyMs,
  messages,
  liveFlightsAreUnavailable,
}: {
  avgLatencyMs: number;
  messages: string[];
  liveFlightsAreUnavailable: boolean;
}) {
  const profile = useProfile();
  const activatedFlights = useActivatedFlightRequests();
  const [_, setForceRerenderLatency] = React.useState(0);

  useInterval(() => {
    setForceRerenderLatency((t) => t + 1);
  }, MINIMUM_UPDATE_INTERVAL);

  const inSecs = avgLatencyMs / 1000;
  const {
    PILOT_WEBSOCKET_MAXIMUM_FLIGHT_ALTITUDE_FEET,
    PILOT_WEBSOCKET_MAXIMUM_FLIGHT_DISTANCE_METERS,
  } = window.env;

  const { displayText, classes, fontColor } = getDisplayText(
    liveFlightsAreUnavailable,
    inSecs,
    avgLatencyMs,
    activatedFlights,
    messages
  );

  const altitudeFeet = PILOT_WEBSOCKET_MAXIMUM_FLIGHT_ALTITUDE_FEET ?? '1500';
  const configDistanceValueMeters = Number.parseInt(
    PILOT_WEBSOCKET_MAXIMUM_FLIGHT_DISTANCE_METERS
  );
  const distanceKiloMeters =
    (isNaN(configDistanceValueMeters) ? 50000 : configDistanceValueMeters) /
    1000;
  const tooltiptext = `Nearby flights (relative altitude difference less than ${altitudeFeet}ft and within ${distanceKiloMeters}km) are visible on the map when available. You must activate your flight to see the live data.`;

  if ((profile?.roles?.length || 0) === 0) return null;

  return (
    <Typography
      data-testid="surveillance-latency-display"
      variant="caption"
      className={classes}
      style={{
        color: fontColor,
      }}
    >
      {displayText}
      <Typography variant="caption" className="tooltiptext">
        {tooltiptext}
      </Typography>
    </Typography>
  );
}

const getDisplayText = (
  liveFlightsAreUnavailable: boolean,
  inSecs: number,
  avgLatencyMs: number,
  actFlights: ExtendedFlightResponseBodyV2[],
  messages: string[] = []
): {
  displayText: string;
  classes: string;
  fontColor: string;
} => {
  const { SURVEILLANCE_LATENCY_WARNING_TIME_IN_SECS } = window.env;

  const configValue = Number.parseInt(
    SURVEILLANCE_LATENCY_WARNING_TIME_IN_SECS
  );
  const latencyThreshold = isNaN(configValue) ? 3 : configValue;
  const isWarningState = inSecs > latencyThreshold;

  const fontColor = liveFlightsAreUnavailable
    ? 'white'
    : isWarningState
      ? 'red'
      : 'black';

  if (actFlights?.length === 0) {
    return {
      displayText: 'Activate a flight to see live flight data',
      classes: classNames('surveillance-latency-display', 'warning'),
      fontColor: 'black',
    };
  }

  if (avgLatencyMs === 0) {
    if (messages?.length > 0) {
      return {
        displayText: messages.join(' '),
        classes: classNames('surveillance-latency-display', 'warning'),
        fontColor: 'black',
      };
    } else {
      return {
        displayText: 'Live data feed is currently unavailable',
        classes: classNames(
          'surveillance-latency-display',
          liveFlightsAreUnavailable ? 'unavailable' : '',
          isWarningState ? 'warning' : ''
        ),
        fontColor,
      };
    }
  }

  return {
    displayText: `Live Data Latency: ${inSecs.toFixed(1)}s`,
    classes: classNames(
      'surveillance-latency-display',
      liveFlightsAreUnavailable ? 'unavailable' : '',
      isWarningState ? 'warning' : ''
    ),
    fontColor,
  };
};
