import { createSelector } from 'reselect';
import type { ComparativeFlightAdvisoryResponse } from '@airshare/external-api-types';
import type { StyledFlightAdvisory } from '@airshare/pilot-types';
import type { RootState } from '../store';

export const getFlightAdvisories = createSelector(
  [(state: RootState) => state.flightAdvisories],
  (state: ComparativeFlightAdvisoryResponse[]) => state
);

export const getHighlightedFlightAdvisories = createSelector(
  [(state: RootState) => state.highlightedFlightAdvisories],
  (state: StyledFlightAdvisory[]) => state
);
