import React, { useContext } from 'react';
import type { Feature, Polygon } from 'geojson';

import { closeActiveInfoWindows } from 'airshare-pilot-web-shared';
import type { StyledFlightSegmentPropertiesJSONView } from '@airshare/external-api-types';

import { GlobalState } from '../../../state/GlobalState';
import {
  useOverlapSummary,
  type LocalOverlapDetail,
} from '../../map-screen/hooks/use-overlap-summary';
import { SegmentDetailAccordion } from './segment-detail-accordion';

interface SegmentsProps {
  segmentFeatures:
    | Feature<Polygon, StyledFlightSegmentPropertiesJSONView>[]
    | undefined;
  timezone: string;
}

export const SegmentList = (props: SegmentsProps): JSX.Element | null => {
  const { highlightedSegments, setHighlightedSegments } =
    useContext(GlobalState);
  const { overlapSummary } = useOverlapSummary();

  const toggleHighlightSegment = (segmentId: string) => {
    closeActiveInfoWindows();
    setHighlightedSegments((prev: string[] | null) => {
      if (prev?.includes(segmentId)) {
        closeActiveInfoWindows();
        return prev.filter((id) => id !== segmentId);
      }
      return [...(prev ?? []), segmentId];
    });
  };

  return props?.segmentFeatures?.length > 0 ? (
    <div className="segment-list">
      {props.segmentFeatures.map((s) => {
        let segmentOverlapDetail: LocalOverlapDetail[] | undefined = undefined;
        if (overlapSummary) {
          segmentOverlapDetail = overlapSummary?.segments[s.properties.id];
        }

        return (
          <SegmentDetailAccordion
            key={s.properties.id}
            segment={s}
            segmentOverlapDetail={segmentOverlapDetail}
            timezone={props.timezone}
            toggleHighlightSegment={toggleHighlightSegment}
            isExpanded={highlightedSegments?.includes(s.properties.id) ?? false}
          />
        );
      })}
    </div>
  ) : null;
};
