import { useSelector, useDispatch } from 'react-redux';
import {
  getFlightPurposes,
  getPilot,
  getPilots,
  getUavs,
  getPathModes,
} from './selectors';
import { flightRequestDefaultsFetchRequested } from './actions';

export function useFlightPurposes() {
  const flightPurposes = useSelector(getFlightPurposes);

  return flightPurposes;
}

export function usePilot() {
  const pilot = useSelector(getPilot);

  return pilot;
}

export function usePilots() {
  const pilots = useSelector(getPilots);

  return pilots;
}

export function useUavs() {
  const uavs = useSelector(getUavs);

  return uavs;
}

export function usePathModes() {
  const pathModes = useSelector(getPathModes);

  return pathModes;
}

export function useFetchFlightRequestDefaults() {
  const dispatch = useDispatch();

  return () => dispatch(flightRequestDefaultsFetchRequested());
}
