import buffer from '@turf/buffer';
import truncate from '@turf/truncate';
import createTurfCircle from '@turf/circle';
import {
  type Polygon,
  polygon as createTurfPolygon,
  type Position,
  lineString as createTurfLineString,
  type LineString,
} from '@turf/helpers';
import { FlightPathModeEnum } from '@airshare/external-api-types';

export const payloadToPolygon = (
  overlayCompleteEvent: google.maps.drawing.OverlayCompleteEvent,
  bufferMetres: number = defaultBufferMetres()
): Polygon | null => {
  switch (overlayCompleteEvent?.type) {
    case google.maps.drawing.OverlayType.CIRCLE:
      return getGeojsonPolygonFromGoogleMapsCircle(
        overlayCompleteEvent.overlay as google.maps.Circle
      );
    case google.maps.drawing.OverlayType.POLYGON:
      return getGeojsonPolygonFromGoogleMapsPolygon(
        overlayCompleteEvent.overlay as google.maps.Polygon
      );
    case google.maps.drawing.OverlayType.POLYLINE:
      return getGeojsonPolygonFromGoogleMapsPolyline(
        overlayCompleteEvent.overlay as google.maps.Polyline,
        bufferMetres
      );
    default:
      return null;
  }
};

export const payloadToWaypoints = (
  overlayCompleteEvent: google.maps.drawing.OverlayCompleteEvent
): LineString | null => {
  if (overlayCompleteEvent?.type === google.maps.drawing.OverlayType.POLYLINE) {
    return getGeojsonLineStringFromGoogleMapsPolyline(
      overlayCompleteEvent.overlay as google.maps.Polyline
    );
  }
  return null;
};

export const getGeojsonPolygonFromGoogleMapsCircle = (
  circle: google.maps.Circle | null
): Polygon | null => {
  try {
    if (circle) {
      const centre = circle.getCenter();
      if (centre) {
        const center: [number, number] = [centre.lng(), centre.lat()];
        const radius = circle.getRadius();

        const { geometry } = createTurfCircle(center, radius / 1000);

        return geometry;
      }
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getGeojsonPolygonFromGoogleMapsPolygon = (
  polygon: google.maps.Polygon | null
): Polygon | null => {
  try {
    if (polygon) {
      const path: google.maps.MVCArray<google.maps.LatLng> = polygon.getPath();
      const coordinates: Position[][] = [[]];

      const firstTuple: Position = [path.getAt(0).lng(), path.getAt(0).lat()];

      path.forEach((_, index) => {
        coordinates[0].push([path.getAt(index).lng(), path.getAt(index).lat()]);
      });

      coordinates[0].push(firstTuple);

      const turfPolygon = truncate(createTurfPolygon(coordinates));

      return turfPolygon.geometry;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const defaultBufferMetres = () =>
  parseInt(window.env.WAYPOINT_BUFFER_METRES) || 100;

export const getGeojsonPolygonFromGoogleMapsPolyline = (
  polyline: google.maps.Polyline | null,
  bufferMetres: number
): Polygon | null => {
  try {
    if (polyline) {
      const path = polyline.getPath();
      const coordinates: Position[] = [];

      path.forEach((_, index) => {
        coordinates.push([path.getAt(index).lng(), path.getAt(index).lat()]);
      });

      return buffer(createTurfLineString(coordinates), bufferMetres, {
        units: 'meters',
      })?.geometry;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getGeojsonLineStringFromGoogleMapsPolyline = (
  polyline: google.maps.Polyline | null
): LineString | null => {
  try {
    if (polyline) {
      const path = polyline.getPath();
      const coordinates: Position[] = [];

      path.forEach((_, index) => {
        coordinates.push([path.getAt(index).lng(), path.getAt(index).lat()]);
      });

      return createTurfLineString(coordinates)?.geometry;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const overlayToPathMode = (
  overlayType?: google.maps.drawing.OverlayType
): FlightPathModeEnum | null => {
  if (!overlayType) {
    return null;
  }

  switch (overlayType) {
    case google.maps.drawing.OverlayType.CIRCLE:
      return FlightPathModeEnum.CIRCLE;
    case google.maps.drawing.OverlayType.POLYGON:
      return FlightPathModeEnum.POLYGON;
    case google.maps.drawing.OverlayType.POLYLINE:
      return FlightPathModeEnum.SEGMENTED;
    default:
      return FlightPathModeEnum.POLYGON;
  }
};
