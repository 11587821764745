import React from 'react';
import { Loading } from '../loading/loading';
import { animated, useTransition } from 'react-spring';

import './loading-panel.scss';

interface Props {
  children?: React.ReactNode;
  isLoading: boolean;
}

export function LoadingPanel({ children, isLoading = false }: Props) {
  const transitions = useTransition(isLoading, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} className="loading-panel">
              <Loading />
            </animated.div>
          )
      )}
      {children}
    </>
  );
}
