import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NotificationEvent } from 'argus-events/notification';
import type {
  NearbyActivatedFlightData,
  AircraftTelemetry,
} from 'argus-events/interfaces';
import { type PilotSurveillanceWebsocketEvent } from 'argus-events/websocket';
import { SubscriptionEvent } from 'argus-events/subscription';

import { useSocket } from '../websocket/use-socket';
import { NotificationState } from '../state/NotificationState';
import { useActivatedFlightRequests } from '../state/flight-requests/hooks';

const isEnabled =
  window.env.PILOT_WEB_SURVEILLANCE_ENABLED?.toLowerCase() === 'true';

export const usePilotSurveillance = (): [AircraftTelemetry[], string[]] => {
  const { setAlert } = useContext(NotificationState);
  const activatedFlights = useActivatedFlightRequests();
  const [surveillanceData, setSurveillanceData] = useState<AircraftTelemetry[]>(
    []
  );
  const [surveillanceMessages, setSurveillanceMessages] = useState<string[]>(
    []
  );

  const socket = useSocket();

  const onEvent = useCallback(
    ({ payload, messages }: PilotSurveillanceWebsocketEvent) => {
      setSurveillanceData(payload);
      setSurveillanceMessages(messages);
    },
    []
  );

  const onNearbyActivatedFlightMessage = useCallback(
    (data: NearbyActivatedFlightData) => {
      setAlert(data);
    },
    [setAlert]
  );

  useEffect(() => {
    if (activatedFlights?.length > 0) {
      // emit on every change of activated flights to trigger a refresh of data
      socket?.emit(SubscriptionEvent.SubscribeToPilotSurveillance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFlights?.length]);

  const onSocketEstablished = useCallback(() => {
    if (isEnabled) {
      socket.on(NotificationEvent.PilotSurveillanceEvent, onEvent);
    }
    if (!isEnabled || activatedFlights?.length === 0) {
      socket?.emit(SubscriptionEvent.UnsubscribeFromPilotSurveillance);
      setSurveillanceData([]);
    }
    socket.on(
      NotificationEvent.NearbyActivatedFlight,
      onNearbyActivatedFlightMessage
    );
    if (activatedFlights?.length > 0) {
      socket?.emit(SubscriptionEvent.SubscribeToPilotSurveillance);
    }
  }, [
    socket,
    onEvent,
    onNearbyActivatedFlightMessage,
    activatedFlights?.length,
  ]);

  useMemo(() => {
    if (socket?.id) {
      onSocketEstablished();
    }
  }, [socket?.id, onSocketEstablished]);

  return [surveillanceData, surveillanceMessages];
};
