import React from 'react';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';

import './splash-page.scss';

interface Props {
  className?: string;
  primaryText: string;
  secondaryText?: string | JSX.Element;
  tertiaryText?: string;
  children?: string | JSX.Element;
}

export const SplashPage: React.FC<Props> = ({
  className,
  primaryText,
  secondaryText,
  tertiaryText,
  children,
}) => (
  <div className={cn('splash-page', { [className]: className })}>
    <div className="logo" />
    <Typography className="primary-text" variant="h5" component="p">
      {primaryText}
    </Typography>
    {secondaryText && (
      <Typography variant="subtitle1" className="secondary-text">
        {secondaryText}
      </Typography>
    )}
    {tertiaryText && (
      <Typography variant="subtitle1" className="tertiary-text">
        {tertiaryText}
      </Typography>
    )}
    {children}
  </div>
);
