import React, { useContext, useEffect, useRef, useState } from 'react';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import type { Polygon } from '@turf/helpers';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { DialogContent } from '@material-ui/core';

import {
  LoadingButton,
  COMPLETION_DELAY,
  useTimeoutEffect,
  useConfirmDialog,
} from 'airshare-pilot-web-shared';
import {
  FlightPurpose as FlightPurposeEnum,
  FlightRequestWarningType,
  type Warning,
  type WarningAirspaceStatus,
} from '@airshare/external-api-types';

import {
  AdvisorySource,
  type ExtendedFlightResponseBodyV2,
} from '@airshare/pilot-types';

import { feetToMeters } from 'airshare-shared-utils/measurements';
import { UasOperationCategory, FlightRule } from 'argus-common/enums';

import { CloseButton } from '../../../shared/close-button/close-button';
import { isFlightEditable } from '../../../../lib/flight-helpers';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { NearbyFlightsAccordion } from '../../../shared/flight-advisories/nearby-flights-accordion';
import { SegmentsAccordion } from '../../../shared/segments/segments-accordion';
import {
  ADD_UAV_PATH,
  DEFAULT_ROUTE,
  FLIGHT_PLAN_PATH,
  getFlightBriefingPath,
} from '../../../../routes';
import {
  useFormState,
  useInputHandlerProps,
  useIsErrored,
  useIsValid,
  useFieldError,
  useErrors,
  useHandleSubmit,
  useSetFieldValue,
  useUpdateValidation,
  useTouched,
} from '../../../../state/flight-plan/flight-plan-form/hooks';
import {
  useIsIntersectingControlZone,
  usePreValidateFlightArea,
} from '../../../../state/flight-plan/pre-validate-flight-area/hooks';
import {
  useValidateFlightPlan,
  useCreateFlightPlan,
  useFlightPlanValidationStatus,
  useFlightPlanCreationStatus,
  useSelectedFavourite,
  useFlightPlanValidationResponse,
  useFlightPlanEdit,
  useFlightPlanEditSuccess,
  useUpdateFlightPlan,
  useUpdateFlightPlanStatus,
} from '../../../../state/flight-plan/hooks';
import { useProfile } from '../../../../state/profile/hooks';
import {
  useUavs,
  usePilots,
} from '../../../../state/flight-request-defaults/hooks';
import { useIsOrgAdmin } from '../../../../state/session/hooks';
import {
  FlightPlanValidationStatus,
  FlightPlanCreationStatus,
  FlightPlanUpdateStatus,
} from '../../../../state/flight-plan/constants';
import {
  useFlightAdvisoriesUpdated,
  useHighlightedFlightAdvisoriesUpdated,
} from '../../../../state/flight-advisories/hooks';
import { useResetFlightPlan } from './hooks/reset-flight-plan.hook';

import useManagedAreaFlightArea from './hooks/use-managed-area-flight-area.hook';

import DateTimePicker from './DateTimePicker';
import { DraftFlightAreaSource } from '../../../../state/flight-plan/flight-plan-form/types';
import { humanizeTime } from './formatter';
import { GlobalState } from '~/state/GlobalState';
import {
  PreValidateFlightAreaPayloadFailed,
  PreValidateFlightAreaSucessPayload,
} from '~/state/flight-plan/pre-validate-flight-area/actions';
import OperationCategory from './operation-category';
import OperationRule from './operation-rule';
import PriorityOperationToggle from './priority-operation-toggle';
import FlightPurpose from './flight-purpose';

import './flight-plan.scss';

const PART_101_HEIGHT_LIMIT = 400;
const PART_102_HEIGHT_LIMIT = 10000;

const FLIGHT_REQUEST_MAX_DURATION_MINS_PART_101 =
  parseInt((window as any).env.FLIGHT_REQUEST_MAX_DURATION_MINS_PART_101) ??
  720;
const FLIGHT_REQUEST_MAX_DURATION_MINS_PART_102 =
  parseInt((window as any).env.FLIGHT_REQUEST_MAX_DURATION_MINS_PART_102) ??
  720;

interface Props extends RouteComponentProps<{ id: string }> {}

function FlightPlanComponent({ match }: Props) {
  const flightId = match.params.id;
  const editing = !!flightId;

  const isInAControlledZone = useIsIntersectingControlZone();
  const { validationResult } = usePreValidateFlightArea();
  const profile = useProfile();

  const navigateTo = useNavigateTo();
  const formTopRef = useRef(null);

  // Dispatch and Async Status
  const validateFlightPlan = useValidateFlightPlan();
  const createFlightPlan = useCreateFlightPlan();
  const {
    flightPlanValidationStatus: validationStatus,
    resetFlightPlanValidationStatus: resetValidationStatus,
  } = useFlightPlanValidationStatus();
  const { flightPlanCreationStatus: creationStatus } =
    useFlightPlanCreationStatus();
  const updateStatus = useUpdateFlightPlanStatus();
  const [isValidated, updateIsValidated] = useState(false);
  const [timezone, setTimezone] = useState(window.env.DEFAULT_TZ);
  const {
    setAdvisoryGeometry: setGlobalAdvisoryGeometry,
    setHighlightedSegments,
    setShowMyFlightMarkers,
  } = useContext(GlobalState);

  const setNearbyFlights = useFlightAdvisoriesUpdated();
  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();

  const [flightPlan, setFlightPlan] =
    useState<ExtendedFlightResponseBodyV2 | null>(null);
  const [advisoryGeometry, setAdvisoryGeometryLocal] = useState<Polygon>(null);

  const [preValidationError, setPreValidationError] = useState('');
  const [warnings, setWarnings] = useState<Warning[]>([]);
  const [priorityOperationLevel, setPriorityOperationLevel] = useState(-1);

  const { flightPlanSuccess } = useFlightPlanValidationResponse();

  // Form State & Handlers
  const form = useFormState();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const inputHandlerProps = useInputHandlerProps();
  const fieldError = useFieldError();
  const setFieldValue = useSetFieldValue();

  const isFormValid = useIsValid();
  const touchedState = useTouched();

  // Defaults
  const uavs = useUavs();

  const [requestFlightPlanToEdit] = useFlightPlanEdit();
  const updateFlightPlan = useUpdateFlightPlan();
  const [flightPlanToEditSuccess] = useFlightPlanEditSuccess();

  const maxDurationMins =
    form.rule === FlightRule.PART_102
      ? FLIGHT_REQUEST_MAX_DURATION_MINS_PART_102
      : FLIGHT_REQUEST_MAX_DURATION_MINS_PART_101;

  const DEFAULT_DURATION_MINS = 30;
  const [durationMinutesState, setDurationState] = useState(
    Math.min(DEFAULT_DURATION_MINS, maxDurationMins)
  );

  const pilots = usePilots()?.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  const [updateValidation] = useUpdateValidation();
  const isOrgAdmin = useIsOrgAdmin();
  const resetFlightPlan = useResetFlightPlan({
    setWarnings,
    setAdvisoryGeometryLocal,
  });

  const selectedFavourite = useSelectedFavourite();

  const {
    authedFlightPaths,
    selectedFlightPathArea,
    handleSelectFlightPathArea,
  } = useManagedAreaFlightArea(form);

  useEffect(() => {
    if (formTopRef.current) {
      formTopRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      });
    }

    setAdvisoryGeometryLocal(null);

    return () => resetFlightPlan(!!flightId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getFlightDetails = async () => {
      requestFlightPlanToEdit(flightId);
    };
    if (flightId) {
      getFlightDetails();
    }
    resetValidationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightId]);

  useEffect(() => {
    if (flightPlanToEditSuccess?.properties?.flightId && !flightPlan) {
      setFlightPlan(flightPlanToEditSuccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightPlanToEditSuccess]);

  useEffect(() => {
    setFieldValue('durationMinutes', durationMinutesState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationMinutesState]);

  useEffect(() => {
    if (flightPlan) {
      if (!isFlightEditable(flightPlan)) {
        const flightIdString = flightPlan.properties?.flightId?.toString();
        navigateTo(getFlightBriefingPath(flightIdString));
      }
      populateFormFromFlightPlan(flightPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightPlan]);

  useEffect(() => {
    updateValidation(form);
    if (isValidated) {
      updateIsValidated(false);
      resetValidationStatus();
      setNearbyFlights([]);
      setHighlightedNearbyFlights([]);
      setHighlightedSegments([]);
      setShowMyFlightMarkers(true);
      setWarnings([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (validationResult) {
      if (
        (validationResult as PreValidateFlightAreaSucessPayload)?.status !==
          200 &&
        (validationResult as PreValidateFlightAreaPayloadFailed).message
      ) {
        setPreValidationError(
          (validationResult as PreValidateFlightAreaPayloadFailed).message
        );
      } else {
        setPreValidationError('');
      }
      const areaTimezone: string = (
        validationResult as PreValidateFlightAreaSucessPayload
      ).data?.timezone;

      if (areaTimezone) {
        setTimezone(areaTimezone);
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (form.dateAndTime) {
          // the legacy date picker calls setFieldValue('dateAndTime') directly so only execute here for newer browsers
          if (form.dateAndTime instanceof DateTime) {
            const newDateTimeWithZoneUpdate = form.dateAndTime.setZone(
              areaTimezone,
              { keepLocalTime: true }
            );
            setFieldValue('dateAndTime', newDateTimeWithZoneUpdate);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationResult]);

  useEffect(() => {
    if (
      (isInAControlledZone ||
        flightPlan?.properties?.allocatedControlZone?.code) &&
      !form.isShielded
    ) {
      setFieldValue('requiresAdditionalInformation', true);
    } else {
      setFieldValue('requiresAdditionalInformation', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInAControlledZone, form.isShielded]);

  useEffect(() => {
    if (!form.requiresAdditionalInformation) {
      setFieldValue('procedureMeasureAltitude', '');
      setFieldValue('emergencyProcedure', '');
    } else {
      const selectedUav = uavs.find((u) => u.id === form.uavId);

      if (selectedUav) {
        setFieldValue(
          'procedureMeasureAltitude',
          selectedUav.defaultProcedureToMeasureAltitude ?? ''
        );
        setFieldValue(
          'emergencyProcedure',
          selectedUav.defaultEmergencyProcedure ?? ''
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.requiresAdditionalInformation, form.uavId, uavs]);

  useEffect(() => {
    if (profile && typeof profile.priorityOperationLevel === 'number') {
      setPriorityOperationLevel(profile.priorityOperationLevel);
    }
  }, [profile]);

  const populateFormFromFlightPlan = (plan: ExtendedFlightResponseBodyV2) => {
    if (plan?.properties) {
      const props = plan.properties;
      const { additionalInfo, uav } = plan.properties;

      setFieldValue('altitudeFeet', props.maxAltitudeFeet);
      setFieldValue('isShielded', props.isShielded);
      setFieldValue('flightPurpose', props.activity);

      const planStartTime = DateTime.fromISO(props.startDateTime).setZone(
        props.timezone
      );
      const planEndTime = DateTime.fromISO(props.endDateTime).setZone(
        props.timezone
      );
      const planDuration = planEndTime.diff(planStartTime, 'minutes').minutes;
      const durationMinutesValue = Math.min(planDuration, maxDurationMins);
      setFieldValue('durationMinutes', durationMinutesValue);
      setDurationState(durationMinutesValue);

      if (pilots?.find((p) => p.email === props.pilotEmail)) {
        setFieldValue('pilotId', props.pilotId);
        setFieldValue('pilotName', props.pilotName);
      }

      if (editing) {
        setFieldValue('nickname', additionalInfo.nickname);
      }
      setFieldValue(
        'rule',
        additionalInfo.operatingRule as string as FlightRule
      );
      setFieldValue('isCameraInUse', additionalInfo.isCameraInUse);
      setFieldValue('isPriorityFlight', additionalInfo.isPriorityFlight);
      setFieldValue(
        'requiresAdditionalInformation',
        !!props.allocatedControlZone?.code
      );
      setFieldValue(
        'hasCertifiedTransmitter',
        additionalInfo.hasCertifiedTransmitter
      );
      setFieldValue('hasVhfRadioContact', additionalInfo.hasVhfRadioContact);
      setFieldValue(
        'descriptionOfOperatingArea',
        additionalInfo.descriptionOfOperatingArea
      );
      setFieldValue(
        'procedureMeasureAltitude',
        additionalInfo.procedureToMeasureAltitude
      );
      setFieldValue('emergencyProcedure', additionalInfo.emergencyProcedure);
      setFieldValue('otherInformation', additionalInfo.otherInformation);
      setFieldValue(
        'coordinates',
        props.coordinates.map(({ Lat, Lng }) => ({
          lat: Lat,
          lng: Lng,
        })) ?? null
      );
      setFieldValue('pathMode', props.pathMode ?? null);
      setFieldValue('radius', props.radius || 0);
      setFieldValue('waypoints', props.waypoints ?? null);
      setFieldValue('segmentCollection', props.segmentCollection ?? null);

      if (props.managedAreaId) {
        handleSelectFlightPathArea(props.managedAreaId);
      }

      if (planStartTime && editing) {
        setFieldValue('dateAndTime', planStartTime.setZone(props.timezone));
      }

      setFieldValue('uavId', `${uav.uavId}`);

      if (!uav.uavId && uav.name && uavs) {
        const matchingUavs = uavs.filter(
          (drone: { name: any }) => drone.name === uav.name
        );
        if (matchingUavs?.length === 1) {
          setFieldValue('uavId', matchingUavs[0].id);
        }
      }

      setAdvisoryGeometryLocal(plan.geometry as Polygon);
    }
  };

  useEffect(() => {
    if (
      selectedFavourite &&
      form.flightAreaSource !== DraftFlightAreaSource.FAVOURITE
    ) {
      populateFormFromFlightPlan(selectedFavourite);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFavourite]);

  useEffect(() => {
    if (
      flightPlanSuccess?.nearbyFlights &&
      validationStatus === FlightPlanValidationStatus.SUCCESS
    ) {
      setNearbyFlights(flightPlanSuccess.nearbyFlights);
    } else {
      setNearbyFlights([]);
      setHighlightedNearbyFlights([]);
      setHighlightedSegments([]);
      setShowMyFlightMarkers(true);
    }

    if (flightPlanSuccess?.warnings) {
      setWarnings(flightPlanSuccess.warnings);
    } else {
      setWarnings([]);
    }

    if (flightPlanSuccess?.segments && form.segmentCollection) {
      const updatedCollection = {
        ...form.segmentCollection,
        features: form.segmentCollection.features.map((f) => {
          const segmentValidation = flightPlanSuccess.segments.find(
            (s) => s.id === f.properties.id
          );

          if (segmentValidation) {
            return {
              ...f,
              properties: {
                ...f.properties,
                ...segmentValidation,
              },
            };
          }
          return f;
        }),
      };

      setFieldValue('segmentCollection', updatedCollection);
    }

    return () => {
      setNearbyFlights([]);
      setHighlightedNearbyFlights([]);
      setWarnings([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightPlanSuccess, validationStatus]);

  // promote advisory geometry up to global as final step
  // setting directly was causing endless loop that locks the map in place
  useEffect(() => {
    setGlobalAdvisoryGeometry(
      advisoryGeometry ? AdvisorySource.FlightBriefing : AdvisorySource.None,
      advisoryGeometry
    );
  }, [advisoryGeometry, setGlobalAdvisoryGeometry]);

  const {
    dialog: confirmResetFormDialog,
    setShowConfirm: setShowConfirmResetUpload,
    setContentToConfirm: setConfirmationDialogContent,
  } = useConfirmDialog(
    'Close new flight plan creation?',
    () => {
      navigateTo(DEFAULT_ROUTE, { referrer: FLIGHT_PLAN_PATH });
      resetFlightPlan();
    },
    () => {},
    { text: 'Yes' },
    { text: 'No', color: 'inherit' }
  );
  const handleCancelButtonClick = () => {
    setConfirmationDialogContent(
      <DialogContent>
        Are you sure you want close this new flight plan creation and reset all
        values to their defaults?
      </DialogContent>
    );
    setShowConfirmResetUpload(true);
  };

  // Handle validation completion delays
  useTimeoutEffect(
    () => {
      const preValidationFailed = !!preValidationError;
      updateIsValidated(!preValidationFailed);
    },
    COMPLETION_DELAY,
    validationStatus === FlightPlanValidationStatus.SUCCESS
  );

  const handleValidate = useHandleSubmit(() => {
    handleFlightPlanValidation();
  });

  const handleCreate = useHandleSubmit(() => {
    if (
      creationStatus !==
      FlightPlanCreationStatus.FLIGHT_PLAN_CREATION_IN_PROGRESS
    ) {
      createFlightPlan(form);
    }
  });

  const handleUpdate = useHandleSubmit(() => {
    navigateTo(getFlightBriefingPath(flightId));
    if (
      updateStatus !== FlightPlanUpdateStatus.FLIGHT_PLAN_UPDATE_IN_PROGRESS
    ) {
      const updatedPlan = {
        flightId,
        ...form,
      };
      updateFlightPlan(updatedPlan);
      resetFlightPlan();
    }
  });

  function handleFlightPlanValidation() {
    if (
      isFormValid &&
      validationStatus !==
        FlightPlanValidationStatus.FLIGHT_PLAN_VALIDATION_IN_PROGRESS
    ) {
      validateFlightPlan({
        ...form,
        flightId,
      });
    }
  }

  const renderWarnings = (fullWarningData: Warning[]) => {
    if (
      fullWarningData?.filter(
        (w) =>
          w.type === FlightRequestWarningType.AirspaceWarning ||
          w.type === FlightRequestWarningType.NotamRequiredWarning
      )?.length > 0
    ) {
      return (
        <Typography
          className="flight-validation-warning"
          data-testid="pilot-web:flight-request:validation-warnings"
        >
          {fullWarningData
            .filter(
              (w) =>
                w.type === FlightRequestWarningType.AirspaceWarning ||
                w.type === FlightRequestWarningType.NotamRequiredWarning
            )
            .map((w) => (w as WarningAirspaceStatus)?.message)
            .join(' ')}
        </Typography>
      );
    }
  };

  const isValidating =
    validationStatus ===
    FlightPlanValidationStatus.FLIGHT_PLAN_VALIDATION_IN_PROGRESS;
  const isCreating =
    creationStatus ===
    FlightPlanCreationStatus.FLIGHT_PLAN_CREATION_IN_PROGRESS;
  const isUpdating =
    updateStatus === FlightPlanUpdateStatus.FLIGHT_PLAN_UPDATE_IN_PROGRESS;

  const isDateAndTimeTouched = touchedState.dateAndTime;
  const haveSomeAuthedFlightPaths = !!authedFlightPaths?.length;

  const datepickertimezone = editing
    ? flightPlan?.properties?.timezone
    : timezone;

  const isPart102Allowed =
    form?.certificationType?.trim() === FlightRule.PART_102;

  return (
    <form
      className="flight-plan-panel"
      data-testid="pilot-web:flight-plan-form"
      ref={formTopRef}
    >
      <Typography
        className="heading"
        variant="h6"
        component="h1"
        align="center"
        data-testid="pilot-web:flight-request:heading"
      >
        {editing ? `Editing Flight Request ${flightId}` : 'New Flight Request'}
      </Typography>
      {!editing && (
        <CloseButton
          className="cancel-button"
          onClick={handleCancelButtonClick}
        />
      )}
      {haveSomeAuthedFlightPaths && !selectedFavourite && (
        <>
          <FormLabel className="form-section-heading" component="h2">
            Flight Path Area
          </FormLabel>

          <FormControl className="flight-path-area-control">
            <Select
              type="text"
              name="flightPathArea"
              inputProps={{
                name: 'flightPathArea',
                className: 'flight-path-area',
              }}
              value={selectedFlightPathArea?.id ?? 'None'}
              {...inputHandlerProps}
              onChange={(_, component) => {
                handleSelectFlightPathArea((component as any).props.value);
              }}
              disabled={!!selectedFavourite || editing}
              data-testid="pilot-web:flight-request:flight-path-area"
            >
              <MenuItem
                key={'None'}
                value={'None'}
                data-testid="pilot-web:flight-request:flight-path-area-item"
              >
                None
              </MenuItem>
              {authedFlightPaths?.map(
                ({ name, id }: { name: string; id: string }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    data-testid="pilot-web:flight-request:flight-path-area-item"
                  >
                    {name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </>
      )}
      <FormControl className="form-control" error={isErrored('nickname')}>
        <TextField
          type="text"
          className="nickname-input"
          label="Nickname"
          id="nickname-input"
          name="nickname"
          disabled={editing}
          {...inputHandlerProps}
          inputProps={{
            value: form.nickname,
          }}
          data-testid="pilot-web:flight-request:nickname"
        />
        <FormHelperText className="input-hint">
          {fieldError('nickname')}
        </FormHelperText>
      </FormControl>
      {!selectedFavourite && (
        // @ts-ignore
        <FormControlLabel
          label={
            <Typography variant="subtitle1">Save as a favourite?</Typography>
          }
          labelPlacement="start"
          className="save-as-a-favourite-switch"
          control={
            <Switch
              name="isFavourite"
              size="small"
              color="primary"
              checked={form.isFavourite}
              disabled={editing}
              {...inputHandlerProps}
              onChange={(_, value) => {
                setFieldValue('isFavourite', value);
              }}
              data-testid="pilot-web:flight-request:save-as-favourite-switch"
            />
          }
        >
          <FormHelperText className="input-hint">
            {fieldError('isFavourite')}
          </FormHelperText>
        </FormControlLabel>
      )}
      <OperationCategory
        isPart102Allowed={isPart102Allowed}
        currentValue={form.uasOperationCategory}
        onChange={(event) => {
          inputHandlerProps.onChange(event);
          if (UasOperationCategory.OPEN === event.currentTarget.value) {
            setFieldValue('rule', FlightRule.PART_101);
          }
        }}
        disabled={!form?.certificationType?.trim() || editing}
      />
      <OperationRule
        isPart102Allowed={isPart102Allowed}
        currentValue={form.rule}
        onChange={(event) => {
          if (
            event.target.innerText.toLowerCase() ===
            FlightRule.PART_101.toLocaleLowerCase()
          ) {
            const value = Math.min(
              durationMinutesState,
              FLIGHT_REQUEST_MAX_DURATION_MINS_PART_101
            );
            setFieldValue('durationMinutes', value);
            setDurationState(value);
          }
          inputHandlerProps.onChange(event);
        }}
        operationCategory={form.uasOperationCategory}
        disabled={!form?.certificationType?.trim() || editing}
      />
      {/* 
// @ts-ignore */}
      <FormControlLabel
        label={
          <div
            className="slider-label form-section-heading"
            data-testid="pilot-web:flight-request:altitude"
          >
            <Typography variant="subtitle1" className="main-label">
              Altitude
            </Typography>
            <Typography variant="subtitle1" className="value-label">
              {form.altitudeFeet} ft. {feetToMeters(form.altitudeFeet)} m
            </Typography>
          </div>
        }
        labelPlacement="top"
        className="altitude-slider"
        control={
          <Slider
            data-testid="pilot-web:flight-request:altitude-slider"
            aria-labelledby="continuous-slider"
            name="altitudeFeet"
            defaultValue={form.altitudeFeet}
            value={form.altitudeFeet}
            min={10}
            max={
              form.rule === FlightRule.PART_102
                ? PART_102_HEIGHT_LIMIT
                : PART_101_HEIGHT_LIMIT
            }
            marks={
              form.rule === FlightRule.PART_102
                ? [
                    {
                      value: PART_101_HEIGHT_LIMIT,
                      label: (
                        <span className="slider-mark-label mark-400-feet part-102">
                          400 ft
                        </span>
                      ),
                    },
                    {
                      value: PART_102_HEIGHT_LIMIT,
                      label: (
                        <span className="slider-mark-label mark-10000-feet part-102">
                          10,000 ft
                        </span>
                      ),
                    },
                  ]
                : [
                    {
                      value: PART_101_HEIGHT_LIMIT,
                      label: (
                        <span className="slider-mark-label mark-400-feet part-101">
                          400 ft
                        </span>
                      ),
                    },
                  ]
            }
            step={10}
            {...inputHandlerProps}
            onChange={(_, value: number) => {
              setFieldValue('altitudeFeet', value);
            }}
          />
        }
      >
        <FormHelperText className="input-hint">
          {fieldError('altitudeFeet')}
        </FormHelperText>
      </FormControlLabel>
      {/* 
// @ts-ignore */}
      <FormControlLabel
        label={
          <Typography
            variant="subtitle1"
            data-testid="pilot-web:flight-request:is-shielded-name"
          >
            Is this a shielded operation?
          </Typography>
        }
        labelPlacement="start"
        className="is-shielded-switch"
        control={
          <Switch
            data-testid="pilot-web:flight-request:is-shielded-switch"
            name="isShielded"
            size="small"
            color="primary"
            checked={form.isShielded}
            {...inputHandlerProps}
            onChange={(_, value) => {
              setFieldValue('isShielded', value);
            }}
          />
        }
        onError={() => (
          <FormHelperText className="input-hint">
            {fieldError('isShielded')}
          </FormHelperText>
        )}
      >
        <FormHelperText className="input-hint">
          {fieldError('isShielded')}
        </FormHelperText>
      </FormControlLabel>
      <PriorityOperationToggle
        priorityOperationLevel={priorityOperationLevel}
        isPriorityFlight={form.isPriorityFlight}
        inputHandlerProps={inputHandlerProps}
        onChange={(_, value) => {
          setFieldValue('isPriorityFlight', value);
          setFieldValue(
            'flightPurpose',
            value ? '' : FlightPurposeEnum.AerialFilming
          );
        }}
      />
      <FlightPurpose
        currentValue={form.flightPurpose}
        inputHandlerProps={inputHandlerProps}
        hasInputError={isErrored('flightPurpose')}
        onChange={(selectedFlightPurpose) => {
          setFieldValue('flightPurpose', selectedFlightPurpose);
        }}
        isPriorityFlight={form.isPriorityFlight}
        errorMessage={fieldError('flightPurpose')}
      />
      <FormLabel className="form-section-heading" component="h2">
        Date & Time
      </FormLabel>
      <FormControl
        className="form-control date-and-time"
        error={isDateAndTimeTouched && isErrored('dateAndTime')}
      >
        <DateTimePicker
          setFieldValue={setFieldValue}
          onFocus={inputHandlerProps.onFocus}
          dateTime={form.dateAndTime}
          isDateAndTimeTouched={isDateAndTimeTouched}
          isErrored={isErrored('dateAndTime')}
          timezone={datepickertimezone}
          data-testid="pilot-web:flight-request:date-time"
        />
        <FormHelperText className="input-hint">
          {isDateAndTimeTouched && fieldError('dateAndTime')}
        </FormHelperText>
      </FormControl>
      {/* 
// @ts-ignore */}
      <FormControlLabel
        label={
          <div
            className="slider-label"
            data-testid="pilot-web:flight-request:duration"
          >
            <Typography variant="subtitle1" className="main-label">
              Duration
            </Typography>
            <Typography variant="subtitle1" className="value-label">
              {humanizeTime(durationMinutesState)}
            </Typography>
          </div>
        }
        labelPlacement="top"
        className="duration-slider"
        control={
          <Slider
            aria-labelledby="continuous-slider"
            name="durationMinutes"
            defaultValue={durationMinutesState}
            value={durationMinutesState}
            min={10}
            max={
              form.rule === FlightRule.PART_102
                ? FLIGHT_REQUEST_MAX_DURATION_MINS_PART_102
                : FLIGHT_REQUEST_MAX_DURATION_MINS_PART_101
            }
            step={5}
            {...inputHandlerProps}
            onChange={(_, value: number) => {
              setFieldValue('durationMinutes', value);
              setDurationState(value);
            }}
            data-testid="pilot-web:flight-request:duration-slider"
          />
        }
      >
        <FormHelperText className="input-hint">
          {fieldError('durationMinutes')}
        </FormHelperText>
      </FormControlLabel>
      <FormLabel className="form-section-heading" component="h2">
        Pilot & Aircraft
      </FormLabel>
      <FormControl className="form-control" error={isErrored('pilotName')}>
        {isOrgAdmin ? (
          <Select
            type="text"
            name="pilotName"
            inputProps={{
              name: 'pilotName',
              className: 'pilot-select select-icon',
            }}
            value={form.pilotName || ''}
            disabled={editing}
            {...inputHandlerProps}
            onChange={(_, component: { props: { value: string } }) => {
              const pilotName = component.props.value;
              const selectedPilot = pilots.find((p) => p.name === pilotName);
              const {
                id,
                name,
                certificationNumber,
                certificationType,
                email,
                defaultUav,
              } = selectedPilot;
              setFieldValue('pilotId', `${id}`);
              setFieldValue('pilotName', name);
              setFieldValue('pilotEmail', email);
              setFieldValue('certificationNumber', certificationNumber?.trim());
              setFieldValue('certificationType', certificationType?.trim());
              setFieldValue('rule', FlightRule.PART_101);
              if (defaultUav) {
                setFieldValue('uavId', defaultUav);
              }
            }}
            data-testid="pilot-web:flight-request:pilot-select"
          >
            {pilots?.map(({ name }, index) =>
              name ? (
                <MenuItem
                  key={index}
                  value={name}
                  data-testid="pilot-web:flight-request:pilot-select-item"
                >
                  {name}
                </MenuItem>
              ) : null
            )}
          </Select>
        ) : (
          <TextField
            type="text"
            className="pilot-input"
            name="pilotName"
            disabled={editing}
            {...inputHandlerProps}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="input-icon" />
                </InputAdornment>
              ),
              value: form.pilotName,
            }}
            data-testid="pilot-web:flight-request:pilot-name"
          />
        )}

        <FormHelperText className="input-hint">
          {fieldError('pilotName')}
        </FormHelperText>
      </FormControl>
      <FormControl className="form-control" error={isErrored('uavId')}>
        {Boolean(!uavs || uavs?.length > 0) && (
          <Select
            type="text"
            name="uavId"
            displayEmpty
            inputProps={{
              name: 'uavId',
              className: 'uav-select-input',
            }}
            readOnly={!uavs}
            disabled={editing}
            value={form.uavId}
            {...inputHandlerProps}
            onChange={(_, component: { props: { value: string } }) => {
              setFieldValue('uavId', component.props.value);
            }}
            data-testid="pilot-web:flight-request:uav-select"
          >
            {uavs?.map((uav) => {
              const fieldValue = uav.name ?? `${uav.make} ${uav.model ?? ''}`;
              return (
                <MenuItem
                  key={uav.id}
                  value={uav.id}
                  data-testid="pilot-web:flight-request:uav-select-item"
                >
                  {fieldValue}
                </MenuItem>
              );
            })}
          </Select>
        )}
        {uavs?.length === 0 && (
          <TextField
            type="text"
            className="uav-creation-prompt"
            value="Click here to add a UAV"
            onClick={() =>
              navigateTo(ADD_UAV_PATH, { referrer: FLIGHT_PLAN_PATH })
            }
            inputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <i className="input-icon" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <i className="input-icon" />
                </InputAdornment>
              ),
            }}
            data-testid="pilot-web:flight-request:uav-name"
          />
        )}
        <FormHelperText className="input-hint">
          {fieldError('uavId')}
        </FormHelperText>
      </FormControl>

      <br />
      {/* 
// @ts-ignore */}
      <FormControlLabel
        label={
          <Typography
            variant="subtitle1"
            data-testid="pilot-web:flight-request:use-camera-text"
          >
            Will your camera be in use?
          </Typography>
        }
        labelPlacement="start"
        className="camera-in-use-switch"
        control={
          <Switch
            name="isCameraInUse"
            size="small"
            color="primary"
            checked={form.isCameraInUse}
            {...inputHandlerProps}
            onChange={(_, value) => {
              setFieldValue('isCameraInUse', value);
            }}
            data-testid="pilot-web:flight-request:use-camera-switch"
          />
        }
      >
        <FormHelperText className="input-hint">
          {fieldError('isCameraInUse')}
        </FormHelperText>
      </FormControlLabel>

      <SegmentsAccordion
        segmentFeatures={form?.segmentCollection?.features}
        timezone={datepickertimezone}
        isReadOnly={editing}
        waypoints={form?.waypoints}
      />
      <FormHelperText className="input-hint" error>
        {fieldError('segmentCollection')}
      </FormHelperText>

      {form.requiresAdditionalInformation && (
        <>
          <Typography
            className="controlled-zone-warning"
            variant="subtitle2"
            align="center"
            data-testid="pilot-web:flight-request:fly-in-control-zone"
          >
            You are requesting to fly in a controlled zone.
          </Typography>
          {!editing && (
            <Typography
              className="controlled-zone-warning"
              variant="subtitle2"
              align="center"
              data-testid="pilot-web:flight-request:need-information"
            >
              We will also need the following information.
            </Typography>
          )}
          {/* 
// @ts-ignore */}
          <FormControlLabel
            label={
              <Typography
                variant="subtitle1"
                data-testid="pilot-web:flight-request:cert-transmitter"
              >
                Certified transmitter fitted?
              </Typography>
            }
            labelPlacement="start"
            className="certified-transmitter-switch"
            control={
              <Switch
                name="hasCertifiedTransmitter"
                size="small"
                color="primary"
                checked={form.hasCertifiedTransmitter}
                {...inputHandlerProps}
                onChange={(_, value) => {
                  setFieldValue('hasCertifiedTransmitter', value);
                }}
                data-testid="pilot-web:flight-request:cert-transmitter-switch"
              />
            }
          >
            <FormHelperText className="input-hint">
              {fieldError('hasCertifiedTransmitter')}
            </FormHelperText>
          </FormControlLabel>
          {/* 
// @ts-ignore */}
          <FormControlLabel
            label={
              <Typography
                variant="subtitle1"
                data-testid="pilot-web:flight-request:vfh-radio-contact"
              >
                VHF radio contact available?
              </Typography>
            }
            labelPlacement="start"
            className="vhf-radio-switch"
            control={
              <Switch
                name="hasVhfRadioContact"
                size="small"
                color="primary"
                checked={form.hasVhfRadioContact}
                {...inputHandlerProps}
                onChange={(_, value) => {
                  setFieldValue('hasVhfRadioContact', value);
                }}
                data-testid="pilot-web:flight-request:vfh-radio-contact-switch"
              />
            }
          >
            <FormHelperText className="input-hint">
              {fieldError('hasVhfRadioContact')}
            </FormHelperText>
          </FormControlLabel>

          <FormControl
            className="form-control"
            error={isErrored('descriptionOfOperatingArea')}
          >
            <TextField
              label="Describe operating area"
              name="descriptionOfOperatingArea"
              id="descriptionOfOperatingArea-input"
              value={form.descriptionOfOperatingArea}
              multiline
              className="description-of-operation-field"
              margin="normal"
              {...inputHandlerProps}
              data-testid="pilot-web:flight-request:description-of-operation-area-text"
            />
            <FormHelperText className="input-hint">
              {fieldError('descriptionOfOperatingArea')}
            </FormHelperText>
          </FormControl>

          <FormControl
            className="form-control"
            error={isErrored('procedureMeasureAltitude')}
          >
            <TextField
              label="Describe procedure to measure altitude"
              name="procedureMeasureAltitude"
              value={form.procedureMeasureAltitude}
              multiline
              className="measure-altitude-field"
              margin="normal"
              {...inputHandlerProps}
              data-testid="pilot-web:flight-request:procedure-measure-altitude-text"
            />
            <FormHelperText className="input-hint">
              {fieldError('procedureMeasureAltitude')}
            </FormHelperText>
          </FormControl>
          <FormControl
            className="form-control"
            error={isErrored('emergencyProcedure')}
          >
            <TextField
              label="Describe emergency procedure"
              name="emergencyProcedure"
              value={form.emergencyProcedure}
              multiline
              className="emergency-procedure-field"
              margin="normal"
              {...inputHandlerProps}
              data-testid="pilot-web:flight-request:emergency-procedure-text"
            />
            <FormHelperText className="input-hint">
              {fieldError('emergencyProcedure')}
            </FormHelperText>
          </FormControl>

          <FormControl
            className="form-control"
            error={isErrored('otherInformation')}
          >
            <TextField
              label="Add any other pertinent information"
              name="otherInformation"
              value={form.otherInformation}
              multiline
              className="other-information-field"
              margin="normal"
              {...inputHandlerProps}
              data-testid="pilot-web:flight-request:other-information-text"
            />
            <FormHelperText className="input-hint">
              {fieldError('otherInformation')}
            </FormHelperText>
          </FormControl>
        </>
      )}

      {renderWarnings(warnings)}

      <NearbyFlightsAccordion />

      <FormHelperText
        className="validation-errors"
        error
        data-testid="pilot-web:flight-request:validation-errors"
      >
        {(creationStatus !== FlightPlanCreationStatus.SUCCESS &&
          fieldError('coordinates')) ||
          fieldError('certificationNumber') ||
          fieldError('uavId') ||
          fieldError('dateAndTime') ||
          fieldError('durationMinutes') ||
          fieldError('waypoints') ||
          errors.message}
      </FormHelperText>
      <FormHelperText
        className="validation-errors"
        error={!!preValidationError}
        data-testid="pilot-web:flight-request:pre-validation-errors"
      >
        {preValidationError}
      </FormHelperText>
      {!isValidated && (
        <LoadingButton
          isLoading={isValidating}
          variant="secondary"
          className="form-control submit-button"
          type="button"
          onClick={(e) => handleValidate(e)}
          data-testid="pilot-web:flight-plan-form:validate-button"
        >
          Validate
        </LoadingButton>
      )}
      {isValidated && !editing && (
        <LoadingButton
          isLoading={isCreating}
          className="form-control submit-button"
          type="button"
          onClick={(e) => handleCreate(e)}
          data-testid="pilot-web:flight-plan-form:submit-button"
        >
          Submit
        </LoadingButton>
      )}

      {isValidated && editing && (
        <LoadingButton
          isLoading={isUpdating}
          className="form-control submit-button"
          type="button"
          onClick={(e) => handleUpdate(e)}
          data-testid="pilot-web:flight-plan-form:update-button"
        >
          Update
        </LoadingButton>
      )}
      {confirmResetFormDialog}
    </form>
  );
}
export const FlightPlan = withRouter(FlightPlanComponent);
