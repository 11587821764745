import { useCallback, useContext, useMemo } from 'react';

import { createAuthenticatedSocket } from 'airshare-web-utils/socket-authenticated';
import { pilotRoomName } from 'argus-events/rooms';
import { useProfile as getUserProfile } from '../state/profile/hooks';
import { getUser } from '../pilot-api-client';
import type { Socket } from 'socket.io-client';
import { GlobalState } from '../state/GlobalState';

const URL = `${window.env.ARGUS_WEBSOCKET_SERVICE_URL}/pilot`;

export const useSocket = (): Socket => {
  const { socket, setSocket } = useContext(GlobalState);
  const pilotId = getUserProfile()?.pilot?.result?.id;

  const establishSocket = useCallback(async () => {
    const newEmailSocket = await createAuthenticatedSocket(URL, getUser, {
      roomName: pilotRoomName(pilotId),
    });
    setSocket(newEmailSocket);
  }, [setSocket, pilotId]);

  const disconnect = useCallback(() => {
    socket?.disconnect();
    setSocket(null);
  }, [socket, setSocket]);

  useMemo(() => {
    if (!socket?.id && pilotId) {
      establishSocket();
    }
  }, [socket?.id, establishSocket, pilotId]);

  useMemo(() => {
    if (socket?.id && !pilotId) {
      disconnect();
    }
  }, [socket?.id, disconnect, pilotId]);

  return socket;
};
