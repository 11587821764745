import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import './custom-slider.scss';
import { debounce } from 'lodash';
interface Mark {
  value: number;
  label: string;
}

const DEFAULT_LOCALE = window.env.DEFAULT_LOCALE ?? 'en-nz';
const currentDate = DateTime.now().setLocale(DEFAULT_LOCALE);
const twoDaysFromNow = currentDate.plus({ days: 2 });
const marks: Mark[] = [];
let currentDay = currentDate;
const specificHours = [0, 6, 12, 18, 24].filter(
  (hour) => currentDay.set({ hour, minute: 0, second: 0 }) <= twoDaysFromNow
);
while (currentDay <= twoDaysFromNow) {
  specificHours.forEach((hour) => {
    const time = currentDay.set({ hour, minute: 0, second: 0 });
    const existingMark = marks.find(
      (mark) => mark.value === time.diff(currentDate).as('minutes')
    );
    if (!existingMark && time >= currentDate && time <= twoDaysFromNow) {
      marks.push({
        value: time.diff(currentDate).as('minutes'),
        label: `${time.toFormat('HH:mm')} ${currentDay.offsetNameShort}`,
      });
    }
  });
  currentDay = currentDay.plus({ days: 1 });
}
const PilotWebSlider = withStyles({
  root: {
    color: '#4377bc',
    height: 8,
    opacity: 1,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&::after': {
      top: -2,
      bottom: -2,
      left: -2,
      right: -2,
    },
    '& .MuiSlider-valueLabel>span': {
      boxShadow: '0 0 3px 0 rgba(0,0,0,0.4)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(50% + 4px)',
    fontSize: 14,
    marginLeft: -40,
    marginTop: 2,
    '& *': {
      color: '#fff',
      width: 'max-content',
      display: 'flex',
      padding: 5,
      transform: 'rotate(0deg)',
      background: '#4377bc',
      borderRadius: 10,
      position: 'relative',
    },
  },
  markLabel: {
    fontSize: 12,
    marginTop: 4,
    fontWeight: 'bold',
  },
  track: {
    height: 4,
    borderRadius: 20,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
  mark: {
    height: 10,
    width: 2,
    background: '#000',
    marginTop: 2,
  },
})(Slider);
interface CustomSliderProps {
  handleFilterMapLayers(startDate: DateTime, endDate: DateTime): void;
}
function CustomSlider(props: CustomSliderProps) {
  const { handleFilterMapLayers } = props;
  const [dateRange, setDateRange] = useState<DateTime[]>([
    currentDate,
    currentDate.plus({ hour: 1 }),
  ]);
  const valueLabelFormat = (value: number) => {
    const time = currentDate.plus({ minutes: value });
    return `${time.toFormat('EEE dd - HH:mm')}`;
  };
  const handleRangeSliderChange = (values: number[]) => {
    setDateRange([
      currentDate.plus({ minutes: values[0] }),
      currentDate.plus({ minutes: values[1] }),
    ]);
  };
  const handleSliderChange = async (values: number[]) => {
    const startDate = currentDate
      .plus({ minutes: values[0] })
      .set({ second: 0, millisecond: 0 });
    const endDate = currentDate
      .plus({ minutes: values[1] })
      .set({ second: 59, millisecond: 999 });
    handleFilterMapLayers(startDate, endDate);
  };

  const debouncedHandleSliderChange = useRef(
    debounce((values: number[]) => {
      handleSliderChange(values);
    }, 100)
  ).current;

  useEffect(() => {
    debouncedHandleSliderChange(
      dateRange.map((date) => date.diff(currentDate).as('minutes'))
    );
    return () => {
      debouncedHandleSliderChange.cancel();
    };
  }, [dateRange, debouncedHandleSliderChange]);

  return (
    <div className="map-slider-container">
      <div className="time-slider">
        <PilotWebSlider
          data-testid="slider-date-range"
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay="auto"
          step={1}
          marks={marks as Mark[]}
          min={currentDate.diff(currentDate).as('minutes')}
          max={twoDaysFromNow.diff(currentDate).as('minutes')}
          value={dateRange.map((date) => date.diff(currentDate).as('minutes'))}
          // @ts-ignore
          onChange={(_: Event, values: number[]) => {
            handleRangeSliderChange(values);
            debouncedHandleSliderChange(values);
          }}
        />
      </div>
    </div>
  );
}
export default CustomSlider;
