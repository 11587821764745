import React from 'react';
import { Button, List } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useNavigateTo } from '../shared/hooks/router.hook';
import { getFlightBriefingPath } from '../../routes';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    topBanner: {
      backgroundColor: '#1976d2', // --color-highlight-blue
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
    },

    content: {
      textAlign: 'center',
      color: '#fff',
      '&:hover, &:focus': {
        backgroundColor: '#1669bb',
      },
    },
    contentAlerts: {
      textAlign: 'center',
      color: '#fff',
      backgroundColor: 'var(--color-highlight-red)',
      '&:hover, &:focus': {
        backgroundColor: 'var(--color-highlight-crimson-red)',
      },
    },
    contentLand: {
      textAlign: 'center',
      color: '#fff',
      backgroundColor: 'var(--color-highlight-red)',
      '&:hover, &:focus': {
        backgroundColor: 'var(--color-highlight-crimson-red)',
      },
    },
  })
);

export default function TopBanner({
  flightIds,
  requestToLand,
  unacknowledgedAlerts,
  secondaryBannerIsVisible,
}: Readonly<{
  flightIds: number[];
  requestToLand: { [flightId: number]: boolean };
  unacknowledgedAlerts: { [flightId: number]: boolean };
  secondaryBannerIsVisible: boolean;
}>) {
  const classes = useStyles();
  const navigateTo = useNavigateTo();
  const height = secondaryBannerIsVisible ? '2rem' : '3rem';

  const banner = (flightId: number) => (
    <Button
      id={`map-top-banner-active-flight-${flightId}`}
      data-testid={`map-top-banner-active-flight-${flightId}`}
      key={flightId}
      onClick={() => navigateTo(getFlightBriefingPath(flightId.toString()))}
      className={
        requestToLand[flightId]
          ? classes.contentLand
          : unacknowledgedAlerts[flightId]
            ? classes.contentAlerts
            : classes.content
      }
      style={
        flightIds.length > 1
          ? { height: height }
          : {
              width: '100%',
              height: height,
            }
      }
    >
      {requestToLand[flightId]
        ? `Authorisation for Flight ${flightId} has been cancelled`
        : unacknowledgedAlerts[flightId]
          ? `Flight ${flightId} has alerts to acknowledge`
          : `Flight ${flightId} is currently active`}
    </Button>
  );

  return (
    <List
      id="map-top-banner"
      className={classes.topBanner}
      data-testid="map-top-banner"
      style={{ height: height }}
    >
      {flightIds.map(banner)}
    </List>
  );
}
