import {
  createFormHooks,
  createSelectors,
} from 'airshare-web-utils/redux-forms/index.ts';
import { actionCreators, LOGIN_FORM_KEY } from './actions';
import { REDUCER_KEY as ROOT_REDUCER_KEY } from '../../reducers.ts';
import { REDUCER_KEY as PARENT_REDUCER_KEY } from '../reducers';
import { schema } from './reducers';

const REDUCER_PATH = `${ROOT_REDUCER_KEY}.${PARENT_REDUCER_KEY}`;

const selectors = createSelectors(REDUCER_PATH, LOGIN_FORM_KEY);

const {
  useOnChange,
  useOnBlur,
  useOnFocus,
  useInputHandlerProps,
  useIsValid,
  useErrors,
  useTouched,
  useValidation,
  useFormState,
  useSetFieldValue,
  useIsErrored,
  useFieldError,
  useHandleSubmit,
  useClearForm,
  useResetErrors,
} = createFormHooks(actionCreators, selectors, schema);

export {
  useOnChange,
  useOnBlur,
  useOnFocus,
  useInputHandlerProps,
  useIsValid,
  useErrors,
  useTouched,
  useValidation,
  useFormState,
  useSetFieldValue,
  useIsErrored,
  useFieldError,
  useHandleSubmit,
  useClearForm,
  useResetErrors,
};
