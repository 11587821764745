import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ButtonBase from '@material-ui/core/ButtonBase';
import TablePagination from '@material-ui/core/TablePagination';

import { AirshareUserRole } from 'argus-common/enums';

import { useProfile } from '../../../../state/profile/hooks';
import * as pilotApiClient from '../../../../pilot-api-client';
import EditOrganisationModal, { EditOrgWithId } from './edit-organisation';
import { BasicUserInfo, Organisation } from '@airshare/pilot-types';

const useOrganisationStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: 'calc(100% - 16px)',
      marginLeft: '16px',
      padding: '2rem 1rem 1rem 1rem',
      overflowY: 'scroll',
      height: '100vh',
    },
    title: {
      width: '100%',
      paddingLeft: '1rem',
    },
    topControlsDiv: {
      width: '100%',
      padding: '0rem 1rem 1rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    addButton: {
      width: '180px',
      height: '44px',
      color: 'var(--color-true-white)',
      background: 'var(--gradient-primary)',
      borderRadius: '22px',
      transition: 'background ease 0.1s',
    },
    table__row: {
      cursor: 'pointer',
    },
    table__cell: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '450px',
    },
  })
);

export function ManageOrganisations() {
  const classes = useOrganisationStyles({});
  const [organizations, setOrganizations] = useState<Organisation[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const profile = useProfile();
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [showFullPilotList, setShowFullPilotList] = useState(false);
  const [fullPilotList, setFullPilotList] = useState<string[]>([]);
  const [focusedOrganisation, setFocusedOrganisation] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [validatedSearchText, setValidatedSearchText] = useState('');

  const NO_OF_EMAILS_TO_SHOW = 3;
  const MINIMUM_CHARACTERS_FOR_SEARCH = 3;

  const fetchAllOrganizations = useCallback(async () => {
    if (validatedSearchText?.length) {
      return;
    }
    const resp: {
      data: Organisation[];
      totalRecords: number;
    } = await pilotApiClient.getOrganisations(pageSize, pageIndex);
    const orgs = resp.data.toSorted((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setOrganizations(orgs);
    setTotalRecords(resp.totalRecords);
  }, [
    setOrganizations,
    setTotalRecords,
    pageIndex,
    pageSize,
    validatedSearchText,
  ]);

  useMemo(() => {
    if (profile?.roles.includes(AirshareUserRole.SUPER_ADMIN)) {
      setIsAdmin(true);
    }
  }, [profile]);

  useEffect(() => {
    fetchAllOrganizations();
  }, [fetchAllOrganizations, pageIndex, pageSize]);

  useEffect(() => {
    if (searchText?.length < MINIMUM_CHARACTERS_FOR_SEARCH) {
      setValidatedSearchText(null);
    } else {
      setValidatedSearchText(searchText);
    }
  }, [searchText]);

  const resetAll = () => {
    setEditingId('');
    setPageIndex(0);
    setSearchText('');
    fetchAllOrganizations();
  };

  const handleChangePage = async (_event: any, newPage: number) => {
    setPageIndex(newPage);
    if (searchText?.length >= MINIMUM_CHARACTERS_FOR_SEARCH) {
      handleSearchChange(searchText, newPage);
    }
  };
  const handleChangeRowsPerPage = async (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const displayOrgAdminEmails = (org: Organisation) => {
    const emails = org.users?.filter(
      (u) => !!u.orgAdmin && u.orgAdmin.organisation === org._id
    );
    return emails.length > 0 ? emails[0]?.email : '';
  };

  const allowPilotsShareContactInfo = (org: Organisation) => {
    return org.allowPilotsShareContactInfo ? 'Yes' : 'No';
  };

  const getPilotEmailsFromOrg = (org?: Organisation): string[] => {
    if (!org?.users?.length) return [];
    return org?.users
      ?.filter((u) => u?.orgAdmin?.organisation !== org._id)
      .map((u) => u.email);
  };

  const displayPilotEmails = (org: Organisation): string => {
    const emails = getPilotEmailsFromOrg(org);
    if (emails.length > NO_OF_EMAILS_TO_SHOW) {
      const noOfPilots = emails.length;
      return emails
        .slice(0, NO_OF_EMAILS_TO_SHOW)
        .concat(`plus ${noOfPilots - NO_OF_EMAILS_TO_SHOW} more...`)
        .join(' ');
    }
    return emails.length > 0 ? emails.join('  ') : '';
  };

  const editOrganisation = (org: { _id: any }) => {
    setEditingId(org._id);
  };

  const getSelectedOrg = (): EditOrgWithId => {
    const org = organizations.find((x) => x._id === editingId);
    if (org) {
      const { name, _id } = org;
      return {
        _id,
        name,
        orgAdmin: org?.users
          .filter(
            (u: BasicUserInfo) =>
              u?.orgAdmin && u?.orgAdmin.organisation === org._id
          )
          .map((u: BasicUserInfo) => u.email),
        pilots: org?.users
          .filter((u: BasicUserInfo) =>
            u?.roles?.includes(AirshareUserRole.PILOT)
          )
          .map((u: BasicUserInfo) => u.email),
        allowPilotsShareContactInfo: org.allowPilotsShareContactInfo,
        ...(org.priorityOperation
          ? { priorityOperation: org.priorityOperation }
          : {}),
      };
    }
    return {
      _id: 'new',
      name: '',
      orgAdmin: [],
      pilots: [],
      allowPilotsShareContactInfo: true,
    };
  };

  const expandPilotCell = (evt: any, id: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (!showFullPilotList) {
      const org = organizations.find((o) => o._id === id);
      setFocusedOrganisation(id);
      const pilotEmails = getPilotEmailsFromOrg(org);
      setFullPilotList(pilotEmails);
      setShowFullPilotList(true);
    } else {
      setFocusedOrganisation(null);
      setFullPilotList([]);
      setShowFullPilotList(false);
    }
  };

  function handleSearchKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      return (document.activeElement as HTMLElement)?.blur();
    }
    return null;
  }

  const handleSearchChange = async (
    searchValue: string,
    newPageIndex: number
  ) => {
    setSearchText(searchValue);

    if (searchValue?.length < MINIMUM_CHARACTERS_FOR_SEARCH) {
      return;
    }

    const resp = await pilotApiClient.getOrganisations(
      pageSize,
      newPageIndex,
      searchValue
    );
    const orgs = resp.data.toSorted((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setPageIndex(newPageIndex);
    setOrganizations(orgs);
    setTotalRecords(resp.totalRecords);
  };

  return (
    <div className={classes.wrapper}>
      <div className="organisations">
        <Typography
          className={classes.title}
          variant="h3"
          data-testid="pilot-web:settings-panel:manage-org:heading"
        >
          Manage Organisations
        </Typography>
        <div className={classes.topControlsDiv}>
          <TextField
            id="search"
            name="search"
            className="search-box"
            placeholder="Search..."
            variant="outlined"
            size="small"
            onChange={(event) => handleSearchChange(event.target.value, 0)}
            onKeyDown={handleSearchKeyDown}
            value={searchText}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
            data-testid="pilot-web:settings-panel:manage-org:search"
          />

          {isAdmin ? (
            <ButtonBase
              className={classes.addButton}
              type="button"
              onClick={() => setEditingId('new')}
              data-testid="pilot-web:settings-panel:manage-org:add-organisation-button"
            >
              Add Organisation
            </ButtonBase>
          ) : null}
        </div>
        <Paper className="organisations_paper">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Org Admin</TableCell>
                <TableCell>
                  Allow pilots to share contact information?
                </TableCell>
                <TableCell>Priority Operator</TableCell>
                <TableCell>Pilots</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations?.map((org) => (
                <TableRow
                  className={classes.table__row}
                  key={org._id}
                  onClick={() => {
                    editOrganisation(org);
                  }}
                  data-testid="pilot-web:settings-panel:manage-org:list-item"
                >
                  <TableCell
                    className={classes.table__cell}
                    data-testid="pilot-web:settings-panel:manage-org:org-name"
                  >
                    {org.name}
                  </TableCell>
                  <TableCell
                    className={classes.table__cell}
                    data-testid="pilot-web:settings-panel:manage-org:org-admin"
                  >
                    {displayOrgAdminEmails(org)}
                  </TableCell>
                  <TableCell
                    className={classes.table__cell}
                    data-testid="pilot-web:settings-panel:manage-org:allowPilotsShareContactInfo"
                  >
                    {allowPilotsShareContactInfo(org)}
                  </TableCell>
                  <TableCell
                    className={classes.table__cell}
                    data-testid="pilot-web:settings-panel:manage-org:priorityOperation"
                  >
                    {org.priorityOperation?.priorityLevel || 'No'}
                  </TableCell>
                  <TableCell
                    className={classes.table__cell}
                    style={{
                      cursor:
                        getPilotEmailsFromOrg(org)?.length >
                        NO_OF_EMAILS_TO_SHOW
                          ? 'pointer'
                          : 'auto',
                    }}
                    onClick={(event) => expandPilotCell(event, org._id)}
                    data-testid="pilot-web:settings-panel:manage-org:pilots"
                  >
                    {showFullPilotList && focusedOrganisation === org._id
                      ? fullPilotList.join(' ')
                      : displayPilotEmails(org)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalRecords}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {editingId && isAdmin && (
        <EditOrganisationModal
          closeModal={() => {
            resetAll();
          }}
          organization={getSelectedOrg()}
        />
      )}
    </div>
  );
}
