import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useAppConfig } from '../../../../state/app-config';

import './information.scss';

export function Information() {
  const appConfig = useAppConfig();

  return (
    <div
      className="information-panel"
      data-testid="pilot-web:information-panel"
    >
      <Typography
        className="heading"
        variant="h6"
        component="h1"
        align="center"
        data-testid="pilot-web:information-panel:header"
      >
        Information
      </Typography>

      <div className="links-container">
        {appConfig?.settingsPage?.map(({ link, label }, index) => (
          <a
            href={link}
            key={index}
            className="link"
            rel="noopener noreferrer"
            target="_blank"
            data-testid="pilot-web:settings-panel:external-url-link"
          >
            {label}
          </a>
        ))}
      </div>
    </div>
  );
}
