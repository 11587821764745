import { createAction } from 'airshare-web-utils/redux-helpers';

export enum MapStylesActionType {
  FETCH_REQUESTED = 'map-styles/FETCH_REQUESTED',
  FETCH_SUCCEEDED = 'map-styles/FETCH_SUCCEEDED',
  FETCH_FAILED = 'map-styles/FETCH_FAILED',
}

export enum MapStyleCode {
  Standard = 'standard',
  Gray = 'gray',
  Dark = 'dark',
  Terrain = 'terrain',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
}

export enum MapTypeId {
  Roadmap = 'roadmap',
  Terrain = 'terrain',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
}

export interface MapStyleData {
  code: MapStyleCode;
  mimeType: 'application/json';
  data: string;
}

export interface MapStyleFetchSucceededPayload {
  standard: MapStyleData;
  gray: MapStyleData;
  dark: MapStyleData;
}

export interface MapStylesFetchRequestedAction {
  type: MapStylesActionType.FETCH_REQUESTED;
}

export interface MapStylesFetchSucceededAction {
  type: MapStylesActionType.FETCH_SUCCEEDED;
  payload: MapStyleFetchSucceededPayload;
}

export interface MapStylesFetchFailedAction {
  type: MapStylesActionType.FETCH_FAILED;
  payload: string;
}

export const mapStylesFetchRequested: () => MapStylesFetchRequestedAction =
  createAction(MapStylesActionType.FETCH_REQUESTED);
export const mapStylesFetchSucceeded: (
  payload: MapStyleFetchSucceededPayload
) => MapStylesFetchSucceededAction = createAction(
  MapStylesActionType.FETCH_SUCCEEDED
);
export const mapStylesFetchFailed: (
  message: string
) => MapStylesFetchFailedAction = createAction(
  MapStylesActionType.FETCH_FAILED
);
