import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { StatusCodes } from 'http-status-codes';

import { pilotAPI } from '../../pilot-api-client/api';
import {
  FETCH_REQUESTED,
  orgPilotsFetchFailed,
  orgPilotsFetchSucceeded,
  CREATION_REQUESTED,
  orgPilotsCreationFailed,
  orgPilotsCreationSucceeded,
  UPDATE_REQUESTED,
  orgPilotsUpdateFailed,
  orgPilotsUpdateSucceeded,
  DELETION_REQUESTED,
  orgPilotsDeletionFailed,
  orgPilotsDeletionSucceeded,
} from './actions';

const orgPilotsEndpoint = `/v2/org-pilots`;

function* fetchOrgPilots() {
  try {
    const { data } = yield call(pilotAPI.get, orgPilotsEndpoint);

    yield put(orgPilotsFetchSucceeded(data));
  } catch (error) {
    const status = get(error, 'response.status', null);

    if (status === StatusCodes.NOT_FOUND) {
      yield put(orgPilotsFetchSucceeded([]));
    } else {
      yield put(orgPilotsFetchFailed(error.message));
    }
  }
}

function* createOrgPilot({ payload }) {
  try {
    const { data } = yield call(pilotAPI.post, orgPilotsEndpoint, {
      ...payload,
    });

    yield put(orgPilotsCreationSucceeded(data));
  } catch (error) {
    const status = get(error, 'response.status', null);

    if (status === StatusCodes.NOT_FOUND) {
      yield put(orgPilotsCreationSucceeded([]));
    } else {
      yield put(orgPilotsCreationFailed(error.message));
    }
  }
}

function* updateOrgPilot({ payload }) {
  try {
    const { data } = yield call(
      pilotAPI.put,
      `${orgPilotsEndpoint}/${payload.id}`,
      {
        ...payload,
      }
    );

    yield put(orgPilotsUpdateSucceeded(data));
  } catch (error) {
    const status = get(error, 'response.status', null);

    if (status === StatusCodes.NOT_FOUND) {
      yield put(orgPilotsUpdateSucceeded([]));
    } else {
      yield put(orgPilotsUpdateFailed(error.message));
    }
  }
}

function* deleteOrgPilot({ payload }) {
  try {
    const { data } = yield call(
      pilotAPI.delete,
      `${orgPilotsEndpoint}/${payload.id}`
    );

    yield put(orgPilotsDeletionSucceeded(data));
  } catch (error) {
    const status = get(error, 'response.status', null);

    if (status === StatusCodes.NOT_FOUND) {
      yield put(orgPilotsDeletionSucceeded([]));
    } else {
      yield put(orgPilotsDeletionFailed(error.message));
    }
  }
}

export function* orgPilotsSaga() {
  yield takeLatest(FETCH_REQUESTED, fetchOrgPilots);
  yield takeLatest(CREATION_REQUESTED, createOrgPilot);
  yield takeLatest(UPDATE_REQUESTED, updateOrgPilot);
  yield takeLatest(DELETION_REQUESTED, deleteOrgPilot);
}
