import React, { useState, useEffect, useCallback } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  ButtonBase,
  TextField,
  TablePagination,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';

import { AirshareUser, AirshareUserRole } from '@airshare/pilot-types';

import * as pilotApiClient from '../../../pilot-api-client';
import { getEditPilotUserPath } from '../../../routes';
import { useProfile } from '../../../state/profile/hooks';
import { useNavigateTo } from '../../shared/hooks/router.hook';

import './manage-pilot-users.scss';

const useEditPilotStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: 'calc(100% - 16px)',
      marginLeft: '16px',
      padding: '2rem 1rem 1rem 1rem',
      overflowY: 'scroll',
      height: '100vh',
    },
    title: {
      width: '100%',
      paddingLeft: '1rem',
    },
    topControlsDiv: {
      width: '100%',
      padding: '0rem 1rem 1rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    table: {
      width: '100%',
    },
  })
);

enum Order {
  ascending = 'asc',
  descending = 'desc',
}

enum Column {
  Name = 'fullName',
  Email = 'email',
  Mobile = 'mobileNo',
  Company = 'pilot.businessName',
  Op_Type = 'pilot.operatorType',
  Cert_Type = 'pilot.certificationType',
  Status = 'status',
}

export function ManagePilotUsers() {
  const profile = useProfile();
  const navigateTo = useNavigateTo();

  const [pilotUsers, setPilotUsers] = useState<AirshareUser[]>([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortBy, setSortBy] = useState<Column>(Column.Name);
  const [order, setOrder] = useState<Order>(Order.ascending);

  const classes = useEditPilotStyles({});

  const updatePilotUser = (pilotUserId: string) => {
    setIsLoading(true);
    navigateTo(getEditPilotUserPath(pilotUserId));
  };

  const refreshPilots = useCallback(async () => {
    setIsLoading(true);
    const resp = await pilotApiClient.getPilotUsers(pageSize, pageIndex);
    if (resp.data?.length) {
      setPilotUsers(resp.data);
    }
    setTotalRecords(resp.totalRecords);
    setPageIndex(resp.pageIndex);
    setIsLoading(false);
  }, [setPilotUsers, setTotalRecords, pageIndex, pageSize]);

  useEffect(() => {
    if (profile?.roles.includes(AirshareUserRole.SUPER_ADMIN)) {
      setIsAdmin(true);
    }
  }, [profile]);

  useEffect(() => {
    refreshPilots();
  }, [refreshPilots, pageIndex, pageSize]);

  const handleChangePage = (_: any, newPage: number) => {
    setPageIndex(newPage);
  };

  const handleChangePageSize = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const onHeadingClicked = (column: Column) => {
    if (sortBy === column) {
      setOrder(order === Order.ascending ? Order.descending : Order.ascending);
    } else {
      setSortBy(column);
      setOrder(Order.ascending);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    setPageIndex(0);
    setTimeout(async () => {
      const resp = await pilotApiClient.getPilotUsers(
        pageSize,
        pageIndex,
        searchValue
      );
      setPilotUsers(resp.data);
      setTotalRecords(resp.totalRecords);
      setIsLoading(false);
    }, 500);
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      return (document.activeElement as HTMLElement)?.blur();
    }
    return null;
  };

  const debouncedHandleSearchChange = debounce(
    (searchValue: string) => handleSearchChange(searchValue),
    500
  );

  if (!isAdmin) {
    return null;
  }

  return (
    <div className={classes.wrapper + ' scrollable-page'}>
      <Typography
        className={classes.title}
        variant="h3"
        data-testid="pilot-web:manage-pilot-users:page-title"
      >
        Manage Pilot Users
      </Typography>
      <div className={classes.topControlsDiv}>
        <TextField
          data-testid="pilot-web:manage-pilot-users:search"
          name="search"
          className="search-box"
          placeholder="Search..."
          variant="outlined"
          size="small"
          onChange={(event) => debouncedHandleSearchChange(event.target.value)}
          onKeyDown={handleSearchKeyDown}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
        <ButtonBase
          className="refresh-pilots-button"
          type="button"
          onClick={() => refreshPilots()}
          disabled={isLoading}
          data-testid="pilot-web:manage-pilot-users:refresh-button"
        >
          Refresh
        </ButtonBase>
      </div>
      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {Object.keys(Column).map((column: keyof typeof Column) => (
                <TableCell
                  key={column}
                  onClick={() => onHeadingClicked(Column[column])}
                  data-testid={`pilot-web:manage-pilot-users:table-header:${column}`}
                >
                  {column.replaceAll('_', ' ')}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pilotUsers?.map((user, ndx) => (
              <TableRow
                key={user.id}
                onClick={() => updatePilotUser(user.id)}
                data-testid={`pilot-web:manage-pilot-users:table-row:${ndx}`}
              >
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.mobileNo}</TableCell>
                <TableCell>{user.pilot?.businessName}</TableCell>
                <TableCell>
                  {user.pilot?.operatorType?.substring(0, 3) + '.'}
                </TableCell>
                <TableCell>{user.pilot?.certificationType}</TableCell>
                <TableCell>{user.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalRecords}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}
