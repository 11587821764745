import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';

import { getProfile } from '~/state/session/selectors';

import App from './app';

const mapStateToProps = (state: any) => ({
  account: getProfile(state),
});

export default hot(module)(connect(mapStateToProps)(App));
