import { combineReducers } from 'redux';

import { activationFormReducer } from './activation/reducers';
import { loginFormReducer } from './login/reducers';
import { registrationFormReducer } from './registration/reducers';

export const REDUCER_KEY = 'authForms';

export const authFormsReducer = combineReducers({
  ...activationFormReducer,
  ...loginFormReducer,
  ...registrationFormReducer,
});
