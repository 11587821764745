import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import {
  useUavs,
  useUavsPageByIndex,
  useUavsTotalRecords,
  useFetchUavs,
} from '../../../../state/uavs/hooks';
import { useIsLoading } from './hooks/is-loading.hook';
import { useIsFailed } from './hooks/is-failed.hook';
import { ImageFallback } from '../../../shared/image-fallback/image-fallback';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { getUavDetailPath, ADD_UAV_PATH } from '../../../../routes';
import { useCssVariable } from '../../../shared/hooks/css-variable.hook';
import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';

import fallbackImage from './images/drone.png';

import './uavs.scss';

const CARD_HEIGHT = 96;
const HEADER_VERTICAL_PADDING = 32;
const HEADER_HEIGHT = 64;

interface Uav {
  id: string;
  name: string;
  image: string;
  model: string;
}

export function Uavs() {
  const navigateTo = useNavigateTo();

  useUavs(0);

  const totalRecords: number = useUavsTotalRecords();

  function handleAddNewUav() {
    navigateTo(ADD_UAV_PATH);
  }

  useCssVariable('--uavs-card-height', `${CARD_HEIGHT}px`);
  useCssVariable('--uavs-header-padding-top', `${HEADER_VERTICAL_PADDING}px`);
  useCssVariable('--uavs-header-height', `${HEADER_HEIGHT}px`);

  const fetchUavs = useFetchUavs();

  function handleRetry() {
    fetchUavs({ pageIndex: 0 });
  }

  const uavsPage: Uav[] = useUavsPageByIndex(0);
  const isLoading: boolean = useIsLoading(uavsPage);
  const isFailed: boolean = useIsFailed();

  return (
    <LoadingPanel isLoading={false}>
      <div className="uavs-panel" data-testid="pilot-web:uavs:panel">
        <Typography
          className="heading"
          variant="h6"
          component="h1"
          align="center"
        >
          UAVs
        </Typography>
        {!isLoading && totalRecords === 0 && (
          <div className="no-flights-placeholder">
            <Typography variant="subtitle1" component="h6" align="center">
              You have no UAVs
            </Typography>
            <Typography variant="subtitle1" component="h6" align="center">
              Create by clicking below
            </Typography>
          </div>
        )}

        {isFailed && (
          <>
            <Typography
              className="failure-message"
              variant="h6"
              component="h2"
              align="center"
            >
              We failed to retrieve your UAVs
            </Typography>
            <Typography
              className="failure-action"
              variant="body1"
              component="p"
              align="center"
            >
              Please click{' '}
              <button
                className="retry-link"
                type="button"
                onClick={handleRetry}
              >
                here
              </button>{' '}
              to retry
            </Typography>
          </>
        )}

        <div className="create-uav-button-container">
          <ButtonBase
            data-testid="pilot-web:uavs:create-button"
            className="create-uav-button button primary"
            type="button"
            onClick={handleAddNewUav}
          >
            Create UAV
          </ButtonBase>
        </div>

        {Boolean(uavsPage?.length) &&
          uavsPage.map(({ id, name, image, model }) => (
            <div key={id} className="card-container">
              <Link to={getUavDetailPath(id)} className="card">
                <ImageFallback
                  className="drone-image"
                  alt="Drone"
                  src={`${window.env.ARGUS_CONTENT_API_URL}${image}`}
                  fallbackSrc={fallbackImage}
                />
                <Typography
                  className="drone-name"
                  data-testid="pilot-web:uavs:drone-list-item-name"
                >
                  {name || model}
                </Typography>
              </Link>
            </div>
          ))}
      </div>
    </LoadingPanel>
  );
}
