import { createContext } from 'react';
import { MapStyleCode } from 'airshare-pilot-web-shared/src/state/map-styles/actions';
import type { LatLng, MapBounds } from 'argus-common/interfaces';
import type { Polygon } from '@turf/helpers';
import { AdvisorySource, type CommonAdvisory } from '@airshare/pilot-types';

import type { MapAreaBody } from '@airshare/external-api-types';

import type { Socket } from 'socket.io-client';
import { defaultBufferMetres } from '../lib/polygon-helpers';

export interface GlobalStateType {
  mapBounds: MapBounds | null;
  setMapBounds: React.Dispatch<React.SetStateAction<MapBounds | null>>;
  mapCenter: LatLng | null;
  setMapCenter: React.Dispatch<React.SetStateAction<LatLng | null>>;
  selectedAdvisory: MapAreaBody | null;
  setSelectedAdvisory: (adv: MapAreaBody) => void;
  hoveredOverAdvisory: CommonAdvisory | null;
  setHoveredOverAdvisory: (adv: CommonAdvisory | null) => void;
  mapStyleCode: MapStyleCode;
  setMapStyleCode: (mapStyleCode: MapStyleCode) => void;
  advisoryGeometry: Polygon | null;
  setAdvisoryGeometry: (src: AdvisorySource, geometry: Polygon | null) => void;
  advisoryGeometrySource: AdvisorySource;
  highlightedSegments: string[] | null;
  setHighlightedSegments: React.Dispatch<React.SetStateAction<string[] | null>>;
  showMyFlightMarkers: boolean;
  setShowMyFlightMarkers: React.Dispatch<React.SetStateAction<boolean>>;
  resetGlobalState: () => void;
  mapLayers: Map<string, any>;
  addMapLayer: (_: string, __: any) => void;
  setMapLayerVisibility: (_: string, visibility: boolean) => void;
  socket: Socket | null;
  setSocket: (socket: Socket | null) => void;
  surveillanceCenterPoint: LatLng | null;
  setSurveillanceCenterPoint: (latLng: LatLng) => void;
  segmentedBufferRadius: number;
  setSegmentedBufferRadius: (radius: number) => void;
}

export const defaultGlobalState: GlobalStateType = {
  mapBounds: null,
  setMapBounds: () => {},
  mapCenter: null,
  setMapCenter: () => {},
  selectedAdvisory: null,
  setSelectedAdvisory: () => {},
  hoveredOverAdvisory: null,
  setHoveredOverAdvisory: () => {},
  mapStyleCode: null,
  setMapStyleCode: (_: MapStyleCode) => {},
  advisoryGeometry: null,
  setAdvisoryGeometry: () => {},
  advisoryGeometrySource: null,
  highlightedSegments: null,
  setHighlightedSegments: () => {},
  showMyFlightMarkers: false,
  setShowMyFlightMarkers: () => {},
  resetGlobalState: () => {},
  mapLayers: new Map<string, any>(),
  addMapLayer: (_: string, __: any) => {},
  setMapLayerVisibility: (_: string, _visibility: boolean) => {},
  socket: null,
  setSocket: () => {},
  surveillanceCenterPoint: null,
  setSurveillanceCenterPoint: () => {},
  segmentedBufferRadius: defaultBufferMetres(),
  setSegmentedBufferRadius: () => {},
};

export const GlobalState = createContext<GlobalStateType>(defaultGlobalState);
