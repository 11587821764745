import { createSelector } from 'reselect';
import { ProfileState } from './saga';
import { RootState } from '../store';

const getLocalState = (state: RootState) => state.profile;

export const getProfile = createSelector(
  [getLocalState],
  (state: ProfileState) => state.user
);

export const getUpdateStatus = createSelector(
  [getLocalState],
  (state: ProfileState) => state.updateStatus
);
