import React from 'react';
import Typography from '@material-ui/core/Typography';
import { feetToMeters } from 'airshare-shared-utils/measurements';
import { getAdvisoryFormattedDate } from '~/lib/advisory-helpers';

export default function AdvisoryAviationRuleDetails({
  activity,
  lowerLimit,
  upperLimit,
  workinghours,
  deviationThresholdHorizontalMetres,
  deviationThresholdVerticalFeet,
  startDateTime,
  endDateTime,
  timezone,
}: Readonly<{
  activity?: string;
  lowerLimit?: string;
  upperLimit?: string;
  workinghours?: string;
  deviationThresholdHorizontalMetres?: number;
  deviationThresholdVerticalFeet?: number;
  startDateTime?: string;
  endDateTime?: string;
  timezone?: string;
}>) {
  const formattedStartDateTime = getAdvisoryFormattedDate(
    startDateTime,
    timezone
  );
  const formattedEndDateTime = getAdvisoryFormattedDate(endDateTime, timezone);
  return (
    <>
      {activity && (
        <Typography className="activity" variant="body1">
          Activity: {activity}
        </Typography>
      )}
      {lowerLimit && (
        <Typography className="lowerlimit" variant="body1">
          Lower limit: {lowerLimit}
        </Typography>
      )}
      {upperLimit && (
        <Typography className="upperlimit" variant="body1">
          Upper limit: {upperLimit}
        </Typography>
      )}
      {formattedStartDateTime && (
        <Typography className="startdatetime" variant="body1">
          Start: {formattedStartDateTime}
        </Typography>
      )}
      {formattedEndDateTime && (
        <Typography className="enddatetime" variant="body1">
          End: {formattedEndDateTime}
        </Typography>
      )}
      {workinghours && (
        <Typography className="workinghours" variant="body1">
          Working hours: {workinghours}
        </Typography>
      )}
      {deviationThresholdHorizontalMetres && (
        <Typography
          className="deviation-threshold-horizontal-metres"
          variant="body1"
        >
          Horizontal Deviation Threshold: {deviationThresholdHorizontalMetres}m
        </Typography>
      )}
      {deviationThresholdVerticalFeet && (
        <Typography
          className="deviation-threshold-vertical-feet"
          variant="body1"
        >
          Vertical Deviation Threshold:{' '}
          {feetToMeters(deviationThresholdVerticalFeet)}m |{' '}
          {deviationThresholdVerticalFeet}ft.
        </Typography>
      )}
    </>
  );
}
