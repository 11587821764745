import { createSelector } from 'reselect';
import { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { State } from './saga';

const getLocalState = (state: any) => state.flightRequests;

export const getFavourites = createSelector(
  [getLocalState],
  (state: State) => state.pages.favourite
);

export const getCurrentFlightRequests = createSelector(
  [getLocalState],
  (state: State) => state.pages.current
);

export const getPastFlightRequests = createSelector(
  [getLocalState],
  (state: State) => state.pages.past
);

export const getActivatedFlightRequests = createSelector(
  [getLocalState],
  (state: State) => state.pages.activated
);

export const getFlightRequestsPages = createSelector(
  [getLocalState],
  (state: State) => state.pages
);

export const getFlightRequestsMeta = createSelector(
  [getLocalState],
  (state: State) => state.meta
);

export const getFlightRequestsFetchStatus = createSelector(
  [getLocalState],
  (state: State) => state.fetchPageStatus
);

export const getFlightRequestsFocussed = createSelector(
  [getLocalState],
  (state: State) => state.focussed
);

export const getFlightRequestsFetchFocussedStatus = createSelector(
  [getLocalState],
  (state: State) => state.fetchFocussedStatus
);

export const getFlightRequestsCancelStatus = createSelector(
  [getLocalState],
  (state: State) => state.cancelStatus
);

export const getFlightActivationStatus = createSelector(
  [getLocalState],
  (state) => state.flightActivation
);

export const getFlightRequestsEndStatus = createSelector(
  [getLocalState],
  (state: State) => state.endStatus
);

export const getErrorMessage = createSelector(
  [getLocalState],
  (state: State) => state.errorMessage
);

export const getFlightRequestsIsListening = createSelector(
  [getLocalState],
  (state: State) => state.isListening
);

export const getFlightRequestsIsListeningFocussed = createSelector(
  [getLocalState],
  (state: State) => state.isListeningFocussed
);

export const getFlightRequestsPageByIndex = (index: number) =>
  createSelector([getLocalState], (state) => state?.pages[index] ?? null);

export const isFocussedPastFlight = createSelector(
  [getPastFlightRequests, getFlightRequestsFocussed],
  (pastFlights, focussedFlight) =>
    Boolean(
      pastFlights?.find(
        (flight: ExtendedFlightResponseBodyV2) =>
          flight.id === focussedFlight?.id
      )
    )
);
