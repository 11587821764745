import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  generalFlightPurposes,
  priorityFlightPurposes,
  FlightPurpose as FlightPurposeEnum,
} from '@airshare/external-api-types';

const FlightPurpose = (props: {
  currentValue: string; //FlightPurpose only,
  inputHandlerProps: Object;
  hasInputError: boolean;
  onChange: (selectedValue: string) => void;
  isPriorityFlight: boolean;
  errorMessage?: string;
}): JSX.Element => {
  const {
    currentValue,
    inputHandlerProps,
    hasInputError,
    onChange,
    isPriorityFlight,
    errorMessage,
  } = props;

  return (
    <>
      <FormLabel className="form-section-heading" component="h2">
        Purpose of Flight
      </FormLabel>
      <FormControl className="form-control" error={hasInputError}>
        <Select
          type="text"
          name="flightPurpose"
          inputProps={{
            name: 'flightPurpose',
            className: 'flight-purpose',
          }}
          value={currentValue}
          {...inputHandlerProps}
          onChange={(_, component: any) => {
            onChange(component.props.value);
          }}
          data-testid="pilot-web:flight-request:flight-purpose"
        >
          {[
            ...(isPriorityFlight
              ? [...priorityFlightPurposes, FlightPurposeEnum.Other]
              : generalFlightPurposes),
          ]?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className="flight-purpose-item"
              data-testid="pilot-web:flight-request:flight-purpose-item"
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className="input-hint">{errorMessage}</FormHelperText>
      </FormControl>
    </>
  );
};

export default FlightPurpose;
