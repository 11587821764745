import { AnyAction, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import history from 'airshare-web-utils/history';
import { LOG_OUT } from 'airshare-web-utils/redux-helpers';
import LogRocket from 'logrocket';

import rootSaga from './root-saga';
import {
  flightPlanReducer,
  REDUCER_KEY as FLIGHT_PLAN_REDUCER_KEY,
} from './flight-plan/reducers';
import { mapStylesReducer } from './map-styles/reducers';
import { profileReducer } from './profile/reducers';
import {
  sessionReducer,
  REDUCER_KEY as SESSION_REDUCER_KEY,
} from './session/reducers';
import { flightRequestDefaultsReducer } from './flight-request-defaults/reducers';
import { uavDefaultsReducer } from './uav-defaults/reducers';
import { flightRequestsReducer } from './flight-requests/reducers';
import {
  orgPilotsReducer,
  REDUCER_KEY as ORG_PILOTS_REDUCER_KEY,
} from './org-pilots/reducers';
import { uavsReducer, REDUCER_KEY as UAVS_REDUCER_KEY } from './uavs/reducers';
import { reducer as appConfigReducer } from './app-config';
import {
  flightAdvisoriesReducer,
  highlightedFlightAdvisoriesReducer,
} from './flight-advisories/reducers';

import type { State as FlightRequestsState } from './flight-requests/saga';
import type { FlightPlanState } from './flight-plan/saga';
import type { State as FlightRequestDefaultsState } from './flight-request-defaults/saga';
import type { FlightAdvisoryResponse } from '@airshare/external-api-types';

import type { StyledFlightAdvisory } from '@airshare/pilot-types';

import type { ProfileState } from './profile/saga';

export interface RootState {
  flightPlan: FlightPlanState;
  flightRequests: FlightRequestsState;
  flightRequestDefaults: FlightRequestDefaultsState;
  uavDefaults: any;
  orgPilots: any;
  mapStyles: any;
  profile: ProfileState;
  session: any;
  uavs: any;
  appConfig: any;
  router: any;
  flightAdvisories: FlightAdvisoryResponse[];
  highlightedFlightAdvisories: StyledFlightAdvisory[];
}

const appReducer = combineReducers<RootState>({
  [FLIGHT_PLAN_REDUCER_KEY]: flightPlanReducer,
  flightRequests: flightRequestsReducer,
  flightRequestDefaults: flightRequestDefaultsReducer,
  uavDefaults: uavDefaultsReducer,
  [ORG_PILOTS_REDUCER_KEY]: orgPilotsReducer,
  mapStyles: mapStylesReducer,
  profile: profileReducer,
  [SESSION_REDUCER_KEY]: sessionReducer,
  [UAVS_REDUCER_KEY]: uavsReducer,
  appConfig: appConfigReducer,
  router: connectRouter(history),
  flightAdvisories: flightAdvisoriesReducer,
  highlightedFlightAdvisories: highlightedFlightAdvisoriesReducer,
});

export const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === LOG_OUT) {
    const { router, appConfig } = state;
    // @ts-ignore
    state = { router, appConfig };
  }
  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware({});

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    routerMiddleware(history),
    sagaMiddleware,
    LogRocket.reduxMiddleware(),
  ],
});

export default store;

// Sagas must be started after other
// dependencies have been initialized
// by explicitly calling this from the
// code after initialization.
export const startSagas = () => {
  sagaMiddleware.run(rootSaga);
};
