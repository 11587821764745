import {
  createFormHooks,
  createSelectors,
} from 'airshare-web-utils/redux-forms/index';
import { actionCreators } from './actions';
import { REDUCER_KEY as PARENT_REDUCER_KEY } from '../reducers';
import { UpdateProfileFormState, schema } from './reducers';

const selectors = createSelectors(PARENT_REDUCER_KEY, 'updateProfileForm');

const {
  useOnChange,
  useOnBlur,
  useOnFocus,
  useInputHandlerProps,
  useIsValid,
  useErrors,
  useTouched,
  useValidation,
  useFormState,
  useSetFieldValue,
  useIsErrored,
  useFieldError,
  useHandleSubmit,
  useClearForm,
  useResetErrors,
  useHydrateForm,
} = createFormHooks<UpdateProfileFormState>(actionCreators, selectors, schema);

export {
  useOnChange,
  useOnBlur,
  useOnFocus,
  useInputHandlerProps,
  useIsValid,
  useErrors,
  useTouched,
  useValidation,
  useFormState,
  useSetFieldValue,
  useIsErrored,
  useFieldError,
  useHandleSubmit,
  useClearForm,
  useResetErrors,
  useHydrateForm,
};
