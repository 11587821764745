/* eslint-disable import/no-named-default */
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import isEqual from 'lodash/isEqual';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const DEFAULT_STATE = [];
const getLocalState = (state) => state.orgPilots;

const getOrgPilotsData = (state) => {
  if (!state.orgPilots) return DEFAULT_STATE;

  return state.orgPilots;
};

export const getOrgPilots = createDeepEqualSelector(
  [getOrgPilotsData],
  (state) => state.data
);

export const getOrgPilot = (id) =>
  createSelector([getOrgPilots], (state) =>
    state ? state.find((pilot) => pilot.id === id) || null : null
  );

export const getOrgPilotsFetchStatus = createSelector(
  [getLocalState],
  (state) => state.fetchStatus
);

export const getOrgPilotsCreationStatus = createSelector(
  [getLocalState],
  (state) => state.creationStatus
);

export const getOrgPilotsUpdateStatus = createSelector(
  [getLocalState],
  (state) => state.updateStatus
);

export const getOrgPilotsDeletionStatus = createSelector(
  [getLocalState],
  (state) => state.deletionStatus
);
