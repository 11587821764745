import type { AmendMessageStatusResponse } from '@airshare/external-api-types';

import { pilotAPI } from './api';

export async function acknowledgeMessage(
  messageId: string,
  flightId: number
): Promise<void> {
  const ERR_MESSAGE = 'Failed to acknowledge message, Please try again later.';

  return pilotAPI
    .put<AmendMessageStatusResponse>(
      `/v1/messages?messageId=${messageId}&flightId=${flightId}`
    )
    .then((resp) => {
      if (
        resp.data?.success === 'OK' ||
        resp.data?.errors?.find((x: string) => x.includes('not found'))
      )
        return Promise.resolve();
      return Promise.reject(new Error(ERR_MESSAGE));
    })
    .catch(() => Promise.reject(new Error(ERR_MESSAGE)));
}
