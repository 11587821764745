import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function AdvisoryDetailPolicies({
  policies = [],
}: {
  policies: string[];
}) {
  const addLinks = (string: string) => {
    if (!string) return string;
    const stringElems = string.split('{link}');
    return stringElems.map((elem, i) => {
      if (i % 2 === 0) return elem;
      return (
        <Link
          target="_blank"
          key={`policy-${i}`}
          href={elem.split('{path}')[1] || elem}
        >
          {elem.split('{path}')[0]}
        </Link>
      );
    });
  };

  return (
    <>
      {policies?.length ? (
        <div className="policies">
          <Typography className="policies-header" variant="subtitle1">
            Policies:
          </Typography>
          <br />
          <ul className="policy-list">
            {policies.map((policy, index) => (
              <React.Fragment key={index}>
                <li>
                  <Typography variant="body2">{addLinks(policy)}</Typography>
                </li>
                <br />
              </React.Fragment>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
