import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, type RouteComponentProps } from 'react-router-dom';

import { NOTAMS_ROUTE } from '~/routes';

import { useIsInTabletMode } from 'airshare-pilot-web-shared';
import { useNavigateTo } from '~/components/shared/hooks/router.hook';
import { CloseButton } from '../../../shared/close-button/close-button';
import { ReadOnlyField } from '~/components/shared/form-inputs/read-only-field';
import { BackButton } from '~/components/shared/back-button/back-button';
import { LoadingPanel } from '~/components/shared/loading-panel/loading-panel';

import { getNotams, isNotamError } from '../../../../pilot-api-client';

import './notam-detail.scss';

export function NotamDetailComponent({
  match,
}: RouteComponentProps<{ id?: string }>) {
  const [notam, setNotam] = useState(null);

  const navigateTo = useNavigateTo();
  const isInTabletMode = useIsInTabletMode();

  const fetchNotam = useCallback(async () => {
    try {
      const notamsResponse = await getNotams();
      if (isNotamError(notamsResponse)) {
        setNotam(null);
      } else {
        setNotam(notamsResponse?.find((x) => x.id === match.params.id));
      }
    } catch (err) {
      setNotam(null);
    }
  }, [setNotam, match.params.id]);

  useEffect(() => {
    fetchNotam();
  }, [fetchNotam]);

  function handleClose() {
    navigateTo(NOTAMS_ROUTE);
  }

  return (
    <LoadingPanel isLoading={!notam}>
      <>
        {isInTabletMode ? (
          <BackButton onClick={handleClose} />
        ) : (
          <CloseButton onClick={handleClose} />
        )}
        <div data-testid="notam-detail-div" className="notam-detail">
          <i className="icon" />

          {notam && (
            <div className="form">
              <ReadOnlyField label="NOTAM ID" value={notam.id} />
              <div className="row">
                <div className="column">
                  <ReadOnlyField label="Effective" value={notam.startDate} />
                  <ReadOnlyField label="Min Height" value={notam.minHeight} />
                </div>
                <div className="column">
                  <ReadOnlyField label="Until" value={notam.endDate} />
                  <ReadOnlyField
                    label="Max Height"
                    value={`${notam.maxHeight}`}
                  />
                </div>
              </div>
              <ReadOnlyField label="Description" value={notam.description} />
            </div>
          )}
        </div>
      </>
    </LoadingPanel>
  );
}

export const NotamDetail = withRouter(NotamDetailComponent);
