import { FRStatusCode } from '@airshare/external-api-types';

// Ideally the overall status would be provided by the backend or live on the flight request
// BE CAREFUL this doesn't deviate from 'airspace-manager-common/status/multi-status-check.ts'
export function resolveSingleStatus<A extends { code: FRStatusCode }>(
  status: A[] | undefined
): FRStatusCode {
  if (!status?.length) {
    return FRStatusCode.Declared;
  }

  if (status.some((x) => x.code === FRStatusCode.Terminated))
    return FRStatusCode.Terminated;
  if (status.some((x) => x.code === FRStatusCode.Cancelled))
    return FRStatusCode.Cancelled;
  if (status.some((x) => x.code === FRStatusCode.Activated))
    return FRStatusCode.Activated;
  if (status.some((x) => x.code === FRStatusCode.RNZAFAuthReq))
    return FRStatusCode.RNZAFAuthReq;
  if (status.some((x) => x.code === FRStatusCode.Declined))
    return FRStatusCode.Declined;
  if (status.some((x) => x.code === FRStatusCode.Conflicted))
    return FRStatusCode.Conflicted;
  if (status.some((x) => x.code === FRStatusCode.Pending))
    return FRStatusCode.Pending;
  if (status.some((x) => x.code === FRStatusCode.ProvAuth))
    return FRStatusCode.ProvAuth;
  if (status.some((x) => x.code === FRStatusCode.Authorized))
    return FRStatusCode.Authorized;
  if (status.some((x) => x.code === FRStatusCode.Declared))
    return FRStatusCode.Declared;
  return FRStatusCode.Declared;
}
