import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';
import { updateProfileFormReducer } from './update-profile-form/reducers';
import { ProfileUpdateStatus } from './enum';
import {
  FETCH_SUCCEEDED,
  UPDATE_SUCCEEDED,
  UPDATE_REQUESTED,
  UPDATE_FAILED,
  UPDATE_STATUS_RESET,
} from './actions';
import { ProfileState } from './saga';

export const REDUCER_KEY = 'profile';

export const userReducer = createReducer({
  [FETCH_SUCCEEDED]: (_: ProfileState, { payload }: { payload: any }) =>
    payload,
});

const INITIAL_UPDATE_STATUS = ProfileUpdateStatus.IDLE;

export const updateStatusReducer = createReducer(
  {
    [UPDATE_REQUESTED]: () => ProfileUpdateStatus.PROFILE_UPDATE_IN_PROGRESS,
    [UPDATE_SUCCEEDED]: () => ProfileUpdateStatus.SUCCESS,
    [UPDATE_FAILED]: () => ProfileUpdateStatus.FAILED,
    [UPDATE_STATUS_RESET]: () => ProfileUpdateStatus.IDLE,
  },
  INITIAL_UPDATE_STATUS
);

export const profileReducer = combineReducers<ProfileState>({
  user: userReducer,
  updateStatus: updateStatusReducer,
  updateProfileForm: updateProfileFormReducer,
});
