import { detect } from 'detect-browser';

const DATE_TIME_SUPPORTED_BROWSERS = [
  { browserName: 'chrome', minVersion: 25 },
  { browserName: 'edge', minVersion: 13 },
  { browserName: 'edge-chromium', minVersion: 13 },
  { browserName: 'firefox', minVersion: 93 },
];

export const isBrowserSupported = () => {
  const detectedAgent = detect();
  const browserName =
    detectedAgent?.type === 'browser' ? detectedAgent.name.toLowerCase() : null;
  const browserVersion = Number(detectedAgent?.version?.split('.')[0]);

  const browserConfig = DATE_TIME_SUPPORTED_BROWSERS.find(
    (x) => x.browserName.toLowerCase() === browserName
  );

  return browserConfig?.minVersion <= browserVersion;
};
