import type { AxiosError } from 'axios';

import type { MapBounds, StyledAreaResponse } from 'argus-common/interfaces';
import type {
  AcknowledgeFlightRequestResponse,
  ConflictingFlightAdvisoriesPaged,
  ComparativeFlightAdvisoryResponse,
} from '@airshare/external-api-types';
import type {
  AirshareUser,
  AirshareUsersPaged,
  CommonAdvisory,
  EditOrganisationRequestBodyData,
  ErrorResponse,
  ExtendedFlightResponseBodyV2,
  FlightRequestQueryResponseV2,
  OrganisationViewModelPaged,
  UpdatePilotUserRequestBody,
  NotamsResponseWithLocation,
  ProfileUser,
} from '@airshare/pilot-types';

import type { AppDefault } from '../state/app-config';

import { pilotAPI } from './api';
import { Polygon } from '@turf/helpers';

export * from './auth-api-client';
export * from './message-client';
export * from './activation-client';

export const getFlightRequest = async (
  flightId: string
): Promise<ExtendedFlightResponseBodyV2> => {
  return pilotAPI
    .get(`v2/flight-requests/${flightId}?version=2.1`)
    .then((resp) => resp.data)
    .catch(() => ({
      errors: [`Unable to return flight ${flightId}.`],
    }));
};

export const getFlightRequests = async (params: {
  afterDate?: string;
  beforeDate?: string;
  pageIndex: number;
}): Promise<FlightRequestQueryResponseV2> => {
  return pilotAPI
    .get(`v2/flight-requests`, { params })
    .then((resp) => resp.data)
    .catch(() => ({
      errors: [`Unable to return flights.`],
    }));
};

export const getFavourites =
  async (): Promise<FlightRequestQueryResponseV2> => {
    return pilotAPI
      .get(`favourite?version=2.1`)
      .then((resp) => resp.data)
      .catch(() => ({
        errors: [`Unable to return flights.`],
      }));
  };

export const getNearbyFlights = async (
  flightId: string,
  activated: boolean = false
): Promise<
  | ConflictingFlightAdvisoriesPaged
  | { message: string; data: ComparativeFlightAdvisoryResponse[] }
> => {
  return pilotAPI
    .get(`v2/flight-requests/${flightId}/nearby?activated=${activated}`)
    .then((resp) => resp.data)
    .catch((err: any) => ({
      message: `Unable to find flights nearby ${flightId}, ${
        err?.response?.data?.message ?? err.message ?? ''
      }`,
    }));
};

export const getWeatherForecast = async (data: {
  lat: number;
  lng: number;
  dateTime: string;
  altitude: number;
  timezone: string;
}) =>
  pilotAPI
    .get(
      `/weather-forecast?lat=${data.lat}&lng=${data.lng}&dateTime=${data.dateTime}&altitude=${data.altitude}&timezone=${data.timezone}`
    )
    .then((resp) => resp.data);

export const getAirspaceActivity = (): Promise<{}[]> =>
  pilotAPI
    .get('/v2/flight-requests/viewer')
    .then((resp) => {
      return resp.data.data;
    })
    .catch((err) => {
      throw new Error(`Error while getting airspace acitivity: ${err}`);
    });

export interface NotamErrorResponse {
  error?: string;
}
export type NotamClientResponse =
  | NotamsResponseWithLocation[]
  | NotamErrorResponse;

export const isNotamError = (
  notamResponse: NotamClientResponse
): notamResponse is NotamErrorResponse => {
  return !!(notamResponse as NotamErrorResponse)?.error;
};

export const getNotams = (
  mapbounds?: MapBounds
): Promise<NotamClientResponse> => {
  const { ne, sw } = mapbounds || {};

  const url = ne && sw ? `/notams?ne=${ne}&sw=${sw}` : '/notams';

  return pilotAPI
    .get(url)
    .then((resp) => {
      return resp.data.map((notam: any) => {
        return {
          ...notam,
          id: notam.id,
          location: { lng: notam.longitude, lat: notam.latitude },
        };
      });
    })
    .catch((err) => ({ error: err }));
};

export const updateOrgPilot = async (
  id: string,
  payload: {
    email: string;
    name?: string;
    mobileNo?: string;
    defaultUav?: string;
  }
) => pilotAPI.put(`v2/org-pilots/${id}`, payload);

const pagedSearchQuery = (
  pageSize: number,
  pageIndex: number,
  search: string | null = null
) => `search=${search}&pageindex=${pageIndex}&pagesize=${pageSize}`;

export const getOrganisations = async (
  pageSize: number,
  pageIndex: number,
  search: string | null = null
): Promise<OrganisationViewModelPaged> =>
  pilotAPI
    .get(`/v2/organisations?${pagedSearchQuery(pageSize, pageIndex, search)}`)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err }));

export const createOrganisation = async (
  body: EditOrganisationRequestBodyData
) =>
  pilotAPI
    .post('/organisations', { data: body })
    .then((resp) => resp.data)
    .catch((err) => ({ error: err }));

export const updateOrganisation = async (
  id: string,
  body: Partial<EditOrganisationRequestBodyData>
) =>
  pilotAPI
    .put(`/organisations/${id}`, {
      data: body,
    })
    .then((resp) => resp.data)
    .catch((err) => ({ error: err }));

export const getEmailAutocomplete = async (
  search: string,
  limit: number = 10
): Promise<{ email: string }[]> =>
  pilotAPI
    .get(`/v2/organisations/users/search?search=${search}&limit=${limit}`)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err }));

export const getAdvisories = async (
  mapBounds: MapBounds | null
): Promise<CommonAdvisory[]> => {
  if (!mapBounds) return [];

  const url = `/advisories?ne=${mapBounds.ne}&sw=${mapBounds.sw}&zoom=${mapBounds.zoom}`;
  try {
    const resp: { data: { message: string } | CommonAdvisory[] } =
      await pilotAPI.get(url);
    // @ts-ignore
    if (resp.data?.message === 'No advisories at this level') return [];
    return resp.data as CommonAdvisory[];
  } catch (err) {
    console.error(`Error while fetching advisories: ${err.message}`);
    throw new Error(err.message);
  }
};

export const getAdvisoriesByGeometry = async (
  geometry: Polygon | null,
  mapBounds: MapBounds | null,
  startDateTime: string | null,
  endDateTime: string | null
): Promise<CommonAdvisory[]> => {
  if (!geometry || !mapBounds) return [];

  let url = `/advisories?ne=${mapBounds.ne}&sw=${mapBounds.sw}&zoom=${mapBounds.zoom}`;
  if (startDateTime && endDateTime) {
    url += `&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
  }
  try {
    const resp: { data: { message: string } | CommonAdvisory[] } =
      await pilotAPI.post(url, { area: geometry });
    // @ts-ignore
    if (resp.data?.message === 'No advisories at this level') return [];
    return resp.data as CommonAdvisory[];
  } catch (err) {
    console.error(
      `Error while fetching advisories by geometry: ${err.message}`
    );
    throw new Error(err.message);
  }
};

export const getAdvisory = async (id?: string) => {
  const url = id && id !== 'undefined' ? `/advisories/${id}` : '/advisories';

  return pilotAPI
    .get(url)
    .then((resp: { data: any }) => resp.data)
    .catch((err) => ({ error: err }));
};

export const getAuthedAreasForPilot = async (
  id: string
): Promise<StyledAreaResponse[]> => {
  return pilotAPI
    .get(`/user-authorized-areas/${id}`)
    .then((resp: { data: any }) => resp.data)
    .catch((err) => {
      console.warn(err);
      return [];
    });
};

export const getDefaultConfigs = async (): Promise<AppDefault[]> => {
  return pilotAPI
    .get(`/app-config/web`)
    .then((resp: { data: any }) => resp.data?.defaults ?? [])
    .catch((err) => {
      console.warn(err);
      return [];
    });
};

export const editDefaultConfigs = async (
  updates: AppDefault[]
): Promise<AppDefault[]> => {
  return pilotAPI
    .post(`/app-config/web`, { updates })
    .then((resp: { data: any }) => resp.data?.defaults ?? [])
    .catch((err) => {
      console.warn(err);
      return [];
    });
};

export const acknowledgeAllAuthChanges = async ({
  properties,
}: ExtendedFlightResponseBodyV2): Promise<AcknowledgeFlightRequestResponse> => {
  const changes = properties?.requiredAuthChanges?.map(
    (change) => change.field
  );
  const id = `${properties?.flightId ?? 'unknown'}`;
  if (changes?.length) {
    return acknowledgeAuthChanges(id, changes);
  }
  return {
    errors: [`Flight ${id} has no changes to acknowledge`],
  };
};

export const acknowledgeAuthChanges = async (
  id: string,
  changes: string[]
): Promise<AcknowledgeFlightRequestResponse> =>
  pilotAPI
    .put(`/v2/flight-requests/${id}/acknowledge`, {
      authorizerChanges: changes,
    })
    .then((resp) => resp.data)
    .catch((err) => {
      if (err?.response?.data?.errors) {
        return err.response.data;
      }
      if (err?.message) {
        return {
          errors: [err.message],
        };
      }
      return {
        errors: [`Error: ${JSON.stringify(err)}`],
      };
    });

export const acknowledgePilotAlertType = async (
  flightId: string,
  alertType: string
): Promise<AcknowledgeFlightRequestResponse> =>
  pilotAPI
    .put(`/v2/flight-requests/${flightId}/acknowledge`, {
      alertType: alertType,
    })
    .then((resp) => resp.data)
    .catch((err) => {
      if (err?.response?.data?.errors) {
        return err.response.data;
      }
      if (err?.message) {
        return {
          errors: [err.message],
        };
      }
      return {
        errors: [`Error: ${JSON.stringify(err)}`],
      };
    });

export const getPilotUsers = async (
  pageSize: number,
  pageIndex: number,
  search: string | null = null
): Promise<AirshareUsersPaged> => {
  return pilotAPI
    .get(`/v2/pilot-users?${pagedSearchQuery(pageSize, pageIndex, search)}`)
    .then((resp: { data: AirshareUsersPaged | ErrorResponse }) => {
      if (isErrorRespBody(resp)) {
        return Promise.reject(resp?.data?.error);
      } else {
        return resp.data;
      }
    })
    .catch((err) => {
      console.warn(err);
      return err.message;
    });
};

export const getPilotUser = async (id: string): Promise<AirshareUser> => {
  return pilotAPI
    .get(`/v2/pilot-users/${id}`)
    .then((resp: { data: AirshareUsersPaged | ErrorResponse }) => {
      if (isErrorRespBody(resp)) {
        return Promise.reject(resp?.data?.error);
      } else {
        return resp.data;
      }
    })
    .catch((err) => {
      console.error(err);
      return err.message;
    });
};

const isErrorRespBody = (resp: any): resp is { data: ErrorResponse } =>
  resp?.data?.error !== undefined;

export const updatePilotUser = async (
  updates: UpdatePilotUserRequestBody
): Promise<{ user?: AirshareUser; errors?: string[] }> => {
  return pilotAPI
    .put(`/v2/pilot-users`, updates)
    .then((resp: { data: any }) => resp.data ?? { errors: ['Unknown error'] })
    .catch((err) => {
      console.warn(err);
      if ((err as AxiosError)?.isAxiosError) {
        return err?.response?.data ?? { errors: [err?.message] };
      }
      return { errors: [err?.message] };
    });
};

export const reportIncident = async (flightId: number, message: string) => {
  return pilotAPI
    .post(`/report-incident`, {
      flightId,
      message,
    })
    .then((resp: { data: any }) => resp.data.message)
    .catch((err) => {
      if (err?.response?.data?.message) {
        throw err.response.data.message;
      } else if (err?.response?.data?.errors) {
        throw err.response.data.errors.join(', ');
      }
      throw 'Error reporting incident';
    });
};

export const getUser = async (): Promise<ProfileUser> => {
  return pilotAPI.get(`/user`);
};
