/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import {
  LoadingButton,
  COMPLETION_DELAY,
  useTimeoutEffect,
} from 'airshare-pilot-web-shared';
import { Recaptcha } from 'common-ui-components';

import {
  useFormState,
  useInputHandlerProps,
  useIsErrored,
  useFieldError,
  useErrors,
  useHandleSubmit,
  useClearForm,
  useResetErrors,
} from '../../../../state/session/auth-forms/login/hooks';
import { LogInStatus } from '../../../../state/session/constants';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { useLogin, useHandleInactiveAccount } from './login.hook';

import './login.scss';

interface Props extends RouteComponentProps {
  referrer: string;
  redirectUrl: string;
}

function LoginFormComponent({ redirectUrl, referrer, history }: Props) {
  const navigateTo = useNavigateTo();

  const emailRef = useRef<HTMLInputElement>();
  const recaptchaRef = useRef<any>(null);

  // State and Actions
  const [loginStatus, attemptLogin] = useLogin();
  const form = useFormState();
  const handlerProps = useInputHandlerProps();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const fieldError = useFieldError();
  const clearForm = useClearForm();
  const resetErrors = useResetErrors();
  const isLoggingIn = loginStatus === LogInStatus.LOG_IN_ATTEMPT_IN_PROGRESS;
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleLoginAttempt = (isInactive: boolean) => {
    if (isInactive) {
      history.push(`${referrer}/signin/activate`, {
        referrer,
        redirectUrl,
      });
    } else {
      clearForm();
    }
  };

  useHandleInactiveAccount({
    handleLoginAttempt: handleLoginAttempt,
    email: form.email,
  });

  useTimeoutEffect(
    () => {
      navigateTo(redirectUrl);
    },
    COMPLETION_DELAY,
    loginStatus === LogInStatus.SUCCESS
  );

  useTimeoutEffect(
    () => {
      navigateTo(`${referrer}/signin/policies`);
    },
    COMPLETION_DELAY,
    loginStatus === LogInStatus.POLICY_ACCEPTANCE
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    resetErrors();

    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    // Reset the recaptcha on login failure
    if (loginStatus === LogInStatus.FAILED && recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, [errors]);

  // Handlers
  const handleSubmit = useHandleSubmit(() => {
    if (
      loginStatus !== LogInStatus.LOG_IN_ATTEMPT_IN_PROGRESS &&
      recaptchaToken
    ) {
      const { email } = form;

      attemptLogin({ ...form, email: email.trim(), recaptchaToken });
    }
  });

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="signin-form"
      data-testid="pilot-web:signin-form"
    >
      <FormControl className="form-control" error={isErrored('email')}>
        <TextField
          type="text"
          name="email"
          className="email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="input-icon email" />
              </InputAdornment>
            ),
            value: form.email,
            placeholder: 'Email Address',
            ref: emailRef,
          }}
          data-testid="pilot-web:signin-form:email-input"
          error={isErrored('email')}
          {...handlerProps}
        />
        <FormHelperText className="input-hint">
          {fieldError('email')}
        </FormHelperText>
      </FormControl>

      <FormControl className="form-control" error={isErrored('password')}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          name="password"
          className="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="start"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <i className="input-icon password" />
              </InputAdornment>
            ),
            value: form.password,
            placeholder: 'Password',
          }}
          data-testid="pilot-web:signin-form:password-input"
          error={isErrored('password')}
          {...handlerProps}
          onKeyDown={handleKeyDown}
        />
        <FormHelperText className="input-hint">
          {fieldError('password')}
        </FormHelperText>
      </FormControl>
      <FormControl>
        <Recaptcha setRecaptchaToken={setRecaptchaToken} ref={recaptchaRef} />
      </FormControl>
      <FormHelperText
        className="validation-errors"
        error
        data-testid="pilot-web:signin-form:validation-error"
      >
        {errors.message}
      </FormHelperText>

      <LoadingButton
        isLoading={isLoggingIn}
        className="form-control"
        type="submit"
        data-testid="pilot-web:signin-form:submit-button"
        disabled={!recaptchaToken}
      >
        Submit
      </LoadingButton>
    </form>
  );
}

export const LoginForm = withRouter(LoginFormComponent);
