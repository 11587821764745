import { createSelector } from 'reselect';

const getLocalState = (state: any) => state.session;

export const getOnline = createSelector(
  [getLocalState],
  (state) => state.online
);

export const getError = createSelector([getLocalState], (state) => state.error);

export const getProfile = createSelector(
  [getLocalState],
  (state) => state.profile
);

export const getLoginStatus = createSelector(
  [getLocalState],
  (state) => state.loginStatus
);

export const getCompleteLoginData = createSelector(
  [getLocalState],
  (state) => state.completeLoginData
);

export const getPoliciesToAccept = createSelector(
  [getLocalState],
  (state) => state.policiesToAccept
);

export const getRegistrationStatus = createSelector(
  [getLocalState],
  (state) => state.registrationStatus
);

export const getActivationStatus = createSelector(
  [getLocalState],
  (state) => state.activationStatus
);

export const getChangePasswordStatus = createSelector(
  [getLocalState],
  (state) => state.changePasswordStatus
);

export const getAccountIsActive = createSelector(
  [getLocalState],
  (state) => state.isAccountActive
);

export const getResendActivationEmailStatus = createSelector(
  [getLocalState],
  (state) => state.resendActivationEmailStatus
);
