export default (
  google: typeof window.google,
  allowSegmentedFlights: boolean
) => {
  const STANDARD_DRAWING_MODES = [
    google.maps.drawing.OverlayType.CIRCLE,
    google.maps.drawing.OverlayType.POLYGON,
  ];

  const DRAWING_MODES = allowSegmentedFlights
    ? STANDARD_DRAWING_MODES.concat(google.maps.drawing.OverlayType.POLYLINE)
    : STANDARD_DRAWING_MODES;

  const shapeOptions = {
    fillColor: '#000000',
    fillOpacity: 0.2,
    strokeColor: '#000000',
    strokeOpacity: 0.4,
    strokeWeight: 2,
    clickable: false,
    editable: true,
    zIndex: 1,
    geodesic: true,
  };

  const drawingManagerConfiguration: google.maps.drawing.DrawingManagerOptions =
    {
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        drawingModes: DRAWING_MODES,
      },
      circleOptions: shapeOptions,
      polygonOptions: shapeOptions,
      polylineOptions: {
        ...shapeOptions,
        draggable: false,
        editable: false,
      },
    };

  return {
    drawingManagerConfiguration,
  };
};
