import { useContext, useEffect, useMemo, useState } from 'react';
import type { FeatureCollection, Polygon } from '@turf/helpers';
import { styleKeys } from '@airshare/external-api-types';

import { AdvisorySource } from '@airshare/pilot-types';

import { GlobalState } from '~/state/GlobalState';
import { styleFeatureCollection } from '../../shared/segments/segment-helpers';
import { clearFeatures } from '../render-helpers';
import { useFocussedFlightRequest } from '../../../state/flight-requests/hooks';

const FLIGHT_AREA_Z_INDEX = 1000;

export const useFlightGeojson = (
  dataLayer: google.maps.Data | null,
  hasPolylineOverlay: FeatureCollection<Polygon> | null
) => {
  const flightBriefing = useFocussedFlightRequest();

  const { highlightedSegments, setAdvisoryGeometry, segmentedBufferRadius } =
    useContext(GlobalState);

  // Only set geometry used in advisories once for an existing flight briefing
  const [advisoryGeometrySubmitted, setAdvisoryGeometrySubmitted] =
    useState(false);

  useEffect(() => {
    if (!flightBriefing) {
      setAdvisoryGeometrySubmitted(false);
    }
  }, [flightBriefing]);

  useMemo(() => {
    if (flightBriefing?.geometry && !advisoryGeometrySubmitted) {
      setAdvisoryGeometry(
        AdvisorySource.FlightBriefing,
        flightBriefing?.geometry as Polygon
      );
      setAdvisoryGeometrySubmitted(true);
    }
  }, [
    setAdvisoryGeometry,
    flightBriefing?.geometry,
    advisoryGeometrySubmitted,
  ]);

  useEffect(() => {
    if (dataLayer && !hasPolylineOverlay) {
      // load features for the flight
      try {
        if (flightBriefing?.properties) {
          clearFeatures(dataLayer);
          dataLayer.addGeoJson(
            flightBriefing?.properties?.segmentCollection
              ? styleFeatureCollection(
                  flightBriefing?.properties?.segmentCollection,
                  highlightedSegments
                )
              : flightBriefing
          );
        }

        setStyle(dataLayer);
      } catch (error) {
        // ! FIXME This try catch safe guards against corrupt geojson sent from the backend
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, [
    dataLayer,
    flightBriefing,
    highlightedSegments,
    setAdvisoryGeometry,
    segmentedBufferRadius,
    hasPolylineOverlay,
  ]);
};

function setStyle(dataLayer: google.maps.Data) {
  dataLayer.setStyle((feature) => {
    // return props if already defined
    let props = feature.getProperty('props') as Object;

    if (props) {
      return props;
    }

    // get all style props needed for rendering
    props = styleKeys.reduce(
      (acc, prop) => ({ ...acc, [prop]: feature.getProperty(prop) }),
      {}
    );

    props = {
      ...props,
      zIndex: FLIGHT_AREA_Z_INDEX,
    };

    // store props for reloading in subsequent calls
    feature.setProperty('props', props);
    return props;
  });
}
