import { DateTime } from 'luxon';

interface AdvisoryProperties {
  name?: string;
  siteName?: string;
  mapCode?: string;
  type?: string;
  phone?: string;
}

export function getAdvisoryTitle(advisory: AdvisoryProperties): string {
  return advisory?.siteName || advisory?.name;
}

export function getAdvisorySubTitle(advisory: AdvisoryProperties): string {
  if (advisory?.mapCode === 'ctr' || advisory?.type === 'Control Zone') {
    return null;
  }

  if (advisory?.siteName) {
    return advisory?.siteName === advisory?.name ? null : advisory?.name;
  }

  return null;
}

export function getAdvisoryPhoneNumber(advisory: AdvisoryProperties): string {
  if (advisory?.mapCode === 'ctr' || advisory?.type === 'Control Zone') {
    return 'Use AirShare';
  }
  if (advisory?.phone && advisory?.phone !== 'Controlled Aerodrome') {
    return `Call ${advisory.phone}`;
  }
  return null;
}

export function getAdvisoryFormattedDate(dateTime: string, timezone: string) {
  if (dateTime && timezone) {
    return DateTime.fromJSDate(new Date(dateTime))
      ?.setZone(timezone)
      ?.toFormat('dd MMMM yyyy HH:mm ZZZZ');
  }
}
