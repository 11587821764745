import * as yup from 'yup';
import { createFormReducer } from 'airshare-web-utils/redux-forms/index.ts';
import { actions, ORG_PILOTS_FORM_KEY } from './actions';
import { MobileRegex } from '@airshare/pilot-types';

export const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email().required('Email is required'),
  mobileNo: yup
    .string()
    .required('Mobile Number is required')
    .test({
      name: 'is-valid-phone',
      message: 'mobileNo must be a valid phone number',
      test: (value) => {
        if (value) {
          return MobileRegex.test(value);
        }
      },
    }),
  defaultUav: yup.string().nullable(),
});

const defaultState = {
  name: '',
  email: '',
  mobileNo: '',
  defaultUav: null,
};

export const orgPilotsFormReducer = {
  [ORG_PILOTS_FORM_KEY]: createFormReducer(actions, defaultState, schema),
};
