import React from 'react';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const PrioritOperationToggle = (props: {
  priorityOperationLevel: number;
  isPriorityFlight: boolean;
  inputHandlerProps: Object;
  onChange: (_: any, val: boolean) => void;
}) => {
  const {
    priorityOperationLevel,
    isPriorityFlight,
    inputHandlerProps,
    onChange,
  } = props;
  return priorityOperationLevel >= 0 ? (
    <>
      {/*
          // @ts-ignore */}
      <FormControlLabel
        label={
          <Typography
            variant="subtitle1"
            data-testid="pilot-web:flight-request:priority-flight"
          >
            Is this a priority/emergency operation?
          </Typography>
        }
        labelPlacement="start"
        className="camera-in-use-switch"
        control={
          <Switch
            name="isPriorityFlight"
            size="small"
            color="primary"
            checked={isPriorityFlight}
            {...inputHandlerProps}
            onChange={onChange}
            data-testid="pilot-web:flight-request:is-priority-flight"
          />
        }
      ></FormControlLabel>
    </>
  ) : null;
};

export default PrioritOperationToggle;
