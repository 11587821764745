import React, { useCallback, useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import bbox from '@turf/bbox';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { AdvisorySource, type CommonAdvisory } from '@airshare/pilot-types';

import { AdvisoryModalItem } from './advisory-modal-item';
import { Loading } from '../loading/loading';
import { getAdvisoriesByGeometry } from '../../../pilot-api-client';
import { GlobalState } from '../../../state/GlobalState';
import { useFocussedFlightRequest } from '../../../state/flight-requests/hooks';
import { useFormState } from '../../../state/flight-plan/flight-plan-form/hooks';
import { FLIGHT_PLAN_PATH, advisoryPinPath } from '../../../routes';

import './advisory-modal.scss';
import { useDebounce } from 'airshare-pilot-web-shared';

interface Props extends RouteComponentProps<any> {
  onCollapseModal: (collapsed: boolean) => void;
}

function AdvisoryModalComponent({
  location: { pathname },
  onCollapseModal,
}: Readonly<Props>) {
  const { advisoryGeometry, setHoveredOverAdvisory, advisoryGeometrySource } =
    useContext(GlobalState);

  const focussedFlight = useFocussedFlightRequest();
  const form = useFormState();

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const [isEnlarged, setIsEnlarged] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [advisories, setAdvisories] = React.useState<CommonAdvisory[] | null>(
    null
  );
  const [errored, setErrored] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | null>(null);
  const [detailAdvisory, setDetailAdvisory] =
    React.useState<CommonAdvisory | null>(null);

  useEffect(() => {
    setMessage(
      pathname.endsWith(FLIGHT_PLAN_PATH)
        ? 'Draw or select a flight area to see advisories'
        : 'Click anywhere on the map to see advisories'
    );
  }, [pathname]);

  const getAdvisoryDateTimes = (
    source: AdvisorySource
  ): { startDateTime: string | null; endDateTime: string | null } => {
    switch (source) {
      case AdvisorySource.FlightBriefing:
        if (
          focussedFlight?.properties?.startDateTime &&
          focussedFlight?.properties?.endDateTime
        ) {
          const startDT = DateTime.fromISO(
            focussedFlight?.properties?.startDateTime
          )
            ?.setZone('UTC')
            .toISO();
          const endDT = DateTime.fromISO(
            focussedFlight?.properties?.endDateTime
          )
            ?.setZone('UTC')
            .toISO();
          return {
            startDateTime: startDT,
            endDateTime: endDT,
          };
        }
      // fall through to flight form while editing existing flight
      case AdvisorySource.DraftFlightArea:
        if (form?.dateAndTime && form?.durationMinutes) {
          const startDT: DateTime =
            form.dateAndTime instanceof DateTime
              ? form.dateAndTime
              : DateTime.fromJSDate(form.dateAndTime);
          return {
            startDateTime: startDT.setZone('UTC').toISO(),
            endDateTime: startDT
              .plus({ minutes: form.durationMinutes })
              .setZone('UTC')
              .toISO(),
          };
        }

        // Default to current time if no date and time is set on form
        return {
          startDateTime: DateTime.utc().toISO(),
          endDateTime: DateTime.utc().plus({ second: 1 }).toISO(),
        };
      case AdvisorySource.Pin:
        return {
          startDateTime: DateTime.utc().toISO(),
          endDateTime: DateTime.utc().plus({ second: 1 }).toISO(),
        };
    }

    return { startDateTime: null, endDateTime: null };
  };

  const debouncedGetAdvisories = useDebounce(async () => {
    const [swLng, swLat, neLng, neLat] = bbox(advisoryGeometry);

    const ne = `${neLng},${neLat}`;
    const sw = `${swLng},${swLat}`;

    const mapBounds = {
      ne,
      sw,
      zoom: 20,
    };
    try {
      const { startDateTime, endDateTime } = getAdvisoryDateTimes(
        advisoryGeometrySource
      );

      const resp = await getAdvisoriesByGeometry(
        advisoryGeometry,
        mapBounds,
        startDateTime,
        endDateTime
      );
      setAdvisories(resp);
      setErrored(false);
      if (resp?.length && resp.length <= 4) {
        setIsEnlarged(false);
      }
    } catch (err) {
      setAdvisories(null);
      setErrored(true);
      setIsEnlarged(false);
    } finally {
      setIsLoading(false);
    }
  });

  const fetchAdvisories = useCallback(async () => {
    const inMiddleOfChangingState =
      !advisoryGeometry?.coordinates ||
      (advisoryGeometrySource !== AdvisorySource.Pin &&
        advisoryPinPath(pathname)) ||
      (advisoryGeometrySource === AdvisorySource.Pin &&
        !advisoryPinPath(pathname));

    if (inMiddleOfChangingState) {
      // don't do anything if we're in the middle of changing state
      return;
    }

    const shouldResetState = advisoryGeometrySource === AdvisorySource.None;

    if (shouldResetState || !advisoryGeometry) {
      setAdvisories(null);
      setDetailAdvisory(null);

      setErrored(false);
      setIsLoading(false);
      setIsEnlarged(false);
      return;
    }

    setIsLoading(true);
    setDetailAdvisory(null);

    debouncedGetAdvisories();
  }, [
    advisoryGeometry,
    advisoryGeometrySource,
    pathname,
    debouncedGetAdvisories,
  ]);

  useEffect(() => {
    fetchAdvisories();

    return () => {
      setAdvisories(null);
      setDetailAdvisory(null);
      setHoveredOverAdvisory(null);
    };
  }, [
    advisoryGeometry,
    advisoryGeometrySource,
    form?.dateAndTime,
    form?.durationMinutes,
    fetchAdvisories,
    setHoveredOverAdvisory,
  ]);

  useEffect(() => {
    setIsCollapsed(false);
  }, [advisoryGeometry]);

  useEffect(() => {
    setIsCollapsed(false);
    setIsEnlarged(false);
  }, [advisoryGeometrySource]);

  const handleRetry = () => {
    fetchAdvisories();
  };
  const handleMouseleave = useCallback(() => {
    if (!detailAdvisory) {
      setHoveredOverAdvisory(null);
    }
  }, [detailAdvisory, setHoveredOverAdvisory]);
  const handleMouseover = useCallback(
    (adv: CommonAdvisory) => {
      if (!detailAdvisory) {
        setHoveredOverAdvisory(adv);
      }
    },
    [detailAdvisory, setHoveredOverAdvisory]
  );
  const handleSelectAdvisory = useCallback(
    (advisory: CommonAdvisory) => {
      setHoveredOverAdvisory(advisory);
      setDetailAdvisory(advisory);
    },
    [setHoveredOverAdvisory]
  );

  const getModalSubTitle = useCallback(() => {
    if (advisories === null || isCollapsed) {
      return null;
    }
    switch (advisoryGeometrySource) {
      case AdvisorySource.DraftFlightArea:
      case AdvisorySource.FlightBriefing:
        return 'That overlap any part of the flight area';
      case AdvisorySource.Pin:
        return 'At the location of the pin';
      default:
        return '';
    }
  }, [advisoryGeometrySource, advisories, isCollapsed]);

  const timezone =
    focussedFlight?.properties?.timezone ||
    (form?.dateAndTime instanceof DateTime
      ? form?.dateAndTime?.zoneName
      : undefined) ||
    window.env.DEFAULT_TZ;

  return (
    <div
      className={classNames(
        'advisory-modal',
        isCollapsed ? 'collapsed' : isEnlarged ? 'enlarged' : '',
        isLoading ? 'loading' : '',
        detailAdvisory ? 'detail-expanded' : ''
      )}
      data-testid="pilot-web:maps-panel:advisories-modal"
    >
      <div
        className="advisory-modal-header"
        onClick={() => {
          setIsCollapsed((prev) => {
            const collapsed = !prev;
            onCollapseModal(collapsed);
            return collapsed;
          });
          setDetailAdvisory(null);
        }}
      >
        <Typography
          className="title"
          variant="h6"
          component="h1"
          align="center"
          data-testid="pilot-web:maps-panel:advisories-modal-header"
        >
          Advisories
          {isCollapsed ? (
            <ExpandMore className="toggle-button" />
          ) : (
            <ExpandLess className="toggle-button" />
          )}
        </Typography>

        {getModalSubTitle() ? (
          <Typography
            className="subtitle"
            variant="subtitle2"
            align="center"
            data-testid="pilot-web:maps-panel:advisories-modal-header-subtitle"
          >
            {getModalSubTitle()}
          </Typography>
        ) : null}
      </div>

      <div
        className="advisory-modal-body"
        data-testid="pilot-web:maps-panel:advisories-modal-body"
      >
        {!isLoading && (
          <div
            className="advisory-modal-items"
            data-testid="pilot-web:maps-panel:advisories-modal-items"
            onMouseLeave={() => {
              handleMouseleave();
            }}
          >
            {advisories?.map((advisory, index) => (
              <AdvisoryModalItem
                advisory={advisory}
                timezone={timezone}
                key={`${advisory.id}-${index}`}
                expanded={advisory.id && advisory.id === detailAdvisory?.id}
                handleSelectAdvisory={handleSelectAdvisory}
                handleMouseover={handleMouseover}
              />
            ))}
          </div>
        )}

        {!isLoading && advisories?.length === 0 && (
          <Typography
            className="no-data"
            variant="subtitle1"
            align="center"
            data-testid="pilot-web:maps-panel:adivsory-model-no-advisories"
          >
            {'There are no advisories at this location'}
          </Typography>
        )}

        {!isLoading && errored && (
          <>
            <Typography
              className="failure-message"
              variant="h6"
              component="h6"
              align="center"
            >
              We failed to retrieve the advisories for this area
            </Typography>
            <Typography
              className="failure-action"
              variant="body1"
              component="p"
              align="center"
            >
              Please click{' '}
              <button
                type="button"
                className="retry-link"
                onClick={handleRetry}
              >
                here
              </button>{' '}
              to retry
            </Typography>
          </>
        )}
        {!isLoading && !errored && advisories === null && (
          <Typography
            className="error-message"
            variant="subtitle1"
            align="center"
            data-testid="pilot-web:maps-panel:advisory-modal-error-message"
          >
            {message}
          </Typography>
        )}

        {isLoading && (
          <div className="loader-wrapper">
            <Loading className="advisories" />
          </div>
        )}
      </div>

      {advisories?.length > 4 && !detailAdvisory && !isCollapsed ? (
        <div
          className="advisory-modal-footer"
          onClick={() => setIsEnlarged((prev) => !prev)}
        >
          {isEnlarged ? (
            <ExpandLess className="toggle-button" />
          ) : (
            <ExpandMore className="toggle-button" />
          )}
        </div>
      ) : null}
    </div>
  );
}

export const AdvisoryModal = withRouter(AdvisoryModalComponent);
