import React from 'react';
import {
  Switch,
  withRouter,
  NavLink,
  matchPath,
  RouteComponentProps,
} from 'react-router-dom';
import cn from 'classnames';
import { animated, useSpring } from 'react-spring';

import { useMeasure } from './hooks/measure.hook';
import { Login } from './auth-forms/login/login';
import { RegistrationForm } from './auth-forms/registration/registration';
import { ActivationForm } from './auth-forms/activation/activation';
import { ResetPasswordForm } from './auth-forms/reset-password/reset-password';
import { useNavigateTo } from '../shared/hooks/router.hook';
import { TrackedRoute } from '../shared/tracked-route/tracked-route';
import { CloseButton } from '../shared/close-button/close-button';
import {
  INFORMATION_PATH,
  LOGIN_PATH,
  REGISTRATION_PATH,
  ACTIVATION_PATH,
  RESET_PASSWORD_PATH,
  ACCEPT_POLICIES_PATH,
} from '../../routes';
import { useMobileDetection } from 'airshare-pilot-web-shared';
import { EndUserPolicyAcceptance } from './auth-forms/login/end-user-policy-acceptance';

import './auth-modal.scss';

export const AUTH_PATH = '/*/(signin|signup)';

const LOGIN_SUB_PATH = 'signin';
const REGISTER_SUB_PATH = 'signup';

export function AuthModalComponent(
  props: RouteComponentProps<{}, {}, { redirectUrl: string; referrer?: string }>
) {
  const { location } = props;
  const redirectUrl = location?.state?.redirectUrl ?? INFORMATION_PATH;
  const referrer = location?.state?.referrer ?? INFORMATION_PATH;
  const pathname = location?.pathname || null;
  const isMobile = useMobileDetection();

  // ! FIXME Find a cleaner way to make sure the auth loop doesn't acumulate,
  // ! this is also present in the protected route component.
  const nextSigninLink =
    referrer.includes(LOGIN_SUB_PATH) || referrer.includes(REGISTER_SUB_PATH)
      ? referrer.replace(REGISTER_SUB_PATH, LOGIN_SUB_PATH)
      : `${referrer}/${LOGIN_SUB_PATH}`;
  const nextSignupLink =
    referrer.includes(LOGIN_SUB_PATH) || referrer.includes(REGISTER_SUB_PATH)
      ? referrer.replace(LOGIN_SUB_PATH, REGISTER_SUB_PATH)
      : `${referrer}/${REGISTER_SUB_PATH}`;

  const navigateTo = useNavigateTo();

  // @ts-ignore
  const [bind, { height }] = useMeasure();
  // ? Add 80px padding to the measured height here.
  const formHeightSpring = useSpring({ height: height + 80 });

  const pathMatch = matchPath(location.pathname, {
    path: AUTH_PATH,
    exact: true,
    strict: true,
  });

  const isLoginOrRegistration = pathMatch?.isExact;

  function handleClose() {
    navigateTo(referrer);
  }

  return (
    <div className={isMobile ? 'mobile-auth' : 'auth-modal'}>
      {!isMobile && <CloseButton size="small" onClick={handleClose} />}
      <div
        className={cn('auth-body', {
          'is-login-or-registration': isLoginOrRegistration,
        })}
      >
        {isMobile && <div className="airshare-brand" />}
        {!isMobile && isLoginOrRegistration && (
          <div className="auth-tab-headers">
            <div
              className={cn('auth-tab', {
                'is-selected': pathname?.includes(LOGIN_SUB_PATH),
              })}
            >
              <NavLink
                to={{
                  pathname: nextSigninLink,
                  state: { redirectUrl, referrer },
                }}
              >
                Sign In
              </NavLink>
            </div>
            <div
              className={cn('auth-tab', {
                'is-selected': pathname?.includes(REGISTER_SUB_PATH),
              })}
            >
              <NavLink
                to={{
                  pathname: nextSignupLink,
                  state: { redirectUrl, referrer },
                }}
                data-testid="pilot-web:auth-modal:signup-tab"
              >
                Sign Up
              </NavLink>
            </div>
          </div>
        )}
        <animated.div style={formHeightSpring} className="auth-form-container">
          <div {...bind} className="auth-form">
            <Switch>
              <TrackedRoute path={RESET_PASSWORD_PATH}>
                <ResetPasswordForm
                  redirectUrl={redirectUrl}
                  referrer={referrer}
                />
              </TrackedRoute>
              <TrackedRoute path={ACCEPT_POLICIES_PATH}>
                <EndUserPolicyAcceptance redirectUrl={redirectUrl} />
              </TrackedRoute>
              <TrackedRoute path={REGISTRATION_PATH}>
                <RegistrationForm
                  redirectUrl={redirectUrl}
                  referrer={referrer}
                />
              </TrackedRoute>
              <TrackedRoute path={ACTIVATION_PATH}>
                <ActivationForm redirectUrl={redirectUrl} referrer={referrer} />
              </TrackedRoute>
              <TrackedRoute path={LOGIN_PATH}>
                <Login redirectUrl={redirectUrl} referrer={referrer} />
              </TrackedRoute>
            </Switch>
          </div>
        </animated.div>
      </div>
    </div>
  );
}

export const AuthModal = withRouter(AuthModalComponent);
