import React from 'react';
import { versionDescription } from 'argus-common/version';
import { Typography } from '@material-ui/core';

import './about-page.scss';

export function AboutPage() {
  return (
    <div className="about-panel">
      <Typography
        className="heading"
        variant="h6"
        component="h1"
        align="center"
      >
        About
      </Typography>
      <h2>
        <b>Version:</b> {versionDescription}
      </h2>
    </div>
  );
}
