import * as React from 'react';
import cn from 'classnames';

import './expand-button.scss';

interface Props {
  isExpanded?: boolean;
  onClick?: () => void;
  actionDescription?: string;
  className?: string;
  dataTestid?: string;
}

export function ExpandButton({
  onClick = () => {},
  isExpanded = false,
  className = null,
  actionDescription = 'Expand',
  dataTestid = null,
}: Readonly<Props>) {
  return (
    <button
      type="button"
      onClick={onClick}
      data-testid={dataTestid}
      className={cn('expand-button', { [className]: Boolean(className) })}
      data-is-expanded={isExpanded}
    >
      {actionDescription}
    </button>
  );
}
