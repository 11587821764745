import { useEffect, useCallback } from 'react';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { AircraftType, AlertStatus, DroneType } from 'argus-common/enums';
import { AircraftTelemetryWithAlerts as SurveillanceViewModel } from 'argus-events/interfaces';
import {
  getAircraftSymbol,
  getLabelClassForHeading,
  getDroneSymbol,
} from 'airshare-pilot-web-shared/src/lib/map/marker-helpers';

let surveillanceMarkers: any[] = [];

export function useMapSurveillance(
  google: typeof window.google,
  mapInstance: google.maps.Map | null,
  surveillanceData: SurveillanceViewModel[] = [],
  zoom: number
) {
  const labelColor = 'white';

  const createMarker = useCallback(
    (
      type: AircraftType,
      lat: number,
      lng: number,
      heading: number | null,
      speed: number | null,
      droneType: DroneType,
      surveillanceItem: { flightId?: number },
      labelContent: string | HTMLElement,
      labelDisplay: string,
      alertConformanceStatus: AlertStatus
    ) => {
      if (type === AircraftType.Drone) {
        const marker = new MarkerWithLabel({
          map: mapInstance,
          position: new google.maps.LatLng(lat, lng),
          icon: getDroneSymbol(
            heading,
            zoom,
            alertConformanceStatus,
            droneType
          ),
          title: labelDisplay,
          labelContent,
          labelAnchor: new google.maps.Point(-25, 10),
          cursor: 'grab',
        } as any);
        marker.set('flightId', surveillanceItem.flightId);
        return marker;
      }
      const marker = new google.maps.Marker({
        map: mapInstance,
        position: { lng, lat },
        icon: getAircraftSymbol(heading, speed, zoom, AlertStatus.SafeOps),
        title: labelDisplay,
        label:
          zoom > 11
            ? {
                text: '',
                color: labelColor,
                className: 'flight-surveillance-label',
              }
            : '',
        cursor: 'grab',
      });
      marker.set('flightId', surveillanceItem.flightId);
      return marker;
    },
    [google, mapInstance, zoom, labelColor]
  );

  useEffect(() => {
    if (google && mapInstance && surveillanceData) {
      cleanUpMarkers(surveillanceData);

      surveillanceData.forEach((surveillanceItem) => {
        const existingMarker = surveillanceMarkers.find(
          ({ flightId }) => surveillanceItem.flightId === flightId
        );

        const {
          flightId,
          lng,
          lat,
          heading,
          type,
          altitude,
          speed,
          alertConformanceStatus,
        } = surveillanceItem;

        if (lng === null || lat === null) {
          return;
        }

        const droneType: DroneType =
          surveillanceItem?.traceProperties?.source ===
          'ExternalAPIDroneDetection'
            ? DroneType.Unknown
            : DroneType.Known;

        const labelContent =
          zoom > 11
            ? `<div class="drone-surveillance-label">#${
                flightId ? `${flightId}<br/>` : ''
              }${
                altitude || altitude === 0 || speed || speed === 0
                  ? `${
                      altitude || altitude === 0
                        ? `${Math.round(altitude)}ft`
                        : ''
                    }
              ${
                speed || speed === 0 ? ` ${Math.round(speed)}kt<br/>` : '<br/>'
              }`
                  : ''
              }${
                heading
                  ? `${'0'.repeat(
                      3 - `${Math.round(heading)}`.length
                    )}${Math.round(heading)}°`
                  : ''
              }</div>`
            : '';

        const [labelDisplay, labelClass] = getLabelForFlight(
          altitude,
          speed,
          heading || 0
        );

        if (existingMarker) {
          if (type === AircraftType.Drone) {
            existingMarker.setOptions({
              icon: getDroneSymbol(
                heading,
                zoom,
                alertConformanceStatus,
                droneType
              ),
              title: labelDisplay,
              labelContent,
              labelAnchor: new google.maps.Point(-25, 10),
              position: new google.maps.LatLng(lat, lng),
            });
          } else {
            existingMarker.setOptions({
              position: { lng, lat },
              icon: getAircraftSymbol(
                heading,
                speed,
                zoom,
                AlertStatus.SafeOps
              ),
              title: labelDisplay,
              label:
                zoom > 11
                  ? {
                      text: labelDisplay,
                      color: labelColor,
                      className: labelClass,
                    }
                  : '',
            });
          }

          if (!isWithinViewport(mapInstance, lat, lng)) {
            existingMarker.setMap(null);

            const index = surveillanceMarkers.indexOf(existingMarker);
            surveillanceMarkers.splice(index, 1);
          }

          return;
        }

        if (isWithinViewport(mapInstance, lat, lng)) {
          const newMarker = createMarker(
            type,
            lat,
            lng,
            heading,
            speed,
            droneType,
            surveillanceItem,
            labelContent,
            labelDisplay,
            alertConformanceStatus
          );

          surveillanceMarkers.push(newMarker);
        }
      });
    }
  }, [google, mapInstance, surveillanceData, zoom, createMarker, labelColor]);
}

function cleanUpMarkers(surveillanceData: SurveillanceViewModel[]) {
  if (surveillanceData.length === 0) {
    removeMarkers();
    return;
  }

  removeStaleMarkers(surveillanceData);
}

function removeMarkers() {
  surveillanceMarkers.forEach((marker: any) => {
    marker.setMap(null);
  });

  surveillanceMarkers = [];
}

function removeStaleMarkers(surveillanceData: SurveillanceViewModel[]) {
  surveillanceMarkers.forEach((marker) => {
    const isMarkerStale = !surveillanceData.find(
      ({ flightId }) => flightId === marker.flightId
    );

    if (isMarkerStale) {
      marker.setMap(null);

      const index = surveillanceMarkers.indexOf(marker);
      surveillanceMarkers.splice(index, 1);
    }
  });
}

function isWithinViewport(mapInstance: any, lat: number, lng: number) {
  const viewport = mapInstance.getBounds();
  return viewport ? viewport.contains({ lat, lng }) : false;
}

function getLabelForFlight(altitude: number, speed: number, heading: number) {
  return [
    getLabelTextForFlight(altitude, speed),
    getLabelClassForHeading(heading, speed),
  ];
}

function getLabelTextForFlight(altitude: number, speed: number) {
  const knots = speed * 1.94384;
  return `${Math.round(altitude)}ft ${Math.round(knots)}kt`;
}
