import ResizeObserver from 'resize-observer-polyfill';
import { useRef, useState, useEffect } from 'react';

export function useMeasure() {
  const ref = useRef();

  const [bounds, set] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const ro = new ResizeObserver(([entry]) => set(entry.contentRect));

    ro.observe(ref.current);

    return () => ro.disconnect();
  }, []);

  return [{ ref }, bounds];
}
