import type { FlightAdvisoryResponse } from '@airshare/external-api-types';

import { createAction } from 'airshare-web-utils/redux-helpers';
import type { StyledFlightAdvisory } from '@airshare/pilot-types';

export const UPDATED = 'flight-advisories/UPDATED';
export const HIGHLIGHTS_UPDATED = 'flight-advisories/HIGHLIGHTS_UPDATED';

export const flightAdvisoriesUpdated = createAction<
  FlightAdvisoryResponse[],
  string
>(UPDATED);

export const highlightedFlightAdvisoriesUpdated = createAction<
  StyledFlightAdvisory[],
  string
>(HIGHLIGHTS_UPDATED);
