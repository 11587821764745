import * as React from 'react';
import cn from 'classnames';

import './close-button.scss';

interface CloseButtonProps {
  onClick(e: React.MouseEvent<HTMLButtonElement>): any;
  className?: string;
  size?: string;
  actionDescription?: string;
}

export function CloseButton({
  onClick,
  className,
  size = 'large',
  actionDescription = 'Close',
}: CloseButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn('close-button', {
        [className]: Boolean(className),
        [size]: Boolean(size),
      })}
      data-testid="pilot-web:close-button"
    >
      {actionDescription}
    </button>
  );
}
