import { call, put, takeEvery } from 'redux-saga/effects';
import { pilotAPI } from '../../pilot-api-client/api';
import {
  FETCH_REQUESTED,
  uavDefaultsFetchSucceeded,
  uavDefaultsFetchFailed,
} from './actions';

interface Manufacturer {
  name: string;
  models: { name: string }[];
}

interface Weight {
  name: string;
  code: string;
}

interface CClass {
  name: string;
  code: string;
}

export interface UavDefaults {
  manufacturers: Manufacturer[];
  weights: Weight[];
  uavCClasses: CClass[];
}

function* fetchUavDefaults() {
  try {
    const { data } = yield call(pilotAPI.get, '/uav-defaults');

    yield put(uavDefaultsFetchSucceeded(data));
  } catch (e) {
    yield put(uavDefaultsFetchFailed(e.message));
  }
}

export function* uavDefaultsSaga() {
  yield call(fetchUavDefaults);

  yield takeEvery(FETCH_REQUESTED, fetchUavDefaults);
}
