export default `<svg width="250" height="250"

  viewBox="0 0 48 48" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
  <filter id='shadow' color-interpolation-filters="sRGB">
    <feDropShadow dx="1" dy="1" stdDeviation="0.5" flood-opacity="0.6"/>
  </filter>
  <g class="layer" filter="url(#shadow)">
    <title>plane</title>
    <path 
      d="M12.127 14.2852L8.88888 17.5233C7.80951 18.6027 6.73015 17.5233 7.80951 16.444L16.4444 7.80904C17.5238 6.72968 18.6032 7.80904 17.5238 8.88841L14.2857 12.1265C14.2857 12.1265 16.4444 12.1265 18.6032 14.2852L20.7619 16.444L30.4762 6.72968L33.7143 9.96777L28.3174 23.9995L34.7936 32.6344L39.1111 30.4757L40.1905 31.5551L31.5555 40.19L30.4762 39.1106L32.6349 34.7932L24 28.317L9.96824 33.7138L6.73015 30.4757L16.4444 20.7614L14.2857 18.6027C12.127 16.444 12.127 14.2852 12.127 14.2852Z" 
      fill="#f57c00" opacity="0.9" id="svg_1" stroke="#000000" stroke-width="1"
      />
  </g>    
</svg>`;
