import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Link,
  RouteComponentProps,
  matchPath,
  withRouter,
} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { NotamsResponseWithLocation } from '@airshare/pilot-types';

import { useDebounce } from 'airshare-pilot-web-shared';

import { getNotamDetailPath, NOTAM_DETAIL_PATH } from '../../../../routes';
import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';
import { getNotams, isNotamError } from '../../../../pilot-api-client';
import { GlobalState } from '../../../../state/GlobalState';

import './notams.scss';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    typography: {
      color: 'var(--color-brand-secondary)',
      fontWeight: 'bold',
    },
  })
);

function NotamsComponent({
  location: { pathname },
}: RouteComponentProps<{}, {}, {}>) {
  const classes = useStyles();
  const { mapBounds } = useContext(GlobalState);
  const [isLoading, setIsLoading] = useState(false);
  const [notams, setNotams] = useState([]);

  const notamDetailPathMatch = matchPath<{ id: string }>(pathname, {
    path: NOTAM_DETAIL_PATH,
    exact: true,
    strict: true,
  });

  const selectedId = notamDetailPathMatch
    ? notamDetailPathMatch.params.id
    : null;

  const debouncedFetchNotams = useDebounce(async () => {
    try {
      const notamsResponse = await getNotams(mapBounds);
      if (isNotamError(notamsResponse)) {
        setNotams(null);
      } else {
        setNotams(notamsResponse);
      }
    } catch (err) {
      setNotams(null);
    }
    setIsLoading(false);
  });

  const fetchNotams = useCallback(() => {
    setIsLoading(true);
    debouncedFetchNotams();
  }, [debouncedFetchNotams, setIsLoading]);

  useEffect(() => {
    fetchNotams();
  }, [fetchNotams, mapBounds]);

  function handleRetry() {
    fetchNotams();
  }

  const noNotams =
    (notams as NotamsResponseWithLocation[])?.length === 0 || !notams;

  return (
    <LoadingPanel isLoading={isLoading}>
      <div className="notams-panel" data-testid="pilot-web:notams-panel">
        <Typography
          className="heading"
          variant="h6"
          component="h1"
          align="center"
        >
          NOTAMs
        </Typography>

        {isNotamError(notams) && (
          <>
            <Typography
              className="failure-message"
              variant="h6"
              component="h2"
              align="center"
            >
              We failed to retrieve the NOTAMs for this area
            </Typography>
            <Typography
              className="failure-action"
              variant="body1"
              component="p"
              align="center"
            >
              Please click{' '}
              <button
                type="button"
                className="retry-link"
                onClick={handleRetry}
              >
                here
              </button>{' '}
              to retry
            </Typography>
          </>
        )}

        {noNotams && (
          <Typography className="no-data" variant="subtitle1">
            There are no NOTAMs for your current map position
          </Typography>
        )}
        {!isLoading &&
          (notams as NotamsResponseWithLocation[])?.map &&
          (notams as NotamsResponseWithLocation[])?.map(
            ({ id, description }) => (
              <Link
                to={{
                  pathname: getNotamDetailPath(id),
                }}
                className="notam card"
                key={id}
              >
                <Typography
                  className={
                    selectedId && selectedId == id
                      ? `description ${classes.typography}`
                      : 'description'
                  }
                >
                  {description}
                </Typography>
              </Link>
            )
          )}
      </div>
    </LoadingPanel>
  );
}

export const Notams = withRouter(NotamsComponent);
