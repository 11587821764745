import { createAction } from 'airshare-web-utils/redux-helpers';
import {
  ExtendedFlightResponseBodyV2,
  FlightRequestQueryResponseV2,
} from '@airshare/pilot-types';

export const FETCH_REQUESTED = 'flight-requests/FETCH_REQUESTED';
export const FETCH_SUCCEEDED = 'flight-requests/FETCH_SUCCEEDED';
export const FETCH_FAILED = 'flight-requests/FETCH_FAILED';
export const RESET_STATE = 'flight-requests/RESET_STATE';
export const FETCH_STATUS_RESET = 'flight-requests/FETCH_STATUS_RESET';
export const FETCH_FOCUSSED_REQUESTED =
  'flight-requests/FETCH_FOCUSSED_REQUESTED';
export const FETCH_FOCUSSED_SUCCEEDED =
  'flight-requests/FETCH_FOCUSSED_SUCCEEDED';
export const FETCH_FOCUSSED_FAILED = 'flight-requests/FETCH_FOCUSSED_FAILED';
export const FOCUSSED_RESET = 'flight-requests/FOCUSSED_RESET';
export const FETCH_FOCUSSED_STATUS_RESET =
  'flight-requests/FETCH_FOCUSSED_STATUS_RESET';
export const CANCEL_REQUESTED = 'flight-requests/CANCEL_REQUESTED';
export const CANCEL_SUCCEEDED = 'flight-requests/CANCEL_SUCCEEDED';
export const CANCEL_FAILED = 'flight-requests/CANCEL_FAILED';
export const CANCEL_STATUS_RESET = 'flight-requests/CANCEL_STATUS_RESET';

export const ACTIVATE_FLIGHT_REQUESTED =
  'flight-requests/ACTIVATE_FLIGHT_REQUESTED';
export const ACTIVATE_FLIGHT_SUCCEEDED =
  'flight-requests/ACTIVATE_FLIGHT_SUCCEEDED';
export const ACTIVATE_FLIGHT_FAILED = 'flight-requests/ACTIVATE_FLIGHT_FAILED';
export const ACTIVATE_FLIGHT_STATUS_RESET =
  'flight-requests/ACTIVATE_FLIGHT_STATUS_RESET';

export const REQUEST_ACTIVATION_REQUESTED =
  'flight-requests/REQUEST_ACTIVATION_REQUESTED';
export const REQUEST_ACTIVATION_SUCCEEDED =
  'flight-requests/REQUEST_ACTIVATION_SUCCEEDED';
export const REQUEST_ACTIVATION_FAILED =
  'flight-requests/REQUEST_ACTIVATION_FAILED';
export const REQUEST_ACTIVATION_STATUS_RESET =
  'flight-requests/REQUEST_ACTIVATION_STATUS_RESET';

export const END_REQUESTED = 'flight-requests/END_REQUESTED';
export const END_SUCCEEDED = 'flight-requests/END_SUCCEEDED';
export const END_FAILED = 'flight-requests/END_FAILED';
export const END_STATUS_RESET = 'flight-requests/END_STATUS_RESET';
export const STARTED_LISTENING = 'flight-requests/STARTED_LISTENING';
export const STOPPED_LISTENING = 'flight-requests/STOPPED_LISTENING';
export const STARTED_LISTENING_TO_FOCUSSED =
  'flight-requests/STARTED_LISTENING_TO_FOCUSSED';
export const STOPPED_LISTENING_TO_FOCUSSED =
  'flight-requests/STOPPED_LISTENING_TO_FOCUSSED';
export const DELETE_FAVOURITE_REQUESTED =
  'flight-requests/DELETE_FAVOURITE_REQUESTED';
export const DELETE_FAVOURITE_FAILED =
  'flight-requests/DELETE_FAVOURITE_FAILED';
export const DELETE_FAVOURITE_SUCCEEDED =
  'flight-requests/DELETE_FAVOURITE_SUCCEEDED';

export const flightRequestsFetchRequested = createAction(FETCH_REQUESTED);

export interface FetchSucceedActionPayload {
  favourite: FlightRequestQueryResponseV2;
  current: FlightRequestQueryResponseV2;
  past: FlightRequestQueryResponseV2;
}

export const flightRequestsFetchSucceeded = createAction<
  FetchSucceedActionPayload,
  typeof FETCH_SUCCEEDED
>(FETCH_SUCCEEDED);
export const flightRequestsFetchFailed = createAction(FETCH_FAILED);
export const flightRequestsFetchStatusReset = createAction(FETCH_STATUS_RESET);
export const flightRequestsFetchFocussedRequested = createAction<
  { id: string; resetState?: false },
  typeof FETCH_FOCUSSED_REQUESTED
>(FETCH_FOCUSSED_REQUESTED);
export const flightRequestsFetchFocussedSucceeded = createAction<
  ExtendedFlightResponseBodyV2,
  typeof FETCH_FOCUSSED_SUCCEEDED
>(FETCH_FOCUSSED_SUCCEEDED);
export const flightRequestsFetchFocussedFailed = createAction(
  FETCH_FOCUSSED_FAILED
);
export const flightRequestsFocussedReset = createAction(FOCUSSED_RESET);
export const flightRequestsFetchFocussedStatusReset = createAction(
  FETCH_FOCUSSED_STATUS_RESET
);
export const flightRequestsCancelRequested = createAction(CANCEL_REQUESTED);
export const flightRequestsCancelSucceeded = createAction(CANCEL_SUCCEEDED);
export const flightRequestsCancelFailed = createAction(CANCEL_FAILED);
export const flightRequestsCancelStatusReset =
  createAction(CANCEL_STATUS_RESET);

export const flightRequestsActivateFlightRequested = createAction(
  ACTIVATE_FLIGHT_REQUESTED
);
export const flightRequestsActivateFlightSucceeded = createAction(
  ACTIVATE_FLIGHT_SUCCEEDED
);
export const flightRequestsActivateFlightFailed = createAction(
  ACTIVATE_FLIGHT_FAILED
);
export const flightRequestsActivateFlightStatusReset = createAction(
  ACTIVATE_FLIGHT_STATUS_RESET
);

export const flightRequestsActivationRequested = createAction(
  REQUEST_ACTIVATION_REQUESTED
);
export const flightRequestsActivationSucceeded = createAction(
  REQUEST_ACTIVATION_SUCCEEDED
);
export const flightRequestsActivationFailed = createAction(
  REQUEST_ACTIVATION_FAILED
);
export const flightRequestsActivationStatusReset = createAction(
  REQUEST_ACTIVATION_STATUS_RESET
);

export const flightRequestsEndRequested = createAction(END_REQUESTED);
export const flightRequestsEndSucceeded = createAction(END_SUCCEEDED);
export const flightRequestsEndFailed = createAction(END_FAILED);
export const flightRequestsEndStatusReset = createAction(END_STATUS_RESET);
export const flightRequestsStartedListening = createAction(STARTED_LISTENING);
export const flightRequestsStoppedListening = createAction(STOPPED_LISTENING);
export const flightRequestsStartedListeningFocussed = createAction(
  STARTED_LISTENING_TO_FOCUSSED
);
export const flightRequestsStoppedListeningFocussed = createAction(
  STOPPED_LISTENING_TO_FOCUSSED
);
export const flightRequestsDeleteFavouriteRequested = createAction(
  DELETE_FAVOURITE_REQUESTED
);
export const flightRequestsDeleteFavouriteFailed = createAction(
  DELETE_FAVOURITE_FAILED
);
export const flightRequestsDeleteFavouriteSucceeded = createAction(
  DELETE_FAVOURITE_SUCCEEDED
);
