import React from 'react';
import { DateTime } from 'luxon';
import Typography from '@material-ui/core/Typography';

import type { AllFlightProperties } from '@airshare/pilot-types';

import { DigitalClearanceStatus } from '@airshare/external-api-types';

const callTower = (name?: string, phone?: string) => {
  if (name && phone) {
    return (
      <>
        {' '}
        call {name} tower on{' '}
        <a href={`tel:${phone}`} target="_self">
          {phone}
        </a>
      </>
    );
  }

  return 'call the air traffic control tower';
};

const informationStyle = {
  minHeight: 80,
  backgroundColor: 'whitesmoke',
  padding: 15,
  borderRadius: 5,
  border: '3px solid gray',
};

interface Props {
  status: DigitalClearanceStatus;
  reason: string;
  allocatedControlZone: AllFlightProperties['allocatedControlZone'];
  operatorNotes: AllFlightProperties['operatorNotes'];
  maxAltitudeFeet: AllFlightProperties['maxAltitudeFeet'];
  isTimedOut: boolean;
  isRequestAllowed: boolean;
  updateDateTime: DateTime;
  expiryPeriodMinutes: number;
  nextAllowableDateTime: string;
}

const getLatestNote = (operatorNotes: AllFlightProperties['operatorNotes']) =>
  operatorNotes.length
    ? operatorNotes.sort((a: { createdAt: Date }, b: { createdAt: Date }) => {
        const date1 = new Date(a.createdAt);
        const date2 = new Date(b.createdAt);

        return date2.valueOf() - date1.valueOf();
      })[0]?.text
    : '';

export default function InfoBox({
  status,
  reason,
  allocatedControlZone,
  operatorNotes,
  maxAltitudeFeet,
  isTimedOut,
  isRequestAllowed,
  updateDateTime,
  expiryPeriodMinutes,
  nextAllowableDateTime,
}: Props) {
  if (status === DigitalClearanceStatus.Requested) {
    if (isTimedOut && isRequestAllowed) {
      const { name, phone } = allocatedControlZone;

      return (
        <div style={informationStyle}>
          <Typography data-testid="digital-clearance-expired-message">
            Activation request expired please retry, otherwise{' '}
            {callTower(name, phone)}
          </Typography>
        </div>
      );
    }
    if (!isTimedOut) {
      return (
        <div style={informationStyle}>
          <Typography data-testid="digital-clearance-request-message">
            Activation requested at {updateDateTime.toFormat('HH:mm')}, please
            wait up to {expiryPeriodMinutes} minutes for a decision.
          </Typography>
        </div>
      );
    }
  }

  if (status === DigitalClearanceStatus.Authorized) {
    const latestNote = getLatestNote(operatorNotes);
    const authorisedMsg = latestNote
      ? `Authorised: ${latestNote}`
      : `Authorised to operate up to an altitude of ${maxAltitudeFeet} ft above ground level.`;
    return (
      <div style={{ ...informationStyle, borderColor: '#22b14c' }}>
        <Typography data-testid="digital-clearance-authorise-message">
          {authorisedMsg}
        </Typography>
      </div>
    );
  }

  if (status === DigitalClearanceStatus.CallTower) {
    const { name, phone } = allocatedControlZone;

    return (
      <div style={{ ...informationStyle, borderColor: '#ff7f27' }}>
        <Typography data-testid="digital-clearance-call-back-requested-message">
          Digital activation not available, please call {callTower(name, phone)}
          .
        </Typography>
      </div>
    );
  }

  if (status === DigitalClearanceStatus.Declined) {
    return (
      <div style={{ ...informationStyle, borderColor: '#ed1c24' }}>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 500,
          }}
          data-testid="digital-clearance-decline-message"
        >
          Declined
          {reason ? `: ${reason}, ` : ': '}
          {nextAllowableDateTime
            ? `you can retry at ${DateTime.fromISO(nextAllowableDateTime).toFormat('HH:mm')}`
            : ''}
        </Typography>
      </div>
    );
  }

  return null;
}
