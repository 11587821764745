import React from 'react';
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { reportIncident } from '../../../../pilot-api-client';
import './flight-briefing.scss';

interface ReportIncidentModalProps {
  flightId: number;
}

const ReportIncidentModal = ({ flightId }: ReportIncidentModalProps) => {
  const [open, setOpen] = React.useState(false);
  const [incidentMessage, setIncidentMessage] = React.useState('');
  const [errorReportIncident, setErrorReportIncident] = React.useState('');
  const [successReportIncident, setSuccessReportIncident] = React.useState('');
  const handleOpenModal = () => {
    setErrorReportIncident('');
    setSuccessReportIncident('');
    setIncidentMessage('');
    setOpen(true);
  };
  const handleReportIncidentModal = async (message: string) => {
    try {
      setErrorReportIncident('');
      setSuccessReportIncident('');
      if (message.length === 0) {
        throw 'Incident is required';
      }
      const response = await reportIncident(flightId, message);
      setSuccessReportIncident(response);
      setIncidentMessage('');
    } catch (error) {
      setErrorReportIncident(error);
    }
  };

  return (
    <>
      <ButtonBase
        className="card flight-summary-incident-button secondary"
        data-testid="pilot-web:flight-briefing-panel:report-incident-button"
        onClick={handleOpenModal}
      >
        Report Incident
      </ButtonBase>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        data-testid="pilot-web:flight-briefing-panel:report-incident-modal"
      >
        <DialogTitle data-testid="pilot-web:flight-briefing-panel:report-incident-modal-title">
          <div>
            <Typography variant="h4">Report Incident</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please describe the incident in as much detail as possible
          </DialogContentText>
          <TextField
            id="outlined-multiline-static"
            fullWidth
            multiline
            minRows={4}
            onChange={(e) => setIncidentMessage(e.target.value)}
            value={incidentMessage}
            placeholder="Type your incident message here..."
            variant="outlined"
            inputProps={{ maxLength: 1000 }}
            data-testid="pilot-web:flight-briefing-panel:report-incident-message"
          />
        </DialogContent>
        <Typography
          variant="caption"
          style={{ paddingLeft: '25px', color: '#999999' }}
        >
          {incidentMessage.length}/1000
        </Typography>
        {errorReportIncident && (
          <DialogContentText
            data-testid="pilot-web:flight-briefing-panel:report-incident-error"
            style={{ color: '#f44336', paddingLeft: '15px', fontSize: '12px' }}
          >
            {errorReportIncident}
          </DialogContentText>
        )}
        {successReportIncident && (
          <DialogContentText
            data-testid="pilot-web:flight-briefing-panel:report-incident-success"
            style={{ color: '#36b39a', paddingLeft: '15px', fontSize: '12px' }}
          >
            {successReportIncident}
          </DialogContentText>
        )}
        <DialogActions className="report-incident-dialog-actions">
          <ButtonBase
            className="cancel-button "
            onClick={() => setOpen(false)}
            data-testid="pilot-web:flight-briefing-panel:report-incident-cancel"
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            onClick={() => handleReportIncidentModal(incidentMessage)}
            className="submit-button button primary"
            data-testid="pilot-web:flight-briefing-panel:report-incident-submit"
          >
            Send
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportIncidentModal;
