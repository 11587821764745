import React from 'react';

import './map-controls.scss';
import { Button, IconButton, Typography } from '@material-ui/core';
import { MapStyleCode } from '../../state';

import beigeNotamArea from './images/beige-notam-area.png';
import purplePolygon from './images/purple-polygon.png';
import mapStyleStandardImage from './images/mapstyle-standard.png';
import mapStyleDarkImage from './images/mapstyle-dark.png';
import mapStyleSatelliteImage from './images/mapstyle-satellite.png';
import mapStyleHybridImage from './images/mapstyle-hybrid.png';
import mapStyleTerrainImage from './images/mapstyle-terrain.png';
import mapStyleGrayImage from './images/mapstyle-gray.png';
import CloseIcon from '@material-ui/icons/Close';

export interface AvailableLayer {
  code: string;
  type: string;
  text: string;
  ignoreCode: string[];
}
interface MapControlsProps {
  handleLayerLegendModalButtonClick?(): void;
  handleMapStyleChange?(mapCode: MapStyleCode): void;
  handleCenterPositionClick(): void;
  handleMapZoomOut(): void;
  handleMapZoomIn(): void;
  mapStyle: MapStyleCode;
  supportedZoneCodes: {
    key: string;
    name: string;
    description: string;
    category: string;
  }[];
  toggleLayer(layer: string): void;
  activeLayers: string[];
  availableLayers: AvailableLayer[];
}
const layerImages: { [key: string]: string } = {
  'beige-notam-area': beigeNotamArea,
  council: purplePolygon,
};

function getLayerImageFromCategory(key: string) {
  return layerImages[key] || purplePolygon;
}

const styleImages: { [key: string]: string } = {
  standard: mapStyleStandardImage,
  dark: mapStyleDarkImage,
  satellite: mapStyleSatelliteImage,
  hybrid: mapStyleHybridImage,
  terrain: mapStyleTerrainImage,
  gray: mapStyleGrayImage,
};

function getImageForStyle(styleName: string) {
  return styleImages[styleName] || mapStyleStandardImage;
}

function MapControls(props: MapControlsProps) {
  const {
    handleLayerLegendModalButtonClick,
    handleMapStyleChange,
    handleCenterPositionClick,
    handleMapZoomOut,
    handleMapZoomIn,
    toggleLayer,
    activeLayers,
    mapStyle,
    availableLayers,
    supportedZoneCodes = [],
  } = props;

  const [showMapLayers, setShowMapLayers] = React.useState(false);
  const [showMapStyles, setShowMapStyles] = React.useState(false);

  const handleLayer = () => {
    setShowMapLayers(!showMapLayers);
  };

  const toggleShowMapStyles = () => {
    setShowMapStyles(!showMapStyles);
  };

  return (
    <div className="map-controls-container ">
      {handleLayerLegendModalButtonClick && (
        <button
          className="map-style-button"
          type="button"
          onClick={handleLayerLegendModalButtonClick}
          data-testid="map-info-button"
        >
          <i className="icon information" />
        </button>
      )}
      <div style={{ position: 'relative' }}>
        <button
          className="map-style-button"
          type="button"
          onClick={handleLayer}
          data-testid="map-layer-button"
        >
          <i className={`icon layer ${showMapLayers ? 'active' : ''}`} />
        </button>
        <MapLayerPanel
          showMapLayers={showMapLayers}
          availableLayers={availableLayers}
          toggleLayer={toggleLayer}
          activeLayers={activeLayers}
          supportedZoneCodes={supportedZoneCodes}
        />
      </div>
      <button
        className="map-style-button"
        type="button"
        onClick={toggleShowMapStyles}
        data-testid="map-style-button"
      >
        <i className="icon globe" />
      </button>
      <MapStylesPanel
        currentMapStyle={mapStyle}
        showMapStyles={showMapStyles}
        availableStyles={Object.values(MapStyleCode)}
        setMapStyle={handleMapStyleChange}
        handleClose={() => toggleShowMapStyles()}
      />
      <button
        className="map-style-button"
        type="button"
        onClick={handleCenterPositionClick}
        data-testid="map-position-button"
      >
        <i className="icon current-location" />
      </button>
      <div className="map-zoom-button-container">
        <button
          className="map-zoom-button minus"
          type="button"
          onClick={handleMapZoomOut}
          data-testid="map-zoom-minus-button"
        >
          <div className="map-zoom-button-inner-text">
            <span>-</span>
          </div>
        </button>
        <button
          className="map-zoom-button plus"
          type="button"
          onClick={handleMapZoomIn}
          data-testid="map-zoom-plus-button"
        >
          <div className="map-zoom-button-inner-text">
            <span>+</span>
          </div>
        </button>
      </div>
    </div>
  );
}

export default MapControls;

interface MapLayerPanelProps {
  showMapLayers: boolean;
  availableLayers: AvailableLayer[];
  toggleLayer(layer: string): void;
  activeLayers: string[];
  supportedZoneCodes: {
    key: string;
    name: string;
    description: string;
    category: string;
  }[];
}

function MapLayerPanel({
  showMapLayers,
  availableLayers,
  toggleLayer,
  activeLayers,
  supportedZoneCodes,
}: MapLayerPanelProps) {
  return (
    <div
      className={`map-layers-panel ${showMapLayers ? 'visible' : ''}`}
      data-testid="map-layers-panel"
    >
      <Typography className="title" variant="h6" component="h1" align="center">
        Map Layers
      </Typography>
      <div className="map-layers-panel-content">
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5em',
            justifyContent: 'center',
          }}
        >
          {availableLayers?.map(({ code, text }, index) => (
            <div key={index} className="map-layer-item">
              <Button
                className="map-style-button"
                variant="text"
                onClick={() => {
                  toggleLayer(code);
                }}
              >
                <img
                  className="legend"
                  alt={code}
                  src={getLayerImageFromCategory(code)}
                />
              </Button>
              <div
                className={`map-layer-text ${activeLayers.includes(code) ? 'active' : ''}`}
              >
                {text}
              </div>
            </div>
          ))}

          {supportedZoneCodes?.map(({ category, key, name }, index) => (
            <div key={index} className="map-layer-item">
              <Button
                className="map-style-button"
                variant="text"
                onClick={() => {
                  toggleLayer(category);
                }}
              >
                <img
                  className="legend"
                  alt={name}
                  src={getLayerImageFromCategory(key)}
                />
              </Button>
              <div
                className={`map-layer-text ${activeLayers.includes(category) ? 'active' : ''}`}
              >
                {name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

interface MapStylesPanelProps {
  showMapStyles: boolean;
  availableStyles: string[];
  currentMapStyle: string;
  setMapStyle(styles: string): void;
  handleClose(): void;
}

function MapStylesPanel({
  showMapStyles,
  availableStyles,
  currentMapStyle,
  setMapStyle,
  handleClose,
}: MapStylesPanelProps) {
  return (
    <div
      className={`map-styles-panel ${showMapStyles ? 'visible' : ''}`}
      data-testid="map-styles-panel"
    >
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          className="title"
          variant="h6"
          component="h1"
          align="center"
          style={{
            marginLeft: 'auto',
            marginRight: '-20px',
          }}
        >
          Map Style
        </Typography>
        <IconButton
          aria-label="close"
          style={{
            color: '#ccc',
            marginLeft: 'auto',
            marginTop: '-20px',
            marginRight: '-10px',
          }}
          onClick={handleClose}
          data-testid="pilot-web:map:map-styles-panel-close-button"
        >
          <CloseIcon />
        </IconButton>
      </span>
      <div className="map-styles-panel-content">
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5em',
            justifyContent: 'center',
          }}
        >
          {availableStyles?.map((styleName, index) => (
            <div key={index} className="map-style-item">
              <Button
                className="map-style-button"
                variant="text"
                onClick={() => {
                  setMapStyle(styleName);
                }}
              >
                <img
                  className="legend"
                  alt={styleName}
                  src={getImageForStyle(styleName)}
                />
              </Button>
              <div
                className={`map-style-text ${currentMapStyle === styleName ? 'active' : ''}`}
              >
                {styleName}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
