import React from 'react';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import FooterStatusBox from './footer-status-box';

import {
  type ExtendedFlightResponseBodyV2,
  DigitalClearanceStatus,
} from '@airshare/pilot-types';

import { useIsOrgAdmin } from '../../../../state/session/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props {
  handleSubmit: () => void;
  flightRequest: ExtendedFlightResponseBodyV2;
  requestMade: boolean;
  errors: string[];
  isSubmitting: boolean;
  viewEligibility: boolean;
}

const ORG_ADMIN_FLIGHT_UNAVAILABLE_ERROR =
  'Please log in as the nominated pilot for the flight to request digital authorisation';

export const Footer = ({
  handleSubmit,
  flightRequest,
  requestMade,
  errors,
  isSubmitting,
  viewEligibility,
}: Props) => {
  const isOrgAdmin = useIsOrgAdmin();
  const classes = useStyles();

  const {
    allocatedControlZone,
    digitalClearance,
    operatorNotes,
    startDateTime,
    timezone,
    maxAltitudeFeet,
  } = flightRequest.properties;
  const {
    status,
    reason,
    updatedAt,
    nextAllowableDateTime,
    activatablePeriodMinutes,
    expiryPeriodMinutes,
  } = digitalClearance || {};

  const updateDateTime = updatedAt
    ? DateTime.fromJSDate(new Date(updatedAt)).setZone(timezone)
    : DateTime.now().setZone(timezone);
  const flightStartTime = DateTime.fromISO(startDateTime).toUTC();
  const currentTime = DateTime.now().toUTC();

  const notAccessibleYet =
    flightStartTime.minus({ minutes: activatablePeriodMinutes ?? 30 }) >
    currentTime;
  const isTimedOut =
    updateDateTime.toUTC().plus({ minutes: expiryPeriodMinutes ?? 5 }) <
    currentTime;

  const requestableStates =
    status !== DigitalClearanceStatus.CallTower &&
    status !== DigitalClearanceStatus.Authorized;
  const isRequestAllowed =
    (requestMade || updatedAt) && nextAllowableDateTime
      ? DateTime.fromISO(nextAllowableDateTime) < DateTime.now()
      : true;

  const pendingRequest =
    (requestMade || status === DigitalClearanceStatus.Requested) && !isTimedOut;
  const disabledRequest =
    notAccessibleYet ||
    !requestableStates ||
    !isRequestAllowed ||
    pendingRequest ||
    !viewEligibility;

  const renderError = (error: string) => {
    const adjustedError =
      isOrgAdmin && error.includes('for this pilot')
        ? ORG_ADMIN_FLIGHT_UNAVAILABLE_ERROR
        : error;

    return (
      <ListItem style={{ display: 'flex' }} key={adjustedError}>
        <ListItemText
          style={{ margin: 0, color: 'red' }}
          primary={adjustedError}
        />
      </ListItem>
    );
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          disabled={disabledRequest || isSubmitting}
          onClick={handleSubmit}
          data-testid="request-digital-clearance-button"
        >
          Request Activation
        </Button>
        {isSubmitting && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
      <List dense>
        {notAccessibleYet && (
          <ListItem style={{ display: 'flex' }}>
            <ListItemText
              style={{ margin: 0, color: 'gray' }}
              primary={`Digital activation is not available more than ${activatablePeriodMinutes} minutes before a flight's start time`}
            />
          </ListItem>
        )}
        {errors.map(renderError)}
      </List>
      <FooterStatusBox
        status={status}
        reason={reason}
        allocatedControlZone={allocatedControlZone}
        operatorNotes={operatorNotes}
        maxAltitudeFeet={maxAltitudeFeet}
        isTimedOut={isTimedOut}
        isRequestAllowed={isRequestAllowed}
        updateDateTime={updateDateTime}
        expiryPeriodMinutes={expiryPeriodMinutes}
        nextAllowableDateTime={nextAllowableDateTime}
      />
    </>
  );
};
