import {
  FlightPathModeEnum,
  type ValidFlightShape,
  styleKeys,
} from '@airshare/external-api-types';

import {
  DraftFlightAreaSource,
  FlightPlanForm,
  coordinate,
} from '../../../state/flight-plan/flight-plan-form/types';
import {
  overlayToPathMode,
  payloadToPolygon,
  payloadToWaypoints,
} from '../../../lib/polygon-helpers';
import { type Position, type LineString } from '@turf/helpers';

export const updateFlightPlanForm = (
  event: google.maps.drawing.OverlayCompleteEvent,
  setFlightPlanFormField: <K extends keyof FlightPlanForm>(
    field: K,
    value: FlightPlanForm[K]
  ) => void
) => {
  if (event?.type === google.maps.drawing.OverlayType.CIRCLE) {
    const circle = event.overlay as google.maps.Circle;
    const center = circle.getCenter();
    const centerCoordinate = [{ lng: center?.lng(), lat: center?.lat() }];
    const waypoints: LineString | null = null;

    setFlightPlanFormField('flightAreaSource', DraftFlightAreaSource.DRAW);
    setFlightPlanFormField('pathMode', FlightPathModeEnum.CIRCLE);
    setFlightPlanFormField('radius', circle.getRadius());
    setFlightPlanFormField('coordinates', centerCoordinate);
    setFlightPlanFormField('waypoints', waypoints);
  } else if (event?.type) {
    const pathMode = overlayToPathMode(event?.type);
    const polygon = payloadToPolygon(event);
    const coordinates = getCoordinatesFromPolygon(polygon?.coordinates);
    const waypoints = payloadToWaypoints(event);

    setFlightPlanFormField('flightAreaSource', DraftFlightAreaSource.DRAW);
    setFlightPlanFormField('pathMode', pathMode);
    setFlightPlanFormField('radius', 0);
    setFlightPlanFormField('coordinates', coordinates);
    setFlightPlanFormField('waypoints', waypoints);
  }
};

const getCoordinatesFromPolygon = (polygonShape: Position[][]): coordinate[] =>
  polygonShape ? polygonShape[0].map(([lng, lat]) => ({ lng, lat })) : null;

export const eventToValidFlightShape = (
  event: google.maps.drawing.OverlayCompleteEvent
): ValidFlightShape => {
  if (event.type === google.maps.drawing.OverlayType.POLYGON)
    return event.overlay as google.maps.Polygon;
  if (event.type === google.maps.drawing.OverlayType.CIRCLE)
    return event.overlay as google.maps.Circle;
  return null;
};

export const addEditListeners = (
  path: ValidFlightShape,
  callback: () => void
) => {
  if ('getRadius' in path) {
    return [
      google.maps.event.addListener(path, 'center_changed', callback),
      google.maps.event.addListener(path, 'radius_changed', callback),
      google.maps.event.addListener(path, 'distance_changed', callback),
    ];
  } else {
    return [
      google.maps.event.addListener(path.getPath(), 'insert_at', callback),
      google.maps.event.addListener(path.getPath(), 'set_at', callback),
    ];
  }
};

export const getStyleForFeature = (feature: google.maps.Data.Feature) =>
  styleKeys.reduce(
    (acc1, prop) => ({
      ...acc1,
      [prop]: feature.getProperty(prop),
    }),
    {
      clickable: false,
      editable: false,
      draggable: false,
    }
  );
