import { DateTime } from 'luxon';
import {
  FRStatusCode,
  PilotFacingStatusCode,
  PilotOnlyStatusCode,
} from '@airshare/external-api-types';

import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

export const isFlightEditable = (flight?: ExtendedFlightResponseBodyV2) => {
  const {
    digitalClearance,
    requiredAuthChanges,
    startDateTime,
    segmentCollection,
  } = flight?.properties || {};

  if (segmentCollection) {
    // editing of segmented flights not supported yet
    return false;
  }

  if (startDateTime) {
    const flightStart: DateTime = DateTime.fromISO(startDateTime);
    if (flightStart.minus({ minutes: 30 }) < DateTime.now()) {
      return false;
    }
    if (requiredAuthChanges?.find((change) => change.isAcknowledged !== true)) {
      return false;
    }
    return digitalClearance?.status !== 'Requested';
  }
  return false;
};

export const statusesOverridenByAuthChanges = [
  FRStatusCode.Authorized,
  FRStatusCode.ProvAuth,
];

export const getOverallPilotFacingStatus = (
  flight?: ExtendedFlightResponseBodyV2
): PilotFacingStatusCode => {
  const overall = flight?.properties?.status;
  return overridenStatus(overall, flight);
};

export const getPilotFacingStatusForAuth = (
  authorizerName: string,
  flight?: ExtendedFlightResponseBodyV2
): PilotFacingStatusCode => {
  const authStatus = flight?.properties?.allStatuses?.find(
    (status) => status.authorizer === authorizerName
  );
  return overridenStatus(authStatus?.code, flight, authorizerName);
};

const overridenStatus = (
  status?: FRStatusCode | 'UNKNOWN',
  flight?: ExtendedFlightResponseBodyV2,
  authorizerName?: string
): PilotFacingStatusCode => {
  if (!status || status === 'UNKNOWN') {
    return undefined;
  }

  if (
    statusesOverridenByAuthChanges.includes(status) &&
    flight?.properties?.requiredAuthChanges?.find(
      (change) =>
        change.isAcknowledged !== true &&
        (!authorizerName || change.authorizerName === authorizerName)
    )
  ) {
    return PilotOnlyStatusCode.AcceptanceReq;
  }

  return status;
};
