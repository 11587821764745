import React from 'react';
import { SplashPage } from './splash-page';

export function Offline() {
  return (
    <SplashPage
      primaryText="You appear to be offline"
      secondaryText="Please reconnect to the internet to use this app."
    />
  );
}
