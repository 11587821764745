import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import type { FeatureCollection, Polygon } from '@turf/helpers';

import TopBanner from '../top-banner/top-banner';
import SurveillanceLatencyDisplay from './surveillance-latency-display';

import {
  useMapHandlers,
  MapControls,
  Search,
  MapHelpers,
  useCenterOnUserLocation,
  useMapLayers,
  useNoaLayer,
  useMapPoints,
  useMapStyleChange,
  useMap,
  getDefaultMapStyles,
  CustomSlider,
} from 'airshare-pilot-web-shared';

import { AdvisoryModal } from '../shared/advisory-modal/advisory-modal';
import { AdvisorySource } from '@airshare/pilot-types';

import { FRStatusCode } from '@airshare/external-api-types';

import { useFocussedFlightBriefingMapOrientation } from './hooks/focussed-flight-briefing-map-orientation.hook';
import { useDynamicDrawingManager } from './hooks/dynamic-drawing-manager.hook';
import { useAdaptivePan } from './hooks/adaptive-pan.hook';
import { useNotamsMarkers } from './hooks/use-notams-markers.hook';
import { useMapLayersStack } from './hooks/use-map-layers-stack.hook';
import { useHighlightActiveArea } from './hooks/use-highlight-active-area.hook';

import {
  useActivatedFlightRequests,
  useFocussedFlightRequest,
} from '../../state/flight-requests/hooks';
import { useFormState } from '../../state/flight-plan/flight-plan-form/hooks';

import { useRenderEditFlightArea } from './hooks/render-edit-flight-area.hook';
import { useRenderDraftFlightPlanArea } from './hooks/use-render-draft-flight-plan-area.hook';
import { useFlightGeojson } from './hooks/use-flight-geojson';
import { useFlightMarkers } from './hooks/use-flight-markers';
import { useMapSurveillance } from './hooks/surveillance.hook';
import { useFocussedNotamMapOrientation } from './hooks/focussed-notam-map-orientation.hook';
import { useRenderFlightAdvisories } from './hooks/use-render-flight-advisories.hook';

import { useDraggablePin } from './hooks/use-draggable-pin.hook';
import { useCloseDetailPanel } from '../shared/hooks/close-detail-panel.hook';
import { useNavigateTo } from '../shared/hooks/router.hook';
import { LayerLegendModal } from '../shared/layer-legend-modal/layer-legend-modal';
import {
  ADVISORY_MODAL_PATHS,
  ADVISORY_MOVABLE_PIN_PATHS,
  AIRSPACE_ACTIVITY_PATH,
  getNotamDetailPath,
} from '../../routes';
import { useIsFocussedOnAFeature } from './hooks/is-focussed-on-a-feature.hook';
import { useSurveillanceLatency } from '../../websocket/use-pilot-surveillance-latency';

import {
  getCenterOnPilot,
  getInitLat,
  getInitLng,
  getInitZoom,
  useAppConfig,
} from '../../state/app-config';
import {
  useFlightAdvisories,
  useHighlightedFlightAdvisories,
  useHighlightedFlightAdvisoriesUpdated,
} from '../../state/flight-advisories/hooks';
import { usePreValidateFlightArea } from '../../state/flight-plan/pre-validate-flight-area/hooks';
import { GlobalState } from '../../state/GlobalState';
import { useProfile } from '../../state/profile/hooks';
import { PreValidateFlightAreaPayloadFailed } from '~/state/flight-plan/pre-validate-flight-area/actions';
import { FlightRule } from 'argus-common/enums';
import { AvailableLayer } from 'airshare-pilot-web-shared/src/components/map-controls/map-controls';
import AdditionalDrawingButtons from './drawing/additional-drawing-buttons';

import configuration from './drawing/configuration';

import './map-screen.scss';

import './drawing/line-drawing.scss';
import { getStyleForFeature } from './hooks/helpers';

//List of layers that can be toggled on and off, type could be category, code or keyword
const availableLayers: AvailableLayer[] = [
  {
    text: 'Ground Advisories',
    code: 'council',
    type: 'category',
    ignoreCode: ['hang-gliding', 'parachuting'],
  },
];

const defaultActiveLayers = [
  'ctr',
  'moa',
  'ad',
  'd',
  'lfz',
  'r',
  'nfz',
  'tl',
  ...availableLayers.map((x) => x.code),
];

const MapScreen = () => {
  const appConfig = useAppConfig();
  const google = window.google;
  const profile = useProfile();
  const form = useFormState();
  const { pathname } = useLocation();

  const isAirspaceActivityPath = matchPath(pathname, {
    path: AIRSPACE_ACTIVITY_PATH,
    exact: true,
  })?.isExact;
  const centerOnPilot = getCenterOnPilot(appConfig) ?? true;
  const initLat =
    getInitLat(appConfig) ?? Number.parseFloat(window.env.INITIAL_LAT);
  const initLng =
    getInitLng(appConfig) ?? Number.parseFloat(window.env.INITIAL_LNG);
  const initZoom = getInitZoom(appConfig) ?? 6;
  const surveillanceIsEnabled =
    window.env.PILOT_WEB_SURVEILLANCE_ENABLED?.toLowerCase() === 'true';
  const allowSegmentedFlights =
    window.env.SEGMENTED_DRONE_FLIGHTS_ENABLED === 'true' &&
    form.rule === FlightRule.PART_102;

  const [hasPolylineOverlay, setHasPolylineOverlay] =
    useState<FeatureCollection<Polygon> | null>(null); // Geojson used rather than boolean so that useEffects detects change in area

  //List of layers to show by default
  const [activeLayers, setActiveLayers] =
    useState<string[]>(defaultActiveLayers);
  const [userUnselectedLayers, setUserUnselectedLayers] = useState<string[]>(
    []
  );
  const [userSelectedLayers, setUserSelectedLayers] = useState<string[]>([]);
  const [resetSearchInput, setResetSearchInput] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const {
    mapBounds: globalMapBounds,
    setMapBounds,
    mapStyleCode,
    setMapStyleCode,
    selectedAdvisory,
    hoveredOverAdvisory,
    advisoryGeometrySource,
  } = useContext(GlobalState);

  const [drawingManagerInstance, setDrawingManagerInstance] =
    useState<google.maps.drawing.DrawingManager | null>(null);
  const [center] = useState({
    lat: initLat,
    lng: initLng,
  });
  const [surveillanceData, avLatency, messages, liveFlightsAreUnavailable] =
    useSurveillanceLatency();
  const { validationResult: flightAreaValidationResult } =
    usePreValidateFlightArea();
  const navigateTo = useNavigateTo();
  const focussedFlightBriefing = useFocussedFlightRequest();
  const activatedFlights = useActivatedFlightRequests();
  const isFocussedOnAFeature = useIsFocussedOnAFeature(pathname);
  const [mapListeners, setMapListeners] = useState<
    google.maps.MapsEventListener[]
  >([]);

  const flightAdvisories = useFlightAdvisories();
  const highlightedFlightAdvisories = useHighlightedFlightAdvisories();

  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();

  const handleMapClick = useCallback(
    (event: google.maps.MapMouseEvent): void => {
      setClickPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    },
    []
  );

  const handleMapReady = useCallback(
    (safeMap: google.maps.Map) => {
      if (safeMap) {
        handleInitialInteraction();

        const { drawingManagerConfiguration } = configuration(
          google,
          allowSegmentedFlights
        );

        const drawingManager = new google.maps.drawing.DrawingManager(
          drawingManagerConfiguration
        );
        drawingManager.setMap(safeMap);

        setDrawingManagerInstance(drawingManager);

        const l5 = google.maps.event.addListener(
          safeMap,
          'click',
          handleMapClick
        );
        setMapListeners([l5]);
      }
    },
    [
      handleMapClick,
      allowSegmentedFlights,
      // @ts-ignore
      handleInitialInteraction,
      google,
    ]
  );

  const defaultMapOptions = {
    center,
    zoom: initZoom || null,
    onReady: handleMapReady,
    disableDefaultUI: true,
    mapId: '361d5174b74ea5ce', // FIMS main dark vector map
    gestureHandling: 'greedy',
    ...getDefaultMapStyles(mapStyleCode),
  };

  const mapRef = useRef();
  const [map, rerenderMap, mapBounds, mapCenter, setMapCenter] = useMap(
    google,
    mapRef,
    defaultMapOptions
  );

  const flightDataLayer: google.maps.Data | null = useMemo(() => {
    if (map) {
      const layer = new google.maps.Data({ map });
      layer.setStyle(getStyleForFeature);
      return layer;
    }
    return null;
  }, [google.maps.Data, map]);

  useHighlightActiveArea(pathname, profile?.areaOfVisibility, map, google);

  useMemo(() => {
    const { drawingManagerConfiguration } = configuration(
      google,
      allowSegmentedFlights
    );

    if (drawingManagerInstance) {
      const currentMode = drawingManagerInstance.get('drawingControl');
      drawingManagerInstance.setOptions({
        ...drawingManagerConfiguration,
        drawingControl: currentMode,
      });
    }
  }, [allowSegmentedFlights, drawingManagerInstance, google]);

  const mapHelpers = new MapHelpers(google, map);

  const [
    { userMarker, zoom, intialMapInteractionDetected },
    {
      handleCenterPositionClick,
      handlePlaceSearchSelection,
      handleInitialInteraction,
      handleMapZoom,
      setUserMarker,
      setZoom,
    },
  ] = useMapHandlers(google, map, initZoom, mapHelpers);

  useEffect(() => {
    if (mapBounds && zoom) {
      const neBound = mapBounds.getNorthEast();
      const swBound = mapBounds.getSouthWest();

      const ne = `${neBound.lng()},${neBound.lat()}`;
      const sw = `${swBound.lng()},${swBound.lat()}`;

      setMapBounds({
        ne,
        sw,
        zoom,
      });
    }
  }, [globalMapBounds, mapBounds, zoom, setMapBounds]);

  const [, handleMapStyleChange] = useMapStyleChange(
    map,
    initZoom || null,
    handleMapReady,
    rerenderMap,
    setMapStyleCode,
    mapStyleCode
  );

  const closeDetailPanel = useCloseDetailPanel();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotamLayer, setShowNotamLayer] = useState(false);

  useAdaptivePan(map, pathname, mapCenter);
  useFlightGeojson(flightDataLayer, hasPolylineOverlay);
  useFlightMarkers(google, map, hasPolylineOverlay);
  useMapLayers(
    google,
    map,
    zoom,
    selectedAdvisory,
    hoveredOverAdvisory,
    activeLayers,
    availableLayers
  );
  useNoaLayer(google, map);
  useMapPoints(google, map, zoom);
  const { updatePin, handleCollapseAdvisoryModal } = useDraggablePin(
    google,
    map,
    pathname,
    setResetSearchInput
  );

  useCenterOnUserLocation(
    google,
    map,
    intialMapInteractionDetected,
    userMarker,
    setUserMarker,
    mapHelpers,
    () => {},
    isFocussedOnAFeature,
    16,
    !centerOnPilot
  );

  useDynamicDrawingManager(
    map,
    flightDataLayer,
    drawingManagerInstance,
    hasPolylineOverlay,
    setHasPolylineOverlay,
    allowSegmentedFlights
  );

  useFocussedFlightBriefingMapOrientation(
    google,
    map,
    pathname,
    userMarker,
    mapHelpers,
    setMapCenter
  );

  const { notams } = useNotamsMarkers(
    google,
    map,
    showNotamLayer,
    setShowNotamLayer,
    pathname,
    (id) => navigateTo(getNotamDetailPath(id))
  );

  const { handleFilterMapLayers } = useMapLayersStack(google, map);

  const showNotamLayerFocussed = useCallback(
    (showNotam: boolean) => {
      if (
        showNotam &&
        !userUnselectedLayers.includes('notam') &&
        !showNotamLayer &&
        !activeLayers.includes('notam')
      ) {
        setActiveLayers([...activeLayers, 'notam']);
        setShowNotamLayer(true);
      }
      if (showNotam && showNotamLayer && userSelectedLayers.includes('notam')) {
        setUserSelectedLayers(userSelectedLayers.filter((x) => x !== 'notam'));
      }
      if (
        !showNotam &&
        !showNotamLayer &&
        userUnselectedLayers.includes('notam')
      ) {
        setUserUnselectedLayers(
          userUnselectedLayers.filter((x) => x !== 'notam')
        );
      }
      if (
        !showNotam &&
        showNotamLayer &&
        !userSelectedLayers.includes('notam')
      ) {
        setActiveLayers(activeLayers.filter((x) => x !== 'notam'));
        setShowNotamLayer(false);
      }
    },
    [activeLayers, showNotamLayer, userSelectedLayers, userUnselectedLayers]
  );

  useFocussedNotamMapOrientation(
    google,
    map,
    pathname,
    setMapCenter,
    notams,
    showNotamLayerFocussed
  );

  useMapSurveillance(google, map, surveillanceData, zoom);

  useRenderDraftFlightPlanArea(google, map, pathname);

  useRenderEditFlightArea(google, map);

  function handleLayerLegendModalButtonClick() {
    setIsModalOpen((prevState) => !prevState);
  }

  const [clickPosition, setClickPosition] =
    useState<google.maps.LatLngLiteral | null>();

  useEffect(() => {
    if (google && map && setZoom) {
      const zoomListener = google.maps.event.addListener(
        map,
        'zoom_changed',
        () => {
          setZoom(map.getZoom() || initZoom);
        }
      );

      return () => google.maps.event.removeListener(zoomListener);
    }
  }, [map, setZoom, initZoom, google]);

  useEffect(() => {
    if (map && clickPosition?.lat) {
      updatePin(map, clickPosition?.lat, clickPosition?.lng);
    }
  }, [clickPosition, map, updatePin]);

  useRenderFlightAdvisories(
    google,
    map,
    flightAdvisories,
    highlightedFlightAdvisories,
    setHighlightedNearbyFlights,
    mapHelpers
  );

  const onCenterPositionClick = () =>
    handleCenterPositionClick(() => map?.setCenter(userMarker));

  const onSearch = useCallback(
    (selection: any) => {
      handlePlaceSearchSelection(selection, closeDetailPanel);

      if (!selection) {
        return;
      }

      if (
        matchPath(pathname, { path: ADVISORY_MOVABLE_PIN_PATHS, exact: true })
          ?.isExact
      ) {
        const { geometry, name } = selection;
        const { lat, lng } = geometry.location;
        const latLng = { lat: lat(), lng: lng() };

        updatePin(map, latLng.lat, latLng.lng, name);
      }
    },
    [closeDetailPanel, handlePlaceSearchSelection, map, pathname, updatePin]
  );

  const onResetSearchInput = useCallback(() => {
    if (advisoryGeometrySource === AdvisorySource.Pin) {
      updatePin(map, null, null);
    }
  }, [advisoryGeometrySource, map, updatePin]);

  const onSearchOpened = (opened: boolean) => {
    setSearchIsOpen(opened);
  };

  useEffect(() => {
    return () => {
      mapListeners.forEach((l) => google.maps.event.removeListener(l));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAdvisoryModal =
    matchPath(pathname, { path: ADVISORY_MODAL_PATHS, exact: true })?.isExact ||
    false;

  const focusedFlightStatus = focussedFlightBriefing?.properties?.status;
  // Focused flight is refreshed more frequently than the activated flights
  const pilotsOwnFlights = activatedFlights
    ?.filter((x) => x && x?.properties?.pilotId === profile?.pilot?.result?.id)
    ?.filter(
      (x) =>
        x.properties.flightId !== focussedFlightBriefing?.properties?.flightId
    )
    .concat(
      focusedFlightStatus === FRStatusCode.Activated
        ? focussedFlightBriefing
        : []
    );

  const mapHeight = pilotsOwnFlights?.length ? 'calc(100% - 3rem)' : '100%';

  return (
    <>
      {!!pilotsOwnFlights?.length && (
        <div
          id="map-top-panel"
          style={{
            display: 'relative',
            top: '0rem',
            height: '3rem',
            width: '100%',
            backgroundColor: '#1976d2', // --color-highlight-blue
          }}
        >
          <TopBanner
            flightIds={pilotsOwnFlights.map((x) => x.properties?.flightId)}
            requestToLand={Object.assign(
              {},
              ...pilotsOwnFlights.map((x) => ({
                [x.properties?.flightId]:
                  x.properties?.additionalInfo?.requestToLand,
              }))
            )}
            unacknowledgedAlerts={Object.assign(
              {},
              ...pilotsOwnFlights.map((x) => ({
                [x.properties?.flightId]:
                  x.properties?.additionalInfo?.requestsToPilot?.some(
                    (r) =>
                      r.type === 'ConformanceAlert' &&
                      r.status !== 'Acknowledged'
                  ),
              }))
            )}
            secondaryBannerIsVisible={liveFlightsAreUnavailable}
          />
        </div>
      )}
      <div
        className={classnames(
          'map__wrapper',
          searchIsOpen ? 'search-is-open' : '',
          allowSegmentedFlights ? 'segmented-flights-enabled' : ''
        )}
        data-testid="map-wrapper"
        style={{ position: 'relative', width: '100%', height: mapHeight }}
      >
        <div
          style={{ position: 'relative', width: '100%', height: '100%' }}
          className="map__container"
          ref={mapRef}
          data-testid="map-container"
        />
        {(flightAreaValidationResult as PreValidateFlightAreaPayloadFailed)
          ?.message && (
          <div className="validation">
            <div className="flight-area-validation">
              <p
                className="validation-message"
                data-testid="pilot-web:map-screen:flight-area-validation-message"
              >
                {
                  (
                    flightAreaValidationResult as PreValidateFlightAreaPayloadFailed
                  ).message
                }
              </p>
            </div>
          </div>
        )}

        <MapControls
          handleLayerLegendModalButtonClick={handleLayerLegendModalButtonClick}
          handleMapStyleChange={handleMapStyleChange}
          mapStyle={mapStyleCode}
          handleCenterPositionClick={onCenterPositionClick}
          handleMapZoomIn={() => handleMapZoom(1)}
          handleMapZoomOut={() => handleMapZoom(-1)}
          supportedZoneCodes={appConfig?.supportedZoneCodes.filter(
            (r) => r.category === 'notam'
          )}
          activeLayers={activeLayers}
          availableLayers={availableLayers}
          toggleLayer={(layerCode: string) => {
            const newActiveLayers = [...activeLayers];
            if (!newActiveLayers.includes(layerCode)) {
              // Adding
              newActiveLayers.push(layerCode);
              setUserUnselectedLayers(
                userUnselectedLayers.filter((x) => x !== layerCode)
              );
              setUserSelectedLayers([...userSelectedLayers, layerCode]);
            } else {
              // Removing
              newActiveLayers.splice(newActiveLayers.indexOf(layerCode), 1);
              setUserUnselectedLayers([...userUnselectedLayers, layerCode]);
              setUserSelectedLayers(
                userSelectedLayers.filter((x) => x !== layerCode)
              );
            }
            setActiveLayers(newActiveLayers);

            if (newActiveLayers.includes('notam')) {
              setShowNotamLayer(true);
            } else {
              setShowNotamLayer(false);
            }
          }}
        />
        {isAirspaceActivityPath && (
          <CustomSlider handleFilterMapLayers={handleFilterMapLayers} />
        )}
        {map && (
          <Search
            className="map-search"
            google={google}
            placesService={new google.maps.places.PlacesService(map)}
            onChange={onSearch}
            resetSearchInput={resetSearchInput}
            onResetInput={onResetSearchInput}
            onOpened={onSearchOpened}
            mapCenter={
              new google.maps.LatLng(
                map?.getCenter().lat(),
                map?.getCenter().lng()
              )
            }
          />
        )}

        {surveillanceIsEnabled && (
          <SurveillanceLatencyDisplay
            avgLatencyMs={avLatency}
            messages={messages}
            liveFlightsAreUnavailable={liveFlightsAreUnavailable}
          />
        )}

        {showAdvisoryModal && (
          <AdvisoryModal onCollapseModal={handleCollapseAdvisoryModal} />
        )}

        <AdditionalDrawingButtons
          allowSegmentedFlights={allowSegmentedFlights}
        />
        {isModalOpen && (
          <LayerLegendModal
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            config={appConfig?.supportedZoneCodes}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(MapScreen);
