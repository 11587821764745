import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Props {
  warnings: string[];
}

export default function CriticalInfoSection({ warnings }: Props) {
  return (
    <>
      {warnings.filter((x) => x)?.length > 0 ? (
        <Alert severity="warning" className="critical-info-box">
          {warnings
            .filter((x) => x)
            .map((warning, ndx) => (
              <Typography
                key={`critical-info-text-${ndx}`}
                className="critical-info-text"
                variant="subtitle1"
                data-testid={`pilot-web:flight-briefing-panel:critical-info-${ndx}`}
              >
                {warning}
              </Typography>
            ))}
        </Alert>
      ) : undefined}
    </>
  );
}
