export enum FlightPlanValidationStatus {
  IDLE = 'IDLE',
  FLIGHT_PLAN_VALIDATION_IN_PROGRESS = 'FLIGHT_PLAN_VALIDATION_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FlightPlanCreationStatus {
  IDLE = 'IDLE',
  FLIGHT_PLAN_CREATION_IN_PROGRESS = 'FLIGHT_PLAN_CREATION_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FlightPlanEditStatus {
  REQUESTED = 'REQUESTED',
}

export enum FlightPlanUpdateStatus {
  IDLE = 'IDLE',
  FLIGHT_PLAN_UPDATE_IN_PROGRESS = 'FLIGHT_PLAN_UPDATE_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum PathMode {
  POLYGON = 'Polygon',
  CIRCLE = 'Circle',
  SEGMENTED = 'Segmented',
}
