import { useState } from 'react';
import { LatLng } from '@airshare/external-api-types';

import { MapHelpers } from '../../lib';
import { PlaceSelection } from '../../components/search/search';

interface MapState {
  zoom: number;
  isLayerLegendOpen: boolean;
  userMarker: LatLng;
  intialMapInteractionDetected: boolean;
  mapHelpers: unknown;
  placesServiceInstance: unknown;
}

interface MapHandlers {
  handleCenterPositionClick: (callback?: () => void) => void;
  handleMapZoom: (step: number) => void;
  manualSetZoom: (value: number) => void;
  handlePlaceSearchSelection: (
    selection: PlaceSelection,
    callback?: () => void
  ) => void;
  handleInitialInteraction: (callback?: () => void) => void;
  setIsLayerLegendOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPlacesServiceInstance: React.Dispatch<any>;
  setUserMarker: React.Dispatch<React.SetStateAction<LatLng>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  setIdleListener: (event: any) => void;
  animationPrep: () => void;
}

export function useMapHandlers(
  google: typeof window.google,
  map: google.maps.Map,
  initialZoom: number | null,
  mapHelpers: MapHelpers | null
): [MapState, MapHandlers] {
  const [zoom, setZoom] = useState<number | null>(initialZoom);
  const [isLayerLegendOpen, setIsLayerLegendOpen] = useState(false);
  const [userMarker, setUserMarker] = useState<LatLng>(null);
  const [intialMapInteractionDetected, setIntialMapInteractionDetected] =
    useState(false);
  const [placesServiceInstance, setPlacesServiceInstance] = useState(null);
  const [idleListener, setIdleListener] = useState(null);

  let lastEvent: number;
  const duration = 1000;
  function eventStoppedFiring(callback: Function) {
    if (lastEvent + duration <= new Date().getTime()) {
      setIntialMapInteractionDetected(true);
      if (callback) {
        callback();
      }
    }
  }

  function delayCallback(callback: Function) {
    lastEvent = new Date().getTime();
    setTimeout(() => {
      eventStoppedFiring(callback);
    }, duration);
  }

  function handleInitialInteraction(callback?: Function): void {
    if (google && map) {
      google.maps.event.addListenerOnce(map, 'idle', () => {
        google.maps.event.addListenerOnce(map, 'click', () => {
          setIntialMapInteractionDetected(true);
          if (callback) {
            delayCallback(callback);
          }
        });

        google.maps.event.addListenerOnce(
          map,
          'bounds_changed',
          // @ts-ignore
          // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
          delayCallback(callback)
        );
      });
    }
  }

  function handleCenterPositionClick(callback?: () => void) {
    if (userMarker) {
      animationPrep();
      mapHelpers?.smoothlyAnimatePanTo(
        new google.maps.LatLng(userMarker),
        () => {
          mapHelpers.animateMapZoomTo(16, callback);
        }
      );
    }
  }

  function handleMapZoom(step: number) {
    const nextZoom = map.getZoom() + step;

    map.setZoom(nextZoom);
    setZoom(nextZoom);
  }

  function manualSetZoom(value: number) {
    map.setZoom(value);
    setZoom(value);
  }

  function handlePlaceSearchSelection(
    selection: PlaceSelection,
    callback?: () => void
  ) {
    if (selection?.geometry) {
      animationPrep();

      if (callback) {
        callback();
      }

      mapHelpers?.smoothlyAnimatePanTo(selection.geometry.location, () => {
        mapHelpers.animateMapZoomTo(16);
      });
    }
  }

  function animationPrep() {
    if (idleListener) {
      google.maps.event.removeListener(idleListener);
      setIdleListener(null);
    }
  }

  const mapState = {
    zoom,
    isLayerLegendOpen,
    userMarker,
    intialMapInteractionDetected,
    mapHelpers,
    placesServiceInstance,
  };

  const mapHandlers = {
    handleCenterPositionClick,
    handleMapZoom,
    manualSetZoom,
    handlePlaceSearchSelection,
    handleInitialInteraction,
    setIsLayerLegendOpen,
    setPlacesServiceInstance,
    setUserMarker,
    setZoom,
    setIdleListener,
    animationPrep,
  };

  return [mapState, mapHandlers];
}
