import React, { useCallback, useEffect, useState } from 'react';
import type { Polygon } from '@turf/helpers';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch, matchPath } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import Div100vh from 'react-div-100vh';
import { loadReCaptcha } from 'react-recaptcha-google';
import { IntercomProvider } from 'react-use-intercom';
import type { Socket } from 'socket.io-client';

import { ErrorBoundary } from 'common-ui-components';
import history from 'airshare-web-utils/history';
import {
  ErrorPage,
  DownloadMobileApp,
  LandscapeOnly,
  Offline,
  theme,
  MapBounds,
  MapStyleCode,
  useMobileDetection,
  useIsOffline,
} from 'airshare-pilot-web-shared';
import { AdvisorySource, PilotAlert } from '@airshare/pilot-types';

import type { LatLng } from '@airshare/external-api-types';

import type { NearbyActivatedFlightData } from 'argus-events/interfaces';

import { DetailPanel } from '../detail-panel/detail-panel';
import { LeftAside } from '../left-aside/left-aside';
import { AuthModal, AUTH_PATH } from '../auth-modal/auth-modal';
import { InfoModal } from '../shared/info-modal/info-modal';
import {
  DEFAULT_ROUTE,
  CLEARANCE_PATH,
  LOGIN_PATH,
  MANAGE_ORGS_PATH,
  EDIT_APP_DEFAULTS_PATH,
  MANAGE_PILOT_USERS_PATH,
  EDIT_PILOT_USER_PATH,
} from '../../routes';
import { TrackedRoute } from '../shared/tracked-route/tracked-route';
import MapLoading from '../map-screen/map-loading';

import './layout.scss';
import { ManageOrganisations } from '../pages/organisations/manage/manage-organisations';
import { EditAppDefaults } from '../pages/config/edit-app-defaults';
import { ManagePilotUsers } from '../pages/pilots/manage-pilot-users';
import { EditPilotUser } from '../pages/pilots/edit-pilot-user';
import { GlobalState } from '../../state/GlobalState';
import { NotificationState } from '../../state/NotificationState';
import { NearbyNotifcation } from '../shared/nearby-notification/nearby-notification';
import { defaultBufferMetres } from '~/lib/polygon-helpers';

const mobileAccessiblePaths = [CLEARANCE_PATH, LOGIN_PATH];

export default function App() {
  const isMobile = useMobileDetection();
  const isOffline = useIsOffline();

  const [mapBounds, setMapBounds] = useState<MapBounds | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLng | null>(null);
  const [selectedAdvisory, setSelectedAdvisory] = useState<any | null>(null);
  const [hoveredOverAdvisory, setHoveredOverAdvisory] = useState<any | null>(
    null
  );
  const [hideContentPanel, setHideContentPanel] = useState(false);

  const [mapLayers, setMapLayers] = useState<Map<string, any>>(
    new Map<string, any>()
  );

  const MapFunctions = {
    addMapLayer: (name: string, layer: any) => {
      setMapLayers(new Map(mapLayers.set(name, layer)));
    },
    setMapLayerVisibility: (name: string, visibility: boolean) => {
      mapLayers.get(name).visibility = visibility;
      setMapLayers(new Map(mapLayers));
    },
  };

  const setMapBoundsOnlyOnChange: React.Dispatch<
    React.SetStateAction<MapBounds | null>
  > = function (mapBoundsUpdated: MapBounds) {
    if (
      !mapBounds ||
      mapBounds.ne != mapBoundsUpdated.ne ||
      mapBounds.sw != mapBoundsUpdated.sw ||
      mapBounds.zoom != mapBoundsUpdated.zoom
    ) {
      setMapBounds(mapBoundsUpdated);
    }
  };
  const setMapCenterOnlyOnChange: React.Dispatch<
    React.SetStateAction<LatLng | null>
  > = function (mapCenterUpdated: LatLng) {
    if (mapCenter != mapCenterUpdated) {
      setMapCenter(mapCenterUpdated);
    }
  };

  const [mapStyleCode, setMapStyleCode] = useState<MapStyleCode>(
    (localStorage.getItem('MapStyleCode') as MapStyleCode) ??
      MapStyleCode.Standard
  );

  const updateMapStyleCode = (value: MapStyleCode) => {
    localStorage.setItem('MapStyleCode', value);
    setMapStyleCode(value);
  };

  const [advisoryGeometry, setAdvisoryGeometryInternal] =
    useState<Polygon | null>(null);
  const [advisoryGeometrySource, setAdvisoryGeometrySource] =
    useState<AdvisorySource>(AdvisorySource.None);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [surveillanceCenterPoint, setSurveillanceCenterPoint] =
    useState<LatLng | null>(null);
  const [highlightedSegments, setHighlightedSegments] = useState<
    string[] | null
  >([]);
  const [showMyFlightMarkers, setShowMyFlightMarkers] = useState<boolean>(true);

  const setAdvisoryGeometry = useCallback(
    (src: AdvisorySource, geo: Polygon | null) => {
      setAdvisoryGeometryInternal(geo);
      setAdvisoryGeometrySource(src);
    },
    [setAdvisoryGeometrySource, setAdvisoryGeometryInternal]
  );

  const [alerts, setAlerts] = useState<Record<string, PilotAlert[]> | null>(
    null
  );

  const setAlert = useCallback(
    ({ messageId, payload }: NearbyActivatedFlightData) => {
      const newAlerts = {
        [messageId]: payload.alerts,
      };
      if (JSON.stringify(newAlerts) !== JSON.stringify(alerts)) {
        setAlerts(newAlerts);
      }
    },
    [alerts, setAlerts]
  );

  const clearAlerts = useCallback(() => setAlerts(null), [setAlerts]);

  const [segmentedBufferRadius, setSegmentedBufferRadius] = useState(
    defaultBufferMetres()
  );

  const getNotificationValue = useCallback(
    () => ({ alerts, setAlert, clearAlerts }),
    [alerts, setAlert, clearAlerts]
  );

  const resetGlobalState = useCallback(() => {
    setAdvisoryGeometryInternal(null);
    setAdvisoryGeometrySource(AdvisorySource.None);
    setHoveredOverAdvisory(null);
    setSelectedAdvisory(null);
    setHighlightedSegments([]);
    setShowMyFlightMarkers(true);
    setSegmentedBufferRadius(defaultBufferMetres());
  }, [
    setAdvisoryGeometryInternal,
    setHoveredOverAdvisory,
    setSelectedAdvisory,
  ]);

  useEffect(() => {
    loadReCaptcha();
  }, []);

  useEffect(() => {
    const NO_LEFT_PANEL_PATHS = ['/airspace-activity'];
    const unlisten = history.listen(({ pathname }: { pathname: string }) => {
      if (NO_LEFT_PANEL_PATHS.includes(pathname)) {
        setHideContentPanel(true);
      } else {
        setHideContentPanel(false);
      }
    });
    if (NO_LEFT_PANEL_PATHS.includes(history.location.pathname)) {
      setHideContentPanel(true);
    }
    return () => {
      unlisten();
    };
  }, []);

  const isMobileAccessible = mobileAccessiblePaths.some((p) =>
    matchPath(history.location.pathname, { path: p, exact: true })
  );

  // Make sure all React hooks are defined before early return
  if (isOffline) return <Offline />;
  if (isMobile && !isMobileAccessible) return <DownloadMobileApp />;

  return (
    <ConnectedRouter history={history}>
      <ErrorBoundary customErrorPage={ErrorPage}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <IntercomProvider
              appId={window.env.INTERCOM_APP_ID || 'test'}
              autoBoot
              autoBootProps={{
                verticalPadding: 46,
                horizontalPadding: 12,
              }}
            >
              <GlobalState.Provider
                value={{
                  mapBounds,
                  setMapBounds: setMapBoundsOnlyOnChange,
                  mapCenter,
                  setMapCenter: setMapCenterOnlyOnChange,
                  selectedAdvisory,
                  setSelectedAdvisory,
                  hoveredOverAdvisory,
                  setHoveredOverAdvisory,
                  mapStyleCode,
                  setMapStyleCode: updateMapStyleCode,
                  advisoryGeometry,
                  setAdvisoryGeometry,
                  advisoryGeometrySource,
                  highlightedSegments,
                  setHighlightedSegments,
                  showMyFlightMarkers,
                  setShowMyFlightMarkers,
                  resetGlobalState,
                  mapLayers,
                  addMapLayer: MapFunctions.addMapLayer,
                  setMapLayerVisibility: MapFunctions.setMapLayerVisibility,
                  socket,
                  setSocket,
                  surveillanceCenterPoint,
                  setSurveillanceCenterPoint,
                  segmentedBufferRadius,
                  setSegmentedBufferRadius,
                }}
              >
                <Div100vh
                  className={
                    hideContentPanel
                      ? 'app-container hide-content-panel'
                      : 'app-container'
                  }
                >
                  {!isMobileAccessible && <LandscapeOnly />}
                  <Switch>
                    <Redirect exact from="/" to={DEFAULT_ROUTE} />
                  </Switch>
                  <DetailPanel />
                  <LeftAside />
                  <main className="map">
                    <Switch>
                      <Route exact path={MANAGE_ORGS_PATH}>
                        <ManageOrganisations />
                      </Route>
                      <Route exact path={EDIT_APP_DEFAULTS_PATH}>
                        <EditAppDefaults />
                      </Route>
                      <Route exact path={MANAGE_PILOT_USERS_PATH}>
                        <ManagePilotUsers />
                      </Route>
                      <TrackedRoute
                        path={EDIT_PILOT_USER_PATH}
                        render={() => (
                          <EditPilotUser key={EDIT_PILOT_USER_PATH} />
                        )}
                      />
                      <Route>
                        <NotificationState.Provider
                          value={getNotificationValue()}
                        >
                          <NearbyNotifcation />
                          <MapLoading />
                        </NotificationState.Provider>
                      </Route>
                    </Switch>
                  </main>
                  <Switch>
                    <TrackedRoute path={AUTH_PATH} component={AuthModal} />
                  </Switch>
                  <InfoModal />
                </Div100vh>
              </GlobalState.Provider>
            </IntercomProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  );
}
