import { FRStatusCode } from '@airshare/external-api-types';

import {
  ExtendedFlightResponseBodyV2,
  PilotOnlyStatusCode,
  PilotOnlyStatus,
} from '@airshare/pilot-types';

import { getPilotFacingStatusForAuth } from '../../../../lib/flight-helpers';

export function getPilotFilteredStatuses(flight: ExtendedFlightResponseBodyV2) {
  const filteredStatuses: PilotOnlyStatus[] =
    flight?.properties.allStatuses
      ?.map((x) => {
        const overall = getPilotFacingStatusForAuth(x.authorizer, flight);
        if (overall === PilotOnlyStatusCode.AcceptanceReq) {
          return {
            ...x,
            code: PilotOnlyStatusCode.AcceptanceReq,
          };
        }

        return overall === FRStatusCode.Declared ||
          ([
            FRStatusCode.Activated,
            FRStatusCode.Terminated,
            FRStatusCode.Cancelled,
          ].includes(overall) &&
            !flight?.properties.allocatedControlZone?.code)
          ? undefined
          : x;
      })
      .filter((x) => x) || [];

  return filteredStatuses;
}
