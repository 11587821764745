import { createAction } from 'airshare-web-utils/redux-helpers';
import { type AxiosResponse } from 'axios';
import { PreValidateFlightAreaResponse } from '@airshare/pilot-types';

import { ProcessedPayload } from './hooks';

export const PRE_VALIDATE_FLIGHT_AREA_REQUESTED =
  'pre-validate-flight-area/PRE_VALIDATE_FLIGHT_AREA_REQUESTED';
export const PRE_VALIDATE_FLIGHT_AREA_SUCCEEDED =
  'pre-validate-flight-area/PRE_VALIDATE_FLIGHT_AREA_SUCCEEDED';
export const PRE_VALIDATE_FLIGHT_AREA_FAILED =
  'pre-validate-flight-area/PRE_VALIDATE_FLIGHT_AREA_FAILED';
export const PRE_VALIDATE_FLIGHT_AREA_RESET =
  'pre-validate-flight-area/PRE_VALIDATE_FLIGHT_AREA_RESET';
export const PRE_VALIDATE_FLIGHT_AREA_STATUS_RESET =
  'pre-validate-flight-area/PRE_VALIDATE_FLIGHT_AREA_STATUS_RESET';

export const preValidateFlightAreaRequested = createAction<
  ProcessedPayload,
  string
>(PRE_VALIDATE_FLIGHT_AREA_REQUESTED);
export type PreValidateFlightAreaSucessPayload =
  AxiosResponse<PreValidateFlightAreaResponse>;

export const preValidateFlightAreaSucceeded = createAction(
  PRE_VALIDATE_FLIGHT_AREA_SUCCEEDED
);

export interface PreValidateFlightAreaPayloadFailed {
  message: string;
}
export const preValidateFlightAreaFailed = createAction<
  PreValidateFlightAreaPayloadFailed,
  string
>(PRE_VALIDATE_FLIGHT_AREA_FAILED);
export const preValidateFlightAreaReset = createAction<void, string>(
  PRE_VALIDATE_FLIGHT_AREA_RESET
);
export const preValidateFlightAreaStatusReset = createAction(
  PRE_VALIDATE_FLIGHT_AREA_STATUS_RESET
);
