import { useEffect, useState } from 'react';
import { LatLng } from '@airshare/external-api-types';

import { MapStyle, useMapStyle } from '../../state/map-styles/hooks';
import { createMapOptions } from '../../lib';
import { MapStyleCode } from '../../state/map-styles/actions';

interface GoogleMapConfig {
  center?: LatLng;
  zoom?: number;
  onReady?: (safeMap: google.maps.Map) => void;
  disableDefaultUI?: boolean;
}

export function useMapStyleChange(
  map: google.maps.Map,
  initialZoom: number | null,
  handleMapReady: (safeMap: google.maps.Map) => void,
  rerenderMap: (config: GoogleMapConfig) => void,
  setMapStyleCode: (code: MapStyleCode) => void,
  styleCode?: MapStyleCode
): [MapStyleCode, (code: MapStyleCode) => void] {
  const [mapStyleOptions, setMapStyle] = useMapStyle(
    styleCode,
    setMapStyleCode
  );
  const [previousMapStyleOptions, setPreviousMapStyleOptions] =
    useState<MapStyle>();

  useEffect(() => {
    if (map) {
      const options = createMapOptions(mapStyleOptions);
      const prevOptions = createMapOptions(previousMapStyleOptions);

      if (options.mapId && prevOptions?.mapId !== options.mapId) {
        const center = {
          lat: map?.getCenter()?.lat() || -41.2864603,
          lng: map?.getCenter()?.lng() || 174.77623600000004,
        };
        const mapOptions = {
          center,
          zoom: map?.getZoom() || initialZoom || 12,
          onReady: handleMapReady,
          disableDefaultUI: true,
          mapId: options.mapId,
          mapTypeId: options.mapTypeId,
          gestureHandling: 'greedy',
        };
        rerenderMap(mapOptions)!;
      } else {
        map.setOptions(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyleOptions]);

  function handleMapStyleChange(code: MapStyleCode) {
    setPreviousMapStyleOptions(mapStyleOptions);
    setMapStyle(code);
  }

  return [mapStyleOptions?.styleCode, handleMapStyleChange];
}
