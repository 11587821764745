import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import ToggleButtonGroup from 'airshare-pilot-web-shared/src/components/toggle-button-group/toggle-button-group';
import {
  useFetchFlightRequestsPage,
  useFlightRequestsMeta,
  useFlightRequestsTotalRecords,
  useCurrentFlightRequests,
  usePastFlightRequests,
  useFavourites,
} from '../../../../state/flight-requests/hooks';
import { useHasNoFlights } from './hooks/has-no-flights.hook';
import { FLIGHT_PLAN_PATH } from '../../../../routes';
import { useIsLoading } from './hooks/is-loading.hook';
import { useIsFailed } from './hooks/is-failed.hook';
import { FilterValue } from '../../../../state/flight-requests/constants';
import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';

import './flight-requests.scss';
import FlightCard from './FlightCard';
import FavouriteCard from './FavouriteCard';
import { useCssVariable } from '../../../shared/hooks/css-variable.hook';

const MENU_OPTIONS = [
  { value: FilterValue.FAVOURITE, label: 'Fav' },
  { value: FilterValue.CURRENT, label: 'Current' },
  { value: FilterValue.PAST, label: 'Past' },
];

const CARD_HEIGHT = 127;
const HEADER_VERTICAL_PADDING = 32;
const HEADER_HEIGHT = 140;

export function FlightRequestsComponent({
  location,
}: {
  location: { pathname: string };
}) {
  const [selectedMenuValue, updateSelectedMenuValue] = useState(
    FilterValue.CURRENT
  );

  const fetchFlightRequests = useFetchFlightRequestsPage();

  const favourites = useFavourites();
  const currentFlightRequests = useCurrentFlightRequests();
  const pastFlightRequests = usePastFlightRequests();

  const hasNoFlights = useHasNoFlights();

  const flightRequestsMeta = useFlightRequestsMeta(selectedMenuValue);
  const totalRecords = useFlightRequestsTotalRecords(selectedMenuValue);

  useCssVariable('--flight-requests-card-height', `${CARD_HEIGHT}px`);
  useCssVariable(
    '--flight-requests-header-padding-top',
    `${HEADER_VERTICAL_PADDING}px`
  );
  useCssVariable('--flight-requests-header-height', `${HEADER_HEIGHT}px`);

  function handleRetry() {
    fetchFlightRequests();
  }

  function getFlightsForCurrentMenuSelection(menuValue: string) {
    switch (menuValue) {
      case FilterValue.CURRENT:
        return currentFlightRequests;

      case FilterValue.PAST:
        return pastFlightRequests;

      case FilterValue.FAVOURITE:
        return favourites;

      default:
        return currentFlightRequests;
    }
  }

  const flights: ExtendedFlightResponseBodyV2[] =
    getFlightsForCurrentMenuSelection(selectedMenuValue);

  const isLoading = useIsLoading(flights);
  const isFailed = useIsFailed();

  return (
    <LoadingPanel isLoading={!flights && isLoading}>
      <div className="flights-panel" data-testid="pilot-web:flights-panel">
        <div className="header-container">
          <Typography
            className="heading"
            variant="h6"
            component="h1"
            align="center"
            data-testid="pilot-web:flights-panel:flight-list-header"
          >
            Flights List
          </Typography>
          {!isLoading && !hasNoFlights && (
            <ToggleButtonGroup
              className="menu-tabs"
              selectedValue={selectedMenuValue}
              onChange={(event: Event) => {
                const newMenuValue = get(
                  event,
                  'currentTarget.value',
                  FilterValue.CURRENT
                );
                updateSelectedMenuValue(newMenuValue);
              }}
              options={MENU_OPTIONS}
            />
          )}
        </div>
        {!isLoading && flightRequestsMeta && (
          <>
            {totalRecords === 0 && (
              <div className="no-flights-placeholder">
                {selectedMenuValue === FilterValue.CURRENT && (
                  <>
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      align="center"
                      data-testid="pilot-web:flights-panel:no-upcoming-flight"
                    >
                      You have no upcoming flights
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      align="center"
                      data-testid="pilot-web:flights-panel:log-your-first-flight"
                    >
                      Click on{' '}
                      <Link
                        to={{
                          pathname: FLIGHT_PLAN_PATH,
                          state: { referrer: location.pathname },
                        }}
                      >
                        <button
                          className="fly-now-button"
                          type="button"
                          data-testid="pilot-web:flights-panel:fly-now-button"
                        >
                          <i className="icon fly-now" />
                        </button>
                      </Link>{' '}
                      to log your first flight
                    </Typography>
                  </>
                )}
                {selectedMenuValue === FilterValue.PAST && (
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    align="center"
                    data-testid="pilot-web:flights-panel:no-past-flight"
                  >
                    You have no past flights
                  </Typography>
                )}
                {selectedMenuValue === FilterValue.FAVOURITE && (
                  <>
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      align="center"
                      data-testid="pilot-web:flights-panel:no-favourite-flight"
                    >
                      You haven&apos;t saved a favourite yet!
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitle2"
                      component="h6"
                      align="center"
                    >
                      When creating a flight request you can save it here to use
                      as a template for future requests
                    </Typography>
                  </>
                )}
              </div>
            )}
            {isFailed && (
              <>
                <Typography
                  className="failure-message"
                  variant="h6"
                  component="h2"
                  align="center"
                  data-testid="pilot-web:flights-panel:flight-retrieve-failure-message"
                >
                  We failed to retrieve your flights
                </Typography>
                <Typography
                  className="failure-action"
                  variant="body1"
                  component="p"
                  align="center"
                  data-testid="pilot-web:flights-panel:retry-button"
                >
                  Please click{' '}
                  <button
                    className="retry-link"
                    type="button"
                    onClick={handleRetry}
                  >
                    here
                  </button>{' '}
                  to retry
                </Typography>
              </>
            )}
            {!isFailed &&
              flights?.map((flightRequest, index) => (
                <div key={index} className="card-container">
                  {(selectedMenuValue === FilterValue.PAST ||
                    selectedMenuValue === FilterValue.CURRENT) && (
                    <FlightCard flightRequest={flightRequest} />
                  )}
                  {selectedMenuValue === FilterValue.FAVOURITE && (
                    <FavouriteCard flightRequest={flightRequest} />
                  )}
                </div>
              ))}
          </>
        )}
      </div>
    </LoadingPanel>
  );
}

export const FlightRequests = withRouter(FlightRequestsComponent);
