import type { Feature, FeatureCollection, Geometry, Polygon } from 'geojson';
import { FlightAdvisoryStatusCode } from './status';

export enum FlightDataSource {
  Airshare = 'Airshare',
  FlightAdvisor = 'FlightAdvisor',
}

export type FlightAdvisorySegmentCollection = FeatureCollection<
  Polygon,
  {
    id: string;
    startDateTime: string;
    endDateTime: string;
    minAltitudeFeet: number;
    maxAltitudeFeet: number;
  }
>;

export interface FlightAdvisoryProperties {
  dataSource: FlightDataSource;
  flightId: number; // either flightId or id;
  segmentId: string | undefined;
  startDateTime: string;
  endDateTime: string;
  timezone?: string;
  isShielded: boolean;
  minAltitudeFeet: number;
  minAltitudeRef: string;
  maxAltitudeFeet: number;
  maxAltitudeRef: string;
  launchElevationFeet?: number;
  status: FlightAdvisoryStatusCode;
  acType: string;
  acModel: string;
  activity?: string;
  numAircraft?: number;
  speed?: number;
  comment?: string;
}

export type FlightAdvisoryResponse = Feature<
  Geometry,
  FlightAdvisoryProperties
>;

export enum OverlappingType {
  OVERLAPPING = 'OVERLAPPING',
  NEARBY = 'NEARBY',
  DISTANT = 'DISTANT',
}
export enum OverlappingEdge {
  START = 'START',
  END = 'END',
  BOTH = 'BOTH',
  NONE = 'NONE',
}
export interface OverlappingEdgeDetails {
  overlap: OverlappingType;
  edge: OverlappingEdge;
}

export interface ComparativeFlightAdvisoryOverlapDetails {
  areaOverlap: OverlappingEdgeDetails;
  timeOverlap: OverlappingEdgeDetails;
  altitudeOverlap: OverlappingEdgeDetails;
  overallOverlap: OverlappingType;
  mySegments: {
    areaOverlap: OverlappingEdgeDetails;
    timeOverlap: OverlappingEdgeDetails;
    altitudeOverlap: OverlappingEdgeDetails;
    mySegmentId: string;
    otherSegmentId?: string;
    overallOverlap: OverlappingType;
  }[];
}

export interface ComparativeFlightAdvisoryProperties
  extends FlightAdvisoryProperties {
  isOverlapping: boolean;
  isConflicting: boolean;
  pilotName?: string;
  pilotPhoneNumber?: string;
  pilotEmail?: string;
  orgName?: string;
  overlapDetails: ComparativeFlightAdvisoryOverlapDetails;
}

export type ComparativeFlightAdvisoryResponse = Feature<
  Geometry,
  ComparativeFlightAdvisoryProperties
>;
