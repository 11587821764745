import {
  useEffect,
  useRef,
  useCallback,
  useState,
  MutableRefObject,
} from 'react';
import type { FeatureCollection } from 'geojson';
import { POINT_MIN_ZOOM } from 'argus-data-model/db/schemas/map-points/map-code-properties';

import hangGlidingIcon from './images/hang-gliding.png';
import parachutingIcon from './images/parachuting.png';
import * as contentAPI from '../../lib/content-api-client';
import { useInterval } from '../use-interval.hook';
import { isEqual } from 'lodash';

interface MapLayerCache {
  mapCode: string;
  featureCollection?: FeatureCollection;
  dataLayer?: google.maps.Data;
}

const styleProps = [
  'mapCode',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'strokeWidth',
  'fillColor',
  'fillOpacity',
];

const ALL_AIRSPACES = ['ctr', 'moa', 'ad', 'd', 'lfz', 'r', 'nfz', 'tl'];
const ALL_POINTS = ['hang-gliding', 'parachuting'];
const MAP_LAYERS_RELOAD_INTERVAL = 30000;

export function useMapLayers(
  google: any,
  map: google.maps.Map | null,
  zoom?: number | null,
  selectedAdvisory?: any | null,
  hoveredOverAdvisory?: any | null,
  activeLayers: string[] | null = null,
  availableLayers: any[] = []
) {
  const dataLayersCache = useRef<MapLayerCache[]>();
  const prevDataLayersCache = useRef<MapLayerCache[]>();
  const [iconLayersVisible, setIconLayersVisible] = useState<boolean>(false);
  const [featureCollections, setFeatureCollections] = useState<{
    [key: string]: FeatureCollection;
  }>({});

  useEffect(() => {
    dataLayersCache.current = [];
    prevDataLayersCache.current = [];
  }, []);

  useEffect(() => {
    setIconLayersVisible(Boolean(zoom && zoom >= POINT_MIN_ZOOM));
  }, [zoom]);

  const setFeatureStyle = useCallback(
    (feature: google.maps.Data.Feature) => {
      const featureId = feature.getId();
      const featureCode = feature.getProperty('code');
      const defaultStyle = styleProps.reduce(
        (acc1, prop) => ({
          ...acc1,
          [prop]: feature.getProperty(prop),
        }),
        {
          icon: {
            url:
              featureCode === 'hang-gliding'
                ? hangGlidingIcon
                : parachutingIcon,
          },
          title: feature.getProperty('name') as string,
          clickable: false,
          editable: false,
          draggable: false,
        }
      );

      // Selected advisory - high opacity, show icon
      if (featureId && featureId === selectedAdvisory?.id) {
        // extra opacity when selected and hovered
        const selectedAdvisoryOpacity =
          featureId === hoveredOverAdvisory?.id ? 0.8 : 0.7;
        return {
          ...defaultStyle,
          fillOpacity: selectedAdvisoryOpacity,
          visible: true,
        };
      }

      // Hovered advisory - medium opacity, show icon
      if (featureId && featureId === hoveredOverAdvisory?.id) {
        const hoveredAdvisoryOpacity = selectedAdvisory ? 0.3 : 0.6;
        return {
          ...defaultStyle,
          fillOpacity: hoveredAdvisoryOpacity,
          visible: true,
        };
      }

      const isIcon =
        featureCode === 'hang-gliding' || featureCode === 'parachuting';

      // Another advisory is selected and/or hovered - very low opacity, don't show icon
      if (hoveredOverAdvisory || selectedAdvisory) {
        const otherAreaOpacity = 0.1;
        return {
          ...defaultStyle,
          fillOpacity: otherAreaOpacity,
          strokeOpacity: otherAreaOpacity,
          visible: !isIcon,
        };
      }

      // default opacity is 0.2 or 0.25 in the database, icons visible depending on zoom
      return {
        ...defaultStyle,
        visible: isIcon ? iconLayersVisible : true,
      };
    },
    [selectedAdvisory, hoveredOverAdvisory, iconLayersVisible]
  );

  const updateDataLayer = useCallback(
    (mapCode: string, featureCollection?: FeatureCollection): Promise<void> => {
      if (!featureCollection || activeLayers?.length === 0) {
        return new Promise((resolve) => resolve());
      }

      return new Promise((resolve) => {
        try {
          const dataLayer: google.maps.Data = new google.maps.Data({});

          dataLayer.addGeoJson(featureCollection);

          dataLayer.setStyle(setFeatureStyle);

          dataLayer.setMap(map);

          const cachedLayer = dataLayersCache.current!.find(
            (dl) => dl.mapCode === mapCode
          );
          cachedLayer!.dataLayer?.forEach((feature) => {
            cachedLayer!.dataLayer?.remove(feature);
          });
          cachedLayer!.dataLayer?.setMap(null);
          cachedLayer!.dataLayer = dataLayer;

          const prevCachedLayers = prevDataLayersCache.current!.filter(
            (dl) => dl.mapCode === mapCode
          );
          prevCachedLayers?.forEach((prev) => {
            prev?.dataLayer?.forEach((feature) => {
              prev?.dataLayer?.remove(feature);
            });
            prev?.dataLayer?.setMap(null);
          });
          prevDataLayersCache.current = prevDataLayersCache.current!.filter(
            (dl) => dl.mapCode !== mapCode
          );

          resolve();
        } catch (e) {
          // ignore when content API returns 400 if no areas are found
          if (e.response && e.response.status !== 400) {
            console.error(e);
          }
          resolve();
        }
      });
    },
    [google.maps.Data, setFeatureStyle, map, activeLayers]
  );

  const [currentAvailableLayers, setCurrentAvailableLayers] = useState<
    string[] | null
  >();

  useEffect(() => {
    if (!isEqual(currentAvailableLayers, availableLayers)) {
      setCurrentAvailableLayers(availableLayers);
    }
  }, [currentAvailableLayers, availableLayers]);

  useEffect(() => {
    if (featureCollections && currentAvailableLayers) {
      Object.entries(featureCollections).map(([mapCode, featureCollection]) => {
        const filteredFeatureCollection = {
          ...featureCollection,
          features: getFilteredLayers(
            featureCollection,
            activeLayers,
            currentAvailableLayers
          ),
        };
        updateCacheLayer(dataLayersCache, mapCode, filteredFeatureCollection);
        updateDataLayer(mapCode, filteredFeatureCollection);
      });
    }
  }, [
    activeLayers,
    currentAvailableLayers,
    featureCollections,
    updateDataLayer,
  ]);

  const loadFeatureCollection = useCallback(
    (mapCode: string): Promise<void> => {
      return new Promise((resolve) => {
        contentAPI
          .getAdvisoryLayer(mapCode)
          .then((featureCollection) => {
            setFeatureCollections((prev) => ({
              ...prev,
              [mapCode]: featureCollection,
            }));

            resolve();
          })
          .catch((e) => {
            // ignore when content API returns 400 if no areas are found
            if (e.response && e.response.status !== 400) {
              console.error(e);
            }
            resolve();
          });
      });
    },
    []
  );

  const onMapCodesChange = useCallback(async () => {
    const existingMapCodes = dataLayersCache.current?.map((dl) => dl.mapCode);

    const allLayers = ALL_AIRSPACES.concat(ALL_POINTS);

    const newMapCodes = allLayers.filter((mc) => {
      if (existingMapCodes!.includes(mc)) {
        return false;
      } else {
        dataLayersCache.current!.push({ mapCode: mc });
        return true;
      }
    });

    if (newMapCodes.length > 0) {
      const loadPromises = newMapCodes.map(loadFeatureCollection);
      await Promise.all(loadPromises);
    }

    dataLayersCache.current?.forEach(({ dataLayer, mapCode }) => {
      if (dataLayer) {
        const m = allLayers.includes(mapCode) ? map : null;
        dataLayer?.setMap(m);
      }
    });
  }, [loadFeatureCollection, map]);

  useEffect(() => {
    if (google && map) {
      onMapCodesChange();
    }
  }, [google, map, onMapCodesChange]);

  useInterval(() => {
    if (google && map) {
      dataLayersCache.current?.forEach((dl) =>
        prevDataLayersCache.current!.push(dl)
      );
      dataLayersCache.current = [];
      onMapCodesChange();
    }
  }, MAP_LAYERS_RELOAD_INTERVAL);

  useEffect(() => {
    dataLayersCache.current?.forEach((dl) => {
      dl?.dataLayer?.setStyle(setFeatureStyle);
    });
  }, [setFeatureStyle]);
}

function getFilteredLayers(
  featureCollection: FeatureCollection,
  activeLayers: string[] | null,
  availableLayers: any[]
) {
  return featureCollection.features.filter((feature) => {
    const isHidden = availableLayers
      .map((availableLayer) => {
        const isActive = activeLayers?.includes(availableLayer.code);

        if (availableLayer.type === 'keyword') {
          const exists = availableLayer.keywords.some((keyword: string) =>
            `${feature.properties?.siteName}${feature.properties?.name}${feature.properties?.description}`
              .toLowerCase()
              .includes(keyword)
          );
          if (!isActive && exists) {
            return false;
          }
        }
        if (availableLayer.type === 'code') {
          const exists = availableLayer.code === feature.properties?.code;
          if (!isActive && exists) {
            return false;
          }
        }
        if (availableLayer.type === 'category') {
          const exists =
            availableLayer.code === feature.properties?.category &&
            !availableLayer.ignoreCode.includes(feature.properties?.code);
          if (!isActive && exists) {
            return false;
          }
        }
        return true;
      })
      .some((x) => x === false);

    return !isHidden;
  });
}

function updateCacheLayer(
  dataLayersCache: MutableRefObject<MapLayerCache[] | undefined>,
  mapCode: string,
  filteredFeatureCollection: FeatureCollection
) {
  const cachedLayer = dataLayersCache.current?.find(
    (dl) => dl.mapCode === mapCode
  );
  if (cachedLayer) {
    cachedLayer.featureCollection = filteredFeatureCollection;
  }
}
