import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import type { Polygon } from '@turf/helpers';
import ReactDomServer from 'react-dom/server';

import { AdvisorySource } from '@airshare/pilot-types';

import { GlobalState } from '../../../state/GlobalState';
import { advisoryPinPath } from '../../../routes';

// workaround for missing value when unit testing
// google.maps.Animation.DROP
const DROP_ANIMATION_VALUE = 2.0;

export const useDraggablePin = (
  google: any,
  map: google.maps.Map | null,
  pathname: string,
  setResetSearchInput: Dispatch<SetStateAction<boolean>>
) => {
  const { setAdvisoryGeometry, advisoryGeometrySource } =
    useContext(GlobalState);
  const pinIsAllowed = useRef(true);
  const [pin, setPin] = useState<google.maps.Marker | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const previousPathname = useRef<string>();

  const updatePin = useCallback(
    (
      safeMap: google.maps.Map,
      lat?: number | null,
      lng?: number | null,
      name?: string,
      animate: boolean = true
    ) => {
      let newPin: google.maps.Marker | null = null;

      if (!lat || !lng) {
        setAdvisoryGeometry(AdvisorySource.None, null);
      }

      if (lat && lng && (pinIsAllowed.current || name)) {
        setAdvisoryGeometry(AdvisorySource.Pin, pointToPolygon(lat, lng));

        newPin = new google.maps.Marker({
          map: safeMap,
          position: { lat, lng },
          draggable: pinIsAllowed.current,
          animation: animate ? DROP_ANIMATION_VALUE : undefined,
          label: name
            ? {
                text: name,
                color: 'white',
                className: 'map-pin-label',
              }
            : '',
          icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
        });

        newPin.addListener('dragstart', () => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
        });

        newPin.addListener('dragend', (event: google.maps.MapMouseEvent) => {
          newPin.setLabel('');
          newPin.setTitle('');
          newPin.setAnimation(null);
          setAdvisoryGeometry(
            AdvisorySource.Pin,
            pointToPolygon(event.latLng.lat(), event.latLng.lng())
          );
          setResetSearchInput((prev) => !prev);
        });

        newPin.addListener('click', (_event: google.maps.MapMouseEvent) => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }

          const infoWindow = new google.maps.InfoWindow({});
          infoWindowRef.current = infoWindow;
          infoWindow.setContent(
            ReactDomServer.renderToStaticMarkup(
              PinInfoWindowContent({ marker: newPin })
            )
          );

          infoWindow.open(safeMap, newPin);

          google.maps.event.addListener(infoWindow, 'domready', () => {
            const obj = document.getElementById('pin-info-window-remove-text');
            if (obj) {
              obj.addEventListener('click', (_e2) => {
                updatePin(safeMap, null, null);
              });
            }
          });
        });
      }

      setPin((prev) => {
        if (prev) {
          google.maps.event.clearListeners(prev, 'dragstart');
          google.maps.event.clearListeners(prev, 'dragend');
          google.maps.event.clearListeners(prev, 'click');
          prev.setMap(null);
        }

        return newPin;
      });
    },
    [
      setResetSearchInput,
      google.maps.InfoWindow,
      google.maps.Marker,
      google.maps.event,
      setAdvisoryGeometry,
    ]
  );

  useEffect(() => {
    if (pathname) {
      pinIsAllowed.current = advisoryPinPath(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (pin) {
      pin.setMap(pinIsAllowed.current ? map : null);

      if (pinIsAllowed.current && previousPathname.current !== pathname) {
        map.panTo(pin.getPosition());
      }
    }

    previousPathname.current = pathname;
  }, [map, pathname, pin, pinIsAllowed]);

  useEffect(() => {
    if (advisoryGeometrySource !== AdvisorySource.Pin && pin) {
      updatePin(map, null, null);
    }
  }, [advisoryGeometrySource, map, pin, updatePin]);

  const handleCollapseAdvisoryModal = useCallback(
    (collapsed: boolean) => {
      if (pin) {
        pin.setMap(collapsed ? null : map);

        if (!collapsed) {
          map.panTo(pin.getPosition());
        }
      }
    },
    [pin, map]
  );

  return { updatePin, handleCollapseAdvisoryModal };
};

function pointToPolygon(lat: number, lng: number): Polygon | null {
  if (!lat || !lng) {
    return null;
  }

  const OFFSET = 0.000001;
  return {
    type: 'Polygon',
    coordinates: [
      [
        [lng, lat],
        [lng + OFFSET, lat],
        [lng, lat + OFFSET],
        [lng, lat],
      ],
    ],
  };
}

export function PinInfoWindowContent({
  marker,
}: {
  marker: google.maps.Marker;
}) {
  const renderListItem = (item: { key: string; value?: number }) => (
    <ListItem style={{ display: 'flex' }} key={item.key}>
      <ListItemText
        style={{ maxWidth: 80, margin: 0 }}
        primaryTypographyProps={{
          style: { fontWeight: 600, width: 80, color: 'white' },
        }}
        primary={`${item.key}:`}
      />
      <ListItemText
        style={{ margin: 0 }}
        primaryTypographyProps={{ style: { float: 'left', color: 'white' } }}
        primary={item.value}
      />
    </ListItem>
  );

  return (
    <div
      data-testid="pin-info-window"
      id={`pin-info-window`}
      className="pin-info-window"
    >
      <List dense>
        {[
          { key: 'Longitude', value: marker.getPosition()?.lng() },
          { key: 'Latitude', value: marker.getPosition()?.lat() },
        ].map(renderListItem)}
      </List>
      <hr />
      <p
        id="pin-info-window-remove-text"
        className="pin-info-window-remove-text"
      >
        Remove pin
      </p>
    </div>
  );
}
