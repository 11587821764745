import React, { useState, useContext, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ButtonBase from '@material-ui/core/ButtonBase';
import type { Feature, LineString, Polygon } from 'geojson';
import classNames from 'classnames';

import type { StyledFlightSegmentPropertiesJSONView } from '@airshare/external-api-types';

import { closeActiveInfoWindows } from 'airshare-pilot-web-shared';

import { GlobalState } from '../../../state/GlobalState';
import { useSetFieldValue } from '~/state/flight-plan/flight-plan-form/hooks';
import { ExpandButton } from '../expand-button/expand-button';
import { SegmentList } from './segment-list';
import { SegmentPlannerModal } from './segment-planner-modal';
import { updateFormSegmentCollection } from './segment-helpers';

import './segments-accordion.scss';

interface SegmentsProps {
  segmentFeatures:
    | Feature<Polygon, StyledFlightSegmentPropertiesJSONView>[]
    | undefined;
  timezone: string | undefined;
  isReadOnly: boolean;
  waypoints: LineString | undefined;
}

export const SegmentsAccordion = (props: SegmentsProps): JSX.Element | null => {
  const {
    highlightedSegments,
    setHighlightedSegments,
    setShowMyFlightMarkers,
  } = useContext(GlobalState);
  const showSegments = props?.segmentFeatures?.length > 0;
  const [showSegmentsState, setShowSegmentsState] = useState(true);
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [formIsUpdated, setFormIsUpdated] = useState(false);
  const setFieldValue = useSetFieldValue();
  const headerRef = useRef(null);

  useEffect(() => {
    setShowMyFlightMarkers(true);
  }, [setShowMyFlightMarkers]);

  useEffect(() => {
    if (highlightedSegments?.length > 0) {
      setShowSegmentsState(true);

      if (headerRef?.current) {
        headerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [highlightedSegments]);

  const handleFormUpdated = (formUpdated: boolean) => {
    setFormIsUpdated(formUpdated);
  };

  return showSegments ? (
    <>
      <div className="segments-section">
        <div
          className="segments-section-heading"
          data-testid="pilot-web:flight-briefing-panel:segments-section"
          ref={headerRef}
        >
          <Typography variant="subtitle1">Segments</Typography>
          <ExpandButton
            isExpanded={showSegmentsState}
            onClick={() =>
              setShowSegmentsState((prevShowDisclaimers) => {
                if (prevShowDisclaimers) {
                  closeActiveInfoWindows();
                  setHighlightedSegments([]);
                }

                setShowMyFlightMarkers(!prevShowDisclaimers);

                return !prevShowDisclaimers;
              })
            }
          />
        </div>
        <Collapse in={showSegmentsState} className="segments">
          <SegmentList
            segmentFeatures={props.segmentFeatures}
            timezone={props.timezone}
          />
          {!props.isReadOnly && (
            <div className="edit-advanced-button-wrapper">
              <ButtonBase
                className={classNames(
                  'edit-advanced-button',
                  formIsUpdated ? 'modified' : ''
                )}
                type="button"
                onClick={() => setShowSegmentModal(true)}
                data-testid="pilot-web:flight-briefing-panel:segments-section:edit-advanced-button"
              >
                {formIsUpdated
                  ? 'Edit Segment Details (unsaved)'
                  : 'Edit Segment Details'}
              </ButtonBase>
            </div>
          )}
        </Collapse>
      </div>
      <SegmentPlannerModal
        segmentFeatures={props.segmentFeatures}
        timezone={props.timezone}
        showSegmentModal={showSegmentModal}
        closeSegmentModal={async (modifiedSegments) => {
          if (modifiedSegments?.length > 0) {
            updateFormSegmentCollection(
              setFieldValue,
              props.segmentFeatures,
              modifiedSegments
            );
            setFormIsUpdated(false);
          }
          setShowSegmentModal(false);
        }}
        waypoints={props.waypoints}
        handleFormUpdated={handleFormUpdated}
      />
    </>
  ) : null;
};
