import * as yup from 'yup';
import { createFormReducer } from 'airshare-web-utils/redux-forms/index.ts';
import { actions, ADD_UAV_FORM_KEY } from './actions';

const isEuroRegulation = window.env.EURO_REGULATIONS?.toLowerCase() === 'true';

const integerValidation = (value) => {
  return !(!Number.isInteger(value) || String(value).includes('.'));
};

export const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  manufacturer: yup.string().required('Manufacturer is required'),
  model: yup.string().required('Model is required'),
  weight: yup.string().required('Weight is required'),
  serialNumber: yup.string(),
  cClass: isEuroRegulation
    ? yup.string().max(20, 'C-Class is required').required('Required')
    : yup.string().nullable(),
  enduranceMinutes: isEuroRegulation
    ? yup
        .number()
        .min(1, 'Endurance must be at least 1')
        .max(600, 'Endurance cannot be greater than 600')
        .positive()
        .required('Endurance is required')
        .test('is-integer', 'Endurance must be an integer', integerValidation)
    : yup.number().nullable(),

  remoteId: yup
    .string()
    .max(20, 'RemoteId cannot be longer than 20 characters'),
  defaultProcedureToMeasureAltitude: yup.string(),
  defaultEmergencyProcedure: yup.string(),
});

const defaultState = {
  name: '',
  manufacturer: '',
  model: '',
  weight: '',
  serialNumber: '',
  cClass: '',
  enduranceMinutes: 30,
  remoteId: '',
  defaultProcedureToMeasureAltitude: '',
  defaultEmergencyProcedure: '',
};

export const addUavFormReducer = {
  [ADD_UAV_FORM_KEY]: createFormReducer(actions, defaultState, schema),
};
