import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import { default as centerOfMass } from '@turf/center-of-mass';
import isEqual from 'lodash/isEqual';
import type { NotamsResponseWithLocation } from '@airshare/pilot-types';

import { NotamsState } from './reducers';

interface StateWithNotams {
  notams: NotamsState;
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const DEFAULT_STATE: NotamsResponseWithLocation[] = [];
const getLocalState = (state: StateWithNotams) => state.notams;

const getNotamsData = (
  state: StateWithNotams
): NotamsResponseWithLocation[] => {
  if (!state.notams.data) return DEFAULT_STATE;

  return state.notams.data.map((notam) => {
    const { geometry } = centerOfMass(notam);
    const [lng, lat] = geometry.coordinates;

    return {
      ...notam,
      location: { lng, lat },
    };
  });
};

export const getNotams = createDeepEqualSelector(
  [getNotamsData],
  (state) => state
);

export const getNotam = (id: string) =>
  createSelector([getNotamsData], (state) =>
    state ? state.find((notam) => notam.id === id) || null : null
  );

export const getNotamsFetchStatus = createSelector(
  [getLocalState],
  (state) => state.fetchStatus
);
