import { useState, useEffect, useCallback } from 'react';

import {
  AuthChangeFieldClient,
  AuthChangeValue,
  RequiredAuthChange,
} from '@airshare/external-api-types';

import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { feetToMeters } from 'airshare-shared-utils/measurements';
import { useAtcName } from './use-atc-name.hook';
import { ATC_AUTH_DEFAULT_NAME } from 'argus-api-shared/lib/constants';
import { DateTime } from 'luxon';

const formattedAltitude = (val: number | AuthChangeValue) =>
  `${val} ft (${feetToMeters(val as number)} m)`;

export function useAcknowledgementsRequired(
  flight?: ExtendedFlightResponseBodyV2
) {
  const [acknowledgements, setAcknowledgements] = useState<string[]>([]);
  const atcName = useAtcName();

  const getAuthName = useCallback(
    (name: string) => {
      return name === ATC_AUTH_DEFAULT_NAME ? atcName : name;
    },
    [atcName]
  );

  useEffect(() => {
    const { startDateTime, timezone, maxAltitudeFeet, requiredAuthChanges } =
      flight?.properties ?? {};
    const changeList = requiredAuthChanges
      ?.map((change: RequiredAuthChange) => {
        if (change.isAcknowledged) {
          return;
        }
        const auth = getAuthName(change.authorizerName);

        switch (change.field) {
          case 'altitudeFeet': {
            const prev = formattedAltitude(change.previousValue);
            const curr = formattedAltitude(maxAltitudeFeet);
            return `Altitude change required by ${auth} from ${prev} to ${curr}`;
          }
          case 'startDateTime': {
            const updatedStartDateTime = DateTime.fromISO(startDateTime, {
              zone: timezone,
            });
            const originalStartDateTime = DateTime.fromISO(
              change.previousValue.toString(),
              {
                zone: timezone,
              }
            );

            if (
              originalStartDateTime.toFormat('dd/MM/yyyy') ===
              updatedStartDateTime.toFormat('dd/MM/yyyy')
            ) {
              return `Flight time change required by ${auth} from ${originalStartDateTime.toFormat(
                'HH:mm ZZZZ'
              )} to ${updatedStartDateTime.toFormat('HH:mm ZZZZ')}`;
            } else {
              return `Flight time change required by ${auth} from ${originalStartDateTime.toFormat(
                'dd MMMM yyyy HH:mm ZZZZ'
              )} to ${updatedStartDateTime.toFormat(
                'dd MMMM yyyy HH:mm ZZZZ'
              )}`;
            }
          }
          default: {
            const prev = change.previousValue;
            // @ts-ignore
            const curr = flight[change.field as AuthChangeFieldClient];
            return `${change.field} change required by ${auth} from ${prev} to ${curr}`;
          }
        }
      })
      .filter((x) => x);
    setAcknowledgements(changeList ?? []);
  }, [flight, getAuthName, setAcknowledgements]);

  return acknowledgements;
}
