import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  orgPilotsFetchRequested,
  orgPilotsCreationRequested,
  orgPilotsUpdateRequested,
  orgPilotsDeletionRequested,
  orgPilotsUpdateStatusReset,
  orgPilotsDeletionStatusReset,
  orgPilotsCreationStatusReset,
} from './actions';
import {
  getOrgPilot,
  getOrgPilots,
  getOrgPilotsFetchStatus,
  getOrgPilotsCreationStatus,
  getOrgPilotsUpdateStatus,
  getOrgPilotsDeletionStatus,
} from './selectors';

export function useFetchOrgPilots() {
  const dispatch = useDispatch();

  return () => dispatch(orgPilotsFetchRequested());
}

export function useCreateOrgPilot() {
  const dispatch = useDispatch();

  return (payload) => dispatch(orgPilotsCreationRequested(payload));
}

export function useUpdateOrgPilot() {
  const dispatch = useDispatch();

  return (payload) => dispatch(orgPilotsUpdateRequested(payload));
}

export function useDeleteOrgPilot() {
  const dispatch = useDispatch();

  return (payload) => dispatch(orgPilotsDeletionRequested(payload));
}

export function useOrgPilots() {
  const fetchOrgPilots = useFetchOrgPilots();

  useEffect(() => {
    fetchOrgPilots();
  }, []);

  const orgPilots = useSelector(getOrgPilots);

  return orgPilots;
}

export function useOrgPilot(id) {
  const orgPilot = useSelector(getOrgPilot(id));

  return orgPilot;
}

export function useOrgPilotsFetchStatus() {
  const orgPilotsFetchStatus = useSelector(getOrgPilotsFetchStatus);

  return orgPilotsFetchStatus;
}

export function useOrgPilotsCreationStatus() {
  const orgPilotsCreationStatus = useSelector(getOrgPilotsCreationStatus);

  return orgPilotsCreationStatus;
}

export function useResetOrgPilotsCreationStatus() {
  const dispatch = useDispatch();

  return () => dispatch(orgPilotsCreationStatusReset());
}

export function useOrgPilotsUpdateStatus() {
  const orgPilotsUpdateStatus = useSelector(getOrgPilotsUpdateStatus);

  return orgPilotsUpdateStatus;
}

export function useResetOrgPilotsUpdateStatus() {
  const dispatch = useDispatch();

  return () => dispatch(orgPilotsUpdateStatusReset());
}

export function useOrgPilotsDeletionStatus() {
  const orgPilotsDeletionStatus = useSelector(getOrgPilotsDeletionStatus);

  return orgPilotsDeletionStatus;
}

export function useResetOrgPilotsDeletionStatus() {
  const dispatch = useDispatch();

  return () => dispatch(orgPilotsDeletionStatusReset());
}
