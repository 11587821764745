import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';

import { closeActiveInfoWindows } from 'airshare-pilot-web-shared';

import { FeatureStyle } from '@airshare/external-api-types';

import { FlightAdvisoryAccordion } from './flight-advisory-accordion';
import { FlightAdvisoryOverlapModal } from './flight-advisory-overlap-modal';

import {
  useFlightAdvisories,
  useHighlightedFlightAdvisories,
  useHighlightedFlightAdvisoriesUpdated,
} from '../../../state/flight-advisories/hooks';
import InfoTooltip from '../info-tooltip/info-tooltip';

import './nearby-flights-accordion.scss';

const useStyles = makeStyles({
  accordion: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  accordionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    borderBottom: '1px solid #ccc',
  },
  warningIcon: {
    color: '#ff9800',
    marginRight: '0.5rem',
  },
  accordionHeaderTitle: {
    fontSize: '1.2rem',
  },
  advisoryContainer: {
    paddingLeft: '3%',
    width: '98%',
  },
});

export const NearbyFlightsAccordion = (): JSX.Element | null => {
  const classes = useStyles();
  const headerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const nearbyFlights = useFlightAdvisories();
  const highlightedNearbyFlights = useHighlightedFlightAdvisories();
  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();

  const setFlightIsHighlighted = useCallback(
    (flightId: number, segmentId: string | undefined, style?: FeatureStyle) => {
      closeActiveInfoWindows();
      const flightToHighlight = nearbyFlights?.find(
        (flight) =>
          flight.properties.flightId === flightId &&
          flight.properties.segmentId === segmentId
      );
      if (flightToHighlight) {
        const highlightIndex = highlightedNearbyFlights?.findIndex(
          (hnbf) => hnbf.flightId === flightId && hnbf.segmentId === segmentId
        );
        if (style && highlightIndex === -1) {
          setHighlightedNearbyFlights([
            ...highlightedNearbyFlights,
            {
              flightId: flightToHighlight.properties.flightId,
              segmentId: flightToHighlight.properties.segmentId,
              style,
            },
          ]);
        } else if (!style && highlightIndex > -1) {
          setHighlightedNearbyFlights([
            ...highlightedNearbyFlights.filter(
              (_hnbf, ndx) => ndx !== highlightIndex
            ),
          ]);
        }
      }
    },
    [nearbyFlights, highlightedNearbyFlights, setHighlightedNearbyFlights]
  );

  useEffect(
    () => {
      return () => {
        setHighlightedNearbyFlights([]);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (highlightedNearbyFlights.length > 0 && headerRef?.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [highlightedNearbyFlights]);

  if (!nearbyFlights?.length) {
    return null;
  }

  const sortedNbFs = nearbyFlights
    .map((flight) => flight.properties)
    .sort((a, b) => {
      if (
        (a.isConflicting && !b.isConflicting) ||
        (a.isOverlapping && !b.isConflicting && !b.isOverlapping)
      ) {
        return -1;
      }
      if (
        (b.isConflicting && !a.isConflicting) ||
        (b.isOverlapping && !a.isConflicting && !a.isOverlapping)
      ) {
        return 1;
      }
      return 0;
    });

  return (
    <div
      className={classes.accordion}
      data-testid="pilot-web:shared:nearby-flights-accordian"
    >
      <div
        className={classes.accordionHeader}
        data-testid="pilot-web:shared:nearby-flights-accordian:heading"
        ref={headerRef}
      >
        <WarningIcon
          className={classes.warningIcon}
          data-testid="pilot-web:shared:nearby-flights-accordian:warning-icon"
        />
        <Typography className={classes.accordionHeaderTitle}>
          Nearby & Overlapping Flights
          <InfoTooltip>
            <strong style={{ color: 'var(--color-highlight-orange)' }}>
              Overlapping flights
            </strong>{' '}
            are those that overlap in both time and space.{' '}
            <strong>Nearby flights</strong> are those that are close in time or
            space but do not overlap.
          </InfoTooltip>
        </Typography>
      </div>
      <ul data-testid="pilot-web:shared:nearby-flights-accordian:list">
        {sortedNbFs.map((nbF) => {
          const flightLabel =
            `${nbF.flightId}` + (nbF.segmentId ? `-${nbF.segmentId}` : '');
          return (
            <li
              className={classes.advisoryContainer}
              key={`cf-${flightLabel}`}
              data-testid="pilot-web:shared:flight-advisory-accordian"
            >
              <FlightAdvisoryAccordion
                advisory={nbF}
                highlightFlight={setFlightIsHighlighted}
                isExpanded={Boolean(
                  highlightedNearbyFlights.find(
                    (h) =>
                      h.flightId === nbF.flightId &&
                      h.segmentId === nbF.segmentId &&
                      !h.mapOnly
                  )
                )}
              />
            </li>
          );
        })}
      </ul>
      <div className="overlapping-details-button-wrapper">
        <ButtonBase
          className={'overlapping-details-button'}
          type="button"
          onClick={() => setShowModal(true)}
          data-testid="pilot-web:flight-briefing-panel:segments-section:overlapping-details-button"
        >
          {'Overlapping Details'}
        </ButtonBase>
      </div>
      <FlightAdvisoryOverlapModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </div>
  );
};
