export default `<svg width="250" height="250" 

  viewBox="0 -8 72 72" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
  <filter id='shadow' color-interpolation-filters="sRGB">
    <feDropShadow dx="1" dy="1" stdDeviation="0.5" flood-opacity="0.6"/>
  </filter>
  <g class="layer" filter="url(#shadow)">
    <title>helicopter</title>
    <path 
      d="M60.64,28.1a1.24,1.24,0,0,0-1.27-1.22l-14.89-.33c.61-.91,1.51-2.05,2.78-3.57,6.16-7.36,4.19-9.8,4.19-9.8s-2.28-2-9.91,3.84c-1.31,1-2.34,1.76-3.19,2.31l.3-14.09a1.19,1.19,0,1,0-2.38,0l-.34,15.33c-2,.44-3-1.25-7.33-3.31,0,0-2.34.91-2.86,2.77a39.41,39.41,0,0,1,3.39,6.24l-14.5-.31a1.2,1.2,0,1,0-.05,2.39l14.6.31a1.28,1.28,0,0,0,.35.59L19.84,41.61l-4.57-3.24.54-1.25S12,39.7,11.5,41.34l-.14,2,1.37-.48.41-1.31L16,45.91s-.79.9-.24,1.47,1.55-.1,1.55-.1l4.35,3.1-1.32.35-.54,1.35,2,0c1.65-.39,4.4-4.13,4.4-4.13l-1.28.49-3-4.71,12.81-9.15a1.31,1.31,0,0,0,1,.45l-.32,15a1.19,1.19,0,1,0,2.38,0L38,35.23a42.17,42.17,0,0,1,5.67,3.47c1.88-.44,2.87-2.75,2.87-2.75-1.71-4.1-3.24-5.34-3.09-7l15.87.34A1.25,1.25,0,0,0,60.64,28.1Z"
       fill="#f57c00" opacity="0.9" id="svg_1" stroke="#000000" stroke-width="1"
      />
  </g>    
</svg>`;
