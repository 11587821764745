import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import InfoTooltip from '../../../shared/info-tooltip/info-tooltip';
import { UasOperationCategory } from 'argus-common/enums';
import ToggleButtonGroup from 'airshare-pilot-web-shared/src/components/toggle-button-group/toggle-button-group';

const useStyles = makeStyles((_theme) => ({
  LabelRule: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    cursor: 'default',
  },
}));

const OperationCategory = (props: {
  isPart102Allowed: boolean;
  currentValue: UasOperationCategory;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}): JSX.Element | null => {
  const classes = useStyles();
  const { isPart102Allowed, currentValue, onChange, disabled } = props;
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';
  return isEuroRegulation ? (
    <>
      <FormLabel className="form-section-heading" component="h2">
        UAS Operation Category
      </FormLabel>
      {/* 
// @ts-ignore */}
      <FormControlLabel
        labelPlacement="top"
        className="uas-operation-category"
        control={
          <div className={classes.LabelRule}>
            <ToggleButtonGroup
              name="uasOperationCategory"
              options={Object.values(UasOperationCategory).map((c) => ({
                value: c,
                label: c,
              }))}
              selectedValue={currentValue}
              onChange={onChange}
              disabled={disabled}
              className="rule"
              data-testid="pilot-web:flight-request:uas-operation-category"
              valuesDisabled={
                isPart102Allowed
                  ? []
                  : [
                      UasOperationCategory.CERTIFIED,
                      UasOperationCategory.SPECIFIC,
                    ]
              }
            />
            {isEuroRegulation && (
              <InfoTooltip>
                {isPart102Allowed
                  ? 'Select Specific or Certified UAS Operation Category for BVLOS flights'
                  : 'You must be a certified pilot to enable Specific or Certified operations'}
              </InfoTooltip>
            )}
          </div>
        }
      ></FormControlLabel>
    </>
  ) : null;
};

export default OperationCategory;
