export enum FlightRequestsFetchStatus {
  IDLE = 'IDLE',
  FLIGHT_REQUESTS_FETCH_IN_PROGRESS = 'FLIGHT_REQUESTS_FETCH_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FlightRequestsFetchFocussedStatus {
  IDLE = 'IDLE',
  FLIGHT_REQUESTS_FETCH_FOCUSSED_IN_PROGRESS = 'FLIGHT_REQUESTS_FETCH_FOCUSSED_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FlightActivationStatus {
  IDLE = 'IDLE',
  FLIGHT_ACTIVATION_IN_PROGRESS = 'FLIGHT_ACTIVATION_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FlightRequestsCancelStatus {
  IDLE = 'IDLE',
  FLIGHT_REQUESTS_CANCEL_IN_PROGRESS = 'FLIGHT_REQUESTS_CANCEL_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FlightRequestsEndStatus {
  IDLE = 'IDLE',
  FLIGHT_REQUESTS_END_IN_PROGRESS = 'FLIGHT_REQUESTS_END_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FilterValue {
  FAVOURITE = 'favourite',
  CURRENT = 'current',
  PAST = 'past',
  ACTIVATED = 'activated',
}
