import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const useStyles = makeStyles({
  linkText: {
    color: 'blue',
    textDecoration: 'underline',
  },
});

interface LinkProps
  extends RouteComponentProps<{ navLocation: string; text: string }> {
  navLocation: string;
  text: string;
}

const NavigationLinkComponent = (props: LinkProps): JSX.Element => {
  const { history, navLocation, text } = props;

  const classes = useStyles();

  return (
    <button
      className={classes.linkText}
      type="button"
      onClick={() => history.push(navLocation)}
      data-testid="navigation-link"
    >
      {text}
    </button>
  );
};

export const NavigationLink = withRouter(NavigationLinkComponent);
