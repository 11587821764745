import { useSelector, useDispatch } from 'react-redux';
import { OperatorType } from '@airshare/external-api-types';

import { getProfile, getUpdateStatus } from './selectors';
import {
  profileUpdateRequested,
  profileFetchRequested,
  profileUpdateStatusReset,
} from './action-creators';

export function useProfile() {
  return useSelector(getProfile);
}

export function useFetchProfile() {
  const dispatch = useDispatch();
  return () => dispatch(profileFetchRequested());
}

export function useIsCommercialOperator() {
  const profile = useSelector(getProfile);

  return Boolean(
    profile?.pilot?.result?.operatorType === OperatorType.COMMERCIAL
  );
}

export function useUpdateProfile() {
  const dispatch = useDispatch();

  const updateStatus = useSelector(getUpdateStatus);

  const updateProfile = (profile: any) =>
    dispatch(profileUpdateRequested({ profile }));

  const resetStatus = () => dispatch(profileUpdateStatusReset());

  return {
    updateProfile,
    updateStatus,
    resetStatus,
  };
}
