import { useContext, useEffect, useState } from 'react';
import type { Warning } from '@airshare/external-api-types';

import type { Polygon } from '@turf/helpers';

import {
  useClearForm,
  useFormState,
  useSetFieldValue,
} from '../../../../../state/flight-plan/flight-plan-form/hooks';
import {
  useFlightPlanValidationResponse,
  useFlightPlanValidationStatus,
  useResetUploadedFlightArea,
  useResetFavourite,
} from '../../../../../state/flight-plan/hooks';
import {
  useUavs,
  usePilots,
} from '../../../../../state/flight-request-defaults/hooks';
import { useProfile } from '../../../../../state/profile/hooks';
import { GlobalState } from '../../../../../state/GlobalState';
import useManagedAreaFlightArea from './use-managed-area-flight-area.hook';

import { defaultBufferMetres } from '../../../../../lib/polygon-helpers';
import {
  useFlightAdvisoriesUpdated,
  useHighlightedFlightAdvisoriesUpdated,
} from '../../../../../state/flight-advisories/hooks';
import { useIsOrgAdmin } from '../../../../../state/session/hooks';
import { useResetFocussedFlightRequest } from '../../../../../state/flight-requests/hooks';

export const useResetFlightPlan = ({
  setWarnings,
  setAdvisoryGeometryLocal,
}: {
  setWarnings: React.Dispatch<React.SetStateAction<Warning[]>>;
  setAdvisoryGeometryLocal: (value: Polygon | null) => void;
}) => {
  const {
    setHighlightedSegments,
    setShowMyFlightMarkers,
    setSegmentedBufferRadius,
  } = useContext(GlobalState);

  const profile = useProfile();
  const isOrgAdmin = useIsOrgAdmin();

  const form = useFormState();
  const setClearForm = useClearForm();
  const setFieldValue = useSetFieldValue();

  const { resetFlightPlanValidation } = useFlightPlanValidationResponse();
  const resetUploadedFlightArea = useResetUploadedFlightArea();
  const resetFavourite = useResetFavourite();
  const { handleSelectFlightPathArea } = useManagedAreaFlightArea(form);
  const { resetFlightPlanValidationStatus: resetValidationStatus } =
    useFlightPlanValidationStatus();
  const setNearbyFlights = useFlightAdvisoriesUpdated();
  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();
  const resetFocussedFlightRequest = useResetFocussedFlightRequest();

  const [manualReset, setManualReset] = useState(false);

  const uavs = useUavs();
  const pilots = usePilots()?.sort((a, b) => a.name.localeCompare(b.name));

  function resetFlightPlan(fullReset: boolean = true) {
    if (fullReset) {
      resetFocussedFlightRequest();
      handleSelectFlightPathArea('None');
      resetUploadedFlightArea();
      resetFavourite();
      setClearForm();
      setManualReset((prev) => !prev);
      setSegmentedBufferRadius(defaultBufferMetres());
    }
    resetValidationStatus();
    resetFlightPlanValidation();
    setNearbyFlights([]);
    setHighlightedNearbyFlights([]);
    setHighlightedSegments([]);
    setShowMyFlightMarkers(true);
    setWarnings([]);
    setAdvisoryGeometryLocal(null);
  }

  useEffect(() => {
    if (uavs && !form.uavId) {
      const firstUav = uavs.find((uav) => Boolean(uav.name));

      if (firstUav) {
        setFieldValue('uavId', firstUav.id);
      }
    } else if (uavs && form.uavId) {
      setFieldValue('uavId', form.uavId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uavs, manualReset]);

  useEffect(() => {
    if (pilots?.length > 0) {
      setFieldValue('pilotId', `${pilots[0].id}`);
      setFieldValue('pilotName', pilots[0].name);
      setFieldValue('pilotEmail', pilots[0].email);

      if (isOrgAdmin) {
        setFieldValue('uavId', pilots[0].defaultUav);
        setFieldValue(
          'certificationNumber',
          pilots[0]?.certificationNumber?.trim()
        );
        setFieldValue(
          'certificationType',
          pilots[0]?.certificationType?.trim()
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pilots, manualReset]);

  useEffect(() => {
    if (profile && !isOrgAdmin) {
      const defaultUav = profile?.organisations
        ?.find((org) => org.defaultUav)
        ?.defaultUav?.toString();

      if (defaultUav) {
        setFieldValue('uavId', defaultUav);
      }

      setFieldValue(
        'certificationNumber',
        profile?.certificationNumber?.trim()
      );
      setFieldValue('certificationType', profile?.certificationType?.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, manualReset]);

  return resetFlightPlan;
};
