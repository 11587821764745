export enum FlightRequestWarningType {
  None = 'FlightDoesNotConflict', // There's no overlap with other flights

  // flights that overlap with a new flight request
  ConflictingFlight = 'ConflictingFlight', // will cause the newly created flight to be Conflicted state
  FlightToBeConflicted = 'FlightToBeConflicted', // will cause the existing flight to be Conflicted state
  ConflictingNotificationOnlyFlight = 'ConflictingNotificationOnlyFlight', // notification only, will not cause any flight to be Conflicted state

  AirspaceWarning = 'AirspaceWarning', // warning message from authorizer status
  NotamRequiredWarning = 'NotamRequiredWarning', // warning message that a NOTAM will be generated due to the altitude of the flight
}

export interface Warning {
  type: FlightRequestWarningType;
  message: string;
}

export interface WarningFlightDetails extends Warning {
  type:
    | FlightRequestWarningType.ConflictingFlight
    | FlightRequestWarningType.FlightToBeConflicted
    | FlightRequestWarningType.ConflictingNotificationOnlyFlight;
  startDateTime: string;
  endDateTime: string;
  managedAreaId?: string;
}

export interface WarningAirspaceStatus extends Warning {
  type: FlightRequestWarningType.AirspaceWarning;
}
