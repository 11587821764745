import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  FlightPathModeEnum,
  StyledSegmentFeatureCollectionJSONView,
} from '@airshare/external-api-types';

import type { Feature, Polygon, Position } from '@turf/helpers';

import { LatLng, StyledAreaResponse } from 'argus-common/interfaces';
import {
  AdvisorySource,
  type ExtendedFlightResponseBodyV2,
} from '@airshare/pilot-types';

import { FLIGHT_PLAN_PATH } from '../../../routes';

import {
  ProcessedPayload,
  usePreValidateFlightArea,
  useResetPreFlightValidateFlightArea,
} from '../../../state/flight-plan/pre-validate-flight-area/hooks';
import { useSetFieldValue } from '../../../state/flight-plan/flight-plan-form/hooks';

import {
  useSelectFavourite,
  useSelectedFavourite,
  useSelectFlightPathArea,
  useSelectedFlightPathArea,
  useUploadFlightArea,
  useUploadedFlightArea,
} from '../../../state/flight-plan/hooks';

import { DraftFlightAreaSource } from '~/state/flight-plan/flight-plan-form/types';
import { GlobalState } from '~/state/GlobalState';

import { clearFeatures, renderFlightAndCenter } from '../render-helpers';
import { cleanExistingFeatureCollection } from '../../shared/segments/segment-helpers';
import { getStyleForFeature } from './helpers';
import { payloadToPolygon } from '~/lib/polygon-helpers';

interface RenderDraftFlightAreaHook {
  flightAreaSource: DraftFlightAreaSource;
}

interface FlightFormFields {
  managedAreaId: string | null;
  coordinates: LatLng[] | null;
  pathMode: FlightPathModeEnum | null;
  radius: number;
  flightAreaSource: DraftFlightAreaSource;
  segmentCollection: StyledSegmentFeatureCollectionJSONView | null;
}

const flightPathToOverlayType = (
  flightPath: FlightPathModeEnum | null
): google.maps.drawing.OverlayType | null => {
  if (flightPath === null) {
    return null;
  }

  switch (flightPath) {
    case FlightPathModeEnum.CIRCLE:
      return google.maps.drawing.OverlayType.CIRCLE;

    case FlightPathModeEnum.POLYGON:
    case FlightPathModeEnum.MULTIPOLYGON:
    case FlightPathModeEnum.SEGMENTED:
    default:
      return google.maps.drawing.OverlayType.POLYGON;
  }
};

export function useRenderDraftFlightPlanArea(
  google: typeof window.google,
  mapInstance: google.maps.Map | null,
  pathname: string
): RenderDraftFlightAreaHook {
  const resetPreFlightValidateFlightArea =
    useResetPreFlightValidateFlightArea();
  const { preValidateFlightArea } = usePreValidateFlightArea();
  const setFlightPlanFormField = useSetFieldValue();
  const { setAdvisoryGeometry: setGlobalAdvisoryGeometry } =
    useContext(GlobalState);

  // External sources for area
  const flightPathArea: StyledAreaResponse | null = useSelectedFlightPathArea();
  const uploadedFlightArea = useUploadedFlightArea();
  const favourite: ExtendedFlightResponseBodyV2 = useSelectedFavourite();

  // Reset external sources - only one area source at a time
  const resetFlightPathArea: (payload: null) => void =
    useSelectFlightPathArea();
  const resetUploadedArea: (payload: null) => void = useUploadFlightArea();
  const resetFavourite: (payload: null) => void = useSelectFavourite();

  // Local state
  const [flightAreaSource, setFlightAreaSource] =
    useState<DraftFlightAreaSource>(DraftFlightAreaSource.NONE);
  const [flightAreaGeojson, setFlightAreaGeojson] =
    useState<Feature<Polygon> | null>(null);
  const [preValidationPayload, setPreValidationPayload] =
    useState<ProcessedPayload | null>(null);
  const [formFields, setFormFields] = useState<FlightFormFields | null>(null);
  const [advisoryGeometry, setAdvisoryGeometryLocal] = useState<Polygon>(null);

  const dataLayer: google.maps.Data | null = useMemo(() => {
    if (google && mapInstance) {
      const layer = new google.maps.Data({ map: mapInstance });
      layer.setStyle(getStyleForFeature);
      return layer;
    }
    return null;
  }, [google, mapInstance]);

  // Draw the flight area
  useEffect(() => {
    if (!pathname.endsWith(FLIGHT_PLAN_PATH) && dataLayer) {
      dataLayer.setMap(null);
    } else if (dataLayer && flightAreaGeojson) {
      clearFeatures(dataLayer);
      renderFlightAndCenter(mapInstance, dataLayer, flightAreaGeojson);
    } else if (!flightAreaGeojson) {
      setFlightAreaSource(DraftFlightAreaSource.NONE);
      setFormFields({
        managedAreaId: null,
        coordinates: null,
        pathMode: null,
        radius: 0,
        flightAreaSource: DraftFlightAreaSource.NONE,
        segmentCollection: null,
      });
      setPreValidationPayload(null);
    }

    return () => clearFeatures(dataLayer);
  }, [google, mapInstance, pathname, dataLayer, flightAreaGeojson]);

  // Send prevalidation on change or rerender
  useEffect(() => {
    if (preValidationPayload && pathname.endsWith(FLIGHT_PLAN_PATH)) {
      preValidateFlightArea(preValidationPayload);
    } else if (!preValidationPayload) {
      resetPreFlightValidateFlightArea();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preValidationPayload, pathname]);

  // Update form fields on change
  useEffect(() => {
    if (formFields) {
      setFlightPlanFormField('managedAreaId', formFields.managedAreaId);
      setFlightPlanFormField('coordinates', formFields.coordinates);
      setFlightPlanFormField('pathMode', formFields.pathMode);
      setFlightPlanFormField('radius', formFields.radius);
      setFlightPlanFormField('flightAreaSource', formFields.flightAreaSource);
      setFlightPlanFormField('segmentCollection', formFields.segmentCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields]);

  // Update geometry for advisory modal
  useEffect(() => {
    if (flightAreaGeojson?.geometry) {
      setAdvisoryGeometryLocal(flightAreaGeojson.geometry);
    }
  }, [flightAreaGeojson?.geometry, setAdvisoryGeometryLocal]);

  // Clear any other areas on update
  const clearOtherAreaTypes = useCallback((type: DraftFlightAreaSource) => {
    if (type !== DraftFlightAreaSource.MANAGEDAREA) {
      resetFlightPathArea(null);
    }
    if (type !== DraftFlightAreaSource.UPLOAD) {
      resetUploadedArea(null);
    }
    if (type !== DraftFlightAreaSource.FAVOURITE) {
      resetFavourite(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle flight path area selection
  useEffect(() => {
    if (google && mapInstance) {
      if (flightPathArea) {
        clearOtherAreaTypes(DraftFlightAreaSource.MANAGEDAREA);

        setFlightAreaSource(DraftFlightAreaSource.MANAGEDAREA);

        const latLngArray: LatLng[] = flightPathArea.geometry.coordinates
          .flat(1)
          .map((x: Position) => ({ lat: x[1], lng: x[0] }));

        setFormFields({
          managedAreaId: flightPathArea.id,
          coordinates: latLngArray,
          pathMode: FlightPathModeEnum.POLYGON,
          radius: 0,
          flightAreaSource: DraftFlightAreaSource.MANAGEDAREA,
          segmentCollection: null,
        });

        setPreValidationPayload({
          ...flightPathArea.geometry,
          managedAreaId: flightPathArea.id,
        } as ProcessedPayload);

        setFlightAreaGeojson({
          geometry: flightPathArea.geometry as Polygon,
          type: 'Feature',
          id: flightPathArea.id,
          properties: {},
        });
      } else if (flightAreaSource === DraftFlightAreaSource.MANAGEDAREA) {
        setFlightAreaGeojson(null);
        setAdvisoryGeometryLocal(null);
      }
    }
  }, [
    google,
    mapInstance,
    flightPathArea,
    flightAreaSource,
    setPreValidationPayload,
    clearOtherAreaTypes,
    setAdvisoryGeometryLocal,
  ]);

  // Handle uploaded flight area selection
  useEffect(() => {
    if (uploadedFlightArea) {
      clearOtherAreaTypes(DraftFlightAreaSource.UPLOAD);

      setFlightAreaSource(DraftFlightAreaSource.UPLOAD);

      const latLngArray: LatLng[] = uploadedFlightArea.geometry.coordinates
        .flat(1)
        .map((x: Position) => ({ lat: x[1], lng: x[0] }));

      setFormFields({
        managedAreaId: null,
        coordinates: latLngArray,
        pathMode: FlightPathModeEnum.POLYGON,
        radius: 0,
        flightAreaSource: DraftFlightAreaSource.UPLOAD,
        segmentCollection: null,
      });

      const flightArea = new google.maps.Polygon({
        paths: latLngArray,
      });
      const polygon = payloadToPolygon({
        type: google.maps.drawing.OverlayType.POLYGON,
        overlay: flightArea,
      });

      setPreValidationPayload({
        ...polygon,
        managedAreaId: null,
      });

      setFlightAreaGeojson({
        geometry: uploadedFlightArea.geometry,
        id: 'uploaded',
        type: 'Feature',
        properties: {},
      });
    } else if (flightAreaSource === DraftFlightAreaSource.UPLOAD) {
      setFlightAreaGeojson(null);
      setAdvisoryGeometryLocal(null);
    }
  }, [
    google,
    mapInstance,
    uploadedFlightArea,
    flightAreaSource,
    setPreValidationPayload,
    clearOtherAreaTypes,
    setAdvisoryGeometryLocal,
  ]);

  // Handle selected favourite
  useEffect(() => {
    if (favourite) {
      clearOtherAreaTypes(DraftFlightAreaSource.FAVOURITE);

      setFlightAreaSource(DraftFlightAreaSource.FAVOURITE);

      const {
        coordinates: rawCoordinates,
        pathMode,
        radius,
        managedAreaId,
        segmentCollection,
      } = favourite.properties;

      const coordinates: google.maps.LatLngLiteral[] = rawCoordinates.map(
        ({ Lng, Lat }) => ({
          lng: Lng,
          lat: Lat,
        })
      );

      setFormFields({
        managedAreaId: managedAreaId,
        coordinates:
          rawCoordinates.map(({ Lat, Lng }) => ({
            lat: Lat,
            lng: Lng,
          })) ?? null,
        pathMode,
        radius: radius || 0,
        flightAreaSource: DraftFlightAreaSource.FAVOURITE,
        segmentCollection: cleanExistingFeatureCollection(segmentCollection),
      });

      let flightArea;

      if (pathMode === FlightPathModeEnum.CIRCLE) {
        flightArea = new google.maps.Circle({
          center: coordinates[0],
          radius: radius,
          editable: !managedAreaId,
        });
      } else if (
        pathMode === FlightPathModeEnum.POLYGON ||
        pathMode === FlightPathModeEnum.SEGMENTED
      ) {
        flightArea = new google.maps.Polygon({
          paths: coordinates,
          editable: !managedAreaId,
        });
      }

      const polygon = payloadToPolygon({
        type:
          flightPathToOverlayType(pathMode) ??
          google.maps.drawing.OverlayType.POLYGON,
        overlay: flightArea,
      });

      setPreValidationPayload({
        ...polygon,
        managedAreaId: managedAreaId,
      });

      setFlightAreaGeojson({
        geometry: favourite.geometry as Feature<Polygon>['geometry'],
        id: `from-favourite-${favourite.properties.flightId}`,
        type: 'Feature',
        properties: {},
      });
    } else if (flightAreaSource === DraftFlightAreaSource.FAVOURITE) {
      setFlightAreaGeojson(null);
      setAdvisoryGeometryLocal(null);
    }
  }, [
    google,
    mapInstance,
    favourite,
    setPreValidationPayload,
    flightAreaSource,
    clearOtherAreaTypes,
    setAdvisoryGeometryLocal,
  ]);

  // promote advisory geometry up to global as final step
  // setting directly was causing endless loop that locks the map in place
  useEffect(() => {
    setGlobalAdvisoryGeometry(
      advisoryGeometry ? AdvisorySource.DraftFlightArea : AdvisorySource.None,
      advisoryGeometry
    );
  }, [advisoryGeometry, setGlobalAdvisoryGeometry]);

  return { flightAreaSource };
}
