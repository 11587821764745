import {
  call,
  put,
  takeLatest,
  takeEvery,
  race,
  take,
  delay,
  select,
} from 'redux-saga/effects';
import { DateTime } from 'luxon';
import {
  LOCATION_CHANGE,
  RouterLocation,
  getLocation,
} from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import type {
  ExtendedFlightResponseBodyV2,
  FlightRequestQueryResponseV2,
  Profile,
} from '@airshare/pilot-types';

import type { PagedResponse } from '@airshare/external-api-types';

import { pilotAPI } from '../../pilot-api-client/api';
import {
  getFavourites,
  getFlightRequest,
  getFlightRequests,
} from '../../pilot-api-client';
import { FLIGHT_BRIEFING_PATH, CLEARANCE_PATH } from '~/routes';
import {
  FETCH_REQUESTED,
  FETCH_FOCUSSED_REQUESTED,
  flightRequestsFetchRequested,
  flightRequestsFetchFailed,
  flightRequestsFetchSucceeded,
  flightRequestsFetchFocussedRequested,
  flightRequestsFetchFocussedSucceeded,
  flightRequestsFetchFocussedFailed,
  flightRequestsActivateFlightSucceeded,
  flightRequestsActivateFlightFailed,
  ACTIVATE_FLIGHT_REQUESTED,
  flightRequestsActivationRequested,
  flightRequestsActivationSucceeded,
  flightRequestsActivationFailed,
  REQUEST_ACTIVATION_REQUESTED,
  flightRequestsFocussedReset,
  flightRequestsCancelSucceeded,
  flightRequestsCancelFailed,
  CANCEL_REQUESTED,
  flightRequestsEndSucceeded,
  flightRequestsEndFailed,
  END_REQUESTED,
  flightRequestsStartedListening,
  STOPPED_LISTENING,
  STOPPED_LISTENING_TO_FOCUSSED,
  flightRequestsStartedListeningFocussed,
  flightRequestsStoppedListening,
  flightRequestsStoppedListeningFocussed,
  flightRequestsDeleteFavouriteSucceeded,
  flightRequestsDeleteFavouriteFailed,
  DELETE_FAVOURITE_REQUESTED,
} from './actions';
import {
  getFlightRequestsIsListening,
  getFlightRequestsIsListeningFocussed,
  isFocussedPastFlight,
} from './selectors';
import {
  FlightActivationStatus,
  FlightRequestsCancelStatus,
  FlightRequestsEndStatus,
  FlightRequestsFetchFocussedStatus,
  FlightRequestsFetchStatus,
} from './constants';

export interface PagesState {
  favourite: ExtendedFlightResponseBodyV2[] | null;
  current: ExtendedFlightResponseBodyV2[] | null;
  past: ExtendedFlightResponseBodyV2[] | null;
  activated: ExtendedFlightResponseBodyV2[] | null;
}

export interface PagesMetaState {
  favourite: PagedResponse;
  current: PagedResponse;
  past: PagedResponse;
  activated: null;
}

export interface State {
  pages: PagesState;
  meta: PagesMetaState;
  fetchPageStatus: FlightRequestsFetchStatus;
  focussed: ExtendedFlightResponseBodyV2;
  fetchFocussedStatus: FlightRequestsFetchFocussedStatus;
  cancelStatus: FlightRequestsCancelStatus;
  endStatus: FlightRequestsEndStatus;
  flightActivation: FlightActivationStatus;
  errorMessage: string;
  isListening: boolean;
  isListeningFocussed: boolean;
}

export function getCurrentFlightsPayload(pageIndex: number = 0) {
  return {
    afterDate: DateTime.now().toISO(),
    pageIndex,
    version: 2.1,
  };
}

export function getPastFlightsPayload(pageIndex: number = 0) {
  return {
    beforeDate: DateTime.now().toISO(),
    pageIndex,
    version: 2.1,
  };
}

export function* fetchFlightRequests({
  payload: { pageIndex },
}: {
  payload: { pageIndex?: number };
}) {
  try {
    const current: FlightRequestQueryResponseV2 = yield call(
      getFlightRequests,
      getCurrentFlightsPayload(pageIndex)
    );
    const favourite: FlightRequestQueryResponseV2 = yield call(getFavourites);
    const past: FlightRequestQueryResponseV2 = yield call(
      getFlightRequests,
      getPastFlightsPayload(pageIndex)
    );

    yield put(
      flightRequestsFetchSucceeded({
        favourite,
        current,
        past,
      })
    );
  } catch (e) {
    yield put(flightRequestsFetchFailed(e.message));
  }
}

function* deleteFavourite({ payload: { id } }: { payload: { id: string } }) {
  try {
    const { data } = yield call(pilotAPI.delete, `favourite/${id}?version=2.1`);

    yield put(
      flightRequestsDeleteFavouriteSucceeded({
        data,
      })
    );
  } catch (e) {
    yield put(flightRequestsDeleteFavouriteFailed(e.message));
  }
}

function* fetchFocussedFlightRequest({
  payload: { id, resetState = true },
}: {
  payload: { id: string; resetState: boolean };
}) {
  try {
    if (resetState) {
      yield put(flightRequestsFocussedReset());
    }

    const flightRequest: ExtendedFlightResponseBodyV2 = yield call(
      getFlightRequest,
      id
    );

    yield put(flightRequestsFetchFocussedSucceeded(flightRequest));
  } catch (e) {
    yield put(flightRequestsFetchFocussedFailed(e.message));
  }
}

function* clearFocussedFlightRequest({
  payload: { location },
}: {
  payload: any;
}) {
  try {
    const pathMatch =
      matchPath(location.pathname, {
        path: FLIGHT_BRIEFING_PATH,
        exact: true,
      }) || matchPath(location.pathname, { path: CLEARANCE_PATH, exact: true });

    const isViewingFocussedFlightRequest = pathMatch?.isExact;

    if (!isViewingFocussedFlightRequest) {
      yield put(flightRequestsFocussedReset());
    }
  } catch (e) {
    //
  }
}

function* cancelFlightRequest({
  payload: { id },
}: {
  payload: { id: string };
}) {
  try {
    yield call(pilotAPI.post, '/cancel-flight-request', { id });

    yield put(flightRequestsFetchFocussedRequested({ id, resetState: false }));
    yield put(flightRequestsFetchRequested({ pageIndex: 0 }));
    yield put(flightRequestsCancelSucceeded());
  } catch (e) {
    const { response } = e;
    const { message } = response && response.status === 400 ? response.data : e;
    yield put(flightRequestsCancelFailed(message));
  }
}

function* endFlightRequest({ payload: { id } }: { payload: { id: string } }) {
  try {
    yield call(pilotAPI.post, '/terminate-flight-request', { id });

    yield put(flightRequestsFetchFocussedRequested({ id, resetState: false }));
    yield put(flightRequestsFetchRequested({ pageIndex: 0 }));
    yield put(flightRequestsEndSucceeded());
  } catch (e) {
    const { response } = e;
    const { message } = response && response.status === 400 ? response.data : e;
    yield put(flightRequestsEndFailed(message));
  }
}

function* flightRequestActivateFlight({
  payload: { id },
}: {
  payload: { id: string };
}) {
  try {
    yield call(pilotAPI.put, `/v2/flight-requests/${id}/activate`);
    yield put(flightRequestsActivateFlightSucceeded({ id }));
    yield fetchFlightRequests({ payload: { pageIndex: 0 } });
  } catch (e) {
    const { response } = e;
    const error =
      response?.status === 400 ? response.data.errors?.join(', ') : e;
    yield put(flightRequestsActivateFlightFailed({ error }));
  }
}

function* flightRequestActivation({
  payload: { id },
}: {
  payload: { id: string };
}) {
  try {
    yield call(pilotAPI.put, `/v2/flight-requests/${id}/request-activation`);
    yield put(flightRequestsActivationRequested());
    yield put(flightRequestsActivationSucceeded());
  } catch (e) {
    const { response } = e;
    const { message } = response && response.status === 400 ? response.data : e;
    yield put(flightRequestsActivationFailed(message));
  }
}

function* pollflightRequest() {
  while (true) {
    // call every minute
    yield fetchFlightRequests({ payload: { pageIndex: 0 } });
    yield delay(1000 * 60);
  }
}

function* pollflightRequestFocussed(id: string, pollDelay = 1000 * 60) {
  while (true) {
    const isPastFlight: boolean = yield select(isFocussedPastFlight);

    if (isPastFlight) {
      yield put(flightRequestsStoppedListeningFocussed());
    }

    yield fetchFocussedFlightRequest({ payload: { id, resetState: false } });
    yield delay(pollDelay);
  }
}

function* startListeningToFlightRequests() {
  const isListening: boolean = yield select(getFlightRequestsIsListening);

  const profile: Profile = JSON.parse(localStorage.getItem('profile') || '{}');

  if (isListening && !profile.email) {
    yield put(flightRequestsStoppedListening());
  } else if (!isListening && profile.email) {
    yield put(flightRequestsStartedListening());

    yield race([call(pollflightRequest), take(STOPPED_LISTENING)]);
  }
}

export function* startListeningToFlightRequestFocussed() {
  const location: RouterLocation<{}> = yield select(getLocation);
  const isListening: boolean = yield select(
    getFlightRequestsIsListeningFocussed
  );

  const pathMatch =
    matchPath<{ flightId: string }>(location.pathname, {
      path: FLIGHT_BRIEFING_PATH,
      exact: true,
    }) ||
    matchPath<{ flightId: string }>(location.pathname, {
      path: CLEARANCE_PATH,
      exact: true,
    });
  const flightId = pathMatch?.params?.flightId ?? null;

  yield put(flightRequestsFocussedReset());

  if (isListening) {
    yield call(handleActivePolling, !!pathMatch, flightId);
  } else if (!isListening && pathMatch?.isExact) {
    yield call(startRetrievingFocussedFlight, flightId);
  }
}

export function* handleActivePolling(pathMatch: boolean, flightId?: string) {
  yield put(flightRequestsStoppedListeningFocussed());

  if (pathMatch) {
    yield call(startRetrievingFocussedFlight, flightId);
  }
}

export function* startRetrievingFocussedFlight(flightId: string) {
  yield put(flightRequestsStartedListeningFocussed());

  yield race([
    call(pollflightRequestFocussed, flightId, 5000),
    take(STOPPED_LISTENING_TO_FOCUSSED),
  ]);
}

export function* flightRequestsSaga() {
  yield takeLatest(FETCH_REQUESTED as any, fetchFlightRequests);
  yield takeLatest(FETCH_FOCUSSED_REQUESTED as any, fetchFocussedFlightRequest);
  yield takeEvery(CANCEL_REQUESTED as any, cancelFlightRequest);
  yield takeEvery(END_REQUESTED as any, endFlightRequest);
  yield takeEvery(
    ACTIVATE_FLIGHT_REQUESTED as any,
    flightRequestActivateFlight
  );
  yield takeEvery(REQUEST_ACTIVATION_REQUESTED as any, flightRequestActivation);
  yield takeEvery(LOCATION_CHANGE as any, clearFocussedFlightRequest);
  yield takeEvery(LOCATION_CHANGE, startListeningToFlightRequests);
  yield takeEvery(LOCATION_CHANGE, startListeningToFlightRequestFocussed);
  yield takeEvery(DELETE_FAVOURITE_REQUESTED as any, deleteFavourite);
  // * Fetch flight requests preemptively
  yield put(flightRequestsFetchRequested({ pageIndex: 0 }));
  // * Check if we are on the flight route and need to start listening
  yield startListeningToFlightRequests();
  yield startListeningToFlightRequestFocussed();
}
