import React from 'react';
import { SplashPage } from './splash-page';

export function ErrorPage() {
  return (
    <SplashPage
      primaryText="We seem to have encountered a problem"
      secondaryText={
        <span>
          Please{' '}
          <a href="/" style={{ color: 'var(--color-brand-primary)' }}>
            click here
          </a>{' '}
          to refresh
        </span>
      }
    ></SplashPage>
  );
}
