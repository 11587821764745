import {
  useContext,
  useEffect,
  useState,
  MutableRefObject,
  useRef,
} from 'react';
import { Cluster, Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { type RouterLocation } from 'connected-react-router';
import bbox from '@turf/bbox';
import distance from '@turf/distance';
import { type Geometry } from '@turf/helpers';
import { MapStyleCode } from 'airshare-pilot-web-shared';

import { GlobalState } from '../../../state/GlobalState';
import { useLocation } from '~/components/shared/hooks/router.hook';
import { DateTime } from 'luxon';

class OpenMarkerClusterer extends MarkerClusterer {
  public getMarkers = () => this.markers;
  public getClusters = () => this.clusters;
}

const MINIMUM_DISTANCE = 10;
export function useMapLayersStack(
  google: typeof window.google,
  mapInstance: google.maps.Map
) {
  const clusterRef: MutableRefObject<Map<string, OpenMarkerClusterer>> = useRef(
    new Map()
  );
  const markersPerLayerRef: MutableRefObject<Map<string, Map<string, Marker>>> =
    useRef(new Map());
  const geometriesRef: MutableRefObject<Map<string, any>> = useRef(new Map());
  const infowindowRef: MutableRefObject<google.maps.InfoWindow> = useRef(null);
  const [filterRangeDateTime, setFilterRangeDateTime] = useState({
    startDate: null,
    endDate: null,
  });

  const location = useLocation();

  const {
    mapLayers: globalMapLayers,
    mapStyleCode,
    mapBounds,
  } = useContext(GlobalState);

  const [mapLayers] = useState<Map<string, any>>(new Map<string, any>());
  const [lastMapStyleCode, setLastMapStyleCode] = useState<MapStyleCode>(null);
  const [lastZoom, setLastZoom] = useState(0);

  useEffect(() => {
    if (mapBounds && mapBounds.zoom !== lastZoom) {
      setLastZoom(mapBounds.zoom);
    }
  }, [lastZoom, mapBounds]);

  useEffect(() => {
    function getInfoWindow() {
      if (!infowindowRef.current) {
        infowindowRef.current = new google.maps.InfoWindow({
          content: 'contentString',
          ariaLabel: 'InfoWindow',
        });
      }
      const infowindow = infowindowRef.current;
      return infowindow;
    }

    if (globalMapLayers && google && mapInstance) {
      const infowindow = getInfoWindow();

      globalMapLayers.forEach((layer, layerName, _map) => {
        //Check if the layer is visible
        if (isLayerNotVisible(layer, location)) {
          hideAllElementsOnLayer(
            layerName,
            markersPerLayerRef,
            clusterRef,
            geometriesRef
          );
          return;
        }

        // Clear all geometries on this layer
        geometriesRef.current.get(layerName)?.forEach((geometry: any) => {
          geometry.dataLayer.setMap(null);
        });

        const markers = new Map<string, Marker>();
        const layers = new Map<string, any>();
        const hasMapStyleChange = mapStyleCode !== lastMapStyleCode;

        const filteredlayer = { ...layer };
        const filteredGeometrieslayer = filteredlayer.geometries.filter(
          (geometry: any) => {
            if (filterRangeDateTime.startDate && filterRangeDateTime.endDate) {
              const startDate = DateTime.fromISO(geometry.startDateTime || '');
              const endDate = DateTime.fromISO(geometry.endDateTime || '');
              return (
                startDate <= filterRangeDateTime.endDate &&
                endDate >= filterRangeDateTime.startDate
              );
            }
            return true;
          }
        );
        filteredlayer.geometries = [...filteredGeometrieslayer];
        removeOutdatedMarkers(clusterRef, layerName, filteredlayer);
        filteredGeometrieslayer.forEach((geometry: any) => {
          //Check if we need to add a new marker
          const currentMarkerCluster = clusterRef.current.get(layerName);

          let existingMarker: Marker = null;

          if (!hasMapStyleChange) {
            existingMarker = currentMarkerCluster
              ?.getMarkers()
              ?.find((mark: any) => mark.id === geometry.id);
          }
          if (!existingMarker) {
            const marker: Marker = new google.maps.Marker({
              map: layer.useMarkerCluster ? null : mapInstance,
              optimized: false,
              zIndex: 10000,
              position: { lat: geometry.center[1], lng: geometry.center[0] },
              ...(layer.getMarker
                ? { icon: layer.getMarker(geometry, mapInstance, google) }
                : {}),
              title: `Flight request #${geometry.id}`,
            });
            (marker as any).id = geometry.id;

            if (layer.useMarkerInfoWindow) {
              marker.addListener('click', () => {
                displayInfoWindow(
                  layer,
                  geometry,
                  infowindow,
                  marker,
                  mapInstance,
                  google
                );
              });
            }

            //Check if there is a marker too close to this one
            moveOverlappingMarker(markers, geometry, marker);

            markers.set(geometry.id, marker);
          } else {
            if (!layer.useMarkerCluster) {
              (existingMarker as any).setMap(mapInstance);
            }

            if (layer.getMarker) {
              (existingMarker as any).setIcon(
                layer.getMarker(geometry, mapInstance, google)
              );
            }
            if (geometry.selected) {
              const boundingBox = bbox({
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: geometry.geometry.coordinates,
                },
              });
              mapInstance.fitBounds({
                east: boundingBox[2],
                north: boundingBox[3],
                south: boundingBox[1],
                west: boundingBox[0],
              });
              google.maps.event.trigger(existingMarker, 'click');
            }
            markers.set(geometry.id, existingMarker);
          }

          showArea(
            mapInstance,
            google,
            layers,
            geometry,
            '',
            layer,
            mapStyleCode
          );
        });

        markersPerLayerRef.current.set(layerName, markers);
        geometriesRef.current.set(layerName, layers);

        if (layer.useMarkerInfoWindow) {
          updateInfoWindowContent(infowindowRef, layer);
        }

        if (layer.useMarkerCluster) {
          //Cluster wrap and redraw by itself, only need to call it if there are changes in the markers
          wrapMarkersAsCluster(
            clusterRef,
            layerName,
            mapInstance,
            google,
            markers
          );
        }
      });
      setLastMapStyleCode(mapStyleCode);
    }
  }, [
    globalMapLayers,
    google,
    mapInstance,
    location,
    mapStyleCode,
    lastMapStyleCode,
    lastZoom,
    filterRangeDateTime,
  ]);

  const handleFilterMapLayers = (startDate: DateTime, endDate: DateTime) => {
    setFilterRangeDateTime({ startDate, endDate });
  };

  return { mapLayers, filterRangeDateTime, handleFilterMapLayers };
}

function moveOverlappingMarker(
  markers: Map<string, Marker>,
  geometry: any,
  marker: google.maps.Marker
) {
  markers.forEach((markerItem) => {
    const position = (markerItem as any).getPosition();
    const dist = distance([position.lng(), position.lat()], geometry.center, {
      units: 'meters',
    });
    if (dist < MINIMUM_DISTANCE) {
      marker.setPosition({
        lat: geometry.center[1] + Math.random() / 10000,
        lng: geometry.center[0] + Math.random() / 10000,
      });
    }
  });
}

function removeOutdatedMarkers(
  clusterRef: MutableRefObject<Map<string, OpenMarkerClusterer>>,
  layerName: string,
  layer: any
) {
  let hasMarkerChanges: boolean = false;
  const currentMarkerCluster = clusterRef.current.get(layerName);
  currentMarkerCluster?.getMarkers().forEach((marker: any) => {
    if (!layer.geometries.find((item: any) => item.id === marker.id)) {
      hasMarkerChanges = true;
      currentMarkerCluster.removeMarker(marker);
    }
  });
  return hasMarkerChanges;
}

function displayInfoWindow(
  layer: any,
  geometry: any,
  infowindow: google.maps.InfoWindow,
  marker: Marker,
  mapInstance: google.maps.Map,
  google: any
) {
  //Get info window content
  const options = layer.getMarkerInfoWindowOptions(geometry);
  infowindow.setContent(options.content);
  infowindow.open({
    anchor: marker,
    map: mapInstance,
    shouldFocus: options.shouldFocus,
  });

  //Update info window style
  google.maps.event.addListener(infowindow, 'domready', function () {
    const elements = document.getElementsByClassName(
      'airspace-activity-flight-info'
    );
    if (elements.length === 0) {
      return;
    }
    const infoWindowElement = elements[0].parentNode?.parentNode?.parentElement;
    if (infoWindowElement) {
      infoWindowElement.style.backgroundColor = 'rgba(0,0,0,0.75)';
      infoWindowElement.style.border = 'border: solid 1px rgba(0,0,0,0.8);';
    }
  });
  google.maps.event.addListenerOnce(mapInstance, 'zoom_changed', function () {
    infowindow.close();
  });
}

function updateInfoWindowContent(
  infowindowRef: MutableRefObject<google.maps.InfoWindow>,
  layer: any
) {
  if ((infowindowRef.current as any).getMap()) {
    const geometry = layer.geometries.find(
      (item: any) => item.id === (infowindowRef.current as any).anchor.id
    );
    if (geometry) {
      const options = layer.getMarkerInfoWindowOptions(geometry);
      infowindowRef.current.setContent(options.content);
    }
  }
}

function wrapMarkersAsCluster(
  clusterRef: MutableRefObject<Map<string, OpenMarkerClusterer>>,
  layerName: string,
  mapInstance: google.maps.Map,
  google: typeof window.google,
  markers: Map<string, any>
) {
  const currentMarkerCluster = clusterRef.current.get(layerName);
  if (currentMarkerCluster) {
    if (mapInstance !== currentMarkerCluster.getMap()) {
      currentMarkerCluster.clearMarkers();
      clusterRef.current.get(layerName)?.setMap(null);
      clusterRef.current.delete(layerName);
    } else {
      const markersList = Array.from(markers, ([_, value]) => value);
      const currentMarkerlist = currentMarkerCluster.getMarkers();
      markersList.forEach((markerItem) => {
        if (
          !currentMarkerlist.find(
            (current: any) => current.id === markerItem.id
          )
        ) {
          currentMarkerCluster.addMarker(markerItem);
        }
      });
      currentMarkerCluster.getClusters().forEach((cluster: any) => {
        cluster.markers.forEach((markerItem: any) => {
          if (
            !markersList.find((current: any) => current.id === markerItem.id)
          ) {
            markerItem.setMap(null);
            currentMarkerCluster.removeMarker(markerItem);
          }
        });
      });

      currentMarkerCluster.render();
      return;
    }
  }
  const markerCluster = createClusterForLayer(mapInstance, google, markers);
  clusterRef.current.set(layerName, markerCluster);
}

function createClusterForLayer(
  mapInstance: google.maps.Map,
  google: typeof window.google,
  markers: Map<string, Marker>
) {
  return new OpenMarkerClusterer({
    markers: Array.from(markers, ([_, value]) => value),
    map: mapInstance,
    renderer: {
      render: (cluster: Cluster) => {
        const clusterColor = `rgba(0,0,255, ${cluster.count / 5})`;
        const clusterSVG = window.btoa(`
                  <svg fill="${clusterColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                    <circle cx="120" cy="120" opacity=".6" r="70" />
                    <circle cx="120" cy="120" opacity=".3" r="90" />
                    <circle cx="120" cy="120" opacity=".2" r="110" />
                    <circle cx="120" cy="120" opacity=".1" r="130" />
                  </svg>`);
        return new google.maps.Marker({
          position: cluster.position,
          icon: {
            url: `data:image/svg+xml;base64,${clusterSVG}`,
            scaledSize: new google.maps.Size(45, 45),
          },
          label: {
            text: String(cluster.count),
            color: `rgba(255,255,255)`,
            fontSize: '12px',
          },
          title: `Cluster of ${cluster.count} flights`,
        });
      },
    },
  });
}

function showArea(
  mapInstance: google.maps.Map,
  google: typeof window.google,
  geometriesMap: Map<string, any>,
  geometry: any,
  selectedId: string,
  layer: any,
  mapStyleCode: MapStyleCode
) {
  const id = `${geometry.id}`;
  if (geometriesMap.has(id)) {
    geometriesMap.get(id).dataLayer.setMap(mapInstance);
  } else {
    geometriesMap.set(id, getDataLayer(google, mapInstance, geometry.geometry));
  }

  geometriesMap
    .get(id)
    .dataLayer.setStyle((): any =>
      layer.getLayer(id, selectedId, geometry, mapStyleCode)
    );
}

function getDataLayer(
  google: typeof window.google,
  mapInstance: google.maps.Map,
  geometry: Geometry
) {
  const dataLayer = new google.maps.Data({
    map: mapInstance,
  });

  dataLayer.addGeoJson({
    type: 'Feature',
    geometry: {
      type: geometry.type,
      coordinates: geometry.coordinates,
    },
  });

  return {
    dataLayer,
  };
}

function hideMapElement(mapElement: any) {
  if (mapElement.setMap) {
    mapElement.setMap(null);
  }
  if (mapElement.dataLayer) {
    mapElement.dataLayer.setMap(null);
  }
}

/**
 * Hide all elements on a layer, including markers, clusters and geometries.
 *
 * @param layerName
 * @param markersRef
 * @param clusterRef
 * @param geometriesRef
 * @returns
 *
 */
function hideAllElementsOnLayer(
  layerName: string,
  markersRef: MutableRefObject<Map<string, any>>,
  clusterRef: MutableRefObject<Map<string, OpenMarkerClusterer>>,
  geometriesRef: MutableRefObject<Map<string, any>>
) {
  //Remove individual markers
  markersRef.current?.get(layerName)?.forEach(hideMapElement);
  //Remove cluster markers
  const cluster = clusterRef.current?.get(layerName);
  cluster?.getMarkers().forEach(hideMapElement);
  cluster?.clearMarkers();
  //Remove geometries
  geometriesRef.current?.get(layerName)?.forEach(hideMapElement);
}

function isLayerNotVisible(layer: any, location: RouterLocation<unknown>) {
  const isNotVisible =
    !layer.visible ||
    (!!layer.isVisibleOnUrl && !layer.isVisibleOnUrl(location.pathname));

  return isNotVisible;
}
