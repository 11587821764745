import { useCallback, useEffect, useMemo, useState } from 'react';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import {
  FeatureStyle,
  FlightAdvisoryResponse,
  getColorForStatus,
  getColorPropsFaded,
} from '@airshare/external-api-types';

import { getStyleForFeature } from './helpers';
import {
  ExtendedFlightResponseBodyV2,
  StyledFlightAdvisory,
} from '@airshare/pilot-types';

import {
  FlightAdvisoryMarkersWithFlight,
  addMarkerListeners,
  processFlightAdvisoriesMarkers,
} from '../../shared/flight-advisories/flight-advisories.helpers';
import { useFocussedFlightRequest } from '~/state/flight-requests/hooks';
import { MapHelpers, flightAdvisoryToPoints } from 'airshare-pilot-web-shared';
import { useDetailPanelIsVisible } from '../../shared/hooks/use-is-detail-panel-visible.hook';
import { clearFeatures } from '../render-helpers';

const clusterColor = '#222222';
const clusterSVG = window.btoa(`
<svg fill="${clusterColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);

export function useRenderFlightAdvisories(
  google: typeof window.google,
  map: google.maps.Map | null,
  flights: FlightAdvisoryResponse[] = [],
  highlightedFlights: StyledFlightAdvisory[] = [],
  setHighlightedNearbyFlights: (flights: StyledFlightAdvisory[]) => void,
  mapHelpers: MapHelpers | null
) {
  const [flightMarkers, setFlightMarkers] = useState<
    FlightAdvisoryMarkersWithFlight[]
  >([]);
  const [_markerClusterer, setMarkerClusterer] =
    useState<MarkerClusterer | null>(null);
  const rootFlightPathArea: ExtendedFlightResponseBodyV2 =
    useFocussedFlightRequest();
  const [rerender, setRerender] = useState(0);
  const [largestBounds, setLargestBounds] =
    useState<google.maps.LatLngBounds>();

  const detailPanelIsVisible = useDetailPanelIsVisible();

  const setFlightIsHighlighted = useCallback(
    (flightId: number, segmentId: string | undefined, style?: FeatureStyle) => {
      const flightToHighlight = flights?.find(
        (flight) =>
          flight.properties.flightId === flightId &&
          flight.properties.segmentId === segmentId
      );
      if (flightToHighlight && style) {
        setHighlightedNearbyFlights([
          {
            flightId: flightToHighlight.properties.flightId,
            segmentId: flightToHighlight.properties.segmentId,
            style,
          },
        ]);
      }
    },
    [flights, setHighlightedNearbyFlights]
  );

  const dataLayer: google.maps.Data | null = useMemo(() => {
    if (google && map) {
      const layer = new google.maps.Data({ map });
      layer.setStyle(getStyleForFeature);
      return layer;
    }
    return null;
  }, [google, map]);

  useEffect(() => {
    if (map) {
      const boundsListener = map.addListener('bounds_changed', () => {
        setRerender((c) => c + 1);
      });
      return () => boundsListener.remove();
    }
  }, [map]);

  useEffect(() => {
    if (rootFlightPathArea?.geometry) {
      const bounds = new google.maps.LatLngBounds();
      const points = flightAdvisoryToPoints(rootFlightPathArea);
      points[0].forEach((p) => {
        bounds.extend(p);
      });
      flights.forEach((f) =>
        flightAdvisoryToPoints(f)[0].forEach((p) => {
          bounds.extend(p);
        })
      );
      if (
        largestBounds?.getNorthEast().lat() !== bounds?.getNorthEast().lat() ||
        largestBounds?.getNorthEast().lng() !== bounds?.getNorthEast().lng() ||
        largestBounds?.getSouthWest().lat() !== bounds?.getSouthWest().lat() ||
        largestBounds?.getSouthWest().lng() !== bounds?.getSouthWest().lng()
      ) {
        setLargestBounds(bounds);
      }
    } else {
      setLargestBounds(undefined);
    }
  }, [
    flights,
    google.maps.LatLngBounds,
    largestBounds,
    rootFlightPathArea,
    detailPanelIsVisible,
  ]);

  useEffect(() => {
    clearFeatures(dataLayer);

    if (dataLayer && flights?.length) {
      const collection = {
        type: 'FeatureCollection',
        features: flights.map(({ geometry, properties }) => {
          const highlightedFlight = highlightedFlights.find(
            (f) =>
              f.flightId === properties.flightId &&
              f.segmentId === properties.segmentId
          );
          const flightColor = getColorForStatus(properties.status);
          const style = highlightedFlight
            ? highlightedFlight.style
            : getColorPropsFaded(flightColor);

          const flightLabel =
            `${properties.flightId}` +
            (properties.segmentId ? `-${properties.segmentId}` : '');

          return {
            type: 'Feature',
            geometry,
            id: flightLabel,
            properties: {
              clickable: false,
              editable: false,
              draggable: false,
              ...style,
            },
          };
        }),
      };
      dataLayer.addGeoJson(collection);
    }
    return () => clearFeatures(dataLayer);
  }, [dataLayer, flights, highlightedFlights]);

  useEffect(() => {
    processFlightAdvisoriesMarkers(
      flights,
      flightMarkers,
      map,
      detailPanelIsVisible,
      setFlightMarkers
    );
    return () => {
      if (flights?.length === 0) {
        flightMarkers.forEach((m) => {
          m.trueMarker.map = null;
          if (m.offscreenMarker) {
            m.offscreenMarker.map = null;
          }
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights, flightMarkers, map, detailPanelIsVisible, rerender]);

  useEffect(() => {
    if (map) {
      setMarkerClusterer((clusterer) => {
        if (clusterer) {
          clusterer.clearMarkers();
          clusterer.setMap(null);
        }
        return new MarkerClusterer({
          algorithmOptions: {
            maxZoom: 20,
          },
          markers: flightMarkers
            .map((x) => x.offscreenMarker)
            .filter((x) => x?.isVisible === true),
          map,
          onClusterClick: () => {
            setHighlightedNearbyFlights([]);
            if (largestBounds) {
              mapHelpers.fitToBoundsWithPadding(
                largestBounds,
                detailPanelIsVisible
              );
            } else {
              map.setZoom(map.getZoom() - 1);
            }
          },
          renderer: {
            render: (cluster: Cluster) => {
              const firstFlightId = cluster.markers
                .map((x: google.maps.marker.AdvancedMarkerElement) =>
                  x.title?.substring(7)
                )
                .sort()[0];
              return new google.maps.Marker({
                position: cluster.position,
                icon: {
                  url: `data:image/svg+xml;base64,${clusterSVG}`,
                  scaledSize: new google.maps.Size(45, 45),
                },
                label: {
                  text: String(cluster.count),
                  color: 'rgba(255,255,255,0.9)',
                  fontSize: '12px',
                },
                title: `${cluster.count} nearby flights: (${firstFlightId}, ...)`,
              });
            },
          },
        });
      });

      const cleanups = flightMarkers.map((m) => {
        return addMarkerListeners(
          map,
          mapHelpers,
          detailPanelIsVisible,
          m.trueMarker,
          m.flight,
          setFlightIsHighlighted,
          largestBounds,
          m.offscreenMarker
        );
      });

      return () => cleanups.forEach((c) => c());
    }
    return () => {
      flightMarkers.forEach((m) => {
        m.trueMarker.map = null;
        if (m.offscreenMarker) {
          m.offscreenMarker.map = null;
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPanelIsVisible, flightMarkers, map, largestBounds]);
}
