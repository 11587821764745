import React, { useState } from 'react';
import ReactPasswordStrength from 'react-password-strength';
import Typography from '@material-ui/core/Typography';

import './PasswordStrength.scss';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

export default function PasswordStrength({
  onPasswordChange,
  placeholder = 'Password',
  style = {
    border: 0,
    borderBottom: '1px solid',
    borderColor: '#999',
    fontSize: 12,
    margin: '18px 0 0 0',
  },
  label = '',
}: {
  onPasswordChange: Function;
  placeholder: string;
  style: Object;
  label: string;
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <div className="container-password-input">
      {label && (
        <Typography
          className="password-label"
          data-testid="pilot-web:reset-password-form:password-field-label"
        >
          {label}
        </Typography>
      )}
      <ReactPasswordStrength
        minLength={8}
        minScore={2}
        style={style}
        scoreWords={['very weak', 'weak', 'good', 'strong', 'stronger']}
        changeCallback={onPasswordChange}
        inputProps={{
          type: showPassword ? 'text' : 'password',
          id: 'inputPassword',
          placeholder,
          className: 'password-input',
        }}
        data-testid="password-input"
      />
      <IconButton
        className="password-visibility-button"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </div>
  );
}
