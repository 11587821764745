import {
  useFocussedFlightRequest,
  useFocussedFlightRequestFetchStatus,
} from '~/state/flight-requests/hooks';
import { FlightRequestsFetchFocussedStatus } from '~/state/flight-requests/constants';

export function useIsLoading() {
  const flightRequestsFetchFocussedStatus =
    useFocussedFlightRequestFetchStatus();
  const focussedFlight = useFocussedFlightRequest();

  return (
    !focussedFlight &&
    flightRequestsFetchFocussedStatus ===
      FlightRequestsFetchFocussedStatus.FLIGHT_REQUESTS_FETCH_FOCUSSED_IN_PROGRESS
  );
}
