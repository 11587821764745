import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AirshareUserRole } from 'argus-common/enums';
import { getError, getAccountIsActive, getProfile } from './selectors';
import { logOut, setAccountActive } from './action-creators';
import { GlobalState } from '../GlobalState';

export function useSessionErrors() {
  const errors = useSelector(getError);

  return errors;
}

export function useSignout() {
  const { resetGlobalState, socket } = useContext(GlobalState);

  const dispatch = useDispatch();

  function dispatchLogout() {
    socket?.disconnect();

    resetGlobalState();
    return dispatch(logOut());
  }

  return dispatchLogout;
}

export function useIsAccountActive() {
  const dispatch = useDispatch();

  const isActive = useSelector(getAccountIsActive);
  const setIsAccountActive = (status: any) =>
    dispatch(setAccountActive(status));

  return [isActive, setIsAccountActive];
}

export function useIsOrgAdmin(): boolean {
  const profile = useSelector(getProfile);
  return profile?.roles?.includes(AirshareUserRole.ORG_ADMIN);
}

export function useIsSuperAdmin(): boolean {
  const profile = useSelector(getProfile);
  return profile?.roles?.includes(AirshareUserRole.SUPER_ADMIN);
}

export function useIsPrivilegedViewer(): boolean {
  const profile = useSelector(getProfile);
  return profile?.roles?.includes(AirshareUserRole.PRIVILEGED_VIEWER);
}
