export enum SubscriptionEvent {
  SubscribeToFimsSurveillance = 'SUBSCRIBE_TO_FIMS_SURVEILLANCE',
  UnsubscribeFromFimsSurveillance = 'UNSUBSCRIBE_FROM_FIMS_SURVEILLANCE',
  SubscribeToSurveillance = 'SUBSCRIBE_TO_SURVEILLANCE',
  UnsubscribeFromSurveillance = 'UNSUBSCRIBE_FROM_SURVEILLANCE',
  SubscribeToPilotSurveillance = 'SUBSCRIBE_TO_PILOT_SURVEILLANCE',
  UnsubscribeFromPilotSurveillance = 'UNSUBSCRIBE_FROM_PILOT_SURVEILLANCE',
}

export enum ExternalSystemSubscriptionEvent {
  SubscribeToSurveillance = 'SUBSCRIBE_TO_SURVEILLANCE',
  UnsubscribeFromSurveillance = 'UNSUBSCRIBE_FROM_SURVEILLANCE',
}
