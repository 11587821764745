import { call, all, put, takeEvery } from 'redux-saga/effects';
import { contentAPI } from '../../pilot-api-client/api';
import { FETCH_REQUESTED } from './actions';
import {
  mapStylesFetchFailed,
  mapStylesFetchSucceeded,
} from './action-creators';

function* fetchMapStyles(): Generator<any, any, any> {
  try {
    const [standard, dark, gray] = yield all([
      call(contentAPI.get, '/map-styles/standard.json'),
      call(contentAPI.get, '/map-styles/dark.json'),
      call(contentAPI.get, '/map-styles/gray.json'),
    ]);

    const data = {
      standard: standard.data,
      gray: gray.data,
      dark: dark.data,
    };

    yield put(mapStylesFetchSucceeded(data));
  } catch (e) {
    yield put(mapStylesFetchFailed(e.message));
  }
}

export function* mapStylesSaga() {
  yield takeEvery(FETCH_REQUESTED, fetchMapStyles);
}
