import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import type { Feature, Polygon } from 'geojson';

import { FlightSegmentPropertiesJSONView } from '@airshare/external-api-types';

import { DateTime } from 'luxon';
import {
  getSegmentClearanceStatusLabel,
  getSegmentStatusLabel,
} from './segment-helpers';

export default function SegmentMarkerInfo(
  segment: Feature<Polygon, FlightSegmentPropertiesJSONView>,
  timezone: string
) {
  const formatAltitude = (altitude?: number, isAboveGroundLevel?: boolean) => {
    return altitude || altitude === 0
      ? `${altitude}ft ${isAboveGroundLevel ? 'AGL' : 'AMSL'}`
      : '';
  };
  const formatDatetime = (dateTimeIso: string): string =>
    dateTimeIso
      ? DateTime.fromISO(dateTimeIso)
          .setZone(timezone)
          .toFormat('yyyy-MM-dd HH:mm')
      : '';

  const renderListItem = (item: { key: string; value: string }) => {
    return item?.value ? (
      <ListItem style={{ display: 'flex' }} key={item.key}>
        <ListItemText
          style={{ maxWidth: 100, margin: 0 }}
          primaryTypographyProps={{
            style: { fontWeight: 600, width: 100, color: 'white' },
          }}
          primary={`${item.key}:`}
        />
        <ListItemText
          style={{ margin: 0 }}
          primaryTypographyProps={{ style: { float: 'left', color: 'white' } }}
          primary={item.value}
        />
      </ListItem>
    ) : null;
  };

  return (
    <List
      dense
      style={{ minWidth: 220, cursor: 'pointer' }}
      data-testid="segment-marker-info"
      id={`${segment.properties.id}-marker-info`}
    >
      {[
        { key: 'Segment ID', value: segment.properties.id },
        {
          key: 'Status',
          value: getSegmentStatusLabel(segment.properties.segmentStatus),
        },
        {
          key: 'Clearance',
          value: getSegmentClearanceStatusLabel(
            segment.properties.segmentClearanceStatus
          ),
        },
        {
          key: 'Starts At',
          value: formatDatetime(segment.properties.startDateTime),
        },
        {
          key: 'Ends At',
          value: formatDatetime(segment.properties.endDateTime),
        },
        {
          key: 'Launch Elev',
          value: formatAltitude(segment.properties.launchElevationFeet, false),
        },
        {
          key: 'Lower Alt',
          value: formatAltitude(segment.properties.minAltitudeFeet, true),
        },
        {
          key: 'Upper Alt',
          value: formatAltitude(segment.properties.maxAltitudeFeet, true),
        },
      ].map(renderListItem)}
    </List>
  );
}
