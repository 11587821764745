/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  LoadingButton,
  COMPLETION_DELAY,
  useTimeoutEffect,
} from 'airshare-pilot-web-shared';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { completePolicyAcceptance } from '~/state/session/action-creators';
import {
  getCompleteLoginData,
  getPoliciesToAccept,
} from '~/state/session/selectors';
import { LogInStatus } from '../../../../state/session/constants';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { useLogin } from './login.hook';
import { EndUserPolicyToAccept } from '@airshare/pilot-types';

interface Props extends RouteComponentProps {
  redirectUrl: string;
}

interface PolicyAcceptance extends EndUserPolicyToAccept {
  accepted: boolean;
}

function EndUserPolicyAcceptanceComponent({ redirectUrl, history }: Props) {
  const dispatch = useDispatch();
  const completeLoginData = useSelector(getCompleteLoginData);
  const requiredPolicies = useSelector(getPoliciesToAccept);
  const navigateTo = useNavigateTo();
  const [loginStatus] = useLogin();
  const [policiesToAccept, setPoliciesToAccept] = useState<PolicyAcceptance[]>(
    requiredPolicies || []
  );
  const [allPoliciesAccepted, setAllPoliciesAccepted] = useState(false);

  const accept = () => {
    const acceptedPolicies = policiesToAccept.map((p) => {
      return {
        policyId: p.policyId,
        version: p.version,
      };
    });

    dispatch(completePolicyAcceptance({ completeLoginData, acceptedPolicies }));
  };

  useTimeoutEffect(
    () => {
      navigateTo(redirectUrl);
    },
    COMPLETION_DELAY,
    loginStatus === LogInStatus.SUCCESS
  );

  useEffect(() => {
    if (requiredPolicies === null) {
      history.push(`${redirectUrl}/signin`);
    }
  }, []);

  useEffect(() => {
    if (policiesToAccept.length > 0) {
      const allAccepted = policiesToAccept.every((p) => p.accepted);
      setAllPoliciesAccepted(allAccepted);
    }
  }, [policiesToAccept]);

  function togglePolicyAcceptance(policyId: string) {
    const newPolicyList = policiesToAccept.slice(0);

    const policy = newPolicyList.find((p) => p.policyId === policyId);
    policy.accepted = !policy.accepted;

    setPoliciesToAccept(newPolicyList);
  }

  return (
    <div data-testid="pilot-web:policy-accept:form">
      <Typography
        className="error-message"
        variant="h6"
        align="center"
        style={{ marginBottom: '12px' }}
      >
        Please review and accept the policies below
      </Typography>

      {policiesToAccept.map((policy) => (
        <Grid
          container
          spacing={0}
          key={policy.policyId}
          style={{ marginBottom: '12px' }}
        >
          <Grid item xs={10}>
            <Typography
              align="left"
              data-testid={`pilot-web:policy-accept:policy-name-${policy.policyId}`}
              style={{ fontWeight: '600', fontSize: '16px' }}
            >
              {policy.policyName}
            </Typography>
            {policy.changeDescription && (
              <Typography
                variant="body2"
                align="left"
                data-testid={`pilot-web:policy-accept:policy-changes-${policy.policyId}`}
              >
                Changes: {policy.changeDescription}
              </Typography>
            )}
            <a
              target="_blank"
              style={{
                textDecoration: 'underline',
                fontSize: '14px',
                color: 'blue',
                top: '0',
              }}
              className="end-user-policy-view-button"
              href={policy.url}
              rel="noreferrer"
            >
              View {policy.policyName}
            </a>
          </Grid>
          <Grid item xs={1}>
            <Checkbox
              name={`accept-policy${policy.policyId}`}
              size="small"
              color="primary"
              checked={policy.accepted}
              onChange={() => togglePolicyAcceptance(policy.policyId)}
              data-testid={`pilot-web:policy-accept:accept-checkbox-${policy.policyId}`}
            />
          </Grid>
        </Grid>
      ))}

      <LoadingButton
        className="form-control"
        type="submit"
        onClick={accept}
        disabled={!allPoliciesAccepted}
        data-testid="pilot-web:policy-accept:accept-button"
      >
        Accept
      </LoadingButton>
    </div>
  );
}

export const EndUserPolicyAcceptance = withRouter(
  EndUserPolicyAcceptanceComponent
);
