import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { makeStyles } from '@material-ui/styles';

import { StatusPill } from 'airshare-pilot-web-shared';
import {
  ExtendedFlightResponseBodyV2,
  PilotOnlyStatusCode,
} from '@airshare/pilot-types';

import { getFlightBriefingPath } from '../../../../routes';
import { getOverallPilotFacingStatus } from '~/lib/flight-helpers';

const useStyles = makeStyles({
  conflictWarning: {
    color: '#ff9800',
  },
});

export default function FlightCard({
  flightRequest,
}: {
  flightRequest: ExtendedFlightResponseBodyV2;
}) {
  const classes = useStyles();
  const {
    startDateTime,
    flightId,
    additionalInfo,
    timezone,
    conflictIds,
    faConflictIds,
  } = flightRequest.properties;
  const { nickname, flightPurpose } = additionalInfo;

  const overallStatus = getOverallPilotFacingStatus(flightRequest);
  const isConflicting = Boolean(conflictIds?.length || faConflictIds?.length);
  const showWarning =
    overallStatus === PilotOnlyStatusCode.AcceptanceReq ||
    isConflicting ||
    flightRequest?.properties?.allStatuses?.find((s) => s.warningMessage);
  return (
    <Link
      id={`flight-info-${flightId}`}
      to={getFlightBriefingPath(flightId.toString())}
      className="card"
      data-testid="pilot-web:flight-briefing:flight-card"
    >
      {showWarning && (
        <WarningIcon
          id={`flight-info-warning-icon-${flightId}`}
          className="warning-icon"
          data-testid="pilot-web:flight-briefing:flight-warning-icon"
        />
      )}
      <div className="card-header-grid">
        <Typography
          id={`flight-info-id-${flightId}`}
          className="flight-id"
          data-testid="pilot-web:flight-briefing:flight-id"
        >
          Flight ID: #{flightId}
        </Typography>
        <StatusPill
          id={`flight-status-${flightId}`}
          className="status"
          status={overallStatus}
        />
      </div>
      {nickname && (
        <Typography
          id={`flight-info-name-${flightId}`}
          className="nickname"
          variant="subtitle2"
          data-testid="pilot-web:flight-briefing:flight-nickname"
        >
          {nickname}
        </Typography>
      )}
      {isConflicting && (
        <Typography
          id={`flight-info-name-${flightId}`}
          className={`${classes.conflictWarning} conflicts-label`}
          variant="subtitle2"
          data-testid="pilot-web:flight-card:flight-conflict"
        >
          Conflicts exist
        </Typography>
      )}
      <div className="card-detail-grid">
        <Typography
          id={`flight-info-type-${flightId}`}
          className="flight-type"
          data-testid="pilot-web:flight-briefing:flight-type"
        >
          {flightPurpose}
        </Typography>
        <div className="conditions">
          <Typography
            id={`flight-info-date-${flightId}`}
            className="date"
            data-testid="pilot-web:flight-briefing:flight-date"
          >
            {DateTime.fromISO(startDateTime, {
              zone: timezone,
            }).toFormat('dd LLL yyyy - HH:mm ZZZZ')}
          </Typography>
        </div>
      </div>
    </Link>
  );
}
