import * as yup from 'yup';
import { createFormReducer } from 'airshare-web-utils/redux-forms/index.ts';
import { OperatorType } from '@airshare/external-api-types';

import { actions, REGISTRATION_FORM_KEY } from './actions';
import { MobileRegex } from '@airshare/pilot-types';

const isEuroRegulation =
  window.env.EURO_REGULATIONS?.toLowerCase() === 'true' || false;

export const schema = yup.object().shape({
  fullName: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  mobileNo: yup
    .string()
    .required('Required')
    .test({
      name: 'is-valid-phone',
      message: 'mobileNo must be a valid phone number',
      test: (value) => {
        if (value) {
          return MobileRegex.test(value);
        }
      },
    }),
  password: yup
    .string()
    .min(8, 'A minimum of 8 characters is required')
    .required('Required'),
  operatorType: yup
    .string()
    .oneOf([OperatorType.RECREATIONAL, OperatorType.COMMERCIAL]),
  certificationNumber: yup.string(),
  businessName: yup.string().when('operatorType', {
    is: OperatorType.COMMERCIAL,
    then: yup.string().required('Business name is required'),
  }),
  registrationNo: isEuroRegulation
    ? yup
        .string()
        .max(20, 'Registration number must be 20 characters or less')
        .required('Required')
    : yup.string().nullable(),
});

const defaultState = {
  fullName: '',
  email: '',
  mobileNo: '',
  registrationNo: '',
  password: '',
  operatorType: OperatorType.RECREATIONAL,
  certificationNumber: '',
  businessName: '',
};

export const registrationFormReducer = {
  [REGISTRATION_FORM_KEY]: createFormReducer(actions, defaultState, schema),
};
