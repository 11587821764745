import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FRStatusCode } from '@airshare/external-api-types';
import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';
import { NavigationLink } from '../../../shared/navigation-link/navigation-link';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    importantInfoTowerWatch: {
      textAlign: 'center',
      background: 'rgb(241, 241, 241)',
      borderRadius: '5px',
      padding: '0.5rem',
    },
  })
);

function createList(header: string, listItems: string[]) {
  return (
    <>
      <Typography className="section-heading" variant="subtitle2">
        {header}
      </Typography>
      <ul className="list">
        {listItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </>
  );
}

function requestAuthorizationMessage(
  flight: ExtendedFlightResponseBodyV2,
  atcHasDigitalAuthEnabled: boolean
) {
  const {
    status,
    allocatedControlZone,
    isShielded,
    flightId,
    segmentCollection,
  } = flight.properties;

  if (status === FRStatusCode.Authorized) {
    return (
      <>
        <Typography variant="body2">
          You have received provisional and/or final authorisations from the
          organisations listed above, however you many need additional
          authorisations to meet your obligations under CAA rules.
        </Typography>
        <br />
      </>
    );
  }

  const intersectsControlZone = Boolean(allocatedControlZone?.code);

  const segmentManagement =
    segmentCollection?.features?.length > 1 &&
    [
      FRStatusCode.Declared.toString(),
      FRStatusCode.ProvAuth.toString(),
      FRStatusCode.Activated.toString(),
    ].includes(status?.toString());

  if (!isShielded && intersectsControlZone) {
    const towerPhone = allocatedControlZone?.phone;

    let message = '';
    if (status === FRStatusCode.Pending) {
      if (atcHasDigitalAuthEnabled) {
        message =
          'Your flight is currently awaiting provisional authorisation from ATC. Once you gain provisional authorisation, you must request final authorisation immediately prior to your flight from the AirShare web or mobile app.';
      } else {
        message = `Your flight is currently awaiting provisional authorisation from ATC. 
        Once you gain provisional authorisation, you must request final authorisation
        15 minutes prior to your flight by calling the tower on ${towerPhone}`;
      }
    } else if (
      status === FRStatusCode.ProvAuth &&
      atcHasDigitalAuthEnabled &&
      !segmentManagement
    ) {
      return (
        <>
          <Typography variant="body2" display="inline">
            You must obtain final authorisation for this flight immediately
            prior to launch. Digital final activation is available
          </Typography>
          <Typography variant="body2" display="inline">
            <NavigationLink
              text="here"
              navLocation={`/clearance/${flightId}`}
            />
          </Typography>
        </>
      );
    } else if (segmentManagement) {
      message =
        'You must obtain final authorisation for each segment of this flight. Manage the segments using the Activate Segments button on the top of the flight details page.';
    } else if (
      status === FRStatusCode.Activated ||
      status === FRStatusCode.Terminated
    ) {
      message = '';
    } else {
      const towerPhoneString = towerPhone ? `on ${towerPhone} ` : '';
      message = `You must call the ATC tower 15 minutes before your flight ${towerPhoneString}to obtain final authorisation to operate.`;
    }

    return (
      <>
        {message && (
          <>
            <Typography variant="body2">{message}</Typography>
            <br />
          </>
        )}
        <Typography variant="body2">
          At the end of your flight you must either press the terminate button
          for the flight on your AirShare app OR phone the ATC tower to advise
          them you have landed.
        </Typography>
        <br />
      </>
    );
  } else if (
    !isShielded &&
    !intersectsControlZone &&
    status === FRStatusCode.Pending
  ) {
    return (
      <>
        <Typography variant="body2">
          Your flight is awaiting authorisation from the authorisers listed
          above.
        </Typography>
        <br />
      </>
    );
  }

  return null;
}

function renderTowerSchedule(towerName: string, scheduleDay: string[]) {
  return (
    <>
      <Typography
        variant="body1"
        data-testid="pilot-web:flight-briefing-panel:tower-schedule"
      >
        {towerName} Tower watch hours are:
      </Typography>
      {scheduleDay.length === 1 ? (
        <Typography variant="body2" style={{ margin: '5px 10px' }}>
          {scheduleDay.map((range) => (
            <span key={range}>{range}</span>
          ))}
        </Typography>
      ) : (
        <ul className="tower-schedule-list">
          {scheduleDay.map((range) => (
            <li key={range}>{range}</li>
          ))}
        </ul>
      )}
      <Typography variant="subtitle2" style={{ fontStyle: 'italic' }}>
        Hours are subject to change
      </Typography>
      <br />
    </>
  );
}

const uncontrolledAerodromes = 'Other uncontrolled aerodromes for your flight';
const propertyOwners = 'Property owners for your flight';
const catchAll =
  'Any other administering authorities, for example Military Operating Areas or Restricted Areas for your flight';

const airshareDoesNot = createList('You must also gain authorisation from:', [
  propertyOwners,
  catchAll,
]);

const airshareDoesNotNotManaged = createList(
  'You must also gain authorisation from:',
  [uncontrolledAerodromes, propertyOwners, catchAll]
);

const aerodromeAuthorization = createList('Aerodrome authorisation:', [
  'You will receive a decision about this request from this aerodrome in AirShare.',
  'Please check this app and your email for any updates.',
]);

const propertyConsent = createList(
  'If your flight is over land not owned by you:',
  ['Contact the property owner directly to ask for consent to fly']
);

const within4kAerodrome = createList(
  'Within 4km of an uncontrolled aerodrome:',
  ['Use this app to find the aerodrome phone number and call them directly']
);

export const ImportantInfo = ({
  flight,
  atcHasDigitalAuthEnabled,
}: {
  flight: ExtendedFlightResponseBodyV2;
  atcHasDigitalAuthEnabled: boolean;
}) => {
  const { aerodromes, controlledAerodromes, allocatedControlZone } =
    flight.properties;
  const intersectsManaged = controlledAerodromes?.length > 0;
  const intersectsUnmanaged = !intersectsManaged && aerodromes?.length > 0;

  return (
    <>
      {allocatedControlZone?.scheduleDay &&
        allocatedControlZone?.scheduleDay.length > 0 &&
        renderTowerSchedule(
          allocatedControlZone.name,
          allocatedControlZone.scheduleDay
        )}
      {requestAuthorizationMessage(flight, atcHasDigitalAuthEnabled)}
      {intersectsManaged && aerodromeAuthorization}
      {!intersectsManaged ? airshareDoesNotNotManaged : airshareDoesNot}
      {intersectsUnmanaged && !intersectsManaged && within4kAerodrome}
      {propertyConsent}
    </>
  );
};
