import { createAction, LOG_OUT } from 'airshare-web-utils/redux-helpers';
import * as actions from './actions';

export const logOut = createAction(LOG_OUT);
export const setProfile = createAction(actions.SET_PROFILE);
export const setError = createAction(actions.SET_ERROR);
export const setCompleteLoginData = createAction(
  actions.SET_COMPLETE_LOGIN_DATA
);
export const setPoliciesToAccept = createAction(actions.SET_POLICIES_TO_ACCEPT);
export const completePolicyAcceptance = createAction(
  actions.COMPLETE_POLICY_ACCEPTANCE
);

export const setLoginStatus = createAction(actions.SET_LOGIN_STATUS);
export const attemptLogin = createAction(actions.ATTEMPT_LOG_IN);

export const setRegistrationStatus = createAction(
  actions.SET_REGISTRATION_STATUS
);
export const attemptRegistration = createAction(actions.ATTEMPT_REGISTRATION);

export const setActivationStatus = createAction(actions.SET_ACTIVATION_STATUS);
export const attemptActivation = createAction(actions.ATTEMPT_ACTIVATION);

export const setAccountActive = createAction(actions.SET_IS_ACCOUNT_ACTIVE);

export const setResendActivationStatus = createAction(
  actions.SET_RESEND_ACTIVATION_EMAIL_STATUS
);
export const attemptResendActivationEmail = createAction(
  actions.ATTEMPT_RESEND_ACTIVATION_EMAIL
);
