import * as yup from 'yup';
import { MobileRegex, OperatorType } from '@airshare/pilot-types';

import { createFormReducer } from 'airshare-web-utils/redux-forms/index';
import { actions } from './actions';

const isEuroRegulation =
  window.env.EURO_REGULATIONS?.toLowerCase() === 'true' || false;

export interface UpdateProfileFormState {
  fullName?: string;
  email?: string;
  mobileNo?: string;
  dateOfBirth?: Date | null;
  password?: string;
  operatorType?: OperatorType;
  certificationNumber?: string;
  businessName?: string;
  notifyConflicts?: boolean;
  shareContactInfo?: boolean;
  registrationNo?: string;
}

export const schema: yup.SchemaOf<UpdateProfileFormState> = yup.object().shape({
  fullName: yup
    .string()
    .required('Required')
    .test({
      name: 'fullName split',
      message: 'First and Last name must each be 20 characters or less',
      test: (value) => {
        const [givenName, ...familyNames] = value.split(' ').filter((r) => r);
        const familyName = familyNames.join(' ');
        return givenName?.length <= 20 && familyName?.length <= 20;
      },
    }),
  email: yup.string().email().required('Required'),
  mobileNo: yup
    .string()
    .required('Required')
    .test({
      name: 'is-valid-phone',
      message: 'mobileNo must be a valid phone number',
      test: (value) => {
        if (value) {
          return MobileRegex.test(value);
        }
      },
    }),
  dateOfBirth: yup.mixed().nullable(),
  operatorType: yup
    .mixed()
    .oneOf([OperatorType.COMMERCIAL, OperatorType.RECREATIONAL]),
  certificationNumber: yup.string().nullable(),
  businessName: yup
    .string()
    .when('operatorType', {
      is: OperatorType.COMMERCIAL,
      then: yup
        .string()
        .required('Business name is required')
        .max(50, 'Business name must be 50 characters or less'),
    })
    .nullable(),
  registrationNo: isEuroRegulation
    ? yup
        .string()
        .max(20, 'Registration number must be 20 characters or less')
        .required('Required')
    : yup.string().nullable(),
  password: yup.string(),
  notifyConflicts: yup.boolean(),
  shareContactInfo: yup.boolean(),
});

const defaultState: UpdateProfileFormState = {
  fullName: '',
  email: '',
  mobileNo: '',
  dateOfBirth: null,
  password: '',
  operatorType: OperatorType.RECREATIONAL,
  certificationNumber: '',
  businessName: '',
  notifyConflicts: false,
  shareContactInfo: false,
  registrationNo: '',
};

export const updateProfileFormReducer =
  createFormReducer<UpdateProfileFormState>(actions, defaultState, schema);
