import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { COMPLETION_DELAY } from 'airshare-pilot-web-shared';

import { pilotAPI } from '../../pilot-api-client/api';
import { UAVS_PATH, getUavDetailPath } from '~/routes';
import { flightRequestDefaultsFetchRequested } from '~/state/flight-request-defaults/actions';
import {
  getUavDefaultWeights,
  getUavDefaultCClasses,
} from '~/state/uav-defaults/selectors';
import {
  CREATION_REQUESTED,
  FETCH_REQUESTED,
  uavsFetchRequested,
  uavsFetchFailed,
  uavsFetchSucceeded,
  uavsCreationFailed,
  uavsCreationSucceeded,
  uavsDeletionSucceeded,
  uavsDeletionFailed,
  uavsUpdateFailed,
  uavsUpdateSucceeded,
  DELETION_REQUESTED,
  UPDATE_REQUESTED,
  uavsStateReset,
} from './actions';
import { actionCreators } from './add-uav-form/actions';

function* fetchUavs({ payload: { pageIndex } }) {
  try {
    const res = yield call(pilotAPI.get, `/uavs?pageIndex=${pageIndex}`);

    // ! FIXME: Exponent are returning duplicate UAVs in the set so we uniqBy id here:
    yield put(
      uavsFetchSucceeded({
        ...res.data,
        data: uniqBy(res.data.data, ({ id }) => id),
      })
    );
  } catch (e) {
    yield put(uavsFetchFailed(e.message));
  }
}

function* createUav({ payload }) {
  const isEuroRegulation = process.env.EURO_REGULATIONS === 'true';

  try {
    const uavDefaultWeight = yield select(getUavDefaultWeights);
    const uavDefaultCClasses = yield select(getUavDefaultCClasses);
    const weight = uavDefaultWeight?.find(
      (w) => w.name === payload.weight
    )?.code;
    const cClass = isEuroRegulation
      ? uavDefaultCClasses?.find((c) => c.name === payload.cClass)?.code
      : undefined;
    const res = yield call(pilotAPI.post, '/uavs', {
      ...payload,
      weight,
      cClass,
    });

    yield put(uavsCreationSucceeded(res.data));

    // * Wait for completion animation to complete before routing.
    yield delay(COMPLETION_DELAY);

    yield put(actionCreators.formCleared());
    yield put(uavsStateReset());
    // ! FIXME This is a bit hacky
    yield put(uavsFetchRequested(0));
    yield put(flightRequestDefaultsFetchRequested());

    if (payload.referrer) {
      yield put(push(payload.referrer));
    } else {
      yield put(push(getUavDetailPath(res.data.id)));
    }
  } catch (error) {
    const defaultError =
      'We were unable to create a UAV. Please try again later';

    let errorMessage = get(error, 'response.data.message');
    const errors = get(error, 'response.data.errors', undefined);

    if (!errorMessage && errors && errors.length > 0) {
      errorMessage = errors.join(', ');
    }

    yield put(
      actionCreators.formErrored({
        message: errorMessage || defaultError,
      })
    );
    yield put(uavsCreationFailed(error));
  }
}

function* updateUav({ payload }) {
  const isEuroRegulation = process.env.EURO_REGULATIONS === 'true';

  try {
    const uavDefaultWeight = yield select(getUavDefaultWeights);
    const uavDefaultCClasses = yield select(getUavDefaultCClasses);
    const weight = uavDefaultWeight?.find(
      (w) => w.name === payload.weight
    )?.code;
    const cClass = isEuroRegulation
      ? uavDefaultCClasses?.find((c) => c.name === payload.cClass)?.code
      : undefined;

    const res = yield call(pilotAPI.put, `/uavs/${payload.id}`, {
      ...payload,
      weight,
      cClass,
    });

    yield put(uavsUpdateSucceeded(res.data));

    // * Wait for completion animation to complete before routing.
    yield delay(COMPLETION_DELAY);

    yield put(actionCreators.formCleared());
    yield put(uavsStateReset());
    // ! FIXME This is a bit hacky
    yield put(uavsFetchRequested(0));
    yield put(flightRequestDefaultsFetchRequested());

    if (payload.referrer) {
      yield put(push(payload.referrer));
    } else {
      yield put(push(getUavDetailPath(res.data.id)));
    }
  } catch (error) {
    const defaultError = 'We were unable to update UAV. Please try again later';

    let errorMessage = get(error, 'response.data.message');
    const errors = get(error, 'response.data.errors', undefined);

    if (!errorMessage && errors && errors.length > 0) {
      errorMessage = errors.join(', ');
    }

    yield put(
      actionCreators.formErrored({
        message: errorMessage || defaultError,
      })
    );
    yield put(uavsUpdateFailed(error));
  }
}

function* deleteUav({ payload: { id } }) {
  try {
    yield call(pilotAPI.delete, `/uavs/${id}`);

    yield put(uavsDeletionSucceeded(id));

    yield delay(COMPLETION_DELAY);

    yield put(uavsStateReset());
    yield put(uavsFetchRequested(0));
    yield put(flightRequestDefaultsFetchRequested());
    yield put(push(UAVS_PATH));
  } catch (e) {
    yield put(uavsDeletionFailed(e.message));
  }
}

export function* uavsSaga() {
  yield takeEvery(FETCH_REQUESTED, fetchUavs);
  yield takeEvery(CREATION_REQUESTED, createUav);
  yield takeEvery(UPDATE_REQUESTED, updateUav);
  yield takeEvery(DELETION_REQUESTED, deleteUav);
}
