import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  AdvisoryTowerSchedule,
  AdvisoryTowerScheduleDay,
} from '@airshare/pilot-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

interface AdvisoryDetailTowerScheduleProps {
  readonly towerSchedule: AdvisoryTowerSchedule;
}

export default function AdvisoryDetailTowerSchedule({
  towerSchedule,
}: AdvisoryDetailTowerScheduleProps) {
  const getDaySchedule = (day: string) => {
    // @ts-ignore
    const daySchedule = towerSchedule[day as keyof AdvisoryTowerScheduleDay];
    const result = daySchedule.map(
      (advTowerScheduleDay: AdvisoryTowerScheduleDay) => {
        if (advTowerScheduleDay.start.length === 0) {
          return (
            <Typography variant="body2" key={advTowerScheduleDay.start}>
              Closed
            </Typography>
          );
        }
        return (
          <span key={advTowerScheduleDay.start}>
            <Typography variant="body2">
              {advTowerScheduleDay.start} - {advTowerScheduleDay.end}
            </Typography>
          </span>
        );
      }
    );
    return daySchedule.length === 1 ? (
      <span style={{ textAlign: 'center' }}>{result[0]}</span>
    ) : (
      <span style={{ textAlign: 'center' }}>{result}</span>
    );
  };

  const towerStatusEnabled =
    window.env.TOWER_WATCH_ENABLED?.toLowerCase() === 'true' &&
    towerSchedule.status &&
    Object.keys(towerSchedule.status).length;

  return (
    <>
      {towerSchedule && towerSchedule.allDay !== undefined ? (
        <div className="tower-schedule">
          <Typography className="tower-schedule-header" variant="subtitle1">
            Tower Schedule:
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginBottom: '1rem',
              marginTop: '0.5rem',
            }}
          >
            ATC tower watch hours are in NZT and are subject to change. It is
            your responsibility to confirm the hours.
          </Typography>
          {towerSchedule.allDay ? (
            <table className="tower-schedule-table">
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body2">
                      Monday - Sunday: 24hrs
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <TableContainer component={Paper}>
              <Table className="tower-schedule-table">
                <TableBody>
                  {Object.keys(towerSchedule)
                    .filter(
                      (day) =>
                        ['allDay', '_id', 'status', 'additionalInfo'].indexOf(
                          day
                        ) === -1
                    )
                    .map((day: string) => (
                      <TableRow key={day} style={{ verticalAlign: 'top' }}>
                        <TableCell scope="row">
                          <Typography variant="body2">
                            {day.charAt(0).toUpperCase() + day.slice(1)}:
                          </Typography>
                        </TableCell>
                        <TableCell>{getDaySchedule(day)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {towerStatusEnabled ? (
            <>
              <br />
              <Typography className="tower-schedule-header" variant="subtitle1">
                Current Tower Status:
              </Typography>

              <Typography
                className="tower-schedule-header"
                variant="body2"
                style={{
                  padding: '0.5rem 1rem',
                  background: towerSchedule.status.onWatch
                    ? 'rgb(56, 142, 60)'
                    : 'rgb(211, 47, 47)',
                  textAlign: 'center',
                  borderRadius: '5px',
                  textTransform: 'uppercase',
                  color: 'white',
                }}
              >
                {towerSchedule.status.onWatch ? 'ON-WATCH' : 'OFF-WATCH'}
              </Typography>
            </>
          ) : null}

          {towerSchedule.additionalInfo && (
            <div
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  marginBottom: '0.1rem',
                  fontWeight: 'bold',
                }}
              >
                Tower Schedule Notes:
              </Typography>
              <Typography variant="body2" style={{ lineHeight: '1.3rem' }}>
                {towerSchedule.additionalInfo}
              </Typography>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
