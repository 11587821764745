import React from 'react';
import { PilotOnlyStatus } from '@airshare/pilot-types';

import { ATC_AUTH_DEFAULT_NAME } from 'argus-api-shared/lib/constants';
import { StatusPill } from 'airshare-pilot-web-shared';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useAtcName } from './hooks/use-atc-name.hook';

const useAuthorizerStyles = makeStyles(() =>
  createStyles({
    authorizerItem: {
      display: 'inline-block',
      width: '68%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      verticalAlign: 'top',
    },
  })
);

interface Props {
  statuses: PilotOnlyStatus[];
}

export default function AuthoriserList({ statuses }: Props) {
  const classes = useAuthorizerStyles({});
  const atcName = useAtcName();

  return (
    <>
      {statuses?.map((status) => (
        <div
          key={`status-${status.authorizer.replace(' ', '-')}-${status.code}`}
        >
          <Typography
            className={classes.authorizerItem}
            data-testid="pilot-web:flight-briefing-panel:authoriser"
          >
            {status.authorizer === ATC_AUTH_DEFAULT_NAME
              ? atcName
              : status.authorizer}
          </Typography>
          <div
            style={{
              width: '30%',
              display: 'inline-block',
            }}
          >
            <StatusPill
              id={`status-${status.authorizer.replace(' ', '-')}-${
                status.code
              }`}
              status={status.code}
              testId="pilot-web:flight-briefing:authoriser-status"
            />
          </div>
        </div>
      ))}
    </>
  );
}
