import { createReducer } from 'airshare-web-utils/redux-helpers';

import {
  MapStylesActionType,
  MapStylesFetchSucceededAction,
  MapTypeId,
} from './actions';

interface styleDetails {
  mapId: string;
  mapTypeId: MapTypeId;
}
export interface MapOptionsState {
  standard: styleDetails;
  gray: styleDetails;
  dark: styleDetails;
  terrain: styleDetails;
  satellite: styleDetails;
  hybrid: styleDetails;
}

export const mapStylesReducer = createReducer({
  [MapStylesActionType.FETCH_SUCCEEDED]: (
    _: MapOptionsState,
    { payload }: MapStylesFetchSucceededAction
  ) => payload,
});
