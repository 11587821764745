import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import type { NotamsResponseWithLocation } from '@airshare/pilot-types';

import { NotamsActionType, NotamsFetchSucceededAction } from './actions';
import { NotamsFetchStatus } from './constants';

export interface NotamsState {
  data: NotamsResponseWithLocation[];
  fetchStatus: NotamsFetchStatus;
}

const INITIAL_STATUS_STATE = NotamsFetchStatus.IDLE;
const notamsFetchStatusReducer = createReducer(
  {
    [NotamsActionType.FETCH_REQUESTED]: () =>
      NotamsFetchStatus.NOTAMS_FETCH_IN_PROGRESS,
    [NotamsActionType.FETCH_SUCCEEDED]: () => NotamsFetchStatus.SUCCESS,
    [NotamsActionType.FETCH_FAILED]: () => NotamsFetchStatus.FAILED,
    [NotamsActionType.FETCH_STATUS_RESET]: () => INITIAL_STATUS_STATE,
  },
  INITIAL_STATUS_STATE
);

export const notamsDataReducer = createReducer({
  [NotamsActionType.FETCH_SUCCEEDED]: (
    _: NotamsState,
    { payload }: NotamsFetchSucceededAction
  ) => payload,
});

export const notamsReducer = combineReducers({
  data: notamsDataReducer,
  fetchStatus: notamsFetchStatusReducer,
});
