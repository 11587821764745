import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Feature, LineString, Polygon } from 'geojson';
import Draggable from 'react-draggable';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { type PaperProps } from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import { StyledFlightSegmentPropertiesJSONView } from '@airshare/external-api-types';

import {
  SegmentPlannerModalDetailRow,
  GridTextItem,
} from './segment-planner-modal-detail-row';
import {
  SegmentValidationResults,
  checkAllSegmentsAreValid,
  getLocalSegmentDetailItems,
  type LocalSegmentDetailItem,
} from './segment-helpers';
import './segment-planner-modal.scss';

export const SegmentPlannerModal = ({
  segmentFeatures,
  showSegmentModal,
  closeSegmentModal,
  timezone,
  waypoints,
  handleFormUpdated,
}: {
  segmentFeatures:
    | Feature<Polygon, StyledFlightSegmentPropertiesJSONView>[]
    | undefined;
  showSegmentModal: boolean;
  closeSegmentModal: (
    modifiedSegments: LocalSegmentDetailItem[] | undefined
  ) => void;
  timezone: string;
  waypoints: LineString | undefined;
  handleFormUpdated: (formIsUpdated: boolean) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isSegmented, setIsSegmented] = useState(false);
  const [segments, setSegments] = useState<LocalSegmentDetailItem[]>([]);
  const [segmentValidationResults, setSegmentValidationResults] =
    useState<SegmentValidationResults | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null);

  useEffect(() => {
    const hasSegments = segmentFeatures?.length > 1;

    if (hasSegments) {
      const initialSegments = getLocalSegmentDetailItems(
        segmentFeatures,
        waypoints
      );
      setSegments(initialSegments);
    } else {
      setSegments([]);
    }

    setIsSegmented(hasSegments);
  }, [segmentFeatures, waypoints]);

  useEffect(() => {
    setShowModal(isSegmented && showSegmentModal);
  }, [isSegmented, showSegmentModal]);

  useEffect(() => {
    checkValidationErrors(segments);
  }, [segments]);

  const checkValidationErrors = (
    allSegments: LocalSegmentDetailItem[]
  ): boolean => {
    const segmentsAreValidResponse = checkAllSegmentsAreValid(allSegments);

    setSegmentValidationResults(segmentsAreValidResponse);
    setErrorMessages(
      segmentsAreValidResponse.segments
        .map((x) =>
          x.messages?.length > 0 ? `[${x.id}] - ${x.messages.join(' ')}` : null
        )
        .filter((x) => x)
    );

    return segmentsAreValidResponse.anyValidationFailed;
  };

  const onAltitudeInputChange = (
    segmentId: string,
    isMaxAltitude: boolean,
    value: string
  ) => {
    setSegments((prevSegments) => {
      return prevSegments.map((segment) => {
        if (segment.id === segmentId) {
          const numericValue = parseInt(value, 10);
          return {
            ...segment,
            [isMaxAltitude ? 'maxAltitudeFeet' : 'minAltitudeFeet']:
              numericValue,
          };
        }
        return segment;
      });
    });
    handleFormUpdated(true);
  };

  const onTimeInputChange = (
    segmentId: string,
    isStartTime: boolean,
    value: string
  ) => {
    setSegments((prevSegments) => {
      return prevSegments.map((segment) => {
        if (segment.id === segmentId) {
          const inputTime = DateTime.fromFormat(value, 'HH:mm');
          const origDateTime = isStartTime
            ? segment.startDateTime
            : segment.endDateTime;
          const newDateTime = DateTime.fromISO(origDateTime)
            .setZone(timezone)
            .set({ hour: inputTime.hour, minute: inputTime.minute })
            .setZone('UTC')
            .toISO();
          return {
            ...segment,
            [isStartTime ? 'startDateTime' : 'endDateTime']: newDateTime,
          };
        }
        return segment;
      });
    });
    handleFormUpdated(true);
  };

  const handleCancel = () => {
    const resetSegments = getLocalSegmentDetailItems(
      segmentFeatures,
      waypoints
    );
    setSegments(resetSegments);

    handleFormUpdated(false);
    setSegmentValidationResults(null);
    setErrorMessages(null);
    closeSegmentModal(undefined);
  };
  const handleSave = () => {
    setErrorMessages(null);

    const anyValidationFailed = checkValidationErrors(segments);

    if (anyValidationFailed) {
      return;
    }

    setSegmentValidationResults(null);
    setErrorMessages(null);
    closeSegmentModal(segments);
  };
  const handleClose = () => {
    closeSegmentModal(undefined);
  };

  return showModal ? (
    <Dialog
      open={showModal}
      className="segment-planner-modal"
      data-testid="pilot-web:segment-planner-modal:dialog"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      maxWidth="md"
    >
      <DialogTitle
        style={{ cursor: 'move' }}
        data-testid="pilot-web:segment-planner-modal:dialog-title"
        id="segment-planner-draggable-title"
        className="segment-planner-modal-title"
        disableTypography={true}
      >
        <Typography variant="h6" component="h1">
          Edit Segment Details
        </Typography>
      </DialogTitle>
      <DialogContent className="segment-planner-modal-content">
        <div className="segment-planner-modal-header-grid">
          <GridTextItem text="Segment ID" isHeader={true} />
          <GridTextItem text="Distance (m)" isHeader={true} />
          <GridTextItem text="Start" isHeader={true} />
          <GridTextItem text="End" isHeader={true} />
          <GridTextItem text="Lower Altitude (ft AGL)" isHeader={true} />
          <GridTextItem text="Upper Altitude (ft AGL)" isHeader={true} />
        </div>

        <div className="segment-planner-modal-details-grid">
          {segments.map((segment) => {
            return (
              <SegmentPlannerModalDetailRow
                key={segment?.id}
                segment={segment}
                segmentValidationResults={
                  segmentValidationResults?.segments?.find(
                    (x) => x.id === segment.id
                  ) || null
                }
                onTimeInputChange={onTimeInputChange}
                onAltitudeInputChange={onAltitudeInputChange}
                timezone={timezone}
              />
            );
          })}
        </div>
      </DialogContent>
      <DialogActions className="segment-planner-modal-dialog-actions">
        <div className="segment-planner-error-messages-wrapper">
          {errorMessages?.map((errorMessage, ix) => (
            <Typography
              key={ix}
              variant="subtitle1"
              color="error"
              className="segment-planner-error-message"
            >
              {errorMessage}
            </Typography>
          ))}
        </div>
        <ButtonBase
          onClick={handleCancel}
          data-testid="pilot-web:segment-planner-modal:dialog-cancel-button"
          className="cancel-button"
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          color="primary"
          onClick={() => handleSave()}
          data-testid="pilot-web:segment-planner-modal:dialog-save-button"
          className="submit-button"
        >
          Save
        </ButtonBase>
      </DialogActions>
    </Dialog>
  ) : null;
};

function PaperComponent(props: Readonly<PaperProps>) {
  return (
    <Draggable
      handle="#segment-planner-draggable-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default SegmentPlannerModal;
