import { useSelector } from 'react-redux';
import type { GetUavDefaultsResponseBody } from '@airshare/pilot-types';

import { getUavDefaults } from './selectors';

export function useUavDefaults() {
  const uavDefaults: GetUavDefaultsResponseBody = useSelector(getUavDefaults);

  return uavDefaults;
}
