import { call, put, takeEvery } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import { FlightRequestDefaultsResponseBodyV2 } from '@airshare/pilot-types';

import { pilotAPI } from '../../pilot-api-client/api';
import {
  FETCH_REQUESTED,
  flightRequestDefaultsFetchSucceeded,
  flightRequestDefaultsFetchFailed,
} from './actions';

export type State = FlightRequestDefaultsResponseBodyV2;

function* fetchFlightRequestDefaults() {
  try {
    const { data } = yield call(pilotAPI.get, 'v2/flight-request/defaults');

    // ! FIXME: Exponent are returning duplicate UAVs in the set so we uniqBy id here:
    yield put(
      flightRequestDefaultsFetchSucceeded({
        ...data,
        uavs: uniqBy(data.uavs, ({ id }) => id),
      })
    );
  } catch (e) {
    yield put(flightRequestDefaultsFetchFailed(e.message));
  }
}

export function* flightRequestDefaultsSaga() {
  yield call(fetchFlightRequestDefaults);

  yield takeEvery(FETCH_REQUESTED, fetchFlightRequestDefaults);
}
