import { useDispatch, useSelector } from 'react-redux';

import {
  getActivationStatus,
  getResendActivationEmailStatus,
} from '~/state/session/selectors';
import {
  ActivationStatus,
  ResendActivationEmailStatus,
} from '~/state/session/constants';
import {
  attemptActivation,
  setActivationStatus,
  setResendActivationStatus,
  attemptResendActivationEmail,
} from '~/state/session/action-creators';

export function useActivation() {
  const dispatch = useDispatch();

  const activationStatus = useSelector(getActivationStatus);

  function dispatchAttemptActivation(payload: string) {
    return dispatch(attemptActivation(payload));
  }

  function dispatchResetActivationStatus() {
    return dispatch(setActivationStatus(ActivationStatus.IDLE));
  }

  return [
    activationStatus,
    dispatchAttemptActivation,
    dispatchResetActivationStatus,
  ];
}

export function useResendActivationEmail(email: string) {
  const dispatch = useDispatch();

  const resendActivationEmailStatus = useSelector(
    getResendActivationEmailStatus
  );

  const resendActivationEmail = () =>
    dispatch(attemptResendActivationEmail({ email }));

  const resetResendActionEmailStatus = () =>
    dispatch(setResendActivationStatus(ResendActivationEmailStatus.IDLE));

  return [
    resendActivationEmailStatus,
    resendActivationEmail,
    resetResendActionEmailStatus,
  ];
}
