import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapBounds } from 'argus-common/interfaces';
import { notamsFetchRequested } from './actions';
import { getNotam, getNotams, getNotamsFetchStatus } from './selectors';

export function useFetchNotams(): (payload: MapBounds) => void {
  const dispatch = useDispatch();

  return (payload) => dispatch(notamsFetchRequested(payload));
}

export function useNotams(mapBounds: MapBounds) {
  const fetchNotams = useFetchNotams();

  const fetchNotamsCallback = useCallback(
    (mapbounds: MapBounds) => {
      fetchNotams(mapbounds);
    },
    [fetchNotams]
  );

  useEffect(() => {
    if (mapBounds) {
      fetchNotamsCallback(mapBounds);
    }
  }, [mapBounds, fetchNotamsCallback]);

  const notams = useSelector(getNotams);

  return notams;
}

export function useNotam(id: string) {
  const notam = useSelector(getNotam(id));

  return notam;
}

export function useNotamsFetchStatus() {
  const notamsFetchStatus = useSelector(getNotamsFetchStatus);

  return notamsFetchStatus;
}
