import { call, put, takeEvery } from 'redux-saga/effects';
import LogRocket from 'logrocket';
import { ProfileUser } from '@airshare/pilot-types';

import { pilotAPI } from '../../pilot-api-client/api';
import {
  profileFetchRequested,
  profileFetchFailed,
  profileFetchSucceeded,
  profileUpdateSucceeded,
  profileUpdateFailed,
} from './action-creators';
import { FETCH_REQUESTED, UPDATE_REQUESTED } from './actions';
import { ProfileUpdateStatus } from './enum';
import type { FormState } from 'airshare-web-utils/redux-forms/reducer';
import type { UpdateProfileFormState } from './update-profile-form/reducers';

export interface ProfileState {
  user: ProfileUser;
  updateStatus: ProfileUpdateStatus;
  updateProfileForm: FormState<UpdateProfileFormState>;
}

function* fetchProfile() {
  try {
    const { data } = yield call(pilotAPI.get, '/user');

    LogRocket.identify(data.email, data);
    yield put(profileFetchSucceeded(data));
  } catch (e) {
    yield put(profileFetchFailed(e.message));
  }
}

function* updateProfile({ payload: { profile } }: { payload: any }) {
  try {
    yield call(pilotAPI.put, '/user', profile);

    yield put(profileUpdateSucceeded());
  } catch (e) {
    yield put(profileUpdateFailed());
  }
}

export function* profileSaga() {
  yield takeEvery(FETCH_REQUESTED, fetchProfile);
  yield takeEvery(UPDATE_REQUESTED as any, updateProfile);

  yield put(profileFetchRequested());
}
