import React from 'react';

import { GoogleApiLoader, useMobileDetection } from 'airshare-pilot-web-shared';

import MapScreen from './map-screen';

import './map-screen.scss';

export default function MapLoading() {
  const isMobile = useMobileDetection();
  const loadingObject = <div className="loading"></div>;

  return (
    !isMobile && (
      <GoogleApiLoader loading={loadingObject}>
        <MapScreen />
      </GoogleApiLoader>
    )
  );
}
