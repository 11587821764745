import { Feature } from '@turf/helpers';
import { createAction } from 'airshare-web-utils/redux-helpers';
import { MapBounds } from 'argus-common/interfaces';

export enum NotamsActionType {
  FETCH_REQUESTED = 'notams/FETCH_REQUESTED',
  FETCH_SUCCEEDED = 'notams/FETCH_SUCCEEDED',
  FETCH_FAILED = 'notams/FETCH_FAILED',
  FETCH_STATUS_RESET = 'notams/FETCH_STATUS_RESET',
}

export interface NotamsFetchRequestedAction {
  type: NotamsActionType.FETCH_REQUESTED;
  payload: MapBounds;
}

export interface NotamsFetchSucceededAction {
  type: NotamsActionType.FETCH_SUCCEEDED;
  payload: Feature[];
}

export interface NotamsFetchFailedAction {
  type: NotamsActionType.FETCH_FAILED;
  payload: string;
}

export interface NotamsFetchStatusResetAction {
  type: NotamsActionType.FETCH_STATUS_RESET;
}

export const notamsFetchRequested: (
  notamsParams: MapBounds
) => NotamsFetchRequestedAction = createAction(
  NotamsActionType.FETCH_REQUESTED
);
export const notamsFetchSucceeded: (
  notams: Feature[]
) => NotamsFetchSucceededAction = createAction(
  NotamsActionType.FETCH_SUCCEEDED
);
export const notamsFetchFailed: (message: string) => NotamsFetchFailedAction =
  createAction(NotamsActionType.FETCH_FAILED);
export const notamsFetchStatusReset: () => NotamsFetchStatusResetAction =
  createAction(NotamsActionType.FETCH_STATUS_RESET);
