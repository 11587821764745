import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import {
  FETCH_SUCCEEDED,
  FETCH_REQUESTED,
  FETCH_FAILED,
  STATE_RESET,
  FETCH_STATUS_RESET,
  CREATION_FAILED,
  CREATION_REQUESTED,
  CREATION_SUCCEEDED,
  CREATION_STATUS_RESET,
  DELETION_REQUESTED,
  DELETION_SUCCEEDED,
  DELETION_FAILED,
  DELETION_STATUS_RESET,
  UPDATE_FAILED,
  UPDATE_REQUESTED,
  UPDATE_STATUS_RESET,
  UPDATE_SUCCEEDED,
} from './actions';
import { addUavFormReducer } from './add-uav-form/reducers';
import {
  UavsCreationStatus,
  UavsUpdateStatus,
  UavsFetchStatus,
  UavsDeletionStatus,
} from './constants';

export const REDUCER_KEY = 'uavs';

const INITIAL_PAGES_STATE = [];
const uavsPagesReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (state, { payload }) => {
      if (payload?.pageIndex || payload.pageIndex === 0) {
        const pages = [...state];
        pages[payload.pageIndex] = payload.data;
        return pages;
      }
      return [...state, payload.data];
    },
    [STATE_RESET]: () => INITIAL_PAGES_STATE,
  },
  INITIAL_PAGES_STATE
);

const INITIAL_META_STATE = null;
const uavsMetaReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (_, { payload }) => {
      // eslint-disable-next-line no-unused-vars
      const { _data, ...meta } = payload;

      return meta;
    },
    [STATE_RESET]: () => INITIAL_META_STATE,
  },
  INITIAL_META_STATE
);

const DEFAULT_CREATION_STATUS_STATE = UavsCreationStatus.IDLE;
export const uavsCreationStatusReducer = createReducer(
  {
    [CREATION_REQUESTED]: () => UavsCreationStatus.UAV_CREATION_IN_PROGRESS,
    [CREATION_SUCCEEDED]: () => UavsCreationStatus.SUCCESS,
    [CREATION_FAILED]: () => UavsCreationStatus.FAILED,
    [CREATION_STATUS_RESET]: () => DEFAULT_CREATION_STATUS_STATE,
  },
  DEFAULT_CREATION_STATUS_STATE
);

const DEFAULT_UPDATE_STATUS_STATE = UavsUpdateStatus.IDLE;
export const uavsUpdateStatusReducer = createReducer(
  {
    [UPDATE_REQUESTED]: () => UavsUpdateStatus.UAV_UPDATE_IN_PROGRESS,
    [UPDATE_SUCCEEDED]: () => UavsUpdateStatus.SUCCESS,
    [UPDATE_FAILED]: () => UavsUpdateStatus.FAILED,
    [UPDATE_STATUS_RESET]: () => DEFAULT_CREATION_STATUS_STATE,
  },
  DEFAULT_UPDATE_STATUS_STATE
);

const DEFAULT_DELETION_STATUS_STATE = UavsDeletionStatus.IDLE;
export const uavsDeletionStatusReducer = createReducer(
  {
    [DELETION_REQUESTED]: () => UavsDeletionStatus.UAV_DELETION_IN_PROGRESS,
    [DELETION_SUCCEEDED]: () => UavsDeletionStatus.SUCCESS,
    [DELETION_FAILED]: () => UavsDeletionStatus.FAILED,
    [DELETION_STATUS_RESET]: () => DEFAULT_DELETION_STATUS_STATE,
  },
  DEFAULT_DELETION_STATUS_STATE
);

const DEFAULT_FETCH_STATUS_STATE = UavsFetchStatus.IDLE;
export const uavsFetchStatusReducer = createReducer(
  {
    [FETCH_REQUESTED]: () => UavsFetchStatus.UAV_FETCH_IN_PROGRESS,
    [FETCH_SUCCEEDED]: () => UavsFetchStatus.SUCCESS,
    [FETCH_FAILED]: () => UavsFetchStatus.FAILED,
    [FETCH_STATUS_RESET]: () => DEFAULT_FETCH_STATUS_STATE,
  },
  DEFAULT_FETCH_STATUS_STATE
);

export const uavsReducer = combineReducers({
  pages: uavsPagesReducer,
  meta: uavsMetaReducer,
  creationStatus: uavsCreationStatusReducer,
  deletionStatus: uavsDeletionStatusReducer,
  updateStatus: uavsUpdateStatusReducer,
  fetchStatus: uavsFetchStatusReducer,
  ...addUavFormReducer,
});
