import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import ButtonBase from '@material-ui/core/ButtonBase';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ReadOnlyField } from '../../../../components/shared/form-inputs/read-only-field';
import {
  useProfile,
  useIsCommercialOperator,
  useFetchProfile,
} from '../../../../state/profile/hooks';
import { PROFILE_UPDATE_PATH } from '../../../../routes';

import './profile.scss';

function ProfileComponent({ history }: Readonly<RouteComponentProps>) {
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';
  const isCommercialOperator = useIsCommercialOperator();
  const profile = useProfile();
  const fetchProfile = useFetchProfile();
  const allowPilotsShareContactInfo =
    profile?.organisations?.every(
      (o) => o.allowPilotsShareContactInfo === true
    ) ?? true;
  const shareContactInfo =
    profile?.pilot?.result?.shareContactInfo && allowPilotsShareContactInfo;

  const showShareContactInfo =
    window.env.CONFLICTING_FLIGHTS_ENABLED?.toLocaleLowerCase() === 'true';

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-panel" data-testid="pilot-web:profile-panel">
      <Typography
        className="heading"
        variant="h6"
        component="h1"
        align="center"
        data-testid="pilot-web:profile-panel-heading"
      >
        Profile
      </Typography>

      {profile?.pilot?.result && (
        <>
          <div
            className="form"
            data-testid="pilot-web:profile-panel:profile-form"
          >
            <ReadOnlyField
              className="full-name"
              label="Name"
              value={profile.fullName || '-'}
              testId="pilot-web:profile-panel:user-full-name"
            />
            <ReadOnlyField
              className="email"
              label="Email"
              value={profile.email || '-'}
              testId="pilot-web:profile-panel:user-email"
            />
            <ReadOnlyField
              className="phone"
              label="Phone Number"
              value={profile.mobileNo || '-'}
              testId="pilot-web:profile-panel:user-phone"
            />
            {isEuroRegulation && profile?.registrationNo && (
              <ReadOnlyField
                className="registration-number"
                label="Registration Number"
                value={profile.registrationNo ? profile.registrationNo : '-'}
              />
            )}
            {isCommercialOperator && (
              <ReadOnlyField
                className="company"
                label="Company"
                value={profile.businessName || '-'}
                testId="pilot-web:profile-panel:user-company"
              />
            )}
            {profile?.certificationNumber && (
              <ReadOnlyField
                className="cert"
                label={`${isEuroRegulation ? 'Operator' : '102'} Certificate`}
                value={
                  profile.certificationNumber
                    ? profile.certificationNumber
                    : '-'
                }
                testId="pilot-web:profile-panel:user-102-certification"
              />
            )}
            <ReadOnlyField
              className="dob"
              label="Date of Birth"
              value={
                profile.dateOfBirth
                  ? DateTime.fromISO(profile.dateOfBirth).toFormat('dd/LL/yyyy')
                  : '-'
              }
              testId="pilot-web:profile-panel:user-dob"
            />
            <ReadOnlyField
              className="notify-conflicts"
              label="Notify Flight Conflicts"
              value={profile.pilot?.result?.notifyConflicts ? 'Yes' : 'No'}
              testId="pilot-web:profile-panel:user-notify-no-conflicts"
            />
            {showShareContactInfo && (
              <ReadOnlyField
                className="notify-conflicts"
                label="Share Contact Info"
                value={shareContactInfo ? 'Yes' : 'No'}
                testId="pilot-web:profile-panel:user-share-contact-info"
              />
            )}
          </div>
          {(!profile?.certificationNumber ||
            profile?.certificationType === 'Part 101') && (
            <div className="form" style={{ marginTop: '0' }}>
              <Typography style={{ gridArea: 'full-name' }} variant="body2">
                To update your account to a Commercial account or to add your{' '}
                {isEuroRegulation ? 'UAS Operation' : 'Part 102'} certificate,
                please contact the AirShare team at{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="mailto:info@airshare.co.nz?body=Hello support team, %0D%0A%0D%0ACould you please update my account to commercial part 102 using below information. %0D%0A%0D%0ACertificate number: {YOUR CERTIFICATE_NUMBER}%0D%0AEmail: {YOUR EMAIL}. %0D%0A%0D%0A Thanks."
                >
                  info@airshare.co.nz
                </a>
              </Typography>
            </div>
          )}
          <div className="update-profile-button-container">
            <ButtonBase
              data-testid="pilot-web:profile-panel:update-button"
              className="update-profile-button button primary"
              type="button"
              onClick={() => history.push(PROFILE_UPDATE_PATH)}
            >
              Update
            </ButtonBase>
          </div>
        </>
      )}
    </div>
  );
}

export const Profile = withRouter(ProfileComponent);
