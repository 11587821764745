/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  LoadingButton,
  COMPLETION_DELAY,
  useTimeoutEffect,
} from 'airshare-pilot-web-shared';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { useFetchFlightRequestDefaults } from '../../../../state/flight-request-defaults/hooks';
import { useUavs, useUavsPageByIndex } from '../../../../state/uavs/hooks';
import {
  useOrgPilots,
  useOrgPilot,
  useDeleteOrgPilot,
  useOrgPilotsCreationStatus,
  useOrgPilotsDeletionStatus,
  useResetOrgPilotsDeletionStatus,
  useResetOrgPilotsCreationStatus,
  useFetchOrgPilots,
} from '../../../../state/org-pilots/hooks';
import {
  OrgPilotsDeletionStatus,
  OrgPilotsCreationStatus,
  OrgPilotsUpdateStatus,
} from '../../../../state/org-pilots/constants';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import {
  ORG_PILOTS_PATH,
  getOrgPilotDetailEditPath,
  getOrgPilotDetailPath,
} from '../../../../routes';

import {
  useFormState,
  useInputHandlerProps,
  useIsErrored,
  useFieldError,
  useSetFieldValue,
  useResetErrors,
  useClearForm,
  useHandleSubmit,
} from '../../../../state/org-pilots/org-pilots-form/hooks';

import LoadingFormWrapper from './loading-form-wrapper';
import { updateOrgPilot } from '../../../../pilot-api-client';

import './org-pilot-detail.scss';
import PhoneNumberInput from 'material-ui-phone-number';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MobileRegex } from '@airshare/pilot-types';

enum FormMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

function getFormModeFromRouterMatch(match: RouteComponentProps['match']) {
  switch (match.path) {
    case '/org-pilots/add':
      return FormMode.ADD;

    case '/org-pilots/:id':
      return FormMode.VIEW;

    case '/org-pilots/edit/:id':
      return FormMode.EDIT;

    default:
      return FormMode.VIEW;
  }
}

export function OrgPilotDetailComponent({
  match,
}: RouteComponentProps<{ id: string }>) {
  const formMode = getFormModeFromRouterMatch(match);
  const navigateTo = useNavigateTo();

  useOrgPilots();
  const orgPilot = useOrgPilot(match.params.id);

  useUavs(0);
  const uavs = useUavsPageByIndex(0);

  const [isRequestingDelete, updateIsRequestingDelete] = useState(false);

  const deleteOrgPilot = useDeleteOrgPilot();
  const fetchOrgPilots = useFetchOrgPilots();

  const form = useFormState();
  const isErrored = useIsErrored();
  const handlerProps = useInputHandlerProps();
  const fieldError = useFieldError();
  // @ts-ignore
  const setFieldValue = useSetFieldValue(form);
  const resetErrors = useResetErrors();
  const clearForm = useClearForm();
  const [apiErrors, setApiErrors] = useState('');

  // TODO: Fix button transition position bug
  const deleteButtonTransitions = useTransition(!isRequestingDelete, null, {
    from: { bottom: -200 },
    enter: { bottom: 34 },
    leave: { bottom: -200 },
  });

  const areYouSureButtonTransitions = useTransition(isRequestingDelete, null, {
    from: { bottom: -200 },
    enter: { bottom: 34 },
    leave: { bottom: -200 },
  });

  const cancelButtonTransitions = useTransition(isRequestingDelete, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  function initFormFields() {
    const formFields = ['name', 'email', 'mobileNo', 'defaultUav'];

    formFields.forEach((field) => {
      setFieldValue(field, orgPilot[field]);
    });
  }

  useEffect(() => {
    if (orgPilot) {
      initFormFields();
    }
  }, [orgPilot, match]);

  useEffect(
    () => () => {
      clearForm();
      resetErrors();
    },
    []
  );

  function handleRequestDeletePilot() {
    updateIsRequestingDelete(true);
  }

  function handleCancelDeletePilot() {
    updateIsRequestingDelete(false);
  }

  function handleEnterEditMode() {
    if (orgPilot) {
      navigateTo(getOrgPilotDetailEditPath(orgPilot.id));
    }
  }

  const handleRequestUpdateOrgPilot = useHandleSubmit(async () => {
    try {
      await updateOrgPilot(orgPilot.id, form);
      setApiErrors('Pilot updated successfully');
      setOrgPilotsUpdateStatus(OrgPilotsUpdateStatus.SUCCESS);
    } catch (err) {
      setApiErrors(err?.response?.data?.message);
      setOrgPilotsUpdateStatus(OrgPilotsUpdateStatus.FAILED);
    }
  });

  const handleMobileNumberChange = (value: string) => {
    try {
      if (!MobileRegex.test(value)) {
        setFieldValue('mobileNo', value);
      } else {
        const formattedNumber = parsePhoneNumber(value);
        const validNumber = `+${formattedNumber.countryCallingCode} ${formattedNumber.nationalNumber}`;
        setFieldValue('mobileNo', validNumber);
      }
    } catch (error) {
      setFieldValue('mobileNo', '+');
      console.error('Invalid mobile number');
    }
  };

  const orgPilotsCreationStatus = useOrgPilotsCreationStatus();
  const resetOrgPilotsCreationStatus = useResetOrgPilotsCreationStatus();
  const [orgPilotsUpdateStatus, setOrgPilotsUpdateStatus] = useState<string>(
    OrgPilotsCreationStatus.IDLE
  );
  const orgPilotsDeletionStatus = useOrgPilotsDeletionStatus();
  const resetOrgPilotsDeletionStatus = useResetOrgPilotsDeletionStatus();
  const fetchFlightRequestDefaults = useFetchFlightRequestDefaults();

  useTimeoutEffect(
    () => {
      fetchOrgPilots();
      navigateTo(ORG_PILOTS_PATH);
      resetOrgPilotsCreationStatus();
      fetchFlightRequestDefaults();
    },
    COMPLETION_DELAY,
    orgPilotsCreationStatus === OrgPilotsCreationStatus.SUCCESS
  );

  useTimeoutEffect(
    () => {
      fetchOrgPilots();
      navigateTo(getOrgPilotDetailPath(orgPilot.id));
      setOrgPilotsUpdateStatus(OrgPilotsUpdateStatus.IDLE);
      fetchFlightRequestDefaults();
    },
    COMPLETION_DELAY,
    orgPilotsUpdateStatus === OrgPilotsUpdateStatus.SUCCESS
  );

  useTimeoutEffect(
    () => {
      fetchOrgPilots();
      navigateTo(ORG_PILOTS_PATH);
      resetOrgPilotsDeletionStatus();
      fetchFlightRequestDefaults();
    },
    COMPLETION_DELAY,
    orgPilotsDeletionStatus === OrgPilotsDeletionStatus.SUCCESS
  );

  const isUpdating =
    orgPilotsUpdateStatus ===
    OrgPilotsUpdateStatus.ORG_PILOTS_UPDATE_IN_PROGRESS;
  const isDeleting =
    orgPilotsDeletionStatus ===
    OrgPilotsDeletionStatus.ORG_PILOTS_DELETION_IN_PROGRESS;
  const isLoading =
    (formMode === FormMode.VIEW || formMode === FormMode.EDIT) && !orgPilot;

  const isReadOnly = formMode === FormMode.VIEW;

  return (
    <LoadingFormWrapper isLoading={isLoading} isReadOnly={isReadOnly}>
      <form
        className="org-pilots-form"
        data-testid="pilot-web:org-pilot-detail:form"
      >
        {formMode !== FormMode.ADD && (
          <FormControl className="form-control" error={isErrored('name')}>
            <InputLabel className="input-label" htmlFor="name">
              Name
            </InputLabel>
            <Input
              readOnly={isReadOnly}
              fullWidth
              name="name"
              {...handlerProps}
              value={form.name}
              inputProps={{
                'data-testid': 'pilot-web:org-pilot-form:name-input',
              }}
            />
            <FormHelperText className="input-hint">
              {fieldError('name')}
            </FormHelperText>
          </FormControl>
        )}

        <FormControl className="form-control" error={isErrored('email')}>
          <InputLabel className="input-label" htmlFor="email">
            Email
          </InputLabel>
          <Input
            readOnly={isReadOnly}
            fullWidth
            name="email"
            placeholder="Add an existing Airshare user"
            {...handlerProps}
            value={form.email}
            inputProps={{
              'data-testid': 'pilot-web:org-pilot-form:email-input',
            }}
          />
          <FormHelperText className="input-hint">
            {fieldError('email')}
          </FormHelperText>
        </FormControl>

        {formMode !== FormMode.ADD && (
          <FormControl className="form-control" error={isErrored('mobileNo')}>
            {isReadOnly ? (
              <>
                <InputLabel className="input-label" htmlFor="mobileNo">
                  Mobile Number
                </InputLabel>
                <Input
                  readOnly={isReadOnly}
                  fullWidth
                  name="mobileNo"
                  {...handlerProps}
                  value={form.mobileNo}
                  inputProps={{
                    'data-testid': 'pilot-web:org-pilot-form:mobile-no-input',
                  }}
                />
              </>
            ) : (
              <PhoneNumberInput
                className="phone-input"
                label="Phone Number"
                name="mobileNo"
                defaultCountry="nz"
                disableAreaCodes={true}
                {...handlerProps}
                onChange={(value: string) => {
                  handleMobileNumberChange(value);
                }}
                value={form.mobileNo}
                inputProps={{
                  'data-testid': 'pilot-web:org-pilot-form:mobile-no-input',
                }}
              />
            )}
            <FormHelperText className="input-hint">
              {fieldError('mobileNo')}
            </FormHelperText>
          </FormControl>
        )}

        {formMode !== FormMode.ADD && (
          <FormControl className="form-control" error={isErrored('defaultUav')}>
            <InputLabel className="input-label" htmlFor="defaultUav">
              Default UAV
            </InputLabel>
            <Select
              data-testid="pilot-web:org-pilot-form:default-org-pilots-select"
              readOnly={isReadOnly}
              type="text"
              placeholder="Select a default UAV"
              name="defaultUav"
              inputProps={{
                name: 'defaultUav',
                className: 'flight-purpose',
              }}
              value={form.defaultUav || ''}
              {...handlerProps}
              onChange={(_, component: any) => {
                setFieldValue(
                  'defaultUav',
                  component?.props?.value !== 'None'
                    ? component.props.value
                    : null
                );
              }}
            >
              <MenuItem key="none" value="None">
                None
              </MenuItem>
              {uavs?.map(({ name, id }: { name: string; id: string }) => (
                <MenuItem key={name} value={id} data-label={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className="input-hint">
              {fieldError('defaultUav')}
            </FormHelperText>
          </FormControl>
        )}

        <FormHelperText className="validation-errors" error>
          {apiErrors}
        </FormHelperText>

        {formMode === FormMode.EDIT && (
          <LoadingButton
            data-testid="pilot-web:org-pilots-form:save-update-button"
            isLoading={isUpdating}
            className="form-control submit-button"
            type="button"
            onClick={handleRequestUpdateOrgPilot}
          >
            Save
          </LoadingButton>
        )}

        {formMode === FormMode.VIEW &&
          deleteButtonTransitions.map(
            (deleteButtonTransitionProps) =>
              deleteButtonTransitionProps.item && (
                <animated.div
                  className="delete-org-pilots-button-animation-container"
                  key={deleteButtonTransitionProps.key}
                  style={deleteButtonTransitionProps.props}
                >
                  <ButtonBase
                    data-testid="pilot-web:org-pilot-detail:edit-button"
                    className="update-org-pilots-button button primary"
                    type="button"
                    onClick={handleEnterEditMode}
                  >
                    Edit
                  </ButtonBase>
                </animated.div>
              )
          )}

        {formMode === FormMode.VIEW &&
          deleteButtonTransitions.map(
            (deleteButtonTransitionProps) =>
              deleteButtonTransitionProps.item && (
                <animated.div
                  className="delete-org-pilots-button-animation-container"
                  key={deleteButtonTransitionProps.key}
                  style={deleteButtonTransitionProps.props}
                >
                  <ButtonBase
                    data-testid="pilot-web:org-pilot-detail:delete-button"
                    className="delete-org-pilots-button button secondary"
                    type="button"
                    onClick={handleRequestDeletePilot}
                  >
                    Delete
                  </ButtonBase>
                </animated.div>
              )
          )}

        {formMode === FormMode.VIEW &&
          areYouSureButtonTransitions.map(
            (areYouSureButtonTransitionProps) =>
              areYouSureButtonTransitionProps.item && (
                <animated.div
                  className="are-you-sure-button-animation-container"
                  key={areYouSureButtonTransitionProps.key}
                  style={areYouSureButtonTransitionProps.props}
                >
                  <LoadingButton
                    data-testid="pilot-web:org-pilot-detail:confirm-button"
                    isLoading={isDeleting}
                    className="form-control submit-button"
                    type="button"
                    onClick={() => deleteOrgPilot({ id: orgPilot.id })}
                  >
                    Are you sure?
                  </LoadingButton>
                </animated.div>
              )
          )}

        {isReadOnly &&
          cancelButtonTransitions.map(
            (cancelButtonTransitionProps) =>
              cancelButtonTransitionProps.item &&
              isRequestingDelete && (
                <animated.button
                  key={cancelButtonTransitionProps.key}
                  style={cancelButtonTransitionProps.props}
                  className="cancel-delete-button"
                  type="button"
                  onClick={handleCancelDeletePilot}
                >
                  cancel
                </animated.button>
              )
          )}
      </form>
    </LoadingFormWrapper>
  );
}

export const OrgPilotDetail = withRouter(OrgPilotDetailComponent);
