import React, { Fragment } from 'react';
import { Collapse, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';

import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { feetToMeters } from 'airshare-shared-utils/measurements';
import { CertificationType } from '@airshare/external-api-types';

import { mapToEuroRegOperationType } from 'argus-common/european-regulations.lib';

export const FlightSummary = ({
  showFlightSummary = true,
  flight,
}: {
  showFlightSummary?: boolean;
  flight?: ExtendedFlightResponseBodyV2;
}) => {
  const {
    additionalInfo,
    endDateTime,
    startDateTime,
    timezone,
    maxAltitudeFeet,
    uav,
    isShielded,
    deviationThresholds,
  } = flight?.properties ?? {};

  const endDT = DateTime.fromISO(endDateTime).setZone(timezone);
  const startDT = DateTime.fromISO(startDateTime).setZone(timezone);
  const duration = Math.ceil(endDT.diff(startDT, 'minutes').minutes);
  const formattedStartTime = startDT.toFormat('HH:mm ZZZZ');
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';

  return (
    <Collapse in={showFlightSummary}>
      <div className="row">
        <Typography className="cell">Start Time</Typography>
        <Typography
          className="cell"
          data-testid="pilot-web:flight-briefing-panel:flight-summary-section:start-time"
        >
          {formattedStartTime}
        </Typography>
      </div>
      <div className="row">
        <Typography className="cell">Duration</Typography>
        <Typography
          className="cell"
          data-testid="pilot-web:flight-briefing-panel:flight-summary-section:duration"
        >
          {duration} minutes
        </Typography>
      </div>
      <div className="row">
        <Typography className="cell">Altitude (AGL)</Typography>
        <Typography
          className="cell"
          data-testid="pilot-web:flight-briefing-panel:flight-summary-section:altitude"
        >
          {feetToMeters(maxAltitudeFeet)}m | {maxAltitudeFeet}ft.
        </Typography>
      </div>
      <div className="row">
        <Typography className="cell">Shielded Operation</Typography>{' '}
        <Typography
          className="cell"
          data-testid="pilot-web:flight-briefing-panel:flight-summary-section:shielded-operation"
        >
          {isShielded ? 'Yes' : 'No'}
        </Typography>
      </div>
      {uav?.weight && (
        <div className="row">
          <Typography className="cell">Max Take-off Weight</Typography>{' '}
          <Typography
            className="cell"
            data-testid="pilot-web:flight-briefing-panel:flight-summary-section:max-weight"
          >
            {uav?.weight} kg
          </Typography>
        </div>
      )}
      {isEuroRegulation && additionalInfo?.uasOperationCategory && (
        <div className="row">
          <>
            <Typography className="cell">UAS Operation Category</Typography>{' '}
            <Typography
              className="cell"
              data-testid="pilot-web:flight-briefing-panel:flight-summary-section:part-102"
            >
              {additionalInfo.uasOperationCategory}
            </Typography>
          </>
        </div>
      )}

      <div className="row">
        {isEuroRegulation ? (
          <>
            <Typography className="cell">LOS/BVLOS</Typography>{' '}
            <Typography
              className="cell"
              data-testid="pilot-web:flight-briefing-panel:flight-summary-section:part-102"
            >
              {mapToEuroRegOperationType(additionalInfo?.operatingRule)}
            </Typography>
          </>
        ) : (
          <>
            <Typography className="cell">Part 102</Typography>{' '}
            <Typography
              className="cell"
              data-testid="pilot-web:flight-briefing-panel:flight-summary-section:part-102"
            >
              {additionalInfo?.operatingRule === CertificationType.PART_102
                ? 'Yes'
                : 'No'}
            </Typography>
          </>
        )}
      </div>
      {deviationThresholds && (
        <Fragment>
          <div className="row">
            <Typography className="cell">
              Horizontal Deviation Threshold
            </Typography>{' '}
            <Typography className="cell">
              {deviationThresholds.horizontalThresholdMetres}m
            </Typography>
          </div>
          <div className="row">
            <Typography className="cell">
              Vertical Deviation Threshold
            </Typography>{' '}
            <Typography className="cell">
              {feetToMeters(deviationThresholds.verticalThresholdFeet)}m |{' '}
              {deviationThresholds.verticalThresholdFeet}ft.
            </Typography>
          </div>
        </Fragment>
      )}
      {additionalInfo.isPriorityFlight && (
        <div className="row">
          <Typography className="cell">Priority/Emergency Operation</Typography>{' '}
          <Typography
            className="cell"
            data-testid="pilot-web:flight-briefing-panel:flight-summary-section:priority-flight"
          >
            Yes
          </Typography>
        </div>
      )}
    </Collapse>
  );
};
