import React, { FormEventHandler, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import {
  COMPLETION_DELAY,
  LoadingButton,
  PasswordStrength,
  useTimeoutEffect,
} from 'airshare-pilot-web-shared';
import { Typography, FormControl, FormHelperText } from '@material-ui/core';

import {
  passwordValidationHint,
  resetKeyValidationHint,
} from './reset-password-helpers';
import { changePassword } from '../../../../pilot-api-client';

import './verification-code-entry.scss';

interface Props {
  email: string;
  isPasswordValid: boolean;
  onPasswordValidityChange: React.Dispatch<React.SetStateAction<boolean>>;
  updateResetPasswordStep: React.Dispatch<React.SetStateAction<void>>;
}

export default function VerificationCodeEntry({
  email,
  isPasswordValid,
  onPasswordValidityChange,
  updateResetPasswordStep,
}: Props) {
  const [newPassword, updateNewPassword] = useState<string>(null);
  const [resetKey, updateResetKey] = useState<string>(null);
  const [error, updateError] = useState<string>();
  const [isLoading, updateIsLoading] = useState(false);
  const [isComplete, updateIsComplete] = useState(false);
  const [passwordSuggestion, onPasswordSuggestionChange] = useState('');

  const isResetKeyError = Boolean(resetKeyValidationHint(resetKey));
  const isPasswordError =
    Boolean(passwordValidationHint(newPassword)) || !isPasswordValid;

  useTimeoutEffect(
    () => {
      updateResetPasswordStep();
      updateIsComplete(false);
    },
    COMPLETION_DELAY,
    isComplete
  );

  function handlePasswordChange(
    result: {
      password: string;
      isValid: boolean | ((prevState: boolean) => boolean);
    },
    zxcvbnResult: { feedback: { warning: string } }
  ) {
    updateError(undefined);
    updateNewPassword(result.password);
    onPasswordValidityChange(result.isValid);
    if (zxcvbnResult?.feedback?.warning)
      onPasswordSuggestionChange(zxcvbnResult.feedback.warning);
    else onPasswordSuggestionChange('');
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    updateIsLoading(true);
    changePassword(email, newPassword, resetKey)
      .then(() => {
        updateIsComplete(true);
      })
      .catch((err: Error) => {
        updateIsLoading(false);
        updateError(err.message);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      data-testid="pilot-web:reset-password-form:activation"
    >
      <Typography className="verification-heading" variant="subtitle1">
        A verification code has been sent to your email. Enter the code to
        continue.
      </Typography>
      <FormControl
        className="form-control verification-code-input"
        error={isResetKeyError}
        data-testid="pilot-web:reset-password-form:verification-code-input"
      >
        <ReactInputVerificationCode
          autoFocus
          length={6}
          onCompleted={(value: string) => {
            updateError(undefined);
            updateResetKey(value);
          }}
        />
        <FormHelperText className="input-hint">
          {resetKey !== null && resetKeyValidationHint(resetKey)}
        </FormHelperText>
      </FormControl>

      <FormControl
        className="form-control"
        error={isPasswordError}
        data-testid="pilot-web:reset-password-form:password-field"
      >
        <PasswordStrength
          onPasswordChange={handlePasswordChange}
          placeholder="Password"
          style={undefined}
          label=""
        />
        <FormHelperText
          className="input-hint"
          data-testid="pilot-web:reset-password-form:password-field-helper-text"
        >
          {(newPassword !== null && passwordValidationHint(newPassword)) ||
            passwordSuggestion}
        </FormHelperText>
      </FormControl>

      <FormHelperText
        className="validation-errors"
        error
        data-testid="pilot-web:reset-password-form:password-validation"
      >
        {error}
      </FormHelperText>

      <LoadingButton
        disabled={isResetKeyError || isPasswordError}
        isLoading={isLoading}
        className="form-control"
        type="submit"
        data-testid="pilot-web:reset-password-form:submit-button"
      >
        Reset My Password
      </LoadingButton>
    </form>
  );
}
