import React from 'react';
import { FLIGHT_REQUESTS_PATH, INFORMATION_PATH } from '../../../../routes';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { useIsAuthenticated } from '../../../shared/hooks/is-authenticated.hook';

export function Home() {
  const isAuthenticated = useIsAuthenticated();
  const navigateTo = useNavigateTo();

  if (isAuthenticated) {
    setTimeout(() => navigateTo(FLIGHT_REQUESTS_PATH), 10);
  } else {
    setTimeout(() => navigateTo(INFORMATION_PATH), 10);
  }

  return <></>;
}
