import { useDispatch, useSelector } from 'react-redux';
import {
  push,
  getLocation,
  RouterRootState,
  RouterLocation,
} from 'connected-react-router';

export function useNavigateTo() {
  const dispatch = useDispatch();

  return (route: string, state?: { referrer?: string }) =>
    dispatch(push(route, state));
}

type LocationState = { referrer?: string; notams?: { id?: string }[] };

export function useLocation() {
  const location: RouterLocation<LocationState> = useSelector(
    getLocation<RouterRootState<LocationState>>
  );

  return location;
}
