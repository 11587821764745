import { pickBy, isEqual } from 'lodash';
import type {
  AirshareUser,
  UpdatePilotUserRequestBody,
} from '@airshare/pilot-types';

export const getUpdateReqBodyFromEdits = (
  current: AirshareUser | null,
  edited: AirshareUser | null
): UpdatePilotUserRequestBody => {
  // Get all changes between the objects
  const changes: any = pickBy(
    edited,
    (value, key) => !isEqual((current as any)[key], value)
  );
  changes.id = current?.id;

  // If fields inside the pilot have changed, flatten them out
  if ('pilot' in changes) {
    delete changes.pilot;
    const pilotChanges = pickBy(
      edited?.pilot,
      (value, key) => !isEqual((current?.pilot as any)[key], value)
    );
    return {
      ...changes,
      ...pilotChanges,
    };
  }
  return changes;
};
