import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import type { FlightAdvisoryResponse } from '@airshare/external-api-types';

import { formatAlt } from './flight-advisories.helpers';

export default function FlightAdvisoryMarkerInfo(
  flight: FlightAdvisoryResponse
) {
  const renderListItem = (item: { key: string; value: string }) => (
    <ListItem style={{ display: 'flex' }} key={item.key}>
      <ListItemText
        style={{ maxWidth: 80, margin: 0 }}
        primaryTypographyProps={{
          style: { fontWeight: 600, width: 80, color: 'white' },
        }}
        primary={`${item.key}:`}
      />
      <ListItemText
        style={{ margin: 0 }}
        primaryTypographyProps={{ style: { float: 'left', color: 'white' } }}
        primary={item.value}
      />
    </ListItem>
  );
  const flightLabel =
    flight.properties.flightId?.toString() +
    (flight.properties.segmentId ? `-${flight.properties.segmentId}` : '');
  return (
    <List
      dense
      style={{ minWidth: 220, cursor: 'pointer' }}
      data-testid="flight-advisory-marker-info"
      id={`flight-advisory-${flightLabel}-marker-info`}
    >
      {[
        { key: 'Type', value: flight.properties.acType },
        { key: 'Flight ID', value: flightLabel },
        {
          key: 'Lower Alt',
          value: formatAlt(
            flight.properties.minAltitudeFeet,
            flight.properties.minAltitudeRef
          ),
        },
        {
          key: 'Upper Alt',
          value: formatAlt(
            flight.properties.maxAltitudeFeet,
            flight.properties.maxAltitudeRef
          ),
        },
      ].map(renderListItem)}
    </List>
  );
}
