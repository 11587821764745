import { createSelector } from 'reselect';
import { FlightPlanState } from './saga';
import { RootState } from '../store';

const getLocalState = (state: RootState) => state.flightPlan;

export const getFlightPlanValidationStatus = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.validationStatus
);

export const getFlightPlanCreationStatus = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.creationStatus
);

export const getFlightPlanSelectedFavourite = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.selectedFavourite
);

export const getFlightPlanSelectedFlightPathArea = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.selectedFlightPathArea
);

export const getIsEditingFavouriteFlightArea = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.isEditingFavouriteFlightArea
);

export const getWarnings = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.validationSuccess
);

export const getFlightPlanEditing = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.editFlightPlan
);

export const getFlightPlanUpdatingStatus = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.updateStatus
);

export const getFlightPlanUploadedFlightArea = createSelector(
  [getLocalState],
  (state: FlightPlanState) => state.uploadedFlightArea
);
