import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Typography, TextField } from '@material-ui/core';

import {
  SegmentValidationDetailItem,
  type LocalSegmentDetailItem,
} from './segment-helpers';

export const SegmentPlannerModalDetailRow = ({
  segment,
  segmentValidationResults,
  onTimeInputChange,
  onAltitudeInputChange,
  timezone,
}: {
  segment: LocalSegmentDetailItem | undefined;
  segmentValidationResults: SegmentValidationDetailItem | null;
  onTimeInputChange: (
    segmentId: string,
    isStartTime: boolean,
    value: string
  ) => void;
  onAltitudeInputChange: (
    segmentId: string,
    isMaxAltitude: boolean,
    value: string
  ) => void;
  timezone: string;
}): JSX.Element => {
  return segment?.id ? (
    <>
      <GridTextItem text={segment.id} />
      <GridTextItem text={segment.distanceMeters?.toLocaleString()} />
      <GridTimeSelectorItem
        onTimeInputChange={onTimeInputChange}
        segmentId={segment.id}
        isStartTime={true}
        timezone={timezone}
        dateTime={segment.startDateTime}
        validationError={segmentValidationResults?.startDateTime}
      />
      <GridTimeSelectorItem
        onTimeInputChange={onTimeInputChange}
        segmentId={segment.id}
        isStartTime={false}
        timezone={timezone}
        dateTime={segment.endDateTime}
        validationError={segmentValidationResults?.endDateTime}
      />
      <GridAltitudeInputItem
        onAltitudeInputChange={onAltitudeInputChange}
        segmentId={segment.id}
        isMaxAltitude={false}
        inputValue={segment.minAltitudeFeet}
        validationError={segmentValidationResults?.minAltitudeFeet}
      />
      <GridAltitudeInputItem
        onAltitudeInputChange={onAltitudeInputChange}
        segmentId={segment.id}
        isMaxAltitude={true}
        inputValue={segment.maxAltitudeFeet}
        validationError={segmentValidationResults?.maxAltitudeFeet}
      />
    </>
  ) : null;
};

export const GridTextItem = ({
  text,
  color = null,
  isHeader = false,
}: {
  text: string;
  color?: string | null;
  isHeader?: boolean;
}): JSX.Element => {
  const style = color ? { color, fontWeight: 600 } : {};
  return (
    <div className="segment-planner-grid-item">
      {isHeader ? (
        <Typography
          variant="subtitle1"
          className="segment-planner-grid-header-item-label"
          style={style}
        >
          {text}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          className="segment-planner-grid-item-readonly-text"
          style={style}
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

const GridTimeSelectorItem = ({
  segmentId,
  dateTime,
  timezone,
  isStartTime,
  validationError,
  onTimeInputChange,
}: {
  segmentId: string;
  dateTime: string;
  timezone: string;
  isStartTime: boolean;
  validationError: boolean | null;
  onTimeInputChange: (
    segmentId: string,
    isStartTime: boolean,
    value: string
  ) => void;
}): JSX.Element => {
  const localTime = DateTime.fromISO(dateTime)
    .setZone(timezone)
    .toFormat('HH:mm');
  const fieldName = isStartTime ? 'startDateTime' : 'endDateTime';
  return (
    <div
      className={classNames(
        'segment-planner-grid-item',
        validationError ? 'validation-error' : null
      )}
    >
      <TextField
        type="time"
        id={`${segmentId}-${fieldName}-input`}
        inputProps={{
          value: localTime,
          'data-testid': `pilot-web:segment-planner-modal:${segmentId}-${fieldName}-input-inner`,
        }}
        onChange={(e) => {
          onTimeInputChange(segmentId, isStartTime, e.target.value);
        }}
        data-testid={`pilot-web:segment-planner-modal:${segmentId}-${fieldName}-input`}
        className="segment-planner-grid-item-time-input"
      />
    </div>
  );
};

const GridAltitudeInputItem = ({
  segmentId,
  inputValue,
  isMaxAltitude,
  validationError,
  onAltitudeInputChange,
}: {
  segmentId: string;
  inputValue: number;
  isMaxAltitude: boolean;
  validationError: boolean | null;
  onAltitudeInputChange: (
    segmentId: string,
    isMaxAltitude: boolean,
    value: string
  ) => void;
}): JSX.Element => {
  const fieldName = isMaxAltitude ? 'maxAltitudeFeet' : 'minAltitudeFeet';
  return (
    <div
      className={classNames(
        'segment-planner-grid-item',
        validationError ? 'validation-error' : null
      )}
    >
      <TextField
        type="number"
        id={`${segmentId}-${fieldName}-input`}
        inputProps={{
          value: inputValue,
          'data-testid': `pilot-web:segment-planner-modal:${segmentId}-${fieldName}-input-inner`,
          min: 0,
        }}
        onChange={(e) => {
          onAltitudeInputChange(segmentId, isMaxAltitude, e.target.value);
        }}
        data-testid={`pilot-web:segment-planner-modal:${segmentId}-${fieldName}-input`}
        className="segment-planner-grid-item-altitude-input"
      />
    </div>
  );
};

export default SegmentPlannerModalDetailRow;
