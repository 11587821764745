import React from 'react';
import Typography from '@material-ui/core/Typography';

import './advisory-contact-details.scss';

export default function AdvisoryContactDetails({
  address,
  phone,
  email,
}: {
  address?: string;
  phone?: string;
  email?: string;
}) {
  const isNoDetails = !address && !email && !phone;

  if (isNoDetails) {
    return (
      <Typography className="no-details" variant="body1">
        The contact details are not available
      </Typography>
    );
  }

  return (
    <>
      {address && (
        <Typography className="address" variant="body1">
          {address}
        </Typography>
      )}
      {phone && (
        <Typography className="phone" variant="body1">
          {phone}
        </Typography>
      )}
      {email && (
        <Typography className="email" variant="body1">
          {email}
        </Typography>
      )}
    </>
  );
}
