import React, { useEffect, useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { type PaperProps } from '@material-ui/core/Paper';
import { Typography, Checkbox } from '@material-ui/core';
import type { Feature, Polygon } from 'geojson';
import type { AxiosError } from 'axios';
import Draggable from 'react-draggable';

import {
  FRStatusCode,
  SegmentClearanceStatusCode,
  SegmentStatusCode,
  type StyledFlightSegmentPropertiesJSONView,
} from '@airshare/external-api-types';

import { pilotAPI } from '../../../pilot-api-client/api';
import { useFocussedFlightRequest } from '../../../state/flight-requests/hooks';
import {
  checkAllSegmentsTerminated,
  getSegmentClearanceStatusLabel,
  getSegmentAltitudeRangeLabel,
  getSegmentStatusLabel,
  getSegmentTitleColor,
} from './segment-helpers';

import './segment-activation-modal.scss';

export const SegmentActivationModal = ({
  closeSegmentModal,
}: {
  closeSegmentModal: () => void;
}) => {
  const focussedFlight = useFocussedFlightRequest();
  const [showModal, setShowModal] = useState(false);
  const [segmentsToActivate, setSegmentsToActivate] = useState<string[]>([]);
  const [segmentsToTerminate, setSegmentsToTerminate] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isInitialised, setIsInitialised] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    const isSegmented =
      focussedFlight?.properties?.segmentCollection?.features?.length > 1;

    setShowModal(isSegmented);
  }, [focussedFlight]);

  useEffect(() => {
    if (showModal && !isInitialised) {
      const currentSegments =
        focussedFlight.properties.segmentCollection.features.map((f) => ({
          id: f.properties.id,
          segmentStatus: f.properties.segmentStatus,
          segmentClearanceStatus: f.properties.segmentClearanceStatus,
        }));
      const overallStatus = focussedFlight.properties.status;

      if (overallStatus === FRStatusCode.Declared) {
        setSegmentsToActivate([currentSegments[0].id]);
      } else {
        const currentActiveSegments =
          currentSegments
            .filter((f) => f.segmentStatus === SegmentStatusCode.ACTIVATED)
            .map((f) => f.id) || [];

        if (currentActiveSegments.length > 1) {
          setSegmentsToTerminate(
            currentActiveSegments.slice(0, -1).filter((s) => s) || []
          );
        }

        setSegmentsToActivate(
          [
            currentSegments.find(
              (f) =>
                f.segmentStatus === SegmentStatusCode.IDLE &&
                f.segmentClearanceStatus !==
                  SegmentClearanceStatusCode.REQUESTED
            )?.id,
          ].filter((s) => s) || []
        );
      }

      setIsInitialised(true);
    }
  }, [
    focussedFlight.properties.segmentCollection.features,
    focussedFlight.properties.status,
    isInitialised,
    showModal,
  ]);

  async function sendSegmentActivationRequest() {
    return pilotAPI
      .put(
        `/v2/flight-requests/${focussedFlight.properties.flightId}/request-segment-activation`,
        {
          segmentsToActivate: segmentsToActivate?.filter((s) => s) || [],
          segmentsToTerminate: segmentsToTerminate?.filter((s) => s) || [],
        },
        { timeout: 5000 }
      )
      .then((_resp) => {
        setIsBusy(false);
        setErrorMessage(null);
        closeSegmentModal();
      })
      .catch((error: AxiosError<{ errors: string[] }>) => {
        if (
          error.response?.status === 400 &&
          error.response?.data?.errors?.length > 0
        ) {
          setErrorMessage(error.response?.data?.errors.join(', '));
        } else {
          setErrorMessage(
            'There was an error activating segments. Please try again later.'
          );
        }
        setIsBusy(false);
      });
  }

  async function terminateFlight() {
    return pilotAPI
      .post(
        `/terminate-flight-request`,
        {
          id: focussedFlight.properties.flightId,
        },
        { timeout: 5000 }
      )
      .then((_resp) => {
        setIsBusy(false);
        setErrorMessage(null);
        closeSegmentModal();
      })
      .catch((error: AxiosError<{ errors: string[] }>) => {
        if (
          error.response?.status === 400 &&
          error.response?.data?.errors?.length > 0
        ) {
          setErrorMessage(error.response?.data?.errors.join(', '));
        } else {
          setErrorMessage(
            'There was an error terminating the flight. Please try again later.'
          );
        }
        setIsBusy(false);
      });
  }

  const handleCancel = () => {
    closeSegmentModal();
  };
  const handleSubmit = () => {
    setIsBusy(true);

    const allSegmentsTerminated = checkAllSegmentsTerminated(
      focussedFlight.properties.segmentCollection,
      segmentsToActivate,
      segmentsToTerminate
    );

    if (allSegmentsTerminated) {
      return terminateFlight();
    } else {
      return sendSegmentActivationRequest();
    }
  };
  const handleClose = () => {
    closeSegmentModal();
  };

  const onCheckboxChange = (
    segmentId: string,
    activate: boolean,
    terminate: boolean,
    isChecked: boolean
  ) => {
    if (activate && isChecked) {
      setSegmentsToActivate([...segmentsToActivate, segmentId]);
    } else {
      setSegmentsToActivate(
        segmentsToActivate.filter((id) => id !== segmentId)
      );
    }

    if (terminate && isChecked) {
      setSegmentsToTerminate([...segmentsToTerminate, segmentId]);
    } else {
      setSegmentsToTerminate(
        segmentsToTerminate.filter((id) => id !== segmentId)
      );
    }
  };

  return showModal ? (
    <Dialog
      open={showModal}
      className="segment-activation-modal"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      maxWidth="md"
    >
      <DialogTitle
        style={{ cursor: 'move' }}
        data-testid="pilot-web:segment-activation-modal:dialog-title"
        id="segment-activation-draggable-title"
        className="segment-activation-modal-title"
        disableTypography={true}
      >
        <Typography variant="h6" component="h1" align="left">
          Activate/Terminate Segments
        </Typography>
      </DialogTitle>
      <DialogContent className="segment-activation-modal-content">
        <div className="segment-activation-modal-header-grid">
          <GridTextItem text="Segment ID" isHeader={true} />
          <GridTextItem text="Altitude (AGL)" isHeader={true} />
          <GridTextItem text="Status" isHeader={true} />
          <GridTextItem text="Approval" isHeader={true} />
          <GridTextItem text="Request Activation" isHeader={true} />
          <GridTextItem text="Terminate" isHeader={true} />
        </div>

        <div className="segment-activation-modal-details-grid">
          {focussedFlight?.properties?.segmentCollection?.features.map(
            (segmentFeature) => {
              const activateIsChecked =
                segmentsToActivate.includes(segmentFeature?.properties?.id) ||
                false;
              const terminateIsChecked =
                segmentsToTerminate.includes(segmentFeature?.properties?.id) ||
                false;

              return (
                <SegmentDetailTableRow
                  key={segmentFeature?.properties?.id}
                  segmentFeature={segmentFeature}
                  activateIsChecked={activateIsChecked}
                  terminateIsChecked={terminateIsChecked}
                  onCheckboxChange={onCheckboxChange}
                />
              );
            }
          )}
        </div>
      </DialogContent>
      <DialogActions className="segment-activation-dialog-actions">
        {errorMessage && (
          <Typography
            variant="subtitle1"
            color="error"
            className="segment-activation-error-message"
          >
            {errorMessage}
          </Typography>
        )}
        {isBusy && <CircularProgress size={24} />}
        <ButtonBase
          onClick={handleCancel}
          data-testid="pilot-web:segment-activation-modal:dialog-cancel-button"
          className="cancel-button"
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          color="primary"
          onClick={async () => await handleSubmit()}
          data-testid="pilot-web:segment-activation-modal:dialog-submit-button"
          className="submit-button"
        >
          Submit
        </ButtonBase>
      </DialogActions>
    </Dialog>
  ) : null;
};

const SegmentDetailTableRow = ({
  segmentFeature,
  activateIsChecked,
  terminateIsChecked,
  onCheckboxChange,
}: {
  segmentFeature:
    | Feature<Polygon, StyledFlightSegmentPropertiesJSONView>
    | undefined;

  activateIsChecked: boolean;
  terminateIsChecked: boolean;
  onCheckboxChange: (
    segmentId: string,
    activate: boolean,
    terminate: boolean,
    isChecked: boolean
  ) => void;
}): JSX.Element => {
  const activateButtonIsDisabled =
    [SegmentStatusCode.TERMINATED, SegmentStatusCode.ACTIVATED].includes(
      segmentFeature.properties.segmentStatus
    ) ||
    [SegmentClearanceStatusCode.REQUESTED].includes(
      segmentFeature.properties.segmentClearanceStatus
    ) ||
    false;

  const terminateButtonIsDisabled =
    [SegmentStatusCode.TERMINATED].includes(
      segmentFeature.properties.segmentStatus
    ) || false;

  return segmentFeature?.properties?.id ? (
    <>
      <GridTextItem
        text={segmentFeature.properties.id}
        color={getSegmentTitleColor(segmentFeature)}
      />
      <GridTextItem
        text={getSegmentAltitudeRangeLabel(
          segmentFeature.properties.minAltitudeFeet,
          segmentFeature.properties.maxAltitudeFeet
        )}
        color={getSegmentTitleColor(segmentFeature)}
      />
      <GridTextItem
        text={getSegmentStatusLabel(segmentFeature.properties.segmentStatus)}
        color={getSegmentTitleColor(segmentFeature)}
      />
      <GridTextItem
        text={getSegmentClearanceStatusLabel(
          segmentFeature.properties.segmentClearanceStatus
        )}
        color={getSegmentTitleColor(segmentFeature)}
      />

      <div className="segment-activation-grid-item">
        {activateButtonIsDisabled ? (
          <div className="disabled-checkbox"></div>
        ) : (
          <Checkbox
            data-testid={`pilot-web:segment-activation-modal:activate-checkbox-${segmentFeature.properties.id}`}
            checked={activateIsChecked}
            onChange={(e) =>
              onCheckboxChange(
                segmentFeature.properties.id,
                true,
                false,
                e.target.checked
              )
            }
            name="activate"
            color="primary"
            disabled={activateButtonIsDisabled}
            className="segment-activation-checkbox"
          />
        )}
      </div>
      <div className="segment-activation-grid-item">
        {terminateButtonIsDisabled ? (
          <div className="disabled-checkbox"></div>
        ) : (
          <Checkbox
            data-testid={`pilot-web:segment-activation-modal:terminate-checkbox-${segmentFeature.properties.id}`}
            checked={terminateIsChecked}
            onChange={(e) =>
              onCheckboxChange(
                segmentFeature.properties.id,
                false,
                true,
                e.target.checked
              )
            }
            name="terminate"
            color="primary"
            disabled={terminateButtonIsDisabled}
            className="segment-activation-checkbox"
          />
        )}
      </div>
    </>
  ) : null;
};

const GridTextItem = ({
  text,
  color = null,
  isHeader = false,
}: {
  text: string;
  color?: string | null;
  isHeader?: boolean;
}): JSX.Element => {
  const style = color ? { color, fontWeight: 600 } : {};
  return (
    <div className="segment-activation-grid-item">
      <Typography
        variant={isHeader ? 'subtitle1' : 'body2'}
        className={
          isHeader
            ? 'segment-activation-grid-header-item-label'
            : 'segment-detail-grid-item-label'
        }
        style={style}
      >
        {text}
      </Typography>
    </div>
  );
};

function PaperComponent(props: Readonly<PaperProps>) {
  return (
    <Draggable
      handle="#segment-activation-draggable-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default SegmentActivationModal;
