import { createAction } from 'airshare-web-utils/redux-helpers';
import * as actions from './actions';

export const profileFetchRequested = createAction(actions.FETCH_REQUESTED);
export const profileFetchSucceeded = createAction(actions.FETCH_SUCCEEDED);
export const profileFetchFailed = createAction(actions.FETCH_FAILED);

export const profileUpdateRequested = createAction<
  any,
  typeof actions.UPDATE_REQUESTED
>(actions.UPDATE_REQUESTED);
export const profileUpdateSucceeded = createAction(actions.UPDATE_SUCCEEDED);
export const profileUpdateFailed = createAction(actions.UPDATE_FAILED);
export const profileUpdateStatusReset = createAction<
  void,
  typeof actions.UPDATE_STATUS_RESET
>(actions.UPDATE_STATUS_RESET);
