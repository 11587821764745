import { DateTime } from 'luxon';
import type { LineString } from 'geojson';

import {
  FlightRule,
  FlightPathModeEnum,
  UasOperationCategory,
} from '@airshare/pilot-types';

import { StyledSegmentFeatureCollectionJSONView } from '@airshare/external-api-types';

export enum DraftFlightAreaSource {
  NONE = 'NONE',
  DRAW = 'DRAW',
  UPLOAD = 'UPLOAD',
  FAVOURITE = 'FAVOURITE',
  MANAGEDAREA = 'MANAGEDAREA',
}

export interface coordinate {
  lat: number;
  lng: number;
}

export interface FlightPlanForm {
  managedAreaId: string | null;
  nickname: string | null;
  isFavourite: boolean;
  altitudeFeet: number;
  isShielded: boolean;
  flightPurpose: string | null;
  dateAndTime: DateTime | Date | null;
  durationMinutes: number;
  pilotId: string | null;
  pilotName: string | null;
  pilotEmail: string | null;
  uavId: string | null;
  rule: FlightRule | null;
  isCameraInUse: boolean;
  isPriorityFlight?: boolean;
  requiresAdditionalInformation: boolean;
  hasCertifiedTransmitter: boolean;
  hasVhfRadioContact: boolean;
  descriptionOfOperatingArea: string | null;
  procedureMeasureAltitude: string | null;
  emergencyProcedure: string | null;
  otherInformation: string | null;
  coordinates: coordinate[] | null;
  pathMode: FlightPathModeEnum | null;
  radius: number;
  certificationNumber: string | null;
  certificationType: string | null;
  flightAreaSource: DraftFlightAreaSource | null;
  waypoints: LineString | null;
  segmentCollection: StyledSegmentFeatureCollectionJSONView | null;
  uasOperationCategory?: UasOperationCategory;
}
