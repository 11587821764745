import { createSelector } from 'reselect';
import get from 'lodash/get';

const getLocalState = (state) => state.uavs;

export const getUavsCreationStatus = createSelector(
  [getLocalState],
  (state) => state.creationStatus
);

export const getUavsUpdateStatus = createSelector(
  [getLocalState],
  (state) => state.updateStatus
);

export const getUavsDeletionStatus = createSelector(
  [getLocalState],
  (state) => state.deletionStatus
);

export const getUavsFetchStatus = createSelector(
  [getLocalState],
  (state) => state.fetchStatus
);

export const getAllUavs = createSelector([getLocalState], (state) =>
  state.pages.reduce((accumulator, array) => accumulator.concat(array), [])
);

export const getUavsPages = createSelector(
  [getLocalState],
  (state) => state.pages
);

export const getUavsMeta = createSelector(
  [getLocalState],
  (state) => state.meta
);

export const getUavsPageByIndex = (index) =>
  createSelector([getLocalState], (state) => {
    const uavs = get(state, `pages[${index}]`, null);

    if (!uavs) return null;

    return uavs.sort((a, b) => a.name.localeCompare(b.name));
  });
