import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import {
  uavsFetchRequested,
  uavsCreationRequested,
  uavsStateReset,
  uavsDeletionRequested,
  uavsUpdateRequested,
} from './actions';
import {
  getUavsCreationStatus,
  getUavsFetchStatus,
  getUavsPages,
  getUavsMeta,
  getUavsPageByIndex,
  getUavsDeletionStatus,
  getUavsUpdateStatus,
} from './selectors';
import { UavsFetchStatus } from './constants';

export function useFetchUavs() {
  const dispatch = useDispatch();

  return (payload) => dispatch(uavsFetchRequested(payload));
}

export function useUavs(pageIndex) {
  const fetchUavs = useFetchUavs();

  useEffect(() => {
    fetchUavs({ pageIndex });
  }, [pageIndex]);

  const uavsFetchStatus = useSelector(getUavsFetchStatus);

  return uavsFetchStatus;
}

export function useUavsStatusPromise() {
  const uavsFetchStatus = useSelector(getUavsFetchStatus);
  const [statusPromise, updateStatusPromise] = useState(null);
  const resolveRef = useRef();
  const rejectRef = useRef();

  useEffect(() => {
    switch (uavsFetchStatus) {
      case UavsFetchStatus.UAV_FETCH_IN_PROGRESS: {
        const promise = new Promise((resolve, reject) => {
          resolveRef.current = resolve;
          rejectRef.current = reject;
        });

        updateStatusPromise(promise);
        break;
      }

      case UavsFetchStatus.SUCCESS:
        if (resolveRef.current) resolveRef.current();
        break;

      case UavsFetchStatus.FAILED:
        if (rejectRef.current) rejectRef.current();
        break;

      case UavsFetchStatus.IDLE:
      default:
        updateStatusPromise(null);
        resolveRef.current = null;
        rejectRef.current = null;
    }
  }, [uavsFetchStatus]);

  return statusPromise;
}

export function useUav(id) {
  const pages = useUavsPages();

  return pages ? flatten(pages).find((uav) => uav.id === id) || null : null;
}

export function useDeleteUav() {
  const dispatch = useDispatch();
  const uavDeletionStatus = useSelector(getUavsDeletionStatus);

  const deleteUav = (payload) => dispatch(uavsDeletionRequested(payload));

  return [deleteUav, uavDeletionStatus];
}

export function useCreateUav() {
  const dispatch = useDispatch();
  const uavCreationStatus = useSelector(getUavsCreationStatus);

  const createUav = (payload) => dispatch(uavsCreationRequested(payload));

  return [createUav, uavCreationStatus];
}

export function useUpdateUav() {
  const dispatch = useDispatch();
  const uavUpdateStatus = useSelector(getUavsUpdateStatus);

  const updateUav = (payload) => dispatch(uavsUpdateRequested(payload));

  return [updateUav, uavUpdateStatus];
}

export function useResetState() {
  const dispatch = useDispatch();

  return () => dispatch(uavsStateReset());
}

export function useUavsPages() {
  const pages = useSelector(getUavsPages);

  return pages;
}

export function useUavsMeta() {
  const meta = useSelector(getUavsMeta);

  return meta;
}

export function useUavsPageCount() {
  const pages = useUavsPages();

  return pages.length;
}

export function useUavsTotalRecords() {
  const meta = useUavsMeta();

  return meta ? meta.totalRecords : null;
}

export function useUavsTotalPages() {
  const meta = useUavsMeta();

  return meta ? Math.ceil(meta.totalRecords / meta.pageSize) : null;
}

export function useUavsPageByIndex(index) {
  const uavsPage = useSelector(getUavsPageByIndex(index));

  return uavsPage;
}

export function useUavsFetchStatus() {
  const fetchStatus = useSelector(getUavsFetchStatus);

  return fetchStatus;
}
