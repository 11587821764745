import { createSelector } from 'reselect';
import { PreValidateFlightAreaState } from './saga';

const getLocalState = (state: any) => state.flightPlan.preValidateFlightArea;

export const getFlightAreaPreValidationStatus = createSelector(
  [getLocalState],
  (state: PreValidateFlightAreaState) => state.validationStatus
);

export const getFlightAreaPreValidation = createSelector(
  [getLocalState],
  (state: PreValidateFlightAreaState) => state.flightAreaValidation
);
