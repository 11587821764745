// eslint-disable-next-line import/no-unresolved
import type { FeatureCollection } from 'geojson';
import { contentAPI } from './api';

export const getAdvisoryLayer: (
  mapCode: string
) => Promise<FeatureCollection> = async (mapCode: string) =>
  contentAPI
    .get<FeatureCollection>(`/advisories/layers/${mapCode}.geojson?isATC=false`)
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to fetch advisory layer');
    });
