import { useEffect, useState, useRef, MutableRefObject } from 'react';
import { Feature, featureCollection } from '@turf/helpers';
import bbox from '@turf/bbox';

const FLIGHT_AREA_Z_INDEX = 1;

export function useFlightAreaLayer(
  google: any,
  map: any,
  flightAreas: Feature[]
) {
  const flightAreasRef: MutableRefObject<any[]> = useRef([]);
  const [isInitRun, setIsInitRun] = useState(true);

  useEffect(() => {
    if (google && map && flightAreas && flightAreasRef.current) {
      flightAreas.forEach((flightArea, index) => {
        if (flightAreasRef.current[index]) {
          flightAreasRef.current[index].dataLayer.setMap(null);
        }

        flightAreasRef.current[index] = {
          flightId: flightArea.properties?.name,
          dataLayer: new google.maps.Data({
            map,
          }),
        };

        flightAreasRef.current[index].dataLayer.addGeoJson(flightArea);

        flightAreasRef.current[index].dataLayer.setStyle(
          (styleProps: any): any => ({
            ...styleProps,
            zIndex: FLIGHT_AREA_Z_INDEX,
            strokeWeight: 3,
            fillOpacity: 0.6,
            strokeColor:
              flightArea.properties && flightArea.properties.strokeColor
                ? flightArea.properties.strokeColor
                : '#000000',
            fillColor:
              flightArea.properties && flightArea.properties.fillColor
                ? flightArea.properties.fillColor
                : '#000000',
          })
        );

        if (isInitRun) {
          const boundingBox = bbox(featureCollection(flightAreas));
          map.fitBounds({
            west: boundingBox[0],
            north: boundingBox[1],
            east: boundingBox[2],
            south: boundingBox[3],
          });
          setIsInitRun(false);
        }
      });
    }
  }, [google, map, flightAreas, isInitRun]);
}
