import { createAction } from 'airshare-web-utils/redux-helpers';

export const FETCH_REQUESTED = 'uavs/FETCH_REQUESTED';
export const FETCH_SUCCEEDED = 'uavs/FETCH_SUCCEEDED';
export const FETCH_FAILED = 'uavs/FETCH_FAILED';
export const STATE_RESET = 'uavs/STATE_RESET';
export const FETCH_STATUS_RESET = 'uavs/FETCH_STATUS_RESET';
export const CREATION_REQUESTED = 'uavs/CREATION_REQUESTED';
export const CREATION_SUCCEEDED = 'uavs/CREATION_SUCCEEDED';
export const CREATION_FAILED = 'uavs/CREATION_FAILED';
export const CREATION_STATUS_RESET = 'uavs/CREATION_STATUS_RESET';
export const DELETION_REQUESTED = 'uavs/DELETION_REQUESTED';
export const DELETION_SUCCEEDED = 'uavs/DELETION_SUCCEEDED';
export const DELETION_FAILED = 'uavs/DELETION_FAILED';
export const DELETION_STATUS_RESET = 'uavs/DELETION_STATUS_RESET';
export const UPDATE_REQUESTED = 'uavs/UPDATE_REQUESTED';
export const UPDATE_SUCCEEDED = 'uavs/UPDATE_SUCCEEDED';
export const UPDATE_FAILED = 'uavs/UPDATE_FAILED';
export const UPDATE_STATUS_RESET = 'uavs/UPDATE_STATUS_RESET';

export const uavsFetchRequested = createAction(FETCH_REQUESTED);
export const uavsFetchSucceeded = createAction(FETCH_SUCCEEDED);
export const uavsFetchFailed = createAction(FETCH_FAILED);
export const uavsStateReset = createAction(STATE_RESET);
export const uavsCreationRequested = createAction(CREATION_REQUESTED);
export const uavsCreationSucceeded = createAction(CREATION_SUCCEEDED);
export const uavsCreationFailed = createAction(CREATION_FAILED);
export const uavsCreationStatusReset = createAction(CREATION_STATUS_RESET);
export const uavsDeletionRequested = createAction(DELETION_REQUESTED);
export const uavsDeletionSucceeded = createAction(DELETION_SUCCEEDED);
export const uavsDeletionFailed = createAction(DELETION_FAILED);
export const uavsDeletionStatusReset = createAction(DELETION_STATUS_RESET);
export const uavsUpdateRequested = createAction(UPDATE_REQUESTED);
export const uavsUpdateSucceeded = createAction(UPDATE_SUCCEEDED);
export const uavsUpdateFailed = createAction(UPDATE_FAILED);
export const uavsUpdateStatusReset = createAction(UPDATE_STATUS_RESET);
