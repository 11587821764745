/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { withRouter, type RouteComponentProps } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { LoadingButton, useIsInTabletMode } from 'airshare-pilot-web-shared';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {
  useUav,
  useUavs,
  useDeleteUav,
  useCreateUav,
  useUpdateUav,
} from '~/state/uavs/hooks';
import {
  UavsDeletionStatus,
  UavsCreationStatus,
  UavsUpdateStatus,
} from '~/state/uavs/constants';
import { ImageFallback } from '~/components/shared/image-fallback/image-fallback';
import { CloseButton } from '../../../shared/close-button/close-button';
import InfoTooltip from '../../../shared/info-tooltip/info-tooltip';
import {
  useNavigateTo,
  useLocation,
} from '~/components/shared/hooks/router.hook';
import { UAVS_PATH, ADD_UAV_PATH } from '~/routes';
import { BackButton } from '~/components/shared/back-button/back-button';
import { LoadingPanel } from '~/components/shared/loading-panel/loading-panel';
import { useUavDefaults } from '~/state/uav-defaults/hooks';
import { useProfile, useFetchProfile } from '~/state/profile/hooks';
import { useIsOrgAdmin } from '~/state/session/hooks';

import {
  useFormState,
  useInputHandlerProps,
  useIsErrored,
  useFieldError,
  useErrors,
  useHandleSubmit,
  useSetFieldValue,
  useResetErrors,
  useClearForm,
  useUpdateValidation,
} from '~/state/uavs/add-uav-form/hooks';

import fallbackImage from './images/drone.png';

import './uav-detail.scss';

function UavDetailComponent({ match }: RouteComponentProps<{ id: string }>) {
  const navigateTo = useNavigateTo();
  useUavs(0);
  const uav = useUav(match.params.id);

  const profile = useProfile();
  const isOrgAdmin = useIsOrgAdmin();
  const fetchProfile = useFetchProfile();

  const [isRequestingDelete, updateIsRequestingDelete] = useState(false);
  const [deleteUav, uavDeletionStatus] = useDeleteUav();
  const isInTabletMode = useIsInTabletMode();
  const uavDefaults = useUavDefaults();
  const [createUav, creationStatus] = useCreateUav();
  const [updateUav, updateStatus] = useUpdateUav();
  const location = useLocation();
  const referrer = location?.state?.referrer || null;

  const form = useFormState();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const handlerProps = useInputHandlerProps();
  const fieldError = useFieldError();
  // @ts-ignore
  const setFieldValue = useSetFieldValue(form);
  const resetErrors = useResetErrors();
  const [updateValidation] = useUpdateValidation();
  const clearForm = useClearForm();
  const handleSubmit = useHandleSubmit(() => {
    const payload = referrer ? { ...form, referrer } : form;
    if (isEditing) {
      updateUav({
        ...payload,
        id: uav.id,
      });
    } else {
      createUav(payload);
    }
  });

  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true' || false;

  const deleteButtonTransitions = useTransition(!isRequestingDelete, null, {
    from: { bottom: -200 },
    enter: { bottom: 36 },
    leave: { bottom: -200 },
  });

  const areYouSureButtonTransitions = useTransition(isRequestingDelete, null, {
    from: { bottom: -200 },
    enter: { bottom: 36 },
    leave: { bottom: -200 },
  });

  const cancelButtonTransitions = useTransition(isRequestingDelete, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (uav) {
      initialiseFields();
      handleCancelDeleteUav();
    }
  }, [uav]);

  useEffect(() => {
    if (!profile && uav) {
      fetchProfile();
    }
  }, [profile, uav]);

  useEffect(
    () => () => {
      resetErrors();
      clearForm();
    },
    []
  );

  useEffect(() => {
    resetErrors();
    updateValidation(form);
  }, [form]);

  const isUavOwner = () => {
    return isOrgAdmin || profile?.id === uav?.user;
  };

  function handleClose() {
    navigateTo(UAVS_PATH);
  }

  function handleDeleteUav() {
    deleteUav({ id: uav.id });
  }

  function handleRequestDeleteUav() {
    updateIsRequestingDelete(true);
  }

  function handleCancelDeleteUav() {
    updateIsRequestingDelete(false);
  }

  function initialiseFields() {
    const formFields = [
      'name',
      'manufacturer',
      'model',
      'serialNumber',
      'cClass',
      'enduranceMinutes',
      'remoteId',
      'defaultProcedureToMeasureAltitude',
      'defaultEmergencyProcedure',
    ];

    formFields.forEach((field) => {
      setFieldValue(field, uav[field]);
    });

    if (uavDefaults?.weights) {
      const weightLabel = uavDefaults.weights.find(
        (weight: any) => weight.code === uav.weight
      ).name;
      setFieldValue('weight', weightLabel);
    }

    if (
      uav.cClass &&
      uavDefaults?.uavCClasses &&
      uavDefaults?.uavCClasses?.length > 0
    ) {
      const classLabel = uavDefaults.uavCClasses?.find(
        (cClass: any) => cClass.code === uav.cClass
      )?.name;
      setFieldValue('cClass', classLabel);
    }
  }

  function getSerialNumberLabel() {
    return isEditing ? 'Serial Number' : 'Serial Number (Optional)';
  }
  function getRemoteIdLabel() {
    return isEditing ? 'RemoteID' : 'RemoteID (Optional)';
  }

  const isCreating =
    creationStatus === UavsCreationStatus.UAV_CREATION_IN_PROGRESS;
  const isDeleting =
    uavDeletionStatus === UavsDeletionStatus.UAV_DELETION_IN_PROGRESS;
  const isUpdating = updateStatus === UavsUpdateStatus.UAV_UPDATE_IN_PROGRESS;
  const isEditing = match.url !== ADD_UAV_PATH;
  const isLoading = isEditing && !uav;

  // Bit horrible, but can't find a better, reliable way of doing this
  const fieldEdited =
    uav &&
    uavDefaults &&
    uavDefaults.weights &&
    (form.name !== uav.name ||
      uav.weight !==
        uavDefaults.weights.find((weight: any) => weight.name === form.weight)
          ?.code ||
      form.defaultProcedureToMeasureAltitude !==
        uav.defaultProcedureToMeasureAltitude ||
      form.defaultEmergencyProcedure !== uav.defaultEmergencyProcedure);

  return (
    <LoadingPanel isLoading={isLoading}>
      <>
        {isInTabletMode ? (
          <BackButton onClick={handleClose} />
        ) : (
          <CloseButton onClick={handleClose} />
        )}
        <div
          className={`uav-detail ${isEditing ? 'read-only' : ''}`}
          data-testid="pilot-web:uav-detail:panel"
        >
          <ImageFallback
            className="drone-image"
            alt="Drone"
            src={`${window.env.ARGUS_CONTENT_API_URL}${
              isEditing && uav?.image
            }`}
            fallbackSrc={fallbackImage}
          />

          <form
            className="uav-form"
            onSubmit={handleSubmit}
            data-testid="pilot-web:uav-detail:form"
          >
            <FormControl className="form-control" error={isErrored('name')}>
              <InputLabel className="input-label" htmlFor="name">
                Nickname
              </InputLabel>
              <Input
                disabled={isEditing && !isUavOwner()}
                fullWidth
                name="name"
                {...handlerProps}
                value={form.name}
                inputProps={{
                  'data-testid': 'pilot-web:uav-detail:name-input',
                }}
              />
              <FormHelperText className="input-hint">
                {fieldError('name')}
              </FormHelperText>
            </FormControl>

            <FormControl
              className="form-control"
              error={isErrored('manufacturer')}
            >
              <InputLabel className="input-label" htmlFor="manufacturer">
                Brand
              </InputLabel>
              <Select
                data-testid="pilot-web:uav-detail:brand-select"
                data-value={form.manufacturer}
                disabled={isEditing}
                type="text"
                placeholder="Add a Brand"
                name="manufacturer"
                inputProps={{
                  name: 'manufacturer',
                  className: 'flight-purpose',
                }}
                value={form.manufacturer}
                {...handlerProps}
                onChange={(_, component: any) => {
                  setFieldValue('manufacturer', component.props.value);
                  setFieldValue('model', '');
                }}
              >
                {uavDefaults?.manufacturers
                  .sort((a: { name: string }, b: { name: string }) =>
                    a.name.localeCompare(b.name)
                  )
                  .map(({ name }: { name: string }) => (
                    <MenuItem
                      key={name}
                      value={name}
                      data-testid="pilot-web:uav-detail:brand-select-item"
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText className="input-hint">
                {fieldError('manufacturer')}
              </FormHelperText>
            </FormControl>

            {form.manufacturer && (
              <FormControl className="form-control" error={isErrored('model')}>
                <InputLabel className="input-label" htmlFor="model">
                  Model
                </InputLabel>
                <Select
                  data-testid="pilot-web:uav-detail:model-select"
                  data-value={form.model}
                  disabled={isEditing}
                  type="text"
                  placeholder="Add a Model"
                  name="model"
                  inputProps={{
                    name: 'model',
                    className: 'flight-purpose',
                  }}
                  value={form.model}
                  {...handlerProps}
                  onChange={(_, component: any) => {
                    setFieldValue('model', component.props.value);
                  }}
                >
                  {uavDefaults?.manufacturers
                    .find(
                      (manufacturer: { name: string }) =>
                        manufacturer.name === form.manufacturer
                    )
                    ?.models?.sort((a: { name: string }, b: { name: string }) =>
                      a.name.localeCompare(b.name)
                    )
                    ?.map(({ name }: { name: string }) => (
                      <MenuItem
                        key={name}
                        value={name}
                        data-testid="pilot-web:uav-detail:model-select-item"
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText className="input-hint">
                  {fieldError('model')}
                </FormHelperText>
              </FormControl>
            )}

            <FormControl className="form-control" error={isErrored('weight')}>
              <InputLabel className="input-label" htmlFor="weight">
                Weight Category
              </InputLabel>
              <Select
                data-testid="pilot-web:uav-detail:weight-select"
                disabled={isEditing && !isUavOwner()}
                data-value={form.weight}
                type="text"
                placeholder="Select"
                name="weight"
                inputProps={{
                  name: 'weight',
                  className: 'flight-purpose',
                }}
                value={form.weight}
                {...handlerProps}
                onChange={(_, component: any) => {
                  setFieldValue('weight', component.props.value);
                }}
              >
                {uavDefaults?.weights?.map(({ name }: { name: string }) => (
                  <MenuItem
                    key={name}
                    value={name}
                    data-testid="pilot-web:uav-detail:weight-select-item"
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className="input-hint">
                {fieldError('weight')}
              </FormHelperText>
            </FormControl>

            {isEuroRegulation && (
              <div className="input-with-tooltip-wrapper">
                <FormControl
                  className="form-control"
                  error={isErrored('cClass')}
                  style={{ flex: 1 }}
                >
                  <InputLabel className="input-label" htmlFor="manufacturer">
                    C-Class
                  </InputLabel>
                  <Select
                    data-testid="pilot-web:uav-detail:uavcclass-select"
                    data-value={form.cClass}
                    type="text"
                    placeholder="Select a C-Class"
                    name="cClass"
                    inputProps={{
                      name: 'cClass',
                      className: 'flight-purpose',
                    }}
                    value={form.cClass}
                    {...handlerProps}
                    onChange={(_, component: any) => {
                      setFieldValue('cClass', component.props.value);
                    }}
                  >
                    {uavDefaults?.uavCClasses?.map(
                      ({ name }: { name: string }) => (
                        <MenuItem
                          key={name}
                          value={name}
                          data-testid="pilot-web:uav-detail:uavcclass-select-item"
                        >
                          {name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText className="input-hint">
                    {fieldError('cClass')}
                  </FormHelperText>
                </FormControl>
                <InfoTooltip url="https://www.easa.europa.eu/en/domains/civil-drones-rpas/open-category-civil-drones">
                  EASA drone class indentification
                </InfoTooltip>
              </div>
            )}

            <FormControl
              className="form-control"
              error={isErrored('serialNumber')}
            >
              <InputLabel
                className="input-label"
                htmlFor="serialNumber"
                data-testid="pilot-web:uav-detail:serial-number-label"
              >
                {getSerialNumberLabel()}
              </InputLabel>
              <Input
                disabled={isEditing}
                fullWidth
                name="serialNumber"
                {...handlerProps}
                value={form.serialNumber}
                inputProps={{
                  'data-testid': 'pilot-web:uav-detail:serial-input',
                }}
              />
              <FormHelperText className="input-hint">
                {fieldError('serialNumber')}
              </FormHelperText>
            </FormControl>

            {isEuroRegulation && (
              <FormControl
                className="form-control"
                error={isErrored('remoteId')}
              >
                <InputLabel
                  className="input-label"
                  htmlFor="remoteId"
                  data-testid="pilot-web:uav-detail:remoteid-number-label"
                >
                  {getRemoteIdLabel()}
                </InputLabel>
                <Input
                  fullWidth
                  name="remoteId"
                  {...handlerProps}
                  value={form.remoteId}
                  inputProps={{
                    'data-testid': 'pilot-web:uav-detail:remoteid-input',
                  }}
                />
                <FormHelperText className="input-hint">
                  {fieldError('remoteId')}
                </FormHelperText>
              </FormControl>
            )}

            {isEuroRegulation && (
              <FormControl
                className="form-control"
                error={isErrored('enduranceMinutes')}
              >
                <InputLabel
                  className="input-label"
                  htmlFor="enduranceMinutes"
                  data-testid="pilot-web:uav-detail:enduranceminutes-number-label"
                >
                  Endurance (minutes)
                </InputLabel>
                <Input
                  fullWidth
                  name="enduranceMinutes"
                  {...handlerProps}
                  value={form.enduranceMinutes}
                  inputProps={{
                    'data-testid':
                      'pilot-web:uav-detail:enduranceminutes-input',
                  }}
                />
                <FormHelperText className="input-hint">
                  {fieldError('enduranceMinutes')}
                </FormHelperText>
              </FormControl>
            )}

            <FormControl
              className="form-control"
              error={isErrored('defaultProcedureToMeasureAltitude')}
            >
              <InputLabel
                className="input-label"
                htmlFor="defaultProcedureToMeasureAltitude"
                data-testid="pilot-web:uav-detail:default-procedure-to-measure-altitude-label"
              >
                Default procedure to measure altitude
              </InputLabel>
              <Input
                disabled={isEditing && !isUavOwner()}
                fullWidth
                name="defaultProcedureToMeasureAltitude"
                {...handlerProps}
                value={form.defaultProcedureToMeasureAltitude || ''}
                inputProps={{
                  'data-testid':
                    'pilot-web:uav-detail:default-procedure-to-measure-altitude-input',
                }}
              />
              <FormHelperText className="input-hint">
                {fieldError('defaultProcedureToMeasureAltitude')}
              </FormHelperText>
            </FormControl>

            <FormControl
              className="form-control"
              error={isErrored('defaultEmergencyProcedure')}
            >
              <InputLabel
                className="input-label"
                htmlFor="defaultEmergencyProcedure"
                data-testid="pilot-web:uav-detail:default-emergency-procedure-label"
              >
                Default emergency procedure
              </InputLabel>
              <Input
                fullWidth
                disabled={isEditing && !isUavOwner()}
                name="defaultEmergencyProcedure"
                {...handlerProps}
                value={form.defaultEmergencyProcedure || ''}
                inputProps={{
                  'data-testid':
                    'pilot-web:uav-detail:default-emergency-procedure-input',
                }}
              />
              <FormHelperText className="input-hint">
                {fieldError('defaultEmergencyProcedure')}
              </FormHelperText>
            </FormControl>

            <FormHelperText className="validation-errors" error>
              {errors.message}
            </FormHelperText>

            {!isEditing && (
              <LoadingButton
                data-testid="pilot-web:uav-detail:save-button"
                isLoading={isCreating}
                className="form-control submit-button"
                type="submit"
              >
                Save
              </LoadingButton>
            )}

            {isEditing && fieldEdited && isUavOwner() && (
              <LoadingButton
                data-testid="pilot-web:uav-detail:save-button"
                isLoading={isUpdating}
                className="form-control submit-button"
                type="submit"
              >
                Save Updates
              </LoadingButton>
            )}

            {isEditing &&
              !fieldEdited &&
              isUavOwner() &&
              deleteButtonTransitions.map(
                (deleteButtonTransitionProps) =>
                  deleteButtonTransitionProps.item && (
                    <animated.div
                      className="delete-uav-button-animation-container"
                      key={deleteButtonTransitionProps.key}
                      style={deleteButtonTransitionProps.props}
                    >
                      <ButtonBase
                        data-testid="pilot-web:uav-detail:delete-button"
                        className="delete-uav-button button secondary"
                        type="button"
                        onClick={handleRequestDeleteUav}
                      >
                        Delete
                      </ButtonBase>
                    </animated.div>
                  )
              )}

            {isEditing &&
              areYouSureButtonTransitions.map(
                (areYouSureButtonTransitionProps) =>
                  areYouSureButtonTransitionProps.item && (
                    <animated.div
                      className="are-you-sure-button-animation-container"
                      key={areYouSureButtonTransitionProps.key}
                      style={areYouSureButtonTransitionProps.props}
                    >
                      <LoadingButton
                        data-testid="pilot-web:uav-detail:confirm-button"
                        isLoading={isDeleting}
                        className="form-control submit-button"
                        type="button"
                        onClick={handleDeleteUav}
                      >
                        Are you sure?
                      </LoadingButton>
                    </animated.div>
                  )
              )}

            {isEditing &&
              cancelButtonTransitions.map(
                (cancelButtonTransitionProps) =>
                  cancelButtonTransitionProps.item &&
                  isRequestingDelete && (
                    <animated.button
                      key={cancelButtonTransitionProps.key}
                      style={cancelButtonTransitionProps.props}
                      className="cancel-delete-button"
                      type="button"
                      onClick={handleCancelDeleteUav}
                    >
                      cancel
                    </animated.button>
                  )
              )}
          </form>
        </div>
      </>
    </LoadingPanel>
  );
}

export const UavDetail = withRouter(UavDetailComponent);
