import React, { useEffect, useRef } from 'react';
import { useAppConfig } from '../../../state/app-config';

import './customer-logo.scss';

export function CustomerLogo(): JSX.Element {
  const ref = useRef<HTMLImageElement>(null);

  const appConfig = useAppConfig();

  useEffect(() => {
    if (ref.current && appConfig) {
      ref.current.src = appConfig.logoSquare;
    }
  }, [appConfig]);

  return (
    <div className="customer-logo-container">
      <img ref={ref} className="customer-logo" />
    </div>
  );
}
