import React, { useEffect, useState, useMemo } from 'react';
import {
  Switch,
  withRouter,
  matchPath,
  RouteComponentProps,
  Route,
} from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { Dictionary } from 'lodash';
import { CircularProgress } from '@material-ui/core';

import { NavigationBar } from '../navigation-bar/navigation-bar';
import { ProtectedRoute } from '../shared/protected-route/protected-route';
import { Profile } from './panels/profile/profile';
import UpdateProfile from './panels/update-profile/update-profile';
import Clearance from './panels/clearance/clearance';
import { FlightPlan } from './panels/flight-plan/flight-plan';
import { FlightRequests } from './panels/flight-requests/flight-requests';
import { Home } from './panels/home/home';
import { Information } from './panels/information/information';
import { Notams } from './panels/notams/notams';
import { Uavs } from './panels/uavs/uavs';
import { AirspaceActivity } from './panels/airspace-activity/airspace-activity';
import { OrgPilots } from './panels/org-pilots/org-pilots';
import { Settings } from './panels/settings/settings';
import {
  useIsInTabletMode,
  useMobileDetection,
} from 'airshare-pilot-web-shared';
import { useTouched } from '../../state/flight-plan/flight-plan-form/hooks';

import { OrgPilotDetail } from '../detail-panel/panels/org-pilot-detail/org-pilot-detail';
import { FlightBriefing } from '../detail-panel/panels/flight-briefing/flight-briefing';
import { NotamDetail } from '../detail-panel/panels/notam-detail/notam-detail';
import { AUTH_PATH } from '../auth-modal/auth-modal';
import { UavDetail } from '../detail-panel/panels/uav-detail/uav-detail';
import { AboutPage } from './panels/about/about-page';
import { TrackedRoute } from '../shared/tracked-route/tracked-route';
import { useNavigateTo } from '../shared/hooks/router.hook';
import { useIsAuthenticated } from '../shared/hooks/is-authenticated.hook';
import {
  PROFILE_PATH,
  PROFILE_UPDATE_PATH,
  FLIGHT_PLAN_PATH,
  FLIGHT_REQUESTS_PATH,
  NOTAMS_PATH,
  UAVS_PATH,
  ORG_PILOTS_PATH,
  SETTINGS_PATH,
  UAV_DETAIL_PATH,
  FLIGHT_BRIEFING_PATH,
  NOTAM_DETAIL_PATH,
  ORG_PILOTS_DETAIL_PATH,
  ORG_PILOTS_DETAIL_EDIT_PATH,
  CLEARANCE_PATH,
  MANAGE_ORGS_PATH,
  EDIT_APP_DEFAULTS_PATH,
  FLIGHT_PLAN_EDIT_PATH,
  MANAGE_PILOT_USERS_PATH,
  EDIT_PILOT_USER_PATH,
  ABOUT_PATH,
  HOME_PATH,
  INFORMATION_PATH,
  DEPRECATED_MAPS_ROUTE,
  AIRSPACE_ACTIVITY_PATH,
} from '../../routes';

import './left-aside.scss';

function LeftAsideComponent({ location }: RouteComponentProps) {
  const isInTabletMode = useIsInTabletMode();
  const navigateTo = useNavigateTo();
  const isMobile = useMobileDetection();
  const isAuthenticated = useIsAuthenticated();
  const [isAdminSettingsRoute, setIsAdminSettingsRoute] = useState(false);
  const isAuth = matchPath(location.pathname, {
    path: AUTH_PATH,
    exact: false,
  });
  const [hasInProgressFlightPlan, setHasInProgressFlightPlan] = useState(false);
  const touchedState: Dictionary<boolean> = useTouched();

  function handleFlyNow() {
    navigateTo(FLIGHT_PLAN_PATH, { referrer: location.pathname });
  }

  useEffect(() => {
    setIsAdminSettingsRoute(
      location.pathname === MANAGE_ORGS_PATH ||
        location.pathname === EDIT_APP_DEFAULTS_PATH ||
        location.pathname === EDIT_PILOT_USER_PATH ||
        location.pathname === MANAGE_PILOT_USERS_PATH
    );
  }, [location.pathname]);

  useMemo(() => {
    // form fields that are automatically set with data
    const fieldsToIgnore = [
      'uavId',
      'certificationType',
      'certificationNumber',
      'pilotEmail',
      'pilotName',
      'pilotId',
      'flightAreaSource',
      'radius',
      'pathMode',
      'waypoints',
      'segmentCollection',
    ];
    let anythingTouched = false;

    for (const [key, value] of Object.entries(touchedState)) {
      if (fieldsToIgnore.includes(key)) continue;
      anythingTouched ||= Boolean(value);
    }
    setHasInProgressFlightPlan(anythingTouched);
  }, [touchedState]);

  if (isMobile && isAuth) return null;

  return (
    <aside className={isMobile ? 'left-aside-mobile' : 'left-aside'}>
      {!isMobile &&
        !location.pathname.includes(FLIGHT_PLAN_PATH) &&
        !isAdminSettingsRoute && (
          <button
            data-testid="pilot-web:fly-now-button"
            className="fly-now-button"
            type="button"
            onClick={handleFlyNow}
          >
            <i className="icon fly-now" />
            {hasInProgressFlightPlan && isAuthenticated ? (
              <CircularProgress
                variant="determinate"
                value={65}
                size={74}
                thickness={3}
                color="inherit"
                className="icon flight-plan-in-progress"
                data-testid="pilot-web:fly-now-button:flight-plan-in-progress"
              />
            ) : null}
          </button>
        )}
      {!isMobile && <NavigationBar />}

      <Div100vh className="content-panel">
        <Switch>
          <TrackedRoute path={HOME_PATH} component={Home} />
          <TrackedRoute path={INFORMATION_PATH} component={Information} />
          <TrackedRoute
            path={NOTAMS_PATH}
            exact={isInTabletMode}
            component={Notams}
          />
          <TrackedRoute
            path={FLIGHT_BRIEFING_PATH}
            component={FlightBriefing}
          />
          <ProtectedRoute
            path={FLIGHT_REQUESTS_PATH}
            exact={isInTabletMode}
            component={FlightRequests}
            /* 
              // @ts-ignore */
            tracked
          />
          <ProtectedRoute
            path={UAVS_PATH}
            exact={isInTabletMode}
            component={Uavs}
          />

          <ProtectedRoute
            path={AIRSPACE_ACTIVITY_PATH}
            exact={isInTabletMode}
            component={AirspaceActivity}
          />

          <ProtectedRoute path={PROFILE_PATH} component={Profile} exact />
          <ProtectedRoute
            path={PROFILE_UPDATE_PATH}
            component={UpdateProfile}
          />
          <ProtectedRoute
            path={FLIGHT_PLAN_PATH}
            component={FlightPlan}
            exact
          />
          <ProtectedRoute path={FLIGHT_PLAN_EDIT_PATH} component={FlightPlan} />
          <TrackedRoute path={SETTINGS_PATH} component={Settings} />
          <ProtectedRoute path={CLEARANCE_PATH} component={Clearance} />
          <ProtectedRoute
            path={ORG_PILOTS_PATH}
            exact={isInTabletMode}
            component={OrgPilots}
          />
          <ProtectedRoute path={ABOUT_PATH} component={AboutPage} />
          {isInTabletMode && (
            <TrackedRoute path={NOTAM_DETAIL_PATH} component={NotamDetail} />
          )}
          {isInTabletMode && (
            <TrackedRoute path={UAV_DETAIL_PATH} component={UavDetail} />
          )}
          {isInTabletMode && (
            <TrackedRoute
              path={ORG_PILOTS_DETAIL_EDIT_PATH}
              render={() => (
                <OrgPilotDetail key={ORG_PILOTS_DETAIL_EDIT_PATH} />
              )}
            />
          )}
          {isInTabletMode && (
            <TrackedRoute
              path={ORG_PILOTS_DETAIL_PATH}
              render={() => <OrgPilotDetail key={ORG_PILOTS_DETAIL_PATH} />}
            />
          )}
          {/* redirect deprecated /maps route to home */}
          <TrackedRoute
            path={DEPRECATED_MAPS_ROUTE}
            exact={true}
            component={Home}
          />

          {/* Superadmin management routes show the Settings panel without triggering a rerender */}
          <TrackedRoute path={MANAGE_ORGS_PATH} component={Settings} />
          <TrackedRoute path={EDIT_APP_DEFAULTS_PATH} component={Settings} />
          <TrackedRoute path={EDIT_PILOT_USER_PATH} component={Settings} />
          <TrackedRoute path={MANAGE_PILOT_USERS_PATH} component={Settings} />
          {/* redirect any unknown route to flight requests via home when authenticated */}
          {isAuthenticated && <Route component={Home} />}

          {/* passively show information panel (without a redirect so that login flow still works) if not authenticated and unknown route  */}
          {!isAuthenticated && <Route component={Information} />}
        </Switch>
      </Div100vh>
    </aside>
  );
}

export const LeftAside = withRouter(LeftAsideComponent);
