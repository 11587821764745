import { matchPath } from 'react-router';

export const AUTH_PATH = '/*/(signin|signup)/*';

export const ACTIVATION_PATH = '/*/signin/activate';
export const LOGIN_PATH = '/*/signin';
export const ACCEPT_POLICIES_PATH = '/*/signin/policies';
export const RESET_PASSWORD_PATH = '/*/signin/reset-password';
export const REGISTRATION_PATH = '/*/signup';

export const DETAIL_PANEL_PATH = '/(uav|org-pilots|org-pilots/edit|notam)/:id';

export const FLIGHT_BRIEFING_PATH = '/flight/:flightId';
export const getFlightBriefingPath = (flightId: string) =>
  FLIGHT_BRIEFING_PATH.replace(':flightId', flightId);
export const CLEARANCE_PATH = '/clearance/:flightId';
export const getClearancePath = (flightId: string) =>
  CLEARANCE_PATH.replace(':flightId', flightId);
export const NOTAM_DETAIL_ROUTE = '/notam/:id';
export const NOTAM_DETAIL_PATH =
  '/notam/:id/(signin|signup)?/(activate|reset-password)?';
export const getNotamDetailPath = (id: string) =>
  NOTAM_DETAIL_ROUTE.replace(':id', id);

export const ADD_UAV_PATH = '/uav/add';
export const UAV_DETAIL_PATH = '/uav/:id';
export const getUavDetailPath = (id: string) =>
  UAV_DETAIL_PATH.replace(':id', id);
const ORG_ADMIN_PATH = '/org-pilots';
export const ORG_PILOTS_DETAIL_EDIT_PATH = `${ORG_ADMIN_PATH}/edit/:id`;
export const getOrgPilotDetailEditPath = (id: string) =>
  ORG_PILOTS_DETAIL_EDIT_PATH.replace(':id', id);
export const ORG_PILOTS_DETAIL_PATH = `${ORG_ADMIN_PATH}/:id`;
export const getOrgPilotDetailPath = (id: string) =>
  ORG_PILOTS_DETAIL_PATH.replace(':id', id);
export const getFlightPlanEditPath = (id: string) =>
  FLIGHT_PLAN_EDIT_PATH.replace(':id', id);

export const MANAGE_ORGS_PATH = '/manage-organisations';
export const EDIT_APP_DEFAULTS_PATH = '/edit-app-defaults';
export const MANAGE_PILOT_USERS_PATH = '/manage-pilot-users';
export const EDIT_PILOT_USER_PATH = `${MANAGE_PILOT_USERS_PATH}/:id`;
export const getEditPilotUserPath = (id: string) =>
  EDIT_PILOT_USER_PATH.replace(':id', id);

export const NOTAMS_ROUTE = '/notam';

export const FLIGHT_PLAN_EDIT_PATH = '/flight-plan/:id';
export const FLIGHT_PLAN_PATH = '/flight-plan';
export const FLIGHT_REQUESTS_PATH = '/flight';
export const PROFILE_PATH = '/profile';
export const PROFILE_UPDATE_PATH = '/profile/update';
export const NOTAMS_PATH = '/notam/(signin|signup)?/(activate|reset-password)?';
export const SETTINGS_PATH = '/settings';
export const INFORMATION_PATH = '/info';
export const UAVS_PATH = '/uav';
export const ORG_PILOTS_PATH = '/org-pilots';
export const ABOUT_PATH = '/about';
export const HOME_PATH = '/home';
export const AIRSPACE_ACTIVITY_PATH = '/airspace-activity';

export const DEPRECATED_MAPS_ROUTE = '/maps';

export const DEFAULT_ROUTE = HOME_PATH;

export const ADVISORY_MOVABLE_PIN_PATHS = [
  ABOUT_PATH,
  AUTH_PATH,
  LOGIN_PATH,
  ACTIVATION_PATH,
  FLIGHT_REQUESTS_PATH,
  NOTAMS_PATH,
  NOTAMS_ROUTE,
  INFORMATION_PATH,
  RESET_PASSWORD_PATH,
  HOME_PATH,
  PROFILE_PATH,
  REGISTRATION_PATH,
  UAVS_PATH,
  ORG_PILOTS_PATH,
  SETTINGS_PATH,
];

export const ADVISORY_MODAL_PATHS = ADVISORY_MOVABLE_PIN_PATHS.concat([
  FLIGHT_BRIEFING_PATH,
  FLIGHT_PLAN_PATH,
  FLIGHT_PLAN_EDIT_PATH,
  CLEARANCE_PATH,
]);

export const advisoryPinPath = (pathname: string) =>
  matchPath(pathname, { path: ADVISORY_MOVABLE_PIN_PATHS, exact: true })
    ?.isExact || false;
