import { useEffect, useState } from 'react';
import { useWindowSize } from './window-size.hook';

const MAX_TABLET_WINDOW_WIDTH = 1350;

export function useIsInTabletMode() {
  const windowSize = useWindowSize();

  const [isInTabletMode, updateIsInTabletMode] = useState(
    windowSize.width < MAX_TABLET_WINDOW_WIDTH
  );

  useEffect(() => {
    if (windowSize.width < MAX_TABLET_WINDOW_WIDTH) {
      updateIsInTabletMode(true);
    } else {
      updateIsInTabletMode(false);
    }
  }, [windowSize.width]);

  return isInTabletMode;
}
