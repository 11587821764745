import {
  useCurrentFlightRequests,
  usePastFlightRequests,
} from '~/state/flight-requests/hooks';

export function useHasNoFlights() {
  const currentFlightRequests = useCurrentFlightRequests();
  const pastFlightRequests = usePastFlightRequests();

  return Boolean(
    currentFlightRequests &&
      currentFlightRequests.length === 0 &&
      pastFlightRequests &&
      pastFlightRequests.length === 0
  );
}

export function useHasPastFlights() {
  const pastFlightRequests = usePastFlightRequests();

  return Boolean(pastFlightRequests && pastFlightRequests.length > 0);
}
