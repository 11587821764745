import { useState, useEffect } from 'react';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

interface ChangePayload {
  coords: { latitude: number; longitude: number; accuracy: number };
  timestamp: number;
}

interface Position {
  latitude: number;
  longitude: number;
  accuracy: number;
  timestamp: number;
}

interface ReturnValue extends Position {
  error: GeolocationPositionError;
}

export const usePosition = (
  watch = false,
  settings = defaultSettings
): ReturnValue => {
  const [position, setPosition] = useState<Position>({
    longitude: null,
    latitude: null,
    accuracy: null,
    timestamp: null,
  });
  const [error, setError] = useState(null);

  const onChange = ({ coords, timestamp }: ChangePayload) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp,
    });
  };

  const onError: PositionErrorCallback = (e) => {
    setError(e.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported');
      return null;
    }

    let watcher: number = null;
    if (watch) {
      watcher = geo.watchPosition(onChange, onError, settings);
    } else {
      geo.getCurrentPosition(onChange, onError, settings);
    }

    return () => watcher && geo.clearWatch(watcher);
  }, [settings, watch]);

  return { ...position, error };
};
