import { createReducer } from 'airshare-web-utils/redux-helpers';

import { FETCH_SUCCEEDED } from './actions';

export const flightRequestDefaultsReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (_: any, { payload }: { payload: any }) => payload,
  },
  {}
);
