import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Polygon } from '@turf/helpers';
import get from 'lodash/get';
import {
  getFlightAreaPreValidation,
  getFlightAreaPreValidationStatus,
} from './selectors';
import {
  preValidateFlightAreaRequested,
  preValidateFlightAreaReset,
  preValidateFlightAreaStatusReset,
} from './actions';

interface WithManagedAreaId {
  managedAreaId?: string | null;
}
export type ProcessedPayload = Polygon & WithManagedAreaId;

export function usePreValidateFlightArea() {
  const dispatch = useDispatch();

  const preValidateFlightArea = useCallback(
    (payload: ProcessedPayload) => {
      dispatch(preValidateFlightAreaRequested(payload));
    },
    [dispatch]
  );

  const validationResult = useSelector(getFlightAreaPreValidation);

  return {
    validationResult,
    preValidateFlightArea,
  };
}

export function useResetPreFlightValidateFlightArea() {
  const dispatch = useDispatch();

  return () => dispatch(preValidateFlightAreaReset());
}

export function usePreFlightValidateFlightAreaStatus() {
  const status = useSelector(getFlightAreaPreValidationStatus);

  return status;
}

export function useResetPreFlightValidateFlightAreaStatus() {
  const dispatch = useDispatch();

  return () => dispatch(preValidateFlightAreaStatusReset());
}

export function useIsIntersectingControlZone() {
  const validationResult = useSelector(getFlightAreaPreValidation);

  const controlZoneIntersection = get(
    validationResult,
    'controlZoneIntersection[0]',
    null
  );

  return Boolean(controlZoneIntersection);
}
