import React from 'react';
import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { DateTime } from 'luxon';
import type { Feature, Polygon } from 'geojson';

import {
  OverlappingType,
  type StyledFlightSegmentPropertiesJSONView,
} from '@airshare/external-api-types';

import { ExpandButton } from '../expand-button/expand-button';
import { type LocalOverlapDetail } from '../../map-screen/hooks/use-overlap-summary';

import './segment-detail-accordion.scss';
import {
  getSegmentClearanceStatusLabel,
  getSegmentStatusLabel,
  getSegmentTitle,
  getSegmentTitleColor,
} from './segment-helpers';

export const SegmentDetailAccordion = ({
  segment,
  segmentOverlapDetail,
  timezone,
  toggleHighlightSegment,
  isExpanded,
}: {
  segment: Feature<Polygon, StyledFlightSegmentPropertiesJSONView> | undefined;
  segmentOverlapDetail: LocalOverlapDetail[] | undefined;
  timezone: string;
  toggleHighlightSegment: (segmentId: string) => void;
  isExpanded: boolean;
}): JSX.Element | null => {
  let warningIconClassName = '';
  const overlapCountTexts: string[] = [];

  if (
    segmentOverlapDetail?.find(
      (detail) => detail.overallOverlap === OverlappingType.NEARBY
    )
  ) {
    warningIconClassName = 'segment-title-warningicon-nearby';
    overlapCountTexts.push(
      `${
        segmentOverlapDetail?.filter(
          (detail) => detail.overallOverlap === OverlappingType.NEARBY
        )?.length || 0
      } nearby`
    );
  }

  if (
    segmentOverlapDetail?.find(
      (detail) => detail.overallOverlap === OverlappingType.OVERLAPPING
    )
  ) {
    warningIconClassName = 'segment-title-warningicon-overlapping';
    overlapCountTexts.push(
      `${
        segmentOverlapDetail?.filter(
          (detail) => detail.overallOverlap === OverlappingType.OVERLAPPING
        )?.length || 0
      } overlapping`
    );
  }

  const overlapDetailText = overlapCountTexts.toReversed().join(', ');

  return segment ? (
    <div
      className="segments-detail-accordion"
      data-testid={`segments-detail-accordian-${segment?.properties?.id}`}
    >
      <div className="segments-collapsible-subsection-heading">
        <Typography
          variant="subtitle1"
          onClick={() => toggleHighlightSegment(segment.properties.id)}
          style={
            getSegmentTitleColor(segment)
              ? { color: getSegmentTitleColor(segment) }
              : {}
          }
        >
          {getSegmentTitle(segment, timezone)}
        </Typography>
        <div className="segment-title-inner-padding" />
        {warningIconClassName && (
          <WarningIcon
            className={warningIconClassName}
            data-testid="pilot-web:flight-briefing-panel:segments-section:segment:warning-icon"
          />
        )}
        <ExpandButton
          dataTestid="pilot-web:flight-briefing-panel:segments-section:segment"
          className="segment-title-expand-button"
          isExpanded={isExpanded}
          onClick={() => toggleHighlightSegment(segment.properties.id)}
        />
      </div>
      <Collapse in={isExpanded} className="segments">
        {getSegmentDetails(segment, timezone, overlapDetailText)}
      </Collapse>
    </div>
  ) : null;
};

const SegmentDetailRow = ({
  label,
  displayValue,
  testId,
}: {
  label: string;
  displayValue: string;
  testId: string;
}): JSX.Element => {
  return (
    <li className="segment-detail-row" data-testid={`segment-list:${testId}`}>
      <Typography variant="body2" className="segment-detail-label">
        {label}
      </Typography>
      <Typography variant="body2" className="segment-detail-value">
        {displayValue}
      </Typography>
    </li>
  );
};

const getSegmentDetails = (
  segment: Feature<Polygon, StyledFlightSegmentPropertiesJSONView>,
  timezone: string,
  overlapDetailText: string | undefined
): JSX.Element => {
  const {
    startDateTime,
    endDateTime,
    launchElevationFeet,
    minAltitudeFeet,
    maxAltitudeFeet,
    segmentStatus,
    segmentClearanceStatus,
  } = segment.properties;

  return (
    <ul className="segment-details-list">
      {overlapDetailText && (
        <SegmentDetailRow
          label="Nearby flights:"
          testId="overlapDetailText"
          displayValue={overlapDetailText}
        />
      )}
      {segmentStatus && (
        <SegmentDetailRow
          label="Status:"
          testId="segmentStatus"
          displayValue={getSegmentStatusLabel(segmentStatus)}
        />
      )}
      {segmentClearanceStatus && (
        <SegmentDetailRow
          label="Clearance:"
          testId="segmentClearanceStatus"
          displayValue={getSegmentClearanceStatusLabel(segmentClearanceStatus)}
        />
      )}
      {startDateTime && (
        <SegmentDetailRow
          label="Starts At:"
          testId="startDateTime"
          displayValue={DateTime.fromISO(startDateTime)
            .setZone(timezone)
            .toFormat('HH:mm ZZZZ')}
        />
      )}
      {endDateTime && (
        <SegmentDetailRow
          label="Ends At:"
          testId="endDateTime"
          displayValue={DateTime.fromISO(endDateTime)
            .setZone(timezone)
            .toFormat('HH:mm ZZZZ')}
        />
      )}
      {(launchElevationFeet > 0 || launchElevationFeet === 0) && (
        <SegmentDetailRow
          label="Launch Elev:"
          testId="launchElevationFeet"
          displayValue={`${launchElevationFeet}ft AMSL`}
        />
      )}
      {(minAltitudeFeet > 0 || minAltitudeFeet === 0) && (
        <SegmentDetailRow
          label="Lower Alt:"
          testId="minAltitudeFeet"
          displayValue={`${minAltitudeFeet}ft AGL`}
        />
      )}
      {(maxAltitudeFeet > 0 || maxAltitudeFeet === 0) && (
        <SegmentDetailRow
          label="Upper Alt:"
          testId="maxAltitudeFeet"
          displayValue={`${maxAltitudeFeet}ft AGL`}
        />
      )}
    </ul>
  );
};
