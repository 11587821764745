/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import PhoneNumberInput from 'material-ui-phone-number';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import {
  LoadingButton,
  COMPLETION_DELAY,
  useTimeoutEffect,
  PasswordStrength,
} from 'airshare-pilot-web-shared';
import { Recaptcha } from 'common-ui-components';

import { RegistrationStatus } from '~/state/session/constants';
import {
  useFormState,
  useErrors,
  useInputHandlerProps,
  useSetFieldValue,
  useIsErrored,
  useFieldError,
  useHandleSubmit,
  useClearForm,
  useResetErrors,
} from '~/state/session/auth-forms/registration/hooks';
import { useSetFieldValue as useSetActivationFieldValue } from '~/state/session/auth-forms/activation/hooks';
import { useRegister } from './registration.hook';

import './registration.scss';
import { OperatorType } from '@airshare/external-api-types';

import { parsePhoneNumber } from 'libphonenumber-js';
import { MobileRegex } from '@airshare/pilot-types';

interface Props extends RouteComponentProps {
  referrer: string;
  redirectUrl: string;
}

function RegistrationFormComponent({ referrer, redirectUrl, history }: Props) {
  const [registrationStatus, attemptRegister, resetRestrationStatus] =
    useRegister();
  const form = useFormState();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const fieldError = useFieldError();
  const setFieldValue = useSetFieldValue();
  const handlerProps = useInputHandlerProps();
  const clearForm = useClearForm();
  const resetErrors = useResetErrors();
  const [isPasswordValid, onPasswordValidityChange] = useState(false);
  const [passwordSuggestion, onPasswordSuggestionChange] = useState('');
  const [isCommercialOperator, setIsCommercialOperator] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const setActivationFormField = useSetActivationFieldValue();
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true' || false;

  const recaptchaRef = useRef<any>(null);

  useTimeoutEffect(
    () => {
      resetRestrationStatus();
      setActivationFormField('email', form.email);
      history.push(`${referrer}/signin/activate`, { referrer, redirectUrl });
    },
    COMPLETION_DELAY,
    registrationStatus === RegistrationStatus.SUCCESS
  );

  useEffect(() => {
    clearForm;
    resetErrors();
    handlePasswordChange({ password: '', isValid: false }, null);
  }, []);

  // Handlers
  const handleSubmit = useHandleSubmit(() => {
    if (
      registrationStatus !==
        RegistrationStatus.REGISTRATION_ATTEMPT_IN_PROGRESS &&
      recaptchaToken &&
      isPasswordValid
    ) {
      attemptRegister({ ...form, recaptchaToken });
    }
  });

  useEffect(() => {
    // Reset the recaptcha on login failure
    if (
      registrationStatus === RegistrationStatus.FAILED &&
      recaptchaRef.current
    ) {
      recaptchaRef.current.reset();
    }
  }, [errors]);

  const handlePasswordChange = (
    result: { password: string; isValid: boolean },
    zxcvbnResult: { feedback: { warning: any } }
  ) => {
    setFieldValue('password', result.password);
    onPasswordValidityChange(result.isValid);
    if (zxcvbnResult?.feedback?.warning) {
      onPasswordSuggestionChange(zxcvbnResult.feedback.warning);
    } else onPasswordSuggestionChange('');
  };
  const handleMobileNumberChange = (value: string) => {
    try {
      if (!MobileRegex.test(value)) {
        setFieldValue('mobileNo', value);
      } else {
        const formattedNumber = parsePhoneNumber(value);
        const validNumber = `+${formattedNumber.countryCallingCode} ${formattedNumber.nationalNumber}`;
        setFieldValue('mobileNo', validNumber);
      }
    } catch (error) {
      setFieldValue('mobileNo', '+');
      console.error('Invalid mobile number');
    }
  };

  const commercialOperatorsSpring = useSpring({
    height: isCommercialOperator ? 150 : 0,
  });

  const isLoading =
    registrationStatus === RegistrationStatus.REGISTRATION_ATTEMPT_IN_PROGRESS;

  return (
    <form
      onSubmit={handleSubmit}
      className="signup-form"
      data-testid="pilot-web:signup-form"
    >
      <FormControl className="form-control" error={isErrored('fullName')}>
        <InputLabel className="input-label" htmlFor="fullName">
          Name
        </InputLabel>
        <Input
          name="fullName"
          {...handlerProps}
          value={form.fullName}
          inputProps={{
            'data-testid': 'pilot-web:signup-form:name-input',
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('fullName')}
        </FormHelperText>
      </FormControl>

      <FormControl className="form-control" error={isErrored('email')}>
        <InputLabel className="input-label" htmlFor="email">
          Email
        </InputLabel>
        <Input
          name="email"
          {...handlerProps}
          value={form.email}
          inputProps={{
            'data-testid': 'pilot-web:signup-form:email-input',
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('email')}
        </FormHelperText>
      </FormControl>

      <FormControl className="form-control phone" error={isErrored('mobileNo')}>
        {!form.mobileNo && (
          <InputLabel className="input-label phone" htmlFor="mobileNo">
            Phone Number
          </InputLabel>
        )}
        <PhoneNumberInput
          className="phone-input"
          name="mobileNo"
          defaultCountry="nz"
          disableAreaCodes={true}
          onFocus={handlerProps.onFocus}
          onBlur={handlerProps.onBlur}
          onChange={(value: string) => {
            handleMobileNumberChange(value);
          }}
          value={form.mobileNo}
          error={isErrored('mobileNo')}
          inputProps={{
            'data-testid': 'pilot-web:signup-form:phone-input',
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('mobileNo')}
        </FormHelperText>
      </FormControl>

      {isEuroRegulation ? (
        <FormControl
          className="form-control"
          error={isErrored('registrationNo')}
        >
          <InputLabel className="input-label" htmlFor="registrationNo">
            Registration Number
          </InputLabel>
          <Input
            name="registrationNo"
            {...handlerProps}
            value={form.registrationNo}
            inputProps={{
              'data-testid': 'pilot-web:signup-form:registrationNo-input',
            }}
          />
          <FormHelperText className="input-hint">
            {fieldError('registrationNo')}
          </FormHelperText>
        </FormControl>
      ) : null}

      <FormControl
        className="form-control"
        error={isErrored('password') || !isPasswordValid}
      >
        <PasswordStrength
          onPasswordChange={handlePasswordChange}
          style={undefined}
          placeholder="Password"
          label=""
        />
        <FormHelperText className="input-hint">
          {fieldError('password') || passwordSuggestion}
        </FormHelperText>
      </FormControl>
      {/* 
// @ts-ignore */}
      <FormControlLabel
        label={
          <Typography
            variant="subtitle1"
            data-testid="pilot-web:signup-form:commercial-operator-text"
          >
            Are you a commercial operator?
          </Typography>
        }
        labelPlacement="start"
        className="commercial-operator-switch"
        control={
          <Switch
            name="operatorType"
            size="small"
            color="primary"
            checked={isCommercialOperator}
            onChange={(_, value) => {
              setIsCommercialOperator((prevValue) => !prevValue);
              const operatorType = value
                ? OperatorType.COMMERCIAL
                : OperatorType.RECREATIONAL;
              setFieldValue('operatorType', operatorType);
            }}
            data-testid="pilot-web:signup-form:commercial-operator-switch"
          />
        }
      >
        <FormHelperText className="input-hint">
          {fieldError('operatorType')}
        </FormHelperText>
      </FormControlLabel>

      <animated.div
        style={commercialOperatorsSpring}
        className="commercial-operator-fields"
      >
        <FormControl className="form-control" error={isErrored('businessName')}>
          <InputLabel
            className="input-label"
            htmlFor="businessName"
            data-testid="pilot-web:signup-form:business-name"
          >
            Business Name
          </InputLabel>
          <Input
            name="businessName"
            {...handlerProps}
            value={form.businessName}
            data-testid="pilot-web:signup-form:business-name-input"
          />
          <FormHelperText
            className="input-hint"
            data-testid="pilot-web:signup-form:business-name-error"
          >
            {fieldError('businessName')}
          </FormHelperText>
        </FormControl>
        <FormControl
          className="form-control"
          error={isErrored('certificationNumber')}
        >
          <InputLabel
            className="input-label"
            htmlFor="certificationNumber"
            data-testid="pilot-web:signup-form:part-102-certification"
          >
            Part 102 Certificate (Optional)
          </InputLabel>
          <Input
            name="certificationNumber"
            {...handlerProps}
            value={form.certificationNumber}
            data-testid="pilot-web:signup-form:part-102-certification-input"
          />
          <FormHelperText
            className="input-hint"
            data-testid="pilot-web:signup-form:part-102-certification-error"
          >
            {fieldError('certificationNumber')}
          </FormHelperText>
        </FormControl>
      </animated.div>

      <FormHelperText error>{errors.message}</FormHelperText>

      <FormControl>
        <Recaptcha ref={recaptchaRef} setRecaptchaToken={setRecaptchaToken} />
      </FormControl>

      <LoadingButton
        isLoading={isLoading}
        variant="secondary"
        className="form-control submit-button"
        type="submit"
        data-testid="pilot-web:signup-form:submit-button"
        disabled={!recaptchaToken}
      >
        Create My Account
      </LoadingButton>
    </form>
  );
}

export const RegistrationForm = withRouter(RegistrationFormComponent);
