import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import Typography from '@material-ui/core/Typography';

import { WeatherForecast } from '@airshare/external-api-types';

import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { getWeatherForecast } from '../../../../pilot-api-client';
import { WeatherIcon } from '../../../shared/weather-icon/weather-icon';
import { useInterval } from 'airshare-pilot-web-shared';

const WEATHER_REQUEST_REFRESH_INTERVAL = 3000;

export default function WeatherBriefing({
  flight,
}: {
  flight?: ExtendedFlightResponseBodyV2;
}) {
  const [weatherForecast, setWeatherForecast] = useState<WeatherForecast>();
  const canShowWeather = useRef(true);

  async function loadWeather() {
    if (flight) {
      const {
        coordinates,
        startDateTime,
        maxAltitudeFeet,
        launchElevationFeet,
        timezone,
      } = flight.properties;

      const weatherPayload = flight
        ? {
            lat: coordinates[0].Lat,
            lng: coordinates[0].Lng,
            dateTime: DateTime.fromISO(startDateTime).toUTC().toString(),
            altitude: maxAltitudeFeet + launchElevationFeet || 0,
            timezone,
          }
        : null;

      try {
        const weatherResponse = await getWeatherForecast(weatherPayload);
        if (canShowWeather.current) {
          setWeatherForecast(weatherResponse);
        }
      } catch (err) {
        if (canShowWeather.current) {
          setWeatherForecast(null);
        }
      }
    }
  }

  useEffect(() => {
    canShowWeather.current = true;
    return () => {
      canShowWeather.current = false;
    };
  }, [flight]);

  useInterval(() => loadWeather(), WEATHER_REQUEST_REFRESH_INTERVAL);

  if (weatherForecast) {
    return (
      <div
        className="card weather"
        data-testid="pilot-web:flight-briefing-panel:weather-section"
      >
        <div className="row">
          <WeatherIcon
            className="weather-icon"
            size="small"
            icon={weatherForecast.icon}
          />
          <Typography variant="body2">
            {weatherForecast.temperature
              ? `${Math.round(weatherForecast.temperature)}˚C`
              : 'Unknown'}
          </Typography>
        </div>
        <div className="row">
          <Typography variant="body2">Wind</Typography>
          <div className="wind-stats">
            <Typography
              variant="body2"
              className="wind-direction"
              data-wind-direction={weatherForecast.windDirection}
            >
              {weatherForecast.windDirection
                ? weatherForecast.windDirection
                : 'Unknown'}{' '}
              {weatherForecast.windSpeed
                ? `${Math.round(weatherForecast.windSpeed)}km/h`
                : 'Unknown'}
            </Typography>
            <Typography variant="body2">
              {weatherForecast.windGust
                ? `Gust ${Math.round(weatherForecast.windGust)}km/h`
                : 'Gust Unknown'}
            </Typography>
          </div>
        </div>
        <div className="row">
          <Typography variant="body2">Visibility</Typography>
          <Typography variant="body2">
            {weatherForecast.visibility
              ? `${Math.round(weatherForecast.visibility)} km`
              : 'Unknown'}
          </Typography>
        </div>
        <div className="row">
          <Typography variant="body2">Rain</Typography>
          <Typography variant="body2">
            {typeof weatherForecast.rainProbability === 'number'
              ? `${Math.round(weatherForecast.rainProbability)}%`
              : 'Unknown'}
          </Typography>
        </div>
        {weatherForecast.location && (
          <div className="row">
            <Typography variant="body2">Location</Typography>
            <Typography variant="body2">
              lat {weatherForecast.location.lat}, ln{' '}
              {weatherForecast.location.lng}
            </Typography>
          </div>
        )}
        {weatherForecast.dateTime && (
          <div className="row">
            <Typography variant="body2">Time Observation</Typography>
            <Typography variant="body2">{weatherForecast.dateTime}</Typography>
          </div>
        )}
        {typeof weatherForecast.cloudCover === 'number' && (
          <div className="row">
            <Typography variant="body2">Lowest Cloud Height </Typography>
            <Typography variant="body2">
              {`${Math.round(weatherForecast.cloudCover)}ft`}
            </Typography>
          </div>
        )}
        {weatherForecast.dewPoint && (
          <div className="row">
            <Typography variant="body2">Dew Point</Typography>
            <Typography variant="body2">
              {`${Math.round(weatherForecast.dewPoint)}˚C`}
            </Typography>
          </div>
        )}
        {weatherForecast.pressure && (
          <div className="row">
            <Typography variant="body2">QNH</Typography>
            <Typography variant="body2">
              {`${Math.round(weatherForecast.pressure)} hPa`}
            </Typography>
          </div>
        )}
        {weatherForecast.source && (
          <div className="row">
            <Typography variant="body2">Source</Typography>
            <Typography variant="body2">
              {weatherForecast.source || ''}
            </Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="card weather placeholder"
      data-testid="pilot-web:flight-briefing-panel:unknown-weather"
    >
      <div className="row">
        <WeatherIcon className="weather-icon" size="small" icon="unknown" />
        <div className="line" />
      </div>
      <div className="line" />
      <div className="line" />
    </div>
  );
}
