/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import ReactInputVerificationCode from 'react-input-verification-code';
import {
  LoadingButton,
  COMPLETION_DELAY,
  useTimeoutEffect,
} from 'airshare-pilot-web-shared';

import { Loading } from '../../../shared/loading/loading';
import {
  useFormState,
  useIsErrored,
  useFieldError,
  useErrors,
  useHandleSubmit,
  useSetFieldValue,
  useClearForm,
  useResetErrors,
} from '~/state/session/auth-forms/activation/hooks';
import {
  ActivationStatus,
  ResendActivationEmailStatus,
} from '~/state/session/constants';
import { useIsAccountActive } from '~/state/session/hooks';
import { useActivation, useResendActivationEmail } from './activation.hook';

import './activation.scss';

interface Props extends RouteComponentProps {
  referrer: string;
  redirectUrl: string;
}

function ActivationFormComponent({ referrer, redirectUrl, history }: Props) {
  const emailRef = useRef<HTMLInputElement>();

  // State and Actions
  const form = useFormState();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const fieldError = useFieldError();
  const setFieldValue = useSetFieldValue();
  const clearForm = useClearForm();
  const resetErrors = useResetErrors();

  const [activationStatus, attemptActivation, resetActivationStatus] =
    useActivation();
  const [
    resendActivationEmailStatus,
    resendActivationEmail,
    resetResendActionEmailStatus,
  ] = useResendActivationEmail(form.email);
  const [, setIsAccountActive] = useIsAccountActive();
  const [isResendEmailInProgress, setIsResendEmailInProgress] = useState(false);

  // Effects
  useTimeoutEffect(
    () => {
      resetActivationStatus();
      history.push(`${referrer}/signin`, { referrer, redirectUrl });
    },
    COMPLETION_DELAY,
    activationStatus === ActivationStatus.SUCCESS
  );

  useTimeoutEffect(
    () => {
      resetResendActionEmailStatus();
    },
    COMPLETION_DELAY,
    resendActivationEmailStatus === ResendActivationEmailStatus.SUCCESS
  );

  useEffect(() => {
    if (!form.email) {
      history.push(`${referrer}/signin`, { referrer, redirectUrl });
    }
  }, []);

  useEffect(
    () => () => {
      clearForm();
      resetErrors();
    },
    []
  );

  useEffect(() => {
    if (form.activationKey && emailRef.current) {
      emailRef.current.focus();
    }
  }, [form.activationKey]);

  useEffect(() => {
    if (
      resendActivationEmailStatus ===
      ResendActivationEmailStatus.RESEND_ACTIVATION_ATTEMPT_IN_PROGRESS
    ) {
      return;
    }

    if (resendActivationEmailStatus === ResendActivationEmailStatus.SUCCESS) {
      setTimeout(() => {
        setIsResendEmailInProgress(false);
      }, 1000);
    } else {
      setIsResendEmailInProgress(false);
    }
  }, [resendActivationEmailStatus]);

  // Handlers
  const handleSubmit = useHandleSubmit(() => {
    if (activationStatus !== ActivationStatus.ACTIVATION_ATTEMPT_IN_PROGRESS) {
      attemptActivation(form);
    }
  });

  const handleResendCode = () => {
    resendActivationEmail();
    setIsResendEmailInProgress(true);
  };

  function handleVerificationCodeEntered(activationKey: string) {
    setFieldValue('activationKey', activationKey);
  }

  const isLoading =
    activationStatus === ActivationStatus.ACTIVATION_ATTEMPT_IN_PROGRESS;

  return isResendEmailInProgress ? (
    <Loading className="loading" />
  ) : (
    <form
      onSubmit={handleSubmit}
      className="activate-form"
      data-testid="pilot-web:activation-form"
      autoComplete="off"
    >
      <Typography
        className="heading"
        variant="subtitle1"
        data-testid="pilot-web:activation-form:header"
      >
        We sent you an email containing an activation code.
      </Typography>

      <FormControl
        className="form-control verification-code-input"
        error={isErrored('activationKey')}
        data-testid="pilot-web:activation-form:verification-code-form"
      >
        <ReactInputVerificationCode
          autoFocus
          length={6}
          onCompleted={handleVerificationCodeEntered}
          data-testid="pilot-web:activation-form:verification-code-input"
        />
        <FormHelperText
          className="input-hint"
          data-testid="pilot-web:activation-form:activation-key-error"
        >
          {fieldError('activationKey')}
        </FormHelperText>
      </FormControl>

      <FormHelperText
        className="validation-errors"
        error
        data-testid="pilot-web:activation-form:validation-error"
      >
        {errors.message}
      </FormHelperText>

      <LoadingButton
        isLoading={isLoading}
        variant="secondary"
        className="loading-button form-control"
        type="submit"
        data-testid="pilot-web:activation-form:activate-button"
      >
        Activate
      </LoadingButton>

      <button
        type="button"
        className="sub-link resend-button"
        onClick={handleResendCode}
        data-testid="pilot-web:activation-form:resend-code-button"
      >
        Resend Code
      </button>

      <Link
        to={{
          pathname: `${referrer}/signin`,
          state: { referrer, redirectUrl },
        }}
        className="sub-link"
        onClick={() => setIsAccountActive(true)}
        data-testid="pilot-web:activation-form:sign-in-button"
      >
        Already activated? Click here to sign in
      </Link>
    </form>
  );
}

export const ActivationForm = withRouter(ActivationFormComponent);
