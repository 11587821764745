import { useEffect, useRef, useCallback } from 'react';

import { AircraftTelemetryWithAlerts as SurveillanceViewModel } from 'argus-events/interfaces';
import { useDebounce } from 'airshare-pilot-web-shared';

import { DateTime, Duration } from 'luxon';
import { AircraftType } from '@airshare/external-api-types';

import { usePilotSurveillance } from './use-pilot-surveillance';

const MAXIMUM_LATENCY = 10000;
let runningLatencyAverages: { createdAt: DateTime; latency: number }[] = [];

export function useSurveillanceLatency(): [
  SurveillanceViewModel[],
  number,
  string[],
  boolean,
] {
  const [surveillance, surveillanceMessages] = usePilotSurveillance();

  const { SURVEILLANCE_LATENCY_AVG_WINDOW_IN_SECS } = window.env;

  const isUnavailableState = useRef(false);

  let totalLatency = Duration.fromMillis(0);
  let minLatencyMs = Number.MAX_SAFE_INTEGER;
  let numAirplanes = 0;
  const nowUtc = DateTime.utc();
  const surveillanceFlights = surveillance.map(
    (flight: SurveillanceViewModel) => {
      if (flight.type === AircraftType.Airplane) {
        numAirplanes++;
        const latency = nowUtc.diff(DateTime.fromISO(flight.eventTime));
        totalLatency = totalLatency.plus(latency);
        if (latency.milliseconds < minLatencyMs) {
          minLatencyMs = latency.milliseconds;
        }
      }
      return {
        ...flight,
      };
    }
  );

  if (numAirplanes > 0) {
    const averageLatencyMs = totalLatency.milliseconds / numAirplanes;
    runningLatencyAverages.push({
      createdAt: nowUtc,
      latency: averageLatencyMs,
    });
  }

  const configValue = Number.parseInt(SURVEILLANCE_LATENCY_AVG_WINDOW_IN_SECS);
  const runningAvgWindow = isNaN(configValue) ? 5 : configValue;
  const cacheWindowTime = nowUtc.minus({ seconds: runningAvgWindow });
  runningLatencyAverages = runningLatencyAverages.filter(
    (prev) => prev.createdAt > cacheWindowTime
  );
  const numDataPoints = runningLatencyAverages.length;
  const runningAvg = numDataPoints
    ? runningLatencyAverages.reduce(
        (total, value) => total + value.latency,
        0
      ) / numDataPoints
    : 0;

  const isDataUnavailable = useCallback(() => {
    return (
      (runningAvg === 0 || runningAvg > MAXIMUM_LATENCY) &&
      surveillanceMessages?.length === 0
    );
  }, [surveillanceMessages?.length, runningAvg]);

  const liveFlightsAreUnavailable = isDataUnavailable();

  const debouncedSetIsUnavailableState = useDebounce(async () => {
    isUnavailableState.current = isDataUnavailable();
  }, 5000);

  // immediately display standard latancy message when live flights are available
  // but delay 5 seconds before showing red banner at the top of the map to reduce false negatives
  useEffect(() => {
    if (!liveFlightsAreUnavailable) {
      isUnavailableState.current = liveFlightsAreUnavailable;
    }

    debouncedSetIsUnavailableState();
  }, [liveFlightsAreUnavailable, debouncedSetIsUnavailableState]);

  const isDataValid = useCallback(() => {
    return (
      runningAvg > 0 &&
      surveillanceMessages?.length === 0 &&
      minLatencyMs < MAXIMUM_LATENCY
    );
  }, [surveillanceMessages?.length, minLatencyMs, runningAvg]);

  // If no data after 10 seconds then we must have lost connection, don't continue to display
  // old aircraft data. Just fall back to only drones
  return [
    isDataValid()
      ? surveillanceFlights
      : surveillanceFlights.filter(
          (flight) => flight.type !== AircraftType.Airplane
        ),
    isDataValid() ? runningAvg : 0,
    surveillanceMessages,
    isUnavailableState.current,
  ];
}
