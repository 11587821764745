import { createAction } from 'airshare-web-utils/redux-helpers';

export const FETCH_REQUESTED = 'flight-request-defaults/FETCH_REQUESTED';
export const FETCH_SUCCEEDED = 'flight-request-defaults/FETCH_SUCCEEDED';
export const FETCH_FAILED = 'flight-request-defaults/FETCH_FAILED';

export const flightRequestDefaultsFetchRequested =
  createAction(FETCH_REQUESTED);
export const flightRequestDefaultsFetchSucceeded =
  createAction(FETCH_SUCCEEDED);
export const flightRequestDefaultsFetchFailed = createAction(FETCH_FAILED);
