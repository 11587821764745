import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';
import { PreValidateFlightAreaStatus } from './constants';
import {
  PRE_VALIDATE_FLIGHT_AREA_REQUESTED,
  PRE_VALIDATE_FLIGHT_AREA_SUCCEEDED,
  PRE_VALIDATE_FLIGHT_AREA_FAILED,
  PRE_VALIDATE_FLIGHT_AREA_STATUS_RESET,
  PRE_VALIDATE_FLIGHT_AREA_RESET,
  PreValidateFlightAreaPayloadFailed,
  PreValidateFlightAreaSucessPayload,
} from './actions';

export const flightAreaValidationReducer = createReducer(
  {
    [PRE_VALIDATE_FLIGHT_AREA_FAILED]: (
      _: any,
      { payload }: { payload: PreValidateFlightAreaPayloadFailed }
    ) => payload,
    [PRE_VALIDATE_FLIGHT_AREA_SUCCEEDED]: (
      _: any,
      { payload }: { payload: PreValidateFlightAreaSucessPayload }
    ) => payload,
    // @ts-ignore
    [PRE_VALIDATE_FLIGHT_AREA_RESET]: () => null,
  },
  null
);

export const validationStatusReducer = createReducer(
  {
    [PRE_VALIDATE_FLIGHT_AREA_REQUESTED]: () =>
      PreValidateFlightAreaStatus.PRE_VALIDATE_FLIGHT_AREA_IN_PROGRESS,
    [PRE_VALIDATE_FLIGHT_AREA_SUCCEEDED]: () =>
      PreValidateFlightAreaStatus.SUCCESS,
    [PRE_VALIDATE_FLIGHT_AREA_FAILED]: () => PreValidateFlightAreaStatus.FAILED,
    [PRE_VALIDATE_FLIGHT_AREA_STATUS_RESET]: () =>
      PreValidateFlightAreaStatus.IDLE,
  },
  PreValidateFlightAreaStatus.IDLE
);

export const preValidateFlightAreaReducer = combineReducers({
  flightAreaValidation: flightAreaValidationReducer,
  validationStatus: validationStatusReducer,
});
