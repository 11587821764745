import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { useSelector } from 'react-redux';

import { createReducer } from 'airshare-web-utils/redux-helpers';

import { pilotAPI } from '../pilot-api-client/api';

export interface ZoneCode {
  key: string;
  name: string;
  description: string;
  category: string;
}

export interface SettingsPageLink {
  label: string;
  link: string;
}

export enum AppDefaultName {
  CENTER_ON_PILOT = 'centerOnPilot',
  INIT_LAT = 'initialLat',
  INIT_LNG = 'initialLng',
  INIT_ZOOM = 'initialZoom',
  ATC_DISPLAY_NAME = 'atcDisplayName',
}

export interface AppDefault {
  name: AppDefaultName;
  type: 'string' | 'bool' | 'number';
  value: any;
  editedValue?: string;
}

export interface AppConfigData {
  supportedZoneCodes?: ZoneCode[];
  settingsPage?: SettingsPageLink[];
  defaults?: AppDefault[];
  logoRectangle?: string;
  logoSquare?: string;
}

export function getDefault(
  name: AppDefaultName,
  cfg?: AppConfigData
): AppDefault | undefined {
  return cfg?.defaults?.find((def) => def.name === name);
}

export function getCenterOnPilot(cfg?: AppConfigData) {
  return getDefault(AppDefaultName.CENTER_ON_PILOT, cfg)?.value as boolean;
}
export function getInitLat(cfg?: AppConfigData) {
  return getDefault(AppDefaultName.INIT_LAT, cfg)?.value as number;
}
export function getInitLng(cfg?: AppConfigData) {
  return getDefault(AppDefaultName.INIT_LNG, cfg)?.value as number;
}
export function getInitZoom(cfg?: AppConfigData) {
  return getDefault(AppDefaultName.INIT_ZOOM, cfg)?.value as number;
}
export function getAtcDisplayName(cfg?: AppConfigData) {
  return getDefault(AppDefaultName.ATC_DISPLAY_NAME, cfg)?.value as string;
}

// action types
const FETCH = 'app-config/FETCH';
const FETCH_SUCCEEDED = 'app-config/FETCH_SUCCEEDED';
const FETCH_FAILED = 'app-config/FETCH_FAILED';

// reducers
const appConfigReducer = createReducer(
  {
    [FETCH]: (): null => null,
    [FETCH_SUCCEEDED]: (_: any, { payload }: any) => payload,
    [FETCH_FAILED]: (): null => null,
  },
  null,
  true
);

const errorReducer = createReducer({
  [FETCH]: (): null => null,
  [FETCH_SUCCEEDED]: (): null => null,
  [FETCH_FAILED]: (_: any, { payload }: any) => payload,
});

export const reducer = combineReducers({
  config: appConfigReducer,
  error: errorReducer,
});

// selectors
export const getLocalState = (state: any) => state.appConfig;

export const selectAppConfig = createSelector(
  [getLocalState],
  (state) => state.config
);

export const selectError = createSelector(
  [getLocalState],
  (state) => state.error
);

// sagas
export function* saga() {
  yield takeLatest(FETCH, onFetch);
  yield put({ type: FETCH });
}

// hooks
export function useAppConfig(): AppConfigData {
  return useSelector(selectAppConfig);
}

function* onFetch() {
  try {
    const { data } = yield call(pilotAPI.get, '/app-config/web');

    yield put({ type: FETCH_SUCCEEDED, payload: data });
  } catch (error) {
    yield put({ type: FETCH_FAILED, payload: error });
  }
}
