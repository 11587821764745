/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { LatLng } from '@airshare/external-api-types';

export interface GoogleMapConfig {
  center?: LatLng;
  zoom?: number;
  onReady?: (map: google.maps.Map) => void;
  disableDefaultUI?: boolean;
}

let globalMap: any = null;

export function useMap(
  google: any,
  ref: React.MutableRefObject<undefined>,
  config: GoogleMapConfig = {}
): [
  google.maps.Map | null,
  () => void,
  google.maps.LatLngBounds | null,
  google.maps.LatLng | null,
  (center?: LatLng) => void,
] {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [currentConfig, setCurrentConfig] = useState<GoogleMapConfig>({});

  const [mapBounds, setMapLocalBounds] =
    useState<google.maps.LatLngBounds | null>();
  const [mapCenter, setMapLocalCenter] = useState<google.maps.LatLng | null>();

  useEffect(() => {
    if (google && ref.current) {
      const mapConfig = {
        ...config,
      };

      setCurrentConfig(mapConfig);
      const newMap = new google.maps.Map(ref.current, mapConfig);
      newMap.addListener('idle', () => {
        setMapLocalBounds(newMap.getBounds());
        setMapLocalCenter(newMap.getCenter());
      });

      setMap(newMap);
      globalMap = newMap;

      google.maps.event.trigger(newMap, 'ready');

      if (mapConfig.onReady) {
        mapConfig.onReady(newMap);
      }
    }
  }, [google, ref]);

  const rerenderMap = useCallback((newConfig: GoogleMapConfig = {}) => {
    if (google && ref.current) {
      const mapConfig = {
        ...currentConfig,
        ...newConfig,
      };

      setCurrentConfig(mapConfig);

      const newMap = new google.maps.Map(ref.current, mapConfig);
      newMap.addListener('idle', () => {
        setMapLocalBounds(newMap.getBounds());
        setMapLocalCenter(newMap.getCenter());
      });

      setMap(newMap);
      globalMap = newMap;

      google.maps.event.trigger(newMap, 'ready');

      if (mapConfig.onReady) {
        mapConfig.onReady(newMap);
      }
    }
  }, []);

  const setMapCenter = useCallback((center?: LatLng) => {
    if (!globalMap || !center) {
      return;
    }
    globalMap.setCenter(center);
  }, []);

  return [map, rerenderMap, mapBounds ?? null, mapCenter ?? null, setMapCenter];
}
