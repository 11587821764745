import { FRStatusCode } from '@airshare/external-api-types';

import { resolveSingleStatus } from './multi-status-check';

export function formatStatus(status: string | null): string | null {
  switch (status) {
    case FRStatusCode.RNZAFAuthReq:
      return window.env.ENVIRONMENT === 'demo'
        ? 'RAF Auth. Req.'
        : 'RNZAF Auth. Req.';
    case FRStatusCode.Authorized:
      return 'Authorised';
    default:
      return status || null;
  }
}

export function getStatusText<A extends { code: FRStatusCode }>(
  status: string | A[]
) {
  const singleStatus = Array.isArray(status)
    ? resolveSingleStatus(status)
    : status;
  return formatStatus(singleStatus);
}
