import { useEffect } from 'react';
import bbox from '@turf/bbox';
import { FlightPathMode } from 'argus-api-shared/lib/constants';
import type { ExtendedFlightResponseBodyV2 } from '@airshare/pilot-types';

import { ValidFlightShape } from '@airshare/external-api-types';

import { useFlightPlanEditSuccess } from '../../../state/flight-plan/hooks';

let selectedFlightArea: ValidFlightShape | null = null;

export function useRenderEditFlightArea(
  google: typeof window.google,
  mapInstance: google.maps.Map | null
) {
  const [flightPlanToEditSuccess] =
    useFlightPlanEditSuccess() as ExtendedFlightResponseBodyV2[];

  useEffect(() => {
    if (
      google &&
      mapInstance &&
      flightPlanToEditSuccess?.properties?.coordinates
    ) {
      const {
        coordinates: rawCoordinates,
        pathMode,
        radius,
      } = flightPlanToEditSuccess.properties;
      const geojson = flightPlanToEditSuccess.geometry;
      let flightArea: ValidFlightShape;

      const coordinates: google.maps.LatLngLiteral[] = rawCoordinates.map(
        ({ Lng, Lat }) => ({
          lng: Lng,
          lat: Lat,
        })
      );

      if (pathMode === FlightPathMode.CIRCLE) {
        flightArea = new google.maps.Circle({
          center: coordinates[0],
          radius: radius,
          map: mapInstance,
          fillColor: '#000000',
          fillOpacity: 0.2,
          strokeColor: '#000000',
          strokeOpacity: 0.4,
          strokeWeight: 2,
          clickable: false,
          zIndex: 1,
          editable: false,
        });
      } else if (pathMode === FlightPathMode.POLYGON) {
        flightArea = new google.maps.Polygon({
          paths: coordinates,
          map: mapInstance,
          strokeWeight: 0.5,
          fillOpacity: 0.2,
          editable: false,
        });
      }

      if (flightArea) {
        if (selectedFlightArea) {
          selectedFlightArea.setMap(null);
        }

        selectedFlightArea = flightArea;
        const [swLng, swLat, neLng, neLat] = bbox(geojson);

        const bounds = new google.maps.LatLngBounds(
          { lat: swLat, lng: swLng },
          { lat: neLat, lng: neLng }
        );

        mapInstance.fitBounds(bounds);
      }
    } else {
      if (selectedFlightArea) {
        selectedFlightArea.setMap(null);
      }
    }
  }, [google, mapInstance, flightPlanToEditSuccess]);
}
