import React, { PropsWithChildren } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles((_theme) => ({
  titleTooltipRule: {
    fontSize: '14px',
  },
  iconTooltipRule: {
    width: '16px',
    height: '16px',
    marginTop: '6',
    marginLeft: '4px',
    cursor: 'pointer',
    color: '#999',
  },
}));

interface OptionalProps {
  url?: string;
}

export default function InfoTooltip(props: PropsWithChildren<OptionalProps>) {
  const classes = useStyles();

  return (
    <Tooltip
      data-testid="info-tooltip"
      title={<span className={classes.titleTooltipRule}>{props.children}</span>}
      onClick={() => {
        if (props.url) {
          window.open(props.url, '_blank');
        }
      }}
    >
      <HelpOutline className={classes.iconTooltipRule} />
    </Tooltip>
  );
}
