import { useFlightRequestsFetchStatus } from '~/state/flight-requests/hooks';
import { FlightRequestsFetchStatus } from '~/state/flight-requests/constants';

export function useIsLoading(flightRequests) {
  const flightRequestsRequestStatus = useFlightRequestsFetchStatus();

  return (
    !flightRequests &&
    flightRequestsRequestStatus ===
      FlightRequestsFetchStatus.FLIGHT_REQUESTS_FETCH_IN_PROGRESS
  );
}
