export const SET_PROFILE = 'session/SET_PROFILE';
export const SET_ERROR = 'session/SET_ERROR';

export const SET_LOGIN_STATUS = 'session/SET_LOGIN_STATUS';
export const ATTEMPT_LOG_IN = 'session/ATTEMPT_LOG_IN';

export const SET_REGISTRATION_STATUS = 'session/SET_REGISTRATION_STATUS';
export const ATTEMPT_REGISTRATION = 'session/ATTEMPT_REGISTRATION';

export const SET_ACTIVATION_STATUS = 'session/SET_ACTIVATION_STATUS';
export const ATTEMPT_ACTIVATION = 'session/ATTEMPT_ACTIVATION';

export const SET_IS_ACCOUNT_ACTIVE = 'session/SET_IS_ACCOUNT_ACTIVE';

export const SET_RESEND_ACTIVATION_EMAIL_STATUS =
  'session/SET_RESEND_ACTIVATION_EMAIL_STATUS';
export const ATTEMPT_RESEND_ACTIVATION_EMAIL =
  'session/ATTEMPT_RESEND_ACTIVATION_EMAIL';

export const SET_COMPLETE_LOGIN_DATA = 'session/SET_COMPLETE_LOGIN_DATA';
export const SET_POLICIES_TO_ACCEPT = 'session/SET_POLICIES_TO_ACCEPT';
export const COMPLETE_POLICY_ACCEPTANCE = 'session/COMPLETE_POLICY_ACCEPTANCE';
