import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { animated, useSpring } from 'react-spring';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { DatePicker } from '@material-ui/pickers';
import PhoneNumberInput from 'material-ui-phone-number';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { LoadingButton } from 'airshare-pilot-web-shared';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { OperatorType } from '@airshare/external-api-types';

import { PROFILE_PATH } from '../../../../routes';
import {
  useFormState,
  useInputHandlerProps,
  useIsErrored,
  useFieldError,
  useErrors,
  useHandleSubmit,
  useSetFieldValue,
  useClearForm,
  useResetErrors,
  useHydrateForm,
} from '../../../../state/profile/update-profile-form/hooks';
import {
  useProfile,
  useUpdateProfile,
  useIsCommercialOperator,
} from '../../../../state/profile/hooks';
import { ProfileUpdateStatus } from '../../../../state/profile/enum';

import './update-profile.scss';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MobileRegex } from '@airshare/pilot-types';

function UpdateProfile({ history }: RouteComponentProps) {
  const isRegisteredCommercialOperator = useIsCommercialOperator();
  const showShareContactInfo =
    window.env.CONFLICTING_FLIGHTS_ENABLED?.toLocaleLowerCase() === 'true';

  const profile = useProfile();
  const {
    updateProfile,
    updateStatus: updateProfileStatus,
    resetStatus,
  } = useUpdateProfile();

  const hydrateForm = useHydrateForm();
  const form: any = useFormState();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const handlerProps = useInputHandlerProps();
  const fieldError = useFieldError();
  const setFieldValue = useSetFieldValue();
  const clearForm = useClearForm();
  const resetErrors = useResetErrors();
  const handleSubmit = useHandleSubmit(() => {
    setIsLoading(true);
    console.log('form value', form.mobileNo);
    updateProfile(form);
  });

  const [isCommercialOperator, setIsCommercialOperator] = useState(false);
  const [updateProfileErrorMessage, setUpdateProfileErrorMessage] =
    useState('');
  const [isLoading, setIsLoading] = useState(false);
  const commercialOperatorsSpring = useSpring({
    height: isCommercialOperator ? 150 : 0,
  });
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true' || false;

  useEffect(() => {
    if (profile) {
      const {
        id,
        photoUrl,
        pilot: { result },
        ...defaultProperties
      } = profile;
      const { notifyConflicts, shareContactInfo } = result;

      hydrateForm({
        id,
        photoUrl,
        ...defaultProperties,
        notifyConflicts,
        shareContactInfo,
      });

      if (isRegisteredCommercialOperator) {
        setFieldValue('operatorType', OperatorType.COMMERCIAL);
        setIsCommercialOperator(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(
    () => () => {
      clearForm();
      resetErrors();
      resetStatus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (updateProfileStatus === ProfileUpdateStatus.SUCCESS) {
      setTimeout(() => {
        setIsLoading(false);
        history.push(PROFILE_PATH);
      }, 1000);
    } else if (updateProfileStatus === ProfileUpdateStatus.FAILED) {
      setIsLoading(false);
      setUpdateProfileErrorMessage(
        'Unfortunately we could not update your profile. Please try again later'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileStatus]);

  const handleMobileNumberChange = (value: string) => {
    try {
      if (!MobileRegex.test(value)) {
        setFieldValue('mobileNo', value);
      } else {
        const formattedNumber = parsePhoneNumber(value);
        const validNumber = `+${formattedNumber.countryCallingCode} ${formattedNumber.nationalNumber}`;
        setFieldValue('mobileNo', validNumber);
      }
    } catch (error) {
      setFieldValue('mobileNo', '+');
      console.error('Invalid mobile number');
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="update-profile-form"
      data-testid="pilot-web:update-profile-form"
    >
      <Typography
        className="heading"
        variant="h6"
        component="h1"
        align="center"
      >
        Edit Profile
      </Typography>

      <FormControl className="form-control" error={isErrored('fullName')}>
        <InputLabel className="input-label" htmlFor="fullName">
          Name
        </InputLabel>
        <Input
          name="fullName"
          {...handlerProps}
          value={form.fullName}
          inputProps={{
            'data-testid': 'pilot-web:update-profile-form:name-input',
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('fullName')}
        </FormHelperText>
      </FormControl>

      <FormControl className="form-control" error={isErrored('email')}>
        <InputLabel className="input-label" htmlFor="email">
          Email
        </InputLabel>
        <Input
          name="email"
          {...handlerProps}
          value={form.email}
          inputProps={{
            'data-testid': 'pilot-web:update-profile-form:email-input',
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('email')}
        </FormHelperText>
      </FormControl>

      <FormControl className="form-control phone" error={isErrored('mobileNo')}>
        {!form.mobileNo && (
          <InputLabel className="input-label phone" htmlFor="mobileNo">
            Phone Number
          </InputLabel>
        )}
        <PhoneNumberInput
          className="phone-input"
          label="Phone Number"
          name="mobileNo"
          defaultCountry="nz"
          disableAreaCodes={true}
          onFocus={handlerProps.onFocus}
          onBlur={handlerProps.onBlur}
          onChange={(value: string) => {
            handleMobileNumberChange(value);
          }}
          value={form.mobileNo}
          error={isErrored('mobileNo')}
          inputProps={{
            'data-testid': 'pilot-web:update-profile-form:phone-input',
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('mobileNo')}
        </FormHelperText>
      </FormControl>

      {isEuroRegulation ? (
        <FormControl
          className="form-control"
          error={isErrored('registrationNo')}
        >
          <InputLabel className="input-label" htmlFor="registrationNo">
            Registration Number
          </InputLabel>
          <Input
            name="registrationNo"
            {...handlerProps}
            value={form.registrationNo}
          />
          <FormHelperText className="input-hint">
            {fieldError('registrationNo')}
          </FormHelperText>
        </FormControl>
      ) : null}

      <FormControl
        className="form-control dob"
        error={isErrored('dateOfBirth')}
      >
        <DatePicker
          variant="inline"
          format="dd/MM/yyyy"
          className="dateOfBirth"
          name="dateOfBirth"
          label="Date of Birth (Optional)"
          openTo="year"
          autoOk
          maxDate={DateTime.now()}
          onChange={(value) => {
            setFieldValue('dateOfBirth', value);
          }}
          value={form.dateOfBirth}
          error={isErrored('dateOfBirth')}
          inputProps={{
            'data-testid': 'pilot-web:update-profile-form:dob-input',
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={(event) => event.stopPropagation()}
              >
                <IconButton
                  size="small"
                  onClick={() => setFieldValue('dateOfBirth', null)}
                  data-testid="pilot-web:update-profile-form:dob-input-clear-button"
                >
                  {form.dateOfBirth && <i className="input-icon clear" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText className="input-hint">
          {fieldError('dateOfBirth')}
        </FormHelperText>
      </FormControl>

      <FormControlLabel
        label={
          <Typography variant="subtitle1">
            Notify of conflicting flights?
          </Typography>
        }
        labelPlacement="start"
        className="notify-conflicting-flight-check"
        control={
          <Checkbox
            name="notifyConflictingFlight"
            size="small"
            color="primary"
            checked={form.notifyConflicts}
            onChange={(_, value) => {
              setFieldValue('notifyConflicts', value);
            }}
            data-testid="pilot-web:update-profile-form:user-notify-no-conflicts-input"
          />
        }
      />

      {showShareContactInfo && (
        <FormControlLabel
          label={
            <Typography variant="subtitle1">
              Share contact info and flight details with pilots of conflicting
              flights?
            </Typography>
          }
          labelPlacement="start"
          className="share-contact-info-check"
          control={
            <Checkbox
              name="shareContactInfo"
              size="small"
              color="primary"
              checked={form.shareContactInfo}
              onChange={(_, value) => {
                setFieldValue('shareContactInfo', value);
              }}
              data-testid="pilot-web:update-profile-form:share-contact-info-input"
            />
          }
        />
      )}

      {isRegisteredCommercialOperator && (
        <>
          {/* 
// @ts-ignore */}
          <FormControlLabel
            label={
              <Typography variant="subtitle1">
                Are you a commercial operator?
              </Typography>
            }
            labelPlacement="start"
            className="commercial-operator-switch"
            control={
              <Checkbox
                name="operatorType"
                size="small"
                color="primary"
                disabled
                checked={form.operatorType === OperatorType.COMMERCIAL}
                onChange={(_, value) => {
                  const operatorType = value
                    ? OperatorType.COMMERCIAL
                    : OperatorType.RECREATIONAL;

                  setIsCommercialOperator(
                    () => operatorType === OperatorType.COMMERCIAL
                  );
                  setFieldValue('operatorType', operatorType);
                }}
              />
            }
          >
            <FormHelperText className="input-hint">
              {fieldError('operatorType')}
            </FormHelperText>
          </FormControlLabel>
          <>
            <animated.div
              style={commercialOperatorsSpring}
              className="commercial-operator-fields"
            >
              <FormControl
                className="form-control"
                error={isErrored('businessName')}
              >
                <InputLabel className="input-label" htmlFor="businessName">
                  Business Name
                </InputLabel>
                <Input
                  name="businessName"
                  {...handlerProps}
                  value={form.businessName}
                  data-testid="pilot-web:update-profile-form:business-name-input"
                />
                <FormHelperText
                  className="input-hint"
                  data-testid="pilot-web:update-profile-form:business-name-error"
                >
                  {fieldError('businessName')}
                </FormHelperText>
              </FormControl>
            </animated.div>
          </>
          <></>
        </>
      )}

      <FormHelperText error>
        {errors.message || updateProfileErrorMessage}
      </FormHelperText>

      <div className="save-profile-button-container">
        <LoadingButton
          isLoading={isLoading}
          variant="primary"
          className="form-control save-profile-button"
          type="submit"
          data-testid="pilot-web:update-profile-form:submit-button"
        >
          Save Changes
        </LoadingButton>
      </div>
    </form>
  );
}

export default withRouter(UpdateProfile);
