import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#0000cc',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: `'Calibri',sans-serif`,
    h1: {
      fontFamily: `'GothamMedium',sans-serif`,
    },
    h2: {
      fontFamily: `GothamMedium,sans-serif`,
    },
    h3: {
      fontFamily: `GothamMedium,sans-serif`,
    },
    h4: {
      fontFamily: `GothamMedium,sans-serif`,
    },
    h5: {
      fontFamily: `GothamMedium,sans-serif`,
    },
    h6: {
      fontFamily: `GothamMedium,sans-serif`,
    },
  },
});
