import React, { useMemo, useContext, memo } from 'react';

import { Notification } from './activated-notification';
import { useProfile as getUserProfile } from '../../../state/profile/hooks';
import {
  NotificationState,
  NotificationStateType,
} from '../../../state/NotificationState';

const AlertsToNotifications = memo(function renderNotifications({
  alerts = {},
}: {
  alerts: NotificationStateType['alerts'];
}) {
  const notifications = Object.entries(alerts ?? {})
    .sort((a, b) => (a[0] > b[0] ? 1 : -1))
    .flatMap(([messageId, payloadAlerts]) => {
      return payloadAlerts?.map(({ flightId, nearbyFlightIds }) => ({
        messageId,
        flightId,
        nearbyFlightIds,
      }));
    });

  return (
    <>
      {notifications?.map(({ messageId, flightId, nearbyFlightIds }) => {
        return (
          <Notification
            key={`${messageId}-${flightId}`}
            messageId={messageId}
            activatedFlightId={flightId}
            overlappingFlightIds={nearbyFlightIds}
          />
        );
      })}
    </>
  );
});

export const NearbyNotifcation = () => {
  const { alerts, clearAlerts } = useContext(NotificationState);
  const userProfile = getUserProfile();

  useMemo(() => {
    if (!userProfile?.email) {
      clearAlerts();
    }
  }, [userProfile?.email, clearAlerts]);

  if (!userProfile?.email || !alerts) {
    return null;
  }

  return <AlertsToNotifications alerts={alerts} />;
};
