import { createSelector } from 'reselect';
import { MapOptionsState } from './reducers';

interface StateWithMapStyles {
  mapStyles: MapOptionsState;
}

const getLocalState = (state: StateWithMapStyles) => state.mapStyles;

export const getMapStyles = createSelector([getLocalState], (state) => state);
