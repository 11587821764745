import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import InfoTooltip from '../../../shared/info-tooltip/info-tooltip';
import { UasOperationCategory, FlightRule } from 'argus-common/enums';
import ToggleButtonGroup from 'airshare-pilot-web-shared/src/components/toggle-button-group/toggle-button-group';

const useStyles = makeStyles((_theme) => ({
  LabelRule: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    cursor: 'default',
  },
}));

const OperationRule = (props: {
  isPart102Allowed: boolean;
  currentValue: string; // only set to FlightRule.PART_101 | FlightRule.PART_102
  operationCategory: UasOperationCategory;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const classes = useStyles();
  const {
    isPart102Allowed,
    currentValue,
    onChange,
    operationCategory,
    disabled,
  } = props;
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';
  return !isEuroRegulation ||
    [UasOperationCategory.CERTIFIED, UasOperationCategory.SPECIFIC].includes(
      operationCategory
    ) ? (
    <>
      <FormLabel className="form-section-heading" component="h2">
        Rules
      </FormLabel>
      {/* 
// @ts-ignore */}
      <FormControlLabel
        labelPlacement="top"
        className="rule"
        control={
          <div className={classes.LabelRule}>
            <ToggleButtonGroup
              name="rule"
              options={[
                {
                  value: FlightRule.PART_101,
                  label: isEuroRegulation ? FlightRule.LOS : 'PART 101',
                },
                {
                  value: FlightRule.PART_102,
                  label: isEuroRegulation ? FlightRule.BVLOS : 'PART 102',
                },
              ]}
              selectedValue={currentValue}
              onChange={onChange}
              disabled={disabled}
              className={`rule ${isPart102Allowed ? '' : 'toggle-button-group-disabled'}`}
              data-testid="pilot-web:flight-request:rules"
              valuesDisabled={isPart102Allowed ? [] : ['PART 102']}
            />
            {!isEuroRegulation && !isPart102Allowed && (
              <InfoTooltip>
                The pilot does not have <strong>Part 102</strong> rule enabled.
              </InfoTooltip>
            )}
          </div>
        }
      ></FormControlLabel>
    </>
  ) : null;
};

export default OperationRule;
