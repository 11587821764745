import type { FeatureCollection, Polygon } from 'geojson';
import type { FlightDateTime } from './database';
import type { Area } from '../misc';
import type { FlightSegmentOverlapProperties } from './segment-collection';

export type BasicFlightDateTime = Pick<
  FlightDateTime,
  'startDateTime' | 'endDateTime' | 'durationMinutes'
>;

export interface FlightAltitude {
  launchElevationFeet: number;
  minAltitudeFeet: number;
  maxAltitudeFeet: number;
}

export type BasicFlightAltitude = Pick<
  FlightAltitude,
  'minAltitudeFeet' | 'maxAltitudeFeet'
>;

export interface FlightSpaceTime {
  flightDateTime: BasicFlightDateTime;
  flightAltitude: BasicFlightAltitude;
  geometry: Area;
  featureCollection?: FeatureCollection<
    Polygon,
    FlightSegmentOverlapProperties
  >;
}

// For future support for AMSL (above mean sea level)
export enum AltitudeType {
  AGL = 'AGL', // above ground level
}
