import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import bbox from '@turf/bbox';
import type { FeatureCollection, Polygon } from '@turf/helpers';
import { useLocation } from 'react-router';

import { AdvisorySource, FlightRule } from '@airshare/pilot-types';
import { type StyledAreaResponse } from '@airshare/external-api-types';

import { FLIGHT_PLAN_PATH } from '../../../routes';
import {
  useFormState,
  useSetFieldValue,
} from '../../../state/flight-plan/flight-plan-form/hooks';
import { PreValidateFlightAreaStatus } from '../../../state/flight-plan/pre-validate-flight-area/constants';
import {
  usePreValidateFlightArea,
  usePreFlightValidateFlightAreaStatus,
  useResetPreFlightValidateFlightAreaStatus,
} from '../../../state/flight-plan/pre-validate-flight-area/hooks';
import {
  useIsEditingFavouriteFlightArea,
  useNewFlightAreaDrawn,
  useSelectedFlightPathArea,
  useResetUploadedFlightArea,
  useUploadedFlightArea,
  useSelectedFavourite,
  useResetFavourite,
  useResetFlightPathArea,
} from '../../../state/flight-plan/hooks';
import {
  updateFlightPlanForm,
  addEditListeners,
  eventToValidFlightShape,
} from './helpers';
import {
  payloadToPolygon,
  overlayToPathMode,
  defaultBufferMetres,
} from '../../../lib/polygon-helpers';
import { GlobalState } from '../../../state/GlobalState';
import { useFormSegmentCollection } from '../../shared/segments/use-form-segment-collection';
import {
  clearFeatures,
  defaultAreaStyle,
  renderFlight,
} from '../render-helpers';
import { styleFeatureCollection } from '../../shared/segments/segment-helpers';

export function useDynamicDrawingManager(
  mapInstance: google.maps.Map | null,
  dataLayer: google.maps.Data | null,
  drawingManagerInstance: google.maps.drawing.DrawingManager | null,
  hasPolylineOverlay: FeatureCollection<Polygon> | null,
  setHasPolylineOverlay: (_: FeatureCollection<Polygon> | null) => void,
  allowSegmentedFlights: boolean
) {
  const { pathname } = useLocation();

  const [flightArea, setFlightArea] =
    useState<google.maps.drawing.OverlayCompleteEvent | null>(null);
  const [overlayListener, setOverlayListener] =
    useState<google.maps.MapsEventListener | null>(null);
  const [leftFlightPlanPage, setLeftFlightPlanPage] = useState(false);
  const { preValidateFlightArea } = usePreValidateFlightArea();
  const resetFavourite = useResetFavourite();
  const favourite = useSelectedFavourite();
  const isEditingFavouriteFlightArea = useIsEditingFavouriteFlightArea();
  const newFlightAreaDrawn = useNewFlightAreaDrawn();
  const flightPathArea: StyledAreaResponse | null = useSelectedFlightPathArea();
  useFormSegmentCollection();
  const resetSelectedArea = useResetFlightPathArea();
  const uploadedFlightArea = useUploadedFlightArea();
  const resetUploadedFlightArea = useResetUploadedFlightArea();
  const form = useFormState();
  const {
    setAdvisoryGeometry,
    segmentedBufferRadius,
    setSegmentedBufferRadius,
    highlightedSegments,
  } = useContext(GlobalState);

  const preFlightValidateFlightAreaStatus =
    usePreFlightValidateFlightAreaStatus();
  const resetPreFlightValidateFlightAreaStatus =
    useResetPreFlightValidateFlightAreaStatus();
  const setFlightPlanFormField = useSetFieldValue();

  const renderPolyline = useCallback(
    (polygon: Polygon, eventType: google.maps.drawing.OverlayType) => {
      const updatedPolygonFeatureCollection: FeatureCollection<Polygon> = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: polygon,
            properties: defaultAreaStyle,
          },
        ],
      };
      if (eventType === google.maps.drawing.OverlayType.POLYLINE) {
        clearFeatures(dataLayer);
        const styledFC = form?.segmentCollection?.features?.length
          ? styleFeatureCollection(form.segmentCollection, highlightedSegments)
          : updatedPolygonFeatureCollection;
        setHasPolylineOverlay(styledFC);
        return renderFlight(dataLayer, styledFC);
      } else {
        setHasPolylineOverlay(null);
      }
    },
    [
      dataLayer,
      form.segmentCollection,
      highlightedSegments,
      setHasPolylineOverlay,
    ]
  );

  useMemo(() => {
    if (
      hasPolylineOverlay &&
      (!allowSegmentedFlights || form.rule !== FlightRule.PART_102)
    ) {
      clearFeatures(dataLayer);
      setHasPolylineOverlay(null);
      flightArea?.overlay.setMap(null);
      setFlightArea(null);
      setSegmentedBufferRadius(defaultBufferMetres());
    }
  }, [
    allowSegmentedFlights,
    hasPolylineOverlay,
    form.rule,
    dataLayer,
    setHasPolylineOverlay,
    flightArea?.overlay,
    setSegmentedBufferRadius,
  ]);

  const handleFlightAreaSelected = useCallback(
    (event: google.maps.drawing.OverlayCompleteEvent) => {
      if (
        preFlightValidateFlightAreaStatus !==
        PreValidateFlightAreaStatus.PRE_VALIDATE_FLIGHT_AREA_IN_PROGRESS
      ) {
        // Clear any other area types area
        resetUploadedFlightArea();
        resetFavourite();
        resetSelectedArea();
        clearFeatures(dataLayer);

        setFlightPlanFormField('pathMode', overlayToPathMode(event.type));

        const path = eventToValidFlightShape(event);
        const updatedPolygon = payloadToPolygon(event, segmentedBufferRadius);

        if (path) {
          addEditListeners(path, () => {
            setFlightArea(event);
            const latestPolygon = payloadToPolygon(
              event,
              segmentedBufferRadius
            );
            preValidateFlightArea(latestPolygon);

            setAdvisoryGeometry(AdvisorySource.DraftFlightArea, latestPolygon);
          });
        }

        setFlightArea((oldFA: google.maps.drawing.OverlayCompleteEvent) => {
          if (oldFA) {
            oldFA.overlay.setMap(null);
          }
          return event;
        });
        preValidateFlightArea(updatedPolygon);

        setAdvisoryGeometry(AdvisorySource.DraftFlightArea, updatedPolygon);

        // ? Return to 'hand' mode
        drawingManagerInstance.setDrawingMode(null);

        newFlightAreaDrawn();
      }
    },
    [
      preFlightValidateFlightAreaStatus,
      resetUploadedFlightArea,
      resetFavourite,
      resetSelectedArea,
      dataLayer,
      setFlightPlanFormField,
      segmentedBufferRadius,
      preValidateFlightArea,
      setAdvisoryGeometry,
      drawingManagerInstance,
      newFlightAreaDrawn,
    ]
  );

  useEffect(() => {
    if (!overlayListener) {
      const nextListener = drawingManagerInstance?.addListener(
        'overlaycomplete',
        handleFlightAreaSelected
      );
      setOverlayListener((oldListener: google.maps.MapsEventListener) => {
        if (oldListener) {
          google.maps.event.removeListener(oldListener);
        }
        return nextListener;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFlightAreaSelected, segmentedBufferRadius]);

  useMemo(() => {
    if (
      google &&
      (preFlightValidateFlightAreaStatus ===
        PreValidateFlightAreaStatus.SUCCESS ||
        preFlightValidateFlightAreaStatus ===
          PreValidateFlightAreaStatus.FAILED) &&
      !isEditingFavouriteFlightArea
    ) {
      resetPreFlightValidateFlightAreaStatus();

      const predefinedGeometry =
        flightPathArea || uploadedFlightArea || favourite;
      if (!predefinedGeometry && flightArea) {
        updateFlightPlanForm(flightArea, setFlightPlanFormField);
      }
    }
  }, [
    preFlightValidateFlightAreaStatus,
    isEditingFavouriteFlightArea,
    resetPreFlightValidateFlightAreaStatus,
    flightArea,
    setFlightPlanFormField,
    flightPathArea,
    uploadedFlightArea,
    favourite,
  ]);

  const polygonRef = useRef<{
    area: google.maps.drawing.OverlayCompleteEvent;
    radius: number;
  }>(null);

  useEffect(() => {
    if (flightArea?.type === google.maps.drawing.OverlayType.POLYLINE) {
      if (
        polygonRef.current?.area === flightArea &&
        polygonRef.current?.radius === segmentedBufferRadius
      ) {
        return;
      }
      polygonRef.current = {
        area: flightArea,
        radius: segmentedBufferRadius,
      };
      const polygon = payloadToPolygon(flightArea, segmentedBufferRadius);
      renderPolyline(polygon, flightArea?.type);
      preValidateFlightArea(polygon);
      setAdvisoryGeometry(AdvisorySource.DraftFlightArea, polygon);
    }
  }, [
    dataLayer,
    flightArea,
    preValidateFlightArea,
    renderPolyline,
    segmentedBufferRadius,
    setAdvisoryGeometry,
    setHasPolylineOverlay,
  ]);

  useMemo(() => {
    if (drawingManagerInstance) {
      // Only mount the drawing manager controls when making a flight plan
      // and user hasn't selected a Flight Plan managed area or uploaded a file
      const canDraw =
        pathname.endsWith(FLIGHT_PLAN_PATH) &&
        !flightPathArea &&
        !uploadedFlightArea;

      if (canDraw) {
        drawingManagerInstance.setMap(mapInstance);

        if (flightArea && form.pathMode && leftFlightPlanPage) {
          const polygon = payloadToPolygon(flightArea, segmentedBufferRadius);
          preValidateFlightArea(polygon);
          flightArea.overlay.setMap(mapInstance);

          renderPolyline(polygon, flightArea.type);

          const [swLng, swLat, neLng, neLat] = bbox(polygon);

          const bounds = new google.maps.LatLngBounds(
            { lat: swLat, lng: swLng },
            { lat: neLat, lng: neLng }
          );

          mapInstance.fitBounds(bounds);
          setAdvisoryGeometry(AdvisorySource.DraftFlightArea, polygon);
        } else if (!form.flightAreaSource && flightArea) {
          // hide the current flight area on form reset
          flightArea.overlay.setMap(null);
        }
      } else {
        setHasPolylineOverlay(null);
        clearFeatures(dataLayer);
        drawingManagerInstance.setMap(null);
        if (flightArea) {
          // hide the current flight area
          flightArea.overlay.setMap(null);
          if (flightPathArea || uploadedFlightArea || favourite) {
            // clear the flight area on file upload or flight path area selection
            setFlightArea(null);
          }
        }
      }

      drawingManagerInstance.setOptions({
        drawingControl: canDraw,
      });

      setLeftFlightPlanPage(!pathname.endsWith(FLIGHT_PLAN_PATH));
    }
  }, [
    pathname,
    dataLayer,
    drawingManagerInstance,
    flightArea,
    flightPathArea,
    mapInstance,
    setFlightArea,
    preValidateFlightArea,
    uploadedFlightArea,
    form.pathMode,
    segmentedBufferRadius,
    setAdvisoryGeometry,
    form.flightAreaSource,
    leftFlightPlanPage,
    favourite,
    renderPolyline,
    setHasPolylineOverlay,
  ]);
}
