import React, { useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';

import type { CommonAdvisory } from '@airshare/pilot-types';

import { CloseButton } from '../close-button/close-button';
import { AdvisoryLogo } from '../advisory-logo/advisory-logo';
import {
  getAdvisorySubTitle,
  getAdvisoryTitle,
  getAdvisoryPhoneNumber,
} from '../../../lib/advisory-helpers';

import './advisory-modal-item.scss';
import { TextTooltip } from 'airshare-pilot-web-shared';
import { useAppConfig } from '../../../state/app-config';
import AdvisoryContactDetails from '../advisory-details/advisory-contact-details';
import AdvisoryDetailPolicies from '../advisory-details/advisory-detail-policies';
import AdvisoryDetailTowerSchedule from '../advisory-details/advisory-detail-tower-schedule';
import AdvisoryAviationRuleDetails from '../advisory-details/advisory-aviation-rules-details';

export function AdvisoryModalItem({
  advisory,
  expanded,
  timezone,
  handleSelectAdvisory,
  handleMouseover,
}: Readonly<{
  advisory: CommonAdvisory;
  expanded: boolean;
  timezone: string;
  handleSelectAdvisory: (adv: CommonAdvisory) => void;
  handleMouseover: (adv: CommonAdvisory) => void;
}>) {
  const appConfig = useAppConfig();

  const ref = useRef(null);

  useEffect(() => {
    if (expanded && ref?.current?.scrollIntoView) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [expanded, ref]);

  const handleCloseButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    handleSelectAdvisory(null);
  };

  const getTooltipText = (): string => {
    if (expanded) {
      return '';
    }

    if (advisory?.description) {
      return advisory.description.replace(' (linked below)', '');
    }

    const genericType = appConfig?.supportedZoneCodes?.find(
      (x) => x.category === advisory.mapCode
    );

    if (genericType) {
      return `${genericType.name}: ${genericType.description}`;
    }

    return advisory?.name || '';
  };

  if (!advisory) {
    return null;
  }

  return (
    <div
      className="advisory-item-wrapper"
      data-testid="pilot-web:maps-panel:advisory-item-wrapper"
      ref={ref}
    >
      {expanded ? (
        <div
          className="advisory-expanded-item"
          data-testid="pilot-web:maps-panel:advisory-expanded-item"
        >
          <div className="close-button-container">
            <CloseButton
              className="close-button"
              onClick={handleCloseButtonClick}
            />
          </div>

          <div className="logo-container">
            <AdvisoryLogo
              className="advisory-logo"
              logoUrl={advisory.logoUrl}
              code={advisory.mapCode || advisory.code}
            />
          </div>
          <Typography
            className="advisory-name"
            variant="h5"
            data-testid="pilot-web:maps-panel:advisory-modal-name"
          >
            {getAdvisoryTitle(advisory)}
          </Typography>
          {getAdvisorySubTitle(advisory) && (
            <Typography
              className="advisory-site-name"
              variant="subtitle1"
              data-testid="pilot-web:maps-panel:advisory-modal-site-name"
            >
              {getAdvisorySubTitle(advisory)}
            </Typography>
          )}
          {advisory.description && (
            <Typography className="description" variant="body1">
              {advisory.description}
            </Typography>
          )}
          <AdvisoryContactDetails
            address={advisory.address}
            phone={advisory.phone}
            email={advisory.email}
          />
          <AdvisoryAviationRuleDetails
            activity={advisory.activity}
            lowerLimit={advisory.lowerlimit}
            upperLimit={advisory.upperlimit}
            workinghours={advisory.workinghours}
            deviationThresholdHorizontalMetres={
              advisory.deviationThresholdHorizontalMetres
            }
            deviationThresholdVerticalFeet={
              advisory.deviationThresholdVerticalFeet
            }
            startDateTime={advisory.startDateTime}
            endDateTime={advisory.endDateTime}
            timezone={timezone}
          />
          <AdvisoryDetailPolicies policies={advisory.policies} />
          {advisory.type === 'Control Zone' && advisory.schedule ? (
            <AdvisoryDetailTowerSchedule
              towerSchedule={advisory.schedule}
              data-testid="pilot-web:maps-panel:advisory-modal-tower-schedule"
            />
          ) : null}
        </div>
      ) : (
        <TextTooltip title={getTooltipText()} placement="right" arrow>
          <div
            className="advisory-item"
            data-testid="pilot-web:maps-panel:advisory-modal-item"
            onClick={() => {
              handleSelectAdvisory(advisory);
            }}
            onMouseOver={() => {
              handleMouseover(advisory);
            }}
          >
            <AdvisoryLogo
              className="advisory-logo"
              logoUrl={advisory.logoUrl}
              code={advisory.mapCode || advisory.code}
              data-testid="pilot-web:maps-panel:advisory-modal-logo"
            />
            <div className="advisory-name-container">
              <Typography
                className="advisory-name"
                variant="body1"
                data-testid="pilot-web:maps-panel:advisory-modal-name"
              >
                {getAdvisoryTitle(advisory)}
              </Typography>
              {getAdvisorySubTitle(advisory) && (
                <Typography
                  className="advisory-site-name"
                  variant="caption"
                  data-testid="pilot-web:maps-panel:advisory-modal-site-name"
                >
                  {getAdvisorySubTitle(advisory)}
                </Typography>
              )}
              {getAdvisoryPhoneNumber(advisory) && (
                <Typography
                  className="advisory-phone-number"
                  variant="subtitle2"
                  data-testid="pilot-web:maps-panel:advisory-modal-phone-number"
                >
                  {getAdvisoryPhoneNumber(advisory)}
                </Typography>
              )}
            </div>
          </div>
        </TextTooltip>
      )}
    </div>
  );
}
