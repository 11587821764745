import bbox from '@turf/bbox';
import type { Feature, FeatureCollection, Polygon } from '@turf/helpers';

export const defaultAreaStyle: google.maps.Data.StyleOptions = {
  fillColor: '#000000',
  fillOpacity: 0.2,
  strokeColor: '#000000',
  strokeOpacity: 0.4,
  strokeWeight: 2,
};

export const clearFeatures = (dataLayer?: google.maps.Data | null) => {
  if (dataLayer) {
    dataLayer.forEach((feature) => dataLayer.remove(feature));
  }
};

export const renderFlight = (
  dataLayer: google.maps.Data,
  flightArea: FeatureCollection<Polygon> | Feature<Polygon>
) => {
  dataLayer.addGeoJson(flightArea);

  return () => {
    if (dataLayer) {
      dataLayer.forEach((f) => dataLayer.remove(f));
    }
  };
};

export const renderFlightAndCenter = (
  map: google.maps.Map,
  dataLayer: google.maps.Data,
  flightArea: Feature<Polygon>
) => {
  dataLayer.setMap(map);
  renderFlight(dataLayer, flightArea);

  const [swLng, swLat, neLng, neLat] = bbox(flightArea);

  const bounds = new google.maps.LatLngBounds(
    { lat: swLat, lng: swLng },
    { lat: neLat, lng: neLng }
  );

  map.fitBounds(bounds);
};
