import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Props {
  title: string;
  messages: string[];
}

export default function WarningPanel({ messages, title }: Props) {
  return (
    <>
      {messages.length > 0 && (
        <div
          className="warning-panel"
          data-testid="pilot-web:flight-briefing-panel:warning-panel"
        >
          <Alert severity="warning">
            {title}
            {messages.map((message, key) => (
              <div key={key}>
                <Typography variant="subtitle2">{message}</Typography>
              </div>
            ))}
          </Alert>
        </div>
      )}
    </>
  );
}
