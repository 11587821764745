import { useSelector, useDispatch } from 'react-redux';
import type { ComparativeFlightAdvisoryResponse } from '@airshare/external-api-types';
import type { StyledFlightAdvisory } from '@airshare/pilot-types';
import {
  getFlightAdvisories,
  getHighlightedFlightAdvisories,
} from './selectors';
import {
  flightAdvisoriesUpdated,
  highlightedFlightAdvisoriesUpdated,
} from './actions';

export function useFlightAdvisories() {
  return useSelector(getFlightAdvisories);
}

export function useFlightAdvisoriesUpdated() {
  const dispatch = useDispatch();
  return (advisories: ComparativeFlightAdvisoryResponse[]) =>
    dispatch(flightAdvisoriesUpdated(advisories));
}

export function useHighlightedFlightAdvisories() {
  return useSelector(getHighlightedFlightAdvisories);
}

export function useHighlightedFlightAdvisoriesUpdated() {
  const dispatch = useDispatch();
  return (advisories: StyledFlightAdvisory[]) =>
    dispatch(highlightedFlightAdvisoriesUpdated(advisories));
}
