import { useCallback, useContext, useMemo } from 'react';
import type {
  FeatureCollection,
  Feature,
  Polygon,
  LineString,
  Position,
} from 'geojson';
import { DateTime } from 'luxon';
import buffer from '@turf/buffer';
import length from '@turf/length';
import { lineString } from '@turf/helpers';

import {
  AltitudeType,
  StyledSegmentFeatureCollectionJSONView,
  getSegmentId,
  type FlightSegmentPropertiesRequest,
} from '@airshare/external-api-types';

import type { FlightPlanForm } from '~/state/flight-plan/flight-plan-form/types';
import { defaultBufferMetres } from '~/lib/polygon-helpers';
import { GlobalState } from '~/state/GlobalState';
import { DEFAULT_SEGMENT_STYLE } from './segment-helpers';
import {
  useFormState,
  useSetFieldValue,
} from '~/state/flight-plan/flight-plan-form/hooks';

const lineToPolygon = (
  position: Position[],
  bufferMetres: number = defaultBufferMetres()
) => {
  return buffer(lineString(position), bufferMetres, {
    units: 'meters',
  })?.geometry;
};

const updateSegmentPolygons =
  (featureCollection: StyledSegmentFeatureCollectionJSONView) =>
  (waypoints: LineString, bufferMetres: number) => {
    const updatedFeatures = featureCollection?.features?.map((f, i) => {
      return {
        ...f,
        geometry: lineToPolygon(
          [waypoints.coordinates[i], waypoints.coordinates[i + 1]],
          bufferMetres
        ),
      };
    });

    return {
      ...featureCollection,
      features: updatedFeatures,
    };
  };

export const setFormSegmentCollection = (
  setFlightPlanFormField: (field: keyof FlightPlanForm, value: any) => void,
  fields: {
    waypoints?: LineString;
    dateAndTime?: DateTime;
    durationMinutes?: number;
    altitudeFeet?: number;
  },
  bufferMetres: number
): void => {
  const { waypoints, dateAndTime, durationMinutes, altitudeFeet } = fields;

  if (!waypoints) {
    setFlightPlanFormField('waypoints', null);
    setFlightPlanFormField('segmentCollection', null);
    return;
  }

  if (!dateAndTime || !durationMinutes || !altitudeFeet) {
    setFlightPlanFormField('segmentCollection', null);
    return;
  }

  const bufferMinutes = 5;
  const waypointsCount = waypoints.coordinates.length;
  const segmentsCount = waypointsCount - 1;
  const finalLoop = waypointsCount - 2;
  const endDateTime = dateAndTime.plus({ minutes: durationMinutes });

  const totalLengthMeters = length(lineString(waypoints.coordinates), {
    units: 'meters',
  });
  let cummulativePercentOffset = 0;
  let previousEndTime = dateAndTime;

  const features: Feature<Polygon, FlightSegmentPropertiesRequest>[] = [];

  for (let i = 0; i < waypointsCount; i++) {
    if (i > finalLoop) {
      break;
    }

    const lengthMeters = length(
      lineString([waypoints.coordinates[i], waypoints.coordinates[i + 1]]),
      { units: 'meters' }
    );

    const percent = lengthMeters / totalLengthMeters;

    // First segment: starts at dateAndTime, ends at portional end time + 2X {bufferMinutes}
    // Middle segment(s): start {bufferMinutes} before portional start time, end {bufferMinutes} after portional end time
    // Last segment: starts at portional start time - 2X {bufferMinutes}, ends at endDateTime

    let start =
      i === 0
        ? dateAndTime
        : dateAndTime
            .plus({ minutes: durationMinutes * cummulativePercentOffset })
            .minus({ minutes: bufferMinutes * (i === finalLoop ? 2 : 1) });
    let end =
      i === finalLoop
        ? endDateTime
        : dateAndTime
            .plus({
              minutes: durationMinutes * (cummulativePercentOffset + percent),
            })
            .plus({ minutes: bufferMinutes * (i === 0 ? 2 : 1) });

    // Ensure this segment never ends out of order
    if (end < previousEndTime) {
      end = previousEndTime;
    }

    // Ensure all segments are within the overall flight duration
    if (start < dateAndTime) {
      start = dateAndTime;
    }
    if (end > endDateTime) {
      end = endDateTime;
    }

    cummulativePercentOffset += percent;

    const feature: Feature<Polygon, FlightSegmentPropertiesRequest> = {
      type: 'Feature',
      properties: {
        ...DEFAULT_SEGMENT_STYLE,
        id: getSegmentId(i, segmentsCount),
        altitudeType: AltitudeType.AGL,
        minAltitudeFeet: 0,
        maxAltitudeFeet: altitudeFeet,
        startDateTime: start.setZone('UTC').toISO(),
        endDateTime: end.setZone('UTC').toISO(),
      },
      geometry: lineToPolygon(
        [waypoints.coordinates[i], waypoints.coordinates[i + 1]],
        bufferMetres
      ),
    };

    previousEndTime = end;

    features.push(feature);
  }

  // Adjust start times to ensure segments are in order
  let previousStartTime = endDateTime;
  for (let i = segmentsCount - 1; i >= 0; i--) {
    const segment = features[i];
    const start = DateTime.fromISO(segment.properties.startDateTime as string);

    if (start > previousStartTime) {
      segment.properties.startDateTime = previousStartTime
        .setZone('UTC')
        .toISO();
    } else {
      previousStartTime = start;
    }
  }

  const featureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features,
  };

  setFlightPlanFormField('segmentCollection', featureCollection);
};

export const useFormSegmentCollection = () => {
  const { segmentedBufferRadius } = useContext(GlobalState);
  const form = useFormState();
  const setFlightPlanFormField = useSetFieldValue();

  const callback = useCallback(
    (fields: {
      waypoints?: LineString;
      dateAndTime?: DateTime;
      durationMinutes?: number;
      altitudeFeet?: number;
    }) => {
      setFormSegmentCollection(
        setFlightPlanFormField,
        fields,
        segmentedBufferRadius
      );
    },
    [setFlightPlanFormField, segmentedBufferRadius]
  );

  useMemo(() => {
    if (form.waypoints) {
      const updatedFC = updateSegmentPolygons(form.segmentCollection)(
        form.waypoints,
        segmentedBufferRadius
      );
      setFlightPlanFormField('segmentCollection', updatedFC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentedBufferRadius]);

  useMemo(() => {
    const dateTime: DateTime =
      form.dateAndTime instanceof DateTime
        ? form.dateAndTime
        : DateTime.fromJSDate(form.dateAndTime);

    callback({
      waypoints: form.waypoints,
      dateAndTime: dateTime,
      durationMinutes: form.durationMinutes,
      altitudeFeet: form.altitudeFeet,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.waypoints,
    form.dateAndTime,
    form.durationMinutes,
    form.altitudeFeet,
  ]);
};
