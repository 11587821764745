import { useContext, useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import bbox from '@turf/bbox';
import {
  MapHelpers,
  usePosition,
  useIsInTabletMode,
} from 'airshare-pilot-web-shared';
import {
  AdvisorySource,
  type ExtendedFlightResponseBodyV2,
} from '@airshare/pilot-types';

import { useFocussedFlightRequest } from '~/state/flight-requests/hooks';
import { FLIGHT_BRIEFING_PATH, CLEARANCE_PATH } from '~/routes';
import { GlobalState } from '~/state/GlobalState';
import { type Polygon } from '@turf/helpers';

export function useFocussedFlightBriefingMapOrientation(
  google: typeof window.google,
  mapInstance: google.maps.Map | null,
  pathname: string,
  userMarker: google.maps.LatLngLiteral,
  mapHelpers: MapHelpers | null,
  setMapCenter: (center: google.maps.LatLngLiteral) => void
) {
  const usersPosition = usePosition();
  const isInTabletMode = useIsInTabletMode();

  const { setAdvisoryGeometry: setGlobalAdvisoryGeometry } =
    useContext(GlobalState);

  const [lastFocussed, updateLastFocussed] = useState<number | null>(null);
  const [lastWasBriefingPath, setLastWasBriefingPath] =
    useState<boolean>(false);
  const [advisoryGeometry, setAdvisoryGeometryLocal] = useState<Polygon>(null);

  const flightBriefingDetailPathMatch = matchPath(pathname, {
    path: FLIGHT_BRIEFING_PATH,
    exact: true,
    strict: true,
  });
  const isViewingFlightBriefing = flightBriefingDetailPathMatch
    ? flightBriefingDetailPathMatch.isExact
    : false;

  const flightClearancePathMatch = matchPath(pathname, {
    path: CLEARANCE_PATH,
    exact: true,
    strict: true,
  });
  const isViewingFlightClearance = flightClearancePathMatch
    ? flightClearancePathMatch.isExact
    : false;

  const isViewingFlightDetailPanel =
    isViewingFlightBriefing || isViewingFlightClearance;

  const focussedFlightBriefing: ExtendedFlightResponseBodyV2 =
    useFocussedFlightRequest();

  useEffect(() => {
    const { geometry, properties } = focussedFlightBriefing ?? {};

    const pageChanged =
      !lastFocussed ||
      properties?.flightId !== lastFocussed ||
      lastWasBriefingPath !== isViewingFlightBriefing;

    if (
      mapInstance &&
      isViewingFlightDetailPanel &&
      properties &&
      pageChanged
    ) {
      if (geometry) {
        const [swLng, swLat, neLng, neLat] = bbox(geometry);

        const bounds = new google.maps.LatLngBounds(
          { lat: swLat, lng: swLng },
          { lat: neLat, lng: neLng }
        );
        mapHelpers.fitToBoundsWithPadding(
          bounds,
          isViewingFlightBriefing && !isInTabletMode
        );
        setAdvisoryGeometryLocal(geometry as Polygon);
      } else if (usersPosition.longitude && usersPosition.latitude) {
        setMapCenter(userMarker);
      }

      updateLastFocussed(properties.flightId);
      setLastWasBriefingPath(isViewingFlightBriefing);
    }
  }, [
    mapInstance,
    focussedFlightBriefing,
    isViewingFlightBriefing,
    isViewingFlightDetailPanel,
    userMarker,
    setMapCenter,
    google.maps.LatLngBounds,
    isInTabletMode,
    lastFocussed,
    usersPosition,
    mapHelpers,
    lastWasBriefingPath,
  ]);

  // promote advisory geometry up to global as final step
  // setting directly was causing endless loop that locks the map in place
  useEffect(() => {
    setGlobalAdvisoryGeometry(
      advisoryGeometry ? AdvisorySource.FlightBriefing : AdvisorySource.None,
      advisoryGeometry
    );
  }, [advisoryGeometry, setGlobalAdvisoryGeometry]);
}
