import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import './read-only-field.scss';

const useLabelStyles = makeStyles({
  root: {
    marginBottom: '0.8rem',
    color: 'var(--color-shade-primary-darkest)',
  },
});

const useValueStyles = makeStyles({
  root: {
    marginBottom: '4rem',
    color: 'var(--color-shade-primary-darker)',
  },
});

interface Props {
  value?: string | number;
  label: string;
  className?: string;
  testId?: string;
}

export function ReadOnlyField({
  value,
  label = '',
  className = null,
  testId = 'field',
}: Readonly<Props>) {
  const labelClasses = useLabelStyles();
  const valueClasses = useValueStyles();

  return (
    <div className={cn('read-only-field', { [className]: className })}>
      <Typography
        variant="subtitle2"
        component="label"
        className={labelClasses.root}
        data-testid={`${testId}-label`}
      >
        {label}
      </Typography>
      <Typography
        data-value={value}
        variant="body2"
        component="p"
        className={valueClasses.root}
        data-testid={`${testId}-value`}
      >
        {value}
      </Typography>
    </div>
  );
}
