import { MapStyleCode, MapTypeId } from './actions';
import { MapOptionsState } from './reducers';

const defaultStyles: MapOptionsState = {
  [MapStyleCode.Standard]: {
    mapId: '5bce8e7814a65556',
    mapTypeId: MapTypeId.Roadmap,
  },
  [MapStyleCode.Gray]: {
    mapId: '6b1e180b4af3ee31',
    mapTypeId: MapTypeId.Roadmap,
  },
  [MapStyleCode.Dark]: {
    mapId: 'b1ed08825a7eb1d0',
    mapTypeId: MapTypeId.Roadmap,
  },
  [MapStyleCode.Terrain]: {
    mapId: 'b1ed08825a7eb1d0',
    mapTypeId: MapTypeId.Terrain,
  },
  [MapStyleCode.Satellite]: {
    mapId: 'b1ed08825a7eb1d0',
    mapTypeId: MapTypeId.Satellite,
  },
  [MapStyleCode.Hybrid]: {
    mapId: 'b1ed08825a7eb1d0',
    mapTypeId: MapTypeId.Hybrid,
  },
};

export default defaultStyles;
