import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTransition, animated } from 'react-spring';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useShouldShowShieldedModal } from './should-show-shielded-modal.hook';
import shieldedOperationImage from './images/shielded-operation.png';

import './info-modal.scss';

function InfoModalComponent({ location }: RouteComponentProps) {
  const [isHidden, updateIsHidden] = useState(false);
  const shouldShowShieldedModal = useShouldShowShieldedModal();

  useEffect(() => {
    if (shouldShowShieldedModal) {
      updateIsHidden(false);
    }
  }, [shouldShowShieldedModal]);

  const transitions = useTransition(
    !isHidden &&
      shouldShowShieldedModal &&
      location.pathname.includes('flight-plan'),
    null,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    }
  );

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} className="info-modal">
              <div className="container">
                <div className="title">
                  <Typography variant="h4">Shielded Operation</Typography>
                </div>
                <div className="body">
                  <div className="text">
                    <Typography variant="h5">
                      For Shielded operations, you must:
                    </Typography>
                    <br />
                    <br />

                    <ul className="list">
                      <li>
                        <Typography variant="body1">
                          Fly within 100m of barrier which separates you from
                          aerodromes.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Fly below the height of the barrier.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Fly outside of aerodromes boundaries.
                        </Typography>
                      </li>
                    </ul>

                    <br />
                    <Typography variant="body1">
                      Before using the shielded operation provisions, make sure
                      you familiarise yourself with the rules at{' '}
                      <a href="https://www.airshare.co.nz/rules">
                        https://www.airshare.co.nz/rules.
                      </a>
                    </Typography>
                    <br />
                    <ButtonBase
                      className="button"
                      onClick={() => updateIsHidden(true)}
                      data-testid="pilot-web:shielded-operation-got-it-button"
                    >
                      Got it!
                    </ButtonBase>
                  </div>
                  <img
                    alt="Shielded Operation"
                    className="image"
                    src={shieldedOperationImage}
                  />
                </div>
              </div>
            </animated.div>
          )
      )}
    </>
  );
}

export const InfoModal = withRouter(InfoModalComponent);
