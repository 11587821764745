import { StyledAreaResponse } from 'argus-common/interfaces';
import { useEffect, useState } from 'react';
import {
  useSelectFlightPathArea,
  useSelectedFlightPathArea,
} from '../../../../../state/flight-plan/hooks';
import { getAuthedAreasForPilot } from '../../../../../pilot-api-client';

export default function useManagedAreaFlightArea(form: any) {
  const [authedFlightPaths, setAuthedFlightPaths] = useState<
    StyledAreaResponse[] | undefined
  >();
  const selectFlightPathArea: (payload: StyledAreaResponse | null) => void =
    useSelectFlightPathArea();
  const selectedFlightPathArea: StyledAreaResponse | null =
    useSelectedFlightPathArea();

  const handleSelectFlightPathArea = (id: string) => {
    if (id === 'None') {
      selectFlightPathArea(null);
    } else {
      const path = authedFlightPaths?.find((fp) => fp.id === id);
      if (path) {
        selectFlightPathArea(path);
      } else {
        selectFlightPathArea(null);
      }
    }
  };

  useEffect(() => {
    const updateAuthedFlightPaths = async (id?: string) => {
      const possibleAreas =
        id && id !== 'undefined' ? await getAuthedAreasForPilot(id) : [];

      if (possibleAreas?.length) {
        setAuthedFlightPaths(possibleAreas);
      } else {
        setAuthedFlightPaths([]);
        selectFlightPathArea(null);
      }
    };

    if (form.pilotId) {
      updateAuthedFlightPaths(form.pilotId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.pilotId, setAuthedFlightPaths]);

  return {
    authedFlightPaths,
    selectedFlightPathArea,
    handleSelectFlightPathArea,
  };
}
