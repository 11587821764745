import React from 'react';
import {
  Switch,
  matchPath,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import {
  useIsInTabletMode,
  useMobileDetection,
} from 'airshare-pilot-web-shared';

import { UavDetail } from './panels/uav-detail/uav-detail';
import { OrgPilotDetail } from './panels/org-pilot-detail/org-pilot-detail';
import { NotamDetail } from './panels/notam-detail/notam-detail';
import { AUTH_PATH } from '../auth-modal/auth-modal';
import { TrackedRoute } from '../shared/tracked-route/tracked-route';
import {
  UAV_DETAIL_PATH,
  ADD_UAV_PATH,
  NOTAM_DETAIL_PATH,
  DETAIL_PANEL_PATH,
  ORG_PILOTS_DETAIL_EDIT_PATH,
  ORG_PILOTS_DETAIL_PATH,
} from '../../routes';

import './detail-panel.scss';

function DetailPanelComponent({ location }: RouteComponentProps) {
  const isInTabletMode = useIsInTabletMode();
  const isMobile = useMobileDetection();
  const pathMatch = matchPath(location.pathname, {
    path: DETAIL_PANEL_PATH,
    exact: true,
    strict: true,
  });
  const isVisible = pathMatch ? pathMatch.isExact : false;

  const isAuth = matchPath(location.pathname, {
    path: AUTH_PATH,
    exact: false,
  });

  const transitions = useTransition(isVisible && !isAuth, null, {
    from: { top: 3000, display: 'none' },
    enter: [{ display: 'block' }, { top: 0 }],
    leave: [{ top: 3000 }, { display: 'none' }],
  });

  if (isInTabletMode) return null;
  if (isMobile && isAuth) return null;

  const list = transitions.map(
    ({ item, props, key }) =>
      item && (
        <animated.div style={props} key={key} className="detail-panel">
          <div className="panel-container">
            <Switch>
              {/* Dont pass component as child props... strange Switch behaviour for path match */}
              <TrackedRoute
                path={ADD_UAV_PATH}
                render={() => <UavDetail key={ADD_UAV_PATH} />}
              />
              <TrackedRoute
                path={UAV_DETAIL_PATH}
                render={() => <UavDetail key={UAV_DETAIL_PATH} />}
              />
              <TrackedRoute
                path={ORG_PILOTS_DETAIL_EDIT_PATH}
                render={() => (
                  <OrgPilotDetail key={ORG_PILOTS_DETAIL_EDIT_PATH} />
                )}
              />
              <TrackedRoute
                path={ORG_PILOTS_DETAIL_PATH}
                render={() => <OrgPilotDetail key={ORG_PILOTS_DETAIL_PATH} />}
              />
              <TrackedRoute path={NOTAM_DETAIL_PATH} component={NotamDetail} />
            </Switch>
          </div>
        </animated.div>
      )
  );

  return <>{list}</>;
}

export const DetailPanel = withRouter(DetailPanelComponent);
