import { useMemo, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { Feature, FeatureCollection, Polygon } from 'geojson';

import { AIRSPACE_ACTIVITY_PATH } from '../../../routes';

export const useHighlightActiveArea = (
  pathname: string,
  areaOfVisibility?: FeatureCollection<Polygon>,
  map?: google.maps.Map,
  google?: any
) => {
  const [activeVisibleArea, setActiveVisibleArea] =
    useState<google.maps.Polygon | null>(null);
  const [mapId, setMapId] = useState<string>('');
  const [lastPath, setLastPath] = useState<string>(pathname);

  const isViewingAirspaceActivity = matchPath(pathname, {
    path: AIRSPACE_ACTIVITY_PATH,
    exact: true,
  })?.isExact;
  const isRerenderRequired = !matchPath(lastPath, {
    path: AIRSPACE_ACTIVITY_PATH,
    exact: true,
  })?.isExact;

  useMemo(() => {
    // @ts-ignore
    const currMapId = map?.mapId as string | undefined;
    if (lastPath !== pathname) {
      setLastPath(pathname);
    }
    if (
      (currMapId !== mapId ||
        activeVisibleArea === null ||
        isRerenderRequired) &&
      areaOfVisibility?.features.length &&
      currMapId &&
      google &&
      isViewingAirspaceActivity
    ) {
      setMapId(currMapId);
      const highlightedArea = new google.maps.Polygon({
        strokeWeight: 0,
        fillColor: 'gray',
        fillOpacity: 0.9,
        zIndex: 30,
        map,
      });
      const earthCoords = [
        new google.maps.LatLng(-85.1054596961173, -180),
        new google.maps.LatLng(85.1054596961173, -180),
        new google.maps.LatLng(85.1054596961173, 180),
        new google.maps.LatLng(-85.1054596961173, 180),
        new google.maps.LatLng(-85.1054596961173, 0),
      ];
      const highlightedAreasCoordinates = areaOfVisibility.features.reduce(
        (acc: google.maps.LatLng[], f: Feature<Polygon>) =>
          [
            ...acc,
            ...f.geometry.coordinates.map((a) =>
              a.map(([y, x]) => ({ lat: x, lng: y }))
            ),
          ] as google.maps.LatLng[],
        []
      );
      highlightedArea.setPaths([earthCoords, ...highlightedAreasCoordinates]);
      setActiveVisibleArea(highlightedArea);
    }
    if (!isViewingAirspaceActivity) {
      activeVisibleArea?.setMap(null);
    }
    return () => {
      activeVisibleArea?.setMap(null);
    };
  }, [
    activeVisibleArea,
    areaOfVisibility,
    map,
    mapId,
    google,
    isViewingAirspaceActivity,
    isRerenderRequired,
    lastPath,
    pathname,
  ]);
};
