export const UavsCreationStatus = Object.freeze({
  IDLE: 'IDLE',
  UAV_CREATION_IN_PROGRESS: 'UAV_CREATION_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const UavsUpdateStatus = Object.freeze({
  IDLE: 'IDLE',
  UAV_UPDATE_IN_PROGRESS: 'UAV_UPDATE_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const UavsDeletionStatus = Object.freeze({
  IDLE: 'IDLE',
  UAV_DELETION_IN_PROGRESS: 'UAV_DELETION_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});

export const UavsFetchStatus = Object.freeze({
  IDLE: 'IDLE',
  UAV_FETCH_IN_PROGRESS: 'UAV_FETCH_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
});
