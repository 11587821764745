import * as yup from 'yup';
import { DateTime } from 'luxon';

import { createFormReducer } from 'airshare-web-utils/redux-forms';
import { FlightRule, UasOperationCategory } from '@airshare/pilot-types';

import { actions, FLIGHT_PLAN_FORM_KEY } from './actions';
import { PathMode } from '../constants';
import { FlightPlanForm } from './types';
import { LineString } from 'geojson';

const maxDurationEnvVar = process.env.FLIGHT_REQUEST_MAX_DURATION_MINS || '';
const maxDurationMins = parseInt(maxDurationEnvVar)
  ? parseInt(maxDurationEnvVar)
  : 720;

const coordinateSchema = yup.object().shape({
  lat: yup.number().required('Required'),
  lng: yup.number().required('Required'),
});

const waypointSchema: yup.SchemaOf<LineString> = yup
  .object()
  .nullable()
  .optional()
  .shape({
    type: yup.string().required().oneOf(['LineString']),
    coordinates: yup
      .array()
      .of(yup.array().of(yup.number().required()).required().min(2).max(2))
      .required()
      .min(2)
      .max(31),
  })
  .test({
    name: 'maxWaypoints',
    test: (value) => !value?.coordinates || value?.coordinates.length <= 31,
    // limit from pilot-web to avoid timeout due to pilot -> external -> rules api
    // 31 waypoints = 30 segments
    message: 'Maximum of 30 segments allowed',
  }) as any as yup.SchemaOf<LineString>;

export const schema = yup.object().shape({
  managedAreaId: yup.string().nullable(),
  nickname: yup.string().when('isFavourite', {
    is: true,
    then: yup
      .string()
      .required('A nickname is required to favourite a flight request'),
  }),
  isFavourite: yup.boolean().required('Required'),
  altitudeFeet: yup.number().min(0).required('Required'),
  isShielded: yup.boolean().required('Required'),
  flightPurpose: yup.string().required('Required'),
  dateAndTime: yup
    .date()
    .nullable()
    .test({
      name: 'isFlightTimeInTheFuture',
      test: (value) => DateTime.fromJSDate(value) >= DateTime.now(),
      message: 'Your flight plan must be in the future',
    })
    .required('Please select the date & time of your flight')
    .typeError('Please select the date & time of your flight'),
  durationMinutes: yup
    .number()
    .min(1, 'Please select a duration of at least 1 minute')
    .max(
      maxDurationMins,
      `Please select a duration of at most ${maxDurationMins} minutes`
    )
    .required('Required')
    .typeError('A valid flight duration value is required'),
  pilotId: yup.string().typeError('Pilot is required').required('Required'),
  pilotName: yup.string().typeError('Pilot is required').required('Required'),
  pilotEmail: yup.string().typeError('Pilot is required').required('Required'),
  uavId: yup
    .string()
    .typeError('UAV is required')
    .required('A UAV is required'),
  rule: yup
    .string()
    .oneOf([FlightRule.PART_101, FlightRule.PART_102])
    .required('Required'),
  uasOperationCategory: yup.string().oneOf(Object.values(UasOperationCategory)),
  isCameraInUse: yup.boolean().required('Required'),
  isPriorityFlight: yup.boolean(),
  requiresAdditionalInformation: yup.boolean().required('Required'),
  hasCertifiedTransmitter: yup.string(),
  hasVhfRadioContact: yup.string(),
  descriptionOfOperatingArea: yup
    .string()
    .when('requiresAdditionalInformation', {
      is: true,
      then: yup
        .string()
        .required(
          'When flying unshielded in a controlled zone a description of the operating area is required'
        ),
    }),
  procedureMeasureAltitude: yup.string(),
  emergencyProcedure: yup.string(),
  otherInformation: yup.string(),
  coordinates: yup
    .array()
    .of(coordinateSchema)
    .when('pathMode', {
      is: PathMode.CIRCLE,
      then: yup
        .array()
        .of(coordinateSchema)
        .min(1)
        .max(1)
        .typeError('Please select your intended flight area on the map')
        .required(
          'Flight areas of path mode type Circle should have a single coordinate representing the center'
        ),
    })
    .when('pathMode', {
      is: PathMode.POLYGON,
      then: yup
        .array()
        .of(coordinateSchema)
        .min(3)
        .typeError('Please select your intended flight area on the map')
        .required(
          'Flight areas of path mode type Polygon should have at least three coordinates representing the vertices'
        ),
    })
    .when('pathMode', {
      is: PathMode.SEGMENTED,
      then: yup
        .array()
        .of(coordinateSchema)
        .min(3)
        .typeError('Please select your intended flight area on the map')
        .required(
          'Flight areas of path mode type Segmented should have generated coordinates representing the buffered area around the line segments'
        ),
    })
    .typeError(
      'Please draw, select or upload your intended flight area on the map'
    ),
  pathMode: yup
    .string()
    .nullable()
    .when('managedAreaId', {
      is: (managedAreaId: string | null) => managedAreaId === null,
      then: yup.string().oneOf(Object.values(PathMode)).required('Required'),
    }),
  radius: yup.number().when('pathMode', {
    is: PathMode.CIRCLE,
    then: yup
      .number()
      .min(1)
      .required('Flight area radius required for circles'),
  }),
  certificationNumber: yup
    .string()
    .nullable()
    .when(['altitudeFeet', 'rule'], {
      is: (altitudeFeet: number, rule: string) =>
        altitudeFeet > 400 && rule === FlightRule.PART_102,
      then: yup
        .string()
        .required('A 102 certification number is required to fly over 400 ft'),
    }),
  waypoints: yup.object().when('pathMode', {
    is: PathMode.SEGMENTED,
    then: waypointSchema.required(),
    otherwise: waypointSchema.nullable(),
  }),
});

const defaultState: FlightPlanForm = {
  managedAreaId: null,
  nickname: '',
  isFavourite: false,
  altitudeFeet: 100,
  isShielded: false,
  flightPurpose: 'Aerial Filming',
  dateAndTime: null,
  durationMinutes: 30,
  pilotId: '',
  pilotName: '',
  pilotEmail: '',
  uavId: '',
  rule: FlightRule.PART_101,
  isCameraInUse: true,
  isPriorityFlight: false,
  requiresAdditionalInformation: false,
  hasCertifiedTransmitter: false,
  hasVhfRadioContact: false,
  descriptionOfOperatingArea: '',
  procedureMeasureAltitude: '',
  emergencyProcedure: '',
  otherInformation: '',
  coordinates: null,
  pathMode: null,
  radius: 0,
  certificationNumber: '',
  certificationType: '',
  flightAreaSource: null,
  waypoints: null,
  segmentCollection: null,
  uasOperationCategory: UasOperationCategory.OPEN,
};

export const flightPlanFormReducer = {
  [FLIGHT_PLAN_FORM_KEY]: createFormReducer<FlightPlanForm>(
    actions,
    defaultState,
    schema
  ),
};
