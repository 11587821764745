import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  flightRequestsFetchRequested,
  flightRequestsFetchFocussedRequested,
  flightRequestsFocussedReset,
  flightRequestsCancelStatusReset,
  flightRequestsCancelRequested,
  flightRequestsActivateFlightRequested,
  flightRequestsEndStatusReset,
  flightRequestsEndRequested,
  flightRequestsDeleteFavouriteRequested,
  flightRequestsActivateFlightStatusReset,
} from './actions';
import {
  getFlightRequestsPageByIndex,
  getFlightRequestsFetchStatus,
  getFlightRequestsPages,
  getFlightRequestsMeta,
  getFlightRequestsFocussed,
  getFlightRequestsFetchFocussedStatus,
  getFlightRequestsCancelStatus,
  getFlightActivationStatus,
  getFlightRequestsEndStatus,
  getCurrentFlightRequests,
  getActivatedFlightRequests,
  getPastFlightRequests,
  getFavourites,
} from './selectors';
import { FilterValue, FlightRequestsFetchStatus } from './constants';

export function useFetchFlightRequestsPage() {
  const dispatch = useDispatch();

  return () =>
    dispatch(flightRequestsFetchRequested({ payload: { pageIndex: 0 } }));
}

export function useFlightRequestsPages() {
  const pages = useSelector(getFlightRequestsPages);

  return pages;
}

export function useFavourites() {
  const pages = useSelector(getFavourites);

  return pages;
}

export function useDeleteFavourite() {
  const dispatch = useDispatch();

  return (id: string) =>
    dispatch(flightRequestsDeleteFavouriteRequested({ id }));
}

export function useCurrentFlightRequests() {
  const pages = useSelector(getCurrentFlightRequests);

  return pages;
}

export function usePastFlightRequests() {
  const pages = useSelector(getPastFlightRequests);
  return pages;
}

export function useActivatedFlightRequests() {
  const pages = useSelector(getActivatedFlightRequests);
  return pages;
}

export function useFlightRequestsMeta(filtervalue: FilterValue) {
  const meta = useSelector(getFlightRequestsMeta);

  return meta ? meta[filtervalue] : null;
}

export function useFlightRequestsTotalRecords(filtervalue: FilterValue) {
  const meta = useFlightRequestsMeta(filtervalue);

  return meta ? meta.totalRecords : null;
}

export function useFlightRequestsTotalPages(filtervalue: FilterValue) {
  const meta = useFlightRequestsMeta(filtervalue);

  return meta ? Math.ceil(meta.totalRecords / meta.pageSize) : null;
}

export function useFlightRequestsPageByIndex(index: number) {
  const flightRequestsPage = useSelector(getFlightRequestsPageByIndex(index));

  return flightRequestsPage;
}

export function useFlightRequestsFetchStatus(): FlightRequestsFetchStatus {
  const fetchStatus = useSelector(getFlightRequestsFetchStatus);

  return fetchStatus;
}

export function useFlightRequestsStatusPromise() {
  const flightRequestsFetchStatus = useFlightRequestsFetchStatus();
  const [statusPromise, setStatusPromise] = useState(null);
  const resolveRef = useRef<any>();
  const rejectRef = useRef<any>();

  useEffect(() => {
    switch (flightRequestsFetchStatus) {
      case FlightRequestsFetchStatus.FLIGHT_REQUESTS_FETCH_IN_PROGRESS: {
        const promise = new Promise((resolve, reject) => {
          resolveRef.current = resolve;
          rejectRef.current = reject;
        });

        setStatusPromise(promise);
        break;
      }

      case FlightRequestsFetchStatus.SUCCESS:
        if (resolveRef.current) resolveRef.current();
        break;

      case FlightRequestsFetchStatus.FAILED:
        if (rejectRef.current) rejectRef.current();
        break;

      case FlightRequestsFetchStatus.IDLE:
      default:
        setStatusPromise(null);
        resolveRef.current = null;
        rejectRef.current = null;
    }
  }, [flightRequestsFetchStatus]);

  return statusPromise;
}

export function useFetchFlightRequestsFocussed() {
  const dispatch = useDispatch();

  return (payload: { id: string; resetState?: false }) =>
    dispatch(flightRequestsFetchFocussedRequested(payload));
}

export function useFocussedFlightRequest() {
  const focussed = useSelector(getFlightRequestsFocussed);

  return focussed;
}

export function useResetFocussedFlightRequest() {
  const dispatch = useDispatch();

  return () => dispatch(flightRequestsFocussedReset());
}

export function useFocussedFlightRequestFetchStatus() {
  const fetchFocussedStatus = useSelector(getFlightRequestsFetchFocussedStatus);

  return fetchFocussedStatus;
}

export function useCancelFlightRequest() {
  const dispatch = useDispatch();

  return (payload: { id: string }) =>
    dispatch(flightRequestsCancelRequested(payload));
}

export function useActivateFlight() {
  const dispatch = useDispatch();

  return (payload: { id: string }) =>
    dispatch(flightRequestsActivateFlightRequested(payload));
}

export function useCancelStatus() {
  const cancelStatus = useSelector(getFlightRequestsCancelStatus);

  return cancelStatus;
}

export function useActivationStatus() {
  const status = useSelector(getFlightActivationStatus);
  return status;
}

export function useResetCancelStatus() {
  const dispatch = useDispatch();

  return () => dispatch(flightRequestsCancelStatusReset());
}

export function useEndFlightRequest() {
  const dispatch = useDispatch();

  return (payload: { id: string }) =>
    dispatch(flightRequestsEndRequested(payload));
}

export function useEndStatus() {
  const cancelStatus = useSelector(getFlightRequestsEndStatus);

  return cancelStatus;
}

export function useResetEndStatus() {
  const dispatch = useDispatch();

  return () => dispatch(flightRequestsEndStatusReset());
}

export function useResetActivationStatus() {
  const dispatch = useDispatch();

  return () => dispatch(flightRequestsActivateFlightStatusReset());
}
