export default `<svg width="24" height="24" version="1.1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 36 36">
<filter id='shadow' color-interpolation-filters="sRGB">
 <feDropShadow dx="1" dy="1" stdDeviation="0.5" flood-opacity="0.6"/>
</filter>

<g class="layer" filter="url(#shadow)">
<path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
fill="#f57c00" opacity="0.9" id="svg_1" stroke="#000000" stroke-width="0.3"
/>
</g>
</svg>`;
