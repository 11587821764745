import React from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import { INFORMATION_PATH } from '../../../routes';
import { useIsAuthenticated } from '../hooks/is-authenticated.hook';
import { TrackedRoute } from '../tracked-route/tracked-route';

interface Props extends RouteComponentProps {
  component: any;
  path: string;
  exact?: boolean;
}

function ProtectedRouteComponent({ component: Component, ...rest }: Props) {
  const isAuthenticated = useIsAuthenticated();
  const redirectUrl = get(rest, 'location.pathname', INFORMATION_PATH);
  const referrer = get(rest, 'location.state.referrer', INFORMATION_PATH);
  const relativeSigninRoute = `${referrer}/signin`;
  const pathname =
    referrer.includes('signin') || referrer.includes('signup')
      ? referrer
      : relativeSigninRoute;

  return (
    <TrackedRoute {...rest}>
      {isAuthenticated ? (
        <Component />
      ) : (
        <Redirect to={{ pathname, state: { referrer, redirectUrl } }} />
      )}
    </TrackedRoute>
  );
}

export const ProtectedRoute = withRouter(ProtectedRouteComponent);
