import { call, put, takeEvery, fork, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import ReactGA from 'react-ga';
import type { Feature, Polygon } from '@turf/helpers';

import { pilotAPI } from '../../pilot-api-client/api';
import { getFlightBriefingPath, FLIGHT_PLAN_EDIT_PATH } from '~/routes';
import {
  CREATION_REQUESTED,
  flightPlanCreationSucceeded,
  flightPlanCreationFailed,
  VALIDATION_REQUESTED,
  flightPlanValidationSucceeded,
  flightPlanValidationFailed,
  flightPlanCreationStatusReset,
  flightPlanFavouriteReset,
  flightPlanEditSucceeded,
  FLIGHT_PLAN_EDIT_REQUESTED,
  flightPlanEditReset,
  UPDATE_REQUESTED,
  flightPlanUpdateSucceeded,
  flightPlanUpdateReset,
  flightPlanUploadedFlightAreaReset,
  flightPlanFlightPathAreaReset,
} from './actions';
import { actionCreators as flightPlanFormActionCreators } from './flight-plan-form/actions';
import {
  type PreValidateFlightAreaState,
  preValidateFlightAreaSaga,
} from './pre-validate-flight-area/saga';
import { fetchFlightRequests } from '../flight-requests/saga';
import {
  FlightPlanCreationStatus,
  FlightPlanEditStatus,
  FlightPlanUpdateStatus,
  FlightPlanValidationStatus,
} from './constants';
import type { FlightPlanForm } from './flight-plan-form/types';
import type { FormState } from 'airshare-web-utils/redux-forms/reducer';

export interface FlightPlanState {
  selectedFavourite: any | null;
  isEditingFavouriteFlightArea: boolean;
  creationStatus: FlightPlanCreationStatus;
  validationStatus: FlightPlanValidationStatus;
  preValidateFlightArea: PreValidateFlightAreaState;
  validationSuccess: any | null;
  editFlightPlan: FlightPlanEditStatus | any;
  selectedFlightPathArea: any;
  uploadedFlightArea: Feature<Polygon> | null;
  updateStatus: FlightPlanUpdateStatus;
  flightPlanForm: FormState<FlightPlanForm>;
}

function* validateFlightPlan({ payload }: { payload: any }) {
  try {
    const { flightId, ...form } = payload;
    const { data } = yield call(
      pilotAPI.post,
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      `/v2/flight-requests/${flightId ? `${flightId}/` : ''}validate`,
      form
    );

    yield put(flightPlanValidationSucceeded(data));
  } catch (error) {
    const defaultError =
      'We were unable to validate your flight. Please try again later';

    let errorMessage = get(error, 'response.data.message');
    const errors: { [key: string]: string } = get(
      error,
      'response.data.errors',
      undefined
    );

    if (errorMessage === 'Declined') {
      errorMessage = 'Your requested flight area has been declined';
    }

    if (!errorMessage && errors) {
      const errorDetails = Object.entries(errors).map((errorObj) => {
        const [key, value] = errorObj;
        return `${key}: ${value}`;
      });
      errorMessage = errorDetails.join(', ');
    }

    yield put(
      flightPlanFormActionCreators.formErrored({
        message: errorMessage || defaultError,
      })
    );
    yield put(flightPlanValidationFailed(error));
  }
}

const getFlightRequestEndpoint = 'v2/flight-requests';

function* createFlightPlan({ payload }: { payload: any }) {
  try {
    const { data } = yield call(pilotAPI.post, '/v2/flight-requests', payload);

    yield put(flightPlanCreationSucceeded(data));

    yield put(flightPlanFavouriteReset());
    yield put(flightPlanUploadedFlightAreaReset());
    yield put(flightPlanFlightPathAreaReset());

    // @ts-ignore
    yield put(push(flightPlanFormActionCreators.formValidationReset()));
    // @ts-ignore
    yield put(push(flightPlanCreationStatusReset()));
    yield put(flightPlanFormActionCreators.formCleared());
    yield put(push(getFlightBriefingPath(data.flightId)));
    yield fetchFlightRequests({ payload: { pageIndex: 0 } });
    ReactGA.event({
      category: 'FlightRequest',
      action: 'FlightRequestCreated',
    });
  } catch (error) {
    const errors = get(error, 'response.data', []);

    let message = Array.isArray(error) ? errors.join(', ') : errors;

    if (!errors.length) {
      message = 'Flight request failed, please resubmit.';
    }

    yield put(flightPlanFormActionCreators.formErrored({ message }));
    yield put(flightPlanCreationFailed(error.message));
  }
}

function* clearFlightPlanFormState({
  payload: { location },
}: {
  payload: { location: any };
}) {
  try {
    const editFlightPlanPathMatch = matchPath(location.pathname, {
      path: FLIGHT_PLAN_EDIT_PATH,
      exact: true,
    });

    const isEditingFlightPlanForm = editFlightPlanPathMatch?.isExact;

    if (!isEditingFlightPlanForm) {
      //put an action to clear the state for the flight plan that was being edited
      yield put(flightPlanEditReset());
    }
  } catch (e) {
    //
  }
}

function* retrieveFlightPlan({ payload }: { payload: string }) {
  const { data } = yield call(
    pilotAPI.get,
    `${getFlightRequestEndpoint}/${payload}?version=2.1`
  );
  yield put(flightPlanEditSucceeded(data));
}

function* updateFlightPlan({ payload }: { payload: any }) {
  try {
    yield call(
      pilotAPI.put,
      `/v2/flight-requests/${payload.flightId}`,
      payload
    );

    yield put(flightPlanUpdateSucceeded());
    //* Allow for completion animation before routing

    yield put(flightPlanUpdateReset());
    ReactGA.event({
      category: 'FlightRequest',
      action: 'FlightRequestUpdated',
    });
  } catch (error) {
    yield put(flightPlanUpdateReset());
    const errors = get(error, 'response.data', []);

    let message = Array.isArray(error)
      ? errors.join(', ')
      : errors.message
        ? errors.message
        : errors;

    if (!errors) {
      message = 'Flight request failed, please resubmit.';
    }

    yield put(flightPlanFormActionCreators.formErrored({ message }));
  }
}

export function* flightPlanSaga() {
  yield fork(preValidateFlightAreaSaga);
  yield takeEvery(VALIDATION_REQUESTED as any, validateFlightPlan);
  yield takeEvery(CREATION_REQUESTED as any, createFlightPlan);
  yield takeEvery(LOCATION_CHANGE as any, clearFlightPlanFormState);
  yield takeLatest(FLIGHT_PLAN_EDIT_REQUESTED as any, retrieveFlightPlan);
  yield takeLatest(UPDATE_REQUESTED as any, updateFlightPlan);
}
