import * as React from 'react';
import cn from 'classnames';

interface Props {
  onClick: () => void;
  actionDescription?: string;
  className?: string;
  size?: 'large' | 'small';
}

import './back-button.scss';

export function BackButton({
  onClick = () => {},
  className = null,
  size = 'large',
  actionDescription = 'Back',
}: Readonly<Props>) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn('back-button', {
        [className]: Boolean(className),
        [size]: Boolean(size),
      })}
      data-testid="pilot-web:back-button"
    >
      {actionDescription}
    </button>
  );
}
