import type {
  ActivationEligibilityResponse,
  ActivationResponseBody,
} from '@airshare/pilot-types';

import { pilotAPI } from './api';

export const getActivationEligibility = async (flightId: string) =>
  pilotAPI
    .get<ActivationEligibilityResponse>(
      `/v2/flight-requests/${flightId}/activation-eligibility`
    )
    .then((resp) => {
      return resp.data;
    })
    .catch(() => ({
      eligible: false,
      atcHasDigitalAuthEnabled: false,
      isActivatable: false,
      errors: [
        `Unable to determine digital clearance eligibility for flight ${flightId}.`,
      ],
    }));

export const requestDigitalActivation = async (flightId: string) =>
  pilotAPI.put<ActivationResponseBody>(
    `/v2/flight-requests/${flightId}/request-activation`
  );
