import { useEffect, MutableRefObject, useRef, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { useInterval } from 'airshare-pilot-web-shared';
import type { NotamsResponseWithLocation } from '@airshare/pilot-types';

import notamIcon from '../images/notam-inverted.png';
import { getNotams } from '../../../pilot-api-client';

const FULL_WORLD_COORDINATES = {
  ne: '180,90',
  sw: '-180,-90',
  zoom: 1,
};
const NOTAMS_REFRESH_INTERVAL = 35000;

export function useNotamsMarkers(
  google: any,
  mapInstance: google.maps.Map,
  showNotamLayer: boolean,
  setShowNotamLayer: (show: boolean) => void,
  pathname: string,
  onNotamClick?: (id: string) => void
) {
  const [notams, setNotams] = useState<NotamsResponseWithLocation[]>([]);
  const notamMarkersRef: MutableRefObject<Map<string, any>> = useRef(new Map());
  const notamAreasRef: MutableRefObject<Map<string, any>> = useRef(new Map());

  const notamDetailPathMatch = matchPath<{ id: string }>(pathname, {
    path: '/notam/:id/(signin|signup)?/(activate|reset-password)?',
    exact: true,
    strict: true,
  });

  const selectedId = notamDetailPathMatch
    ? notamDetailPathMatch.params.id
    : null;

  async function loadNotams() {
    try {
      const notamsList = await getNotams(FULL_WORLD_COORDINATES);
      if ((notamsList as NotamsResponseWithLocation[])?.length > 0) {
        setNotams(notamsList as NotamsResponseWithLocation[]);
      }
    } catch {
      //
    }
  }

  useEffect(() => {
    if (google && mapInstance) {
      loadNotams();
    }
  }, [google, mapInstance]);

  useEffect(() => {
    if (selectedId) {
      // Always show notam layer when a detail notam is selected
      setShowNotamLayer(true);
    }
  }, [selectedId, setShowNotamLayer]);

  useInterval(() => {
    loadNotams();
  }, NOTAMS_REFRESH_INTERVAL);

  useEffect(() => {
    if (google && mapInstance && notams) {
      if (!showNotamLayer) {
        hideAll(notamAreasRef, notamMarkersRef);
        return;
      }
      hideNotInView(notamAreasRef, notams, notamMarkersRef);

      const icon = getIcon(mapInstance, google);
      notams?.forEach((notam) => {
        if (selectedId && selectedId === notam.id) {
          showMarker(
            mapInstance,
            google,
            notam,
            notamMarkersRef,
            icon,
            onNotamClick
          );
        }
        showArea(mapInstance, google, notam, notamAreasRef, selectedId);
      });
    }
  }, [google, mapInstance, notams, onNotamClick, selectedId, showNotamLayer]);

  return { notams };
}

function showArea(
  mapInstance: google.maps.Map,
  google: any,
  notam: NotamsResponseWithLocation,
  notamAreasRef: MutableRefObject<Map<string, any>>,
  selectedId: string
) {
  if (notamAreasRef.current.has(notam.id)) {
    notamAreasRef.current.get(notam.id).dataLayer.setMap(mapInstance);
  } else {
    notamAreasRef.current.set(
      notam.id,
      getDataLayer(google, mapInstance, notam)
    );
  }
  notamAreasRef.current
    .get(notam.id)
    .dataLayer.setStyle((styleProps: any): any => ({
      ...styleProps,
      ...(selectedId && selectedId === notam.id
        ? notam.selectedStyles
        : notam.styles),
      clickable: false,
      editable: false,
      draggable: false,
    }));
}

function showMarker(
  mapInstance: google.maps.Map,
  google: any,
  notam: NotamsResponseWithLocation,
  notamMarkersRef: MutableRefObject<Map<string, any>>,
  icon: { url: any; anchor: any; scaledSize: any },
  onNotamClick: (id: string) => void
) {
  if (notamMarkersRef.current.has(notam.id)) {
    notamMarkersRef.current.get(notam.id).setMap(mapInstance);
    notamMarkersRef.current.get(notam.id).setIcon(icon);
  } else {
    notamMarkersRef.current.set(
      notam.id,
      getMarker(google, mapInstance, icon, notam, onNotamClick)
    );
  }
}

function hideNotInView(
  notamAreasRef: MutableRefObject<Map<string, any>>,
  notams: NotamsResponseWithLocation[],
  notamMarkersRef: MutableRefObject<Map<string, any>>
) {
  notamAreasRef.current.forEach((area, index) => {
    if (!notams?.find((notam) => notam.id === index)) {
      area.dataLayer.setMap(null);
    }
  });
  notamMarkersRef.current.forEach((area) => area.setMap(null));
}

function getIcon(mapInstance: any, google: any) {
  const currentZoom = mapInstance.getZoom();
  const scaledSize =
    currentZoom < 7
      ? new google.maps.Size(12, 12)
      : new google.maps.Size(24, 24);
  const anchor =
    currentZoom < 7
      ? new google.maps.Point(6, 6)
      : new google.maps.Point(12, 12);
  const icon = { url: notamIcon, anchor: anchor, scaledSize: scaledSize };
  return icon;
}

function hideAll(
  notamAreasRef: MutableRefObject<Map<string, any>>,
  notamMarkersRef: MutableRefObject<Map<string, any>>
) {
  notamAreasRef.current.forEach((area) => area.dataLayer.setMap(null));
  notamMarkersRef.current.forEach((area) => area.setMap(null));
}

function getMarker(
  google: any,
  mapInstance: google.maps.Map,
  icon: { url: any; anchor: any; scaledSize: any },
  notam: NotamsResponseWithLocation,
  onNotamClick: (id: string) => void
) {
  const marker = new google.maps.Marker({
    map: mapInstance,
    google,
    position: notam.location,
    icon: icon,
    zIndex: notam?.styles?.zIndex,
  });

  if (onNotamClick) {
    marker.addListener('click', () => onNotamClick(notam.id));
  }
  return marker;
}

function getDataLayer(
  google: any,
  mapInstance: google.maps.Map,
  notam: NotamsResponseWithLocation
) {
  const dataLayer = new google.maps.Data({
    map: mapInstance,
  });

  dataLayer.addGeoJson({
    type: 'Feature',
    geometry: {
      type: notam.type,
      coordinates: notam.coordinates,
    },
  });

  return {
    dataLayer,
  };
}
