import React, { useState, useEffect, useCallback, useContext } from 'react';
import cn from 'classnames';
import { RouteComponentProps, withRouter, matchPath } from 'react-router';
import { DateTime } from 'luxon';
import { animated, useSpring, useTransition } from 'react-spring';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { Alert } from '@material-ui/lab';

import {
  LoadingButton,
  StatusPill,
  useInterval,
} from 'airshare-pilot-web-shared';
import {
  FRStatusCode,
  AcknowledgeFlightRequestResponse,
  ComparativeFlightAdvisoryResponse,
  Status,
} from '@airshare/external-api-types';

import { AdvisorySource, PilotOnlyStatusCode } from '@airshare/pilot-types';

import {
  getNearbyFlights,
  acknowledgeAllAuthChanges,
  getActivationEligibility,
} from '../../../../pilot-api-client';
import {
  getOverallPilotFacingStatus,
  isFlightEditable,
} from '../../../../lib/flight-helpers';
import {
  useFetchFlightRequestsFocussed,
  useCancelFlightRequest,
  useActivateFlight,
  useCancelStatus,
  useActivationStatus,
  useResetCancelStatus,
  useResetEndStatus,
  useResetActivationStatus,
  useEndFlightRequest,
  useEndStatus,
  useFocussedFlightRequest,
} from '../../../../state/flight-requests/hooks';
import {
  FlightRequestsCancelStatus,
  FlightRequestsEndStatus,
} from '../../../../state/flight-requests/constants';
import {
  useFlightAdvisoriesUpdated,
  useHighlightedFlightAdvisoriesUpdated,
} from '../../../../state/flight-advisories/hooks';
import {
  FLIGHT_BRIEFING_PATH,
  FLIGHT_REQUESTS_PATH,
  getFlightPlanEditPath,
} from '../../../../routes';
import { ExpandButton } from '../../../shared/expand-button/expand-button';
import { useNavigateTo } from '../../../shared/hooks/router.hook';
import { BackButton } from '../../../shared/back-button/back-button';
import { LoadingPanel } from '../../../shared/loading-panel/loading-panel';
import { canEndFlight } from '../../../../lib/flight-endable';
import { useIsLoading } from './hooks/is-loading.hook';
import { useHasError } from './hooks/has-error.hook';
import { useAcknowledgementsRequired } from './hooks/use-acknowledgements-required.hook';
import ImportantInfoSection from './important-info-section';
import CriticalInfoSection from './critical-info-section';
import WarningPanel from './warning-panel';
import PilotAlerts from './pilot-alerts';
import WeatherBriefing from './weather-briefing';
import SegmentActivationModal from '../../../shared/segments/segment-activation-modal';
import { FlightSummary } from './flight-summary';
import './flight-briefing.scss';
import { useClearForm } from '../../../../state/flight-plan/flight-plan-form/hooks';
import { GlobalState } from '~/state/GlobalState';
import { useIsAuthenticated } from '../../../shared/hooks/is-authenticated.hook';

import ReportIncidentModal from './report-incident-modal';
import { RequestToPilotStatus, RequestToPilotType } from 'argus-common/enums';

const FLIGHT_REQUEST_REFRESH_INTERVAL = 3000;
const REPORT_INCIDENT_ENABLED =
  window.env.REPORT_INCIDENT_ENABLED?.toLowerCase() === 'true' || false;
interface FlightData {
  isEligible: boolean;
  isEditable: boolean;
  atcHasDigitalAuthEnabled: boolean;
  nearbyFlights: ComparativeFlightAdvisoryResponse[];
  isActivatable: boolean;
}

interface Props
  extends RouteComponentProps<{ flightId: string; nearbyFlight?: string }> {}

function FlightBriefingComponent({ match, history }: Props) {
  const isAuthenticated = useIsAuthenticated();

  const { flightId: flightIdParam } = match.params;
  const { setAdvisoryGeometry, setHighlightedSegments } =
    useContext(GlobalState);

  const navigateTo = useNavigateTo();

  const isLoading = useIsLoading();
  const { errorMessage } = useHasError();
  const setNearbyFlights = useFlightAdvisoriesUpdated();
  const setHighlightedNearbyFlights = useHighlightedFlightAdvisoriesUpdated();

  const fetchFocussedFlight = useFetchFlightRequestsFocussed();
  const focussedFlight = useFocussedFlightRequest();
  const [showFlightSummary, updateShowFlightSummary] = useState(true);
  const [isRequestingCancel, updateIsRequestingCancel] = useState(false);
  const [isRequestingEnd, updateIsRequestingEnd] = useState(false);
  const [isActivatingFlight, updateIsActivatingFlight] = useState(false);
  const cancelFlightRequest = useCancelFlightRequest();
  const activateFlight = useActivateFlight();
  const endFlightRequest = useEndFlightRequest();
  const activationStatus = useActivationStatus();
  const cancelStatus = useCancelStatus();
  const endStatus = useEndStatus();
  const resetCancel = useResetCancelStatus();
  const resetEnd = useResetEndStatus();
  const resetActivation = useResetActivationStatus();
  const [isEditable, setIsEditable] = useState(true);
  const [ackErrors, setAckErrors] = useState<string[]>([]);
  const [atcHasDigitalAuthEnabled, setATCHasDigitalAuthEnabled] =
    useState(false);
  const [isDigitalActivationEligible, setDigitalActivationEligible] =
    useState(false);
  const [isActivatable, setIsActivatable] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [canShowButtons, setCanShowButtons] = useState(false);
  const [actionErrorMessage, setActionErrorMessage] = useState('');
  const setClearForm = useClearForm();
  const [showReportIncidentButton, setShowReportIncidentButton] =
    React.useState(false);
  const [showSegmentModal, setShowSegmentModal] = useState(false);

  const flightInfoSpring = useSpring({});

  const showAreYouSure =
    isRequestingCancel || isRequestingEnd || isActivatingFlight;

  const deleteButtonTransitions = useTransition(!showAreYouSure, null, {
    from: { bottom: -200 },
    enter: { bottom: 15 },
    leave: { bottom: -200 },
  });

  const areYouSureButtonTransitions = useTransition(showAreYouSure, null, {
    from: { bottom: -200 },
    enter: { bottom: 42 },
    leave: { bottom: -200 },
  });

  const cancelButtonTransitions = useTransition(showAreYouSure, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (!isAuthenticated && !location.pathname.endsWith('/signin')) {
      history.push(`${location.pathname}/signin`, {
        redirectUrl: location.pathname,
      });
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    updateIsRequestingCancel(false);
    updateIsRequestingEnd(false);
    setCanShowButtons(false);
  }, [flightIdParam]);

  useEffect(() => {
    resetCancel();
    resetEnd();
    resetActivation();
  }, [resetCancel, resetEnd, resetActivation]);

  useEffect(() => {
    if (focussedFlight) {
      setIsEditable(isFlightEditable(focussedFlight));
      if (
        REPORT_INCIDENT_ENABLED &&
        focussedFlight?.properties?.startDateTime
      ) {
        setShowReportIncidentButton(
          DateTime.fromISO(focussedFlight.properties.startDateTime)
            .startOf('day')
            .toISODate() <= DateTime.local().startOf('day').toISODate()
        );
      }
    } else {
      setIsEditable(false);
    }
  }, [focussedFlight, setIsEditable]);

  const updateFlightRequest = useCallback(
    async (updateFlightId?: string) => {
      if (!updateFlightId || !isAuthenticated) {
        setNearbyFlights([]);
        setHighlightedSegments([]);
        return;
      }
      const flightData: FlightData = {
        isEligible: false,
        isEditable: false,
        atcHasDigitalAuthEnabled: false,
        isActivatable: false,
        nearbyFlights: [],
      };
      const setFlightData = (fd: FlightData) => {
        setATCHasDigitalAuthEnabled(fd.atcHasDigitalAuthEnabled);
        setNearbyFlights(fd.nearbyFlights ?? []);
        if (!fd.nearbyFlights?.length) {
          setHighlightedNearbyFlights([]);
        }
        setDigitalActivationEligible(!!fd.isEligible);
        setIsActivatable(!!fd.isActivatable);
      };

      fetchFocussedFlight({ id: updateFlightId, resetState: false });
      const overallStatus = getOverallPilotFacingStatus(focussedFlight);
      const requestActivationEligibility =
        focussedFlight?.properties &&
        ![
          FRStatusCode.Cancelled.toString(),
          FRStatusCode.Terminated.toString(),
          FRStatusCode.Declined.toString(),
          FRStatusCode.Activated.toString(),
        ].includes(overallStatus.toString());

      const [authRes, nearbyRes] = await Promise.all([
        requestActivationEligibility
          ? getActivationEligibility(updateFlightId)
          : {
              eligible: false,
              atcHasDigitalAuthEnabled: false,
              isActivatable: false,
            },
        getNearbyFlights(updateFlightId),
      ]);
      // only update nearby flights if we're still on the flight briefing page
      if (
        matchPath(location.pathname, {
          path: FLIGHT_BRIEFING_PATH,
          exact: false,
        })
      ) {
        if (
          (requestActivationEligibility && authRes) ||
          overallStatus === FRStatusCode.Activated
        ) {
          setCanShowButtons(true);
        }
        flightData.isEligible = authRes?.eligible;
        flightData.atcHasDigitalAuthEnabled = authRes?.atcHasDigitalAuthEnabled;
        flightData.isActivatable = authRes?.isActivatable;
        flightData.nearbyFlights =
          nearbyRes && 'message' in nearbyRes ? [] : nearbyRes?.data;
      }
      setFlightData(flightData);
    },
    [
      fetchFocussedFlight,
      focussedFlight,
      setHighlightedNearbyFlights,
      setHighlightedSegments,
      setNearbyFlights,
      isAuthenticated,
    ]
  );

  useEffect(
    () => {
      updateIsActivatingFlight(false);
      resetActivation();

      updateFlightRequest(flightIdParam);
      return () => {
        updateFlightRequest('');
        setAdvisoryGeometry(AdvisorySource.None, null);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flightIdParam]
  );

  useInterval(
    () => updateFlightRequest(flightIdParam),
    FLIGHT_REQUEST_REFRESH_INTERVAL
  );

  const acknowledgements = useAcknowledgementsRequired(focussedFlight);

  useEffect(() => {
    const isCancelled = cancelStatus === FlightRequestsCancelStatus.SUCCESS;
    const hasEnded = endStatus === FlightRequestsEndStatus.SUCCESS;
    if (isCancelled || hasEnded) {
      navigateTo(FLIGHT_REQUESTS_PATH);
    }
  }, [cancelStatus, endStatus, navigateTo]);

  useEffect(() => {
    async function endActivation() {
      const hasBeenActivated =
        activationStatus === FlightRequestsEndStatus.SUCCESS;
      if (hasBeenActivated && isActivatingFlight) {
        await updateFlightRequest(flightIdParam);
        updateIsActivatingFlight(false);
        resetActivation();
      }
      const isActivationFailed =
        activationStatus.status === FlightRequestsEndStatus.FAILED;
      if (isActivationFailed && isActivatingFlight) {
        setActionErrorMessage(activationStatus.payload.error);
        await updateFlightRequest(flightIdParam);
        setIsActivating(false);
        resetActivation();
      }
    }
    endActivation();
  }, [
    activationStatus,
    flightIdParam,
    isActivatingFlight,
    resetActivation,
    updateFlightRequest,
  ]);

  useEffect(() => {
    const isCancelled = cancelStatus === FlightRequestsCancelStatus.SUCCESS;
    const hasEnded = endStatus === FlightRequestsEndStatus.SUCCESS;

    if (isCancelled) {
      navigateTo(FLIGHT_REQUESTS_PATH);
    }
    if (hasEnded) {
      navigateTo(`${FLIGHT_REQUESTS_PATH}/${flightIdParam}`);
    }
  }, [cancelStatus, navigateTo, endStatus, flightIdParam]);

  function handleClose() {
    navigateTo(FLIGHT_REQUESTS_PATH);
  }

  function handleCancelFlight() {
    cancelFlightRequest({ id: focussedFlight?.properties?.id });
  }

  function handleRequestCancelFlight() {
    updateIsRequestingCancel(true);
  }

  function handleCancelCancelFlight() {
    updateIsRequestingCancel(false);
  }

  function handleEndFlight() {
    endFlightRequest({ id: focussedFlight?.properties?.id });
  }

  function handleRequestEndFlight() {
    setIsActivating(false);
    updateIsRequestingEnd(true);
  }

  function handleCancelEndFlight() {
    updateIsRequestingEnd(false);
  }

  function handleCancelActivationFlight() {
    setActionErrorMessage('');
    updateIsActivatingFlight(false);
  }

  function handleEditFlight() {
    setClearForm();
    navigateTo(getFlightPlanEditPath(flightIdParam));
  }

  function handleConfirmActivateFlight() {
    setIsActivating(true);
    activateFlight({ id: flightIdParam });
  }
  function handleActivateFlight() {
    updateIsActivatingFlight(true);
  }
  function handleRequestActivation() {
    navigateTo(`/clearance/${flightIdParam}`);
  }

  function handleAcceptFlightChanges() {
    acknowledgeAllAuthChanges(focussedFlight).then(
      (resp: AcknowledgeFlightRequestResponse) => {
        if (resp.errors) {
          setAckErrors(resp.errors);
        } else {
          updateFlightRequest(flightIdParam);
        }
      }
    );
  }

  const overallStatus = getOverallPilotFacingStatus(focussedFlight);

  const needsAcknowledgement =
    overallStatus === PilotOnlyStatusCode.AcceptanceReq;

  const isTerminated = overallStatus === FRStatusCode.Terminated;
  const isDeclined = overallStatus === FRStatusCode.Declined;
  const isActivated = overallStatus === FRStatusCode.Activated;
  const isCancelled = overallStatus === FRStatusCode.Cancelled;
  const isCanceling =
    cancelStatus ===
    FlightRequestsCancelStatus.FLIGHT_REQUESTS_CANCEL_IN_PROGRESS;
  const isEnding =
    endStatus === FlightRequestsEndStatus.FLIGHT_REQUESTS_END_IN_PROGRESS;

  const isCancelable = !(
    isTerminated ||
    isCancelled ||
    isDeclined ||
    isActivated
  );

  const {
    flightId,
    additionalInfo,
    allStatuses,
    endDateTime,
    startDateTime,
    timezone,
    pilotName,
  } = focussedFlight?.properties ?? {};

  const requestsToLandUnacknowledged = additionalInfo?.requestsToPilot
    ? additionalInfo?.requestsToPilot
        .filter(
          (item) =>
            item.type === RequestToPilotType.RequestToLand &&
            item.status === RequestToPilotStatus.Requested
        )
        .filter(
          (item, index, list) =>
            list.findIndex((i) => i.message === item.message) === index
        )
        .map(
          (item) =>
            `Your flight authorisation has been cancelled - ${item.message}`
        )
    : [];

  const pilotConformanceAlerts = additionalInfo?.requestsToPilot
    ? additionalInfo?.requestsToPilot.filter(
        (item) => item.type === 'ConformanceAlert'
      )
    : [];

  const endDT = DateTime.fromISO(endDateTime).setZone(timezone);
  const startDT = DateTime.fromISO(startDateTime).setZone(timezone);
  const duration = endDT.diff(startDT, 'minutes').minutes;
  const formattedStartDate = startDT.toFormat('dd LLLL yyyy');

  const canManageSegments =
    focussedFlight?.properties?.segmentCollection?.features?.length > 0 &&
    [
      FRStatusCode.Declared.toString(),
      FRStatusCode.ProvAuth.toString(),
      FRStatusCode.Activated.toString(),
    ].includes(overallStatus?.toString());

  const canActivateFlight =
    (overallStatus?.toString() === FRStatusCode.Declared.toString() ||
      (!atcHasDigitalAuthEnabled && !isDigitalActivationEligible)) &&
    isActivatable &&
    !canManageSegments &&
    [
      FRStatusCode.Declared.toString(),
      FRStatusCode.Authorized.toString(),
      FRStatusCode.RNZAFAuthReq.toString(),
    ].includes(overallStatus?.toString());

  const canRequestActivation =
    atcHasDigitalAuthEnabled &&
    isDigitalActivationEligible &&
    !isActivatable &&
    !canManageSegments &&
    [FRStatusCode.ProvAuth.toString()].includes(overallStatus?.toString());

  const isEndable =
    !canRequestActivation &&
    !canActivateFlight &&
    canEndFlight(startDateTime, duration, allStatuses);

  const renderUndoCancelEnd = () =>
    cancelButtonTransitions.map(
      (cancelButtonTransitionProps) =>
        cancelButtonTransitionProps.item &&
        showAreYouSure && (
          <animated.button
            key={cancelButtonTransitionProps.key}
            style={cancelButtonTransitionProps.props}
            className="cancel-delete-button"
            type="button"
            onClick={
              isRequestingEnd
                ? handleCancelEndFlight
                : isActivatingFlight
                  ? handleCancelActivationFlight
                  : handleCancelCancelFlight
            }
            data-testid="pilot-web:flight-briefing-panel:cancel-delete-button"
          >
            go back
          </animated.button>
        )
    );

  return (
    <LoadingPanel isLoading={isLoading}>
      <>
        <BackButton onClick={handleClose} />

        {showSegmentModal && (
          <SegmentActivationModal
            closeSegmentModal={async () => {
              await updateFlightRequest(flightIdParam);
              setShowSegmentModal(false);
            }}
          />
        )}

        <div
          className="flight-briefing-panel"
          data-testid="pilot-web:flight-briefing-panel"
        >
          {focussedFlight?.properties && (
            <>
              <div
                className={cn('card flight-details', {
                  cancelable:
                    !needsAcknowledgement && (isCancelable || isEndable),
                })}
              >
                <Typography
                  className="heading"
                  variant="subtitle1"
                  data-testid="pilot-web:flight-briefing-panel:flight-id"
                >
                  Flight ID: #{flightId}
                </Typography>
                <Typography
                  className="heading"
                  variant="subtitle2"
                  data-testid="pilot-web:flight-briefing-panel:flight-date"
                >
                  {formattedStartDate}
                </Typography>
                <StatusPill
                  id={`flight-briefing-${flightId}`}
                  className="status"
                  status={overallStatus}
                  variant="open"
                  testId="pilot-web:flight-briefing-panel:flight-status"
                />
                {additionalInfo?.nickname && (
                  <Typography
                    className="heading nickname"
                    variant="subtitle2"
                    data-testid="pilot-web:flight-briefing-panel:flight-name"
                  >
                    {additionalInfo?.nickname}
                  </Typography>
                )}
                {pilotName && (
                  <Typography
                    className="heading pilotname"
                    variant="subtitle2"
                    data-testid="pilot-web:flight-briefing-panel:pilot-name"
                  >
                    Pilot: {pilotName}
                  </Typography>
                )}
                {actionErrorMessage && (
                  <Typography
                    className="action-errors"
                    variant="subtitle2"
                    data-testid="pilot-web:flight-briefing-panel:action-error-message"
                  >
                    {actionErrorMessage}
                  </Typography>
                )}
                {!needsAcknowledgement && (isCancelable || isEndable) && (
                  <>
                    {deleteButtonTransitions.map(
                      (deleteButtonTransitionProps) =>
                        deleteButtonTransitionProps.item && (
                          <animated.div
                            className="cancel-flight-button-animation-container"
                            key={deleteButtonTransitionProps.key}
                            style={deleteButtonTransitionProps.props}
                          >
                            {canRequestActivation && (
                              <div className="row100">
                                <ButtonBase
                                  className="request-activation-button button primary flight-action-button"
                                  type="button"
                                  onClick={handleRequestActivation}
                                  data-testid="pilot-web:flight-briefing-panel:request-activation-button"
                                >
                                  Request Activation
                                </ButtonBase>
                              </div>
                            )}
                            {canManageSegments && (
                              <div className="row100">
                                <ButtonBase
                                  className="manage-segments-flight-button button primary flight-action-button"
                                  type="button"
                                  onClick={() => setShowSegmentModal(true)}
                                  data-testid="pilot-web:flight-briefing-panel:manage-segments-flight-button"
                                >
                                  Activate Segments
                                </ButtonBase>
                              </div>
                            )}
                            {canActivateFlight && (
                              <div className="row100">
                                <ButtonBase
                                  className="activate-flight-button button primary flight-action-button"
                                  type="button"
                                  onClick={handleActivateFlight}
                                  data-testid="pilot-web:flight-briefing-panel:activate-flight-button"
                                >
                                  Activate Flight
                                </ButtonBase>
                              </div>
                            )}
                            {!canShowButtons && (
                              <>
                                <Skeleton
                                  variant="rect"
                                  width={90}
                                  height={28}
                                />
                                <Skeleton
                                  variant="rect"
                                  width={90}
                                  height={28}
                                />
                              </>
                            )}
                            {isCancelable && canShowButtons && (
                              <ButtonBase
                                className="cancel-flight-button button flight-action-button"
                                type="button"
                                onClick={handleRequestCancelFlight}
                                data-testid="pilot-web:flight-briefing-panel:cancel-flight-button"
                              >
                                Cancel Flight
                              </ButtonBase>
                            )}
                            {!isEditable && isEndable && canShowButtons ? (
                              <ButtonBase
                                className="terminate-flight-button button secondary flight-action-button"
                                type="button"
                                onClick={handleRequestEndFlight}
                                data-testid="pilot-web:flight-briefing-panel:terminate-flight-button"
                              >
                                Terminate Flight
                              </ButtonBase>
                            ) : null}

                            {isEditable && !isActivated && canShowButtons && (
                              <ButtonBase
                                className="edit-flight-button button primary flight-action-button"
                                type="button"
                                onClick={handleEditFlight}
                                data-testid="pilot-web:flight-briefing-panel:edit-flight-button"
                              >
                                Edit Flight
                              </ButtonBase>
                            )}
                          </animated.div>
                        )
                    )}

                    {!actionErrorMessage &&
                      areYouSureButtonTransitions.map(
                        (areYouSureButtonTransitionProps) =>
                          areYouSureButtonTransitionProps.item && (
                            <animated.div
                              className="are-you-sure-button-animation-container"
                              key={areYouSureButtonTransitionProps.key}
                              style={areYouSureButtonTransitionProps.props}
                            >
                              <div className="row100">
                                <LoadingButton
                                  isLoading={
                                    isCanceling || isEnding || isActivating
                                  }
                                  className="are-you-sure-button"
                                  type="button"
                                  onClick={
                                    isRequestingEnd
                                      ? handleEndFlight
                                      : isActivatingFlight
                                        ? handleConfirmActivateFlight
                                        : handleCancelFlight
                                  }
                                  data-testid="pilot-web:flight-briefing-panel:delete-button"
                                >
                                  {isActivatingFlight &&
                                    'Continue Activating Flight?'}
                                  {!isActivatingFlight && 'Are you sure?'}
                                </LoadingButton>
                              </div>
                            </animated.div>
                          )
                      )}

                    {renderUndoCancelEnd()}
                  </>
                )}

                {errorMessage && (
                  <p
                    className="validation-errors"
                    data-testid="pilot-web:flight-briefing-panel:validation-errors"
                  >
                    {errorMessage}
                  </p>
                )}
              </div>

              {needsAcknowledgement && (
                <div
                  className={cn('card accept-flight-changes', {
                    cancelable: isCancelable || isEndable,
                  })}
                >
                  <Alert severity="warning" className="ack-alert-box">
                    {acknowledgements.map((change, ndx) => (
                      <Typography
                        key={`acknowledement-text-${ndx}`}
                        className="heading"
                        variant="subtitle1"
                        data-testid="pilot-web:flight-briefing-panel:acknowledgement"
                      >
                        {change}
                      </Typography>
                    ))}
                  </Alert>
                  <>
                    {deleteButtonTransitions.map(
                      (deleteButtonTransitionProps) =>
                        deleteButtonTransitionProps.item && (
                          <animated.div
                            className="cancel-flight-button-animation-container"
                            key={deleteButtonTransitionProps.key}
                            style={deleteButtonTransitionProps.props}
                          >
                            <ButtonBase
                              className="cancel-flight-button button"
                              type="button"
                              onClick={handleRequestCancelFlight}
                              data-testid="pilot-web:flight-briefing-panel:cancel-flight-button"
                            >
                              Cancel Flight
                            </ButtonBase>

                            <ButtonBase
                              className="accept-flight-changes-button button primary"
                              type="button"
                              onClick={handleAcceptFlightChanges}
                              data-testid="pilot-web:flight-briefing-panel:accept-flight-changes-button"
                            >
                              Accept
                            </ButtonBase>
                          </animated.div>
                        )
                    )}

                    {areYouSureButtonTransitions.map(
                      (areYouSureButtonTransitionProps) =>
                        areYouSureButtonTransitionProps.item && (
                          <animated.div
                            className="are-you-sure-button-animation-container"
                            key={areYouSureButtonTransitionProps.key}
                            style={areYouSureButtonTransitionProps.props}
                          >
                            <LoadingButton
                              isLoading={isCanceling}
                              className="are-you-sure-button"
                              type="button"
                              onClick={handleCancelFlight}
                              data-testid="pilot-web:flight-briefing-panel:delete-button"
                            >
                              Are you sure?
                            </LoadingButton>
                          </animated.div>
                        )
                    )}

                    {renderUndoCancelEnd()}
                  </>

                  {ackErrors && (
                    <div>
                      {ackErrors.map((ackErr, ndx) => (
                        <p
                          key={`ack-error-${ndx}`}
                          className="validation-errors"
                          data-testid="pilot-web:flight-briefing-panel:acknowledge-errors"
                        >
                          {ackErr}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <WarningPanel
                title="The flight conditions have changed due to the following:"
                messages={requestsToLandUnacknowledged}
              />
              <PilotAlerts
                flightId={flightIdParam}
                title="Conformance Alerts:"
                alerts={pilotConformanceAlerts}
                overallStatus={overallStatus}
                timezone={timezone}
              />

              <CriticalInfoSection
                warnings={focussedFlight?.properties?.allStatuses
                  ?.map((s: Status) => s.warningMessage)
                  .filter((x: string) => x)}
              />

              <WeatherBriefing flight={focussedFlight} />

              {showReportIncidentButton && (
                <ReportIncidentModal flightId={flightId} />
              )}
              <ImportantInfoSection
                flight={focussedFlight}
                atcHasDigitalAuthEnabled={atcHasDigitalAuthEnabled}
              />

              <div className="flight-summary-section">
                <div
                  className="collapsible-section-heading"
                  data-testid="pilot-web:flight-briefing-panel:flight-summary-section"
                >
                  <Typography
                    className="sub-heading"
                    variant="subtitle1"
                    onClick={() =>
                      updateShowFlightSummary(
                        (prevShowFlightSummary) => !prevShowFlightSummary
                      )
                    }
                  >
                    Flight Summary
                  </Typography>
                  <ExpandButton
                    isExpanded={showFlightSummary}
                    onClick={() =>
                      updateShowFlightSummary(
                        (prevShowFlightSummary) => !prevShowFlightSummary
                      )
                    }
                  />
                </div>

                <animated.div
                  style={flightInfoSpring}
                  className="flight-summary"
                >
                  <FlightSummary
                    showFlightSummary={showFlightSummary}
                    flight={focussedFlight}
                  />
                </animated.div>
              </div>
            </>
          )}
          {!focussedFlight?.properties && (
            <>
              <Typography
                className="failure-message"
                variant="h6"
                component="h2"
                align="center"
                data-testid="pilot-web:flight-briefing-panel:retrieve-flight-error"
              >
                We failed to retrieve your flight
              </Typography>
              <Typography
                className="failure-action"
                variant="body1"
                component="p"
                align="center"
                data-testid="pilot-web:flight-briefing-panel:click-flight-error-message"
              >
                Please click
                <button
                  className="retry-link"
                  type="button"
                  onClick={() => fetchFocussedFlight({ id: flightIdParam })}
                  data-testid="pilot-web:flight-briefing-panel:click-flight-error-message:flightId"
                >
                  {'\u00A0'}here
                </button>{' '}
                to retry
              </Typography>
            </>
          )}
        </div>
      </>
    </LoadingPanel>
  );
}

export const FlightBriefing = withRouter(FlightBriefingComponent);
