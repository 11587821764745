import * as yup from 'yup';
import { createFormReducer } from 'airshare-web-utils/redux-forms/index.ts';
import { actions, ACTIVATION_FORM_KEY } from './actions';

export const REDUCER_KEY = 'authForms';

export const schema = yup.object().shape({
  email: yup.string().email().required('Required'),
  activationKey: yup.string().length(6).required('Required'),
});

const defaultState = { email: '', activationKey: '' };

export const activationFormReducer = {
  [ACTIVATION_FORM_KEY]: createFormReducer(actions, defaultState, schema),
};
