import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  type OverlappingEdgeDetails,
  OverlappingEdge,
  OverlappingType,
} from '@airshare/external-api-types';

const useStyles = makeStyles({
  advisoryDetailItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  advisoryDetailItemOverlap: {
    color: 'var(--color-highlight-orange)',
  },
  advisoryDetailItemNearby: {},
  advisoryDetailItemDistant: {},
});

export const AdvisoryDetailItem = ({
  label,
  testId,
  value,
  overlappingDetails,
  edge,
}: {
  label: string;
  testId: string;
  value: string;
  overlappingDetails?: OverlappingEdgeDetails;
  edge?: OverlappingEdge;
}): JSX.Element => {
  const classes = useStyles();

  let display: JSX.Element | string = value;
  if (testId === 'pilot-number') {
    display = (
      <a href={`tel:${value}`} target="_self">
        {value}
      </a>
    );
  } else if (testId === 'pilot-email') {
    display = (
      <a href={`mailto:${value}`} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  }

  let overlapTypeClassName = '';
  const doStyleText =
    edge &&
    edge !== OverlappingEdge.NONE &&
    !(
      edge === OverlappingEdge.START &&
      overlappingDetails?.edge === OverlappingEdge.END
    ) &&
    !(
      edge === OverlappingEdge.END &&
      overlappingDetails?.edge === OverlappingEdge.START
    );

  if (doStyleText) {
    switch (overlappingDetails?.overlap) {
      case OverlappingType.OVERLAPPING:
        overlapTypeClassName = classes.advisoryDetailItemOverlap;
        break;
      case OverlappingType.NEARBY:
        overlapTypeClassName = classes.advisoryDetailItemNearby;
        break;
      case OverlappingType.DISTANT:
        overlapTypeClassName = classes.advisoryDetailItemDistant;
        break;
      default:
        overlapTypeClassName = '';
        break;
    }
  }

  return (
    <li className={classes.advisoryDetailItem}>
      <Typography variant="body2">{label}</Typography>
      <Typography
        variant="body2"
        data-testid={'pilot-web:shared:flight-advisory-accordian:' + testId}
        className={overlapTypeClassName}
      >
        {display}
      </Typography>
    </li>
  );
};
